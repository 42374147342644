import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { createLocation } from 'components/MyRouting/utils';
import ChooseScreen from 'components/SearchTools/ChooseScreen';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import {
  useBasketsQuery,
  useDeleteBasketMutation,
  useUniversesQuery,
} from 'screens/Universe/lib/queries';
import { Routes } from 'utils/constants';

const columnsWithoutUniverse = ['id', 'name', 'assets'];
const columnsWithUniverse = ['id', 'name', 'assets', 'universe'];

const ChooseBasketScreen = () => {
  const history = useHistory();
  const hasCreatePermission = useCreatePermission(PermissionResourceName.BASKET);

  const { universes } = useUniversesQuery();
  const { baskets, isLoading } = useBasketsQuery();
  const { mutate: deleteBasket } = useDeleteBasketMutation();

  const columns = _.size(universes) > 1 ? columnsWithUniverse : columnsWithoutUniverse;
  const cellFormatting = {
    assets: assets => assets.length,
    universe: universeId => (universes[universeId] ? universes[universeId].name : ''),
  };

  return (
    <ChooseScreen
      dialogTranslatePath='basketScreen.useSavePromptAndDialog'
      waitWhile={isLoading}
      key={columns}
      tableObjects={baskets}
      columns={columns}
      searchColumns={columns}
      cellFormatting={cellFormatting}
      screenTitle='manageBaskets'
      searchValue={null}
      translateTableId='basketTable'
      isTableWhite
      onNewClick={hasCreatePermission ? () => history.push(Routes.NEW_BASKET) : null}
      onRowClick={id => history.push(createLocation(Routes.BASKET, id))}
      onDeleteRowClick={obj => {
        deleteBasket(obj.id);
      }}
    />
  );
};

export default ChooseBasketScreen;

/* Component with Initialization code.
Include it in Root after LocalizeProvider.
 */

import { useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { initialize, setActiveLanguage } from 'react-localize-redux';
import { useDispatch } from 'react-redux';

import useLocalStorage from 'lib/misc/useLocalStorage';
import translations from 'static/translations/translations.json';

const LocalizeInitializer = () => {
  const dispatch = useDispatch();
  const [activeLanguageStorage] = useLocalStorage('activeLanguage', 'de');

  useEffect(() => {
    dispatch(setActiveLanguage(activeLanguageStorage));
  }, [activeLanguageStorage, dispatch]);

  useEffect(() => {
    dispatch(
      initialize({
        languages: [
          { name: 'English', code: 'en' },
          { name: 'German', code: 'de' },
        ],
        translation: translations,
        options: {
          renderToStaticMarkup,
          renderInnerHtml: true,
        },
      })
    );
    dispatch(setActiveLanguage(activeLanguageStorage));
  }, [activeLanguageStorage, dispatch]);

  return null;
};

export default LocalizeInitializer;

import { Snackbar } from '@mui/material/index';
import PropTypes from 'prop-types';
import React from 'react';

import SimpleText from 'components/SimpleText';

class NotificationSnack extends React.Component {
  state = {
    cachedNotification: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.notification && props.notification !== state.cachedNotification) {
      return {
        cachedNotification: props.notification,
      };
    }
    return null;
  }

  render() {
    const { place, onClose, timeout, notification } = this.props;
    const { cachedNotification } = this.state;
    const open = Boolean(notification);

    return (
      <Snackbar
        style={{ marginBottom: `${place * 4}rem`, height: '2rem' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={timeout}
        onClose={onClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <SimpleText translateId={`smallNotification.${cachedNotification}`} variant='body1' />
        }
      />
    );
  }
}

NotificationSnack.propTypes = {
  timeout: PropTypes.number,
  notification: PropTypes.string,
  place: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

NotificationSnack.defaultProps = {
  timeout: null,
  place: 0,
  notification: null,
};

export default NotificationSnack;

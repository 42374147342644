import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { makePerformanceInfosFromAssetQueries } from 'lib/performance/helpers';
import KPIsTable from 'lib/performance/KPIsTable';
import { useAssetQueries } from 'screens/Environment/lib/queries';
import usePortfolioPerformance from 'screens/Portfolio/lib/hooks/usePortfolioPerformance';
import { deleteWidget } from 'screens/Report/redux/actions';
import WidgetToolbar from 'screens/Report/ReportScreen/components/WidgetToolbar';
import useDashboardStyles from 'screens/Report/ReportScreen/configuration/dashboard.style';
import { widgetComponentPropType } from 'utils/types';

const KPITableWidget = ({
  widget,
  setSelectedWidget,
  setShowSettings,
  setSettingsType,
  setLayout,
}) => {
  const dashboardStyles = useDashboardStyles();
  const {
    portfolio: portfolioId,
    backtestId,
    benchmarks,
    type: dataType,
    startDate,
    endDate,
    rows,
    size,
  } = widget.configuration;
  const dispatch = useDispatch();

  const compareAssetIds = (benchmarks?.value ?? []).map(b => b.id);
  const assetQueries = useAssetQueries(compareAssetIds);
  const benchmarkPerfInfos = useMemo(() => {
    return makePerformanceInfosFromAssetQueries(compareAssetIds, assetQueries);
  }, [compareAssetIds, assetQueries]);
  const { performanceInfo, progressOrErrorElem, points } = usePortfolioPerformance({
    portfolioId: portfolioId?.value,
    dataType: dataType?.value,
    backtestId: backtestId?.value,
  });
  const content = (
    <KPIsTable
      performanceInfo={performanceInfo}
      benchmarkPerformanceInfos={benchmarkPerfInfos}
      startDate={startDate?.value}
      endDate={endDate?.value}
      points={points}
      kpisToShow={rows?.value}
      size={size?.value}
      isSimpleTable
    />
  );
  return (
    <div className={dashboardStyles.mainCardWrapper}>
      <WidgetToolbar
        onEdit={() => {
          setSettingsType('KPITableWidget');
          setSelectedWidget(widget);
          setShowSettings(true);
        }}
        onDelete={() => {
          setLayout(prev => prev.filter(item => item.i !== widget.id));
          dispatch(deleteWidget(widget.id));
        }}
      />
      {progressOrErrorElem ?? content}
    </div>
  );
};

KPITableWidget.propTypes = widgetComponentPropType;

export default KPITableWidget;

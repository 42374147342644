import { Id } from 'lib/types';
import { useAnalysisQuery } from 'screens/Analysis/lib/queries';
import { useBacktestQuery } from 'screens/Backtest/lib/queries';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';

export const useOptimizationJobQuery = (portfolioId: Id | undefined) => {
  const query = usePortfolioQuery(portfolioId);
  return {
    ...query,
    data: query.data?.optimizationJob,
    job: query.data?.optimizationJob,
    portfolio: undefined,
  };
};

export const useBacktestJobQuery = (backtestId: Id | undefined) => {
  const query = useBacktestQuery(backtestId);
  return {
    ...query,
    data: query.data?.backtestJob,
    job: query.data?.backtestJob,
    backtest: undefined,
  };
};

export const useAnalysisJobQuery = (analysisId: Id | undefined) => {
  const query = useAnalysisQuery(analysisId);
  return {
    ...query,
    data: query.data?.analysisJob,
    job: query.data?.analysisJob,
    analysis: undefined,
  };
};

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import parse from 'html-react-parser';
import React from 'react';

import DialogButton from 'components/Buttons/DialogButton';
import SimpleText from 'components/SimpleText';
import privacyPolicy from 'static/translations/privacyPolicy';
import termsAndConditions from 'static/translations/termsAndConditions';
import { DialogKeys } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  section: {
    paddingTop: theme.spacing(6),
  },
  paragraph: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  dialog: {
    '@media print': {
      visibility: 'hidden',
    },
  },
  dialogPaper: {
    '@media print': {
      width: '100%',
      height: '100%',
      margin: 0,
      borderRadius: 0,
      maxWidth: '100%',
      maxHeight: 'none',
      visibility: 'visible',
    },
  },
  buttons: {
    '@media print': {
      display: 'none',
    },
  },
}));

interface TermsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'termsAndConditions' | 'privacyPolicy';
}

const TermsDialog = ({ isOpen, onClose, type }: TermsDialogProps) => {
  const classes = useStyles();
  const translations = type === 'termsAndConditions' ? termsAndConditions.de : privacyPolicy.de;

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={isOpen}
      onClose={onClose}
      aria-labelledby='tc-dialog'
      className={classes.dialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle id='tc-title'>
        <Typography variant='h2'>{translations.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>{parse(translations.definitions)}</Typography>

        {translations.sections.map((section, sectionIndex) => (
          <div key={section.title} className={classes.section}>
            <SimpleText variant='h3' text={`${sectionIndex + 1}. ${section.title}`} />
            {section.paragraphs.map((paragraph, paragraphIndex) => (
              <Typography className={classes.paragraph} variant='body1' key={paragraph}>
                {parse(`<b>(${paragraphIndex + 1})</b> ${paragraph}`)}
              </Typography>
            ))}
          </div>
        ))}
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <DialogButton
          onClick={onClose}
          translateId={`dialogKey.${DialogKeys.CLOSE}`}
          variant='lowEmp'
        />
        <DialogButton
          onClick={window.print}
          translateId={`dialogKey.${DialogKeys.PRINT}`}
          variant='lowEmp'
        />
      </DialogActions>
    </Dialog>
  );
};

export default TermsDialog;

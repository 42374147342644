import React from 'react';

import ObjectAccessDialog from 'components/ObjectAccessDialog/ObjectAccessDialog';
import { FunctionalityName } from 'lib/access/constants';
import useFunctionalityOn from 'lib/access/useFunctionalityOn';
import useHasPermission from 'lib/access/useHasPermission';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';
import { ResourceType } from 'utils/types';

const ObjectAccessDialogWrapper = ({ object }) => {
  const isFunctionalityOn = useFunctionalityOn(FunctionalityName.SHARING);
  const { institute } = useCurrentInstitute();
  const minRequiredPermissionLevel =
    institute?.settings?.features?.[FunctionalityName.SHARING]?.min_required_permission_level;
  const hasPermission = useHasPermission(object, minRequiredPermissionLevel);
  if (isFunctionalityOn && hasPermission) {
    return <ObjectAccessDialog object={object} />;
  }
  return null;
};

ObjectAccessDialogWrapper.propTypes = {
  object: ResourceType.isRequired,
};

export default ObjectAccessDialogWrapper;

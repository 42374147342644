import _ from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export function createSearchMenuData(objects, func, extraFuncVariable, switchOrder) {
  const onObjectClickFuncs = {};
  if (!extraFuncVariable) {
    _.forOwn(objects, obj => {
      onObjectClickFuncs[obj.id] = () => func(obj);
    });
  } else if (!switchOrder) {
    _.forOwn(objects, obj => {
      onObjectClickFuncs[obj.id] = () => func(obj, extraFuncVariable);
    });
  } else {
    _.forOwn(objects, obj => {
      onObjectClickFuncs[obj.id] = () => func(obj, extraFuncVariable, switchOrder);
    });
  }
  return { objects, onObjectClickFuncs };
}

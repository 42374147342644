import _ from 'lodash';
import { generatePath, matchPath } from 'react-router-dom';

import createLogger from 'lib/logging/logging';

// eslint-disable-next-line no-unused-vars
const log = createLogger('MyRouting:utils');

export function createLocation(route, params) {
  let finalParams = null;
  if (!_.isNil(params)) {
    // if not object, then it is ID
    if (!_.isObject(params)) {
      finalParams = { id: params };
    } else {
      finalParams = params;
    }
  }
  return generatePath(route, finalParams);
}

export function matchLocation(location, route) {
  // log.debug('matchLocation: ', location, 'for: ', route);
  const { pathname } = location;
  const match = matchPath(pathname, { path: route, exact: true });
  // log.debug('finishedMatchLocation: ', location, 'for: ', route);
  return match;
}

export function isMatchingLocation(location, route) {
  // log.debug('isMatchingLocation: ', location, 'for: ', route);
  return Boolean(matchLocation(location, route));
}

export function isPortfolioPage(location) {
  return location.pathname.includes('portfolio');
}

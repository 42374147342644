import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import ScreenBase from 'components/ScreenBase/ScreenBase';
import DocumentsCard from 'screens/Institute/lib/components/DocumentsCard';
import FeaturesCard from 'screens/Institute/lib/components/FeaturesCard';
import TrialConfigCard from 'screens/Institute/lib/components/TrialConfigCard';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';

const translationPath = 'adminInstituteScreen';

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

const InstituteScreen = () => {
  const classes = useStyles();

  const { institute, isLoading } = useCurrentInstitute();

  const nameTitleProps = {
    name: institute?.name,
    canRename: false,
  };

  return (
    <ScreenBase
      titleTranslationId={`${translationPath}.screenTitle`}
      nameTitleProps={nameTitleProps}
      waitWhile={isLoading}
    >
      <div className={classes.content}>
        <Grid container spacing={4}>
          {institute?.isTrial && (
            <Grid item xs={12} md={6}>
              <TrialConfigCard
                isForAdmin={false}
                draftIsTrial={institute?.isTrial ?? false}
                draftExpiresOn={institute?.expiresOn}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <FeaturesCard isForAdmin={false} draftFeatures={institute?.settings?.features} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DocumentsCard />
          </Grid>
        </Grid>
      </div>
    </ScreenBase>
  );
};

export default InstituteScreen;

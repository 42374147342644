import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useAssetsQuery } from 'screens/Environment/lib/queries';
import AssetsTable from 'screens/Universe/lib/components/AssetsTable';
import { idArrayType } from 'utils/types';

const UniverseTable = ({ draftAssetIds, dispatchDraftAssetIds, disabled }) => {
  const { assets } = useAssetsQuery();
  const availableAssetIds = useMemo(() => {
    return Object.values(assets ?? {})
      .filter(asset => asset.isQualityCheckPassed)
      .map(asset => asset.id);
  }, [assets]);
  return (
    <AssetsTable
      dispatchDraftAssetIds={dispatchDraftAssetIds}
      availableAssetIds={availableAssetIds}
      draftAssetIds={draftAssetIds}
      disabled={disabled}
      translatePath='universeScreen'
    />
  );
};

UniverseTable.propTypes = {
  draftAssetIds: idArrayType.isRequired,
  dispatchDraftAssetIds: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

UniverseTable.defaultProps = {
  disabled: false,
};

export default UniverseTable;

import { PermissionLevel } from 'lib/access/constants';
import useHasPermission from 'lib/access/useHasPermission';

/**
 *
 * @param resourceName {string}: name of resource from constants.PermissionResourceName
 * @param isGeneralRole
 * @returns {boolean}
 * CURRENTLY WORKS ONLY FOR OBJECTS, BELONGING TO INSTITUTE!
 * Returns True, if current user has CREATE permission for the object.
 */
const useCreatePermission = (resourceName, { isGeneralRole } = {}) => {
  return useHasPermission(resourceName, PermissionLevel.EDIT, isGeneralRole);
};

export default useCreatePermission;

import { Id, Ids, TranslateProps } from 'lib/types';
import { AssetGroups, AssetOverview, AssetOverviews } from 'screens/Environment/lib/types';
import {
  PortfolioAllocationType,
  USED_INDIVIDUAL_ALERT_TYPES,
} from 'screens/Portfolio/lib/constants';
import {
  Portfolio,
  PortfolioAlerts,
  PortfolioAllocationTypeT,
  PortfolioBase,
  PortfolioOverview,
  Weight,
} from 'screens/Portfolio/lib/types';

export function getTranslateIdTarget(isTargetRisk: boolean): string {
  return isTargetRisk ? 'CVAR' : 'return';
}

export function isPortfolioBusy(portfolio: any) {
  return portfolio?.busyDetails?.isBusy || portfolio?.busyDetails?.isBusyNew;
}

export function isPortfolioTracked(portfolio: PortfolioBase) {
  return portfolio?.isTracked;
}

export function getAlerts(portfolio: PortfolioOverview | undefined): PortfolioAlerts | undefined {
  return portfolio?.currentState?.alerts;
}

export function getMainAllocation(portfolio: Portfolio | undefined) {
  return portfolio?.currentState?.allocation;
}

export function getTargetAllocation(portfolio: Portfolio | undefined) {
  return portfolio?.targetAllocation;
}

export function getOptimAllocation(portfolio: Portfolio | undefined) {
  return portfolio?.optimizationState?.optimizationResult?.allocation;
}

export function getOptimNewAllocation(portfolio: Portfolio | undefined) {
  return portfolio?.newOptimizationState?.optimizationResult?.allocation;
}

export function hasOptimNewAllocation(portfolio: Portfolio) {
  return Boolean(getOptimNewAllocation(portfolio));
}

export function getNewStrategy(portfolio: Portfolio) {
  return portfolio.newOptimizationState?.strategy;
}

export function hasNewStrategy(portfolio: Portfolio) {
  return Boolean(getNewStrategy(portfolio));
}

export function getStrategy(portfolio: Portfolio) {
  return portfolio.optimizationState.strategy;
}

export function getOptimizationResult(portfolio: PortfolioOverview) {
  return portfolio?.optimizationState?.optimizationResult;
}

export function getMainWeights(portfolio: Portfolio | undefined) {
  return getMainAllocation(portfolio)?.weights;
}

export function getOptimWeights(portfolio: Portfolio | undefined) {
  return getOptimAllocation(portfolio)?.weights;
}

export function geTargetWeights(portfolio: Portfolio | undefined) {
  return getTargetAllocation(portfolio)?.weights;
}

export function getOptimNewWeights(portfolio: Portfolio | undefined) {
  return getOptimNewAllocation(portfolio)?.weights;
}

export function getWeights(
  portfolio: Portfolio | undefined,
  type: PortfolioAllocationTypeT | undefined
) {
  const typeToFunc = {
    [PortfolioAllocationType.CURRENT]: getMainWeights,
    [PortfolioAllocationType.OPTIMIZED]: getOptimWeights,
    [PortfolioAllocationType.TARGET]: geTargetWeights,
    [PortfolioAllocationType.OPTIMIZED_NEW]: getOptimNewWeights,
  };
  if (!type) {
    return undefined;
  }
  return typeToFunc[type](portfolio);
}

export type MissingAssets = {
  [type in typeof USED_INDIVIDUAL_ALERT_TYPES[number]]: Ids;
};

interface GetAlertsInfoReturn {
  alertCount: number;
  errorMessages: ReadonlyArray<TranslateProps>;
  missingAssets: MissingAssets;
}

export function getNumberOfAlerts(portfolio: PortfolioOverview) {
  let alertCount = 0;
  const alerts = portfolio.currentState?.alerts;
  if (alerts) {
    if (alerts.targetAlert) {
      alertCount += 1;
    }
    alertCount += alerts.groupAlerts.length;
    alertCount += alerts.individualAlerts.length;
  }
  return alertCount;
}

/**
 * Examines, which alerts are triggered and should be shown.
 * `alertCount` says how many alerts exist in Portfolio.
 */
export function getAlertsInfo(
  portfolio: PortfolioOverview | undefined,
  { assetGroups = undefined }: { assetGroups?: AssetGroups } = {}
): GetAlertsInfoReturn {
  let alertCount = 0;
  const errorMessages = [];
  const missingAssetsOfDifferentTypes: any = {};

  USED_INDIVIDUAL_ALERT_TYPES.forEach(alertType => {
    missingAssetsOfDifferentTypes[alertType] = [];
  });

  const alerts = getAlerts(portfolio);
  if (alerts) {
    if (alerts.groupAlerts.length > 0) {
      alertCount += 1;
      errorMessages.push({
        translateId: 'groupAlert',
        translateData: { howMany: alerts.groupAlerts.length },
      });
      if (assetGroups) {
        alerts.groupAlerts.forEach(alert => {
          errorMessages.push({
            translateId: alert.diff > 0 ? 'groupAlertDetailHigher' : 'groupAlertDetailLower',
            translateData: {
              assetGroup: assetGroups?.[alert.assetGroupId]?.name ?? alert.assetGroupId,
              weightDiff: `${(100 * alert.diff).toFixed(2)}%`,
            },
          });
        });
      }
    }
    if (alerts.individualAlerts.length > 0) {
      USED_INDIVIDUAL_ALERT_TYPES.forEach(alertType => {
        let individualAlertCount = 0;
        const missingAssetsIds: Id[] = [];
        alerts.individualAlerts.forEach(alert => {
          if (alert.typ === alertType) {
            individualAlertCount += 1;
            missingAssetsIds.push(alert.assetId);
          }
        });
        if (individualAlertCount > 0) {
          alertCount += 1;
          errorMessages.push({
            translateId: individualAlertCount === 1 ? alertType : `${alertType}-multiple`,
            translateData: { howMany: individualAlertCount },
          });
        }
        missingAssetsOfDifferentTypes[alertType] = missingAssetsIds;
      });
    }
    const { targetAlert } = alerts;
    if (targetAlert) {
      alertCount += 1;
      const translateIdTarget = targetAlert.isTargetRisk ? 'CVaR' : 'return';
      const translateId =
        targetAlert.diff > 0 ? `${translateIdTarget}Higher` : `${translateIdTarget}Lower`;
      const diffToShow = targetAlert.diff > 0 ? targetAlert.diff : -targetAlert.diff;
      errorMessages.push({
        translateId,
        translateData: {
          diff: `${(100 * diffToShow).toFixed(2)} %`,
          acceptedAmount: `${(100 * targetAlert.expectedValue).toFixed(2)} %`,
        },
      });
    }
  }
  return { alertCount, errorMessages, missingAssets: missingAssetsOfDifferentTypes };
}

// old method to remove
export function getBacktest(portfolio: any) {
  return portfolio?.backtests?.[0];
}

// old method to remove
export function hasBacktest(portfolio: any) {
  return Boolean(getBacktest(portfolio));
}

interface WeightInfo extends AssetOverview {
  weight: number;
}

export function transformToWeightInfo(
  assets: AssetOverviews | undefined,
  weights: ReadonlyArray<Weight> | undefined
) {
  if (!assets || !weights) {
    return [];
  }
  const data: WeightInfo[] = [];
  weights.forEach(weight => {
    const asset = assets[weight.assetId];
    if (asset) {
      data.push({
        ...asset,
        weight: weight.weight,
      });
    }
  });
  return data;
}

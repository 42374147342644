import util from 'util';

import { saveAs } from 'file-saver';
import { Parser } from 'json2csv';
import _ from 'lodash';
import neatCsv from 'neat-csv';

import { env } from 'lib/env';
import { isMonday, isSunday } from 'lib/timeSeries/tsData';
import { DEFAULT_CSV_DELIMITER } from 'utils/constants';

export function filterObj(obj, predicate) {
  const result = {};
  let key;

  for (key in obj) {
    if (predicate(obj[key])) {
      result[key] = obj[key];
    }
  }

  return result;
}

function sortByWeight(a, b) {
  if (a.weight < b.weight) {
    return 1;
  }
  if (a.weight > b.weight) {
    return -1;
  }
  return 0;
}

export function toCsv(data, options) {
  data.sort(sortByWeight);
  const json2csvParser = new Parser(options);
  if (options.exportFormatting) {
    const formattedData = data.map(obj =>
      _.mapValues(obj, (value, key) => {
        if (key in options.exportFormatting) {
          return options.exportFormatting[key](value);
        }
        return value;
      })
    );
    return json2csvParser.parse(formattedData);
  }
  return json2csvParser.parse(data);
}

export function triggerCsvDownload(data, fileName, options) {
  console.log('TABLE_DATA: ', data, fileName, options);
  const csv = toCsv(data, options);
  const csvBlob = new Blob([csv], { type: `text/csv;charset=utf-8,${encodeURI(csv)}` });
  saveAs(csvBlob, fileName);
}

export async function parseCsv(csv, afterParseFunc, options) {
  try {
    const data = await neatCsv(csv, options);
    afterParseFunc(data);
  } catch (error) {
    console.log(error.response);
  }
}

export async function parseCsvRealPortfolios(csv, afterParseFunc, options, id, assets, fileName) {
  const data = await neatCsv(csv, options);
  const formatedData = [];
  let dialogMessage = { missing: [], other: [] };
  let pieces;
  let instituteIdentifier;
  let execDate;
  let assetId;
  for (let i = 0; i < data.length; i += 1) {
    pieces = null;
    instituteIdentifier = null;
    execDate = null;
    assetId = null;
    pieces = data[i].pieces;
    instituteIdentifier = data[i].instituteIdentifier;
    if (data[i].date) {
      const myDate = new Date(data[i].date);
      execDate = myDate.toISOString().replace('Z', '+00:00');
    } else {
      const myDate = new Date();
      execDate = myDate.toISOString().replace('Z', '+00:00');
    }
    // eslint-disable-next-line guard-for-in
    for (const key in assets) {
      const asset = assets[key];
      if (asset.instituteIdentifier === instituteIdentifier) {
        assetId = asset.id;
      }
    }
    const transaction = {
      asset: assetId,
      executedOn: execDate,
      pieces,
    };
    if (assetId && pieces) {
      formatedData.push(transaction);
    } else if (!assetId) {
      dialogMessage.missing.push(instituteIdentifier);
    } else {
      dialogMessage.other.push(instituteIdentifier);
    }
  }
  if (
    (dialogMessage.missing === undefined || dialogMessage.missing.length === 0) &&
    (dialogMessage.other === undefined || dialogMessage.other.length === 0)
  ) {
    dialogMessage = null;
  }
  afterParseFunc(id, { positions: formatedData, name: fileName }, dialogMessage);
}

export function isProductionEnv() {
  return env.NODE_ENV === 'production';
}

export function isDevelopmentEnv() {
  return env.NODE_ENV === 'development';
}

export function getBuildEnvName() {
  const { REACT_APP_BUILD_ENV } = env;
  if (_.isNil(REACT_APP_BUILD_ENV)) {
    return 'local';
  }
  return REACT_APP_BUILD_ENV;
}

export function onFileUpload(e, onSuccessfulUpload) {
  const csv = e.target.result;
  const options = { separator: DEFAULT_CSV_DELIMITER };
  // const onSuccessfulUpload = (data) => { console.log(data); };
  parseCsv(csv, onSuccessfulUpload, options);
  // const availableAssetIds = checkAvailability(assets, uploadedIsins);
  // onSuccessfulUpload(availableAssetIds);
}

export function keysToLowerCase(obj) {
  return _.transform(obj, (result, val, key) => {
    // eslint-disable-next-line no-param-reassign
    result[key.toLowerCase()] = val;
  });
}

export function parseJSON(response) {
  return response.actions;
}

export function parseIdFromUrl(url) {
  let urlToSearch = url;
  if (url.slice(-1) === '/') {
    urlToSearch = url.slice(0, -1);
  }
  return urlToSearch.split('/').pop();
}

export function parseIdFromLocation(location) {
  return parseIdFromUrl(location.pathname);
}

// if array is given, returns same array, if other value is given, returns [value].
export function toArray(value) {
  let arr;
  if (!Array.isArray(value)) {
    arr = [value];
  } else {
    arr = value;
  }
  return arr;
}

export function toPercentageString(value, digits = 2) {
  return `${(value * 100).toFixed(digits)}%`;
}

export function toGermanNumberString(value, digits = 5) {
  return `${value.toFixed(digits)}`.replace('.', ',');
}

export function Enum(keys) {
  const enumeration = _.keyBy(keys, _.identity);
  return Object.freeze(enumeration);
}

export function isAnyEmpty(arrayOfCollections) {
  return arrayOfCollections.some(collection => _.isEmpty(collection));
}

export function isAnyNil(values) {
  return values.some(value => _.isNil(value));
}

export function isAnyEmptyOrNil(arrayOfCollections) {
  return isAnyEmpty(arrayOfCollections) || isAnyNil(arrayOfCollections);
}

export function getRandomNumberBetween(min, max, myGenerateRandomFunc) {
  const generateRandom = myGenerateRandomFunc || Math.random;
  return Math.floor(generateRandom() * (max - min) + min);
}

export function getRandomColor(myrandom) {
  return `hsl(${getRandomNumberBetween(0, 340, myrandom)},${getRandomNumberBetween(
    30,
    50,
    myrandom
  )}%,${getRandomNumberBetween(30, 50, myrandom)}%)`;
}

export class ColorPicker {
  static baseColors = [196, 110, 8, 340, 205, 185];

  currentIndex = 0;

  pickNext = () => {
    const color = ColorPicker.baseColors[this.currentIndex];
    this.currentIndex += 1;
    if (this.currentIndex >= ColorPicker.baseColors.length) {
      this.currentIndex = 0;
    }
    return color;
  };
}

export function inspect(obj) {
  return util.inspect(obj, { showHidden: false, depth: null });
}

/**
 * Get current UTC date (without time).
 */
export function getUTCDate() {
  const now = new Date();
  return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
}

export function getUTCDateYesterday() {
  const now = new Date();
  return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 1));
}

/**
 * Get previous business day.
 */
export function getUTCBusinessDateYesterday() {
  const now = new Date();
  let daysAgo = 1;
  if (isMonday(now)) {
    daysAgo = 3;
  } else if (isSunday(now)) {
    daysAgo = 2;
  }
  return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - daysAgo));
}

export function convertToUTCDate(dateString) {
  const date = new Date(dateString);
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
}

export function reverseString(str) {
  return str.split('').reverse().join('');
}

export function makeRandomName(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function makeRandomString() {
  return Math.random().toString(36).substr(2, 9);
}

export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  a.sort();
  b.sort();
  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

/**
 * Returns a copy of array with a given element, if it was not in array.
 * Returns a copy of array without a given element, if it was already in array.
 *
 * @param array
 * @param element
 */
export function switchElementInArray(array, element) {
  let newArray;
  if (array.includes(element)) {
    newArray = array.filter(item => item !== element);
  } else {
    newArray = [...array, element];
  }
  return newArray;
}

// returns copy with one object replaced (or added, if it did not exists)
// object is replaced if 'id' of it is the same
export function replaceObjectInArray(array, obj, idPropName = 'id') {
  let isReplaced = false;
  const newArray = array.map(el => {
    if (el[idPropName] !== obj[idPropName]) {
      return el;
    }
    isReplaced = true;
    return obj;
  });
  if (!isReplaced) {
    newArray.push(obj);
  }
  return newArray;
}

// returns copy with one object with given id removed (if object with such id exists)
export function removeObjectFromArray(array, objId, idPropName = 'id') {
  const newArray = [...(array ?? [])];
  _.remove(newArray, obj => obj[idPropName] === objId);
  return newArray;
}

// return a copy with one object updated (object is also a copy)
// object is updated if 'id' of it is the same
// if there is no object with same 'id' found in `array`, then no object is updated
export function updateObjectInArray(array, obj, idPropName = 'id') {
  return array.map(el => {
    if (el[idPropName] !== obj[idPropName]) {
      return el;
    }
    return { ...el, ...obj };
  });
}

export function addElementsToDict(dict, elements, idPropertyName = 'id') {
  if (_.isNil(elements)) {
    return dict;
  }
  const arrayToAdd = toArray(elements);
  if (arrayToAdd.length > 0) {
    const newDict = { ...dict };
    arrayToAdd.forEach(el => {
      newDict[el[idPropertyName]] = el;
    });
    return newDict;
  }
  return dict;
}

export function dictToArray(dict, idPropertyName) {
  return Object.keys(dict).map(id => {
    const dictValue = { ...dict[id] };
    if (idPropertyName) {
      dictValue[idPropertyName] = id;
    }
    return dictValue;
  });
}

export function createTypes(moduleName, constants) {
  const types = {};
  constants.forEach(constant => {
    types[constant] = `${moduleName}/${constant}`;
  });
  return types;
}

export function createAction(type) {
  return () => ({ type });
}

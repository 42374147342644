import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import MyDropdown from 'components/MyDropdown/MyDropdown';
import { createLocation } from 'components/MyRouting/utils';
import ObjectScreenBase from 'components/ObjectScreenBase';
import TopScreenButtons from 'components/TopScreenButtons';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import useEditPermission from 'lib/access/useEditPermission';
import { useDraft, useDraftList, useDraftString } from 'lib/misc/useDraft/useDraft';
import { useCashQuery } from 'screens/Environment/lib/queries';
import BasketTable from 'screens/Universe/BasketScreen/components/BasketTable';
import {
  useBasketQuery,
  useBasketsQuery,
  useEveryBasketMutation,
  useUniversesQuery,
} from 'screens/Universe/lib/queries';
import { Routes } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  dropdown: {
    marginTop: theme.spacing(2),
  },
  selectUniverseButton: {
    marginLeft: theme.spacing(12),
  },
  tablesContainer: {
    paddingLeft: theme.spacing(12),
  },
  searchMenu: {
    paddingLeft: theme.spacing(12),
  },
}));

const BasketScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id: currentBasketId } = useParams();
  const isNew = !currentBasketId;
  const { basket, isLoading: isLoadingBasket } = useBasketQuery(currentBasketId);
  const hasEditAccess = useEditPermission(basket);
  const hasCreateAccess = useCreatePermission(PermissionResourceName.BASKET);
  const hasAccess = isNew ? hasCreateAccess : hasEditAccess;
  const { cash } = useCashQuery();
  const { baskets } = useBasketsQuery();
  const { universes } = useUniversesQuery();
  const {
    createBasket,
    deleteBasket,
    updateBasket,
    isLoading: isLoadingMutation,
  } = useEveryBasketMutation();

  const { draft: draftName, setDraft: setDraftName } = useDraftString(basket?.name);
  const { draft: draftUniverseId, setDraft: setDraftUniverseId } = useDraft(basket?.universe);
  const { draft: draftAssetIds, dispatchDraft: dispatchDraftAssetIds } = useDraftList(
    basket?.assets
  );

  const basketDraft = { assets: draftAssetIds, name: draftName, universe: draftUniverseId };

  useEffect(() => {
    // put some universe as the first choice
    if (!draftUniverseId && universes && universes[Object.keys(universes)[0]] && isNew) {
      setDraftUniverseId(universes[Object.keys(universes)[0]].id);
    }
  }, [universes, draftUniverseId, isNew, setDraftUniverseId]);

  useEffect(() => {
    if (isNew && cash) {
      dispatchDraftAssetIds({ type: 'set', value: [cash.id] });
    }
  }, [isNew, dispatchDraftAssetIds, cash, draftUniverseId]);

  const onSaveClick = () => {
    if (isNew) {
      createBasket(basketDraft, {
        onSuccess: data => {
          history.push(createLocation(Routes.BASKET, data.id));
        },
      });
    } else {
      updateBasket({ id: currentBasketId, data: basketDraft });
    }
  };

  const onSaveNameClick = () => {
    updateBasket({ id: currentBasketId, data: { name: draftName } });
  };

  const onDeleteClick = () => {
    /*
    function handleOnDeleteBasketClick() {
    if (Object.keys(portfolios).length > 0 && basket) {
      if (basket.portfolioInfos.length > 0) {
        const error = makeErrorFromBasket(basket, portfolios);
        onCreateErrorMessage(error);
      } else {
        onDeleteBasketClick(basket);
      }
    }
  }
     */
    deleteBasket(currentBasketId, {
      onSuccess: () => {
        history.push(Routes.CHOOSE_BASKET);
      },
    });
  };

  return (
    <ObjectScreenBase
      disabled={isLoadingMutation}
      onSaveNameClick={onSaveNameClick}
      waitWhile={isLoadingBasket}
      object={basket}
      objects={baskets}
      draftObj={basketDraft}
      onSaveClick={onSaveClick}
      onDeleteClick={onDeleteClick}
      hasAccess={hasAccess}
      translationPath='basketScreen'
      propsToTriggerDialog={['assets', 'universe']}
      routeNew={Routes.BASKET_NEW}
      routeChoose={Routes.CHOOSE_BASKET}
      routeObject={Routes.BASKET}
      draftName={draftName}
      setDraftName={setDraftName}
    >
      <TopScreenButtons>
        {_.size(universes) > 1 ? (
          <MyDropdown
            className={classes.dropdown}
            value={draftUniverseId}
            onChange={setDraftUniverseId}
            onUpdateSearch={null}
            placeholderId='universe'
            disabled={!isNew}
            isNullAllowed={false}
          >
            {_.values(universes).map(universe => (
              <MenuItem key={universe.id} value={universe.id}>
                {universe.name}
              </MenuItem>
            ))}
          </MyDropdown>
        ) : null}
      </TopScreenButtons>
      <div className={classes.content}>
        {draftUniverseId ? (
          <Grid container>
            <Grid item xs={12}>
              <BasketTable
                basketId={currentBasketId}
                draftUniverseId={draftUniverseId}
                draftAssetIds={draftAssetIds}
                dispatchDraftAssetIds={dispatchDraftAssetIds}
                disabled={!hasAccess}
              />
            </Grid>
          </Grid>
        ) : null}
      </div>
    </ObjectScreenBase>
  );
};

export default BasketScreen;

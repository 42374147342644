import * as Sentry from '@sentry/react';
import React from 'react';
import StackTrace from 'stacktrace-js';

import MyDialog from 'components/MyDialog/MyDialog';
import { isDevelopmentEnv, isProductionEnv } from 'lib/misc/misc';
import { postError } from 'screens/Help/lib/queries';
import { ChildrenType } from 'utils/types';

const translationPath = 'globalErrorHandler';

class GlobalErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    let errorJson;
    if (error instanceof Error) {
      errorJson = { name: error.name, message: error.message };
    } else {
      errorJson = { name: error };
    }

    const callback = stackframes => {
      const stringifiedStack = stackframes.map(sf => sf.toString()).join('\n');
      // to us
      postError(errorJson, { componentStack: stringifiedStack });

      // to Sentry
      if (isProductionEnv()) {
        Sentry.withScope(scope => {
          scope.setExtra('componentStack', stringifiedStack);
          Sentry.captureException(error);
        });
      }
    };

    StackTrace.fromError(error).then(callback);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    const isDev = isDevelopmentEnv();
    if (hasError && !isDev) {
      // You can render any custom fallback UI
      return (
        <div>
          <MyDialog
            isOpen
            header={`${translationPath}.dialogHeader`}
            message={`${translationPath}.dialogMessage`}
            performKey={`${translationPath}.reloadButton`}
            onPerformClick={() => window.location.reload()}
          />
        </div>
      );
    }
    return children;
  }
}

GlobalErrorHandler.propTypes = {
  children: ChildrenType.isRequired,
};

GlobalErrorHandler.defaultProps = {};

export default GlobalErrorHandler;

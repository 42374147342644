import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import SimpleButton from 'components/SimpleButton';
import { Id } from 'lib/types';
import usePortfolioAllocationDropdowns from 'screens/Portfolio/lib/hooks/usePortfolioAllocationDropdowns';
import { PortfolioAllocationTypeT } from 'screens/Portfolio/lib/types';

const translationPath = 'portfolioScreen.portfolioAllocation.useAllocationToolbar' as const;

const useStyles = makeStyles(theme => ({
  allocationDropdownCompare: {
    paddingLeft: theme.spacing(4),
  },
}));

interface UseAllocationToolbarProps {
  portfolioId?: Id;
  allocationType?: PortfolioAllocationTypeT | PortfolioAllocationTypeT[];
  compareAllocationType?: PortfolioAllocationTypeT | PortfolioAllocationTypeT[];
}

const useAllocationToolbar = ({
  portfolioId = undefined,
  allocationType = undefined,
  compareAllocationType = undefined,
}: UseAllocationToolbarProps) => {
  const [isCompareOn, setIsCompareOn] = React.useState(false);
  const classes = useStyles();

  const { selectedPortfolioId, selectedAllocType, allocationTypeDropdownElem } =
    usePortfolioAllocationDropdowns({ portfolioId, allowedAllocationTypes: allocationType });

  const {
    selectedPortfolioId: selectedPortfolioIdCompare,
    selectedAllocType: selectedAllocTypeCompare,
    portfolioDropdownElem: portfolioDropdownCompareElem,
    allocationTypeDropdownElem: allocationTypeDropdownCompareElem,
  } = usePortfolioAllocationDropdowns({ allowedAllocationTypes: compareAllocationType });

  const handleCompareButton = () => {
    setIsCompareOn(true);
  };
  const handleExitCompareButton = () => {
    setIsCompareOn(false);
  };

  const Toolbar = () => (
    <Grid container alignItems='center'>
      <Grid item xs={6}>
        <div style={{ width: '100%' }}>{allocationTypeDropdownElem}</div>
      </Grid>
      <Grid item xs={6}>
        <div style={{ float: 'right' }}>
          <SimpleButton
            translateId={`${translationPath}.compareButton`}
            onClick={handleCompareButton}
            variant='midEmp'
          />
        </div>
      </Grid>
    </Grid>
  );

  const ToolbarCompare = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container alignItems='center'>
          <Grid item xs={4}>
            {allocationTypeDropdownElem}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container alignItems='center'>
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={6}>
                {portfolioDropdownCompareElem}
              </Grid>
              <Grid item xs={6}>
                <div className={classes.allocationDropdownCompare}>
                  {allocationTypeDropdownCompareElem}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <div style={{ float: 'right' }}>
              <SimpleButton
                translateId={`${translationPath}.exitCompareButton`}
                onClick={handleExitCompareButton}
                variant='midEmp'
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  return {
    selectedPortfolioId,
    selectedAllocType,
    isCompareOn,
    Toolbar: isCompareOn ? ToolbarCompare : Toolbar,
    selectedPortfolioIdCompare: isCompareOn ? selectedPortfolioIdCompare : undefined,
    selectedAllocTypeCompare: isCompareOn ? selectedAllocTypeCompare : undefined,
  };
};

export default useAllocationToolbar;

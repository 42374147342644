import { Card, CardContent } from '@mui/material';
import React from 'react';

import { Id } from 'lib/types';
import WeightsTable from 'lib/weights/WeightsTable';
import { useAlerts } from 'screens/Portfolio/lib/hooks/portfolio';
import usePortfolioAllocation from 'screens/Portfolio/lib/hooks/usePortfolioAllocation';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { PortfolioAllocationTypeT } from 'screens/Portfolio/lib/types';

interface WeightsTableCardProps {
  portfolioId?: Id;
  allocationType?: PortfolioAllocationTypeT;
  showAlerts?: boolean;
}

const WeightsTableCard = ({
  portfolioId,
  allocationType,
  showAlerts = false,
}: WeightsTableCardProps) => {
  const { portfolio } = usePortfolioQuery(portfolioId);
  const { missingAssets } = useAlerts(showAlerts ? portfolioId : undefined);
  const { weights, progressOrErrorElem, derivatives } = usePortfolioAllocation(
    portfolioId,
    allocationType
  );
  const content = (
    <WeightsTable
      weights={weights}
      portfolioName={portfolio?.name}
      allocationType={allocationType}
      missingAssets={showAlerts ? missingAssets : undefined}
      derivatives={derivatives}
      portfolioId={portfolioId}
      assetsToDerivativesIds={portfolio?.assetsToDerivativesIds ?? {}}
    />
  );

  return (
    <Card>
      <CardContent>
        <div>{progressOrErrorElem ?? content}</div>
      </CardContent>
    </Card>
  );
};

export default WeightsTableCard;

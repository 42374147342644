import { Card, CardContent, FormControlLabel, FormGroup, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import SimpleText from 'components/SimpleText';
import DatePicker from 'lib/dates/DatePicker';
import { useTranslate } from 'lib/localization/localization';
import { IsoDateTime } from 'lib/types';

const translationPath = 'adminInstituteScreen.trialConfigCard';

const useStyles = makeStyles(theme => ({
  section: {
    paddingTop: theme.spacing(8),
  },
  datePicker: {
    paddingLeft: theme.spacing(2),
  },
}));

interface TrialConfigCardProps {
  draftIsTrial: boolean;
  setDraftIsTrial?: (isTrial: boolean) => void;
  draftExpiresOn?: IsoDateTime | null;
  setDraftExpiresOn?: (expiresOn?: IsoDateTime) => void;
  isForAdmin?: boolean;
}

const TrialConfigCard = ({
  draftIsTrial,
  setDraftIsTrial,
  draftExpiresOn,
  setDraftExpiresOn,
  isForAdmin = false,
}: TrialConfigCardProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Card style={{ height: '100%' }}>
      <CardContent>
        <SimpleText translateId={`${translationPath}.title`} variant='h2' />
        <div className={classes.section}>
          <FormGroup row>
            {isForAdmin && (
              <FormControlLabel
                control={
                  <Switch
                    disabled={setDraftExpiresOn === undefined}
                    checked={draftIsTrial}
                    onChange={e => {
                      if (setDraftIsTrial) {
                        setDraftIsTrial(e.target.checked);
                      }
                    }}
                    color='primary'
                  />
                }
                labelPlacement='start'
                label={translate(`${translationPath}.isTrial`)}
              />
            )}
            <FormControlLabel
              control={
                draftIsTrial ? (
                  <div className={classes.datePicker}>
                    <DatePicker
                      disabled={!isForAdmin}
                      date={draftExpiresOn}
                      onDateChange={newDate => {
                        if (setDraftExpiresOn) {
                          setDraftExpiresOn(newDate);
                        }
                      }}
                    />
                  </div>
                ) : (
                  <SimpleText translateId={`${translationPath}.expiresNever`} />
                )
              }
              labelPlacement='start'
              label={translate(`${translationPath}.expiresOn`)}
            />
          </FormGroup>
        </div>
      </CardContent>
    </Card>
  );
};

export default TrialConfigCard;

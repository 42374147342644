import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

import ScreenBase from 'components/ScreenBase/ScreenBase';
import Table from 'components/Table';
import useTableDelete from 'components/Table/useTableDelete';
import TopScreenButtons from 'components/TopScreenButtons';
import { ClassesType, ReturnButtonPropsType, TableObjectsType } from 'utils/types';

// eslint-disable-next-line no-unused-vars
const styles = {
  tableMargins: {
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
};

class ChooseScreen extends React.Component {
  componentDidMount() {
    const { fetchObjectsFunc, onComponentDidMount } = this.props;
    if (fetchObjectsFunc) {
      fetchObjectsFunc();
    }
    if (onComponentDidMount) {
      onComponentDidMount();
    }
  }

  render() {
    const {
      translationPath,
      dialogTranslatePath,
      translateTableId,
      screenTitle,
      onNewClick,
      classes,
      hasNavButtons,
      tableObjects,
      waitWhile,
      returnButtonProps,
      topScreen,
      editPermissionOverride,
      onDeleteRowClick,
      ...other
    } = this.props;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { deletableRows, handleDeleteClick, deleteDialog } = useTableDelete({
      tableObjects,
      dialogTranslatePath,
      onDeleteRowClick,
      editPermissionOverride,
    });

    const navButtonsProps = [];
    if (onNewClick) {
      navButtonsProps.push({
        translateId: 'navButton.new',
        onClick: onNewClick,
        variant: 'highEmp',
        'pendo-id': 'lib-choose-screen-new-button',
      });
    }

    return (
      <ScreenBase
        waitWhile={waitWhile}
        title={screenTitle}
        titleTranslationId={`${translationPath}.title`}
        navButtonsProps={hasNavButtons ? navButtonsProps : []}
        returnButtonProps={returnButtonProps}
      >
        {deleteDialog}
        {topScreen && <TopScreenButtons>{topScreen}</TopScreenButtons>}
        <div className={classes.tableMargins}>
          <Table
            isWithCheckbox={false}
            tableObjects={tableObjects}
            deletableRows={onDeleteRowClick ? deletableRows : false}
            translateTableId={translationPath ? `${translationPath}.columns` : translateTableId}
            onDeleteRowClick={onDeleteRowClick ? handleDeleteClick : undefined}
            isDownloadButton={false}
            {...other}
          />
        </div>
      </ScreenBase>
    );
  }
}

ChooseScreen.propTypes = {
  // if translationPath is provided, then it will be used for all translateIds
  // translationPath.title for `screenTitle` and translationPath.columns for `translateTableId`
  topScreen: PropTypes.node,
  dialogTranslatePath: PropTypes.string,
  translationPath: PropTypes.string,
  translateTableId: PropTypes.string,
  tableObjects: TableObjectsType.isRequired,
  screenTitle: PropTypes.string,
  fetchObjectsFunc: PropTypes.func,
  onNewClick: PropTypes.func,
  onDeleteRowClick: PropTypes.func,
  classes: ClassesType.isRequired,
  onComponentDidMount: PropTypes.func,
  hasNavButtons: PropTypes.bool,
  waitWhile: PropTypes.bool,
  returnButtonProps: ReturnButtonPropsType,
  editPermissionOverride: PropTypes.bool,
};

ChooseScreen.defaultProps = {
  dialogTranslatePath: undefined,
  onDeleteRowClick: undefined,
  editPermissionOverride: undefined,
  onNewClick: undefined,
  topScreen: undefined,
  translationPath: null,
  translateTableId: null,
  screenTitle: null,
  onComponentDidMount: null,
  hasNavButtons: true,
  fetchObjectsFunc: null,
  waitWhile: false,
  returnButtonProps: undefined,
};
export default withStyles(styles, { withTheme: true })(ChooseScreen);

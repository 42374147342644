import { useRouteMatch } from 'react-router-dom';

import { Id } from 'lib/types';
import { Routes } from 'utils/constants';

export const useMatchNewPortfolioRoute = () => {
  return useRouteMatch({ path: Routes.NEW_PORTFOLIO, exact: true });
};

export const useMatchPortfolioRoute = () => {
  const match = useRouteMatch<{ id: Id }>(Routes.PORTFOLIO);
  return match?.params?.id;
};

import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useActiveLanguage } from 'lib/localization/localization';
import useLocalStorage from 'lib/misc/useLocalStorage';
import { Id } from 'lib/types';
import { getAvailableInstituteIds } from 'screens/Institute/lib/helpers';
import { useInstituteQuery } from 'screens/Institute/lib/queries';
import { useUserSelfQuery } from 'screens/Login/lib/queries';
import { useUserGroupsQuery } from 'screens/UserGroup/lib/queries';
import { Routes } from 'utils/constants';
import { identifyUserForExtensions } from 'utils/extensions';

export const useCurrentInstitute = () => {
  const { user } = useUserSelfQuery();
  const { userGroups } = useUserGroupsQuery();
  const activeLanguage = useActiveLanguage();
  const history = useHistory();
  const [instituteId, setValue] = useLocalStorage<Id | undefined>('currentInstituteId', undefined);
  const { institute, isLoading } = useInstituteQuery(instituteId);

  const setInstituteId = useCallback(
    id => {
      setValue(id);
      history.push(Routes.CHOOSE_PORTFOLIO);
    },
    [history, setValue]
  );

  useEffect(() => {
    if (user && instituteId) {
      identifyUserForExtensions(user, instituteId, activeLanguage);
    }
  }, [instituteId, user, activeLanguage]);

  useEffect(() => {
    const availableInstituteIds = getAvailableInstituteIds(user, userGroups);
    // If there is no instituteId set, or
    // if existing instituteId is not among institutes, to which user has access,
    // then set instituteId to the first of available institutes.
    if (!instituteId || !availableInstituteIds.includes(instituteId)) {
      if (availableInstituteIds[0]) {
        setInstituteId(availableInstituteIds[0]);
      }
    }
  }, [user, userGroups, instituteId, setInstituteId]);

  const instituteIdReturn = instituteId as Id;
  return {
    institute,
    instituteId: instituteIdReturn,
    setInstituteId,
    isLoading,
  };
};

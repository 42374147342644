import { Cancel } from '@mui/icons-material';
import { Toolbar, IconButton, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

import PositionMe from 'components/PositionMe';
import RestToRight from 'components/RestToRight';
import SimpleText from 'components/SimpleText';
import ConfigurationBottomToolbar from 'screens/Report/ReportScreen/components/ConfigurationBottomToolbar';
import { ChildrenType } from 'utils/types';

const useStyles = makeStyles(theme => ({
  content: {
    position: 'fixed',
    height: '85vh',
    width: '355px',
    backgroundColor: '#FFFF',
    right: '0',
    bottom: '10px',
    boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)',
    zIndex: '100',
    borderRadius: theme.shape.borderRadius,
  },
}));

const ConfigurationFrame = ({ closeAction, saveAction, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <Toolbar style={{ marginBottom: '0.7rem' }}>
        <SimpleText translateId='dashboard.configuration' variant='h5' />
        <RestToRight />
        <IconButton onClick={closeAction} size='large'>
          <Cancel color='action' size='small' />
        </IconButton>
      </Toolbar>
      <Container fixed>
        {children}
        <PositionMe howHorizontal='right' howVertical='bottom'>
          <ConfigurationBottomToolbar onCancelAction={closeAction} onSaveAction={saveAction} />
        </PositionMe>
      </Container>
    </div>
  );
};

ConfigurationFrame.propTypes = {
  closeAction: PropTypes.func.isRequired,
  saveAction: PropTypes.func.isRequired,
  children: ChildrenType.isRequired,
};

export default ConfigurationFrame;

import MenuItem from '@mui/material/MenuItem';
import React, { useMemo } from 'react';

import MyDropdown from 'components/MyDropdown/MyDropdown';
import { getAvailableInstituteIds } from 'screens/Institute/lib/helpers';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';
import { useInstitutesQuery } from 'screens/Institute/lib/queries';
import { useUserSelfQuery } from 'screens/Login/lib/queries';
import { useUserGroupsQuery } from 'screens/UserGroup/lib/queries';

const InstituteDropdown = () => {
  const { user } = useUserSelfQuery();
  const { userGroups } = useUserGroupsQuery();
  const { institutes } = useInstitutesQuery();
  const { instituteId, setInstituteId } = useCurrentInstitute();
  const availableInstitutes = useMemo(
    () => getAvailableInstituteIds(user, userGroups),
    [user, userGroups]
  );

  if (availableInstitutes.length <= 1) {
    return null;
  }

  return (
    <MyDropdown
      value={instituteId}
      onChange={v => {
        setInstituteId(v);
      }}
      placeholderId='institute'
      isNullAllowed={false}
    >
      {availableInstitutes.map(id => (
        <MenuItem
          key={id}
          value={id}
          // disabled={itemDisabled(assetAllocation.id)}
        >
          {institutes[id] ? institutes[id].name : id}
        </MenuItem>
      ))}
    </MyDropdown>
  );
};

export default InstituteDropdown;

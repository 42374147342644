import { Card, CardActions, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import MyProgress from 'components/MyProgress';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import Table from 'components/Table/TableView';
import { useTabs } from 'lib/navigation/navigation';
import { Id } from 'lib/types';
import { useAssetsQuery } from 'screens/Environment/lib/queries';
import { AssetOverviews } from 'screens/Environment/lib/types';
import { PORTFOLIO_TAB_NAMES, PortfolioTabName } from 'screens/Portfolio/lib/constants';
import {
  getMainWeights,
  getOptimWeights,
  isPortfolioTracked,
  transformToWeightInfo,
} from 'screens/Portfolio/lib/helpers';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { Weight } from 'screens/Portfolio/lib/types';
import { CARD_MIN_HEIGHT } from 'screens/Portfolio/PortfolioScreen/components/PortfolioOverviewTab/cards/constants';
import { Order, Routes } from 'utils/constants';

const translationPath = 'portfolioScreen.portfolioOverview.topHoldingsCard' as const;

const useStyles = makeStyles({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    minHeight: CARD_MIN_HEIGHT,
    paddingTop: '0 !important',
    flexGrow: 1,
  },
});

const columns = ['isin', 'name', 'weight'];

function computeTopWeightInfos(
  assets: AssetOverviews | undefined,
  weights: ReadonlyArray<Weight> | undefined
) {
  const weightInfos = transformToWeightInfo(assets, weights);
  return weightInfos.sort((a, b) => b.weight - a.weight).slice(0, 5);
}

const TopHoldingsCard = ({ portfolioId }: { portfolioId: Id }) => {
  const classes = useStyles();
  const history = useHistory();
  const { portfolio, isLoading } = usePortfolioQuery(portfolioId);
  const { assets, isLoading: isLoadingAssets } = useAssetsQuery();
  const { onTabChange } = useTabs(PORTFOLIO_TAB_NAMES);
  const weights = getMainWeights(portfolio) || getOptimWeights(portfolio);
  const tableObjects = useMemo(() => computeTopWeightInfos(assets, weights), [assets, weights]);
  const cellFormatting = {
    weight: (value: number) => `${(100 * value).toFixed(2)} %`,
  };
  const titleTranslationId =
    !portfolio || isPortfolioTracked(portfolio)
      ? `${translationPath}.title`
      : `${translationPath}.titleOptimized`;
  const handleViewMoreClick = () => {
    history.push(generatePath(Routes.PORTFOLIO, { id: portfolioId }));
    onTabChange(PortfolioTabName.ALLOCATION);
  };

  if (isLoading || isLoadingAssets || !portfolio) {
    return (
      <Card>
        <CardContent className={classes.content}>
          <SimpleText translateId={titleTranslationId} variant='h2' />
          <MyProgress />
        </CardContent>
      </Card>
    );
  }
  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Table
            titleTranslateId={titleTranslationId}
            hasPagination={false}
            translateTableId='weightsTable'
            tableObjects={tableObjects}
            columns={columns}
            showEmptyRows={false}
            isTableWhite
            initialOrderBy='weight'
            initialOrder={Order.DESC}
            onRowClick={() => {}}
            cellFormatting={cellFormatting}
          />
        </CardContent>
        <CardActions>
          <SimpleButton
            translateId={`${translationPath}.viewMore`}
            onClick={handleViewMoreClick}
            variant='lowEmp'
          />
        </CardActions>
      </Card>
    </>
  );
};

export default TopHoldingsCard;

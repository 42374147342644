import { SmallNotification } from 'utils/constants';

export const IndividualAlertType = {
  EACH: 'EACH',
  INDIVIDUAL: 'INDIVIDUAL',
  BASKET: 'BASKET',
  UNIVERSE: 'UNIVERSE',
  RESEARCH: 'RESEARCH',
} as const;

export const USED_INDIVIDUAL_ALERT_TYPES = [
  IndividualAlertType.UNIVERSE,
  IndividualAlertType.BASKET,
  IndividualAlertType.RESEARCH,
] as const;

export const PortfolioAction = {
  TRACK: 'TRACK',
  REBALANCE: 'REBALANCE',
  CANCEL_TRACK: 'CANCEL_TRACK',
  DISCARD_NEW: 'DISCARD_NEW',
  APPLY_NEW: 'APPLY_NEW',
  APPLY_CLOSEST: 'APPLY_CLOSEST',
  APPLY_CLOSEST_NEW: 'APPLY_CLOSEST_NEW',
  SAVE: 'SAVE',
  SAVE_AND_TRACK: 'SAVE_AND_TRACK',
  REOPTIMIZE: 'REOPTIMIZE',
  REOPTIMIZE_NEW: 'REOPTIMIZE_NEW',
} as const;

export const PortfolioActionToNotification = {
  [PortfolioAction.TRACK]: SmallNotification.PORTFOLIO_TRACKED,
  [PortfolioAction.CANCEL_TRACK]: SmallNotification.PORTFOLIO_UNTRACKED,
  [PortfolioAction.APPLY_NEW]: SmallNotification.NEW_PORTFOLIO_APPLIED,
  [PortfolioAction.DISCARD_NEW]: SmallNotification.NEW_PORTFOLIO_DISCARDED,
  [PortfolioAction.APPLY_CLOSEST]: SmallNotification.CLOSEST_PORTFOLIO_APPLIED,
  [PortfolioAction.APPLY_CLOSEST_NEW]: SmallNotification.CLOSEST_PORTFOLIO_APPLIED,
  [PortfolioAction.REBALANCE]: SmallNotification.ACTION_SENT,
  [PortfolioAction.SAVE]: SmallNotification.PORTFOLIO_CREATED,
  [PortfolioAction.SAVE_AND_TRACK]: SmallNotification.PORTFOLIO_CREATED,
  [PortfolioAction.REOPTIMIZE]: SmallNotification.ACTION_SENT,
  [PortfolioAction.REOPTIMIZE_NEW]: SmallNotification.ACTION_SENT,
} as const;

export const PortfolioTabName = {
  OVERVIEW: 'overview',
  STRATEGY: 'strategy',
  ALLOCATION: 'allocation',
  PERFORMANCE: 'performance',
  REBALANCE: 'rebalance',
} as const;

export const PORTFOLIO_TAB_NAMES = Object.values(PortfolioTabName);

export const PortfolioAllocationType = {
  CURRENT: 'CURRENT',
  OPTIMIZED: 'OPTIMIZED',
  OPTIMIZED_NEW: 'OPTIMIZED_NEW',
  TARGET: 'TARGET',
} as const;

export const OptimPortfolioAllocationTypes = [
  PortfolioAllocationType.OPTIMIZED,
  PortfolioAllocationType.OPTIMIZED_NEW,
];

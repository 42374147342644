import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'babel-polyfill';
import './static/styles/styles.scss';
import 'typeface-roboto';
import 'typeface-raleway';
import 'typeface-open-sans';

import 'react-grid-layout/css/styles.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-resizable/css/styles.css';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';

import 'react-universal-hooks';
import Root from 'components/Global/Root';
import { initSentry } from 'utils/extensions';

initSentry();

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//  async onUpdate() {
//    const bc = new BroadcastChannel('app-update');
//    bc.postMessage('App has updated.');
//  },
// });
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

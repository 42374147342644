import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, Paper, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';

import SimpleText from 'components/SimpleText';
import { THIRD_LEVEL_HEIGHT } from 'screens/Analysis/lib/constants';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    padding: '1rem',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    position: 'relative',
  },
  title: {
    color: theme.palette.primary.dark,
    textAlign: 'center',
  },
  subTitle: {
    color: '#d9dada',
    textAlign: 'center',
  },
  numberItem: {
    color: theme.palette.primary.dark,
    textAlign: 'center',
  },
  tooltipIcon: {
    position: 'absolute',
    right: '0',
    top: '0',
    marginTop: '0.7rem',
    marginRight: '0.7rem',
    padding: '0',
    zIndex: 10,
    color: '#a9aaaa',
  },
}));

function toFixed(value, isPercentage) {
  if (isPercentage) {
    return `${(value * 100).toFixed(2)}%`;
  }
  return `${value.toFixed(2)}`;
}

const RatioItem = ({ title, portfolio, benchmark, subTitle, isPercentage, hasTooltip }) => {
  const classes = useStyles();

  let show = true;
  let xs;
  if (portfolio && benchmark) {
    xs = 6;
  } else if (portfolio || benchmark) {
    xs = 12;
  } else {
    show = false;
    xs = 12;
  }
  return (
    <Grid item md={4} lg={4} xl={2} style={{ minHeight: THIRD_LEVEL_HEIGHT }}>
      <Paper className={classes.wrapper}>
        {hasTooltip && (
          <div className={classes.tooltipIcon}>
            <Tooltip title={<Translate id={`analysis.ratio.tooltip.${title}`} />}>
              <InfoOutlinedIcon />
            </Tooltip>
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <SimpleText
            className={classes.title}
            translateId={!subTitle ? `analysis.ratio.title.${title}` : null}
            text={subTitle ? title : null}
            variant='h6'
          />
          {subTitle && <SimpleText className={classes.subTitle} text={subTitle} variant='h7' />}
        </div>
        <Grid container direction='row' justifyContent='center' alignItems='center' style={{}}>
          {show ? (
            <>
              {portfolio && (
                <Grid item xs={xs} className={classes.numberItem}>
                  <SimpleText text={toFixed(portfolio, isPercentage)} variant='h6' />
                  <Typography variant='overline'>
                    <Translate id='analysis.portfolio' />
                  </Typography>
                </Grid>
              )}

              {benchmark && (
                <Grid item xs={xs} className={classes.numberItem}>
                  <SimpleText text={toFixed(benchmark, isPercentage)} variant='h6' />
                  <Typography variant='overline'>
                    <Translate id='analysis.benchmark' />
                  </Typography>
                </Grid>
              )}
            </>
          ) : (
            <Grid item xs={xs} className={classes.numberItem}>
              <Typography variant='overline'>
                <Translate id='analysis.valueUndefined' />
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

RatioItem.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  portfolio: PropTypes.number.isRequired,
  benchmark: PropTypes.number.isRequired,
  isPercentage: PropTypes.number,
  hasTooltip: PropTypes.bool,
};

RatioItem.defaultProps = {
  subTitle: '',
  isPercentage: false,
  hasTooltip: false,
};

export default RatioItem;

import { useMemo } from 'react';

import { Id } from 'lib/types';
import { useAssetGroupsQuery } from 'screens/Environment/lib/queries';
import { PortfolioAllocationType } from 'screens/Portfolio/lib/constants';
import { getAlertsInfo, getWeights } from 'screens/Portfolio/lib/helpers';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { PortfolioAllocationTypeT } from 'screens/Portfolio/lib/types';
import { useIsOptimJobDone } from 'screens/Task/lib/hooks/useJobStatus';

export const useOptimizationState = (
  portfolioId: Id | undefined,
  allocationType: PortfolioAllocationTypeT | undefined
) => {
  const { portfolio, isLoading } = usePortfolioQuery(portfolioId);
  const optimizationState = useMemo(() => {
    if (allocationType === PortfolioAllocationType.OPTIMIZED) {
      return portfolio?.optimizationState;
    }
    if (allocationType === PortfolioAllocationType.OPTIMIZED_NEW) {
      return portfolio?.newOptimizationState;
    }
    return undefined;
  }, [allocationType, portfolio]);
  return {
    optimizationState,
    isLoading,
  };
};

export const useIsClosest = (
  portfolioId: Id | undefined,
  allocationType: PortfolioAllocationTypeT | undefined
) => {
  const { optimizationState, isLoading } = useOptimizationState(portfolioId, allocationType);
  const isClosest = optimizationState?.optimizationResult?.isClosest ?? false;
  return { isClosest, isLoading };
};

export const useIsOutdated = (
  portfolioId: Id | undefined,
  allocationType: PortfolioAllocationTypeT | undefined
) => {
  const { optimizationState, isLoading } = useOptimizationState(portfolioId, allocationType);
  const isOutdated = optimizationState?.optimizationResult?.isOutdated ?? false;
  const outdatedOn = optimizationState?.optimizationResult?.outdatedOn;
  return { isOutdated, isLoading, outdatedOn };
};

export const useOptimizationError = (
  portfolioId: Id | undefined,
  allocationType: PortfolioAllocationTypeT | undefined
) => {
  const { optimizationState, isLoading } = useOptimizationState(portfolioId, allocationType);
  const optimizationError = useMemo(() => {
    if (optimizationState?.optimizationResult !== undefined) {
      return {
        isError: optimizationState.optimizationResult.isError,
        errorMsg: optimizationState.optimizationResult.errorMsg,
      };
    }
    return {
      isError: false,
      errorMsg: null,
    };
  }, [optimizationState?.optimizationResult]);
  return { ...optimizationError, isLoading };
};

export const useOptimizationInfo = (
  portfolioId: Id | undefined,
  allocationType: PortfolioAllocationTypeT | undefined
) => {
  const { isClosest, isLoading: isLoadingClosest } = useIsClosest(portfolioId, allocationType);
  const {
    isError,
    errorMsg,
    isLoading: isLoadingError,
  } = useOptimizationError(portfolioId, allocationType);
  const { jobIsDone, isLoading: isLoadingJob } = useIsOptimJobDone(portfolioId, allocationType);
  const {
    isOutdated,
    isLoading: isLoadingOutdated,
    outdatedOn,
  } = useIsOutdated(portfolioId, allocationType);
  return {
    isClosest,
    isOutdated,
    outdatedOn,
    isError,
    errorMsg,
    jobIsDone,
    isLoading: isLoadingClosest || isLoadingError || isLoadingJob || isLoadingOutdated,
  };
};

export const useAlerts = (portfolioId: Id | undefined) => {
  const { portfolio, isLoading } = usePortfolioQuery(portfolioId);
  const { assetGroups, isLoading: isLoadingGroups } = useAssetGroupsQuery();
  const alertsInfo = useMemo(() => {
    return getAlertsInfo(portfolio, { assetGroups });
  }, [assetGroups, portfolio]);
  return { ...alertsInfo, isLoading: isLoading || isLoadingGroups };
};

export const useWeights = (
  portfolioId: Id | undefined,
  allocationType: PortfolioAllocationTypeT | undefined
) => {
  const { portfolio, isLoading } = usePortfolioQuery(portfolioId);
  const weights = getWeights(portfolio, allocationType);
  return { weights, isLoading };
};

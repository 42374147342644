import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';

import ContributionTableViewCell from 'screens/Analysis/AnalysisScreen/widgets/ContributionTable/ContributionTableViewCell';
import { TableObjectsType } from 'utils/types';

const useStyles = makeStyles(theme => ({
  headingFont: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    textAlign: 'left',
  },
}));

const ContributionTableView = ({ columns, tableObjects, cellFormatting, showBenchmark }) => {
  const classes = useStyles();

  return (
    <TableContainer
      style={{
        marginTop: '1.3rem',
        height: '90%',
      }}
    >
      <Table stickyHeader size='small' aria-label='a dense weights table'>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'background.paper' }}>
            {columns.map(column => (
              <TableCell align='right' key={column} className={classes.headingFont}>
                <Translate id={`analysis.${column}`} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableObjects.map(row => {
            if (row.avWeight === 0 && row.avWeightB === 0) {
              return null;
            }
            return (
              <TableRow key={nanoid()}>
                {columns.map((column, i) => (
                  <ContributionTableViewCell
                    key={column}
                    cellFormatting={cellFormatting[column]}
                    value={row[column]}
                    benchmarkValue={i > 0 && showBenchmark ? row[`${column}B`] : null}
                  />
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ContributionTableView.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableObjects: TableObjectsType.isRequired,
  cellFormatting: PropTypes.objectOf(PropTypes.func),
  showBenchmark: PropTypes.bool,
};

ContributionTableView.defaultProps = {
  cellFormatting: {},
  showBenchmark: true,
};
export default ContributionTableView;

import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React from 'react';

import SimpleButton from 'components/SimpleButton';

const useStyles = makeStyles({
  button: {
    height: '2.5rem',
  },
});

interface DialogButtonProps {
  [x: string]: any;
  className?: string;
}

const DialogButton = ({ className, ...other }: DialogButtonProps) => {
  const classes = useStyles();
  // @ts-ignore
  return <SimpleButton className={classNames(classes.button, className)} {...other} />;
};

export default DialogButton;

import { ResourceName } from 'lib/constants';

export const FunctionalityName = {
  REPORTING: 'reporting',
  ANALYSIS: 'analysis',
  BACKTEST: 'backtest',
  REAL: 'real',
  RESEARCH: 'research',
  SHARING: 'sharing',
  DOUBLE_ALERT: 'double_alert',
  CERTIFICATES: 'certificates',
  ONLY_RISK_TARGET: 'only_risk_target',
} as const;

export const PermissionAccessLevel = {
  OWN: 'OWN',
  ANY: 'ANY',
  NO: 'NO',
} as const;

export const PermissionLevel = {
  NO: 'NO',
  READ: 'READ',
  EDIT: 'EDIT',
  ADMIN: 'ADMIN',
} as const;

export const PERMISSION_LEVEL_HIERARCHY = [
  PermissionLevel.READ,
  PermissionLevel.EDIT,
  PermissionLevel.ADMIN,
] as const;

export const PERMISSION_LEVEL_HIERARCHY_FULL = [
  PermissionLevel.NO,
  ...PERMISSION_LEVEL_HIERARCHY,
] as const;

export const PermissionResourceName = ResourceName;

import { KeyboardArrowDown } from '@mui/icons-material';
import { IconButton, Typography, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';

const WidgetTitleSelector = ({
  options,
  title,
  setTitle,
  disableSelection,
  omitMargin,
  mainTitle,
}) => {
  const [menuEl, setMenuEl] = useState(null);

  return (
    <>
      <Typography
        variant='h6'
        component='span'
        style={!omitMargin ? { marginLeft: '0.8rem' } : null}
      >
        <Translate id={`analysis.mainTitle.${mainTitle}`} />
        {title && ' '}
        {title && <Translate id={`analysis.title.${title}`} />}
        {!disableSelection && (
          <IconButton
            onClick={e => setMenuEl(e.currentTarget)}
            style={{ padding: '0', marginTop: '0.3rem' }}
            size='large'
          >
            <KeyboardArrowDown
              fontSize='small'
              aria-controls='title-selection'
              aria-haspopup='true'
            />
          </IconButton>
        )}
      </Typography>

      <Menu
        id='title-selection'
        anchorEl={menuEl}
        open={Boolean(menuEl)}
        onClose={() => setMenuEl(null)}
      >
        {options.map(item => (
          <MenuItem
            key={item}
            onClick={() => {
              setTitle(item);
              setMenuEl(null);
            }}
          >
            <Translate id={`analysis.title.${item}`} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

WidgetTitleSelector.propTypes = {
  mainTitle: PropTypes.string.isRequired,
  title: PropTypes.string,
  setTitle: PropTypes.func,
  disableSelection: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  omitMargin: PropTypes.bool,
};

WidgetTitleSelector.defaultProps = {
  title: null,
  setTitle: null,
  options: [],
  disableSelection: false,
  omitMargin: false,
};

export default WidgetTitleSelector;

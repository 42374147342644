import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

import PositionMe from '../../components/PositionMe';

import SimpleText from 'components/SimpleText';
import { HelpSubtopicType } from 'utils/types';

const useStyles = makeStyles(theme => ({
  logoFontCard: {
    width: '100%',
    boxShadow: '0 0 0 0',
    cursor: 'pointer',
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    height: '20rem',
  },

  picture: {
    marginTop: '1rem',
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle',
  },
  header: {
    marginTop: '1rem',
  },
  message: {
    margin: theme.spacing(8),
  },
  tableOfContents: {
    marginBottom: '0.2rem',
  },
}));

const HelpTopicCard = props => {
  const { picture, header, message, topicId, subTopics, setViewedHelpTopicId } = props;
  const classes = useStyles();

  return (
    <Card className={classes.logoFontCard} onClick={() => setViewedHelpTopicId(topicId)}>
      <PositionMe>
        <CardActionArea>
          <PositionMe>
            <CardMedia
              className={classes.picture}
              component='img'
              alt='Contemplative Reptile'
              src={picture}
              title={header}
            />
          </PositionMe>
          <CardContent>
            <SimpleText
              className={classes.header}
              text={header}
              variant='h2'
              fontWeight='bold'
              align='center'
            />
            <SimpleText
              className={classes.message}
              text={message}
              variant='subtitle1'
              align='center'
            />
          </CardContent>
          <Collapse in={false} timeout='auto' unmountOnExit>
            <CardContent>
              {subTopics.map(subtopic => (
                <SimpleText
                  key={subtopic.header}
                  className={classes.tableOfContents}
                  text={subtopic.header}
                  variant='subtitle1'
                />
              ))}
            </CardContent>
          </Collapse>
        </CardActionArea>
      </PositionMe>
    </Card>
  );
};

HelpTopicCard.propTypes = {
  topicId: PropTypes.string.isRequired,
  picture: PropTypes.string,
  header: PropTypes.string,
  message: PropTypes.string,
  subTopics: PropTypes.arrayOf(HelpSubtopicType).isRequired,
};

HelpTopicCard.defaultProps = {
  picture: null,
  header: null,
  message: null,
};

export default HelpTopicCard;

import React from 'react';

import { useDerivativesForPortfolioQuery } from '../queries';

import MyProgress from 'components/MyProgress';
import SimpleText from 'components/SimpleText';
import { Id } from 'lib/types';
import ClosestPortfolioWarning from 'screens/Portfolio/lib/components/ClosestPortfolioWarning';
import OutdatedPortfolioWarning from 'screens/Portfolio/lib/components/OutdatedPortfolioWarning';
import { useOptimizationInfo, useWeights } from 'screens/Portfolio/lib/hooks/portfolio';
import { isOptimAllocationType, PortfolioAllocationTypeT } from 'screens/Portfolio/lib/types';

const translationPath = 'portfolioLib.usePortfolioAllocation' as const;

const getErrorTranslateId = (
  portfolioId: Id | undefined,
  allocationType: PortfolioAllocationTypeT | undefined
) => {
  if (portfolioId === undefined) {
    return 'noPortfolioId';
  }
  if (allocationType === undefined) {
    return 'noAllocationType';
  }
  return 'weightsNotAvailable';
};

const usePortfolioAllocation = (
  portfolioId: Id | undefined,
  allocationType: PortfolioAllocationTypeT | undefined
) => {
  const { weights, isLoading: isLoadingWeights } = useWeights(portfolioId, allocationType);
  const { data: derivativesData } = useDerivativesForPortfolioQuery(portfolioId);
  const {
    isClosest,
    isOutdated,
    isError,
    errorMsg,
    jobIsDone,
    isLoading: isLoadingOptimInfo,
  } = useOptimizationInfo(portfolioId, allocationType);
  const progress = (
    <MyProgress
      translateId={`${translationPath}.${!jobIsDone ? 'runningOptimization' : 'loadingWeights'}`}
    />
  );
  let content: JSX.Element | undefined;
  if (isLoadingWeights || isLoadingOptimInfo || !jobIsDone) {
    content = progress;
  } else if (isError) {
    content = (
      <SimpleText
        color='error.main'
        variant='h3'
        translateId={`${translationPath}.optimizationError`}
        translateData={{ errorMsg }}
      />
    );
  } else if (weights === undefined) {
    content = (
      <SimpleText
        color='error.main'
        variant='h3'
        translateId={`${translationPath}.${getErrorTranslateId(portfolioId, allocationType)}`}
      />
    );
  }
  const outdatedWarningElem =
    isOptimAllocationType(allocationType) && isOutdated && jobIsDone ? (
      <OutdatedPortfolioWarning portfolioId={portfolioId} allocationType={allocationType} />
    ) : undefined;
  const closestWarningElem =
    isOptimAllocationType(allocationType) && isClosest && jobIsDone ? (
      <ClosestPortfolioWarning portfolioId={portfolioId} allocationType={allocationType} />
    ) : undefined;
  return {
    weights,
    isClosest,
    isOutdated,
    isError,
    isLoading: isLoadingWeights || isLoadingOptimInfo,
    progressOrErrorElem: content,
    outdatedWarningElem,
    closestWarningElem,
    derivatives: derivativesData?.derivatives ?? [],
  };
};

export default usePortfolioAllocation;

import { useMemo } from 'react';

import { adjustGroupsAccessForNewChanges, defineGroupsAccessTo } from 'lib/access/helpers';
import { useUserGroupsQuery } from 'screens/UserGroup/lib/queries';

/**
 *
 * @param object {object}: object (e.g. Basket/Portfolio), against which access is checked.
 * @param newPermissionLevels {object}: object of shape { 1: "EDIT", 2: "READ" }, where key
 * is ID of the User Group and value is PermissionLevel.
 * If this object is not empty, method assumes that PermissionLevels of given Groups are changed to
 * values, given in this object and adjust the return correspondingly.
 * @returns {{actualPermissionLevels, possiblePermissionLevelsByGroup}}
 * CURRENTLY WORKS ONLY FOR OBJECTS, BELONGING TO INSTITUTE!
 *
 * Returns {actualPermissionLevels, possiblePermissionLevelsByGroup}:
 *
 * - actualPermissionLevels of shape { 1: "READ", 2: "ADMIN" }, where key
 * is ID of the User Group and value is PermissionLevel, which is current highest PermissionLevel
 * for a given User Groups for this object.
 *
 * - possiblePermissionLevelsByGroup of shape { 1: ["NO"], 2: ["READ", "EDIT"] }, where key
 * is ID of the User Group and value is array of PermissionLevels. Access of the Group can be
 * possibly changed to one of these PermissionLevels.
 */
const useCheckAccessTo = (object, newPermissionLevels = {}) => {
  const { userGroups } = useUserGroupsQuery();

  const { actualPermissionLevels, possiblePermissionLevelsByGroup } = useMemo(
    () => defineGroupsAccessTo(object, userGroups),
    [object, userGroups]
  );

  const { adjustedActualPermissionLevels, adjustedPossiblePermissionLevelsByGroup } = useMemo(
    () =>
      adjustGroupsAccessForNewChanges(
        actualPermissionLevels,
        possiblePermissionLevelsByGroup,
        newPermissionLevels
      ),
    [actualPermissionLevels, possiblePermissionLevelsByGroup, newPermissionLevels]
  );

  return {
    actualPermissionLevels: adjustedActualPermissionLevels,
    possiblePermissionLevelsByGroup: adjustedPossiblePermissionLevelsByGroup,
  };
};

export default useCheckAccessTo;

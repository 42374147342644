import { Card, CardActions, CardContent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React from 'react';

import MyDropdown from 'components/MyDropdown/MyDropdown';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import { toDateString } from 'lib/dates/dateUtils';
import useStartEndDatePickers, {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE,
} from 'lib/dates/useStartEndDatePickers';
import { useTranslate } from 'lib/localization/localization';
import { findDiffOriginalAndDraft } from 'lib/misc/diff';
import { useDraftValue } from 'lib/misc/useDraft/useDraft';
import { Id } from 'lib/types';
import { RebalancingFrequency } from 'screens/Backtest/lib/constants';
import { useBacktestQuery } from 'screens/Backtest/lib/queries';
import { BacktestConfiguration, RebalancingFrequencyT } from 'screens/Backtest/lib/types';

const translationPath = 'backtestScreen.backtestConfig';

const useStyles = makeStyles(theme => ({
  section: {
    paddingTop: theme.spacing(8),
  },
  datePickerSection: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

interface RebalancingFrequencyDropdownProps {
  rebalancingFreq: RebalancingFrequencyT;
  onRebalancingFreqChange?: (rebalancingFreq: RebalancingFrequencyT) => void;
  disabled?: boolean;
}

const RebalancingFrequencyDropdown = ({
  rebalancingFreq,
  onRebalancingFreqChange,
  disabled = false,
}: RebalancingFrequencyDropdownProps) => {
  const translate = useTranslate();

  return (
    <MyDropdown
      // @ts-ignore
      disabled={disabled}
      value={rebalancingFreq}
      onChange={onRebalancingFreqChange}
      onUpdateSearch={null}
      translationPath={`${translationPath}.rebalancingFrequencyDropdown`}
      isNullAllowed={false}
    >
      {_.values(RebalancingFrequency).map(freq => (
        <MenuItem key={freq} value={freq}>
          {translate(`backtestLib.rebalancingFrequency.${freq}`)}
        </MenuItem>
      ))}
    </MyDropdown>
  );
};

interface BacktestConfigProps {
  backtestId: Id | undefined;
  disabled?: boolean;
  onClick?: (backtestConfigDraft: BacktestConfiguration) => void;
}

const BacktestConfig = ({ backtestId, disabled = false, onClick }: BacktestConfigProps) => {
  const classes = useStyles();
  const isNew = backtestId === undefined;
  const { backtest, isLoading: isLoadingBacktest } = useBacktestQuery(backtestId);
  const { draft: draftRebFreq, setDraft: setDraftRebFreq } = useDraftValue(
    backtest?.rebalancingFrequency ?? RebalancingFrequency.M3
  );
  const { draft: draftStartDate, setDraft: setDraftStartDate } = useDraftValue(
    backtest?.startDate ?? toDateString(DEFAULT_START_DATE)
  );
  const { draft: draftEndDate, setDraft: setDraftEndDate } = useDraftValue(
    backtest?.endDate ?? toDateString(DEFAULT_END_DATE)
  );
  const { startDatePickerElem, endDatePickerElem, quickSetElem } = useStartEndDatePickers({
    startDate: draftStartDate,
    endDate: draftEndDate,
    onEndDateChange: setDraftEndDate,
    onStartDateChange: setDraftStartDate,
    disabled: disabled || isLoadingBacktest,
  });

  const draft = {
    rebalancingFrequency: draftRebFreq,
    startDate: draftStartDate,
    endDate: draftEndDate,
  };
  const original = {
    rebalancingFrequency: backtest?.rebalancingFrequency,
    startDate: backtest?.startDate,
    endDate: backtest?.endDate,
  };
  const { isEqual } = findDiffOriginalAndDraft(original, draft);
  const saveButtonTranslateId = `${translationPath}.${isEqual ? 'rerunButton' : 'saveButton'}`;

  const handleClick = () => {
    if (onClick) {
      onClick(draft);
    }
  };
  return (
    <>
      <Card>
        <CardContent>
          <SimpleText translateId={`${translationPath}.title`} variant='h2' />
          <div className={classes.section}>
            <RebalancingFrequencyDropdown
              rebalancingFreq={draftRebFreq}
              onRebalancingFreqChange={setDraftRebFreq}
              disabled={disabled || isLoadingBacktest}
            />
          </div>
          <div className={classes.section}>
            <div className={classes.datePickerSection}>
              <SimpleText translateId={`${translationPath}.startDate`} variant='subtitle1' />
              {startDatePickerElem}
            </div>
            <div className={classes.datePickerSection}>
              <SimpleText translateId={`${translationPath}.endDate`} variant='subtitle1' />
              {endDatePickerElem}
            </div>
            <div className={classes.datePickerSection}>
              <SimpleText translateId={`${translationPath}.quickSet`} variant='subtitle1' />
              {quickSetElem}
            </div>
          </div>
        </CardContent>
        <CardActions>
          <SimpleButton
            translateId={isNew ? `${translationPath}.createButton` : saveButtonTranslateId}
            onClick={handleClick}
            variant='highEmp'
            disabled={disabled || isLoadingBacktest}
            pendo-id='backtest-backtest-config-save-run-button'
          />
        </CardActions>
      </Card>
    </>
  );
};

export default BacktestConfig;

import Badge from '@mui/material/Badge';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SimpleText from 'components/SimpleText';
import { ClassesType, TabsWithBadgeType } from 'utils/types';

const tabHeight = '52px';
const styles = theme => ({
  tab: {
    backgroundColor: 'inherit',
    height: tabHeight,
    minWidth: '9rem',
    '& .MuiBadge-anchorOriginTopRightRectangular': {
      color: theme.palette.error.main,
      backgroundColor: 'inherit',
    },
  },
  badge: {
    padding: theme.spacing(2),
  },
  customBadge: {
    backgroundColor: 'white',
    width: 19,
    height: 20,
    borderRadius: '50%',
    borderStyle: 'solid',
    border: theme.palette.primary[500],
    color: theme.palette.primary[500],
    borderWidth: 1,
  },
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
});
const MyTabs = ({
  classes,
  tabs,
  translationPath,
  tabsWithBadge,
  tabsVariant,
  tabVariant,
  badgeVariant,
  chosenTab,
  onChangeTab,
  className,
  textVariant,
  isSmall,
}) => (
  <Tabs
    value={chosenTab}
    onChange={onChangeTab}
    indicatorColor='primary'
    textColor='inherit'
    className={
      isSmall
        ? classNames(classes.tabsRoot, classes[tabsVariant])
        : classNames(className, classes[tabsVariant])
    }
  >
    {tabs &&
      tabs.map(tab => (
        <Tab
          key={tab}
          className={isSmall ? classes.tabRoot : classNames(className, classes[tabVariant])}
          label={
            <SimpleText
              className={classes[textVariant]}
              translateId={`${translationPath}.${tab}`}
              variant={isSmall ? 'body2' : 'h3'}
            />
          }
        />
      ))}
    {tabsWithBadge &&
      tabsWithBadge.map(tabWithBadge => (
        <Tab
          pendo-id={tabWithBadge['pendo-id'] ?? undefined}
          key={tabWithBadge.translateId}
          className={isSmall ? classes.tabRoot : classNames(className, classes[tabVariant])}
          label={
            <Badge
              classes={tabWithBadge.isComponent ? null : { badge: classes.customBadge }}
              badgeContent={tabWithBadge.badge}
              className={isSmall ? null : classes[badgeVariant]}
            >
              <SimpleText
                className={classes[textVariant]}
                translateId={`${translationPath || 'tabs'}.${tabWithBadge.translateId}`}
                variant={isSmall ? 'body2' : 'h3'}
              />
            </Badge>
          }
        />
      ))}
  </Tabs>
);

MyTabs.propTypes = {
  classes: ClassesType.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string),
  translationPath: PropTypes.string,
  tabsWithBadge: TabsWithBadgeType,
  tabsVariant: PropTypes.string,
  tabVariant: PropTypes.string,
  textVariant: PropTypes.string,
  badgeVariant: PropTypes.string,
  chosenTab: PropTypes.number.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
};

MyTabs.defaultProps = {
  translationPath: null,
  tabs: null,
  tabsWithBadge: null,
  tabVariant: 'tab',
  tabsVariant: '',
  textVariant: '',
  badgeVariant: 'badge',
  className: '',
  isSmall: false,
};

export default withStyles(styles, { withTheme: true })(MyTabs);

import { useRef } from 'react';

import { NAVBAR_HEIGHT_PX } from 'utils/constants';

const useScroll = () => {
  const scrollRef = useRef(null);
  const executeScroll = () => {
    if (scrollRef.current) {
      // @ts-ignore
      window.scrollTo({ top: scrollRef.current.offsetTop - NAVBAR_HEIGHT_PX, behavior: 'smooth' });
    }
  };
  return { scrollRef, executeScroll };
};

export default useScroll;

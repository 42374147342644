import { format } from 'date-fns';
import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import ChooseScreen from 'components/SearchTools/ChooseScreen';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import { useDeleteResearchListMutation, useResearchListsQuery } from 'screens/Research/lib/queries';
import { Routes } from 'utils/constants';

const columns = ['id', 'name', 'createdOn', 'updatedOn'];

const ChooseResearchListScreen = () => {
  const history = useHistory();
  const hasCreatePermission = useCreatePermission(PermissionResourceName.RESEARCH_LIST);

  const { researchLists, isLoading } = useResearchListsQuery();
  const { mutate: deleteObj } = useDeleteResearchListMutation();
  const cellFormatting = {
    createdOn: date => (date ? format(new Date(date), 'yyyy-MM-dd') : ''),
    updatedOn: date => (date ? format(new Date(date), 'yyyy-MM-dd') : ''),
  };

  return (
    <ChooseScreen
      dialogTranslatePath='researchListScreen.useSavePromptAndDialog'
      waitWhile={isLoading}
      key={columns}
      tableObjects={researchLists}
      columns={columns}
      searchColumns={columns}
      cellFormatting={cellFormatting}
      searchValue={null}
      translationPath='chooseResearchListScreen'
      isTableWhite
      onNewClick={hasCreatePermission ? () => history.push(Routes.NEW_RESEARCH_LIST) : null}
      onRowClick={id => history.push(generatePath(Routes.RESEARCH_LIST, { id }))}
      onDeleteRowClick={obj => {
        deleteObj(obj.id);
      }}
    />
  );
};

export default ChooseResearchListScreen;

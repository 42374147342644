import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ResourceName } from 'lib/constants';
import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import {
  get,
  post,
  selectListToDict,
  useDeleteMutationCommon,
  useUpdateMutationCommon,
} from 'lib/queries';
import { addError, addSmallNotification } from 'redux/notifications/actions';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';
import { userGroupsKeys } from 'screens/UserGroup/lib/queries';
import { SmallNotification } from 'utils/constants';

export const groupConstraintListKeys = {
  all: [{ scope: ResourceName.GROUP_CONSTRAINT_LIST }],
  lists: () => [{ ...groupConstraintListKeys.all[0], entity: 'list' }],
  list: ({ filter = 'all', instituteId }) => [
    { ...groupConstraintListKeys.lists()[0], filter, instituteId },
  ],
  details: () => [{ ...groupConstraintListKeys.all[0], entity: 'detail' }],
  detail: ({ id }) => [{ ...groupConstraintListKeys.details()[0], id }],
};

const baseUrl = 'group_constraint_lists/';

// transformConstraintsToArray(data)

async function getGroupConstraintList({ queryKey: [{ id }] }) {
  return get(`${baseUrl}${id}/`);
}

async function getGroupConstraintLists({ queryKey: [{ instituteId }] }) {
  return get(baseUrl, { 'institute-id': instituteId });
}

async function postGroupConstraintList(data, instituteId, { noPortfolioUpdate = false } = {}) {
  return post(baseUrl, { ...data, noPortfolioUpdate }, { 'institute-id': instituteId });
}

const selectPredefined = data => {
  return selectListToDict(data.filter(obj => obj.isPredefined));
};

export const useGroupConstraintListsQuery = ({ filter = 'all' } = {}) => {
  const { instituteId } = useCurrentInstitute();
  const query = useQuery(
    groupConstraintListKeys.list({ filter, instituteId }),
    getGroupConstraintLists,
    {
      select: filter === 'predefined' ? selectPredefined : selectListToDict,
    }
  );
  return {
    ...query,
    groupConstraintLists: query.data || {},
  };
};

export const useGroupConstraintListQuery = id => {
  const queryClient = useQueryClient();
  const { instituteId } = useCurrentInstitute();
  const query = useQuery(groupConstraintListKeys.detail({ id }), getGroupConstraintList, {
    enabled: !!id,
    initialData: () => {
      const objects = queryClient.getQueryData(groupConstraintListKeys.list({ instituteId }));
      if (objects) {
        const obj = objects.find(o => o.id === id);
        if (obj) {
          return obj;
        }
      }
      return undefined;
    },
  });
  return {
    ...query,
    groupConstraintList: query.data,
  };
};

export const useCreateGroupConstraintListMutation = ({ noPortfolioUpdate = false } = {}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { instituteId } = useCurrentInstitute();
  return useMutation(data => postGroupConstraintList(data, instituteId, { noPortfolioUpdate }), {
    onSuccess: data => {
      queryClient.setQueriesData(groupConstraintListKeys.lists(), prev => [...prev, data]);

      queryClient.setQueriesData([{ ...groupConstraintListKeys.details()[0], id: data.id }], data);
      queryClient.invalidateQueries(userGroupsKeys.all);
      dispatch(addSmallNotification(SmallNotification.GROUP_CONSTRAINT_CREATED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const useUpdateGroupConstraintListMutation = () => {
  return useUpdateMutationCommon(baseUrl, {
    notification: SmallNotification.GROUP_CONSTRAINT_SAVED,
    queryKeyLists: groupConstraintListKeys.lists(),
    queryKeyDetails: groupConstraintListKeys.details(),
  });
};

export const useDeleteGroupConstraintListMutation = () => {
  return useDeleteMutationCommon(baseUrl, {
    notification: SmallNotification.GROUP_CONSTRAINT_DELETED,
    queryKeyLists: groupConstraintListKeys.lists(),
    queryKeyDetails: groupConstraintListKeys.details(),
  });
};

export const useEveryGroupConstraintListMutation = () => {
  const createMutation = useCreateGroupConstraintListMutation();
  const updateMutation = useUpdateGroupConstraintListMutation();
  const deleteMutation = useDeleteGroupConstraintListMutation();
  const isLoading =
    createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading;
  return {
    createGroupConstraintList: createMutation.mutate,
    updateGroupConstraintList: updateMutation.mutate,
    deleteGroupConstraintList: deleteMutation.mutate,
    isLoading,
  };
};

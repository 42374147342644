import { createAction, createTypes } from 'lib/misc/misc';

const types = createTypes('notifications', [
  'ADD_ALERT',
  'REMOVE_ALERT',
  'ADD_SMALL_NOTIFICATION',
  'REMOVE_SMALL_NOTIFICATION',
]);

export default types;

export const removeSmallNotification = createAction(types.REMOVE_SMALL_NOTIFICATION);

export function addSmallNotification(notification) {
  return {
    type: types.ADD_SMALL_NOTIFICATION,
    payload: notification,
  };
}

export function addAlert(severity, alert) {
  return {
    type: types.ADD_ALERT,
    payload: { alert, severity },
  };
}

export function addError(error) {
  return {
    type: types.ADD_ALERT,
    payload: { alert: error, severity: 'error' },
  };
}

export function removeAlert(severity) {
  return {
    type: types.REMOVE_ALERT,
    payload: severity,
  };
}

export function removeError() {
  return {
    type: types.REMOVE_ALERT,
    payload: 'error',
  };
}

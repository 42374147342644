import types from './actions';

const initialUiState = {
  localStorageItems: {},
  isSideMenuCollapsed: true,
  searchValue: '',
};

function ui(state = initialUiState, action) {
  switch (action.type) {
    case types.COLLAPSE_SIDE_MENU:
      return { ...state, isSideMenuCollapsed: !state.isSideMenuCollapsed };
    case types.UPDATE_SEARCH_VALUE:
      return { ...state, searchValue: action.payload };
    case types.SET_LOCAL_STORAGE_VALUE:
      return {
        ...state,
        localStorageItems: {
          ...state.localStorageItems,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
}

export default ui;

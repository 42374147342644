import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { useSelector, useDispatch } from 'react-redux';

import { addWidgetToDashboard, toggleEdit } from 'screens/Report/redux/actions';
import { nameToWidgetMapping } from 'screens/Report/ReportScreen/configuration/dashboard.config';
import { prepareWidgetAddition } from 'screens/Report/utils/helpers';

const useStyles = makeStyles(theme => ({
  holder: {
    border: '0.5px solid #d9dada',
    padding: '1rem',
    borderRadius: theme.shape.borderRadius,
    marginBottom: '0.6rem',
  },
}));

const WidgetCard = ({
  type,
  IconComponent,
  setSelectedWidget,
  setShowSettings,
  setSettingsType,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const editMode = useSelector(state => state.dashboardScreen.editMode);
  const lastY = useSelector(state => state.dashboardScreen.lastY);

  const handleClick = () => {
    const widget = prepareWidgetAddition(nameToWidgetMapping[type], { x: 0, y: lastY }, editMode);
    dispatch(addWidgetToDashboard(widget));
    if (!editMode) {
      dispatch(toggleEdit(true));
    }
    setSettingsType(type);
    setSelectedWidget(widget);
    setShowSettings(true);
  };

  return (
    <div
      draggable
      unselectable='on'
      className='droppable-element'
      onDragStart={e => e.dataTransfer.setData('text/plain', type)}
    >
      <Tooltip title={<Translate id={`dashboard.${type}`} />}>
        <IconButton className={classes.holder} onClick={handleClick} size='large'>
          <IconComponent fontSize='large' color='action' />
        </IconButton>
      </Tooltip>
    </div>
  );
};

WidgetCard.propTypes = {
  type: PropTypes.string.isRequired,
  IconComponent: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default WidgetCard;

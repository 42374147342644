export const PerformanceInfoType = {
  ASSET: 'ASSET',
  PORTFOLIO: 'PORTFOLIO',
} as const;

export const PARENT_KPIS = {
  RETURNS: 'returns',
  RISK: 'risk',
  RISK_RETURN: 'riskReturn',
} as const;

export const CHILD_KPIS = {
  [PARENT_KPIS.RETURNS]: ['cumulativeReturn', 'meanReturn'],
  [PARENT_KPIS.RISK]: ['STD', 'CVAR', 'VAR', 'MDD'],
  [PARENT_KPIS.RISK_RETURN]: ['sharpeRatio', 'cSharpeRatio', 'turnover'],
} as const;

export const ALL_KPIS = [
  'cumulativeReturn',
  'meanReturn',
  'STD',
  'CVAR',
  'VAR',
  'MDD',
  'sharpeRatio',
  'cSharpeRatio',
  'turnover',
] as const;

import _ from 'lodash';
import React, { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';

import MyDialog from 'components/MyDialog/MyDialog';
import { findDiffOriginalAndDraft } from 'lib/misc/diff';
import { DialogKeys } from 'utils/constants';

const translationBase = 'useSavePromptAndDialog';

const shouldPropsTrigger = (diff, propsToTriggerPrompt) => {
  if (!propsToTriggerPrompt) {
    return !_.isEmpty(diff);
  }
  return propsToTriggerPrompt.some(prop => diff[prop] !== undefined);
};

const shouldLocationTrigger = (currentLocation, nextLocation) => {
  // show prompt if we close the browser tab
  if (nextLocation === undefined) {
    return true;
  }
  // This situation happens, when user creates an object and is directly redirected to the object's
  // page. In this case we do not want to show the prompt.
  const notNewObjectUrl = !matchPath(nextLocation.pathname, {
    path: `${currentLocation.pathname}/:id`,
    exact: true,
    strict: false,
  });
  const notSameScreenUrl = !matchPath(nextLocation.pathname, {
    path: currentLocation.pathname,
    exact: false,
  });
  return notNewObjectUrl && notSameScreenUrl;
};

export const useSavePromptAndDialog = (
  isNew,
  shouldTrigger,
  {
    shouldPromptTrigger = undefined,
    shouldSaveDialogTrigger = undefined,
    onSaveClick = () => {},
    onDeleteClick = () => {},
    translationPath,
  } = {}
) => {
  const shouldPromptTriggerFinal =
    shouldPromptTrigger !== undefined ? shouldPromptTrigger : shouldTrigger;
  const shouldSaveDialogTriggerFinal =
    shouldSaveDialogTrigger !== undefined ? shouldSaveDialogTrigger : shouldTrigger;
  const [openDialogType, setOpenDialogType] = React.useState(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const translationPathFinal = translationPath
    ? `${translationPath}.${translationBase}`
    : translationBase;

  const prompt = (
    <NavigationPrompt
      // call the callback function when finished cleaning up
      // afterCancel={null}
      // Children will be rendered even if props.when is falsey and isActive is false:
      renderIfNotActive
      when={(crntLocation, nextLocation) =>
        shouldPromptTriggerFinal &&
        shouldLocationTrigger(crntLocation, nextLocation) &&
        openDialogType !== 'delete'
      }
    >
      {({ isActive, onCancel, onConfirm }) => {
        if (isActive) {
          return (
            <MyDialog
              isOpen
              header={
                isNew
                  ? `${translationPathFinal}.dialogHeader.quitCreating`
                  : `${translationPathFinal}.dialogHeader.quitEditing`
              }
              message={
                isNew
                  ? `${translationPathFinal}.dialogMessage.quitCreating`
                  : `${translationPathFinal}.dialogMessage.quitEditing`
              }
              notPerformKey={
                isNew
                  ? `dialogKey.${DialogKeys.KEEP_CREATING}`
                  : `dialogKey.${DialogKeys.KEEP_EDITING}`
              }
              performKey={`dialogKey.${DialogKeys.QUIT}`}
              onNotPerformClick={onCancel}
              onPerformClick={onConfirm}
            />
          );
        }
        return null;
      }}
    </NavigationPrompt>
  );
  const dialogPerformKey = openDialogType ? openDialogType.toUpperCase() : '';
  const dialog = (
    <MyDialog
      isOpen={isDialogOpen}
      header={`${translationPathFinal}.dialogHeader.${openDialogType}`}
      message={`${translationPathFinal}.dialogMessage.${openDialogType}`}
      notPerformKey={`dialogKey.${DialogKeys.CANCEL}`}
      performKey={`dialogKey.${dialogPerformKey}`}
      TransitionProps={{
        onExited: () => {
          setOpenDialogType(null);
        },
      }}
      onNotPerformClick={() => {
        setIsDialogOpen(false);
      }}
      onPerformClick={() => {
        setIsDialogOpen(false);
        if (openDialogType === 'delete') {
          onDeleteClick();
        } else if (openDialogType === 'save') {
          onSaveClick();
        }
      }}
    />
  );
  const promptAndDialog = (
    <>
      {prompt}
      {dialog}
    </>
  );

  return {
    prompt,
    dialog,
    promptAndDialog,
    onSaveClickWrapper: () => {
      if (shouldSaveDialogTriggerFinal) {
        setOpenDialogType('save');
        setIsDialogOpen(true);
      } else {
        onSaveClick();
      }
    },
    onDeleteClickWrapper: () => {
      setOpenDialogType('delete');
      setIsDialogOpen(true);
    },
  };
};

export const useDraftSavePromptAndDialog = (
  originalObject,
  draftObject,
  onSaveClick,
  onDeleteClick,
  { translationPath, propsToTriggerPrompt, propsToTriggerDialog }
) => {
  const isNew = !originalObject;
  const { isEqual, diff } = useMemo(() => {
    return findDiffOriginalAndDraft(originalObject, draftObject);
  }, [originalObject, draftObject]);

  const shouldSaveDialogTrigger = !isNew && shouldPropsTrigger(diff, propsToTriggerDialog);
  const shouldPromptTrigger = shouldPropsTrigger(diff, propsToTriggerPrompt);

  const result = useSavePromptAndDialog(isNew, false, {
    shouldPromptTrigger,
    shouldSaveDialogTrigger,
    onSaveClick,
    onDeleteClick,
    translationPath,
  });

  return {
    isDraftEqual: isEqual,
    draftDiff: diff,
    ...result,
  };
};

import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';

import { ChildrenType } from 'utils/types';

class SearchMenuItem extends React.Component {
  onClickAndCloseMenu = () => {
    const { onClick, menuCloseFunc } = this.props;
    onClick();
    menuCloseFunc();
  };

  render() {
    const { children } = this.props;
    return <MenuItem onClick={this.onClickAndCloseMenu}>{children}</MenuItem>;
  }
}

SearchMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  // if not null, this function should close the menu
  menuCloseFunc: PropTypes.func,
  children: ChildrenType.isRequired,
};

SearchMenuItem.defaultProps = {
  menuCloseFunc: null,
};

export default SearchMenuItem;

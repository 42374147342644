import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';

const useFunctionalityOn = functionalityName => {
  const { institute: currentInstitute } = useCurrentInstitute();
  if (!functionalityName) {
    return true;
  }
  if (!currentInstitute) {
    return true;
  }
  if (
    !currentInstitute.settings ||
    !currentInstitute.settings.features ||
    !currentInstitute.settings.features[functionalityName]
  ) {
    return false;
  }
  const isOn = currentInstitute.settings.features[functionalityName].available;
  return Boolean(isOn);
};

export default useFunctionalityOn;

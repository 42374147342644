export function getTextColor(hex) {
  let hexNum = hex.replace('#', '');
  if (hexNum.length === 3) {
    hexNum = hexNum[0] + hexNum[0] + hexNum[1] + hexNum[1] + hexNum[2] + hexNum[2];
  }
  const r = parseInt(hexNum.substring(0, 2), 16);
  const g = parseInt(hexNum.substring(2, 4), 16);
  const b = parseInt(hexNum.substring(4, 6), 16);
  if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
    return '#000000';
  }
  return '#ffffff';
}

export function getThemeHueColors(hueColors, labelDict, textLabelDict) {
  const themeColors = {};
  Object.keys(labelDict).forEach(label => {
    themeColors[label] = hueColors[labelDict[label]];
  });
  Object.keys(textLabelDict).forEach(label => {
    themeColors[label] = getTextColor(themeColors[textLabelDict[label]]);
  });
  return themeColors;
}

import { useEffect, useMemo } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

type SearchParams<T extends string[] = string[]> = { [K in T[number]]: string };

interface GeneratePathParams {
  [key: string]: string;
}

type UseURLSearchParamsReturn<T extends string[] = string[]> = SearchParams<T>;

/**
 * A custom hook that builds on useLocation to parse the query string for you.
 * @return Object containing the query string parameters.
 * E.g. for url=https://example.com?foo=bar&baz=42, returns {foo: 'bar', baz: '42'}
 */
export function useURLSearchParams<T extends string[] = string[]>(): UseURLSearchParamsReturn<T> {
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const result = {} as { [key: string]: string };
  for (const [key, value] of searchParams.entries()) {
    result[key] = value;
  }
  return result as UseURLSearchParamsReturn<T>;
}

interface GenerateSearchPathOptions {
  searchParams?: SearchParams;
  params?: GeneratePathParams;
}

/**
 * Does the same as generatePath but also adds search params to the end of the URL
 * @param pattern same as pattern in generatePath
 * @param searchParams Object with key/value pairs to be added to the URL as search params
 * @param params Same as params in generatePath
 * @return Final path with params and searchParams taken into account.
 */
export function generateSearchPath(
  pattern: string,
  { searchParams = undefined, params = undefined }: GenerateSearchPathOptions
): string {
  let path;
  if (params) {
    path = generatePath(pattern, params);
  } else {
    path = pattern;
  }
  if (searchParams) {
    path = `${path}?${new URLSearchParams(searchParams).toString()}`;
  }
  return path;
}

type TabTuple = ['tab'];

export function useTabs<T extends readonly string[]>(tabs: T) {
  const history = useHistory();
  const { tab }: { tab: T[number] } = useURLSearchParams<TabTuple>();
  useEffect(() => {
    if (!tab || !tabs.includes(tab)) {
      history.replace({ search: `?tab=${tabs[0]}` });
    }
  });
  return {
    tab,
    tabIndex: tabs.indexOf(tab),
    onTabIndexChange: (tabIndex: number) => {
      history.replace({ search: `?tab=${tabs[tabIndex]}` });
    },
    onTabChange: (newTab: string) => {
      history.replace({ search: `?tab=${newTab}` });
    },
  };
}

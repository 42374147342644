import { Slider } from '@mui/material';
import CardActions from '@mui/material/CardActions/index';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

import SimpleText from 'components/SimpleText';
import { useAssetGroupsQuery } from 'screens/Environment/lib/queries';
import { GroupConstraintType } from 'utils/types';

const useStyles = makeStyles({
  text: {
    minWidth: '10rem',
    maxWidth: '10rem',
    textAlign: 'left',
    paddingRight: '1rem',
  },
  slider: {
    minWidth: '150px',
  },
  insideCard: {
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
  },
});

const GroupConstraintSlider = ({ draftConstraint, setDraftConstraint, disabled }) => {
  const classes = useStyles();
  const { assetGroups } = useAssetGroupsQuery();
  const assetGroup = assetGroups?.[draftConstraint.assetGroup];

  return (
    <CardActions className={classes.insideCard} key={draftConstraint.assetGroup}>
      <div>
        <SimpleText text={assetGroup?.name} className={classes.text} />
      </div>
      <div className={classes.slider}>
        <Slider
          size='small'
          valueLabelDisplay='on'
          orientation='horizontal'
          min={0}
          max={100}
          step={5}
          disabled={disabled}
          value={[
            Math.round(draftConstraint.minimum * 100) ?? 0,
            Math.round(draftConstraint.maximum * 100) ?? 100,
          ]}
          onChange={(e, values) =>
            setDraftConstraint({
              ...draftConstraint,
              minimum: values[0] / 100,
              maximum: values[1] / 100,
            })
          }
        />
      </div>
    </CardActions>
  );
};

GroupConstraintSlider.propTypes = {
  draftConstraint: GroupConstraintType.isRequired,
  setDraftConstraint: PropTypes.func,
  disabled: PropTypes.bool,
};

GroupConstraintSlider.defaultProps = {
  disabled: false,
  setDraftConstraint: () => {},
};
export default React.memo(GroupConstraintSlider);

import React from 'react';
import { Redirect } from 'react-router-dom';

import LoginScreenBase from 'screens/Login/lib/components/LoginScreenBase';
import { useAuth } from 'screens/Login/lib/queries';
import LoginForm from 'screens/Login/LoginScreen/components/LoginForm';
import { Routes } from 'utils/constants';

const LoginScreen = () => {
  const { login, isAuthenticated, isAuthenticating } = useAuth();

  if (isAuthenticating) {
    return <div>Loading...</div>;
  }
  if (isAuthenticated) {
    return <Redirect to={Routes.CHOOSE_PORTFOLIO} />;
  }
  return (
    <LoginScreenBase>
      <div>
        <LoginForm login={login} />
      </div>
    </LoginScreenBase>
  );
};

export default LoginScreen;

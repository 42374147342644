import { Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty, first } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import SimpleText from 'components/SimpleText';
import { useTranslate } from 'lib/localization/localization';
import WidgetNotAvailable from 'screens/Analysis/AnalysisScreen/WidgetNotAvailable';
import ReturnBreakdownBarChart from 'screens/Analysis/lib/components/ReturnBreakdownBarChart';
import TestSpinner from 'screens/Analysis/lib/components/TestSpinner';
import useDateToggle from 'screens/Analysis/lib/components/useDateToggle';
import { FIRST_LEVEL_HEIGHT } from 'screens/Analysis/lib/constants';
import { useAnalysisQuery } from 'screens/Analysis/lib/queries';
import Breakdown from 'static/images/breakdown.png';

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%',
    minHeight: FIRST_LEVEL_HEIGHT,
    position: 'relative',
  },
  returnBlock: {
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    backgroundColor: theme.palette.primary.main,
    padding: '0.6rem',
    textAlign: 'center',
  },
  barBlock: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    padding: '0.6rem',
  },
  returnTitle: {
    textAlign: 'center',
    marginTop: '0.8rem',
    marginBottom: '5rem',
  },
  percentageTitle: {
    marginBottom: '0.9rem',
  },
  breakAll: {
    wordSpacing: '9999px',
    wordBreak: 'break-all',
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('xl')]: {
      fontSize: '0.65rem',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.5rem',
    },
  },
  bar: {
    fontSize: '0.9rem',
    border: `1px solid ${theme.palette.primary.dark}`,
    padding: '0rem 3.3rem',
    minHeight: '0.95rem',
    color: '#5d5c80',
    display: 'inline-block',
    position: 'relative',
    flex: '1',
    [theme.breakpoints.down('xl')]: {
      padding: '0rem 1.75rem',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '0rem 0.9rem',
    },
    '&:before': {
      content: '""',
      display: 'inline-block',
      height: '100%',
      verticalAlign: 'middle',
    },
  },
  positiveBorder: {
    alignSelf: 'flex-end',
    verticalAlign: 'middle',
  },
  negativeBorder: {
    alignSelf: 'flex-start',
    top: '100%',
  },
}));

const barLineOptions = [
  'excessReturn',
  'benchmarkReturn',
  'allocationEffect',
  'selectionEffect',
  'interactionEffect',
];

const ReturnBreakdownBar = ({ analysisId }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const { analysis } = useAnalysisQuery(analysisId);
  const widgetData = analysis?.result?.ReturnBreakdownBar ?? {};
  const [dateFilter, DateFilterComponent] = useDateToggle(
    !isEmpty(widgetData) ? first(widgetData.filters) : 'all'
  );

  return (
    <Grid item xs={12} lg={9}>
      <Paper className={classes.wrapper} pendo-id='analysis-return-breakdown-bar-paper'>
        <Grid container style={{ height: '100%' }}>
          {!isEmpty(widgetData) && isEmpty(widgetData.filters) ? (
            <WidgetNotAvailable image={Breakdown} />
          ) : (
            <>
              {isEmpty(widgetData) ? (
                <TestSpinner />
              ) : (
                <>
                  <DateFilterComponent
                    available={widgetData.filters}
                    errors={widgetData.errors}
                    hints={widgetData.hints}
                  />
                  <Grid item xs={3} className={classes.returnBlock}>
                    <SimpleText
                      translateId='analysis.portfolioReturn'
                      variant='h2'
                      sx={{ color: 'primary.contrastText' }}
                      className={classes.returnTitle}
                    />
                    <Grid container>
                      <Grid item xs={6}>
                        <SimpleText
                          text={`${(widgetData.data[dateFilter].block.cReturn * 100).toFixed(2)} %`}
                          variant='h2'
                          sx={{ color: 'primary.contrastText' }}
                          className={classes.percentageTitle}
                        />
                        <SimpleText
                          translateId='analysis.cReturn'
                          sx={{ color: 'primary.contrastText' }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SimpleText
                          text={`${(widgetData.data[dateFilter].block.alpha * 100).toFixed(2)} %`}
                          variant='h2'
                          sx={{ color: 'primary.contrastText' }}
                          className={classes.percentageTitle}
                        />
                        <SimpleText
                          translateId='analysis.alpha'
                          sx={{ color: 'primary.contrastText' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={9}>
                    <div style={{ height: '99%', width: '100%', position: 'relative' }}>
                      <ReturnBreakdownBarChart
                        data={barLineOptions.map(key => {
                          const value = widgetData.data[dateFilter].chart[key];
                          return {
                            key: translate(`analysis.${key}`),
                            [translate('analysis.value')]: (value * 100).toFixed(2),
                          };
                        })}
                        padding={0.1}
                      />
                    </div>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

ReturnBreakdownBar.propTypes = {
  analysisId: PropTypes.string,
};

ReturnBreakdownBar.defaultProps = {
  analysisId: undefined,
};

export default ReturnBreakdownBar;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { createLocation } from 'components/MyRouting/utils';
import ChooseScreen from 'components/SearchTools/ChooseScreen';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';
import { useDeleteUserGroupMutation, useUserGroupsQuery } from 'screens/UserGroup/lib/queries';
import { Routes } from 'utils/constants';

const ChooseUserGroupScreen = () => {
  const history = useHistory();
  const hasCreatePermission = useCreatePermission(PermissionResourceName.USER_GROUP);
  const { userGroups } = useUserGroupsQuery();
  const { instituteId } = useCurrentInstitute();
  const { deleteUserGroup } = useDeleteUserGroupMutation();
  const cols = ['id', 'name', 'roles'];
  const cellFormatting = {
    roles: roles =>
      roles
        .reduce((previousValue, currentValue) => `${previousValue}, ${currentValue.name}`, '')
        .slice(2),
  };

  return (
    <ChooseScreen
      dialogTranslatePath='userGroupScreen.useSavePromptAndDialog'
      tableObjects={Object.values(userGroups).filter(
        userGroup => userGroup.institute === instituteId
      )}
      columns={cols}
      cellFormatting={cellFormatting}
      searchColumns={cols}
      screenTitle='adminUserGroups'
      searchValue={null}
      translateTableId='userGroupsTable'
      isTableWhite
      onNewClick={hasCreatePermission ? () => history.push(Routes.NEW_USER_GROUP) : null}
      onRowClick={id => history.push(createLocation(Routes.USER_GROUP, id))}
      onDeleteRowClick={userGroup => {
        deleteUserGroup(userGroup.id);
      }}
    />
  );
};

export default ChooseUserGroupScreen;

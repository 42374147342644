import { createAction, createTypes } from 'lib/misc/misc';

const types = createTypes('ui', [
  'COLLAPSE_SIDE_MENU',
  'UPDATE_SEARCH_VALUE',
  'SET_LOCAL_STORAGE_VALUE',
]);

export default types;

export const collapseSideMenu = createAction(types.COLLAPSE_SIDE_MENU);

export function updateSearchValue(value) {
  return {
    type: types.UPDATE_SEARCH_VALUE,
    payload: value,
  };
}

export function setLocalStorageValue(key, value) {
  return {
    type: types.SET_LOCAL_STORAGE_VALUE,
    payload: { key, value },
  };
}

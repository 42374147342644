import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ResourceName } from 'lib/constants';
import { removeObjectFromArray, replaceObjectInArray } from 'lib/misc/misc';
import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import { del, get, post, put, selectListToDict } from 'lib/queries';
import { Id, ValueOf } from 'lib/types';
import { addError, addSmallNotification } from 'redux/notifications/actions';
import { analysisKeys } from 'screens/Analysis/lib/queries';
import {
  Backtest,
  BacktestOverview,
  BacktestOverviews,
  GetBacktest,
  PostBacktest,
  PutBacktest,
} from 'screens/Backtest/lib/types';
import { selectAllocation } from 'screens/Portfolio/lib/queries';
import { SmallNotification, SocketioEvent } from 'utils/constants';

interface ListParams {
  portfolioId: Id;
}
interface DetailParams {
  id: Id;
}

interface UpdateMutateParams {
  id: Id;
  data: PutBacktest;
}

export const backtestKeys = {
  all: [{ scope: ResourceName.BACKTEST }] as const,
  lists: () => [{ ...backtestKeys.all[0], entity: 'list' }] as const,
  list: ({ portfolioId }: ListParams) => [{ ...backtestKeys.lists()[0], portfolioId }] as const,
  details: () => [{ ...backtestKeys.all[0], entity: 'detail' }] as const,
  detail: ({ id }: DetailParams) => [{ ...backtestKeys.details()[0], id }] as const,
};

async function getBacktests({
  queryKey: [{ portfolioId }],
}: QueryFunctionContext<ReturnType<typeof backtestKeys.list>>): Promise<
  ReadonlyArray<BacktestOverview>
> {
  return get(`portfolios/${portfolioId}/backtests/`);
}

async function getBacktest({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<typeof backtestKeys.detail>>): Promise<GetBacktest> {
  return get(`backtests/${id}/`);
}

async function postBacktest(data: PostBacktest, portfolioId: Id): Promise<GetBacktest> {
  return post(`portfolios/${portfolioId}/backtests/`, data);
}

async function putBacktest(data: PutBacktest, backtestId: Id): Promise<GetBacktest> {
  return put(`backtests/${backtestId}/`, data);
}

async function cancelBacktest(backtestId: Id): Promise<GetBacktest> {
  return put(`backtests/${backtestId}/cancel/`);
}

async function deleteBacktest(backtestId: Id) {
  return del(`backtests/${backtestId}/`);
}

const selectBacktest = (data: GetBacktest): Backtest => {
  const points = data.portfolios.map(portfolioAPI => {
    return {
      effectiveDate: portfolioAPI.effectiveDate,
      allocation: selectAllocation(portfolioAPI.virtualAllocation),
      wasRebalanced: portfolioAPI.virtualAllocation.wasRebalanced,
      turnover: portfolioAPI.virtualAllocation.turnover,
    };
  });
  return {
    id: data.id,
    name: data.name,
    createdOn: data.createdOn,
    updatedOn: data.updatedOn,
    portfolioId: data.portfolioId,
    tablename: ResourceName.BACKTEST,
    points,
    performance: data.performance,
    startDate: data.startDate,
    endDate: data.endDate,
    rebalancingFrequency: data.rebalancingFrequency,
    backtestJob: data.backtestJob,
  };
};

export const useBacktestsQuery = (portfolioId: Id | undefined) => {
  const query = useQuery(backtestKeys.list({ portfolioId } as { portfolioId: Id }), getBacktests, {
    select: selectListToDict,
    enabled: !!portfolioId,
  });
  const data = query.data as BacktestOverviews | undefined;
  const backtests = data || {};
  return {
    ...query,
    data,
    backtests,
  };
};

export const useBacktestQuery = (id: Id | undefined) => {
  const query = useQuery(backtestKeys.detail({ id } as { id: Id }), getBacktest, {
    select: selectBacktest,
    enabled: !!id,
  });
  const data = query.data as Backtest | undefined;
  return {
    ...query,
    backtest: data,
    data,
  };
};

export const useCreateBacktestMutation = (portfolioId: Id) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation((draft: PostBacktest) => postBacktest(draft, portfolioId), {
    onSuccess: data => {
      queryClient.setQueriesData<GetBacktest[]>(
        backtestKeys.list({ portfolioId: data.portfolioId }),
        prev => (prev ? [...prev, data] : [data])
      );
      queryClient.setQueriesData(backtestKeys.detail({ id: data.id }), data);
      dispatch(addSmallNotification(SmallNotification.BACKTEST_CREATED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const useUpdateBacktestMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(({ id, data }: UpdateMutateParams) => putBacktest(data, id), {
    onSuccess: data => {
      queryClient.setQueriesData(backtestKeys.list({ portfolioId: data.portfolioId }), prev =>
        replaceObjectInArray(prev, data)
      );
      queryClient.setQueriesData(backtestKeys.detail({ id: data.id }), data);
      dispatch(addSmallNotification(SmallNotification.BACKTEST_SAVED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const useCancelBacktestMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation((id: Id) => cancelBacktest(id), {
    onSuccess: data => {
      queryClient.setQueriesData<GetBacktest[]>(
        backtestKeys.list({ portfolioId: data.portfolioId }),
        prev => (prev ? [...prev, data] : [data])
      );
      queryClient.setQueriesData(backtestKeys.detail({ id: data.id }), data);
      dispatch(addSmallNotification(SmallNotification.ACTION_SENT));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const useDeleteBacktestMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mutation = useMutation((id: Id) => deleteBacktest(id), {
    onSuccess: (data, id) => {
      queryClient.setQueriesData(backtestKeys.lists(), prev => removeObjectFromArray(prev, id));
      queryClient.invalidateQueries(analysisKeys.all);
      dispatch(addSmallNotification(SmallNotification.BACKTEST_DELETED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
  return { ...mutation, delete: mutation.mutate };
};

export const useReceiveBacktestWebsockets = (event: ValueOf<typeof SocketioEvent>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return (data: GetBacktest) => {
    queryClient.setQueriesData(backtestKeys.list({ portfolioId: data.portfolioId }), prev =>
      replaceObjectInArray(prev, data)
    );
    queryClient.setQueriesData(backtestKeys.detail({ id: data.id }), data);
    if (event === SocketioEvent.BACKTEST_FINISHED) {
      dispatch(addSmallNotification(SmallNotification.BACKTEST_FINISHED));
    }
  };
};

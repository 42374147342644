import React from 'react';
import { useHistory } from 'react-router-dom';

import { createLocation } from 'components/MyRouting/utils';
import ChooseScreen from 'components/SearchTools/ChooseScreen';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import { useDeleteUniverseMutation, useUniversesQuery } from 'screens/Universe/lib/queries';
import { Routes } from 'utils/constants';

const columns = ['id', 'name', 'assets'];

const ChooseUniverseScreen = () => {
  const history = useHistory();
  const hasCreatePermission = useCreatePermission(PermissionResourceName.UNIVERSE);

  const { universes, isLoading } = useUniversesQuery();
  const { mutate: deleteUniverse } = useDeleteUniverseMutation();

  const cellFormatting = {
    assets: assets => assets.length,
  };

  return (
    <ChooseScreen
      dialogTranslatePath='universeScreen.useSavePromptAndDialog'
      waitWhile={isLoading}
      key={columns}
      tableObjects={universes}
      columns={columns}
      searchColumns={columns}
      cellFormatting={cellFormatting}
      screenTitle='adminUniverses'
      searchValue={null}
      translateTableId='universeTable'
      isTableWhite
      onNewClick={hasCreatePermission ? () => history.push(Routes.NEW_UNIVERSE) : null}
      onRowClick={id => history.push(createLocation(Routes.UNIVERSE, id))}
      onDeleteRowClick={obj => {
        deleteUniverse(obj.id);
      }}
    />
  );
};

export default ChooseUniverseScreen;

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import React from 'react';
import { Router as BaseRouter, Route as BaseRoute } from 'react-router-dom';

import { env } from 'lib/env';
import { getBuildEnvName, isProductionEnv } from 'lib/misc/misc';

const SENTRY_DSN = 'https://3cc1c41c05f34b5cb47e5f0d78288c2d@o1333733.ingest.sentry.io/6599430';
let isPendoInitialized = false;
let activeLanguagePrevious = null;

// Create Custom Sentry Route and Router components
const Route = Sentry.withSentryRouting(BaseRoute);
const history = createBrowserHistory();
const Router = ({ children }) => <BaseRouter history={history}>{children}</BaseRouter>;

function initSentry() {
  if (isProductionEnv()) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      environment: getBuildEnvName(),
      release: env.REACT_APP_VERSION_NAME ?? 'missing',
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
}

function sentryIdentify(user, instituteId) {
  Sentry.setUser({ id: `${getBuildEnvName()}-${user.id}`, instituteId });
}

function sentryLogout() {
  Sentry.setUser(null);
}

function pendoInitializeOrIdentify(user, instituteId, activeLanguage) {
  const { pendo } = window;
  if (!pendo || !pendo.getVisitorId || !pendo.getAccountId || getBuildEnvName() === 'local') {
    return;
  }
  let languageUi = activeLanguage;
  if (languageUi === 'en') {
    languageUi = 'en_US';
  }
  if (
    pendo.getVisitorId() !== user.id ||
    pendo.getAccountId() !== instituteId ||
    activeLanguagePrevious !== languageUi
  ) {
    activeLanguagePrevious = languageUi;
    const data = {
      visitor: {
        id: `${getBuildEnvName()}-${user.id}`, // Required if user is logged in
        // email: user.email, // Recommended if using Pendo Feedback, or NPS Email
        // full_name: `${user.surname}  ${user.name}`, // Recommended if using Pendo Feedback
        languageUi,
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: {
        id: `${getBuildEnvName()}-${instituteId}`, // Highly recommended
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    };
    if (isPendoInitialized) {
      pendo.identify(data);
    } else {
      pendo.initialize(data);
      isPendoInitialized = true;
    }
  }
}

function identifyUserForExtensions(user, instituteId, activeLanguage) {
  sentryIdentify(user, instituteId);
  pendoInitializeOrIdentify(user, instituteId, activeLanguage);
}

function logoutUserForExtensions() {
  sentryLogout();
}

export { initSentry, identifyUserForExtensions, logoutUserForExtensions, Route, Router };

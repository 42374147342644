import { TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  contentFont: {
    color: theme.palette.text.primary,
  },
  benchmark: {
    marginLeft: '0.3rem',
    color: '#d9dada',
    fontWeight: '600',
  },
}));

const ContributionTableViewCell = ({ value, benchmarkValue, cellFormatting }) => {
  const classes = useStyles();
  const showBenchmark = !isNil(benchmarkValue);

  return (
    <TableCell className={classes.contentFont}>
      {!isNil(cellFormatting) && !isNil(value) ? cellFormatting(value) : value}
      {showBenchmark ? (
        <span className={classes.benchmark}>{(benchmarkValue * 100).toFixed(2)}</span>
      ) : null}
    </TableCell>
  );
};

ContributionTableViewCell.propTypes = {
  value: PropTypes.number.isRequired,
  benchmarkValue: PropTypes.number,
  cellFormatting: PropTypes.func,
};

ContributionTableViewCell.defaultProps = {
  benchmarkValue: null,
  cellFormatting: null,
};

export default ContributionTableViewCell;

import { useTheme } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import PropTypes from 'prop-types';
import React from 'react';

const BarChartWrapper = ({ data, height, padding }) => {
  const theme = useTheme();
  return (
    <ResponsiveBar
      height={height}
      data={data}
      colors={['#abe6e9', '#d9dada']}
      padding={padding}
      innerPadding={2}
      keys={['Portfolio', 'Benchmark']}
      indexBy='date'
      margin={{ top: 10, right: 10, bottom: 20, left: 10 }}
      groupMode='grouped'
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 10,
        legendPosition: 'middle',
        legendOffset: 10,
      }}
      axisLeft={null}
      gridYValues={[0]}
      labelSkipWidth={13}
      labelSkipHeight={9}
      labelTextColor={{ from: 'color', modifiers: [['darker', '1.5']] }}
      legends={[]}
      isInteractive
      animate
      motionStiffness={90}
      motionDamping={15}
      theme={{
        tooltip: {
          container: {
            background: theme.palette.background.paper,
            color: theme.palette.text.primary,
          },
        },
        axis: { ticks: { text: { fill: theme.palette.text.secondary } } },
      }}
    />
  );
};

BarChartWrapper.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  height: PropTypes.number,
  padding: PropTypes.number,
};

BarChartWrapper.defaultProps = {
  height: 375,
  padding: 0.6,
};

export default BarChartWrapper;

import { Order } from 'utils/constants';

function descSimple(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function descCaseInsensitive(a, b, orderBy) {
  if (a[orderBy] && b[orderBy]) {
    if (b[orderBy].toString().toLowerCase() < a[orderBy].toString().toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toString().toLowerCase() > a[orderBy].toString().toLowerCase()) {
      return 1;
    }
  }
  return 0;
}

/**
 * In case a[orderBy] and b[orderBy] are strings, make case-insensitive comparison.
 * Otherwise, make simple comparison.
 */
function desc(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    return descCaseInsensitive(a, b, orderBy);
  }
  return descSimple(a, b, orderBy);
}

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const pairOrder = cmp(a[0], b[0]);
    if (pairOrder !== 0) return pairOrder;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export function getSorting(order, orderBy) {
  const multiplier = order === Order.DESC ? 1 : -1;
  return (a, b) => multiplier * desc(a, b, orderBy);
}

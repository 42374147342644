import { CircularProgress } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React from 'react';

import MyDropdown from 'components/MyDropdown/MyDropdown';
import { Id } from 'lib/types';
import { usePortfoliosQuery } from 'screens/Portfolio/lib/queries';

const translationPath = 'backtestLib.portfoliosDropdown';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  dropdown: {
    marginTop: theme.spacing(2),
  },
}));

interface PortfoliosDropdownProps {
  portfolioId: Id | undefined;
  onChange: (portfolioId: Id) => void;
  disabled?: boolean;
}

const PortfoliosDropdown = ({
  portfolioId,
  onChange,
  disabled = false,
}: PortfoliosDropdownProps) => {
  const classes = useStyles();
  const { portfolios, isLoading: isLoadingPortfolios } = usePortfoliosQuery();

  return (
    <div className={classes.content}>
      <MyDropdown
        // @ts-ignore
        className={classes.dropdown}
        disabled={disabled}
        value={portfolioId}
        onChange={onChange}
        onUpdateSearch={null}
        translationPath={translationPath}
        isNullAllowed={false}
        pendo-id='lib-portfolios-dropdown'
      >
        {_.values(portfolios)
          .filter(p => p.isSaved)
          .map(p => (
            <MenuItem key={p.id} value={p.id}>
              {p.name}
            </MenuItem>
          ))}
      </MyDropdown>
      {isLoadingPortfolios && <CircularProgress size={24} />}
    </div>
  );
};

export default PortfoliosDropdown;

import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React from 'react';

import ScreenBase from 'components/ScreenBase';
import SearchBar from 'components/SearchBar/SearchBarView';
import { fuseSearch } from 'components/SearchBar/utils';
import HelpTopicCard from 'screens/Help/HelpTopicCard';
import HelpTopicView from 'screens/Help/HelpTopicView';
import help from 'static/translations/help.json';
import { PORTFOLIO_CARD_HEIGHT } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  searchBar: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
  },
}));

const HelpScreen = () => {
  const classes = useStyles();
  const activeLanguage = 'en'; // useActiveLanguage();
  const isFetchingHelp = false;
  let topics = [];
  if (help && activeLanguage in help) {
    topics = help[activeLanguage];
  }
  const [searchValue, setSearchValue] = React.useState(null);
  const [viewedHelpTopicId, setViewedHelpTopicId] = React.useState(null);

  const onGoBackHome = () => {
    setViewedHelpTopicId(null);
  };

  const skeletonCards = [1, 2, 3, 4, 5, 6, 7, 8].map(number => (
    <Grid item key={number} xs={12} sm={12} md={6} lg={3} xl={3}>
      <Skeleton variant='rectangular' height={PORTFOLIO_CARD_HEIGHT} />
    </Grid>
  ));
  const searchfeatures = ['header', 'message'];
  const searchedTopics = fuseSearch(topics, searchValue, searchfeatures);
  const cardsToShow = _.values(searchedTopics).map(topic => (
    <Grid item key={topic.id} xs={12} sm={6} md={4} lg={4} xl={4}>
      <HelpTopicCard
        topicId={topic.id}
        picture={topic.picture}
        header={topic.header}
        message={topic.message}
        link={topic.link}
        subTopics={topic.subtopics}
        setViewedHelpTopicId={setViewedHelpTopicId}
      />
    </Grid>
  ));
  const returnButtonProps = {
    translateId: 'returnButton.home',
    onClick: onGoBackHome,
  };
  let viewedHelpTopicData;
  if (viewedHelpTopicId) {
    viewedHelpTopicData = topics.find(topic => topic.id === viewedHelpTopicId);
  }
  return (
    <ScreenBase
      title='help'
      noPadding
      returnButtonProps={viewedHelpTopicId ? returnButtonProps : null}
    >
      <div className={classes.content}>
        <Grid container spacing={10} className={classes.searchBar}>
          <Grid item xs={12} lg={12}>
            <SearchBar onUpdateSearch={setSearchValue} placeholderId='search' />
          </Grid>
        </Grid>
        {!viewedHelpTopicId ? (
          <Grid container spacing={2}>
            {isFetchingHelp ? skeletonCards : cardsToShow}
          </Grid>
        ) : (
          <HelpTopicView searchValue={searchValue} data={viewedHelpTopicData} />
        )}
      </div>
    </ScreenBase>
  );
};

export default HelpScreen;

import React from 'react';

import { Id } from '../types';

import MyDialog from 'components/MyDialog/MyDialog';
import { triggerCsvDownload } from 'lib/misc/misc';
import { makeWeightsTableData } from 'lib/weights/WeightsTable';
import { useAssetsQuery } from 'screens/Environment/lib/queries';
import { Derivative, Weights } from 'screens/Portfolio/lib/types';
import { DEFAULT_CSV_DELIMITER, DialogKeys, Dialogs } from 'utils/constants';

interface WeightsExportDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  weights: Weights | undefined;
  derivatives: Derivative[];
  assetsToDerivativesIds: { [key: Id]: Id | null };
}

const WeightsExportDialog = ({
  isOpen,
  setIsOpen,
  weights,
  derivatives,
  assetsToDerivativesIds,
}: WeightsExportDialogProps) => {
  const { assets, isLoading } = useAssetsQuery();

  const handleAcceptExport = () => {
    const exportObjects = makeWeightsTableData(
      assets,
      weights,
      derivatives,
      assetsToDerivativesIds
    );
    const options = {
      delimiter: DEFAULT_CSV_DELIMITER,
      fields: ['instituteIdentifier', 'name', 'weight'],
    };
    // options.exportFormatting = exportFormatting;
    triggerCsvDownload(exportObjects, 'optimal_weights.csv', options);
    setIsOpen(false);
  };

  return (
    <MyDialog
      isOpen={isOpen}
      header={`dialogHeader.${Dialogs.EXPORT_WEIGHTS}`}
      message={null}
      notPerformKey={`dialogKey.${DialogKeys.NO}`}
      performKey={`dialogKey.${DialogKeys.EXPORT}`}
      onNotPerformClick={() => setIsOpen(false)}
      onPerformClick={handleAcceptExport}
      isPerformKeyDisabled={isLoading}
    />
  );
};

export default WeightsExportDialog;

import React from 'react';
import styled from 'styled-components';

const FooterDiv = styled.div.attrs({
  // className: 'footer',
})`
  clear: both;
  text-align: right;
  font-size: 0.75rem;
  position: fixed;
  bottom: 0px;
  right: 0px;
`;

const Footer = () => <FooterDiv>Copyright &copy; 2022 The Double Alpha Factory.</FooterDiv>;

export default Footer;

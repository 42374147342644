import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import MyDialog from 'components/MyDialog/MyDialog';
import ObjectScreenBase from 'components/ObjectScreenBase';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import useEditPermission from 'lib/access/useEditPermission';
import { useDraftObject, useDraftObjectList, useDraftString } from 'lib/misc/useDraft/useDraft';
import { generateSearchPath } from 'lib/navigation/navigation';
import ConstraintDropdown from 'screens/Research/lib/components/ConstraintDropdown';
import { ResearchAction } from 'screens/Research/lib/constants';
import {
  useEveryResearchListMutation,
  useResearchListQuery,
  useResearchListsQuery,
} from 'screens/Research/lib/queries';
import RecommendationsTab from 'screens/Research/ResearchListScreen/components/RecommendationsTab';
import { DialogKeys, Routes } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

function makeInitialRecommendationTypesDefaults(recommendationTypes) {
  const recommendationTypesDefaults = {};
  recommendationTypes.forEach(recType => {
    recommendationTypesDefaults[recType] = ResearchAction.SELL;
  });
  return recommendationTypesDefaults;
}

function getRecommendationTypes(recommendations) {
  const recommendationTypes = [];
  recommendations.forEach(recommendation => {
    const recType = recommendation.recommendationType;
    if (recType && !recommendationTypes.includes(recType)) {
      recommendationTypes.push(recType);
    }
  });
  return recommendationTypes;
}

function getNewRecommendationTypes(existingRecommendations, uploadedRecommendations) {
  const newRecommendationTypes = [];
  const existingTypes = getRecommendationTypes(existingRecommendations ?? []);
  const uploadedTypes = getRecommendationTypes(uploadedRecommendations ?? []);
  uploadedTypes.forEach(uploadedType => {
    if (!existingTypes.includes(uploadedType)) {
      newRecommendationTypes.push(uploadedType);
    }
  });
  return newRecommendationTypes;
}

const ResearchListScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const { id: objId } = useParams();
  const isNew = !objId;
  const { researchList, isLoading: isLoadingResearchList } = useResearchListQuery(objId);
  const hasEditAccess = useEditPermission(researchList);
  const hasCreateAccess = useCreatePermission(PermissionResourceName.RESEARCH_LIST);
  const hasAccess = isNew ? hasCreateAccess : hasEditAccess;
  const { researchLists } = useResearchListsQuery();
  const {
    createResearchList,
    deleteResearchList,
    updateResearchList,
    isLoading: isLoadingMutation,
  } = useEveryResearchListMutation();

  const { draft: draftName, setDraft: setDraftName } = useDraftString(researchList?.name);
  const { draft: draftIdColName, setDraft: setDraftIdColName } = useDraftString(
    researchList?.idColName
  );
  const { draft: draftRecColName, setDraft: setDraftRecColName } = useDraftString(
    researchList?.recColName
  );
  const { draft: draftRecommendations, setDraft: setDraftRecommendations } = useDraftObjectList(
    researchList?.recommendations,
    { idPropName: 'asset' }
  );

  const {
    draft: draftRecTypesDefaults,
    setDraft: setDraftRecTypesDefaults,
    dispatchDraft: dispatchDraftRecTypesDefaults,
  } = useDraftObject(undefined);

  const draft = {
    name: draftName,
    idColName: draftIdColName,
    recColName: draftRecColName,
    recommendations: draftRecommendations,
    recommendationsTypesDefaults: draftRecTypesDefaults,
  };
  const original = { ...researchList, recommendationsTypesDefaults: {} };

  const onSaveClick = () => {
    if (isNew) {
      createResearchList(draft, {
        onSuccess: data => {
          history.push(generatePath(Routes.RESEARCH_LIST, { id: data.id }));
        },
      });
    } else {
      // check, if there are new recommendation types.
      // if yes, we should set default actions for each of them to be applied to all existing
      // ResearchConstraintLists (presets).
      const newRecommendationTypes = getNewRecommendationTypes(
        researchList?.recommendations,
        draftRecommendations
      );
      if (newRecommendationTypes.length > 0) {
        setIsOpen(true);
        setDraftRecTypesDefaults(makeInitialRecommendationTypesDefaults(newRecommendationTypes));
      } else {
        updateResearchList({ id: objId, data: draft });
      }
    }
  };

  const onSaveNameClick = () => {
    updateResearchList({ id: objId, data: { name: draftName } });
  };

  const onFinalSaveClick = () => {
    setIsOpen(false);
    setDraftRecTypesDefaults(undefined);
    setDraftRecommendations(undefined);
    updateResearchList({ id: objId, data: draft });
  };

  const onDeleteClick = () => {
    deleteResearchList(objId, {
      onSuccess: () => {
        history.push(Routes.CHOOSE_RESEARCH_LIST);
      },
    });
  };

  return (
    <ObjectScreenBase
      disabled={isLoadingMutation}
      onSaveNameClick={onSaveNameClick}
      waitWhile={isLoadingResearchList}
      object={researchList}
      objects={researchLists}
      originalObj={original}
      draftObj={draft}
      onSaveClick={onSaveClick}
      onDeleteClick={onDeleteClick}
      hasAccess={hasAccess}
      translationPath='researchListScreen'
      propsToTriggerDialog={['recommendations', 'idColName', 'recColName']}
      routeNew={Routes.NEW_RESEARCH_LIST}
      routeChoose={Routes.CHOOSE_RESEARCH_LIST}
      routeObject={Routes.RESEARCH_LIST}
      draftName={draftName}
      setDraftName={setDraftName}
      urlNext={generateSearchPath(Routes.CHOOSE_RESEARCH_CONSTRAINT_LIST, {
        searchParams: { researchListId: objId },
      })}
    >
      <div className={classes.content}>
        <RecommendationsTab
          draftIdColName={draftIdColName}
          setDraftIdColName={setDraftIdColName}
          draftRecColName={draftRecColName}
          setDraftRecColName={setDraftRecColName}
          draftRecommendations={draftRecommendations}
          setDraftRecommendations={setDraftRecommendations}
          hasAccess={hasAccess}
        />
      </div>
      <MyDialog
        isOpen={isOpen}
        header='researchListScreen.defaultActionsDialog.header'
        message='researchListScreen.defaultActionsDialog.message'
        notPerformKey={`dialogKey.${DialogKeys.DISCARD}`}
        performKey={`dialogKey.${DialogKeys.SAVE}`}
        onNotPerformClick={() => setIsOpen(false)}
        onPerformClick={() => onFinalSaveClick()}
      >
        {Object.entries(draftRecTypesDefaults).map(([recTypeName, action]) => (
          <ConstraintDropdown
            key={recTypeName}
            text={recTypeName}
            value={action}
            onChange={value => {
              dispatchDraftRecTypesDefaults({ type: 'add', key: recTypeName, value });
            }}
          />
        ))}
      </MyDialog>
    </ObjectScreenBase>
  );
};

export default ResearchListScreen;

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';

import SimpleText from 'components/SimpleText';
import {
  restoreWidgetConfig,
  updateWidgetConfig,
  updateWidgetSavedValues,
} from 'screens/Report/redux/actions';
import ConfigurationFrame from 'screens/Report/ReportScreen/components/ConfigurationFrame';
import { widgetType } from 'utils/types';

const TextWidgetConfiguration = ({ closeAction, config }) => {
  const { text: defaultText } = config.configuration;

  const dispatch = useDispatch();

  const [text, setText] = useState(defaultText.value);
  useEffect(() => {
    dispatch(
      updateWidgetConfig(config.id, {
        text: {
          ...config.configuration.text,
          value: text,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <ConfigurationFrame
      closeAction={() => {
        dispatch(restoreWidgetConfig(config.id));
        closeAction();
      }}
      saveAction={() => {
        dispatch(updateWidgetSavedValues(config.id));
        closeAction();
      }}
    >
      <SimpleText translateId='dashboard.text' fontWeight='bold' mb={2} />
      <ReactQuill
        value={text}
        // https://github.com/zenoamaro/react-quill#using-deltas
        onChange={(_, __, ___, editor) => setText(editor.getContents())}
        theme='snow'
        style={{ height: '450px' }}
        modules={{
          toolbar: [
            [{ font: [] }, { size: [] }, 'bold', 'italic', 'underline'],
            [{ color: [] }, { background: [] }, { script: 'sub' }, { script: 'super' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
      />
    </ConfigurationFrame>
  );
};

TextWidgetConfiguration.propTypes = {
  closeAction: PropTypes.func.isRequired,
  config: widgetType.isRequired,
};

export default TextWidgetConfiguration;

import debug from 'debug';
import _ from 'lodash';

const appName = 'WL';
const levels = {
  error: 0,
  warn: 1,
  info: 2,
  verbose: 3,
  debug: 4,
  silly: 5,
};

export default function createLogger(name) {
  const logger = {};
  _.forOwn(levels, (value, level) => {
    const loggerLevel = debug(`${appName}:${level}:${name}`);
    logger[level] = loggerLevel;
  });
  return logger;
}

import { Card, CardActions, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import MyProgress from 'components/MyProgress';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import { useTabs } from 'lib/navigation/navigation';
import { Id } from 'lib/types';
import PortfolioMessages from 'screens/Portfolio/lib/components/PortfolioMessages';
import { PORTFOLIO_TAB_NAMES, PortfolioTabName } from 'screens/Portfolio/lib/constants';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { CARD_MIN_HEIGHT } from 'screens/Portfolio/PortfolioScreen/components/PortfolioOverviewTab/cards/constants';
import { Routes } from 'utils/constants';

const translationPath = 'portfolioScreen.portfolioOverview.alertsCard' as const;

const useStyles = makeStyles(theme => ({
  section: {
    paddingTop: theme.spacing(6),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    minHeight: CARD_MIN_HEIGHT,
    flexGrow: 1,
  },
}));

const AlertsCard = ({ portfolioId }: { portfolioId: Id }) => {
  const classes = useStyles();
  const history = useHistory();
  const { portfolio, isLoading } = usePortfolioQuery(portfolioId);
  const { onTabChange } = useTabs(PORTFOLIO_TAB_NAMES);

  const handleViewMoreClick = () => {
    history.push(generatePath(Routes.PORTFOLIO, { id: portfolioId }));
    onTabChange(PortfolioTabName.REBALANCE);
  };

  if (isLoading || !portfolio) {
    return (
      <Card>
        <CardContent className={classes.content}>
          <SimpleText translateId={`${translationPath}.title`} variant='h2' />
          <MyProgress />
        </CardContent>
      </Card>
    );
  }
  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <SimpleText translateId={`${translationPath}.title`} variant='h2' />
          <div className={classes.section}>
            <PortfolioMessages portfolio={portfolio} />
          </div>
        </CardContent>
        <CardActions>
          <SimpleButton
            translateId={`${translationPath}.viewMore`}
            onClick={handleViewMoreClick}
            variant='lowEmp'
          />
        </CardActions>
      </Card>
    </>
  );
};

export default AlertsCard;

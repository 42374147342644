import { Card, CardContent, Grid, Hidden } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import MyProgress from 'components/MyProgress';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import { Id, ValueOf } from 'lib/types';
import {
  ALLOCATION_CHART_HEIGHT,
  AllocationChip,
  DEFAULT_COMPARE_ALLOC_CHIPS,
  DEFAULT_SINGLE_ALLOC_CHIPS,
} from 'lib/weights/constants';
import useAllocationChips from 'lib/weights/useAllocationChips';
import useSingleAllocationChips from 'lib/weights/useSingleAllocationChips';
import WeightsBarChart from 'lib/weights/WeightsBarChart';
import WeightsChart from 'lib/weights/WeightsChart';
import ClosestPortfolioWarning from 'screens/Portfolio/lib/components/ClosestPortfolioWarning';
import OutdatedPortfolioWarning from 'screens/Portfolio/lib/components/OutdatedPortfolioWarning';
import { getWeights } from 'screens/Portfolio/lib/helpers';
import {
  useIsClosest,
  useIsOutdated,
  useOptimizationError,
} from 'screens/Portfolio/lib/hooks/portfolio';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import {
  isOptimAllocationType,
  OptimizationError,
  PortfolioAllocationTypeT,
  Weights,
} from 'screens/Portfolio/lib/types';
import { useIsOptimJobDone } from 'screens/Task/lib/hooks/useJobStatus';

const translationPath = 'portfolioLib.weightsChartComparisonCard' as const;

const useStyles = makeStyles(theme => ({
  mainContent: {
    height: ALLOCATION_CHART_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  warningBar: {
    paddingTop: theme.spacing(2),
  },
}));

interface WeightsChartComparisonCardProps {
  portfolioId?: Id;
  allocationType?: PortfolioAllocationTypeT;
  portfolioIdCompare?: Id;
  allocationTypeCompare?: PortfolioAllocationTypeT;
}

const getErrorTranslateId = (
  portfolioId: Id | undefined,
  allocationType: PortfolioAllocationTypeT | undefined
) => {
  if (portfolioId === undefined) {
    return 'noPortfolioId';
  }
  if (allocationType === undefined) {
    return 'noAllocationType';
  }
  return 'weightsNotAvailable';
};

const getSingleContent = (
  weights: Weights | undefined,
  toggledValues: ReadonlyArray<ValueOf<typeof AllocationChip>>,
  isLoading: boolean,
  portfolioId: Id | undefined,
  allocationType: PortfolioAllocationTypeT | undefined,
  jobIsDone: boolean,
  optimError: OptimizationError
) => {
  const progress = (
    <MyProgress
      translateId={`${translationPath}.${!jobIsDone ? 'runningOptimization' : 'loadingWeights'}`}
    />
  );
  let content;
  if (isLoading || !jobIsDone) {
    content = progress;
  } else if (weights === undefined) {
    content = (
      <SimpleText
        color='error.main'
        variant='h3'
        translateId={`${translationPath}.${getErrorTranslateId(portfolioId, allocationType)}`}
      />
    );
  } else if (optimError.isError) {
    content = (
      <SimpleText
        color='error.main'
        variant='h3'
        translateId={`${translationPath}.optimizationError`}
        translateData={{ errorMsg: optimError.errorMsg }}
      />
    );
  } else {
    content = <WeightsChart weights={weights} toggledChips={toggledValues} />;
  }
  return content;
};

const getBarChartErrorTranslateId = (
  portfolioId: Id | undefined,
  allocationType: PortfolioAllocationTypeT | undefined,
  portfolioIdCompare: Id | undefined,
  allocationTypeCompare: PortfolioAllocationTypeT | undefined
) => {
  if (portfolioId === undefined || portfolioIdCompare === undefined) {
    return 'noPortfolioId';
  }
  if (allocationType === undefined || allocationTypeCompare === undefined) {
    return 'noAllocationType';
  }
  return 'weightsNotAvailable';
};

const getBarChartContent = (
  weights: Weights | undefined,
  weightsCompare: Weights | undefined,
  chosenValue: ValueOf<typeof AllocationChip>,
  isLoading: boolean,
  isLoadingCompare: boolean,
  portfolioId: Id | undefined,
  allocationType: PortfolioAllocationTypeT | undefined,
  portfolioIdCompare: Id | undefined,
  allocationTypeCompare: PortfolioAllocationTypeT | undefined,
  jobIsDone: boolean,
  jobIsDoneCompare: boolean,
  optimError: OptimizationError,
  optimErrorCompare: OptimizationError
) => {
  const oneOfJobsNotDone = !jobIsDone || !jobIsDoneCompare;
  const progress = (
    <MyProgress
      translateId={`${translationPath}.${
        oneOfJobsNotDone ? 'runningOptimization' : 'loadingWeights'
      }`}
    />
  );
  let content;
  if (isLoading || isLoadingCompare || oneOfJobsNotDone) {
    content = progress;
  } else if (weights === undefined || weightsCompare === undefined) {
    content = (
      <SimpleText
        color='error.main'
        variant='h3'
        translateId={`${translationPath}.${getBarChartErrorTranslateId(
          portfolioId,
          allocationType,
          portfolioIdCompare,
          allocationTypeCompare
        )}`}
      />
    );
  } else if (optimError.isError || optimErrorCompare.isError) {
    content = (
      <SimpleText
        color='error.main'
        variant='h3'
        translateId={`${translationPath}.optimizationError`}
        translateData={{ errorMsg: optimError.errorMsg }}
      />
    );
  } else {
    content = (
      <WeightsBarChart
        weights={weights}
        weightsCompare={weightsCompare}
        toggledChip={chosenValue}
      />
    );
  }
  return content;
};

const WeightsChartComparisonCard = ({
  portfolioId,
  allocationType,
  portfolioIdCompare,
  allocationTypeCompare,
}: WeightsChartComparisonCardProps) => {
  const classes = useStyles();
  const [isBarChart, toggleIsBarChart] = React.useReducer(previous => !previous, false);
  const { toggledValues, toolbarChipsElem: toolbarChipsMultipleChoiceElem } = useAllocationChips({
    chipValues: DEFAULT_SINGLE_ALLOC_CHIPS,
  });
  const { chosenValue, toolbarChipsElem: toolbarChipsSingleChoiceElem } = useSingleAllocationChips({
    chipValues: DEFAULT_COMPARE_ALLOC_CHIPS,
  });

  const { portfolio, isLoading } = usePortfolioQuery(portfolioId);
  const { portfolio: portfolioCompare, isLoading: isLoadingCompare } =
    usePortfolioQuery(portfolioIdCompare);

  const { jobIsDone, isLoading: isLoadingJob } = useIsOptimJobDone(portfolioId, allocationType);
  const { jobIsDone: jobIsDoneCompare, isLoading: isLoadingJobCompare } = useIsOptimJobDone(
    portfolioIdCompare,
    allocationTypeCompare
  );

  const { isClosest } = useIsClosest(portfolioId, allocationType);
  const { isClosest: isClosestCompare } = useIsClosest(portfolioIdCompare, allocationTypeCompare);
  const showClosestWarning = isOptimAllocationType(allocationType) && isClosest && jobIsDone;
  const showClosestWarningCompare =
    isOptimAllocationType(allocationTypeCompare) && isClosestCompare && jobIsDoneCompare;
  const showAnyOfClosestWarnings = showClosestWarning || showClosestWarningCompare;

  const { isOutdated } = useIsOutdated(portfolioId, allocationType);
  const { isOutdated: isOutdatedCompare } = useIsOutdated(
    portfolioIdCompare,
    allocationTypeCompare
  );
  const showOutdatedWarning = isOptimAllocationType(allocationType) && isOutdated && jobIsDone;
  const showOutdatedWarningCompare =
    isOptimAllocationType(allocationTypeCompare) && isOutdatedCompare && jobIsDoneCompare;
  const showAnyOfOutdatedWarnings = showOutdatedWarning || showOutdatedWarningCompare;

  const weights = getWeights(portfolio, allocationType);
  const weightsCompare = getWeights(portfolioCompare, allocationTypeCompare);

  const optimError = useOptimizationError(portfolioId, allocationType);
  const optimErrorCompare = useOptimizationError(portfolioIdCompare, allocationTypeCompare);

  const content = getSingleContent(
    weights,
    toggledValues,
    isLoading || isLoadingJob,
    portfolioId,
    allocationType,
    jobIsDone,
    optimError
  );
  const contentCompare = getSingleContent(
    weightsCompare,
    toggledValues,
    isLoadingCompare || isLoadingJobCompare,
    portfolioIdCompare,
    allocationTypeCompare,
    jobIsDoneCompare,
    optimErrorCompare
  );
  const barChartContent = getBarChartContent(
    weights,
    weightsCompare,
    chosenValue,
    isLoading,
    isLoadingCompare,
    portfolioId,
    allocationType,
    portfolioIdCompare,
    allocationTypeCompare,
    jobIsDone,
    jobIsDoneCompare,
    optimError,
    optimErrorCompare
  );
  const closestWarning = showClosestWarning && (
    <div className={classes.warningBar}>
      <ClosestPortfolioWarning portfolioId={portfolioId} allocationType={allocationType} />
    </div>
  );
  const closestWarningCompare = showClosestWarningCompare && (
    <div className={classes.warningBar}>
      <ClosestPortfolioWarning
        portfolioId={portfolioIdCompare}
        allocationType={allocationTypeCompare}
      />
    </div>
  );
  const outdatedWarning = showOutdatedWarning && (
    <div className={classes.warningBar}>
      <OutdatedPortfolioWarning portfolioId={portfolioId} allocationType={allocationType} />
    </div>
  );
  const outdatedWarningCompare = showOutdatedWarningCompare && (
    <div className={classes.warningBar}>
      <OutdatedPortfolioWarning
        portfolioId={portfolioIdCompare}
        allocationType={allocationTypeCompare}
      />
    </div>
  );
  return (
    <Card>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={9}>
            {isBarChart ? toolbarChipsSingleChoiceElem : toolbarChipsMultipleChoiceElem}
          </Grid>
          <Grid item xs={3}>
            <div style={{ float: 'right' }}>
              <SimpleButton
                translateId={`${translationPath}.switchTo${isBarChart ? 'Pie' : 'Bar'}Chart`}
                onClick={toggleIsBarChart}
                variant='midEmp'
              />
            </div>
          </Grid>
        </Grid>
        <Hidden mdDown={!isBarChart}>
          {showAnyOfOutdatedWarnings && (
            <Grid container spacing={4}>
              <Grid item xs={6}>
                {outdatedWarning}
              </Grid>
              <Grid item xs={6}>
                {outdatedWarningCompare}
              </Grid>
            </Grid>
          )}
        </Hidden>
        <Hidden mdDown={!isBarChart}>
          {showAnyOfClosestWarnings && (
            <Grid container spacing={4}>
              <Grid item xs={6}>
                {closestWarning}
              </Grid>
              <Grid item xs={6}>
                {closestWarningCompare}
              </Grid>
            </Grid>
          )}
        </Hidden>

        <Grid container spacing={4}>
          {isBarChart ? (
            <Grid item xs={12}>
              <div className={classes.mainContent}>{barChartContent}</div>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <Hidden mdUp>{outdatedWarning}</Hidden>
                <Hidden mdUp>{closestWarning}</Hidden>
                <div className={classes.mainContent}>{content}</div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Hidden mdUp>{outdatedWarningCompare}</Hidden>
                <Hidden mdUp>{closestWarningCompare}</Hidden>
                <div className={classes.mainContent}>{contentCompare}</div>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WeightsChartComparisonCard;

function truncateLeadingZeros(s) {
  return s.replace(/^0{2,}/, '');
}

function replaceCommasWithDots(s) {
  return s.replace(',', '.');
}

export function normalizeFloatString(s) {
  return truncateLeadingZeros(replaceCommasWithDots(s));
}

export function parseNumber(s) {
  return Number(s);
}

export function isParsableNumber(s) {
  return !Number.isNaN(Number(s));
}

export function endsWithDot(s) {
  return s.endsWith('.');
}

export function endsWithNumber(s) {
  return isParsableNumber(s.slice(-1));
}

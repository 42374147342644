import { Dialog, DialogActions, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import DialogButton from 'components/Buttons/DialogButton';
import SearchBar from 'components/SearchBar/SearchBarView';
import Table from 'components/Table/TableView';
import useCheckedColumns from 'components/Table/useCheckedColumns';
import { useAssetsQuery } from 'screens/Environment/lib/queries';
import { getUploadedIsins } from 'screens/Universe/lib/helpers';
import { DialogKeys } from 'utils/constants';
import { idArrayType } from 'utils/types';

const ALL_COLUMNS = ['isin', 'name', 'assetClass', 'superSector', 'sector', 'country', 'assetType'];
const DEFAULT_COLUMNS = [...ALL_COLUMNS];

const AssetsTable = ({
  availableAssetIds,
  draftAssetIds,
  dispatchDraftAssetIds,
  disabled,
  translatePath,
}) => {
  const { checkedColumns, handleColumnCheck } = useCheckedColumns(
    'AssetsTable.columns',
    DEFAULT_COLUMNS,
    ALL_COLUMNS
  );
  const [searchValue, setSearchValue] = useState('');
  const [searchValueDialog, setSearchValueDialog] = useState('');

  const { assets } = useAssetsQuery();

  const { draftAssets, availableAssets } = useMemo(() => {
    return {
      draftAssets: Object.values(assets).filter(asset => draftAssetIds.includes(asset.id)),
      availableAssets: Object.values(assets).filter(
        asset => !draftAssetIds.includes(asset.id) && availableAssetIds.includes(asset.id)
      ),
    };
  }, [assets, draftAssetIds, availableAssetIds]);

  const [open, setOpen] = React.useState(false);
  const [checkedAssetIds, setCheckedAssetIds] = useState([]);

  const handleAssetIdsToggle = assetId => {
    const currentIndex = checkedAssetIds.indexOf(assetId);
    const newChecked = [...checkedAssetIds];

    if (currentIndex === -1) {
      newChecked.push(assetId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedAssetIds(newChecked);
  };

  const handleDiscard = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    dispatchDraftAssetIds({ type: 'add', value: checkedAssetIds });
    setOpen(false);
  };

  const onSuccessfulUpload = useCallback(
    uploadedData => {
      const isins = getUploadedIsins(uploadedData);
      const assetIdsToAdd = availableAssets
        .filter(asset => isins.includes(asset.isin))
        .map(asset => asset.id);
      dispatchDraftAssetIds({ type: 'add', value: assetIdsToAdd });
    },
    [availableAssets, dispatchDraftAssetIds]
  );
  return (
    <>
      <Dialog
        fullWidth
        maxWidth='xl'
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='add-asset-dialog-title'
        TransitionProps={{
          onExited: () => setCheckedAssetIds([]),
        }}
      >
        <DialogContent>
          <SearchBar
            searchValue={searchValueDialog}
            onUpdateSearch={setSearchValueDialog}
            placeholderId='universe'
          />
          <Table
            titleTranslateId={`${translatePath}.availableAssetsTableName`}
            translateTableId='assetTable'
            tableObjects={availableAssets}
            columns={ALL_COLUMNS}
            checkedColumns={checkedColumns}
            onColumnCheck={handleColumnCheck}
            searchValue={searchValueDialog}
            deletableRows={false}
            isWithCheckbox
            isDownloadButton
            isTableWhite
            showEmptyRows={false}
            onRowClick={id => handleAssetIdsToggle(id)}
            onSelectAllClick={(ids, numSelected) => {
              if (numSelected === 0) {
                setCheckedAssetIds(ids);
              } else {
                setCheckedAssetIds([]);
              }
            }}
            selectedObjectIds={checkedAssetIds}
          />
        </DialogContent>
        <DialogActions>
          <DialogButton
            onClick={() => handleDiscard()}
            translateId={`dialogKey.${DialogKeys.DISCARD}`}
            variant='lowEmp'
          />
          <DialogButton
            onClick={() => handleAdd()}
            translateId='basketScreen.dialogKey.addAssets'
            variant='lowEmp'
          />
        </DialogActions>
      </Dialog>

      <SearchBar
        searchValue={searchValue}
        onUpdateSearch={setSearchValue}
        placeholderId='universe'
      />
      <Table
        titleTranslateId={`${translatePath}.ownAssetsTableName`}
        translateTableId='assetTable'
        tableObjects={draftAssets}
        columns={ALL_COLUMNS}
        checkedColumns={checkedColumns}
        onColumnCheck={handleColumnCheck}
        searchValue={searchValue}
        deletableRows={!disabled}
        isWithCheckbox={!disabled}
        onRowClick={!disabled ? undefined : () => {}}
        isDownloadButton
        isUploadButton={!disabled}
        onSuccessfulUpload={onSuccessfulUpload}
        isTableWhite
        showEmptyRows={false}
        onDeleteRowClick={obj => dispatchDraftAssetIds({ type: 'remove', value: [obj.id] })}
        onDeleteSelectedClick={ids => dispatchDraftAssetIds({ type: 'remove', value: ids })}
        onAddRowClick={!disabled ? () => setOpen(true) : undefined}
        addRowTranslateId={`${translatePath}.addAssetsTooltip`}
      />
    </>
  );
};

AssetsTable.propTypes = {
  availableAssetIds: idArrayType.isRequired,
  draftAssetIds: idArrayType.isRequired,
  dispatchDraftAssetIds: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  translatePath: PropTypes.string.isRequired,
};

AssetsTable.defaultProps = {
  disabled: false,
};

export default AssetsTable;

import { Link, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory } from 'react-router-dom';

import MyTimeout from 'components/MyTimeout';
import SimpleButton from 'components/SimpleButton';
import { useTranslate } from 'lib/localization/localization';
import { useURLSearchParams } from 'lib/navigation/navigation';
import LoginScreenBase from 'screens/Login/lib/components/LoginScreenBase';
import { useRequestResetLinkMutation } from 'screens/Login/lib/queries';
import { Routes } from 'utils/constants';

const translationPath = 'forgetPasswordScreen';

const useStyles = makeStyles(theme => ({
  section: {
    paddingTop: theme.spacing(6),
  },
  root: {
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  button: {
    paddingTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ForgetPasswordScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const searchParams = useURLSearchParams<['email']>();
  const translate = useTranslate();
  const { mutate: requestResetLink, isLoading } = useRequestResetLinkMutation();

  const [email, setEmail] = React.useState(searchParams.email ?? '');
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

  const handleBackToLoginClick = (event: any) => {
    event.preventDefault();
    history.push(Routes.LOGIN);
  };

  return (
    <LoginScreenBase>
      <div>
        {isButtonDisabled ? (
          <MyTimeout onTimeout={() => setIsButtonDisabled(false)} ms={60 * 1000} />
        ) : null}
        <div className={classes.root}>
          <div className={classes.section}>
            <TextField
              size='small'
              required
              fullWidth
              label={translate(`${translationPath}.email`)}
              variant='outlined'
              value={email}
              onChange={event => {
                setEmail(event.target.value);
              }}
            />
          </div>
          <div className={classes.section}>
            <Link href={Routes.LOGIN} onClick={handleBackToLoginClick}>
              {translate(`${translationPath}.backToLogin`)}
            </Link>
          </div>
          <div className={classes.button}>
            <SimpleButton
              clickOnEnter
              disabled={isButtonDisabled || isLoading}
              size='large'
              onClick={() =>
                requestResetLink(
                  { email },
                  {
                    onSuccess: () => {
                      setIsButtonDisabled(true);
                    },
                  }
                )
              }
              variant='highEmp'
              translateId={`${translationPath}.sendLinkButton`}
            />
          </div>
        </div>
      </div>
    </LoginScreenBase>
  );
};

export default ForgetPasswordScreen;

import { Card, CardActions, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import MyProgress from 'components/MyProgress';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import { FunctionalityName } from 'lib/access/constants';
import useFunctionalityOn from 'lib/access/useFunctionalityOn';
import { useTabs } from 'lib/navigation/navigation';
import {
  getCumReturn,
  getMaxDrawDown,
  parseDatesInSeries,
  sortAndFillMissingData,
} from 'lib/timeSeries/tsData';
import { Id } from 'lib/types';
import { PORTFOLIO_TAB_NAMES, PortfolioTabName } from 'screens/Portfolio/lib/constants';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { TimeSeries } from 'screens/Portfolio/lib/types';
import { CARD_MIN_HEIGHT } from 'screens/Portfolio/PortfolioScreen/components/PortfolioOverviewTab/cards/constants';
import { Routes } from 'utils/constants';

const translationPath = 'portfolioScreen.portfolioOverview.metricsCard' as const;

function percToString(percValue?: number | null): string {
  return percValue ? `${(100 * percValue).toFixed(2)} %` : 'NA';
}

function computeMetrics(performance: TimeSeries | undefined) {
  if (!performance) {
    return { cumReturnStr: 'NA', maxDDStr: 'NA' };
  }
  const sortedPerformance = sortAndFillMissingData(parseDatesInSeries(performance));
  const cumReturn = getCumReturn(sortedPerformance);
  const maxDD = getMaxDrawDown(sortedPerformance);
  const cumReturnStr = percToString(cumReturn);
  const maxDDStr = percToString(maxDD);
  return { cumReturnStr, maxDDStr };
}

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    minHeight: CARD_MIN_HEIGHT,
    flexGrow: 1,
  },
  section: {
    paddingTop: theme.spacing(6),
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
}));

const MetricsCard = ({ portfolioId }: { portfolioId: Id }) => {
  const classes = useStyles();
  const history = useHistory();
  const isDoubleAlertOn = useFunctionalityOn(FunctionalityName.DOUBLE_ALERT);
  const { portfolio, isLoading } = usePortfolioQuery(portfolioId);
  const { onTabChange } = useTabs(PORTFOLIO_TAB_NAMES);
  const { cumReturnStr, maxDDStr } = useMemo(() => {
    return computeMetrics(portfolio?.history?.performance);
  }, [portfolio]);

  const handleViewMoreClick = () => {
    history.push(generatePath(Routes.PORTFOLIO, { id: portfolioId }));
    onTabChange(PortfolioTabName.PERFORMANCE);
  };

  if (isLoading || !portfolio) {
    return (
      <Card>
        <CardContent className={classes.content}>
          <SimpleText translateId={`${translationPath}.title`} variant='h2' />
          <MyProgress />
        </CardContent>
      </Card>
    );
  }
  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <SimpleText translateId={`${translationPath}.title`} variant='h2' />
          <div>
            <div className={classes.section}>
              <SimpleText translateId={`${translationPath}.cumReturn`} variant='subtitle1' />
              <SimpleText text={cumReturnStr} variant='body1' />
            </div>
            <div className={classes.section}>
              <SimpleText translateId={`${translationPath}.maxDD`} variant='subtitle1' />
              <SimpleText text={maxDDStr} variant='body1' />
            </div>
            {isDoubleAlertOn && (
              <>
                <div className={classes.section}>
                  <SimpleText
                    translateId={`${translationPath}.expectedReturn`}
                    variant='subtitle1'
                  />
                  <SimpleText text={percToString(portfolio?.currentState?.ret)} variant='body1' />
                </div>
                <div className={classes.section}>
                  <SimpleText translateId={`${translationPath}.expectedCvar`} variant='subtitle1' />
                  <SimpleText text={percToString(portfolio?.currentState?.cvar)} variant='body1' />
                </div>
                <div className={classes.section}>
                  <SimpleText
                    translateId={`${translationPath}.expectedReturnOptim`}
                    variant='subtitle1'
                  />
                  <SimpleText
                    text={percToString(portfolio?.optimizationState?.optimizationResult?.ret)}
                    variant='body1'
                  />
                </div>
                <div className={classes.section}>
                  <SimpleText
                    translateId={`${translationPath}.expectedCvarOptim`}
                    variant='subtitle1'
                  />
                  <SimpleText
                    text={percToString(portfolio?.optimizationState?.optimizationResult?.cvar)}
                    variant='body1'
                  />
                </div>
              </>
            )}
          </div>
        </CardContent>
        <CardActions>
          <SimpleButton
            translateId={`${translationPath}.viewMore`}
            onClick={handleViewMoreClick}
            variant='lowEmp'
          />
        </CardActions>
      </Card>
    </>
  );
};

export default MetricsCard;

import { Id } from 'lib/types';
import { PortfolioAllocationType } from 'screens/Portfolio/lib/constants';
import { hasNewStrategy } from 'screens/Portfolio/lib/helpers';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { PortfolioAllocationTypeT } from 'screens/Portfolio/lib/types';
import { isDone } from 'screens/Task/lib/helpers';
import { useOptimizationJobQuery } from 'screens/Task/lib/queries';

export const useIsOptimJobDone = (
  portfolioId: Id | undefined,
  forAllocationType: PortfolioAllocationTypeT | undefined
) => {
  const { job, isLoading } = useOptimizationJobQuery(portfolioId);
  const { portfolio, isLoading: isLoadingPortfolio } = usePortfolioQuery(portfolioId);

  let allocationForJob: PortfolioAllocationTypeT = PortfolioAllocationType.OPTIMIZED_NEW;
  if (portfolio && !hasNewStrategy(portfolio)) {
    allocationForJob = PortfolioAllocationType.OPTIMIZED;
  }

  const jobIsDone =
    job !== undefined && forAllocationType === allocationForJob ? isDone(job) : true;

  return { jobIsDone, isLoading: isLoading || isLoadingPortfolio };
};

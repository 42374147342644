import PropTypes from 'prop-types';
import React from 'react';
import ContainerDimensions from 'react-container-dimensions';

import BarChartBase from './BarChartBase';

import { BarChartDataType } from 'utils/types';

const BarChartWrapper = ({ data, additionalWidth, padding }) => (
  <ContainerDimensions>
    {({ width, height }) => (
      <BarChartBase width={width + additionalWidth} height={height} data={data} padding={padding} />
    )}
  </ContainerDimensions>
);

BarChartWrapper.propTypes = {
  data: BarChartDataType.isRequired,
  additionalWidth: PropTypes.number,
  padding: PropTypes.number,
};

BarChartWrapper.defaultProps = {
  additionalWidth: 0,
  padding: 30,
};

export default BarChartWrapper;

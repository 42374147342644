/**
 * Get institutes, to which user is assigned.
 * @param {object | undefined} user: types.UserType
 * @param {object | undefined} userGroups: object or array of types.UserGroupType
 * @return instituteIds: array of institute ids
 */
export function getAvailableInstituteIds(user, userGroups) {
  if (!user || !userGroups) {
    return [];
  }
  const availableInstituteIds = [];
  for (const userGroupId of user.groups) {
    const userGroup = userGroups[userGroupId];
    if (userGroup) {
      const instituteId = userGroup.institute;
      if (!availableInstituteIds.includes(instituteId) && instituteId) {
        availableInstituteIds.push(instituteId);
      }
    }
  }
  return availableInstituteIds;
}

import AssessmentIcon from '@mui/icons-material/Assessment';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';
import { TranslateFunction } from 'react-localize-redux';

import DialogButton from '../../components/Buttons/DialogButton';
import SimpleText from '../../components/SimpleText';
import { useReplaceAssetToDerivativeMutation } from '../../screens/Portfolio/lib/queries';
import { FunctionalityName } from '../access/constants';
import useFunctionalityOn from '../access/useFunctionalityOn';

import MyProgress from 'components/MyProgress';
import Table from 'components/Table/TableView';
import useCheckedColumns from 'components/Table/useCheckedColumns';
import { useTranslate } from 'lib/localization/localization';
import { toGermanNumberString, toPercentageString } from 'lib/misc/misc';
import { Id } from 'lib/types';
import { ALL_WEIGHTS_TABLE_COLUMNS, DEFAULT_WEIGHTS_TABLE_COLUMNS } from 'lib/weights/constants';
import PaperTable from 'lib/weights/PaperTable';
import { useAssetsQuery } from 'screens/Environment/lib/queries';
import { AssetOverview, AssetOverviews } from 'screens/Environment/lib/types';
import { IndividualAlertType, USED_INDIVIDUAL_ALERT_TYPES } from 'screens/Portfolio/lib/constants';
import { MissingAssets } from 'screens/Portfolio/lib/helpers';
import { Derivative, PortfolioAllocationTypeT, Weights } from 'screens/Portfolio/lib/types';
import { DialogKeys, Order } from 'utils/constants';

const translationPath = 'lib.weights.portfolioWeightsTable' as const;

const useStyles = makeStyles(theme => ({
  errorIcon: {
    color: theme.palette.error.main,
    float: 'left',
    marginLeft: '1rem',
  },
}));

const ALERT_TYPES_TO_ICONS = {
  [IndividualAlertType.BASKET]: ShoppingBasketIcon,
  [IndividualAlertType.UNIVERSE]: ShoppingBasketIcon,
  [IndividualAlertType.RESEARCH]: AssessmentIcon,
} as const;

const ALERT_TYPES_TO_TRANSLATE_IDS = {
  [IndividualAlertType.BASKET]: `${translationPath}.missingBasket`,
  [IndividualAlertType.UNIVERSE]: `${translationPath}.missingUniverse`,
  [IndividualAlertType.RESEARCH]: `${translationPath}.missingResearch`,
} as const;

function makeAlertIdentifiers(
  missingAssets: MissingAssets | undefined,
  assets: AssetOverviews,
  classNameErrorIcon: string,
  translate: TranslateFunction,
  derivatives: Derivative[],
  assetsToDerivativesIds: { [key: Id]: Id | null }
) {
  const alertsAndIcons: { [key in Id]: JSX.Element } = {};

  USED_INDIVIDUAL_ALERT_TYPES.forEach(alertType => {
    if (missingAssets) {
      const IconClass = ALERT_TYPES_TO_ICONS[alertType];
      const translateId = ALERT_TYPES_TO_TRANSLATE_IDS[alertType];
      const missingAssetsOfType = missingAssets[alertType];
      missingAssetsOfType.forEach(missingAssetId => {
        let isin = assets[missingAssetId]?.isin ?? missingAssetId;
        if (assetsToDerivativesIds[missingAssetId]) {
          const derivative = derivatives.find(
            drv => drv.id === assetsToDerivativesIds[missingAssetId]
          );
          isin = derivative?.isin ?? isin;
        }
        const iconElement = (
          <Tooltip title={translate(translateId)}>
            <IconClass className={classNameErrorIcon} fontSize='small' />
          </Tooltip>
        );
        if (isin in alertsAndIcons) {
          alertsAndIcons[isin] = (
            <React.Fragment>
              {alertsAndIcons[isin]}
              {iconElement}
            </React.Fragment>
          );
        } else {
          alertsAndIcons[isin] = iconElement;
        }
      });
    }
  });
  return alertsAndIcons;
}

interface WeightsTableRow extends AssetOverview {
  weight: number;
}

export function makeWeightsTableData(
  assets: AssetOverviews,
  weights: Weights | undefined,
  derivatives: Derivative[],
  assetsToDerivativesIds: { [key: Id]: Id | null }
) {
  if (!assets || !weights) {
    return [];
  }
  const minWeightToShow = 0.0001;

  const data: WeightsTableRow[] = [];
  weights.forEach(weight => {
    const asset = assets[weight.assetId];
    if (asset && weight.weight >= minWeightToShow) {
      const item = {
        ...asset,
        weight: weight.weight,
      };
      if (assetsToDerivativesIds[asset.id]) {
        const derivative = derivatives.find(drv => drv.id === assetsToDerivativesIds[asset.id]);
        if (derivative) {
          item.name = derivative.name;
          item.isin = derivative.isin;
          // @ts-ignore
          item.instituteIdentifier = derivative.isin;
          item.assetType = derivative.assetType;
        }
      }

      data.push(item);
    }
  });
  return data;
}

interface WeightsTableProps {
  weights: Weights | undefined;
  portfolioName?: string;
  allocationType?: PortfolioAllocationTypeT;
  missingAssets?: MissingAssets;
  isSimpleTable?: boolean;
  size?: 'small' | 'medium' | 'large';
  orderBy?: string;
  order?: 'desc' | 'asc';
  columns?: string[];
  derivatives: Derivative[];
  portfolioId?: Id;
  assetsToDerivativesIds: { [key: Id]: Id | null };
}

const cellFormatting = { weight: toPercentageString };
const exportFormatting = { weight: toGermanNumberString };

const REPLACEMENT_TABLE_COLUMNS = ['isin', 'name', 'assetType'];

interface ReplaceDialogDerivative {
  id: Id | null;
  isin: string;
  name: string;
  assetType: string;
}

const ReplaceDialog = ({
  isReplaceDialogOpen,
  closeReplaceDialog,
  derivativesToReplace,
  portfolioId,
  replaceDialogAssetId,
}: {
  isReplaceDialogOpen: boolean;
  closeReplaceDialog: () => void;
  derivativesToReplace: ReplaceDialogDerivative[];
  portfolioId: Id;
  replaceDialogAssetId: Id;
}) => {
  const { mutate } = useReplaceAssetToDerivativeMutation(portfolioId);

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={isReplaceDialogOpen}
      onClose={closeReplaceDialog}
      aria-labelledby='replace-dialog-title'
      TransitionProps={{
        onExited: closeReplaceDialog,
      }}
    >
      <DialogTitle id='replace-title'>
        <SimpleText variant='h2' translateId={`${translationPath}.replaceDialogTitle`} />
      </DialogTitle>
      <DialogContent>
        <Table
          tableObjects={derivativesToReplace}
          onRowClick={(id: Id | null) => {
            mutate(
              {
                assetId: replaceDialogAssetId,
                derivativeId: id,
              },
              { onSuccess: closeReplaceDialog }
            );
          }}
          titleTranslateId={`${translationPath}.replaceTableTitle`}
          translateTableId='weightsTable'
          columns={REPLACEMENT_TABLE_COLUMNS}
          showEmptyRows={false}
          isTableWhite
          initialOrderBy='name'
          initialOrder={Order.DESC}
        />
      </DialogContent>
      <DialogActions>
        <DialogButton
          onClick={closeReplaceDialog}
          translateId={`dialogKey.${DialogKeys.CANCEL}`}
          variant='lowEmp'
        />
      </DialogActions>
    </Dialog>
  );
};

const WeightsTable = ({
  weights,
  portfolioName,
  allocationType,
  missingAssets,
  isSimpleTable = false,
  size = 'small',
  columns,
  orderBy,
  order,
  derivatives,
  portfolioId = undefined,
  assetsToDerivativesIds,
}: WeightsTableProps) => {
  const isFunctionalityOn = useFunctionalityOn(FunctionalityName.CERTIFICATES);
  const { assets, isLoading } = useAssetsQuery();
  const [isReplaceDialogOpen, setIsReplaceDialogOpen] = React.useState(false);
  const [replaceDialogAssetId, setReplaceDialogAssetId] = React.useState<Id | undefined>(undefined);
  const replaceableAssetIds = useMemo(() => {
    return derivatives.map(derivative => derivative.underlyingAssetId);
  }, [derivatives]);

  const derivativesToReplace = useMemo(() => {
    let currentDerivativeId: string | undefined | null;
    if (replaceDialogAssetId !== null && replaceDialogAssetId !== undefined) {
      currentDerivativeId = assetsToDerivativesIds[replaceDialogAssetId];
    }
    const arr: ReplaceDialogDerivative[] = derivatives.filter(
      derivative =>
        derivative.underlyingAssetId === replaceDialogAssetId &&
        derivative.id !== currentDerivativeId
    );

    if (
      currentDerivativeId !== null &&
      currentDerivativeId !== undefined &&
      replaceDialogAssetId !== null &&
      replaceDialogAssetId !== undefined
    ) {
      const replaceDialogAsset = assets[replaceDialogAssetId];
      if (replaceDialogAsset !== undefined) {
        arr.unshift({
          id: null,
          isin: replaceDialogAsset.isin,
          name: replaceDialogAsset.name,
          assetType: replaceDialogAsset.assetType,
        });
      }
    }
    return arr;
  }, [assets, assetsToDerivativesIds, derivatives, replaceDialogAssetId]);
  const closeReplaceDialog = () => {
    setIsReplaceDialogOpen(false);
    setReplaceDialogAssetId(undefined);
  };
  const openReplaceDialog = (id: Id) => {
    setIsReplaceDialogOpen(true);
    setReplaceDialogAssetId(id);
  };

  const classes = useStyles();
  const translate = useTranslate();

  const { checkedColumns, handleColumnCheck } = useCheckedColumns(
    'WeightsTable.columns',
    DEFAULT_WEIGHTS_TABLE_COLUMNS,
    ALL_WEIGHTS_TABLE_COLUMNS
  );

  const alertIdentifiers = useMemo(() => {
    return makeAlertIdentifiers(
      missingAssets,
      assets,
      classes.errorIcon,
      translate,
      derivatives,
      assetsToDerivativesIds
    );
  }, [missingAssets, assets, classes.errorIcon, translate, derivatives, assetsToDerivativesIds]);
  const tableObjects = useMemo(() => {
    return makeWeightsTableData(assets, weights, derivatives, assetsToDerivativesIds);
  }, [weights, assets, derivatives, assetsToDerivativesIds]);
  const allocationTypeName =
    allocationType !== undefined
      ? translate(`${translationPath}.allocationType.${allocationType}`)
      : undefined;
  const table = isSimpleTable ? (
    <PaperTable
      columns={columns ?? checkedColumns}
      tableObjects={tableObjects}
      size={size}
      cellFormatting={cellFormatting}
      translateId='weightsTable'
      orderBy={orderBy}
      order={order}
    />
  ) : (
    <Table
      tableObjects={tableObjects}
      onRowClick={(id: Id) => {
        if (isFunctionalityOn && replaceableAssetIds.includes(id)) {
          openReplaceDialog(id);
        }
      }}
      isDownloadButton
      downloadFileTitle={portfolioName ?? 'weights'}
      titleTranslateId={`${translationPath}.tableTitle`}
      titleTranslateData={{ portfolioName, allocationTypeName }}
      cellFormatting={cellFormatting}
      exportFormatting={exportFormatting}
      translateTableId='weightsTable'
      columns={ALL_WEIGHTS_TABLE_COLUMNS}
      checkedColumns={checkedColumns}
      onColumnCheck={handleColumnCheck}
      showEmptyRows={false}
      isTableWhite
      initialOrderBy='weight'
      initialOrder={Order.DESC}
      orderBy={orderBy}
      order={order}
      // @ts-ignore
      alertIdentifiers={alertIdentifiers}
      replaceableIds={isFunctionalityOn ? replaceableAssetIds : []}
    />
  );
  return (
    <>
      {!isLoading ? table : <MyProgress translateId={`${translationPath}.waitEnvironment`} />}
      {portfolioId && replaceDialogAssetId && (
        <ReplaceDialog
          portfolioId={portfolioId}
          isReplaceDialogOpen={isReplaceDialogOpen}
          closeReplaceDialog={closeReplaceDialog}
          derivativesToReplace={derivativesToReplace}
          replaceDialogAssetId={replaceDialogAssetId}
        />
      )}
    </>
  );
};

export default WeightsTable;

import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useIsPathAvailable } from 'lib/access/useIsPathAvailable';
import { toArray } from 'lib/misc/misc';
import { useAuth } from 'screens/Login/lib/queries';
import NoAccessScreen from 'screens/NoAccessScreen';
import { Routes } from 'utils/constants';
import { Route } from 'utils/extensions';

const AuthenticatingStub = () => <div data-test-id='AuthenticatingStub'>Authenticating...</div>;

const CheckAccessWrapper = ({ children }) => {
  const location = useLocation();

  const isPathAvailable = useIsPathAvailable(location.pathname);

  if (isPathAvailable) {
    // if user has enough rights to access the page, then open it
    return children;
  }
  // if user have not enough rights, then show no access screen
  return <NoAccessScreen />;
};

CheckAccessWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const AuthenticationWrapper = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, isAuthenticating } = useAuth();

  let toRender = null;
  if (isAuthenticating) {
    // if authentication is in process
    toRender = <AuthenticatingStub />;
  } else if (isAuthenticated) {
    // if user is Authenticated

    toRender = children;
  } else {
    // if user could not be authenticated with given email and pass
    toRender = (
      <Redirect
        to={{
          pathname: Routes.LOGIN,
          state: { from: location },
        }}
      />
    );
  }
  return toRender;
};

AuthenticationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

function RouteWithAuth({ path, exact, component }) {
  const pathsArray = toArray(path);
  const routes = [];
  const Component = component;

  for (const currentPath of pathsArray) {
    routes.push(
      <Route path={currentPath} exact={exact} key={currentPath}>
        <AuthenticationWrapper>
          <CheckAccessWrapper>
            <Component />
          </CheckAccessWrapper>
        </AuthenticationWrapper>
      </Route>
    );
  }

  return routes;
}

export default RouteWithAuth;

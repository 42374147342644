import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';

import PositionMe from 'components/PositionMe';
import { fuseSearch } from 'components/SearchBar/utils';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import { useSendFeedbackMutation } from 'screens/Help/lib/queries';
import { HelpType } from 'utils/types';

const useStyles = makeStyles(theme => ({
  allFrames: {
    paddingRight: '3.3rem',
    paddingLeft: '3.3rem',
  },
  mainHeader: {
    marginBottom: '1rem',
    width: '100%',
  },
  mainMessage: {
    width: '100%',
    marginBottom: '1rem',
  },
  subHeader: {
    marginBottom: '1rem',
    marginTop: '1rem',
    width: '100%',
  },
  subMessage: {
    width: '100%',
    marginBottom: '1rem',
  },
  tableOfContents: {
    marginBottom: '1rem',
    marginTop: '1rem',
    width: '100%',
    color: theme.palette.primary.main,
  },
  messageHeader: {
    marginBottom: '1rem',
    marginTop: '1rem',
    width: '100%',
  },
  videosAndPictures: {
    maxWidth: '100%',
    margin: '2rem',
  },
  subtopicContainer: {
    marginBottom: '2.5rem',
  },
  wasInfoHelpful: {
    marginTop: '10rem',
    marginBottom: '2rem',
  },
  yesNoButtons: {
    height: '3rem',
    marginBottom: '5rem',
  },
}));

const HelpTopicView = ({ data, searchValue }) => {
  const classes = useStyles();

  const { mutate: onSendClick, isLoading } = useSendFeedbackMutation();
  function onContentClick(id) {
    const topPosition = document.getElementById(id).offsetTop - 90;
    window.scrollTo({
      top: topPosition,
      left: 0,
      behavior: 'smooth',
    });
  }

  const searchfeatures = ['header'];
  let searchedSubTopics;
  if (data) {
    searchedSubTopics = fuseSearch(data.subtopics, searchValue, searchfeatures);
  }

  return (
    <React.Fragment>
      <Grid container className={classes.allFrames}>
        <Grid item className={classes.mainHeader} key={data.header} xs={12} sm={12} md={12} lg={12}>
          <SimpleText text={data.header} variant='h1' fontWeight='bold' />
        </Grid>
        {searchedSubTopics.map(subtopic => (
          <Grid container key={subtopic.header}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div style={{ cursor: 'pointer' }}>
                <SimpleText
                  onClick={() => onContentClick(subtopic.header)}
                  className={classes.tableOfContents}
                  text={subtopic.header}
                  variant='subtitle1'
                />
              </div>
            </Grid>
          </Grid>
        ))}
        {searchedSubTopics.map(subtopic => (
          <Grid container className={classes.subtopicContainer} key={subtopic.header}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div id={subtopic.header}>
                <SimpleText
                  className={classes.subHeader}
                  text={subtopic.header}
                  variant='h2'
                  fontWeight='bold'
                />
              </div>
            </Grid>
            {subtopic.messages.map(paragraph => (
              <React.Fragment key={paragraph.message}>
                {paragraph.header ? (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <SimpleText
                      className={classes.messageHeader}
                      text={paragraph.header}
                      variant='h3'
                      fontWeight='bold'
                    />
                  </Grid>
                ) : null}
                {paragraph.message ? (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <SimpleText
                      className={classes.messageHeader}
                      text={paragraph.message}
                      variant='subtitle1'
                    />
                  </Grid>
                ) : null}
                {paragraph.picture ? (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PositionMe howHorizontal='left'>
                      <Card className={classes.videosAndPictures}>
                        <CardMedia
                          component='img'
                          alt='Contemplative Reptile'
                          image={paragraph.picture}
                          title='Contemplative Reptile'
                        />
                      </Card>
                    </PositionMe>
                  </Grid>
                ) : null}
                {paragraph.video ? (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ReactPlayer
                      controls
                      className={classes.videosAndPictures}
                      url={paragraph.video}
                    />
                  </Grid>
                ) : null}
              </React.Fragment>
            ))}
          </Grid>
        ))}
        <Grid item lg={12}>
          <SimpleText className={classes.wasInfoHelpful} translateId='help.wasInfoHelpful' />
          <SimpleButton
            className={classes.yesNoButtons}
            translateId='help.yes'
            variant='highEmp'
            disabled={isLoading}
            onClick={() =>
              onSendClick({
                topic: `Feedback-Help-'${data.header}'`,
                message: `Help section '${data.header}' was helpful`,
              })
            }
          />
          <SimpleButton
            className={classes.yesNoButtons}
            translateId='help.no'
            variant='highEmp'
            disabled={isLoading}
            onClick={() =>
              onSendClick({
                topic: `Feedback-Help-'${data.header}'`,
                message: `Help section '${data.header}' was NOT helpful`,
              })
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

HelpTopicView.propTypes = {
  data: HelpType.isRequired,
  searchValue: PropTypes.string,
};

HelpTopicView.defaultProps = {
  searchValue: null,
};

export default HelpTopicView;

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment/index';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import BaseTextField from 'components/BaseTextField';
import { ClassesType } from 'utils/types';

const styles = theme => ({
  icon: {
    color: theme.palette.neutral.main,
  },
});

class SearchBarView extends PureComponent {
  render() {
    const { onUpdateSearch, placeholderId, classes, className, searchValue, translationPath } =
      this.props;
    const isControlled = !!searchValue;
    return (
      <BaseTextField
        size='small'
        className={className}
        label={null}
        placeholder={
          translationPath
            ? `${translationPath}.placeholder`
            : `searchBarPlaceholder.${placeholderId}`
        }
        typographyVariant='subtitle1'
        onChange={e => onUpdateSearch(e.target.value)}
        color='black'
        notControlled={!isControlled}
        value={searchValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon className={classes.icon} />
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

SearchBarView.propTypes = {
  classes: ClassesType.isRequired,
  onUpdateSearch: PropTypes.func.isRequired,
  placeholderId: PropTypes.string,
  translationPath: PropTypes.string,
  className: PropTypes.string,
  searchValue: PropTypes.string,
};

SearchBarView.defaultProps = {
  placeholderId: null,
  translationPath: null,
  className: '',
  searchValue: null,
};

export default withStyles(styles, { withTheme: true })(SearchBarView);

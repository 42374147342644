import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import SimpleText from 'components/SimpleText';
import { Id } from 'lib/types';
import { useAlerts } from 'screens/Portfolio/lib/hooks/portfolio';

const translationPath = 'portfolioScreen.portfolioOverview.alertsCard';

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 75,
    color: theme.palette.error.main,
    marginRight: '2rem',
  },
  content: {
    paddingTop: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
  },
  alerts: {
    marginBottom: '1rem',
  },
}));

interface AlertsCardProps {
  portfolioId: Id | undefined;
}

const AlertsCard = ({ portfolioId }: AlertsCardProps) => {
  const classes = useStyles();
  const { errorMessages } = useAlerts(portfolioId);

  return (
    <Card>
      <CardContent>
        <SimpleText translateId={`${translationPath}.title`} variant='h2' />
        <div className={classes.content}>
          <ErrorOutlineIcon className={classes.icon} />
          <div>
            {errorMessages.map(errorMsg => (
              <SimpleText
                className={classes.alerts}
                translateId={`portfolioLib.alerts.${errorMsg.translateId}`}
                translateData={errorMsg.translateData}
              />
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default AlertsCard;

import { useCallback, useReducer } from 'react';

const reducerDraft = () => (prevState, action) => {
  switch (action.type) {
    case 'set':
      return action.value;
    default:
      throw new Error();
  }
};

const reducerDraftArray = originalValue => (prevState, action) => {
  const state = prevState ?? originalValue;
  switch (action.type) {
    case 'remove':
      if (Array.isArray(action.value)) {
        return (state ?? []).filter(elem => !action.value.includes(elem));
      }
      return (state ?? []).filter(elem => elem !== action.value);

    case 'add':
      if (Array.isArray(action.value)) {
        return [...(state ?? []), ...action.value];
      }
      return [...(state ?? []), action.value];

    case 'set':
      return action.value;
    default:
      throw new Error();
  }
};

const reducerDraftObject = originalValue => (prevState, action) => {
  const state = prevState ?? originalValue;
  const newObj = { ...(state ?? {}) };
  switch (action.type) {
    case 'remove':
      delete newObj[action.key];
      return newObj;
    case 'add':
      newObj[action.key] = action.value;
      return newObj;
    case 'set':
      return action.value;
    default:
      throw new Error();
  }
};

const reducerDraftObjectArray = (originalValue, idPropName) => (prevState, action) => {
  const state = prevState ?? originalValue;
  let alreadyExists;
  let arr;
  switch (action.type) {
    case 'remove':
      return (state ?? []).filter(elem => elem[idPropName] !== action.key);
    case 'add':
      alreadyExists = (state ?? []).find(elem => elem[idPropName] === action.value[idPropName]);
      if (!alreadyExists) {
        if (action.index === undefined) {
          return [...(state ?? []), action.value];
        }
        arr = [...(state ?? [])];
        arr.splice(action.index, 0, action.value);
        return arr;
      }
      return state;
    case 'setElement':
      arr = state.map(elem => {
        const isSame = elem[idPropName] === action.value[idPropName];
        if (isSame) {
          return action.value;
        }
        return elem;
      });
      return arr;
    case 'set':
      return action.value;
    default:
      throw new Error();
  }
};

/**
 * Generic Hook for managing draft values.
 * @param originalValue The original value.
 * @param placeholder The placeholder value, if both draft and originalValue are nil.
 * @param idPropName
 * If not nil, it is assumed, that originalValue is an array of objects, and the `idPropName` field
 * is used to identify these objects.
 * @param isObject {boolean} If true, the originalValue is an object.
 * @param isArray {boolean} If true, the originalValue is an array.
 * @return {{dispatchDraft, draft, setDraft, setDraftElement}}
 */
export const useDraft = (
  originalValue,
  { placeholder = undefined, idPropName = undefined, isObject = false, isArray = false } = {}
) => {
  if (isObject && isArray) {
    throw new Error('useDraft: isObject and isArray cannot be both true.');
  }
  if (isObject && idPropName) {
    throw new Error('useDraft: idPropName cannot be used with isObject.');
  }
  let reducerFn;
  if (isObject) {
    reducerFn = reducerDraftObject(originalValue);
  } else if (idPropName) {
    reducerFn = reducerDraftObjectArray(originalValue, idPropName);
  } else if (isArray) {
    reducerFn = reducerDraftArray(originalValue);
  } else {
    reducerFn = reducerDraft();
  }
  const [draft, dispatchDraft] = useReducer(reducerFn, undefined);
  const setDraft = useCallback(value => dispatchDraft({ type: 'set', value }), [dispatchDraft]);
  const placeholderValue = originalValue === undefined ? placeholder : originalValue;
  const showValue = draft === undefined ? placeholderValue : draft;
  return {
    // if draft was not yet set/changed, return original value
    // if original value is also nil, return placeholder
    draft: showValue,
    // dispatchDraft is used to update draft, if it is assumed to be an Array
    // Action then looks like { type: 'add', value: [...newElements] },
    // or { type: 'remove', value: [...elementsToRemove] },
    // or { type: 'set', value } for setting new draft.
    dispatchDraft,
    // setDraft is a shortcut for dispatchDraft({ type: 'set', value })
    // Useful, when you need just "set" possibility
    setDraft,
  };
};

export const useDraftValue = (originalValue, { placeholder = undefined } = {}) => {
  const { draft, setDraft } = useDraft(originalValue, { placeholder });
  return { draft, setDraft };
};

export const useDraftList = originalValue => {
  const { draft, dispatchDraft, setDraft } = useDraft(originalValue, {
    placeholder: [],
    isArray: true,
  });
  const addDraftElement = useCallback(
    value => dispatchDraft({ type: 'add', value }),
    [dispatchDraft]
  );
  const removeDraftElement = useCallback(
    value => dispatchDraft({ type: 'remove', value }),
    [dispatchDraft]
  );
  const toggleDraftElement = useCallback(
    value => {
      if (draft.includes(value)) {
        removeDraftElement(value);
      } else {
        addDraftElement(value);
      }
    },
    [addDraftElement, draft, removeDraftElement]
  );
  return {
    draft,
    dispatchDraft,
    setDraft,
    addDraftElement,
    removeDraftElement,
    toggleDraftElement,
  };
};

export const useDraftObject = originalValue => {
  const { draft, dispatchDraft, setDraft } = useDraft(originalValue, {
    placeholder: {},
    isObject: true,
  });
  return { draft, dispatchDraft, setDraft };
};

export const useDraftObjectList = (originalValue, { idPropName = 'id' } = {}) => {
  const { draft, dispatchDraft, setDraft } = useDraft(originalValue, {
    placeholder: [],
    idPropName,
  });
  const setDraftElement = useCallback(
    value => dispatchDraft({ type: 'setElement', value }),
    [dispatchDraft]
  );
  const addDraftElement = useCallback(
    (value, index = undefined) => dispatchDraft({ type: 'add', value, index }),
    [dispatchDraft]
  );
  const removeDraftElementAtKey = useCallback(
    key => dispatchDraft({ type: 'remove', key }),
    [dispatchDraft]
  );
  return { draft, setDraftElement, setDraft, removeDraftElementAtKey, addDraftElement };
};

export const useDraftString = originalValue => {
  const { draft, setDraft } = useDraft(originalValue, { placeholder: '' });
  return { draft, setDraft };
};

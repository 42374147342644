import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { Translate } from 'react-localize-redux';

import { getSorting, stableSort } from 'components/Table/utils';
import useDashboardStyles from 'screens/Report/ReportScreen/configuration/dashboard.style';
import { Order } from 'utils/constants';
import { TableObjectsType } from 'utils/types';

const numberFields = ['weight'];

const PaperTable = ({
  columns,
  translateId,
  tableObjects,
  cellFormatting,
  size,
  orderBy,
  order,
}) => {
  const wrapper = useRef();

  const dashboardClasses = useDashboardStyles();

  const sortedTableObjects = useMemo(
    () => stableSort(tableObjects, getSorting(order ?? Order.DESC, orderBy ?? 'weight')),
    [tableObjects, order, orderBy]
  );

  return (
    <TableContainer
      ref={wrapper}
      style={{
        maxHeight: wrapper?.current?.parentNode.offsetHeight,
        background: '#fff',
      }}
    >
      <Table stickyHeader size='small' aria-label='a dense weights table'>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                align='right'
                key={column}
                className={dashboardClasses[`${size}Table`]}
                style={{
                  '.reset this': {
                    fontSize: 'none',
                  },
                  fontWeight: 'bold',
                  textAlign: numberFields.includes(column) ? 'right' : 'left',
                }}
              >
                <Translate id={`${translateId}.${column}`} />
              </TableCell>
            ))}
            {/* Jus to make a bit of space on the right for edit/delete */}
            <TableCell className='d-print-none' />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTableObjects.map(row => (
            <TableRow key={row.instituteIdentifier}>
              {columns.map(column => (
                <TableCell
                  key={column}
                  className={dashboardClasses[`${size}Table`]}
                  style={{
                    '.reset this': {
                      fontSize: 'none',
                    },
                    fontWeight: 400,
                    textAlign: numberFields.includes(column) ? 'right' : 'left',
                  }}
                >
                  {column in cellFormatting ? cellFormatting[column](row[column]) : row[column]}
                </TableCell>
              ))}
              {/* Jus to make a bit of space on the right for edit/delete */}
              <TableCell className='d-print-none' />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PaperTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  translateId: PropTypes.string.isRequired,
  tableObjects: TableObjectsType.isRequired,
  cellFormatting: PropTypes.objectOf(PropTypes.func),
  size: PropTypes.string.isRequired,
};

PaperTable.defaultProps = {
  cellFormatting: {},
};

export default PaperTable;

import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';

import MyDropdown from 'components/MyDropdown/MyDropdown';
import useAllocationAvailable from 'screens/AssetAllocation/GroupConstraintListScreen/components/useAllocationAvailable';
import { createGroupConstraints } from 'screens/AssetAllocation/lib/helpers';
import { useAssetAllocationsQuery } from 'screens/Environment/lib/queries';
import { idType } from 'utils/types';

const AssetAllocationDropdown = ({
  draftAssetAlloc,
  setDraftAssetAlloc,
  setDraftConstraints,
  disabled,
  itemDisabledFn,
}) => {
  const { assetAllocations } = useAssetAllocationsQuery();
  const assetAllocationsAvailable = useAllocationAvailable(assetAllocations, [draftAssetAlloc]);

  return (
    <MyDropdown
      value={draftAssetAlloc}
      onChange={assetAllocId => {
        setDraftAssetAlloc(assetAllocId);
        setDraftConstraints(createGroupConstraints(assetAllocations[assetAllocId]));
      }}
      disabled={disabled}
      placeholderId='assetAllocation'
    >
      {Object.values(assetAllocationsAvailable).map(assetAllocation => (
        <MenuItem
          key={assetAllocation.id}
          value={assetAllocation.id}
          disabled={itemDisabledFn(assetAllocation.id)}
        >
          {assetAllocation.name}
        </MenuItem>
      ))}
    </MyDropdown>
  );
};

AssetAllocationDropdown.propTypes = {
  draftAssetAlloc: idType.isRequired,
  setDraftAssetAlloc: PropTypes.func,
  setDraftConstraints: PropTypes.func,
  disabled: PropTypes.bool,
  itemDisabledFn: PropTypes.func,
};

AssetAllocationDropdown.defaultProps = {
  setDraftAssetAlloc: () => {},
  setDraftConstraints: () => {},
  disabled: false,
  itemDisabledFn: () => false,
};

export default AssetAllocationDropdown;

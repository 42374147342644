import types from './actions';

const initialNavigationState = {
  toPortfolio: null,
  toConfig: null,
  redirectTo: null,
  toBacktest: null,
  currentRoute: null,
  isToRefresh: false,
};

function navigation(state = initialNavigationState, action) {
  switch (action.type) {
    case types.REFRESH:
      return { ...state, isToRefresh: true };
    case types.GO_TO_PORTFOLIO:
      return { ...state, toPortfolio: action.payload };
    case types.REDIRECT_TO:
      if (action.payload !== state.redirectTo) {
        return { ...state, redirectTo: action.payload };
      }
      return state;
    case types.GO_TO_CONFIG:
      return { ...state, toConfig: action.payload };
    case types.RESET_NAVIGATION:
      return { ...initialNavigationState };
    case types.GO_TO_BACKTEST:
      return { ...state, toBacktest: action.payload };
    case types.ADD_PREV_ROUTE:
      return { ...state, prevRoute: action.payload };
    case types.ADD_CURRENT_ROUTE:
      return { ...state, currentRoute: action.payload };
    default:
      return state;
  }
}

export default navigation;

import { ResourceName } from 'lib/constants';
import { Id, Ids, IsoDate, IsoDateTime, NullableId, UserOwnerable, ValueOf } from 'lib/types';
import { GroupConstraintList } from 'screens/AssetAllocation/lib/types';
import {
  IndividualAlertType,
  OptimPortfolioAllocationTypes,
  PortfolioAllocationType,
} from 'screens/Portfolio/lib/constants';
import { ResearchConstraintList } from 'screens/Research/lib/types';
import { GetJob, OptimizationJob } from 'screens/Task/lib/types';

export type PortfolioAllocationTypeT = ValueOf<typeof PortfolioAllocationType>;
export type OptimPortfolioAllocationTypeT = typeof OptimPortfolioAllocationTypes[number];

export const isOptimAllocationType = (
  allocationType: PortfolioAllocationTypeT | undefined
): allocationType is OptimPortfolioAllocationTypeT => {
  return OptimPortfolioAllocationTypes.includes(allocationType as OptimPortfolioAllocationTypeT);
};

export function isPortfolioType(portfolio: Portfolio | PortfolioOverview): portfolio is Portfolio {
  return (portfolio as Portfolio)?.newOptimizationState !== undefined;
}

export interface Weight {
  assetId: Id;
  weight: number;
}

export type Weights = ReadonlyArray<Weight>;

export interface WeightsAllocation {
  weights: Weights;
}

export interface OptimizationError {
  isError: boolean;
  errorMsg: string | null;
}

export interface OptimizationResult extends OptimizationError {
  effectiveDate: IsoDate;
  updatedOn: IsoDateTime;
  allocation: WeightsAllocation;
  isClosest: boolean;
  isSolved: boolean;
  isOutdated: boolean;
  outdatedOn: IsoDateTime | null;
  cvar?: number;
  ret?: number;
}

export interface HistoricalSnapshot {
  effectiveDate: IsoDate;
  allocation: WeightsAllocation;
  wasRebalanced: boolean;
  allocation_before_rebalance?: WeightsAllocation;
  turnover?: number;
}

interface PortfolioStrategyBase {
  isTargetRisk: boolean;
  targetValue: number;
  targetCorridor: number;
  maxConstraint?: number;
  minConstraint?: number;

  universeId: Id;
  basketId: NullableId;
}

export interface PortfolioStrategy extends PortfolioStrategyBase {
  groupConstraintListsIds: Ids;
  researchConstraintListsIds: Ids;
}

export interface PortfolioStrategyDraft extends Partial<PortfolioStrategyBase> {
  groupConstraintLists: GroupConstraintList[];
  researchConstraintLists: ResearchConstraintList[];
}

interface TimeSeriesPoint {
  x: IsoDate;
  y: number;
}

export type TimeSeries = ReadonlyArray<TimeSeriesPoint>;

export interface PortfolioHistoryOverview {
  performance: TimeSeries;
}

export interface PortfolioHistory extends PortfolioHistoryOverview {
  tablename: typeof ResourceName.PORTFOLIO_HISTORY;
  points: ReadonlyArray<HistoricalSnapshot>;
}

interface BaseAlert {
  expectedValue: number;
  actualValue: number;
  diff: number;
}

interface TargetAlert extends BaseAlert {
  isTargetRisk: boolean;
}

export interface GroupAlert extends BaseAlert {
  assetGroupId: Id;
}

type IndividualAlertTypeT = ValueOf<typeof IndividualAlertType>;

export interface IndividualAlert extends BaseAlert {
  assetId: Id;
  researchConstraintListId: NullableId;
  typ: IndividualAlertTypeT;
}

export interface PortfolioAlerts {
  targetAlert?: TargetAlert;
  groupAlerts: ReadonlyArray<GroupAlert>;
  individualAlerts: ReadonlyArray<IndividualAlert>;
}

export interface OptimizationStateOverview {
  optimizationResult?: OptimizationResult;
}

interface OptimizationState extends OptimizationStateOverview {
  strategy?: PortfolioStrategy;
}

interface PortfolioStateBase {
  effectiveDate: IsoDate;
  alerts?: PortfolioAlerts;
  cvar?: number;
  ret?: number;
}

export interface PortfolioState extends PortfolioStateBase {
  allocation?: WeightsAllocation;
}

interface PortfolioStateOverview extends PortfolioStateBase {}

export interface PortfolioBase extends UserOwnerable {
  id: Id;
  tablename: typeof ResourceName.PORTFOLIO;
  name: string;
  isFavorite: boolean;
  isSaved: boolean;
  isTracked: boolean;
  trackingStartDate?: IsoDate;
  lastRebalancedOn: IsoDateTime;
  createdOn: IsoDateTime;
}

export interface Portfolio extends PortfolioBase {
  currentState?: PortfolioState;
  optimizationState: OptimizationState;
  newOptimizationState?: OptimizationState;
  targetAllocation?: WeightsAllocation;
  history: PortfolioHistoryOverview;
  optimizationJob?: OptimizationJob;
  assetsToDerivativesIds: { [key: Id]: Id | null };
}

export interface PortfolioOverview extends PortfolioBase {
  currentState?: PortfolioStateOverview;
  optimizationState: OptimizationStateOverview;
  history: PortfolioHistoryOverview;
}

export type PortfolioOverviews = {
  [id: Id]: PortfolioOverview;
};

interface GetPortfolioBaseAlert {
  isAlert: boolean;
  isHigher: boolean;
  weightDiff: number;
}

interface GetPortfolioGroupAlert extends GetPortfolioBaseAlert {
  assetGroup: Id;
  groupWeight: number;
}

interface GetPortfolioIndividualAlert extends GetPortfolioBaseAlert {
  asset: Id;
  researchConstraintList: NullableId;
  typ: ValueOf<typeof IndividualAlertType>;
}

interface GetPortfolioWeight {
  asset: Id;
  weight: number;
}

export interface GetPortfolioAllocation {
  cvar: number;
  ret: number;
  weights: ReadonlyArray<GetPortfolioWeight>;
  wasRebalanced: boolean;
  updatedOn: IsoDateTime;

  isClosest: boolean;
  isSolved: boolean;
  isError: boolean;
  errorMsg: string;

  isTargetAlert: boolean;
  targetDiff: number;
  groupAlerts: ReadonlyArray<GetPortfolioGroupAlert>;
  individualAlerts: ReadonlyArray<GetPortfolioIndividualAlert>;

  turnover: number;
}

export interface GetManyPortfolioStrategy {
  effectiveDate: IsoDate;
  isTracked: boolean;
  trackingStartDate: IsoDate;
  isOutdated: boolean;
  outdatedOn: IsoDateTime | null;
  isTargetRisk: boolean;
  targetValue: number;
  targetCorridor: number;
  maxConstraint: number;
  minConstraint: number;

  optimAllocation: GetPortfolioAllocation;
  virtualAllocation: GetPortfolioAllocation;
}

export interface GetPortfolioStrategy extends GetManyPortfolioStrategy {
  targetAllocation: GetPortfolioAllocation;
  optimizationJob?: GetJob;
}

interface GetManyPortfolioHistory {
  performance: TimeSeries;
}

export interface GetPortfolioHistory extends GetManyPortfolioHistory {
  portfolios: GetManyPortfolioStrategy[];
}

export interface GetManyPortfolio {
  id: Id;
  tablename: typeof ResourceName.PORTFOLIO;
  institute: Id;
  user: Id;
  name: string;
  isFavorite: boolean;
  isSaved: boolean;
  lastRebalancedOn: IsoDateTime;
  createdOn: IsoDateTime;
  history: GetManyPortfolioHistory;
  current: GetManyPortfolioStrategy;
}

export interface GetPortfolio extends GetManyPortfolio {
  assetsToDerivativesIds: { [key: number]: number | null };
  universe: Id;
  basket: NullableId;
  newBasket: NullableId;
  researchConstraintLists: Ids;
  newResearchConstraintLists: Ids;
  groupConstraintLists: Ids;
  newGroupConstraintLists: Ids;
  new?: GetPortfolioStrategy;
  current: GetPortfolioStrategy;
}

export interface Derivative {
  id: Id;
  name: string;
  isin: string;
  assetType: string;
  underlyingAssetId: Id;
}

export interface GetDerivativesForPortfolio {
  derivatives: Derivative[];
}

export interface PutGroupConstraintList extends GroupConstraintList {
  noPortfolioUpdate: boolean;
}

export interface PutResearchConstraintList extends ResearchConstraintList {
  noPortfolioUpdate: boolean;
}

export interface PutPortfolioStrategy {
  universe?: Id;
  basket?: NullableId;
  researchConstraintLists?: Ids;
  groupConstraintLists?: Ids;
  current?: Partial<GetPortfolioStrategy>;
  groupConstraintListsAnonymous?: PutGroupConstraintList[];
  researchConstraintListsAnonymous?: PutResearchConstraintList[];
}

import { TextField } from '@mui/material';
import { passwordStrength } from 'check-password-strength';
import React, { useEffect } from 'react';

import { useTranslate } from 'lib/localization/localization';

const translationPath = 'usePasswordStrength';

const usePasswordStrength = () => {
  const translate = useTranslate();
  const [password, setPassword] = React.useState('');
  const [isPasswordStrong, setIsPasswordStrong] = React.useState(false);
  const [passwordRepeat, setPasswordRepeat] = React.useState('');
  const [arePasswordsSame, setArePasswordsSame] = React.useState(false);

  useEffect(() => {
    if (password.length > 0 && passwordStrength(password).id < 2) {
      setIsPasswordStrong(false);
    } else {
      setIsPasswordStrong(true);
    }
  }, [password]);

  useEffect(() => {
    if (passwordRepeat.length > 0 && password !== passwordRepeat) {
      setArePasswordsSame(false);
    } else {
      setArePasswordsSame(true);
    }
  }, [password, passwordRepeat]);

  const passwordFieldElem = (
    <TextField
      error={!isPasswordStrong}
      helperText={!isPasswordStrong ? translate(`${translationPath}.passwordNotStrong`) : ''}
      size='small'
      type='password'
      fullWidth
      required
      label={translate(`${translationPath}.password`)}
      variant='outlined'
      value={password}
      onChange={event => {
        setPassword(event.target.value);
      }}
    />
  );

  const passwordRepeatFieldElem = (
    <TextField
      error={!arePasswordsSame}
      helperText={!arePasswordsSame ? translate(`${translationPath}.passwordsNotSame`) : ''}
      size='small'
      type='password'
      fullWidth
      required
      label={translate(`${translationPath}.passwordRepeat`)}
      variant='outlined'
      value={passwordRepeat}
      onChange={event => {
        setPasswordRepeat(event.target.value);
      }}
    />
  );

  return {
    password,
    isPasswordStrong,
    passwordRepeat,
    arePasswordsSame,
    passwordFieldElem,
    passwordRepeatFieldElem,
  };
};

export default usePasswordStrength;

import { format } from 'date-fns';

import { IsoDate, IsoDateTime } from 'lib/types';

export function toDateString(inputDate: Date | IsoDateTime | IsoDate | undefined | null): IsoDate {
  if (inputDate === undefined || inputDate === null) {
    return 'NA';
  }
  let date: Date;
  if (typeof inputDate === 'string') {
    date = new Date(inputDate);
  } else {
    date = inputDate;
  }
  return format(date, 'yyyy-MM-dd');
}

export function toDateTimeString(
  inputDate: Date | IsoDateTime | IsoDate | undefined | null
): IsoDate {
  if (inputDate === undefined || inputDate === null) {
    return 'NA';
  }
  let date: Date;
  if (typeof inputDate === 'string') {
    date = new Date(inputDate);
  } else {
    date = inputDate;
  }
  return format(date, 'yyyy-MM-dd  HH:mm');
}

import { ListItemButton, ListItemText } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import PositionMe from 'components/PositionMe';
import SimpleText from 'components/SimpleText';
import { useIsPathAvailable } from 'lib/access/useIsPathAvailable';

const styles = theme => ({
  section: {
    color: `${theme.palette.text.secondary} !important`,
  },
});

const SideListItem = ({ translationId, path, isSection, classes }) => {
  const isPathAvailable = useIsPathAvailable(path);
  const history = useHistory();

  if (!isPathAvailable) {
    return null;
  }
  let fullTranslationId;
  if (isSection) {
    fullTranslationId = `sideMenuItem.section.${translationId}`;
    return (
      <ListItem key={fullTranslationId} component='li' className={classes.section}>
        <PositionMe>
          <SimpleText variant='h3' translateId={fullTranslationId} />
        </PositionMe>
      </ListItem>
    );
  }
  fullTranslationId = `sideMenuItem.${translationId}`;
  return (
    <ListItem key={fullTranslationId} disablePadding>
      <ListItemButton
        key={fullTranslationId}
        onClick={() => history.push(path)}
        sx={{ height: '45px', textAlign: 'center' }}
      >
        <ListItemText
          primary={<SimpleText translateId={fullTranslationId} variant='body1' />}
          disableTypography
        />
      </ListItemButton>
    </ListItem>
  );
};

SideListItem.propTypes = {
  translationId: PropTypes.string.isRequired,
  path: PropTypes.string,
  isSection: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

SideListItem.defaultProps = {
  path: null,
  isSection: false,
};

export default withStyles(styles, { withTheme: true })(SideListItem);

import types from './actions';

const initialNotificationsState = {
  error: null,
  success: null,
  warning: null,
  info: null,
  smallNotification: null,
};

function notifications(state = initialNotificationsState, action) {
  switch (action.type) {
    case types.ADD_SMALL_NOTIFICATION:
      return { ...state, smallNotification: action.payload };
    case types.ADD_ALERT:
      return { ...state, [action.payload.severity]: action.payload.alert };
    case types.REMOVE_SMALL_NOTIFICATION:
      return { ...state, smallNotification: null };
    case types.REMOVE_ALERT:
      return { ...state, [action.payload]: null };
    default:
      return state;
  }
}

export default notifications;

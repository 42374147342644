import React from 'react';

import Slider from 'screens/AssetAllocation/lib/components/GroupConstraintSlider';
import { GroupConstraint } from 'screens/AssetAllocation/lib/types';

interface GroupConstraintSlidersProps {
  draftConstraints: GroupConstraint[];
  setDraftConstraint?: (draftConstraint: GroupConstraint) => void;
  disabled?: boolean;
}

const GroupConstraintSliders = ({
  draftConstraints,
  setDraftConstraint = () => {},
  disabled = false,
}: GroupConstraintSlidersProps) => {
  return (
    <>
      {draftConstraints.map(draftConstraint => (
        <Slider
          draftConstraint={draftConstraint}
          setDraftConstraint={setDraftConstraint}
          key={draftConstraint.assetGroup}
          disabled={disabled}
        />
      ))}
    </>
  );
};

export default GroupConstraintSliders;

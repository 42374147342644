import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import {
  HorizontalBarSeries,
  LabelSeries,
  VerticalBarSeries,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis-bi0max';

import { getMinMaxPlusFiveX, getMinMaxPlusFiveY } from './utils';

import { ClassesType, BarChartDataType } from 'utils/types';

const styles = {
  root: {
    width: '100%',
    minWidth: '400px',
    maxWidth: '100vw',
    borderRadius: '0',
    backgroundColor: 'inherit',
    boxShadow: '0 0 0',
  },
  plot: {
    left: '10rem',
  },
};

const BarChartBase = props => {
  const { classes, data, height, width, theme } = props;
  const tickerStyle = {
    line: { strokeWidth: 1, stroke: theme.palette.neutral.main },
    text: {
      stroke: 'none',
      fill: theme.palette.neutral.main,
      fontSize: 9,
      fontWeight: 400,
    },
    title: { stroke: theme.palette.neutral.main },
  };
  let BarSeries;
  let plotProps;
  let labelSeriesProps;
  let labelSeriesData;
  if (data.length < 20) {
    BarSeries = HorizontalBarSeries;
    plotProps = {
      xDomain: getMinMaxPlusFiveX(data),
      margin: {
        bottom: 30,
        left: 250,
        right: 250,
        top: 0,
      },
      yType: 'ordinal',
    };
    labelSeriesData = data.map(obj => ({ ...obj, label: `${obj.x.toFixed(2)} %` }));
    labelSeriesProps = {
      labelAnchorX: 'middle',
      labelAnchorY: 'text-after-edge',
    };
  } else {
    BarSeries = VerticalBarSeries;
    plotProps = {
      yDomain: getMinMaxPlusFiveY(data),
      margin: {
        bottom: 250,
        left: 50,
        right: 20,
        top: 30,
      },
      xType: 'ordinal',
    };
    labelSeriesData = data.map(obj => ({ ...obj, label: `${obj.y.toFixed(2)} %` }));
    labelSeriesProps = {
      labelAnchorY: 'middle',
      rotation: 270,
      labelAnchorX: 'text-after-edge',
    };
  }

  return (
    <Paper className={classes.root}>
      <XYPlot width={width + 50} height={height} {...plotProps}>
        <BarSeries colorType='literal' data={data} />
        <XAxis tickLabelAngle={data.length < 20 ? 0 : -90} style={tickerStyle} tickSize={1} />
        <YAxis style={tickerStyle} tickSize={1} />
        <LabelSeries
          data={labelSeriesData}
          style={{ fontSize: 10, fill: theme.palette.neutral.main }}
          {...labelSeriesProps}
        />
      </XYPlot>
    </Paper>
  );
};

BarChartBase.propTypes = {
  classes: ClassesType.isRequired,
  data: BarChartDataType.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

BarChartBase.defaultProps = {};

export default withStyles(styles, { withTheme: true })(BarChartBase);

import PropTypes from 'prop-types';
import React, { memo } from 'react';

import ChartOptionSelector from 'screens/Analysis/AnalysisScreen/widgets/PerformanceBreakdown/ChartOptionSelector';
import WidgetTitleSelector from 'screens/Analysis/lib/components/WidgetTitleSelector';

const PerformanceWidgetHeader = ({ option, setOption, title, setTitle }) => {
  const isLine = option === 'line';
  return (
    <>
      <WidgetTitleSelector
        mainTitle='performance'
        title={isLine ? null : title}
        setTitle={isLine ? null : setTitle}
        options={['month', 'Country', 'Sector']}
        disableSelection={isLine}
      />
      <ChartOptionSelector option={option} setOption={setOption} />
    </>
  );
};

PerformanceWidgetHeader.propTypes = {
  option: PropTypes.string.isRequired,
  setOption: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  setTitle: PropTypes.func.isRequired,
};

export default memo(PerformanceWidgetHeader);

import { createTypes } from 'lib/misc/misc';

const types = createTypes('addAssetAllocationScreen', [
  'CHANGE_PAGE_ORIENTATION',
  'CLEAR_CURRENT',
  'CHANGE_NAME',
  'TOGGLE_IS_NEW',
  'TOGGLE_MODIFIED',
  'SET_PORTFOLIO',
  'SET_FETCHING_SINGLE_REPORT',
  'SET_FETCHED_SINGLE_REPORT',
  'APPLY_REPORT_TO_UI',
  'SET_REPORT_ID',
  'ADD_WIDGET',
  'UPDATE_WIDGET',
  'TOGGLE_EDIT',
  'UPDATE_LAYOUT',
  'DELETE_WIDGET',
  'UPDATE_WIDGET_CONFIG',
  'RESTORE_WIDGET_CONFIG',
  'UPDATE_WIDGET_SAVED_VALUES',
  'UPDATE_WIDGET_SIZE',
]);

export default types;

export function changePageOrientation(orientation) {
  return {
    type: types.CHANGE_PAGE_ORIENTATION,
    payload: orientation,
  };
}

export function clearCurrentReport() {
  return {
    type: types.CLEAR_CURRENT,
  };
}

export function applyReportToUi(report) {
  return {
    type: types.APPLY_REPORT_TO_UI,
    payload: report,
  };
}

export function addWidgetToDashboard(widget) {
  return {
    type: types.ADD_WIDGET,
    payload: widget,
  };
}

export function updateWidget(id, widget) {
  return {
    type: types.UPDATE_WIDGET,
    payload: {
      id,
      widget,
    },
  };
}

export function toggleEdit(mode) {
  return {
    type: types.TOGGLE_EDIT,
    payload: mode,
  };
}

export function toggleModified(mode) {
  return {
    type: types.TOGGLE_MODIFIED,
    payload: mode,
  };
}

export function updateLayout(layout) {
  return {
    type: types.UPDATE_LAYOUT,
    payload: layout,
  };
}

export function deleteWidget(id) {
  return {
    type: types.DELETE_WIDGET,
    payload: id,
  };
}

export function updateWidgetConfig(id, config) {
  return {
    type: types.UPDATE_WIDGET_CONFIG,
    payload: {
      id,
      config,
    },
  };
}

export function restoreWidgetConfig(id) {
  return {
    type: types.RESTORE_WIDGET_CONFIG,
    payload: id,
  };
}

export function updateWidgetSavedValues(id) {
  return {
    type: types.UPDATE_WIDGET_SAVED_VALUES,
    payload: id,
  };
}

export function updateWidgetSize(id, layout) {
  return {
    type: types.UPDATE_WIDGET_SIZE,
    payload: {
      id,
      layout,
    },
  };
}

import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { createLocation } from 'components/MyRouting/utils';
import ObjectScreenBase from 'components/ObjectScreenBase';
import { PermissionLevel, PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import useEditPermission from 'lib/access/useEditPermission';
import { useDraft, useDraftList, useDraftString } from 'lib/misc/useDraft/useDraft';
import { InstituteUsersTable } from 'screens/User/lib/components/UsersTable';
import { useEveryUserGroupMutation, useUserGroupsQuery } from 'screens/UserGroup/lib/queries';
import DefaultAccessDropdown from 'screens/UserGroup/UserGroupScreen/components/DefaultAccessDropdown';
import RolesList from 'screens/UserGroup/UserGroupScreen/components/RolesList';
import { Routes } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

const UserGroupScreen = () => {
  const history = useHistory();
  const classes = useStyles();
  const { id: userGroupId } = useParams();
  const {
    deleteUserGroup,
    createUserGroup,
    updateUserGroup,
    isLoading: isLoadingMutation,
  } = useEveryUserGroupMutation();
  const isNew = !userGroupId;
  const { userGroups, isLoading: isLoadingUserGroups } = useUserGroupsQuery();
  const userGroup = userGroups[userGroupId];
  const hasEditAccess = useEditPermission(userGroup);
  const hasCreateAccess = useCreatePermission(PermissionResourceName.USER_GROUP);
  const hasAccess = isNew ? hasCreateAccess : hasEditAccess;
  const { draft: draftRoleIds, dispatchDraft: dispatchDraftRoleIds } = useDraftList(
    userGroup?.roles && userGroup.roles.map(role => role.id)
  );
  const { draft: draftUserIds, dispatchDraft: dispatchDraftUserIds } = useDraftList(
    userGroup?.users
  );
  const { draft: draftName, setDraft: setDraftName } = useDraftString(userGroup?.name);
  const { draft: draftDefaultAccess, setDraft: setDraftDefaultAccess } = useDraft(
    userGroup?.defaultAccessInGroup,
    { placeholder: PermissionLevel.NO }
  );

  const userGroupDraft = {
    name: draftName,
    roles: draftRoleIds,
    users: draftUserIds,
    defaultAccessInGroup: draftDefaultAccess,
  };
  const userGroupOriginal = userGroup
    ? {
        name: userGroup.name,
        roles: userGroup.roles.map(role => role.id),
        users: userGroup.users,
        defaultAccessInGroup: userGroup.defaultAccessInGroup,
      }
    : null;
  const onSaveClick = () => {
    if (isNew) {
      createUserGroup(userGroupDraft, {
        onSuccess: data => {
          history.push(createLocation(Routes.USER_GROUP, data.id));
        },
      });
    } else {
      updateUserGroup({ id: userGroupId, data: userGroupDraft });
    }
  };

  const onSaveNameClick = () => {
    updateUserGroup({ id: userGroupId, data: { name: draftName } });
  };

  const onDeleteClick = () => {
    deleteUserGroup(userGroupId, {
      onSuccess: () => {
        history.push(Routes.CHOOSE_USER_GROUP);
      },
    });
  };

  return (
    <ObjectScreenBase
      disabled={isLoadingMutation}
      onSaveNameClick={onSaveNameClick}
      waitWhile={isLoadingUserGroups}
      object={userGroup}
      originalObj={userGroupOriginal}
      objects={userGroups}
      draftObj={userGroupDraft}
      onSaveClick={onSaveClick}
      onDeleteClick={onDeleteClick}
      hasAccess={hasAccess}
      translationPath='userGroupScreen'
      propsToTriggerDialog={['roles', 'users', 'defaultAccessInGroup']}
      routeNew={Routes.NEW_USER_GROUP}
      routeChoose={Routes.CHOOSE_USER_GROUP}
      routeObject={Routes.USER_GROUP}
      draftName={draftName}
      setDraftName={setDraftName}
    >
      <div className={classes.content}>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <RolesList
              draftRoleIds={draftRoleIds}
              dispatchDraftRoleIds={dispatchDraftRoleIds}
              disabled={!hasAccess}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <DefaultAccessDropdown
              draftDefaultAccess={draftDefaultAccess}
              setDraftDefaultAccess={setDraftDefaultAccess}
              disabled={!hasAccess}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <InstituteUsersTable
              draftUserIds={draftUserIds}
              dispatchDraftUserIds={dispatchDraftUserIds}
              disabled={!hasAccess}
            />
          </Grid>
        </Grid>
      </div>
    </ObjectScreenBase>
  );
};

export default UserGroupScreen;

import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import MyDropdown from 'components/MyDropdown/MyDropdown';
import ObjectScreenBase from 'components/ObjectScreenBase';
import TopScreenButtons from 'components/TopScreenButtons';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import useEditPermission from 'lib/access/useEditPermission';
import { useDraftObjectList, useDraftString, useDraftValue } from 'lib/misc/useDraft/useDraft';
import { generateSearchPath, useURLSearchParams } from 'lib/navigation/navigation';
import ConstraintsDropdowns from 'screens/Research/lib/components/ConstraintsDropdowns';
import { ResearchAction } from 'screens/Research/lib/constants';
import { createResearchConstraints } from 'screens/Research/lib/helpers';
import {
  useEveryResearchConstraintListMutation,
  useResearchConstraintListQuery,
  useResearchListsQuery,
} from 'screens/Research/lib/queries';
import { Routes } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  dropdown: {
    marginTop: theme.spacing(2),
  },
  tablesContainer: {
    paddingLeft: theme.spacing(12),
  },
  searchMenu: {
    paddingLeft: theme.spacing(12),
  },
}));

const ResearchConstraintListScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const searchParams = useURLSearchParams();

  const { id: objId } = useParams();
  const isNew = !objId;
  const { researchConstraintList, isLoading } = useResearchConstraintListQuery(objId);
  const hasEditAccess = useEditPermission(researchConstraintList);
  const hasCreateAccess = useCreatePermission(PermissionResourceName.RESEARCH_CONSTRAINT_LIST);
  const hasAccess = isNew ? hasCreateAccess : hasEditAccess;
  const { researchLists } = useResearchListsQuery();
  const {
    createResearchConstraintList,
    deleteResearchConstraintList,
    updateResearchConstraintList,
    isLoading: isLoadingMutation,
  } = useEveryResearchConstraintListMutation();

  const { draft: draftName, setDraft: setDraftName } = useDraftString(researchConstraintList?.name);
  const { draft: draftResearchListId, setDraft: setDraftResearchListId } = useDraftValue(
    researchConstraintList?.researchList
  );
  const { draft: draftNoOpinionAction, setDraft: setDraftNoOpinionAction } = useDraftValue(
    researchConstraintList?.noOpinionAction,
    { placeholder: ResearchAction.HOLD }
  );
  const {
    draft: draftConstraints,
    setDraftElement: setDraftConstraint,
    setDraft: setDraftConstraints,
  } = useDraftObjectList(researchConstraintList?.constraints, { idPropName: 'recommendationType' });

  useEffect(() => {
    // set initial value of draftResearchListId to param from the URL
    const { researchListId } = searchParams;
    if (researchListId && isNew) {
      setDraftResearchListId(researchListId);
    }
  }, [isNew, searchParams, setDraftResearchListId]);

  useEffect(() => {
    if (draftConstraints.length === 0) {
      setDraftConstraints(createResearchConstraints(researchLists[draftResearchListId]));
    }
  }, [draftConstraints, draftResearchListId, researchLists, setDraftConstraints]);

  const draft = {
    name: draftName,
    researchList: draftResearchListId,
    constraints: draftConstraints,
    noOpinionAction: draftNoOpinionAction,
    isPredefined: true,
  };

  const onSaveClick = () => {
    if (isNew) {
      createResearchConstraintList(draft, {
        onSuccess: data => {
          history.push(
            generateSearchPath(Routes.RESEARCH_CONSTRAINT_LIST, {
              params: { id: data.id },
              searchParams,
            })
          );
        },
      });
    } else {
      updateResearchConstraintList({ id: objId, data: draft });
    }
  };

  const onSaveNameClick = () => {
    updateResearchConstraintList({ id: objId, data: { name: draftName } });
  };

  const onDeleteClick = () => {
    deleteResearchConstraintList(objId, {
      onSuccess: () => {
        history.push(Routes.CHOOSE_RESEARCH_CONSTRAINT_LIST);
      },
    });
  };
  return (
    <ObjectScreenBase
      disabled={isLoadingMutation}
      onSaveNameClick={onSaveNameClick}
      waitWhile={isLoading}
      object={researchConstraintList}
      draftObj={draft}
      onSaveClick={onSaveClick}
      onDeleteClick={onDeleteClick}
      hasAccess={hasAccess}
      translationPath='researchConstraintListScreen'
      propsToTriggerDialog={['constraints', 'researchList']}
      routeChoose={generateSearchPath(Routes.CHOOSE_RESEARCH_CONSTRAINT_LIST, { searchParams })}
      draftName={draftName}
      setDraftName={setDraftName}
    >
      <TopScreenButtons>
        <MyDropdown
          className={classes.dropdown}
          disabled={!hasAccess || !isNew}
          value={draftResearchListId}
          onChange={value => {
            setDraftResearchListId(value);
            setDraftConstraints(createResearchConstraints(researchLists[value]));
            history.replace(Routes.NEW_RESEARCH_CONSTRAINT_LIST);
          }}
          isNullAllowed={false}
          translationPath='researchConstraintListScreen.researchListDropdown'
        >
          {_.values(researchLists).map(resList => (
            <MenuItem key={resList.id} value={resList.id}>
              {resList.name}
            </MenuItem>
          ))}
        </MyDropdown>
      </TopScreenButtons>
      <div className={classes.content}>
        <ConstraintsDropdowns
          recommendationTypes={researchLists?.[draftResearchListId]?.recommendationTypes}
          draftConstraints={draftConstraints}
          setDraftConstraint={setDraftConstraint}
          draftNoOpinionAction={draftNoOpinionAction}
          setDraftNoOpinionAction={setDraftNoOpinionAction}
          disabled={!hasAccess}
        />
      </div>
    </ObjectScreenBase>
  );
};

export default ResearchConstraintListScreen;

import Button from '@mui/material/Button/index';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

import SimpleText from 'components/SimpleText';
import { ClassesType, TranslateDataType, TypographyVariantType } from 'utils/types';

const styles = {
  root: {
    margin: '0 0 0 0',
    textAlign: 'left',
    color: 'inherit',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    overflow: 'hidden',
  },
};

const TitlePageButton = ({
  text,
  translateId,
  onClick,
  typographyVariant,
  component,
  classes,
  translateData,
  ...other
}) => (
  <Button
    data-test-id={`TitlePageButton-${translateId}`}
    className={classes.root}
    onClick={onClick}
    component={component}
    {...other}
  >
    <SimpleText
      text={text}
      translateId={translateId}
      translateData={translateData}
      variant={typographyVariant}
    />
  </Button>
);

TitlePageButton.propTypes = {
  classes: ClassesType.isRequired,
  translateId: PropTypes.string,
  onClick: PropTypes.func,
  component: PropTypes.string,
  translateData: TranslateDataType,
  text: PropTypes.string,
  typographyVariant: TypographyVariantType,
};

TitlePageButton.defaultProps = {
  component: 'button',
  onClick: null,
  translateData: null,
  translateId: null,
  text: null,
  typographyVariant: 'h1',
};

export default withStyles(styles, { withTheme: true })(TitlePageButton);

import {
  TableChart,
  TextFormat,
  Timeline,
  PieChart,
  FeaturedPlayList,
  Image,
} from '@mui/icons-material';

import { ALL_KPIS } from 'lib/performance/constants';
import { DEFAULT_WEIGHTS_TABLE_COLUMNS } from 'lib/weights/constants';
import ImageWidget from 'screens/Report/ReportScreen/widgets/ImageWidget/ImageWidget';
import ImageWidgetConfiguration from 'screens/Report/ReportScreen/widgets/ImageWidget/ImageWidgetConfiguration';
import KPITableWidget from 'screens/Report/ReportScreen/widgets/KPITableWidget/KPITableWidget';
import LineChartConfiguration from 'screens/Report/ReportScreen/widgets/LineChartWidget/LineChartConfiguration';
import LineChartWidget from 'screens/Report/ReportScreen/widgets/LineChartWidget/LineChartWidget';
import PieChartConfiguration from 'screens/Report/ReportScreen/widgets/PieChartWidget/PieChartConfiguration';
import PieChartWidget from 'screens/Report/ReportScreen/widgets/PieChartWidget/PieChartWidget';
import TextWidget from 'screens/Report/ReportScreen/widgets/TextWidget/TextWidget';
import TextWidgetConfiguration from 'screens/Report/ReportScreen/widgets/TextWidget/TextWidgetConfiguration';
import WeightsTableConfiguration from 'screens/Report/ReportScreen/widgets/WeightsTable/WeightsTableConfiguration';
import WeightsTableWidget from 'screens/Report/ReportScreen/widgets/WeightsTable/WeightsTableWidget';
import { Order } from 'utils/constants';

export const orientationMapping = {
  portrait: 794,
  landscape: 1123,
};

export const separatorStartHeightMappings = {
  landscape: 20,
  portrait: 27,
};

export const typeToComponentMapping = {
  separator: null,
  TextWidget,
  WeightsTableWidget,
  PieChartWidget,
  KPITableWidget,
  LineChartWidget,
  ImageWidget,
};

export const nameToWidgetMapping = {
  TextWidget: {
    type: 'TextWidget',
    icon: TextFormat,
    minW: 3,
    minH: 3,
    maxW: 12,
    maxH: 15,
    widget: TextWidget,
    configurationComponent: TextWidgetConfiguration,
    configuration: {
      text: {
        field: 'text',
        savedValue: {
          ops: [{ insert: 'Insert Text' }],
        },
        value: {
          ops: [{ insert: 'Insert Text' }],
        },
      },
    },
  },
  ImageWidget: {
    type: 'ImageWidget',
    icon: Image,
    minW: 3,
    minH: 3,
    maxW: 12,
    maxH: 15,
    widget: ImageWidget,
    configurationComponent: ImageWidgetConfiguration,
    configuration: {
      imageContainer: {
        field: 'imageContainer',
        savedValue: {
          ops: [{ insert: 'Add Images' }],
        },
        value: {
          ops: [{ insert: 'Add Images' }],
        },
      },
    },
  },
  WeightsTableWidget: {
    type: 'WeightsTableWidget',
    icon: TableChart,
    minW: 6,
    minH: 5,
    mediumW: 9,
    maxW: 12,
    maxH: 25,
    widget: WeightsTableWidget,
    configurationComponent: WeightsTableConfiguration,
    configuration: {
      portfolio: {
        field: 'portfolio',
        savedValue: null,
        value: null,
      },
      allocationType: {
        field: 'allocationType',
        savedValue: undefined,
        value: undefined,
      },
      columns: {
        field: 'columns',
        savedValue: DEFAULT_WEIGHTS_TABLE_COLUMNS,
        value: DEFAULT_WEIGHTS_TABLE_COLUMNS,
      },
      size: {
        field: 'size',
        savedValue: 'small',
        value: 'small',
      },
      sort: {
        field: 'sort',
        savedValue: ['weight', Order.DESC],
        value: ['weight', Order.DESC],
      },
    },
  },
  LineChartWidget: {
    type: 'LineChartWidget',
    icon: Timeline,
    minW: 6,
    minH: 4,
    maxW: 12,
    maxH: 25,
    widget: LineChartWidget,
    configurationComponent: LineChartConfiguration,
    configuration: {
      portfolio: {
        field: 'portfolio',
        savedValue: null,
        value: null,
      },
      backtestId: {
        field: 'backtestId',
        savedValue: undefined,
        value: undefined,
      },
      type: {
        field: 'type',
        value: undefined,
        savedValue: undefined,
      },
      benchmarks: {
        field: 'benchmarks',
        savedValue: [],
        value: [],
      },
      startDate: {
        field: 'startDate',
        savedValue: undefined,
        value: undefined,
      },
      endDate: {
        field: 'endDate',
        savedValue: undefined,
        value: undefined,
      },
      showBenchmarkLegend: {
        field: 'showBenchmarkLegend',
        savedValue: true,
        value: true,
      },
    },
  },
  PieChartWidget: {
    type: 'PieChartWidget',
    icon: PieChart,
    minW: 6,
    minH: 5,
    maxW: 12,
    maxH: 25,
    widget: PieChartWidget,
    configurationComponent: PieChartConfiguration,
    configuration: {
      portfolio: {
        field: 'portfolio',
        savedValue: null,
        value: null,
      },
      category: {
        field: 'category',
        savedValue: undefined,
        value: undefined,
      },
      allocationType: {
        field: 'allocationType',
        savedValue: undefined,
        value: undefined,
      },
    },
  },
  KPITableWidget: {
    type: 'KPITableWidget',
    icon: FeaturedPlayList,
    minW: 6,
    minH: 4,
    mediumW: 9,
    maxW: 12,
    maxH: 25,
    widget: KPITableWidget,
    configurationComponent: KPITableWidget,
    configuration: {
      portfolio: {
        field: 'portfolio',
        savedValue: null,
        value: null,
      },
      backtestId: {
        field: 'backtestId',
        savedValue: undefined,
        value: undefined,
      },
      type: {
        field: 'type',
        value: undefined,
        savedValue: undefined,
      },
      benchmarks: {
        field: 'benchmarks',
        savedValue: [],
        value: [],
      },
      startDate: {
        field: 'startDate',
        savedValue: undefined,
        value: undefined,
      },
      endDate: {
        field: 'endDate',
        savedValue: undefined,
        value: undefined,
      },
      rows: {
        field: 'rows',
        savedValue: ALL_KPIS,
        value: ALL_KPIS,
      },
      size: {
        field: 'size',
        savedValue: 'small',
        value: 'small',
      },
    },
  },
};

export const sizeMappings = {
  small: 'minW',
  medium: 'mediumW',
  large: 'maxW',
};

import { Box, Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import ScreenBase from 'components/ScreenBase/ScreenBase';
import TopScreenButtons from 'components/TopScreenButtons';
import useEditPermission from 'lib/access/useEditPermission';
import { useDraftString } from 'lib/misc/useDraft/useDraft';
import { useSavePromptAndDialog } from 'lib/misc/useSavePromptAndDialog';
import { createCustomTheme } from 'lib/theming/theme';
import PortfoliosDropdown from 'screens/Backtest/lib/components/PortfoliosDropdown';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import {
  useCreateReportMutation,
  useDeleteReportMutation,
  useReportQuery,
  useUpdateReportMutation,
} from 'screens/Report/lib/queries';
import {
  addWidgetToDashboard,
  applyReportToUi,
  clearCurrentReport,
  toggleEdit,
  toggleModified,
  updateLayout,
} from 'screens/Report/redux/actions';
import Sidebar from 'screens/Report/ReportScreen/components/Sidebar';
import {
  nameToWidgetMapping,
  orientationMapping,
} from 'screens/Report/ReportScreen/configuration/dashboard.config';
import {
  prepareWidgetAddition,
  transformDataForReport,
  transformLayoutToGrid,
} from 'screens/Report/utils/helpers';
import { Routes } from 'utils/constants';

const translationPath = 'reportScreen';

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh',
  },
  portrait: {
    padding: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '210mm',
  },
  landscape: {
    padding: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '297mm',
  },
  separator: {
    maxHeight: '5px!important',
    borderTop: '5px dashed #F1F1F1',
    // boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)',
    '@media print': {
      border: 'white',
      backgroundColor: 'white',
      boxShadow: 'none',
    },
  },
  background: {
    backgroundColor: '#FFFF',
    boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)',
    '@media print': {
      boxShadow: 'none',
    },
  },
}));

const ReportScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const reportTheme = React.useMemo(() => createCustomTheme('light'), []);

  const { id, portfolioId } = useParams();
  const { portfolio } = usePortfolioQuery(portfolioId);
  const hasAccess = useEditPermission(portfolio);
  const isNew = !id;

  const { report: currentReportInfo, isLoading: isLoadingReport } = useReportQuery(id);
  const { mutate: create, isLoading: isLoadingCreate } = useCreateReportMutation(portfolioId);
  const { mutate: update, isLoading: isLoadingUpdate } = useUpdateReportMutation();
  const { mutate: deleteReport } = useDeleteReportMutation();
  const { draft: draftName, setDraft: setDraftName } = useDraftString(
    currentReportInfo?.name ?? ''
  );
  const isLoading = isLoadingCreate || isLoadingUpdate || isLoadingReport;

  const isModified = useSelector(state => state.dashboardScreen.isModified);
  const currentReportSettings = useSelector(state => state.dashboardScreen.currentSettings);
  const currentReportWidgets = useSelector(state => state.dashboardScreen.currentWidgets);
  const editMode = useSelector(state => state.dashboardScreen.editMode);

  const [settingsType, setSettingsType] = useState('general');
  const [showSettings, setShowSettings] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [layout, setLayout] = useState([]);

  useEffect(() => {
    if (isNew) {
      dispatch(clearCurrentReport());
    }
  }, [dispatch, isNew]);

  useEffect(() => {
    if (currentReportInfo) {
      dispatch(applyReportToUi(currentReportInfo));
    } else {
      dispatch(clearCurrentReport());
    }
  }, [currentReportInfo, dispatch]);

  useEffect(() => {
    dispatch(toggleEdit(false));
    dispatch(toggleModified(false));
  }, [dispatch]);

  useEffect(() => {
    setLayout(currentReportWidgets.map(widget => widget.layout));
  }, [currentReportWidgets]);

  const handleChangePortfolioId = newPortfolioId => {
    history.push(
      generatePath(Routes.NEW_REPORT, {
        portfolioId: newPortfolioId,
      })
    );
  };
  const handleCreate = () => {
    const postData = transformDataForReport(
      currentReportInfo,
      currentReportSettings,
      currentReportWidgets
    );
    const draft = {
      ...postData,
      name: draftName,
    };
    create(draft, {
      onSuccess: data => {
        dispatch(toggleEdit(false));
        dispatch(toggleModified(false));
        history.push(
          generatePath(Routes.REPORT, {
            portfolioId,
            id: data.id,
          })
        );
      },
    });
  };
  const handleUpdate = () => {
    const postData = transformDataForReport(
      currentReportInfo,
      currentReportSettings,
      currentReportWidgets
    );
    const draft = {
      ...postData,
      name: draftName,
    };
    update(
      { data: draft, id },
      {
        onSuccess: () => {
          dispatch(toggleEdit(false));
          dispatch(toggleModified(false));
        },
      }
    );
  };
  const handleUpdateName = () => {
    if (id) {
      const draft = {
        name: draftName,
      };
      update({ data: draft, id });
    }
  };

  const handleDelete = () => {
    deleteReport(id, {
      onSuccess: () => {
        history.push(generatePath(Routes.CHOOSE_REPORT_WITH_PORTFOLIO, { portfolioId }));
      },
    });
  };
  const { onSaveClickWrapper, onDeleteClickWrapper, promptAndDialog } = useSavePromptAndDialog(
    isNew,
    isNew || isModified,
    {
      translationPath,
      onSaveClick: isNew ? handleCreate : handleUpdate,
      onDeleteClick: handleDelete,
    }
  );

  const returnButtonProps = {
    translateId: `${translationPath}.returnButton`,
    onClick: () => {
      history.push(generatePath(Routes.CHOOSE_REPORT_WITH_PORTFOLIO, { portfolioId }));
    },
  };

  const nameTitleProps = {
    name: draftName,
    onRename: setDraftName,
    onConfirm: handleUpdateName,
    startWithEditing: isNew,
    canRename: hasAccess && !isLoading,
  };

  let navButtonsProps = isNew
    ? [
        {
          translateId: 'navButton.create',
          onClick: onSaveClickWrapper,
          variant: 'highEmp',
          disabled: !hasAccess || !draftName || isLoading,
        },
      ]
    : [
        {
          translateId: 'navButton.delete',
          onClick: onDeleteClickWrapper,
          variant: 'midEmp',
          disabled: !hasAccess || isLoading,
        },
        {
          translateId: 'navButton.save',
          onClick: onSaveClickWrapper,
          variant: 'highEmp',
          disabled: !hasAccess || !draftName || isLoading || !isModified,
        },
      ];
  navButtonsProps = [
    {
      translateId: `${translationPath}.${editMode ? 'doneEditingButton' : 'startEditingButton'}`,
      onClick: () => dispatch(toggleEdit(!editMode)),
      variant: 'midEmp',
      disabled: !hasAccess || isLoading,
    },
    ...navButtonsProps,
  ];

  const grid = useMemo(() => {
    return transformLayoutToGrid(layout, classes);
  }, [classes, layout]);
  const handleDrop = (__, layoutItem, event) => {
    const type = event.dataTransfer.getData('text/plain');
    if (type in nameToWidgetMapping && layoutItem !== undefined) {
      const widget = prepareWidgetAddition(nameToWidgetMapping[type], layoutItem, editMode);
      dispatch(addWidgetToDashboard(widget));
      setSelectedWidget(widget);
      setSettingsType(type);
      setShowSettings(true);
      if (!editMode) {
        dispatch(toggleEdit(true));
      }
    }
  };

  let disableAddWidgets;
  if (document.getElementById('printBoard')) {
    disableAddWidgets = document.getElementById('printBoard').clientHeight > 5000;
  }

  return (
    <>
      {promptAndDialog}
      <div className='d-print-none'>
        <ScreenBase
          titleTranslationId={`${translationPath}.screenTitle`}
          nameTitleProps={nameTitleProps}
          navButtonsProps={navButtonsProps}
          returnButtonProps={returnButtonProps}
        >
          <TopScreenButtons>
            <PortfoliosDropdown
              disabled={!isNew}
              onChange={handleChangePortfolioId}
              portfolioId={portfolioId}
            />
          </TopScreenButtons>
          <ThemeProvider theme={reportTheme}>
            <Box sx={{ color: 'text.primary' }}>
              <Sidebar
                orientation={currentReportSettings.pageOrientation}
                type={settingsType}
                show={showSettings}
                widgetConfig={selectedWidget}
                parentShow={setShowSettings}
                setType={setSettingsType}
                disableAddWidgets={disableAddWidgets}
                setSelectedWidget={setSelectedWidget}
                setShowSettings={setShowSettings}
                setSettingsType={setSettingsType}
              />

              <div className={classes.content}>
                <Grid container>
                  <div id='printBoard' className={classes[currentReportSettings.pageOrientation]}>
                    <GridLayout
                      className={`layout ${classes.background}`}
                      layout={layout}
                      cols={12}
                      margin={
                        currentReportSettings.pageOrientation === 'landscape'
                          ? [10.55, 10.55]
                          : [10.45, 10.45]
                      }
                      rowHeight={currentReportSettings.pageOrientation === 'landscape' ? 29 : 31}
                      isDroppable
                      width={orientationMapping[currentReportSettings.pageOrientation]}
                      onDrop={handleDrop}
                      onLayoutChange={newLayout => dispatch(updateLayout(newLayout))}
                    >
                      {grid.map(item => {
                        const widget = currentReportWidgets.find(v => v.id === item.key);
                        return (
                          <div className={item.className} key={item.key}>
                            {item.component && !_.isEmpty(widget) ? (
                              <item.component
                                widget={widget}
                                setSelectedWidget={setSelectedWidget}
                                setShowSettings={setShowSettings}
                                setSettingsType={setSettingsType}
                                setLayout={setLayout}
                              />
                            ) : null}
                          </div>
                        );
                      })}
                    </GridLayout>
                  </div>
                </Grid>
              </div>
            </Box>
          </ThemeProvider>
        </ScreenBase>
      </div>
      <div
        id='printDestination'
        className={`d-print-block ${currentReportSettings.pageOrientation}`}
      />
    </>
  );
};

export default ReportScreen;

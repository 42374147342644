import makeStyles from '@mui/styles/makeStyles';

const tableWithFonts = {
  smallTable: {
    fontSize: '8px !important',
  },
  mediumTable: {
    fontSize: '11px !important',
  },
  largeTable: {
    fontSize: '14px !important',
  },
};

const wrappers = {
  mainCardWrapper: {
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    height: 'inherit',
    width: 'inherit',
    outline: '0.3px solid #d9dada',
    overflow: 'hidden',
    '@media print': {
      outline: 'none',
    },
  },
};

export default makeStyles({
  ...tableWithFonts,
  ...wrappers,
});

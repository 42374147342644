import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format, parse } from 'date-fns';
import { isEmpty, first } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useTranslate } from 'lib/localization/localization';
import RatioItem from 'screens/Analysis/AnalysisScreen/widgets/RatiosBar/RatioItem';
import TestSpinner from 'screens/Analysis/lib/components/TestSpinner';
import useDateToggle from 'screens/Analysis/lib/components/useDateToggle';
import WidgetTitleSelector from 'screens/Analysis/lib/components/WidgetTitleSelector';
import { useAnalysisQuery } from 'screens/Analysis/lib/queries';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '0.7rem',
    position: 'relative',
  },
  content: {
    height: '10rem',
    width: '100%',
    marginTop: '0.4rem',
  },
});

const DrawdownBar = ({ analysisId }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { analysis } = useAnalysisQuery(analysisId);
  const widgetData = analysis?.result?.DrawdownBar ?? {};

  const [title, setTitle] = useState('portfolio');

  const [dateFilter, DateFilterComponent] = useDateToggle(
    !isEmpty(widgetData) ? first(widgetData.filters) : 'all'
  );

  return (
    <Grid item className={classes.wrapper} md={12}>
      {!isEmpty(widgetData) && (
        <>
          <WidgetTitleSelector
            mainTitle='drawdowns'
            title={title}
            setTitle={setTitle}
            options={['portfolio', 'benchmark']}
            omitMargin
          />
          <DateFilterComponent
            available={widgetData.filters}
            errors={widgetData.errors}
            hints={widgetData.hints}
          />
        </>
      )}
      <Grid container spacing={4}>
        {isEmpty(widgetData) ? (
          <div className={classes.content}>
            <TestSpinner />
          </div>
        ) : (
          <>
            {widgetData.data[dateFilter][title].map((item, i) => {
              const startDate = parse(item.start, 'yyyy-MM-dd', new Date());
              const endDate = parse(item.end, 'yyyy-MM-dd', new Date());
              const recoveryString = item.daysRecovery
                ? `${item.daysRecovery} ${translate('analysis.daysRecovery')}`
                : translate('analysis.notRecovered');
              const ratioItemData = {
                subTitle: `${item.daysDrawdown} ${translate(
                  'analysis.daysDrawdown'
                )} | ${recoveryString}`,
                title: `${format(startDate, 'MMM dd, yyyy')} - ${format(endDate, 'MMM dd, yyyy')}`,
                isPercentage: true,
              };
              if (title === 'portfolio') {
                ratioItemData.portfolio = item.value;
                ratioItemData.benchmark = item.valueComparison;
              } else {
                ratioItemData.benchmark = item.value;
                ratioItemData.portfolio = item.valueComparison;
              }

              return <RatioItem key={`${i + 1}-${ratioItemData.title}`} {...ratioItemData} />;
            })}
          </>
        )}
      </Grid>
    </Grid>
  );
};

DrawdownBar.propTypes = {
  analysisId: PropTypes.string,
};

DrawdownBar.defaultProps = {
  analysisId: undefined,
};

export default DrawdownBar;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { findDiffOriginalAndDraft } from 'lib/misc/diff';
import { setLocalStorageValue } from 'redux/ui/actions';

function returnInitialState<T>(storageKey: string, defaultValue: T): T {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(storageKey);
    // Parse stored json or if none return an empty object
    return item ? JSON.parse(item) : defaultValue;
  } catch (error) {
    return defaultValue;
  }
}

function useLocalStorage<T>(storageKey: string, defaultValue: T) {
  const dispatch = useDispatch();
  const reduxValue = useSelector((state: any) => state.ui.localStorageItems[storageKey]);
  const storedValue = returnInitialState<T>(storageKey, defaultValue);

  useEffect(() => {
    const { isEqual } = findDiffOriginalAndDraft(storedValue, reduxValue);
    if (!isEqual) {
      dispatch(setLocalStorageValue(storageKey, storedValue));
    }
  });

  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save to local storage
      window.localStorage.setItem(storageKey, JSON.stringify(valueToStore));
      // Save state
      dispatch(setLocalStorageValue(storageKey, valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
    }
  };

  return [storedValue, setValue] as [T, (value: T) => void];
}

export default useLocalStorage;

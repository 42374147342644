import MenuItem from '@mui/material/MenuItem';
import React, { useEffect } from 'react';

import MyDropdown from 'components/MyDropdown';
import { useTranslate } from 'lib/localization/localization';
import { DEFAULT_SINGLE_ALLOC_CHIPS } from 'lib/weights/constants';

const translationPath = 'lib.weights.useAssetAllocation' as const;

type AllocationTypeT = typeof DEFAULT_SINGLE_ALLOC_CHIPS[number];

interface AllocationTypeDropdownProps {
  selectedAllocType: AllocationTypeT | undefined;
  setSelectedAllocType: (allocType: AllocationTypeT) => void;
}

const AllocationTypeDropdown = ({
  selectedAllocType,
  setSelectedAllocType,
}: AllocationTypeDropdownProps) => {
  const translate = useTranslate();
  return (
    <div>
      <MyDropdown
        // @ts-ignore
        value={selectedAllocType}
        onChange={setSelectedAllocType}
        // onUpdateSearch={setSelectedAllocType}
        translationPath={`${translationPath}.dropdown`}
        isNullAllowed={false}
      >
        {DEFAULT_SINGLE_ALLOC_CHIPS.map(type => (
          <MenuItem key={type} value={type}>
            {translate(`${translationPath}.chip.${type}`)}
          </MenuItem>
        ))}
      </MyDropdown>
    </div>
  );
};

interface UseAllocationDropdownProps {
  initialAllocationType?: AllocationTypeT;
}

const useAllocationDropdown = ({ initialAllocationType }: UseAllocationDropdownProps) => {
  const [selectedAssetAllocType, setSelectedAllocType] = React.useState(
    initialAllocationType || DEFAULT_SINGLE_ALLOC_CHIPS[0]
  );
  useEffect(() => {
    if (initialAllocationType !== undefined) {
      setSelectedAllocType(initialAllocationType);
    }
  }, [initialAllocationType]);
  return {
    selectedAssetAllocType,
    assetAllocTypeDropdownElem: (
      <AllocationTypeDropdown
        selectedAllocType={selectedAssetAllocType}
        setSelectedAllocType={setSelectedAllocType}
      />
    ),
  };
};

export default useAllocationDropdown;

import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { MenuItem, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';

import MyDropdown from 'components/MyDropdown/MyDropdown';
import SimpleText from 'components/SimpleText';
import { Order } from 'utils/constants';

const useSortingField = (columns, selectedColumn, selectedOrder) => {
  const [column, setColumn] = useState(selectedColumn);
  const [order, setOrder] = useState(selectedOrder);

  const SortingField = () => (
    <>
      <SimpleText translateId='dashboard.sort' fontWeight='bold' my={4} />
      <MyDropdown
        value={column}
        onChange={value => setColumn(value)}
        placeholderId='column'
        isNullAllowed={false}
      >
        {columns.map(item => (
          <MenuItem value={item.label} key={item.label}>
            <Translate id={`dashboard.${item.label}`} />
          </MenuItem>
        ))}
      </MyDropdown>
      {column ? (
        <>
          <SimpleText translateId='dashboard.order' fontWeight='bold' my={4} />
          <IconButton onClick={() => setOrder(Order.ASC)} size='large'>
            <ArrowUpward fontSize='large' color={order === Order.ASC ? 'primary' : 'action'} />
          </IconButton>
          <IconButton onClick={() => setOrder(Order.DESC)} size='large'>
            <ArrowDownward fontSize='large' color={order === Order.DESC ? 'primary' : 'action'} />
          </IconButton>
        </>
      ) : null}
    </>
  );

  return [SortingField, column, order];
};

export default useSortingField;

import DoneIcon from '@mui/icons-material/Done';
import { Chip, Stack } from '@mui/material';
import { addBusinessDays, endOfDay, endOfYesterday, subBusinessDays, subYears } from 'date-fns';
import React, { useEffect } from 'react';

import DatePickerBase from 'lib/dates/DatePickerBase';
import { toDateString } from 'lib/dates/dateUtils';
import { useTranslate } from 'lib/localization/localization';
import { IsoDate } from 'lib/types';

const translationPath = 'backtestLib.startEndDatePickers';

interface DatesPickerProps {
  startDate: IsoDate;
  endDate: IsoDate;
  onStartDateChange: (startDate: IsoDate) => void;
  onEndDateChange: (endDate: IsoDate) => void;
  disabled?: boolean;
  limitStartDate?: Date | IsoDate;
  limitEndDate?: Date | IsoDate;
}

const QUICK_SET_YEARS = [1, 3, 5];
export const DEFAULT_END_DATE = endOfYesterday();
export const DEFAULT_START_DATE = subYears(DEFAULT_END_DATE, 3);
const LIMIT_START_DATE = subYears(DEFAULT_END_DATE, 6);
const LIMIT_END_DATE = DEFAULT_END_DATE;

const useDatesPicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  disabled = false,
  limitStartDate = LIMIT_START_DATE,
  limitEndDate = LIMIT_END_DATE,
}: DatesPickerProps) => {
  const translate = useTranslate();
  const endDateDate = endOfDay(new Date(endDate));
  const startDateDate = endOfDay(new Date(startDate));
  const limitStartDateDate = endOfDay(new Date(limitStartDate));
  const limitEndDateDate = endOfDay(new Date(limitEndDate));

  const handleStartDateChange = (newStartDate: Date) => {
    let adjustedStartDate = newStartDate;
    if (newStartDate < limitStartDateDate) {
      adjustedStartDate = limitStartDateDate;
    } else if (newStartDate >= limitEndDateDate) {
      adjustedStartDate = subBusinessDays(limitEndDateDate, 1);
    }
    if (subBusinessDays(endDateDate, 1) < adjustedStartDate) {
      onEndDateChange(toDateString(addBusinessDays(adjustedStartDate, 1)));
    }
    onStartDateChange(toDateString(adjustedStartDate));
  };

  const handleEndDateChange = (newEndDate: Date) => {
    let adjustedEndDate = newEndDate;
    if (newEndDate > limitEndDateDate) {
      adjustedEndDate = limitEndDateDate;
    } else if (newEndDate <= limitStartDateDate) {
      adjustedEndDate = addBusinessDays(limitStartDateDate, 1);
    }
    if (addBusinessDays(startDateDate, 1) > adjustedEndDate) {
      onStartDateChange(toDateString(subBusinessDays(adjustedEndDate, 1)));
    }
    onEndDateChange(toDateString(adjustedEndDate));
  };

  const handleDatesChange = (newStartDate: Date, newEndDate: Date) => {
    let adjustedEndDate = newEndDate;
    let adjustedStartDate = newStartDate;
    if (addBusinessDays(adjustedStartDate, 1) > adjustedEndDate) {
      adjustedStartDate = subBusinessDays(adjustedEndDate, 1);
    }
    if (newEndDate > limitEndDateDate) {
      adjustedEndDate = limitEndDateDate;
    } else if (newEndDate <= limitStartDateDate) {
      adjustedEndDate = addBusinessDays(limitStartDateDate, 1);
    }
    if (newStartDate < limitStartDateDate) {
      adjustedStartDate = limitStartDateDate;
    } else if (newStartDate >= limitEndDateDate) {
      adjustedStartDate = subBusinessDays(limitEndDateDate, 1);
    }
    onStartDateChange(toDateString(adjustedStartDate));
    onEndDateChange(toDateString(adjustedEndDate));
  };

  const handleQuickSet = (nYears: number) => {
    handleDatesChange(subYears(limitEndDateDate, nYears), limitEndDateDate);
  };

  useEffect(() => {
    handleDatesChange(startDateDate, endDateDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitStartDate, limitEndDate]);

  const startDatePickerElem = (
    <DatePickerBase
      value={startDate}
      onChange={handleStartDateChange}
      // @ts-ignore
      minDate={limitStartDateDate}
      maxDate={limitEndDateDate}
      disabled={disabled}
    />
  );
  const endDatePickerElem = (
    <DatePickerBase
      value={endDate}
      onChange={handleEndDateChange}
      // @ts-ignore
      minDate={limitStartDateDate}
      maxDate={limitEndDateDate}
      disabled={disabled}
    />
  );
  const quickSetElem = (
    <Stack direction='row' spacing={2}>
      {QUICK_SET_YEARS.map(nYears => {
        const isClicked = subYears(endDateDate, nYears).getTime() === startDateDate.getTime();
        return (
          <Chip
            variant='outlined'
            key={nYears}
            label={translate(`${translationPath}.quickSetYears.${nYears}`)}
            onClick={() => handleQuickSet(nYears)}
            color={isClicked ? 'primary' : 'default'}
            icon={isClicked ? <DoneIcon /> : undefined}
          />
        );
      })}
    </Stack>
  );
  return {
    startDatePickerElem,
    endDatePickerElem,
    quickSetElem,
  };
};

export default useDatesPicker;

import Typography, { TypographyProps } from '@mui/material/Typography';
import React from 'react';
import { LocalizedElement, LocalizedElementMap, Translate } from 'react-localize-redux';

interface SimpleTextProps {
  bold?: boolean;
  translateId?: string;
  text?: string | number | LocalizedElement | LocalizedElementMap;
  translateData?: any;
}

const SimpleText = ({
  translateId,
  text,
  translateData,
  ...other
}: SimpleTextProps & TypographyProps) => (
  <Typography {...other}>
    {translateId ? <Translate id={translateId} data={translateData} /> : null}
    {text}
  </Typography>
);

export default SimpleText;

import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  ChartLabel,
  HorizontalGridLines,
  LineSeries,
  XAxis,
  XYPlot,
  YAxis,
  MarkSeries,
  Hint,
} from 'react-vis';

import { ChartDataType, ClassesType, MarkSeriesDataType } from 'utils/types';

const styles = theme => ({
  hint: {
    backgroundColor: `${theme.palette.neutral.dark} !important`,
    color: theme.palette.common.white,
    marginBottom: '3rem',
    marginTop: '3rem',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  hintContent: {
    fontSize: '0.6rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  unselectable: {
    unelectable: 'on',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },
});

function getMainLineChartData(lineChartData) {
  return lineChartData.main;
}

function getAllSeries(data, start, end) {
  const dataKeys = Object.keys(data);
  let allSeries = [];
  for (let i = 0; i < dataKeys.length; i += 1) {
    if (data[dataKeys[i]].series) {
      allSeries = allSeries.concat(data[dataKeys[i]].series.slice(start, end));
    }
  }
  return allSeries;
}

function getMinMAx(data, startIndex, endIndex, howMany) {
  let start;
  let end;
  let count;
  if (!howMany) {
    count = 3;
  } else {
    count = howMany;
  }
  if (!startIndex) {
    start = 0;
  } else {
    start = startIndex;
  }
  if (!endIndex) {
    end = data.main.series.length;
  } else {
    end = endIndex;
  }
  const allSeries = getAllSeries(data, start, end);
  let lowest = Number.POSITIVE_INFINITY;
  let highest = Number.NEGATIVE_INFINITY;
  let tmp;
  for (let i = allSeries.length - 1; i >= 0; i -= 1) {
    tmp = allSeries[i].y;
    if (tmp < lowest) lowest = tmp;
    if (tmp > highest) highest = tmp;
  }
  const dif = (highest - lowest) / count;
  const gridLines = [];
  // for till count + 2 if need some empty space above all lines
  for (let i = 0; i <= count; i += 1) {
    const tempGrid = lowest + i * dif;
    gridLines.push(tempGrid);
  }
  return gridLines;
}

class MyLineChartBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markSeriesHint: null,
    };
  }

  state = {
    markSeriesHint: false,
  };

  componentDidMount() {
    this.setState({
      isDragging: false,
    });
    this.zoomOut();
  }

  componentDidUpdate(prevProps) {
    const { data, skipScrolling } = this.props;
    if (data !== prevProps.data && skipScrolling) {
      this.zoomOut();
    }
  }

  handleWheelEvent(event) {
    const { data, zoomSlowness } = this.props;
    const mainData = getMainLineChartData(data);
    const { zoomAreaSize, markSeriesHint, startIndex, endIndex } = this.state;
    let newStartIndex = startIndex;
    let newEndIndex = endIndex;
    let newZoomAreaSize = zoomAreaSize;
    if (event.deltaY < 0) {
      if (zoomAreaSize - mainData.series.length / zoomSlowness >= 10) {
        newZoomAreaSize = zoomAreaSize - mainData.series.length / zoomSlowness;
      }
    } else if (event.deltaY > 0) {
      if (zoomAreaSize + mainData.series.length / zoomSlowness <= mainData.series.length) {
        newZoomAreaSize = zoomAreaSize + mainData.series.length / zoomSlowness;
      } else {
        newStartIndex = 0;
        newEndIndex = mainData.series.length;
        newZoomAreaSize = mainData.series.length;
      }
    }

    if (newZoomAreaSize < mainData.series.length) {
      for (let i = 0; i < mainData.series.length; i += 1) {
        if (mainData.series[i] && markSeriesHint) {
          if (mainData.series[i].x.getTime() === markSeriesHint.x.getTime()) {
            if (i - Math.round(newZoomAreaSize / 2) >= 0) {
              newStartIndex = i - Math.round(newZoomAreaSize / 2);
            } else {
              newStartIndex = 0;
            }
            if (i + Math.round(newZoomAreaSize / 2) <= mainData.series.length) {
              newEndIndex = i + Math.round(newZoomAreaSize / 2);
            } else {
              newEndIndex = mainData.series.length;
            }
          }
        }
      }
    }
    this.setState({
      zoomAreaSize: newZoomAreaSize,
      startIndex: newStartIndex,
      endIndex: newEndIndex,
    });
    if (newStartIndex !== startIndex || newEndIndex !== endIndex) {
      this.callbackOnDatesChange(mainData, newStartIndex, newEndIndex);
    }
  }

  getRebalancingPoint(markSeriesHint) {
    const { rebalancingPoints, onRebalancingPointClick } = this.props;
    const exactTimes = rebalancingPoints.map(e => e.x.getTime());
    const closest = exactTimes.reduce((prev, curr) =>
      Math.abs(curr - markSeriesHint.x.getTime()) < Math.abs(prev - markSeriesHint.x.getTime())
        ? curr
        : prev
    );
    const index = exactTimes.indexOf(closest);
    rebalancingPoints.sort((a, b) => a.x - b.x);
    let secondPoint;
    const firstPoint = rebalancingPoints[index];
    if (index === 0) {
      secondPoint = rebalancingPoints[index + 1];
      if (!secondPoint) {
        secondPoint = firstPoint;
      }
      onRebalancingPointClick(firstPoint.x, secondPoint.x);
    } else {
      secondPoint = rebalancingPoints[index - 1];
      if (!secondPoint) {
        secondPoint = firstPoint;
      }
      onRebalancingPointClick(secondPoint.x, firstPoint.x);
    }
  }

  getVisibleRebalancingPoints() {
    const { data, rebalancingPoints } = this.props;
    const { startIndex, endIndex } = this.state;
    const mainData = getMainLineChartData(data);
    if (mainData.series.length > 1) {
      if (mainData.series.slice(startIndex, endIndex).length > 1) {
        const startDate = mainData.series.slice(startIndex, endIndex)[0].x.getTime();
        const endDate = mainData.series
          .slice(startIndex, endIndex)
          [mainData.series.slice(startIndex, endIndex).length - 1].x.getTime();
        const visibleRebalancingPoints = [];
        if (rebalancingPoints) {
          for (let i = 0; i < rebalancingPoints.length; i += 1) {
            if (
              rebalancingPoints[i].x.getTime() >= startDate &&
              rebalancingPoints[i].x.getTime() <= endDate
            ) {
              visibleRebalancingPoints.push(rebalancingPoints[i]);
            }
          }
          return visibleRebalancingPoints;
        }
      }
    }
    return null;
  }

  forgetValue = () => {
    this.setState({
      markSeriesHint: null,
    });
  };

  rememberValue = markSeriesHint => {
    const { onPointerMove } = this.props;
    if (onPointerMove) {
      this.setState({ markSeriesHint });
      onPointerMove(markSeriesHint ? markSeriesHint.x : null);
    }
  };

  handleStartDrag = event => {
    this.setState({ isDragging: true, dragStartPositionX: event.clientX, mouseDown: true });
    document.body.style.cursor = 'grab';
  };

  handleEndDrag = () => {
    this.setState({ isDragging: false, mouseDown: false });
    document.body.style.cursor = 'default';
  };

  handleDragging = event => {
    const { width, data } = this.props;
    const { dragStartPositionX, zoomAreaSize, startIndex, endIndex, mouseDown } = this.state;
    if (mouseDown) {
      document.body.style.cursor = 'grabbing';
      const mainData = getMainLineChartData(data);
      const percentageChange = (event.clientX - dragStartPositionX) / width;
      const indexChange = Math.floor(zoomAreaSize * percentageChange);
      const newStartIndex = startIndex - indexChange;
      const newEndIndex = endIndex - indexChange;
      this.setState({ dragStartPositionX: event.clientX });
      if (newStartIndex >= 0 && newEndIndex <= mainData.series.length) {
        this.setState({ startIndex: newStartIndex, endIndex: newEndIndex });
      }
      this.callbackOnDatesChange(mainData, startIndex, endIndex);
    }
  };

  preventDefault = e => {
    const relevantE = e || window.document;
    if (relevantE.preventDefault) relevantE.preventDefault();
    relevantE.returnValue = false;
  };

  callbackOnDatesChange = (mainData, startIndex, endIndex) => {
    const { onDatesChange } = this.props;
    if (onDatesChange) {
      let startDate;
      let endDate;
      const timeSeries = mainData.series.slice(startIndex, endIndex);
      if (timeSeries.length > 1) {
        startDate = timeSeries[0].x;
        endDate = timeSeries[timeSeries.length - 1].x;
        onDatesChange(startDate, endDate);
      }
    }
  };

  handleMouseLeaveLineChart = () => {
    this.setState({ markSeriesHint: false, mouseDown: false });
    document.removeEventListener('wheel', this.preventDefault, { passive: false }); // Enable scrolling in Chrome
    window.onwheel = null;
    window.ontouchmove = null;
    document.onkeydown = null;
  };

  disableScrolling = () => {
    document.addEventListener('wheel', this.preventDefault, { passive: false }); // Disable scrolling in Chrome
    window.onwheel = this.preventDefault; // modern standard
    window.ontouchmove = this.preventDefault; // mobile
  };

  zoomOut = () => {
    const { data } = this.props;
    const mainData = getMainLineChartData(data);
    const startIndex = 0;
    const endIndex = mainData.series.length;
    this.setState({
      startIndex,
      endIndex,
      zoomAreaSize: endIndex,
    });
    this.callbackOnDatesChange(mainData, startIndex, endIndex);
  };

  render() {
    const {
      theme,
      classes,
      data,
      XAxisTitle,
      YAxisTitle,
      markSeriesData,
      width,
      height,
      gridLinesCount,
      hoveredLine,
      tickTotalX,
      rebalancingPoints,
      onRebalancingPointClick,
      skipScrolling,
      whiteBackground,
      transparentBackground,
    } = this.props;
    const mainData = getMainLineChartData(data);
    const { markSeriesHint, startIndex, endIndex, isDragging } = this.state;
    const visibleRebalancingData = this.getVisibleRebalancingPoints();
    const lines = [];
    const lineKeys = Object.keys(data);
    for (let i = 0; i < lineKeys.length; i += 1) {
      if (!data[lineKeys[i]].error) {
        lines.push(
          <LineSeries
            key={lineKeys[i]}
            strokeWidth={data[lineKeys[i]].name === hoveredLine ? 3 : 1.5}
            stroke={data[lineKeys[i]].color}
            data={data[lineKeys[i]].series.slice(startIndex, endIndex)}
          />
        );
      }
    }
    let backgroundColor = theme.palette.background.default;

    if (transparentBackground) {
      backgroundColor = 'inherit';
    }

    if (whiteBackground) {
      backgroundColor = '#FFF';
    }

    return (
      <div
        onMouseDown={event => this.handleStartDrag(event)}
        onMouseUp={() => this.handleEndDrag()}
        onMouseMove={event => this.handleDragging(event)}
        onDrag={() => this.preventDefault()}
        onMouseLeave={() => this.handleEndDrag()}
      >
        <XYPlot
          width={width}
          height={height}
          onDoubleClick={() => this.zoomOut()}
          onWheel={event => (!skipScrolling ? this.handleWheelEvent(event) : null)}
          onMouseEnter={() => (!skipScrolling ? this.disableScrolling() : null)}
          onMouseLeave={() => this.handleMouseLeaveLineChart()}
          yDomain={[
            getMinMAx(data, startIndex, endIndex, gridLinesCount)[0],
            getMinMAx(data, startIndex, endIndex, gridLinesCount)[
              getMinMAx(data, startIndex, endIndex, gridLinesCount).length - 1
            ],
          ]}
          style={{
            backgroundColor,
          }}
        >
          <HorizontalGridLines
            tickValues={getMinMAx(data, startIndex, endIndex, gridLinesCount)}
            style={{ strokeWidth: 0.2, stroke: theme.palette.text.secondary }}
          />
          <XAxis
            title={XAxisTitle}
            tickTotal={tickTotalX}
            xType='time'
            style={{
              line: { strokeWidth: 0.1, stroke: theme.palette.text.secondary },
              text: {
                stroke: 'none',
                fill: theme.palette.text.secondary,
                fontSize: 9,
                fontWeight: 400,
                unelectable: 'on',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none',
              },
            }}
            tickSize={1}
          />
          <YAxis
            title={YAxisTitle}
            tickValues={getMinMAx(data, startIndex, endIndex, gridLinesCount)}
            style={{
              line: { strokeWidth: 0.1, stroke: theme.palette.text.secondary },
              text: {
                stroke: 'none',
                fill: theme.palette.text.secondary,
                fontSize: 9,
                fontWeight: 400,
                unelectable: 'on',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none',
              },
              title: { stroke: theme.palette.text.secondary },
            }}
            tickSize={1}
          />
          <ChartLabel
            style={{
              text: {
                unelectable: 'on',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none',
              },
            }}
            text='%'
            includeMargin={false}
          />
          {lines}
          {markSeriesData ? (
            <MarkSeries
              onNearestX={this.rememberValue}
              onValueMouseOut={this.forgetValue}
              data={markSeriesData.slice(startIndex, endIndex)}
              size={0.1}
              color={theme.palette.text.primary}
            />
          ) : null}
          {rebalancingPoints ? (
            <MarkSeries
              data={visibleRebalancingData}
              size={hoveredLine === mainData.name ? 7.5 : 6}
              style={{ stroke: theme.palette.text.primary, fill: theme.palette.text.primary }}
              color={theme.palette.text.primary}
              onSeriesClick={
                onRebalancingPointClick ? () => this.getRebalancingPoint(markSeriesHint) : null
              }
            />
          ) : null}
          {markSeriesHint && !isDragging ? (
            <LineSeries
              data={[
                {
                  x: markSeriesHint.x,
                  y: getMinMAx(data, startIndex, endIndex, gridLinesCount)[
                    getMinMAx(data, startIndex, endIndex, gridLinesCount).length - 1
                  ],
                },
                {
                  x: markSeriesHint.x,
                  y: getMinMAx(data, startIndex, endIndex, gridLinesCount)[0],
                },
              ]}
              stroke={theme.palette.text.primary}
              strokeWidth={0.5}
            />
          ) : null}
          {markSeriesHint && !isDragging ? (
            <Hint value={markSeriesHint}>
              <div className={classes.hint}>
                <div className={classes.hintContent}>
                  {`Date:
                ${markSeriesHint.x.getDate()}/
                ${markSeriesHint.x.getMonth() + 1}/
                ${markSeriesHint.x.getFullYear()}`}
                </div>
              </div>
            </Hint>
          ) : null}
        </XYPlot>
      </div>
    );
  }
}

MyLineChartBase.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  classes: ClassesType.isRequired,
  data: ChartDataType.isRequired,
  XAxisTitle: PropTypes.string,
  YAxisTitle: PropTypes.string,
  onDatesChange: PropTypes.func,
  markSeriesData: MarkSeriesDataType,
  gridLinesCount: PropTypes.number,
  hoveredLine: PropTypes.string,
  zoomSlowness: PropTypes.number,
  tickTotalX: PropTypes.number,
  onPointerMove: PropTypes.func,
  rebalancingPoints: MarkSeriesDataType,
  onRebalancingPointClick: PropTypes.func,
  skipScrolling: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  transparentBackground: PropTypes.bool,
};

MyLineChartBase.defaultProps = {
  XAxisTitle: null,
  YAxisTitle: null,
  onDatesChange: null,
  markSeriesData: null,
  gridLinesCount: 4,
  hoveredLine: null,
  zoomSlowness: 40,
  tickTotalX: 3,
  onPointerMove: null,
  rebalancingPoints: null,
  onRebalancingPointClick: null,
  skipScrolling: false,
  whiteBackground: false,
  transparentBackground: false,
};

export default withStyles(styles, { withTheme: true })(MyLineChartBase);

import PropTypes from 'prop-types';
import React from 'react';
import {
  ChartLabel,
  HorizontalGridLines,
  LineSeries,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis-bi0max/es';

import { ChartDataType } from 'utils/types';

function getDummyData() {
  const startDate = new Date('2019-05-01');
  const endDate = new Date('2019-06-01');
  const data = [];

  const dt = new Date(startDate);
  while (dt <= endDate) {
    data.push({ x: new Date(dt), y: 0 });
    dt.setDate(dt.getDate() + 1);
  }
  return data;
}

function getMinMAx(data) {
  let lowest = Number.POSITIVE_INFINITY;
  let highest = Number.NEGATIVE_INFINITY;
  let tmp;
  for (let i = data.length - 1; i >= 0; i -= 1) {
    tmp = data[i].y;
    if (tmp < lowest) lowest = tmp;
    if (tmp > highest) highest = tmp;
  }
  const dif = (highest - lowest) / 3;
  return [lowest, lowest + dif, highest - dif, highest];
}

const MySimpleLineChartBase = props => {
  const { data, height, width, XAxisTitle, YAxisTitle } = props;
  return (
    <XYPlot width={width} height={height}>
      <HorizontalGridLines
        tickValues={getMinMAx(data)}
        style={{ strokeWidth: 0.2, stroke: '#FFFFFF' }}
      />
      <XAxis
        title={XAxisTitle}
        tickTotal={3}
        xType='time'
        style={{
          line: { strokeWidth: 0.1, stroke: '#FFFFFF' },
          text: {
            stroke: 'none',
            fill: '#FFFFFF',
            fontSize: 9,
            fontWeight: 400,
          },
        }}
        tickSize={1}
      />
      <YAxis
        title={YAxisTitle}
        tickValues={getMinMAx(data)}
        style={{
          line: { strokeWidth: 0.1, stroke: '#FFFFFF' },
          text: {
            stroke: 'none',
            fill: '#FFFFFF',
            fontSize: 9,
            fontWeight: 400,
          },
          title: { stroke: '#FFFFFF' },
        }}
        tickSize={1}
      />
      <ChartLabel text='%' includeMargin={false} />
      <LineSeries strokeWidth={2} stroke='#FFFFFF' data={data} />
      {getMinMAx(data)[0] <= 0 ? (
        <LineSeries data={getDummyData()} strokeWidth={0.7} stroke='#FFFFFF' />
      ) : null}
    </XYPlot>
  );
};

MySimpleLineChartBase.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: ChartDataType.isRequired,
  XAxisTitle: PropTypes.string,
  YAxisTitle: PropTypes.string,
};

MySimpleLineChartBase.defaultProps = {
  XAxisTitle: null,
  YAxisTitle: null,
};

export default MySimpleLineChartBase;

import { CircularProgress } from '@mui/material';
import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import useEditPermission from 'lib/access/useEditPermission';
import { Id } from 'lib/types';
import useStrategyConfiguration from 'screens/Portfolio/lib/hooks/useStrategyConfiguration';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { Routes } from 'utils/constants';

const PortfolioStrategyTab = () => {
  const history = useHistory();
  const { id } = useParams<{ id: Id }>();
  const { portfolio, isLoading } = usePortfolioQuery(id);
  const hasEditAccess = useEditPermission(portfolio);
  const handleReconfigurationClick = () => {
    history.push(generatePath(Routes.PORTFOLIO_RECONFIGURATION, { id }));
  };
  const strategy = portfolio?.optimizationState?.strategy;
  const { strategyConfigElement } = useStrategyConfiguration({
    strategy,
    disabled: true,
    onReconfigureClick: hasEditAccess ? handleReconfigurationClick : undefined,
  });

  if (isLoading || !strategy) {
    return <CircularProgress />;
  }

  return strategyConfigElement;
};

export default PortfolioStrategyTab;

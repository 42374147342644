import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React, { useEffect } from 'react';

import MyDropdown from 'components/MyDropdown';
import { fuseSearch } from 'components/SearchBar/utils';
import SimpleText from 'components/SimpleText';
import { useDraftValue } from 'lib/misc/useDraft/useDraft';
import { Id, NullableId } from 'lib/types';
import {
  StrategyCard,
  StrategyCardContent,
  StrategyCardMainSection,
} from 'screens/Portfolio/lib/hooks/useStrategyConfiguration/cards/StrategyCard';
import { useBasketsQuery, useUniversesQuery } from 'screens/Universe/lib/queries';

const translationPath = 'portfolioLib.strategyConfiguration.basketCard' as const;

const useStyles = makeStyles(theme => ({
  text: {
    paddingTop: theme.spacing(4),
  },
  basketName: {
    color: theme.palette.text.primary,
  },
}));

interface BasketCardProps {
  universeId?: Id;
  basketId?: NullableId;
  disabled?: boolean;
  onChangeUniverseId?: (value: Id) => void;
  onChangeBasketId?: (value: Id) => void;
}

const BasketCard = ({
  universeId,
  basketId = null,
  disabled = false,
  onChangeUniverseId = undefined,
  onChangeBasketId = undefined,
}: BasketCardProps) => {
  const classes = useStyles();
  const { baskets, isLoading: isLoadingBaskets } = useBasketsQuery();
  const { universes, isLoading: isLoadingUniverses } = useUniversesQuery();
  const [searchValueBasket, setSearchValueBasket] = React.useState('');
  const [searchValueUnivese, setSearchValueUniverse] = React.useState('');
  const amountUniverses = _.size(universes);
  const basketsFromUniverse = _.values(baskets).filter(basket => basket.universe === universeId);
  const searchedBaskets = fuseSearch(basketsFromUniverse, searchValueBasket, ['name']);
  const searchedUniverses = fuseSearch(Object.values(universes), searchValueUnivese, ['name']);

  const isLoading = isLoadingBaskets || isLoadingUniverses;

  return (
    <StrategyCard isLoading={isLoading} title={`${translationPath}.title`}>
      <StrategyCardMainSection>
        {amountUniverses > 1 ? (
          <div>
            <MyDropdown
              // @ts-ignore
              value={universeId}
              onChange={onChangeUniverseId}
              onUpdateSearch={setSearchValueUniverse}
              placeholderId='universe'
              disabled={disabled || onChangeUniverseId === undefined}
              isNullAllowed={false}
            >
              {searchedUniverses.map(universe => (
                <MenuItem key={universe.id} value={universe.id}>
                  {universe.name}
                </MenuItem>
              ))}
            </MyDropdown>
          </div>
        ) : null}

        <div>
          <MyDropdown
            // @ts-ignore
            value={basketId}
            onChange={onChangeBasketId}
            onUpdateSearch={setSearchValueBasket}
            placeholderId='basket'
            disabled={disabled || onChangeBasketId === undefined}
          >
            {searchedBaskets.map(basket => (
              <MenuItem key={basket.id} value={basket.id}>
                {basket.name}
              </MenuItem>
            ))}
          </MyDropdown>
        </div>
      </StrategyCardMainSection>
      <StrategyCardContent>
        <div className={classes.text}>
          {basketId ? (
            <SimpleText
              className={classes.basketName}
              translateId={`${translationPath}.basketChosen`}
              translateData={{
                amountAssets: baskets?.[basketId]?.assets?.length ?? '',
                name: baskets[basketId] ? baskets[basketId].name : '',
              }}
              variant='body1'
            />
          ) : (
            <SimpleText
              translateId={`${translationPath}.noBasketChosen`}
              variant='body1'
              translateData={{
                amountAssets:
                  universeId && universes[universeId] ? universes[universeId].assets.length : '',
              }}
            />
          )}
        </div>
      </StrategyCardContent>
    </StrategyCard>
  );
};

interface UseBasketCardProps {
  universeId?: Id;
  basketId?: NullableId;
  isNew?: boolean;
  disabled?: boolean;
}

const useBasketCard = ({
  universeId,
  basketId = null,
  disabled = false,
  isNew = false,
}: UseBasketCardProps) => {
  const { draft: draftUniverseId, setDraft: setDraftUniverseId } = useDraftValue(universeId);
  const { draft: draftBasketId, setDraft: setDraftBasketId } = useDraftValue(basketId);
  const { universes, isLoading: isLoadingUniverses } = useUniversesQuery();

  useEffect(() => {
    if (isNew && universeId === undefined && !isLoadingUniverses) {
      const firstUniverseKey = Object.keys(universes)[0];
      if (firstUniverseKey && universes[firstUniverseKey]) {
        setDraftUniverseId(universes[firstUniverseKey].id);
      }
    }
  }, [isNew, universeId, setDraftUniverseId, isLoadingUniverses, universes]);

  const handleChangeUniverseId = (id: Id) => {
    setDraftUniverseId(id);
    setDraftBasketId(null);
  };

  const resetDraft = () => {
    setDraftUniverseId(undefined);
    setDraftBasketId(undefined);
  };
  const draft = {
    universeId: draftUniverseId,
    basketId: draftBasketId,
  };

  const cardElement = (
    <BasketCard
      basketId={draftBasketId}
      universeId={draftUniverseId}
      disabled={disabled}
      onChangeUniverseId={isNew ? handleChangeUniverseId : undefined}
      onChangeBasketId={setDraftBasketId}
    />
  );

  return {
    cardElement,
    draft,
    resetDraft,
    isLoading: isLoadingUniverses,
  };
};

export default useBasketCard;

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  tooltipIcon: {
    marginTop: theme.spacing(1),
    zIndex: 10,
    color: theme.palette.neutral.main,
  },
}));

const InfoTooltip = ({ translateId, children, size }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {children}
      <div className={classes.tooltipIcon}>
        <Tooltip title={<Translate id={translateId} />}>
          <InfoOutlinedIcon fontSize={size} />
        </Tooltip>
      </div>
    </div>
  );
};

InfoTooltip.propTypes = {
  translateId: PropTypes.string.isRequired,
  children: PropTypes.node,
  size: PropTypes.string,
};

InfoTooltip.defaultProps = {
  size: 'small',
  children: null,
};

export default InfoTooltip;

import { PortfolioJobStatus } from 'utils/constants';

export const JobStatus = {
  IN_QUEUE: 'IN_QUEUE',
  TO_START: 'TO_START',
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
  CANCELLED: 'CANCELLED',
} as const;

export const NOT_DONE_JOB_STATUSES = [
  PortfolioJobStatus.IN_QUEUE,
  PortfolioJobStatus.TO_START,
  PortfolioJobStatus.RUNNING,
] as const;

export const DONE_JOB_STATUSES = [
  PortfolioJobStatus.ERROR,
  PortfolioJobStatus.FINISHED,
  PortfolioJobStatus.CANCELLED,
] as const;

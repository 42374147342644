import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import React, { useState } from 'react';

import MyDropdown from 'components/MyDropdown/MyDropdown';
import SimpleText from 'components/SimpleText';

const listItems = ['small', 'medium', 'large'];
const useSizeDropdown = propSize => {
  const [size, setSize] = useState(propSize || 'small');
  const DropDown = () => (
    <>
      <SimpleText translateId='dashboard.size' fontWeight='bold' my={4} />
      <MyDropdown
        value={size}
        onChange={value => setSize(value)}
        placeholderId='size'
        isNullAllowed={false}
      >
        {listItems.map(item => (
          <MenuItem value={item} key={item}>
            {_.startCase(item)}
          </MenuItem>
        ))}
      </MyDropdown>
    </>
  );

  return [DropDown, size, setSize];
};

export default useSizeDropdown;

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';

import { ChildrenType } from 'utils/types';

const SimpleIconButton = ({ children, translateId, onClick, ...other }) => (
  <Translate>
    {({ translate }) => (
      <Tooltip title={translate(`iconButtonTooltip.${translateId}`)}>
        <IconButton onClick={onClick} {...other} size='large'>
          {children}
        </IconButton>
      </Tooltip>
    )}
  </Translate>
);

SimpleIconButton.propTypes = {
  children: ChildrenType.isRequired,
  translateId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

SimpleIconButton.defaultProps = {
  onClick: null,
};

SimpleIconButton.defaultProps = {};

export default SimpleIconButton;

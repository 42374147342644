const termsAndConditions = {
  de: {
    title: 'Datenschutzrichtlinie',
    definitions:
      'Verantwortlicher im Sinne der DSGVO:<br>' +
      'The Double Alpha Factory GmbH<br>Humboldtstraße 1<br>53819 Neunkirchen-Seelscheid<br>' +
      'E-mail-Adresse: support@alpha-factory.de',
    sections: [
      {
        title: 'Von uns erfasste personenbezogene Daten',
        paragraphs: [
          'Customer Centricity ist ein Leitwert der Double Alpha Factory: Mit jedem Produkt oder Service möchten wir für unsere Kunden einen Mehrwert schaffen. Dafür ist es wichtig, dass wir uns direkt mit Ihnen auszutauschen und Ihren Umgang mit unseren Produkten und Services auswerten können. Dabei hat der Schutz Ihrer Daten für uns einen sehr hohen Stellenwert.',
          'Diese Datenschutzrichtlinie bezieht sich auf unseren Umgang mit personenbezogenen Daten, die wir verwerten, jedoch nicht auf die Praktiken von Unternehmen, die wir nicht kontrollieren.',
          'Wir erheben personenbezogene Daten über Sie, wenn Sie uns diese Informationen direkt zur Verfügung stellen oder wenn personenbezogene Daten automatisch in Verbindung mit der Nutzung unserer Testversion erhoben werden.',
          'Wir erhalten und speichern alle Informationen, die Sie uns zur Verfügung stellen. Dabei handelt es sich maßgeblich um Nutzerstammdaten, die Sie uns zum Beispiel während des Onboarding Prozesses, einer Umfrage oder eines Videointerviews preisgeben, wie:<br><br>' +
            '•Vor- und Nachname, Benutzername<br>' +
            '•Kennwort<br>' +
            '•E-Mail-Adresse<br>' +
            '•Telefonnummer<br>' +
            '•Beruf und Titel<br>' +
            '•Arbeitgeber<br>' +
            '•Informationen zur Ausbildung<br>',
          'Wenn Sie sich auf unsere Testversion einloggen, erhalten wir automatisch Informationen von Ihrem Browser. Dazu gehören Metadaten und Nutzungsdaten, zum Beispiel:<br><br>' +
            '•Neuste Browserversion und -name<br>' +
            '•Neuster Servername<br>' +
            '•Neustes Betriebssystem<br>' +
            '•Informationen über die Nutzung (z.B. Anzahl der aktiven Tage; Seitenaufrufe)<br>' +
            '•IP-Adresse und Geolokationsdaten<br>',
        ],
      },
      {
        title: 'So verwenden wir personenbezogene Daten',
        paragraphs: [
          'Wir erheben verschiedene Arten personenbezogener Daten von unseren Nutzern. Diese personenbezogenen Daten verwenden wir in Verbindung mit unseren Produkten und Services, unter anderem um: <br><br>' +
            '•unsere Produkte und Services zu entwickeln und zu verbessern;<br>' +
            '•Sie zu kontaktieren, z.B. für Supportfälle;<br>' +
            '•Sie über Produktentwicklungen oder neue Produkte und Services auf dem Laufenden zu halten;<br>' +
            '•Ihre Anfragen nach bestimmten Produkten und Services zu erfüllen;<br>' +
            '•zu analysieren, wie Sie unsere Produkte und Services nutzen. <br>',
        ],
      },
      {
        title: 'An wen wir Ihre personenbezogenen Daten weitergeben',
        paragraphs: [
          'Grundsätzlich achten wir darauf, dass wir nur ein Minimum Ihrer personenbezogenen Daten an Dritte weitergeben. Damit wir unsere Produkte und Services anbieten und verbessern können, nutzen wir die folgenden Anbieter:',
          '<b>Microsoft Corporation</b><br><br>' +
            'Für unsere internen Prozesse und für Auswertungen nutzen wir Produkte der Microsoft Corporation, beispielsweise Microsoft Office365. Hier werden die Auswertungen Ihres Nutzungsverhaltens sowie Ihre Nutzerdaten gespeichert und wie oben beschrieben genutzt.<br>' +
            'Die Datenschutzrichtlinie der Microsoft Corporation finden Sie <a href="https://privacy.microsoft.com/de-de/privacystatement">hier</a>.',
          '<b>Pendo.io</b><br><br>' +
            'Zur Analyse Ihres Nutzerverhaltens übergeben wir Ihre Metadaten und Nutzungsdaten wie oben beschrieben an Pendo.io. Ihre Benutzerstammdaten wie Name oder E-Mail-Adresse werden nicht an Pendo.io weitergegeben.<br>' +
            'Die Datenschutzrichtlinie von Pendo.io können Sie <a href="https://www.pendo.io/legal/privacy-policy/">hier</a> einsehen.',
        ],
      },
      {
        title: 'Welche Möglichkeiten Sie haben',
        paragraphs: [
          'Sie haben immer die Möglichkeit, keine Informationen an uns weiterzugeben. Bitte bedenken Sie, dass einige Informationen erforderlich sein können, um sich bei uns zu registrieren oder einige unserer Funktionen zu nutzen.',
          'Sie haben zusätzlich jederzeit die Möglichkeit, Informationen hinzuzufügen, zu aktualisieren oder zu löschen, indem Sie eine Mail an support@alpha-factory.de schreiben.',
          'Wenn Sie Fragen oder Bedenken bezüglich unserer Datenschutzrichtlinien haben, senden Sie uns einfach eine Nachricht an support@alpha-factory.de und wir klären Ihr Anliegen schnellstmöglich.',
        ],
      },
    ],
  },
} as const;

export default termsAndConditions;

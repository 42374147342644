import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ResourceName } from 'lib/constants';
import { replaceObjectInArray } from 'lib/misc/misc';
import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import { get, post, put, selectListToDict, useDeleteMutationCommon } from 'lib/queries';
import { Id } from 'lib/types';
import { addError, addSmallNotification } from 'redux/notifications/actions';
import { PostReport, PutReport, Report, Reports } from 'screens/Report/lib/types';
import { SmallNotification } from 'utils/constants';

interface ListParams {
  portfolioId: Id;
}
interface DetailParams {
  id: Id;
}

interface UpdateMutateParams {
  id: Id;
  data: PutReport;
}

export const reportKeys = {
  all: [{ scope: ResourceName.REPORT }] as const,
  lists: () => [{ ...reportKeys.all[0], entity: 'list' }] as const,
  list: ({ portfolioId }: ListParams) => [{ ...reportKeys.lists()[0], portfolioId }] as const,
  details: () => [{ ...reportKeys.all[0], entity: 'detail' }] as const,
  detail: ({ id }: DetailParams) => [{ ...reportKeys.details()[0], id }] as const,
};

async function getReports({
  queryKey: [{ portfolioId }],
}: QueryFunctionContext<ReturnType<typeof reportKeys.list>>): Promise<ReadonlyArray<Report>> {
  return get(`portfolios/${portfolioId}/reports/`);
}

async function getReport({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<typeof reportKeys.detail>>): Promise<Report> {
  return get(`reports/${id}/`);
}

async function postReport(data: PostReport, portfolioId: Id): Promise<Report> {
  return post(`portfolios/${portfolioId}/reports/`, data);
}

async function putReport(data: PutReport, id: Id): Promise<Report> {
  return put(`reports/${id}/`, data);
}

export const useReportsQuery = (portfolioId: Id | undefined) => {
  const query = useQuery(reportKeys.list({ portfolioId } as { portfolioId: Id }), getReports, {
    select: selectListToDict,
    enabled: !!portfolioId,
  });
  const data = query.data as Reports | undefined;
  const reports = data || {};
  return {
    ...query,
    data,
    reports,
  };
};

export const useReportQuery = (id: Id | undefined) => {
  const query = useQuery(reportKeys.detail({ id } as { id: Id }), getReport, {
    enabled: !!id,
  });
  return {
    ...query,
    report: query.data,
  };
};

export const useCreateReportMutation = (portfolioId: Id) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation((draft: PostReport) => postReport(draft, portfolioId), {
    onSuccess: data => {
      queryClient.setQueriesData<Report[]>(
        reportKeys.list({ portfolioId: data.portfolioId }),
        prev => (prev ? [...prev, data] : [data])
      );
      queryClient.setQueriesData(reportKeys.detail({ id: data.id }), data);
      dispatch(addSmallNotification(SmallNotification.REPORT_CREATED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const useUpdateReportMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(({ id, data }: UpdateMutateParams) => putReport(data, id), {
    onSuccess: data => {
      queryClient.setQueriesData(reportKeys.list({ portfolioId: data.portfolioId }), prev =>
        replaceObjectInArray(prev, data)
      );
      queryClient.setQueriesData(reportKeys.detail({ id: data.id }), data);
      dispatch(addSmallNotification(SmallNotification.REPORT_UPDATED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const useDeleteReportMutation = () => {
  return useDeleteMutationCommon('reports/', {
    notification: SmallNotification.REPORT_DELETED,
    queryKeyLists: reportKeys.lists(),
    queryKeyDetails: reportKeys.details(),
  });
};

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ResourceName } from 'lib/constants';
import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import { del, get, post, put, selectListToDict } from 'lib/queries';
import { addError, addSmallNotification } from 'redux/notifications/actions';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';
import { SmallNotification } from 'utils/constants';

const rolesKeys = {
  all: [{ scope: 'role' }],
  lists: () => [{ ...rolesKeys.all[0], entity: 'list' }],
  list: ({ filter = 'all', instituteId }) => [{ ...rolesKeys.lists()[0], filter, instituteId }],
};

async function getRoles({ queryKey: [{ instituteId }] }) {
  return get('roles/', { 'institute-id': instituteId });
}

export const useRolesQuery = () => {
  const { instituteId } = useCurrentInstitute();
  return useQuery(rolesKeys.list({ instituteId }), getRoles);
};

export const userGroupsKeys = {
  all: [{ scope: ResourceName.USER_GROUP }],
  lists: () => [{ ...userGroupsKeys.all[0], entity: 'list' }],
  list: ({ filter = 'all' } = {}) => [{ ...userGroupsKeys.lists()[0], filter }],
};

async function getUserGroups() {
  return get('user_groups/');
}

async function delUserGroup(id) {
  return del(`user_groups/${id}/`);
}

async function putUserGroup(id, group) {
  return put(`user_groups/${id}/`, group);
}

async function postUserGroup(group, instituteId) {
  return post(`user_groups/`, group, { 'institute-id': instituteId });
}

export const useUserGroupsQuery = () => {
  const query = useQuery(userGroupsKeys.list(), getUserGroups, {
    select: selectListToDict,
  });
  return {
    ...query,
    userGroups: query.data || {},
  };
};

export const useCreateUserGroupMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { instituteId } = useCurrentInstitute();
  const mutation = useMutation(data => postUserGroup(data, instituteId), {
    onSuccess: data => {
      queryClient.setQueriesData(userGroupsKeys.lists(), previousList => [...previousList, data]);
      queryClient.invalidateQueries(userGroupsKeys.all);
      dispatch(addSmallNotification(SmallNotification.USER_GROUP_CREATED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
  return { ...mutation, createUserGroup: mutation.mutate };
};

export const useUpdateUserGroupMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mutation = useMutation(({ id, data }) => putUserGroup(id, data), {
    onSuccess: data => {
      queryClient.setQueriesData(userGroupsKeys.lists(), previousList => {
        const index = previousList.findIndex(group => group.id === data.id);
        return [...previousList.slice(0, index), data, ...previousList.slice(index + 1)];
      });
      dispatch(addSmallNotification(SmallNotification.USER_GROUP_SAVED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
  return { ...mutation, updateUserGroup: mutation.mutate };
};

export const useDeleteUserGroupMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mutation = useMutation(id => delUserGroup(id), {
    onSuccess: (data, id) => {
      queryClient.setQueriesData(userGroupsKeys.lists(), previousList =>
        previousList.filter(({ id: previousId }) => previousId !== id)
      );
      dispatch(addSmallNotification(SmallNotification.USER_GROUP_DELETED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
  return { ...mutation, deleteUserGroup: mutation.mutate };
};

export const useEveryUserGroupMutation = () => {
  const createUserGroupMutation = useCreateUserGroupMutation();
  const updateUserGroupMutation = useUpdateUserGroupMutation();
  const deleteUserGroupMutation = useDeleteUserGroupMutation();
  const isLoading =
    createUserGroupMutation.isLoading ||
    updateUserGroupMutation.isLoading ||
    deleteUserGroupMutation.isLoading;
  return {
    createUserGroup: createUserGroupMutation.mutate,
    updateUserGroup: updateUserGroupMutation.mutate,
    deleteUserGroup: deleteUserGroupMutation.mutate,
    isLoading,
  };
};

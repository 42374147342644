import React from 'react';
import { useHistory } from 'react-router-dom';

import { createLocation } from 'components/MyRouting/utils';
import ChooseScreen from 'components/SearchTools/ChooseScreen';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import { useInstitutesQueryAdmin } from 'screens/Institute/lib/queries';
import { Routes } from 'utils/constants';

const columns = ['id', 'name'];

const ChooseInstituteScreen = () => {
  const history = useHistory();
  const hasCreatePermission = useCreatePermission(PermissionResourceName.INSTITUTE);

  const { institutes, isLoading } = useInstitutesQueryAdmin();

  return (
    // @ts-ignore
    <ChooseScreen
      dialogTranslatePath='adminInstituteScreen.useSavePromptAndDialog'
      waitWhile={isLoading}
      tableObjects={institutes}
      // @ts-ignore
      columns={columns}
      searchColumns={columns}
      screenTitle='adminInstitutes'
      searchValue={null}
      translateTableId='instituteTableAdmin'
      isTableWhite
      onNewClick={hasCreatePermission ? () => history.push(Routes.ADMIN_NEW_INSTITUTE) : null}
      onRowClick={(id: string) => history.push(createLocation(Routes.ADMIN_INSTITUTE, id))}
    />
  );
};

export default ChooseInstituteScreen;

import React from 'react';
import { useParams } from 'react-router-dom';

import ContributionTable from 'screens/Analysis/AnalysisScreen/widgets/ContributionTable/ContributionTable';
import PerformanceBreakdownWrapper from 'screens/Analysis/AnalysisScreen/widgets/PerformanceBreakdown/PerformanceBreakdownWrapper';
import RatiosBar from 'screens/Analysis/AnalysisScreen/widgets/RatiosBar/RatiosBar';
import ReturnBreakdownBar from 'screens/Analysis/AnalysisScreen/widgets/ReturnBreakdownBar/ReturnBreakdownBar';
import UpCaptureChart from 'screens/Analysis/AnalysisScreen/widgets/UpCapture/UpCaptureChart';

const PerformanceTab = () => {
  const { id } = useParams();
  return (
    <>
      <ReturnBreakdownBar analysisId={id} />
      <PerformanceBreakdownWrapper analysisId={id} />
      <ContributionTable analysisId={id} />
      <RatiosBar analysisId={id} />
      <UpCaptureChart analysisId={id} />
    </>
  );
};

export default PerformanceTab;

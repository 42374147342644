import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { createLocation } from 'components/MyRouting/utils';
import ScreenBase from 'components/ScreenBase';
import { createSearchMenuData } from 'components/SearchTools/utils';
import { useDraftSavePromptAndDialog } from 'lib/misc/useSavePromptAndDialog';
import { AnyBackendObject } from 'utils/types';

const ObjectScreenBase = ({
  children,
  object,
  objects,
  draftObj,
  originalObj,
  onSaveClick,
  onSaveNameClick,
  onDeleteClick,
  translationPath,
  propsToTriggerDialog,
  routeNew,
  routeChoose,
  routeObject,
  urlNext,
  hasAccess,
  waitWhile,
  draftName,
  setDraftName,
  disabled,
  isAccessDialogOn,
}) => {
  const history = useHistory();
  const isNew = !object;
  const original = !isNew ? originalObj || _.pick(object, _.keys(draftObj)) : null;

  const { onSaveClickWrapper, onDeleteClickWrapper, promptAndDialog, isDraftEqual } =
    useDraftSavePromptAndDialog(original, draftObj, onSaveClick, onDeleteClick, {
      translationPath,
      propsToTriggerDialog: propsToTriggerDialog ?? _.keys(draftObj),
    });

  const searchMenuProps = useMemo(() => {
    if (!objects || !routeObject || !routeChoose || !routeNew) {
      return null;
    }
    const redirectToObjectFn =
      typeof routeObject === 'function'
        ? routeObject
        : b => history.push(createLocation(routeObject, b));
    return {
      ...createSearchMenuData(objects, redirectToObjectFn),
      onMoreClick: () => history.push(routeChoose),
      onNewClick: () => history.push(routeNew),
      excludeKey: object ? object.id : null,
    };
  }, [objects, object, history, routeObject, routeChoose, routeNew]);

  const nameTitleProps = {
    name: draftName,
    onRename: setDraftName,
    startWithEditing: isNew && setDraftName !== undefined,
    canRename: hasAccess && setDraftName !== undefined,
    onConfirm: !isNew && draftName !== original?.name ? onSaveNameClick : undefined,
  };

  const navButtonsProps = [];
  if (isNew) {
    navButtonsProps.push({
      translateId: 'navButton.create',
      onClick: onSaveClickWrapper,
      variant: 'highEmp',
      disabled: !hasAccess || isDraftEqual || !draftName || disabled,
      'pendo-id': 'lib-object-screen-base-create-button',
    });
  } else {
    if (onDeleteClick) {
      navButtonsProps.push({
        translateId: 'navButton.delete',
        onClick: onDeleteClickWrapper,
        variant: 'midEmp',
        disabled: !hasAccess || disabled,
      });
    }
    if (onSaveClick) {
      navButtonsProps.push({
        translateId: 'navButton.save',
        onClick: onSaveClickWrapper,
        variant: 'highEmp',
        disabled: !hasAccess || isDraftEqual || !draftName || disabled,
      });
    }
    if (urlNext) {
      navButtonsProps.push({
        translateId: `${translationPath}.navButton.next`,
        onClick: () => history.push(urlNext),
        variant: 'lowEmp',
      });
    }
  }

  return (
    <ScreenBase
      titleTranslationId={`${translationPath}.screenTitle`}
      nameTitleProps={nameTitleProps}
      navButtonsProps={navButtonsProps}
      waitWhile={waitWhile}
      searchMenuProps={searchMenuProps}
      returnButtonProps={
        routeChoose
          ? {
              translateId: `${translationPath}.returnButton`,
              route: routeChoose,
            }
          : null
      }
      object={isAccessDialogOn ? object : undefined}
    >
      {promptAndDialog}
      {children}
    </ScreenBase>
  );
};

ObjectScreenBase.propTypes = {
  children: PropTypes.node,
  object: AnyBackendObject,
  // `objects` are required only to create SearchMenu
  objects: PropTypes.objectOf(AnyBackendObject),
  draftObj: AnyBackendObject.isRequired,
  // if `originalObj` is not provided, `object` with keys from `draftObj` will be used instead
  originalObj: AnyBackendObject,
  onSaveClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func,
  translationPath: PropTypes.string.isRequired,
  // if propsToTriggerDialog is not provided, all props from `draft` will be used to trigger dialog
  propsToTriggerDialog: PropTypes.arrayOf(PropTypes.string),
  // only for SearchMenu
  routeNew: PropTypes.string,
  // for return button and SearchMenu
  routeChoose: PropTypes.string,
  // only for SearchMenu
  routeObject: PropTypes.oneOfType(PropTypes.string, PropTypes.func),
  hasAccess: PropTypes.bool,
  waitWhile: PropTypes.bool,
  draftName: PropTypes.string,
  setDraftName: PropTypes.func,
  // for next button
  urlNext: PropTypes.string,
  disabled: PropTypes.bool,
  onSaveNameClick: PropTypes.func,
  isAccessDialogOn: PropTypes.bool,
};

ObjectScreenBase.defaultProps = {
  children: null,
  object: null,
  objects: null,
  originalObj: null,
  propsToTriggerDialog: null,
  routeNew: null,
  routeChoose: null,
  routeObject: null,
  hasAccess: false,
  waitWhile: false,
  urlNext: undefined,
  disabled: false,
  onSaveNameClick: undefined,
  draftName: undefined,
  onDeleteClick: undefined,
  isAccessDialogOn: true,
  setDraftName: undefined,
};

export default ObjectScreenBase;

import { Alert } from '@mui/material';
import React from 'react';

import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';

interface PortfolioWarningProps {
  onClick: () => void;
  translationPath: string;
  translateDataMessage?: object;
  translateDataDetails?: object;
}

const PortfolioWarning = ({
  onClick,
  translationPath,
  translateDataDetails,
  translateDataMessage,
}: PortfolioWarningProps) => {
  const text = (
    <React.Fragment>
      <SimpleText
        translateId={`${translationPath}.warningMessage`}
        translateData={translateDataMessage}
      />
      <br />
      <SimpleText
        translateId={`${translationPath}.warningDetails`}
        translateData={translateDataDetails}
      />
    </React.Fragment>
  );
  const button = (
    <SimpleButton
      variant='midEmp'
      onClick={onClick}
      translateId={`${translationPath}.setButton`}
      disabled={false}
    />
  );
  return (
    <Alert severity='warning' action={button}>
      {text}
    </Alert>
  );
};

export default PortfolioWarning;

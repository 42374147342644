import React from 'react';
// @ts-ignore
import { Event, Socket } from 'react-socket-io';

import { REACT_APP_ROBO_API_URL, useObjectsUpdatedWebsockets } from 'lib/queries';
import { useReceiveAnalysisWebsockets } from 'screens/Analysis/lib/queries';
import { useReceiveBacktestWebsockets } from 'screens/Backtest/lib/queries';
import { useAuth, useAuthToken } from 'screens/Login/lib/queries';
import { useReceivePortfolioWebsockets } from 'screens/Portfolio/lib/queries';
import { useReceiveUserWebsocketsAdmin } from 'screens/User/lib/queries';
import { SocketioEvent } from 'utils/constants';

const ROBO_SOCKET_URL = REACT_APP_ROBO_API_URL;
const ROBO_SOCKET_PATH = '/api/socket.io';

function generateSocketIoOptions(token: string) {
  return {
    path: ROBO_SOCKET_PATH,
    query: { token },
    // if possible, use 'websocket', otherwise rollback to 'polling'
    transports: ['polling', 'websocket'],
    upgrade: true,
  };
}

const SocketioEventListener = () => {
  const { isAuthenticated } = useAuth();
  const { token } = useAuthToken();
  const handleUserInvitationSent = useReceiveUserWebsocketsAdmin(
    SocketioEvent.USER_INVITATION_SENT
  );
  const handleOptimizationStarted = useReceivePortfolioWebsockets(
    SocketioEvent.OPTIMIZATION_STARTED
  );
  const handleOptimizationFinished = useReceivePortfolioWebsockets(
    SocketioEvent.OPTIMIZATION_FINISHED
  );
  const handleBacktestStarted = useReceiveBacktestWebsockets(SocketioEvent.BACKTEST_STARTED);
  const handleBacktestFinished = useReceiveBacktestWebsockets(SocketioEvent.BACKTEST_FINISHED);

  const handleAnalysisStarted = useReceiveAnalysisWebsockets(
    SocketioEvent.PORTFOLIO_ANALYSIS_STARTED
  );
  const handleAnalysisFinished = useReceiveAnalysisWebsockets(
    SocketioEvent.PORTFOLIO_ANALYSIS_FINISHED
  );
  const handleAnalysisPartial = useReceiveAnalysisWebsockets(
    SocketioEvent.PORTFOLIO_ANALYSIS_PARTIAL
  );

  const handleObjectsUpdated = useObjectsUpdatedWebsockets();
  return isAuthenticated && token ? (
    <Socket uri={ROBO_SOCKET_URL} options={generateSocketIoOptions(token)}>
      <div>
        <Event event={SocketioEvent.USER_INVITATION_SENT} handler={handleUserInvitationSent} />
        <Event event={SocketioEvent.OPTIMIZATION_STARTED} handler={handleOptimizationStarted} />
        <Event event={SocketioEvent.OPTIMIZATION_FINISHED} handler={handleOptimizationFinished} />
        <Event event={SocketioEvent.OBJECTS_UPDATED} handler={handleObjectsUpdated} />

        <Event event={SocketioEvent.PORTFOLIO_ANALYSIS_STARTED} handler={handleAnalysisStarted} />
        <Event event={SocketioEvent.PORTFOLIO_ANALYSIS_PARTIAL} handler={handleAnalysisPartial} />
        <Event event={SocketioEvent.PORTFOLIO_ANALYSIS_FINISHED} handler={handleAnalysisFinished} />
        <Event event={SocketioEvent.BACKTEST_STARTED} handler={handleBacktestStarted} />
        <Event event={SocketioEvent.BACKTEST_FINISHED} handler={handleBacktestFinished} />
        <Event event={SocketioEvent.BACKTEST_CANCELLED} handler={handleBacktestStarted} />
      </div>
    </Socket>
  ) : null;
};

export default SocketioEventListener;

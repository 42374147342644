import { Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { addMonths } from 'date-fns';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { createLocation } from 'components/MyRouting/utils';
import ObjectScreenBase from 'components/ObjectScreenBase';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import useEditPermission from 'lib/access/useEditPermission';
import { toDateString } from 'lib/dates/dateUtils';
import {
  useDraftList,
  useDraftObject,
  useDraftString,
  useDraftValue,
} from 'lib/misc/useDraft/useDraft';
import { Id } from 'lib/types';
import FeaturesCard from 'screens/Institute/lib/components/FeaturesCard';
import TrialConfigCard from 'screens/Institute/lib/components/TrialConfigCard';
import {
  useCreateInstituteMutationAdmin,
  useInstituteQueryAdmin,
  useUpdateInstituteMutationAdmin,
} from 'screens/Institute/lib/queries';
import { UsersTableAdmin } from 'screens/User/lib/components/UsersTable';
import { Routes } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

const DEFAULT_EXPIRES_ON = addMonths(new Date(), 3).toISOString();

const AdminInstituteScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id: objId } = useParams<{ id: Id | undefined }>();
  const isNew = !objId;
  const { institute, isLoading: isLoadingInstitute } = useInstituteQueryAdmin(objId);
  const hasEditAccess = useEditPermission(institute, { isGeneralRole: true });
  const hasCreateAccess = useCreatePermission(PermissionResourceName.INSTITUTE, {
    isGeneralRole: true,
  });
  const hasAccess = isNew ? hasCreateAccess : hasEditAccess;
  const { mutate: createInstitute, isLoading: isLoadingCreate } = useCreateInstituteMutationAdmin();
  const { mutate: updateInstitute, isLoading: isLoadingUpdate } = useUpdateInstituteMutationAdmin();
  const isLoadingMutation = isLoadingCreate || isLoadingUpdate;

  const { draft: draftName, setDraft: setDraftName } = useDraftString(institute?.name);
  const { draft: draftExpiresOn, setDraft: setDraftExpiresOn } = useDraftValue(
    institute?.expiresOn ?? toDateString(DEFAULT_EXPIRES_ON)
  );
  const { draft: draftIsTrial, setDraft: setDraftIsTrial } = useDraftValue(
    institute?.isTrial ?? true
  );

  const { draft: draftUserIds, dispatchDraft: dispatchDraftUserIds } = useDraftList(
    institute?.users ?? []
  );

  const { draft: draftFeatures, dispatchDraft: dispatchDraftFeatures } = useDraftObject(
    institute?.settings?.features
  );

  const draft = {
    users: draftUserIds,
    name: draftName,
    expiresOn: draftIsTrial ? toDateString(draftExpiresOn) : null,
    isTrial: draftIsTrial,
    settings: {
      ...(institute?.settings ?? {}),
      features: draftFeatures,
    },
  };

  const onSaveClick = () => {
    if (isNew) {
      createInstitute(draft, {
        onSuccess: data => {
          history.push(createLocation(Routes.ADMIN_INSTITUTE, data.id));
        },
      });
    } else {
      updateInstitute({ id: objId, data: draft });
    }
  };

  const onSaveNameClick = () => {
    if (!isNew) {
      updateInstitute({ id: objId, data: { name: draftName } });
    }
  };

  return (
    <ObjectScreenBase
      disabled={isLoadingMutation}
      onSaveNameClick={onSaveNameClick}
      waitWhile={isLoadingInstitute}
      object={institute}
      draftObj={draft}
      onSaveClick={onSaveClick}
      hasAccess={hasAccess}
      translationPath='adminInstituteScreen'
      routeNew={Routes.ADMIN_NEW_INSTITUTE}
      routeChoose={Routes.ADMIN_INSTITUTES}
      routeObject={Routes.ADMIN_INSTITUTE}
      draftName={draftName}
      setDraftName={setDraftName}
      isAccessDialogOn={false}
    >
      <div className={classes.content}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TrialConfigCard
              isForAdmin
              draftIsTrial={draftIsTrial}
              draftExpiresOn={draftExpiresOn}
              setDraftIsTrial={setDraftIsTrial}
              setDraftExpiresOn={setDraftExpiresOn}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FeaturesCard
              isForAdmin
              draftFeatures={draftFeatures}
              dispatchDraftFeatures={dispatchDraftFeatures}
            />
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <UsersTableAdmin
                  draftUserIds={draftUserIds}
                  dispatchDraftUserIds={dispatchDraftUserIds}
                  disabled={!hasAccess}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </ObjectScreenBase>
  );
};

export default AdminInstituteScreen;

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ResourceName } from 'lib/constants';
import { removeObjectFromArray } from 'lib/misc/misc';
import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import {
  del,
  get,
  selectListToDict,
  useCreateMutationCommon,
  useDeleteMutationCommon,
  useUpdateMutationCommon,
} from 'lib/queries';
import { addError, addSmallNotification } from 'redux/notifications/actions';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';
import { SmallNotification } from 'utils/constants';

const basketKeys = {
  all: [{ scope: ResourceName.BASKET }],
  lists: () => [{ ...basketKeys.all[0], entity: 'list' }],
  list: ({ filter = 'all', instituteId }) => [{ ...basketKeys.lists()[0], filter, instituteId }],
  details: () => [{ ...basketKeys.all[0], entity: 'detail' }],
  detail: ({ id }) => [{ ...basketKeys.details()[0], id }],
};

async function getBaskets({ queryKey: [{ instituteId }] }) {
  return get('baskets/', { 'institute-id': instituteId });
}

async function getBasket({ queryKey: [{ id }] }) {
  return get(`baskets/${id}/`);
}

export const useBasketsQuery = () => {
  const { instituteId } = useCurrentInstitute();
  const query = useQuery(basketKeys.list({ instituteId }), getBaskets, {
    select: selectListToDict,
  });
  return {
    ...query,
    baskets: query.data || {},
  };
};

export const useBasketQuery = id => {
  const queryClient = useQueryClient();
  const { instituteId } = useCurrentInstitute();
  const query = useQuery(basketKeys.detail({ id }), getBasket, {
    enabled: !!id,
    initialData: () => {
      const baskets = queryClient.getQueryData(basketKeys.list({ instituteId }));
      if (baskets) {
        const basket = baskets.find(b => b.id === id);
        if (basket) {
          return basket;
        }
      }
      return undefined;
    },
  });
  return {
    ...query,
    basket: query.data,
  };
};

export const useCreateBasketMutation = () => {
  return useCreateMutationCommon('baskets/', {
    notification: SmallNotification.BASKET_CREATED,
    queryKeyLists: basketKeys.lists(),
    queryKeyDetails: basketKeys.details(),
  });
};

export const useUpdateBasketMutation = () => {
  return useUpdateMutationCommon('baskets/', {
    notification: SmallNotification.BASKET_SAVED,
    queryKeyLists: basketKeys.lists(),
    queryKeyDetails: basketKeys.details(),
  });
};

export const useDeleteBasketMutation = () => {
  return useDeleteMutationCommon('baskets/', {
    notification: SmallNotification.BASKET_DELETED,
    queryKeyLists: basketKeys.lists(),
    queryKeyDetails: basketKeys.details(),
  });
};

export const useEveryBasketMutation = () => {
  const createMutation = useCreateBasketMutation();
  const updateMutation = useUpdateBasketMutation();
  const deleteMutation = useDeleteBasketMutation();
  const isLoading =
    createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading;
  return {
    createBasket: createMutation.mutate,
    updateBasket: updateMutation.mutate,
    deleteBasket: deleteMutation.mutate,
    isLoading,
  };
};

const universeKeys = {
  all: [{ scope: ResourceName.UNIVERSE }],
  lists: () => [{ ...universeKeys.all[0], entity: 'list' }],
  list: ({ filter = 'all', instituteId }) => [{ ...universeKeys.lists()[0], filter, instituteId }],
  details: () => [{ ...universeKeys.all[0], entity: 'detail' }],
  detail: ({ id }) => [{ ...universeKeys.details()[0], id }],
};

async function getUniverses({ queryKey: [{ instituteId }] }) {
  return get('universes/', { 'institute-id': instituteId });
}

async function getUniverse({ queryKey: [{ id }] }) {
  return get(`universes/${id}/`);
}

async function deleteUniverse(id) {
  return del(`universes/${id}/`);
}

export const useUniversesQuery = () => {
  const { instituteId } = useCurrentInstitute();
  const query = useQuery(universeKeys.list({ instituteId }), getUniverses, {
    select: selectListToDict,
  });
  return {
    ...query,
    universes: query.data || {},
  };
};

export const useUniverseQuery = id => {
  const queryClient = useQueryClient();
  const { instituteId } = useCurrentInstitute();
  const query = useQuery(universeKeys.detail({ id }), getUniverse, {
    enabled: !!id,
    initialData: () => {
      const objs = queryClient.getQueryData(universeKeys.list({ instituteId }));
      if (objs) {
        const obj = objs.find(o => o.id === id);
        if (obj) {
          return obj;
        }
      }
      return undefined;
    },
  });
  return {
    ...query,
    universe: query.data,
  };
};

export const useCreateUniverseMutation = () => {
  return useCreateMutationCommon('universes/', {
    notification: SmallNotification.UNIVERSE_CREATED,
    queryKeyLists: universeKeys.lists(),
    queryKeyDetails: universeKeys.details(),
  });
};

export const useUpdateUniverseMutation = () => {
  return useUpdateMutationCommon('universes/', {
    notification: SmallNotification.UNIVERSE_SAVED,
    queryKeyLists: universeKeys.lists(),
    queryKeyDetails: universeKeys.details(),
  });
};

export const useDeleteUniverseMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mutation = useMutation(id => deleteUniverse(id), {
    onSuccess: (data, id) => {
      queryClient.setQueriesData(universeKeys.lists(), prev => removeObjectFromArray(prev, id));
      queryClient.invalidateQueries(basketKeys.all);
      dispatch(addSmallNotification(SmallNotification.UNIVERSE_DELETED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
  return { ...mutation, delete: mutation.mutate };
};

export const useEveryUniverseMutation = () => {
  const createMutation = useCreateUniverseMutation();
  const updateMutation = useUpdateUniverseMutation();
  const deleteMutation = useDeleteUniverseMutation();
  const isLoading =
    createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading;
  return {
    createUniverse: createMutation.mutate,
    updateUniverse: updateMutation.mutate,
    deleteUniverse: deleteMutation.mutate,
    isLoading,
  };
};

import InfoIcon from '@mui/icons-material/Info';
import withStyles from '@mui/styles/withStyles';
import React from 'react';

import SimpleText from 'components/SimpleText';
import { TipsAndTricks } from 'utils/constants';
import { ClassesType } from 'utils/types';

const styles = theme => ({
  '@keyframes blinker': {
    from: { opacity: 0.4 },
    to: { opacity: 1 },
  },
  root: {
    color: theme.palette.text.primary,
    paddingTop: 20,
  },
  icon: {
    color: theme.palette.secondary.main,
    float: 'left',
    // animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  text: {
    float: 'left',
    marginTop: '0.3rem',
    marginLeft: '1rem',
  },
});

function pickRandomTipOrTrick() {
  const keys = Object.keys(TipsAndTricks);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  return TipsAndTricks[randomKey];
}

class MyTipsAndTricks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      randomPick: 'ComingSoon',
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ randomPick: pickRandomTipOrTrick() }), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { classes } = this.props;
    const { randomPick } = this.state;
    return (
      <div className={classes.root}>
        <InfoIcon className={classes.icon} />
        <SimpleText
          className={classes.text}
          translateId={`tipsAndTricks.${TipsAndTricks[randomPick]}`}
        />
      </div>
    );
  }
}

MyTipsAndTricks.propTypes = {
  classes: ClassesType.isRequired,
};

MyTipsAndTricks.defaultProps = {};

export default withStyles(styles, { withTheme: true })(MyTipsAndTricks);

import React from 'react';
import { useDispatch } from 'react-redux';

import WeightsChart from 'lib/weights/WeightsChart';
import usePortfolioAllocation from 'screens/Portfolio/lib/hooks/usePortfolioAllocation';
import { deleteWidget } from 'screens/Report/redux/actions';
import WidgetToolbar from 'screens/Report/ReportScreen/components/WidgetToolbar';
import useDashboardStyles from 'screens/Report/ReportScreen/configuration/dashboard.style';
import { widgetComponentPropType } from 'utils/types';

const PieChartWidget = ({
  widget,
  setSelectedWidget,
  setShowSettings,
  setSettingsType,
  setLayout,
}) => {
  const dashboardStyles = useDashboardStyles();

  const dispatch = useDispatch();
  const { portfolio, category, allocationType } = widget.configuration;

  const { weights, progressOrErrorElem } = usePortfolioAllocation(
    portfolio?.value,
    allocationType?.value
  );

  const content = <WeightsChart weights={weights} toggledChips={[category?.value]} />;

  return (
    <div className={dashboardStyles.mainCardWrapper}>
      <WidgetToolbar
        onEdit={() => {
          setSettingsType('PieChartWidget');
          setSelectedWidget(widget);
          setShowSettings(true);
        }}
        onDelete={() => {
          setLayout(prev => prev.filter(item => item.i !== widget.id));
          dispatch(deleteWidget(widget.id));
        }}
      />
      {progressOrErrorElem ?? content}
    </div>
  );
};

PieChartWidget.propTypes = widgetComponentPropType;
export default PieChartWidget;

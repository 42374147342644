const termsAndConditions = {
  de: {
    title: 'Nutzungsvereinbarung',
    definitions:
      'Zwischen<br><br>' +
      'The Double Alpha Factory GmbH, Humboldtstraße 1, 53819 Neunkirchen-Seelscheid, vertreten durch Michael Sulzbach (nachfolgend: „Provider“)<br><br>und<br><br>' +
      'dem Nutzer der SaaS-Dienstleistung „digitaler Anlageassistent“ (nachfolgend: „Kunde“) wird folgende Vereinbarung abgeschlossen:',
    sections: [
      {
        title: 'Vertragsgegenstand',
        paragraphs: [
          'Der Provider erbringt für den Kunden SaaS-Dienstleistungen über das Medium Internet im Bereich Investmentsoftware. Der Provider erbringt gegenüber dem Kunden und Dritten keine Dienstleistung, die seitens des Providers eine Erlaubnis gemäß § 32 KWG erfordert, insbesondere wird keine Anlagenberatung erbracht.',
          'Vertragsgegenstand ist die Überlassung eines Testzugangs zur Software „digitaler Anlageassistent“ (nachfolgend als „Software“ bezeichnet) des Providers zur Nutzung über das Internet.',
        ],
      },
      {
        title: 'Softwareüberlassung',
        paragraphs: [
          'Der Provider stellt dem Kunden für die Dauer dieses Vertrages (nachfolgend „Testzeitraum“) die Software unentgeltlich in der jeweils aktuellen Version über das Internet zur Verfügung. Zu diesem Zweck richtet der Provider die Software auf einem Server ein, der über das Internet für den Kunden erreichbar ist.',
          'Der Testzeitraum beginnt mit der Aushändigung der Logindaten und endet zwölf Wochen danach, außer der Provider und der Kunde einigen sich auf eine Verlängerung des Testzeitraums.',
          'Der jeweilige Funktionsumfang der Software ergibt sich aus dem aktuellen Entwicklungsstand der Software. Darüber hinaus erhält der Kunde für jeden Nutzer einen personalisierten Benutzernamen und das dazugehörige Passwort (nachfolgend „Logindaten“ genannt).',
          'Der Provider schuldet nicht die Datenverbindung zwischen Ausgang aus dem Rechenzentrum des Providers zum Übergabepunkt im IT-System des Kunden.',
        ],
      },
      {
        title: 'Nutzungsrechte an der Software',
        paragraphs: [
          'Der Provider räumt dem Kunden das nicht ausschließliche und nicht übertragbare Recht ein, die in diesem Vertrag bezeichnete Software während des Testzeitraums bestimmungsgemäß zu nutzen.',
          'Der Kunde darf die Software nur bearbeiten, soweit dies durch die bestimmungsgemäße Benutzung der Software laut aktuellem Entwicklungsstand abgedeckt ist.',
          'Der Kunde darf die Software nur notwendigerweise vervielfältigen. Zur notwendigen Vervielfältigung zählt das Laden der Software in den Arbeitsspeicher auf dem Server des Providers, nicht jedoch die auch nur vorübergehende Installation oder das Speichern der Software auf Datenträgern (wie etwas Festplatten etc.) der vom Kunden eingesetzten Hardware.',
          'Der Kunde ist nicht berechtigt, die Software Dritten entgeltlich oder unentgeltlich zur Nutzung zu Verfügung zu stellen. Eine Weitervermietung der Software wird dem Kunden somit ausdrücklich nicht gestattet.',
          'Der Provider ist berechtigt, angemessene technische Maßnahmen zum Schutz vor einer nicht vertragsgemäßen Nutzung zu treffen. Der vertragsgemäße Einsatz der Leistungen darf dadurch nicht mehr als nur unwesentlich beeinträchtigt werden.',
        ],
      },
      {
        title: 'Aktualisierung/Wartung',
        paragraphs: [
          'Der Provider entwickelt die Software laufend weiter und wird diese durch laufende Updates und Fixes verbessern.',
          'Die in § 4 Abs. (1) genannten Updates und Fixes werden regelmäßig zwischen 17:00 und 09:00 Uhr durchgeführt. Der Kunde wird kurzfristig darüber in Kenntnis gesetzt. Währenddessen ist die Software nicht verfügbar.',
          'Bei schweren Fehlern, die die Nutzung der Software unmöglich machen, oder diese erheblich einschränken, erfolgt die Wartung im Ausnahmefall auch außerhalb der in § 4 Abs. (1) genannten Uhrzeit.',
        ],
      },
      {
        title: 'Erreichbarkeit',
        paragraphs: [
          'Anpassungen, Änderungen und Ergänzungen der vertragsgegenständlichen Software sowie Maßnahmen, die der Feststellung und Behebung von Funktionsstörungen dienen, werden nur dann zu einer vorübergehenden Unterbrechung oder Beeinträchtigung der Erreichbarkeit führen, wenn dies aus technischen Gründen zwingend notwendig ist.',
          'Bei schweren Fehlern, die die Nutzung der Software unmöglich machen, oder diese erheblich einschränken, versucht der Provider so schnell wie möglich den Fehler zu beheben und die Funktionsfähigkeit wiederherzustellen. Der Provider wird den Kunden von den Wartungsarbeiten umgehend verständigen und den technischen Bedingungen entsprechend in der möglichst kürzesten Zeit durchführen.',
          'Ein Verfügbarkeitslevel für die Software wird während des Testzeitraums nicht vom Provider garantiert.',
        ],
      },
      {
        title: 'Datenschutz',
        paragraphs: [
          'Die Parteien verpflichten sich, die für sie einschlägigen Datenschutzvorschriften einzuhalten, insbesondere die Vorschriften der DSGVO und des BDSG (neu).',
        ],
      },
      {
        title: 'Support',
        paragraphs: [
          'Der Kunde kann Supportanfragen an den Provider unter der E-Mail-Adresse support@alpha-factory.de  richten. Diese werden nach besten Möglichkeiten der Mitarbeiter beantwortet, jedoch verpflichtet sich der Provider während des Testzeitraums zu keinem Support.',
        ],
      },
      {
        title: 'Pflichten des Kunden',
        paragraphs: [
          'Der Kunde ist verpflichtet, den Zugriff unbefugter Dritter auf die Software durch geeignete Vorkehrungen zu verhindern. Zu diesem Zwecke wird der Kunde, soweit erforderlich, seine Mitarbeiter auf die Einhaltung des Urheberrechts hinweisen.',
          'Der Kunde ist verpflichtet, die Logindaten geheim zu halten und Dritten gegenüber nicht zugänglich zu machen.',
        ],
      },
      {
        title: 'Haftung',
        paragraphs: [
          'Während des Testzeitraums garantiert der Provider nicht die Funktions- und die Betriebsbereitschaft der Software nach den Bestimmungen dieses Vertrages.',
          'Für den Fall, dass Leistungen des Providers von unberechtigten Dritten unter Verwendung der Zugangsdaten des Kunden in Anspruch genommen werden, haftet der Kunde für dadurch anfallende Entgelte im Rahmen der zivilrechtlichen Haftung bis zum Eingang des Kundenauftrages zur Änderung der Zugangsdaten oder der Meldung des Verlusts oder Diebstahls, sofern den Kunden am Zugriff des unberechtigten Dritten ein Verschulden trifft.',
          'Schadenersatzansprüche gegen den Provider sind unabhängig vom Rechtsgrund ausgeschlossen, es sei denn, der Provider, seine gesetzlichen Vertreter der Erfüllungsgehilfen haben vorsätzlich oder grob fahrlässig gehandelt. Für leichte Fahrlässigkeit haftet der Provider nur, wenn eine der vertragswesentlichen Pflichten durch den Provider, seine gesetzlichen Vertreter oder leitende Angestellte oder Erfüllungsgehilfen verletzt wurde. Der Provider haftet dabei nur für vorhersehbare Schäden, mit deren Entstehung typischerweise gerechnet werden muss. Vertragswesentliche Pflichten sind solche, die die Grundlage des Vertrages bilden, die entscheidend für den Abschluss des Vertrages waren und auf deren Erfüllung der Kunde vertrauen darf.',
          'Der Provider haftet unbeschränkt für vorsätzlich oder fahrlässig verursachte Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit durch den Provider, seine gesetzlichen Vertreter oder Erfüllungsgehilfen.',
          'Soweit der Kunde gegenüber seinen Kunden, unter Rückgriff auf die Software, Dienstleistungen erbringt, die einer Erlaubnis gemäß § 32 KWG bedürfen, insbesondere Anlageberatungen, erfolgt dies stets in eigener Verantwortung.',
        ],
      },
      {
        title: 'Vergütung, Laufzeit und Kündigung',
        paragraphs: [
          'Während des Testzeitraums überlässt der Provider dem Kunden die Software unentgeltlich.',
          'Nach Beendigung des Testzeitraums wird der Zugang des Kunden zur Software sowie alle produzierten Outputs automatisch, ohne Ankündigung und ohne Mitwirken des Kunden gelöscht.',
          'Entscheidet sich der Kunde für eine bezahltes Abonnement der Software, entfällt das Verfahren nach § 10 Abs. (2). Hierfür muss der Kunde den Provider unter der E-Mail-Adresse support@alpha-factory.de mindestens zwei Tage vor Ende des Testzeitraum kontaktieren. Die Umstellung auf das bezahlte Abonnement wird in einem separaten Vertrag geregelt.',
          'Der Provider behält sich das Recht vor, diesen Vertrag und den Zugang zur Software mit oder ohne Grund jederzeit mit oder ohne Vorankündigung auszusetzen oder zu beenden. Der Kunde kann seinen Zugang zur Software mit oder ohne Grund jederzeit durch eine schriftliche Mitteilung an den Provider unter der E-Mail-Adresse support@alpha-factory.de kündigen.',
        ],
      },
      {
        title: 'Schlussbestimmungen',
        paragraphs: [
          'Auf vorliegenden Vertrag findet deutsches Recht unter Ausschluss des UN-Kaufrechts Anwendung.',
          'Für Streitigkeiten aus diesem Vertrag ist ausschließlicher Gerichtsstand 53721, Siegburg.',
          'Mündliche Nebenabreden sind nicht getroffen. Änderungen, Ergänzungen und Zusätze dieses Vertrages bedürfen der Schriftform. Dies gilt auch für die Abänderung dieser Vertragsbestimmung.',
          'Sollte eine Bestimmung dieses Vertrages unwirksam sein oder werden, so berührt dies nicht die Wirksamkeit des Vertrages im Übrigen. Die Parteien verpflichten sich, für die unwirksame Regelung einen Ersatz zu finden, der dem wirtschaftlichen Zweck der Regelung am nächsten kommt. Entsprechendes gilt im Falle einer Vertragslücke.',
        ],
      },
    ],
  },
} as const;

export default termsAndConditions;

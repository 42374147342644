import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import PositionMe from 'components/PositionMe';
import SearchBarView from 'components/SearchBar/SearchBarView';
import { fuseSearch } from 'components/SearchBar/utils';
import SearchMenuItem from 'components/SearchTools/SearchMenuItem';
import { updateSearchValue } from 'redux/ui/actions';
import { ClassesType, SearchMenuObjectsType } from 'utils/types';

const styles = theme => ({
  addNew: {
    width: '100%',
    color: theme.palette.primary[700],
  },
  searchField: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  showMoreButton: {
    color: theme.palette.primary[700],
  },
});

const mapStateToProps = state => ({
  searchValue: state.ui.searchValue,
});

const mapDispatchToProps = dispatch => ({
  onUpdateSearch: value => {
    dispatch(updateSearchValue(value));
  },
});

class SearchMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  fuseSearchMemo = memoizeOne(fuseSearch);

  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    const { onUpdateSearch } = this.props;
    onUpdateSearch('');
  };

  onMoreClick = () => {};

  render() {
    const {
      classes,
      children,
      onNewClick,
      menuDisplayProp,
      searchValue,
      onUpdateSearch,
      withoutMoreButton,
      withoutSearchField,
      objects,
      onObjectClickFuncs,
      menuKeyProp,
      onMoreClick,
      excludeKey,
      canOpen,
    } = this.props;

    const onMoreClickFunc = onMoreClick || this.onMoreClick;
    const searchedObjects = this.fuseSearchMemo(objects, searchValue, [menuDisplayProp]);
    const searchKeys = searchedObjects.map(el => el[menuKeyProp]);

    const { anchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        {onNewClick ? (
          <PositionMe howHorizontal='left'>
            <Button className={classes.addNew} onClick={onNewClick}>
              <Translate id='searchMenu.addNew' />
            </Button>
          </PositionMe>
        ) : null}
        {!withoutSearchField ? (
          <SearchBarView
            className={classes.searchField}
            onUpdateSearch={onUpdateSearch}
            placeholderId='search'
            searchValue={searchValue}
          />
        ) : null}
        {searchKeys.map(key => {
          const objArray = _.values(objects).filter(asset => asset.id === key);
          if (objArray.length > 0) {
            const obj = objArray[0];
            if (obj[menuKeyProp] === excludeKey) {
              return null;
            }
            if (!obj[menuDisplayProp] || obj[menuDisplayProp] === 'NA') {
              return null;
            }
            const onClickFunc = onObjectClickFuncs[key];
            return (
              <SearchMenuItem
                onClick={onClickFunc}
                key={obj[menuKeyProp]}
                menuCloseFunc={this.handleMenuClose}
              >
                {obj[menuDisplayProp]}
              </SearchMenuItem>
            );
          }
          return null;
        })}
        {!withoutMoreButton ? (
          <PositionMe howHorizontal='center'>
            <Button className={classes.showMoreButton} onClick={onMoreClickFunc}>
              <Translate id='searchMenu.showMore' />
            </Button>
          </PositionMe>
        ) : null}
      </Menu>
    );

    return (
      <React.Fragment>
        {children(this.handleMenuOpen)}
        {canOpen ? renderMenu : null}
      </React.Fragment>
    );
  }
}

SearchMenu.propTypes = {
  classes: ClassesType.isRequired,
  // property of object, which will be used to show in the SearchMenu
  menuDisplayProp: PropTypes.string,
  // property of object, which will be used for the key of MenuListItem
  menuKeyProp: PropTypes.string,
  // objects to use in the Menu and Advanced Search Screen
  objects: SearchMenuObjectsType.isRequired,
  // functions, which are executed, when user clicks on the corresponding object
  onObjectClickFuncs: PropTypes.objectOf(PropTypes.func).isRequired,
  children: PropTypes.func.isRequired,
  // if not null, do not show the object if (object[menuKeyProp] === excludeKey)
  excludeKey: PropTypes.string,

  // removes button for Advanced search
  withoutMoreButton: PropTypes.bool,
  onMoreClick: PropTypes.func,

  // if not null, adds "+New" button
  onNewClick: PropTypes.func,

  // removes Search field
  withoutSearchField: PropTypes.bool,

  // from mapDispatchProps and StateToProps
  onUpdateSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,

  canOpen: PropTypes.bool,
};

SearchMenu.defaultProps = {
  excludeKey: null,
  onNewClick: null,
  onMoreClick: null,
  withoutMoreButton: false,
  withoutSearchField: false,
  menuKeyProp: 'id',
  menuDisplayProp: 'name',
  canOpen: true,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SearchMenu));

import { Link, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory } from 'react-router-dom';

import SimpleButton from 'components/SimpleButton';
import { useTranslate } from 'lib/localization/localization';
import { useURLSearchParams } from 'lib/navigation/navigation';
import { Routes } from 'utils/constants';

const translationPath = 'loginScreen.loginForm';

const useStyles = makeStyles(theme => ({
  section: {
    paddingTop: theme.spacing(6),
  },
  links: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  loginButton: {
    paddingTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface LoginFormProps {
  login: ({ email, password }: { email: string; password: string }) => void;
}

const LoginForm = ({ login }: LoginFormProps) => {
  const classes = useStyles();
  const history = useHistory();
  const searchParams = useURLSearchParams<['email']>();
  const translate = useTranslate();

  const [email, setEmail] = React.useState(searchParams.email ?? '');
  const [password, setPassword] = React.useState('');

  const onLoginClick = () => {
    login({ email, password });
  };

  const handleForgotPasswordClick = (event: any) => {
    event.preventDefault();
    history.push(Routes.FORGOT_PASSWORD);
  };

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <TextField
          size='small'
          required
          fullWidth
          label={translate(`${translationPath}.email`)}
          variant='outlined'
          value={email}
          onChange={event => {
            setEmail(event.target.value);
          }}
        />
      </div>
      <div className={classes.section}>
        <TextField
          size='small'
          fullWidth
          required
          type='password'
          label={translate(`${translationPath}.password`)}
          variant='outlined'
          value={password}
          onChange={event => {
            setPassword(event.target.value);
          }}
        />
      </div>
      <div className={classes.section}>
        <div className={classes.links}>
          <Link href={Routes.FORGOT_PASSWORD} onClick={handleForgotPasswordClick}>
            {translate(`${translationPath}.forgotPassword`)}
          </Link>
          <Link href='https://alpha-factory.de/contact/'>
            {translate(`${translationPath}.notRegistered`)}
          </Link>
        </div>
      </div>

      <div className={classes.loginButton}>
        <SimpleButton
          clickOnEnter
          disabled={false}
          size='large'
          onClick={onLoginClick}
          variant='highEmp'
          translateId={`${translationPath}.loginButton`}
        />
      </div>
    </div>
  );
};

export default LoginForm;

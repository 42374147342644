import { Card, CardContent, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { Id } from 'lib/types';
import { ALLOCATION_CHART_HEIGHT, DEFAULT_SINGLE_ALLOC_CHIPS } from 'lib/weights/constants';
import useAllocationChips from 'lib/weights/useAllocationChips';
import WeightsChart from 'lib/weights/WeightsChart';
import usePortfolioAllocation from 'screens/Portfolio/lib/hooks/usePortfolioAllocation';
import { PortfolioAllocationTypeT } from 'screens/Portfolio/lib/types';

const useStyles = makeStyles(theme => ({
  mainContent: {
    height: ALLOCATION_CHART_HEIGHT,
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  warningBar: {
    paddingTop: theme.spacing(2),
  },
}));

interface WeightsChartCardProps {
  portfolioId?: Id;
  allocationType?: PortfolioAllocationTypeT;
}

const WeightsChartCard = ({ portfolioId, allocationType }: WeightsChartCardProps) => {
  const classes = useStyles();
  const { toggledValues, toolbarChipsElem } = useAllocationChips({
    chipValues: DEFAULT_SINGLE_ALLOC_CHIPS,
  });
  const { weights, progressOrErrorElem, outdatedWarningElem, closestWarningElem } =
    usePortfolioAllocation(portfolioId, allocationType);

  const content = <WeightsChart weights={weights} toggledChips={toggledValues} />;

  return (
    <Card>
      <CardContent>
        {toolbarChipsElem}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {outdatedWarningElem && <div className={classes.warningBar}>{outdatedWarningElem}</div>}
            {closestWarningElem && <div className={classes.warningBar}>{closestWarningElem}</div>}
            <div className={classes.mainContent}>{progressOrErrorElem ?? content}</div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WeightsChartCard;

import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Id } from 'lib/types';
import WeightsChartComparisonCard from 'screens/Portfolio/lib/components/WeightsChartComparisonCard';
import WeightsTableCard from 'screens/Portfolio/lib/components/WeightsTableCard';
import { PortfolioAllocationType } from 'screens/Portfolio/lib/constants';
import { useAlerts } from 'screens/Portfolio/lib/hooks/portfolio';
import AlertsCard from 'screens/Portfolio/PortfolioScreen/components/PortfolioRebalanceTab/AlertsCard';
import RebalanceToolbar from 'screens/Portfolio/PortfolioScreen/components/PortfolioRebalanceTab/RebalanceToolbar';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(4),
  },
  alertsCard: {
    marginBottom: theme.spacing(4),
  },
}));

const PortfolioRebalanceTab = () => {
  const { id } = useParams<{ id: Id }>();
  const classes = useStyles();
  const { alertCount } = useAlerts(id);

  return (
    <>
      {alertCount > 0 && (
        <div className={classes.alertsCard}>
          <AlertsCard portfolioId={id} />
        </div>
      )}
      <div>
        <RebalanceToolbar portfolioId={id} />
      </div>
      <div className={classes.card}>
        <WeightsChartComparisonCard
          portfolioId={id}
          allocationType={PortfolioAllocationType.CURRENT}
          portfolioIdCompare={id}
          allocationTypeCompare={PortfolioAllocationType.OPTIMIZED}
        />
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <div className={classes.card}>
            <WeightsTableCard
              showAlerts
              portfolioId={id}
              allocationType={PortfolioAllocationType.CURRENT}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.card}>
            <WeightsTableCard portfolioId={id} allocationType={PortfolioAllocationType.OPTIMIZED} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default PortfolioRebalanceTab;

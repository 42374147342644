import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';

import SimpleText from 'components/SimpleText';
import {
  restoreWidgetConfig,
  updateWidgetConfig,
  updateWidgetSavedValues,
} from 'screens/Report/redux/actions';
import ConfigurationFrame from 'screens/Report/ReportScreen/components/ConfigurationFrame';
import { widgetType } from 'utils/types';

const ImageWidgetConfiguration = ({ closeAction, config }) => {
  const { imageContainer: defaultImageContainer } = config.configuration;

  const dispatch = useDispatch();

  const [imageContainer, setImageContainer] = useState(defaultImageContainer.value);

  useEffect(() => {
    dispatch(
      updateWidgetConfig(config.id, {
        imageContainer: {
          ...config.configuration.imageContainer,
          value: imageContainer,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageContainer]);

  return (
    <ConfigurationFrame
      closeAction={() => {
        dispatch(restoreWidgetConfig(config.id));
        closeAction();
      }}
      saveAction={() => {
        dispatch(updateWidgetSavedValues(config.id));
        closeAction();
      }}
    >
      <SimpleText translateId='dashboard.insertImages' fontWeight='bold' mb={2} />
      <ReactQuill
        value={imageContainer}
        // https://github.com/zenoamaro/react-quill#using-deltas
        onChange={(_, __, ___, editor) => setImageContainer(editor.getContents())}
        theme='snow'
        style={{ height: '450px' }}
        modules={{
          toolbar: [['image']],
          clipboard: {
            matchVisual: false,
          },
        }}
      />
    </ConfigurationFrame>
  );
};

ImageWidgetConfiguration.propTypes = {
  closeAction: PropTypes.func.isRequired,
  config: widgetType.isRequired,
};

export default ImageWidgetConfiguration;

import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import MyProgress from 'components/MyProgress';
import MyTabs from 'components/MyTabs';
import ScreenBase from 'components/ScreenBase/ScreenBase';
import TopScreenButtons from 'components/TopScreenButtons';
import useEditPermission from 'lib/access/useEditPermission';
import { useDraftString } from 'lib/misc/useDraft/useDraft';
import { useSavePromptAndDialog } from 'lib/misc/useSavePromptAndDialog';
import { Id } from 'lib/types';
import AnalysisConfigCard from 'screens/Analysis/AnalysisScreen/AnalysisConfigCard';
import PerformanceTab from 'screens/Analysis/AnalysisScreen/AnalysisPerformanceTab';
import RiskTab from 'screens/Analysis/AnalysisScreen/AnalysisRiskTab';
import {
  useAnalysisQuery,
  useCreateAnalysisMutation,
  useDeleteAnalysisMutation,
  useUpdateAnalysisMutation,
} from 'screens/Analysis/lib/queries';
import { AnalysisConfiguration } from 'screens/Analysis/lib/types';
import PortfoliosDropdown from 'screens/Backtest/lib/components/PortfoliosDropdown';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { isNotDone } from 'screens/Task/lib/helpers';
import { useAnalysisJobQuery } from 'screens/Task/lib/queries';
import { Routes } from 'utils/constants';

const translationPath = 'analysisScreen' as const;

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh',
  },
  tabs: {
    paddingTop: theme.spacing(2),
  },
}));

const AnalysisScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id, portfolioId } = useParams<{ id: Id | undefined; portfolioId: Id }>();
  const { portfolio } = usePortfolioQuery(portfolioId);
  const hasAccess = useEditPermission(portfolio);
  const isNew = !id;

  const { analysis, isLoading: isLoadingAnalysis } = useAnalysisQuery(id);
  const { job, isLoading: isLoadingJob } = useAnalysisJobQuery(id);
  const { mutate: create, isLoading: isLoadingCreate } = useCreateAnalysisMutation(portfolioId);
  const { mutate: updateConfig, isLoading: isLoadingUpdateConfig } = useUpdateAnalysisMutation();
  const { mutate: updateName, isLoading: isLoadingUpdateName } = useUpdateAnalysisMutation();
  const { mutate: deleteAnalysis, isLoading: isLoadingDeleteAnalysis } =
    useDeleteAnalysisMutation();

  const { draft: draftName, setDraft: setDraftName } = useDraftString(analysis?.name ?? '');
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangePortfolioId = (newPortfolioId: Id) => {
    history.push(
      generatePath(Routes.NEW_ANALYSIS, {
        portfolioId: newPortfolioId,
      })
    );
  };
  const handleCreate = (config: AnalysisConfiguration) => {
    const draft = {
      ...config,
      name: draftName,
    };
    create(draft, {
      onSuccess: data => {
        history.push(
          generatePath(Routes.ANALYSIS, {
            portfolioId,
            id: data.id,
          })
        );
      },
    });
  };
  const handleUpdateConfig = (config: AnalysisConfiguration) => {
    if (id) {
      updateConfig({ id, data: config });
    }
  };
  const handleUpdateName = () => {
    if (id) {
      updateName({ id, data: { name: draftName } });
    }
  };
  const handleDeleteClick = () => {
    if (id) {
      deleteAnalysis(id, {
        onSuccess: () => {
          history.push(generatePath(Routes.CHOOSE_ANALYSIS_WITH_PORTFOLIO, { portfolioId }));
        },
      });
    }
  };
  const returnButtonProps = {
    translateId: `${translationPath}.returnButton`,
    onClick: () => {
      history.push(generatePath(Routes.CHOOSE_ANALYSIS_WITH_PORTFOLIO, { portfolioId }));
    },
  };
  const nameTitleProps = {
    name: draftName,
    onRename: setDraftName,
    onConfirm: handleUpdateName,
    startWithEditing: isNew,
    canRename: hasAccess && !isLoadingUpdateName,
  };

  const showProgress = job && isNotDone(job);
  const disabled =
    isLoadingCreate ||
    isLoadingUpdateConfig ||
    isLoadingJob ||
    isLoadingAnalysis ||
    isLoadingDeleteAnalysis ||
    !hasAccess ||
    !draftName;
  const config = (
    <AnalysisConfigCard
      analysisId={id}
      portfolioId={portfolioId}
      disabled={disabled}
      onClick={isNew ? handleCreate : handleUpdateConfig}
    />
  );
  const { onDeleteClickWrapper, dialog } = useSavePromptAndDialog(isNew, false, {
    translationPath,
    onDeleteClick: handleDeleteClick,
  });
  const navButtonsProps = !isNew
    ? [
        {
          translateId: 'navButton.delete',
          onClick: onDeleteClickWrapper,
          variant: 'midEmp',
          disabled: !hasAccess || disabled,
        },
      ]
    : undefined;
  return (
    <ScreenBase
      titleTranslationId={`${translationPath}.title`}
      returnButtonProps={returnButtonProps}
      nameTitleProps={nameTitleProps}
      navButtonsProps={navButtonsProps}
    >
      {dialog}
      <TopScreenButtons>
        <PortfoliosDropdown
          disabled={!isNew}
          onChange={handleChangePortfolioId}
          portfolioId={portfolioId}
        />
        <div className={classes.tabs}>
          <MyTabs
            chosenTab={selectedTab}
            onChangeTab={(_: any, tabIndex: number) => setSelectedTab(tabIndex)}
            tabsWithBadge={[
              { translateId: 'performance', badge: 0 },
              { translateId: 'risk', badge: 0, 'pendo-id': 'analysis-screen-risk-tab' },
            ]}
            tabVariant='tab'
            badgeVariant='badge'
          />
        </div>
      </TopScreenButtons>

      <div className={classes.content}>
        {isNew && !showProgress && (
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={5} lg={3}>
              {config}
            </Grid>
          </Grid>
        )}
        {!isNew && !showProgress && (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={5} lg={3}>
                {config}
              </Grid>
              {analysis?.result && (selectedTab === 0 ? <PerformanceTab /> : <RiskTab />)}
            </Grid>
          </>
        )}

        {showProgress && (
          <div className={classes.progress}>
            <MyProgress />
          </div>
        )}
      </div>
    </ScreenBase>
  );
};

export default AnalysisScreen;

import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React from 'react';

import MyProgress from 'components/MyProgress';
import PortfolioAllocationChart from 'components/PortfolioAllocationChart';
import SimpleText from 'components/SimpleText';
import { Ids, ValueOf } from 'lib/types';
import { AllocationChip, DEFAULT_SINGLE_ALLOC_CHIPS } from 'lib/weights/constants';
import {
  useAssetAllocationsQuery,
  useAssetGroupsQuery,
  useAssetsQuery,
} from 'screens/Environment/lib/queries';
import { AssetAllocations } from 'screens/Environment/lib/types';
import { Weights } from 'screens/Portfolio/lib/types';

interface WeightsChartProps {
  weights: Weights | undefined;
  toggledChips: ReadonlyArray<ValueOf<typeof AllocationChip>>;
}

const translationPath = 'lib.weights.portfolioWeightsChart' as const;

const useStyles = makeStyles({
  chart: {
    height: '100%',
    width: '100%',
  },
});

const definePriority = (
  toggledChips: ReadonlyArray<ValueOf<typeof AllocationChip>>,
  assetAllocations: AssetAllocations
): Ids => {
  const priority: Ids = [];
  DEFAULT_SINGLE_ALLOC_CHIPS.forEach(chip => {
    if (!toggledChips.includes(chip)) {
      return;
    }
    if (chip === AllocationChip.ASSETS) {
      return;
    }

    _.forOwn(assetAllocations, assetAllocation => {
      if (chip === assetAllocation.typ) {
        priority.push(assetAllocation.id);
      }
    });
  });
  return priority;
};

const isNoLeafs = (toggledChips: ReadonlyArray<ValueOf<typeof AllocationChip>>) =>
  !toggledChips.includes(AllocationChip.ASSETS);

const WeightsChart = ({ weights, toggledChips }: WeightsChartProps) => {
  const classes = useStyles();
  const { assets, isLoading: isLoadingAssets } = useAssetsQuery();
  const { assetGroups, isLoading: isLoadingAssetGroups } = useAssetGroupsQuery();
  const { assetAllocations, isLoading: isLoadingAssetAllocations } = useAssetAllocationsQuery();
  const isEnvironmentLoading = isLoadingAssets || isLoadingAssetGroups || isLoadingAssetAllocations;

  let mainContent;
  if (isEnvironmentLoading) {
    mainContent = <MyProgress translateId={`${translationPath}.waitEnvironment`} />;
  } else if (weights === undefined) {
    mainContent = (
      <SimpleText
        color='error.main'
        variant='h3'
        translateId={`${translationPath}.weightsNotAvailable`}
      />
    );
  } else {
    mainContent = (
      <div className={classes.chart}>
        <PortfolioAllocationChart
          weights={weights}
          assets={assets}
          assetGroups={assetGroups}
          assetAllocations={assetAllocations}
          priority={definePriority(toggledChips, assetAllocations)}
          noLeafs={isNoLeafs(toggledChips)}
        />
      </div>
    );
  }
  return mainContent;
};

export default WeightsChart;

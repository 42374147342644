import _ from 'lodash';
import PropTypes from 'prop-types';

import { ResearchAction } from 'screens/Research/lib/constants';
import {
  AssetGroupKind,
  ConfigurationChip,
  ErrorKind,
  Order,
  PortfolioJobStatus,
  TreemapChip,
} from 'utils/constants';

const {
  shape,
  number,
  string,
  bool,
  arrayOf,
  objectOf,
  oneOf,
  node,
  oneOfType,
  func,
  any,
  object,
  link,
} = PropTypes;

export const DateType = oneOfType([PropTypes.instanceOf(Date), string]);

export const idType = string;
export const idArrayType = arrayOf(string);
export const ClassesType = objectOf(string);
export const ChildrenType = oneOfType([arrayOf(node), node]);

export const AssetType = shape({
  assetClass: string,
  assetType: string,
  country: string,
  currency: string,
  id: string.isRequired,
  index: string,
  industry: string,
  effectiveAdapterIdentifier: string,
  instituteIdentifier: string.isRequired,
  name: string,
  masterUpdatedOn: DateType,
  isMasterFilled: bool.isRequired,
  isCash: bool.isRequired,
  assetGroups: idArrayType.isRequired,
});

export const AssetsType = objectOf(AssetType);

export const BasketType = shape({
  assets: idArrayType,
  id: idType,
  name: string,
});

export const BasketsType = objectOf(BasketType);

export const UniverseType = shape({
  assets: idArrayType.isRequired,
  id: idType,
  name: string,
});

export const UniversesType = objectOf(UniverseType);

export const RecommendationType = shape({
  asset: idType,
  recommendationType: idType,
  id: idType,
});

export const RecommendationTypeType = shape({
  id: idType,
  name: string,
});

export const ResearchListType = shape({
  recColName: string,
  idColName: string,
  id: idType,
  name: string,
  recommendations: arrayOf(RecommendationType),
  recommendationTypes: arrayOf(RecommendationTypeType),
});

export const ResearchListsType = objectOf(ResearchListType);

export const ResearchConstraintType = shape({
  action: oneOf(_.keys(ResearchAction)),
  recommendationType: idType,
});

export const ResearchConstraintListType = shape({
  constraints: arrayOf(ResearchConstraintType),
  researchList: idType,
  id: idType,
  name: string,
  isPredefined: bool,
});

export const ResearchConstraintListsType = objectOf(ResearchConstraintListType);

export const ResearchCardType = shape({
  researchListId: idType,
  cardId: number,
  recommendationActions: arrayOf(ResearchConstraintType),
  id: idType,
  name: string,
  isPredefined: bool,
  researchConstraintListId: idType,
});

export const ResearchCardsType = arrayOf(ResearchCardType);

export const AssetGroupType = shape({
  assets: idArrayType.isRequired,
  id: string.isRequired,
  name: string.isRequired,
  typ: oneOf(_.keys(AssetGroupKind)).isRequired,
});

const TreemapDataLeafType = shape({
  name: string.isRequired,
  level: number.isRequired,
  size: number.isRequired,
});
const TreemapData = {
  name: string.isRequired,
  level: number.isRequired,
};
export const TreemapDataType = shape(TreemapData);
TreemapData.children = arrayOf(oneOfType([TreemapDataType, TreemapDataLeafType])).isRequired;

const ChartDataPointType = shape({
  x: DateType,
  y: number,
});

export const ChartDataType = arrayOf(ChartDataPointType);

export const MarkSeriesDataType = arrayOf(ChartDataPointType);

export const AssetGroupsType = objectOf(AssetGroupType);

export const AssetAllocationType = shape({
  id: string.isRequired,
  name: string.isRequired,
  typ: oneOf(_.keys(AssetGroupKind)).isRequired,
  assetGroups: idArrayType,
});

export const AssetAllocationsType = objectOf(AssetAllocationType);

export const JobStatusType = oneOf(_.keys(PortfolioJobStatus));

export const JobType = shape({
  id: string.isRequired,
  status: JobStatusType.isRequired,
});

export const GroupConstraintType = shape({
  assetGroup: idType.isRequired,
  id: string,
  maximum: number.isRequired,
  minimum: number.isRequired,
  tolerance: number,
});

export const IndividualConstraintType = shape({});

export const ResultWeightType = shape({
  asset: string.isRequired,
  id: string.isRequired,
  weight: number.isRequired,
});

export const GroupConstraintListType = shape({
  constraints: arrayOf(GroupConstraintType),
  assetAllocation: idType,
  id: idType,
  name: string,
  isPredefined: bool,
});

export const GroupConstraintListsType = objectOf(GroupConstraintListType);

export const WeightsType = arrayOf(ResultWeightType);
export const PortfolioAllocationType = shape({
  errorMsg: string,
  updatedOn: string,
  weights: WeightsType.isRequired,
  rebalancedWeights: arrayOf(ResultWeightType),
  isError: bool,
  isClosest: bool,
  isSolved: bool,
});

export const TaskType = shape({
  id: idType.isRequired,
  effectiveDate: DateType,
  jobs: arrayOf(JobType).isRequired,
});

export const PortfolioType = shape({
  assetAllocation: string,
  configUpdatedOn: string,
  createdOn: string,
  groupConstraints: arrayOf(GroupConstraintType),
  id: string.isRequired,
  individualConstraints: arrayOf(IndividualConstraintType),
  isTargetRisk: bool,
  isTracked: bool,
  optimizationJob: JobType,
  name: string,
  targetCorridor: number,
  targetValue: number,
  optimAllocation: PortfolioAllocationType,
  virtualmAllocation: PortfolioAllocationType,
  realAllocation: PortfolioAllocationType,
  optimAlertAllocation: PortfolioAllocationType,
  backtestTask: TaskType,
  transactionsUpdateTask: TaskType,
});

export const PortfolioChangesType = shape({
  configChanged: bool.isRequired,
  wasReoptimized: bool.isRequired,
  anythingChanged: bool.isRequired,
  nameChanged: bool.isRequired,
  unsaved: bool.isRequired,
  type: string,
});

const TabWithBadgeType = oneOfType([
  shape({
    translateId: string,
    badge: number,
    'pendo-id': string,
  }),
  shape({
    translateId: string,
    badge: node,
    isComponent: bool,
  }),
]);

export const TabsWithBadgeType = arrayOf(TabWithBadgeType);

export const MenuItemType = shape({
  translateId: string,
  onclick: func,
  path: link,
});
export const MenuItemsType = arrayOf(MenuItemType);

export const PortfoliosType = objectOf(PortfolioType);

export const UiPortfolioType = objectOf(any);
export const UiBacktestType = objectOf(any);

export const TranslateDataType = objectOf(oneOfType([string, number, arrayOf(string)]));
export const errorKindType = oneOf(_.keys(ErrorKind));
export const OrderType = oneOf(_.values(Order));
export const UIErrorType = shape({
  origin: string.isRequired,
  kind: errorKindType,
  data: TranslateDataType,
});

export const configurationChipType = arrayOf(oneOf(_.keys(ConfigurationChip)));
export const TreemapChipType = arrayOf(oneOf(_.keys(TreemapChip)));

export const LocationType = PropTypes.shape({
  pathname: PropTypes.string,
});

export const MatchType = PropTypes.shape({
  isExact: bool,
  path: string,
  url: string,
  // eslint-disable-next-line react/forbid-prop-types
  params: objectOf(any),
});

export const HistoryType = PropTypes.shape({
  push: func.isRequired,
  location: LocationType,
});

export const TableObjectsType = oneOfType([arrayOf(object), objectOf(object)]);
export const SearchMenuObjectsType = objectOf(object);
export const ExportFieldsType = arrayOf(string);

const RebalancingDataPointType = shape({
  date: string,
  message: string,
});

export const RebalancingDataType = arrayOf(RebalancingDataPointType);

export const LegendChipType = shape({
  color: string,
  value: string,
  test: string,
  onDelete: func,
});

export const LegendChipsType = arrayOf(LegendChipType);

export const KPITableColumnType = shape({
  cumulativeReturn: number,
  meanReturn: number,
  STD: number,
  VAR: number,
  CVAR: number,
  annualMean: number,
  sharpeRatio: number,
  cSharpeRatio: number,
});

export const PermissionType = shape({
  resourceName: string.isRequired,
  permissionLevel: string.isRequired,
  accessLevel: string.isRequired,
});

export const RoleType = shape({
  name: string.isRequired,
  institute: idType.isRequired,
  permissions: arrayOf(PermissionType),
});

export const UserGroupAccessDictType = shape({
  edit: objectOf(arrayOf(idType)),
  read: objectOf(arrayOf(idType)),
});

export const UserGroupType = shape({
  name: string.isRequired,
  institute: idType.isRequired,
  accessDict: UserGroupAccessDictType.isRequired,
  roles: arrayOf(RoleType),
});

export const UserType = shape({
  id: idType.isRequired,
  email: string.isRequired,
});

export const ResourceType = shape({
  id: idType.isRequired,
  tablename: string.isRequired,
});

export const InstituteType = shape({
  id: idType.isRequired,
  name: string.isRequired,
  actualDate: DateType,
  assets: idArrayType.isRequired,
  assetGroups: idArrayType.isRequired,
  assetAllocations: idArrayType.isRequired,
  universes: idArrayType.isRequired,
  users: idArrayType.isRequired,
  tsUpdateTask: TaskType.isRequired,
  updateTask: TaskType.isRequired,
});
export const InstitutesType = objectOf(InstituteType);

export const KPITableType = objectOf(KPITableColumnType);

// not the whole, list, continue in case of need or change the logic
export const ColorType = oneOf(['primary', 'secondary', 'neutral']);
export const TypographyVariantType = any;
export const ThemeType = objectOf(any);

export const SearchMenuPropsType = PropTypes.objectOf(PropTypes.any);
export const NameTitlePropsType = PropTypes.objectOf(PropTypes.any);
export const ReturnButtonPropsType = PropTypes.objectOf(PropTypes.any);

export const HelpParagraphType = shape({
  header: string,
  message: string,
  picture: string,
  video: string,
  link: string,
});
export const HelpSubtopicType = shape({
  messages: arrayOf(HelpParagraphType).isRequired,
  header: string,
});
export const HelpType = shape({
  subtopics: arrayOf(HelpSubtopicType),
  header: string,
  message: string,
  picture: string,
  id: idType,
});

export const ActiveLanguageType = shape({
  code: string.isRequired,
});

export const CardType = oneOf(_.keys(ConfigurationChip));
export const CardTypes = arrayOf(CardType);

export const AreaInfoDataType = PropTypes.arrayOf(any);
export const AreaVisualDataType = PropTypes.arrayOf(any);
export const BarChartDataType = PropTypes.arrayOf(any);
export const SliderDomainType = PropTypes.arrayOf(number); // min and max

export const PortfolioComparisonTableDataType = PropTypes.arrayOf(any);

export const cardInfoType = PropTypes.shape({
  preset: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  allocation: PropTypes.string.isRequired,
  gid: PropTypes.oneOfType([null, idType]),
  constraints: PropTypes.oneOfType([
    null,
    PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }),
  ]),
  isPortfolio: PropTypes.bool.isRequired,
});

export const aaCardsType = PropTypes.objectOf(
  PropTypes.shape({
    id: PropTypes.string,
    allocation: PropTypes.string,
  })
);

export const reportType = PropTypes.shape({
  id: idType,
  portfolioInfo: PropTypes.string,
});

export const reportsType = PropTypes.objectOf(reportType);

export const widgetLayoutType = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
  i: PropTypes.string.isRequired,
  static: PropTypes.bool.isRequired,
});

export const widgetType = PropTypes.shape({
  id: idType.isRequired,
  type: PropTypes.string.isRequired,
  configuration: PropTypes.objectOf(PropTypes.any),
  layout: widgetLayoutType,
});

export const reportInfoType = PropTypes.shape({
  name: PropTypes.string,
  portfolioInfo: PropTypes.oneOfType([null, PropTypes.string]),
});

export const widgetComponentPropType = {
  widget: widgetType.isRequired,
  setSelectedWidget: PropTypes.func.isRequired,
  setShowSettings: PropTypes.func.isRequired,
  setSettingsType: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/forbid-prop-types
export const FieldType = PropTypes.shape({ field: PropTypes.string, value: PropTypes.any });

export const AnyBackendObject = objectOf(any);

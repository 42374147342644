import { PictureAsPdf } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';

const PdfExport = ({ orientation, disabled }) => {
  useEffect(() => {
    const destination = document.querySelector('#printDestination');
    const listener = () => {
      destination.innerHTML = null;
    };
    window.addEventListener('afterprint', listener);
    return function cleanup() {
      window.removeEventListener('afterprint', listener);
    };
  });
  const handlePrint = () => {
    const sheet = window.document.styleSheets[0];
    const ratio = 0.67;
    const height = 297 * ratio;
    const width = 210 * ratio;

    sheet.insertRule(
      `@media print {
        @page {
          size: ${
            orientation === 'landscape' ? `${height}mm ${width}mm` : `${width}mm ${height}mm`
          };
          margin: 0;
          }
        }`,
      sheet.cssRules.length
    );
    const content = document.querySelector('#printBoard');
    const destination = document.querySelector('#printDestination');
    destination.appendChild(content.cloneNode(true));
    window.print();
  };

  return (
    <Tooltip title={<Translate id='dashboard.exportPdf' />}>
      <span>
        <IconButton onClick={handlePrint} disabled={disabled} size='large'>
          <PictureAsPdf fontSize='large' color={disabled ? 'disabled' : 'action'} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

PdfExport.propTypes = {
  orientation: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

PdfExport.defaultProps = {
  disabled: false,
};
export default React.memo(PdfExport);

import PropTypes from 'prop-types';
import React from 'react';
import ContainerDimensions from 'react-container-dimensions';

import MyLineChartBase from './MyLineChartBase';

import { MarkSeriesDataType } from 'utils/types';

const MyLineChartWrapper = ({
  data,
  padding,
  YAxisTitle,
  isDummyBacktest,
  onDatesChange,
  markSeriesData,
  gridLinesCount,
  hoveredLine,
  zoomSlowness,
  tickTotalX,
  onPointerMove,
  rebalancingPoints,
  onRebalancingPointClick,
  skipScrolling,
  whiteBackground,
  transparentBackground,
}) => (
  <ContainerDimensions>
    {({ width, height }) => (
      <MyLineChartBase
        width={width - padding}
        data={data}
        markSeriesData={markSeriesData}
        height={height}
        YAxisTitle={YAxisTitle}
        isDummyBacktest={isDummyBacktest}
        onDatesChange={onDatesChange}
        gridLinesCount={gridLinesCount}
        hoveredLine={hoveredLine}
        zoomSlowness={zoomSlowness}
        tickTotalX={tickTotalX}
        onPointerMove={onPointerMove}
        rebalancingPoints={rebalancingPoints}
        onRebalancingPointClick={onRebalancingPointClick}
        skipScrolling={skipScrolling}
        whiteBackground={whiteBackground}
        transparentBackground={transparentBackground}
      />
    )}
  </ContainerDimensions>
);

MyLineChartWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  padding: PropTypes.number,
  YAxisTitle: PropTypes.string,
  isDummyBacktest: PropTypes.bool,
  onDatesChange: PropTypes.func,
  markSeriesData: MarkSeriesDataType,
  gridLinesCount: PropTypes.number,
  hoveredLine: PropTypes.string,
  zoomSlowness: PropTypes.number,
  tickTotalX: PropTypes.number,
  onPointerMove: PropTypes.func,
  rebalancingPoints: MarkSeriesDataType,
  onRebalancingPointClick: PropTypes.func,
  skipScrolling: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  transparentBackground: PropTypes.bool,
};

MyLineChartWrapper.defaultProps = {
  padding: 0,
  YAxisTitle: null,
  isDummyBacktest: false,
  onDatesChange: null,
  markSeriesData: null,
  gridLinesCount: 3,
  hoveredLine: null,
  zoomSlowness: 40,
  tickTotalX: 3,
  onPointerMove: null,
  rebalancingPoints: null,
  onRebalancingPointClick: null,
  skipScrolling: false,
  whiteBackground: false,
  transparentBackground: false,
};

export default MyLineChartWrapper;

import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import MyProgress from 'components/MyProgress';
import ScreenBase from 'components/ScreenBase';
import TopScreenButtons from 'components/TopScreenButtons';
import useEditPermission from 'lib/access/useEditPermission';
import { useDraftString } from 'lib/misc/useDraft/useDraft';
import { useSavePromptAndDialog } from 'lib/misc/useSavePromptAndDialog';
import useScroll from 'lib/misc/useScroll';
import KPIsTableCard from 'lib/performance/KPIsTableCard';
import PerformanceChartCard from 'lib/performance/PerformanceChartCard';
import { Id } from 'lib/types';
import HistoryWeightsChartsCard from 'lib/weights/HistoryWeightsChartsCard';
import { AnalysisDataType } from 'screens/Analysis/lib/constants';
import BacktestConfig from 'screens/Backtest/BacktestScreen/components/BacktestConfig';
import PortfoliosDropdown from 'screens/Backtest/lib/components/PortfoliosDropdown';
import {
  useBacktestQuery,
  useCancelBacktestMutation,
  useCreateBacktestMutation,
  useDeleteBacktestMutation,
  useUpdateBacktestMutation,
} from 'screens/Backtest/lib/queries';
import { BacktestConfiguration } from 'screens/Backtest/lib/types';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { isNotDone } from 'screens/Task/lib/helpers';
import { useBacktestJobQuery } from 'screens/Task/lib/queries';
import { Routes } from 'utils/constants';

const translationPath = 'backtestScreen' as const;

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh',
  },
}));

const BacktestScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id, portfolioId } = useParams<{ id: Id | undefined; portfolioId: Id }>();
  const isNew = !id;
  const { backtest, isLoading: isLoadingBacktest } = useBacktestQuery(id);
  const { job, isLoading: isLoadingJob } = useBacktestJobQuery(id);
  const { mutate: createBacktest, isLoading: isLoadingCreate } =
    useCreateBacktestMutation(portfolioId);
  const { mutate: updateBacktestConfig, isLoading: isLoadingUpdateConfig } =
    useUpdateBacktestMutation();
  const { mutate: updateBacktestName, isLoading: isLoadingUpdateName } =
    useUpdateBacktestMutation();
  const { mutate: deleteBacktest, isLoading: isLoadingDelete } = useDeleteBacktestMutation();
  const { mutate: cancelBacktest, isLoading: isLoadingCancel } = useCancelBacktestMutation();
  const { portfolio } = usePortfolioQuery(portfolioId);
  const { draft: draftName, setDraft: setDraftName } = useDraftString(backtest?.name ?? '');
  const hasAccess = useEditPermission(portfolio);
  const [startDate, setStartDate] = React.useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = React.useState<Date | undefined>(undefined);
  const [compareAssetIds, setCompareAssetIds] = React.useState<Id[]>([]);
  const { scrollRef, executeScroll } = useScroll();

  const handleDatesChange = (newStartDate: Date, newEndDate: Date) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };
  const handleChangePortfolioId = (newPortfolioId: Id) => {
    history.push(
      generatePath(Routes.NEW_BACKTEST, {
        portfolioId: newPortfolioId,
      })
    );
  };
  const handleCreateBacktest = (backtestConfig: BacktestConfiguration) => {
    const draft = {
      ...backtestConfig,
      name: draftName,
    };
    createBacktest(draft, {
      onSuccess: data => {
        history.push(
          generatePath(Routes.BACKTEST_WITH_PORTFOLIO, {
            portfolioId,
            id: data.id,
          })
        );
      },
    });
  };
  const handleUpdateBacktestConfig = (backtestConfig: BacktestConfiguration) => {
    if (id) {
      updateBacktestConfig({ id, data: backtestConfig });
    }
  };
  const handleUpdateBacktestName = () => {
    if (id) {
      updateBacktestName({ id, data: { name: draftName } });
    }
  };
  const handleCancelClick = () => {
    if (id) {
      cancelBacktest(id);
    }
  };
  const handleDeleteClick = () => {
    if (id) {
      deleteBacktest(id, {
        onSuccess: () => {
          history.push(generatePath(Routes.CHOOSE_BACKTEST_WITH_PORTFOLIO, { portfolioId }));
        },
      });
    }
  };
  const returnButtonProps = {
    translateId: `${translationPath}.returnButton`,
    onClick: () => {
      history.push(generatePath(Routes.CHOOSE_BACKTEST_WITH_PORTFOLIO, { portfolioId }));
    },
  };
  const nameTitleProps = {
    name: draftName,
    onRename: setDraftName,
    onConfirm: handleUpdateBacktestName,
    startWithEditing: isNew,
    canRename: hasAccess && !isLoadingUpdateName,
  };
  const disabled =
    isLoadingCreate ||
    isLoadingUpdateConfig ||
    isLoadingCancel ||
    isLoadingJob ||
    isLoadingBacktest ||
    isLoadingDelete ||
    !hasAccess ||
    !draftName;
  const config = (
    <BacktestConfig
      backtestId={id}
      disabled={disabled}
      onClick={isNew ? handleCreateBacktest : handleUpdateBacktestConfig}
    />
  );

  const showProgress = isLoadingCancel || (job && isNotDone(job));
  const { onDeleteClickWrapper, dialog } = useSavePromptAndDialog(isNew, false, {
    translationPath,
    onDeleteClick: handleDeleteClick,
  });
  const navButtonsProps = !isNew
    ? [
        {
          translateId: 'navButton.delete',
          onClick: onDeleteClickWrapper,
          variant: 'midEmp',
          disabled: !hasAccess || disabled,
        },
      ]
    : undefined;
  return (
    <ScreenBase
      titleTranslationId={`${translationPath}.title`}
      returnButtonProps={returnButtonProps}
      nameTitleProps={nameTitleProps}
      navButtonsProps={navButtonsProps}
    >
      {dialog}
      <TopScreenButtons>
        <PortfoliosDropdown
          disabled={!isNew}
          onChange={handleChangePortfolioId}
          portfolioId={portfolioId}
        />
      </TopScreenButtons>
      <div className={classes.content}>
        <Grid container spacing={4} alignItems='stretch'>
          {isNew && !showProgress && (
            <Grid item xs={12} sm={6} md={5} lg={3}>
              {config}
            </Grid>
          )}
          {!isNew && !showProgress && (
            <>
              <Grid item xs={12} sm={6} md={5} lg={3}>
                {config}
              </Grid>
              <Grid item xs={12} lg={9}>
                <PerformanceChartCard
                  backtestId={id}
                  dataType={AnalysisDataType.BACKTEST}
                  onDatesChange={handleDatesChange}
                  compareAssetIds={compareAssetIds}
                  onChangeCompareAssetIds={setCompareAssetIds}
                  onRebalancingPointClick={executeScroll}
                />
              </Grid>

              <Grid item xs={12}>
                <KPIsTableCard
                  backtestId={id}
                  dataType={AnalysisDataType.BACKTEST}
                  compareAssetIds={compareAssetIds}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Grid>
              {backtest?.points && (
                <Grid ref={scrollRef} item xs={12}>
                  <HistoryWeightsChartsCard rebalancingPoints={backtest?.points} />
                </Grid>
              )}
            </>
          )}
        </Grid>
        {showProgress && (
          <div className={classes.progress}>
            <MyProgress onCancelClick={handleCancelClick} />
          </div>
        )}
      </div>
    </ScreenBase>
  );
};

export default BacktestScreen;

import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';

import ScreenBase from 'components/ScreenBase';
import SearchBar from 'components/SearchBar/SearchBarView';
import Table from 'components/Table/TableView';
import { useAssetsAdminQuery } from 'screens/Environment/lib/queries';

const translatePath = 'adminAssetScreen';

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

const COLS = [
  'isin',
  'dataAdapterName',
  'ticker',
  'iexTicker',
  'effectiveAdapterIdentifier',
  'name',
  'masterUpdatedOn',
  'effectiveDate',
  'isQualityCheckPassedStr',
  'qualityCheckResultReason',
  'qualityCheckEffDate',
];

export const transformAssets = (assets: any) => {
  return _.values(assets)
    .filter(asset => asset.isDeleted === false)
    .map(asset => {
      const qualityCheckResultReason = asset?.qualityCheckResult?.reason || '';
      const isQualityCheckPassedStr = asset.isQualityCheckPassed ? 'Pass' : 'Fail';
      return { ...asset, qualityCheckResultReason, isQualityCheckPassedStr };
    });
};

const AssetsScreen = () => {
  const classes = useStyles();
  const { assets } = useAssetsAdminQuery();
  const tableObjects = useMemo(() => transformAssets(assets), [assets]);
  const [searchValue, setSearchValue] = useState('');
  return (
    <ScreenBase title='adminAssets'>
      <div className={classes.content}>
        <SearchBar
          searchValue={searchValue}
          onUpdateSearch={setSearchValue}
          placeholderId='universe'
        />
        <Table
          titleTranslateId={`${translatePath}.tableTitle`}
          translateTableId='assetTable'
          tableObjects={tableObjects}
          columns={COLS}
          searchValue={searchValue}
          isDownloadButton
          isTableWhite
          showEmptyRows={false}
        />
      </div>
    </ScreenBase>
  );
};

export default AssetsScreen;

import { CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import DialogButton from 'components/Buttons/DialogButton';
import { generateSearchPath } from 'lib/navigation/navigation';
import { Id } from 'lib/types';
import { getNewStrategy, getStrategy, hasNewStrategy } from 'screens/Portfolio/lib/helpers';
import { useMatchNewPortfolioRoute } from 'screens/Portfolio/lib/hooks/useMatch';
import useStrategyConfiguration from 'screens/Portfolio/lib/hooks/useStrategyConfiguration';
import {
  useCreatePortfolioMutation,
  usePortfolioQuery,
  useUpdatePortfolioStrategyMutation,
} from 'screens/Portfolio/lib/queries';
import { DialogKeys, Routes } from 'utils/constants';

const translationPath = 'portfolioReconfigurationScreen.configurationDialog';

interface ConfigurationDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const ConfigurationDialog = ({ isOpen, onClose }: ConfigurationDialogProps) => {
  const history = useHistory();
  const { id } = useParams<{ id: Id | undefined }>();
  const isNew = Boolean(useMatchNewPortfolioRoute());
  const { mutate: updatePortfolio } = useUpdatePortfolioStrategyMutation(id);
  const { mutate: createPortfolio } = useCreatePortfolioMutation();
  const { portfolio, isLoading } = usePortfolioQuery(id);
  const strategy = useMemo(() => {
    if (portfolio === undefined) {
      return undefined;
    }
    if (hasNewStrategy(portfolio)) {
      return getNewStrategy(portfolio);
    }
    return getStrategy(portfolio);
  }, [portfolio]);
  const {
    strategyConfigElement,
    draft,
    resetDraft,
    isLoading: isLoadingConfig,
  } = useStrategyConfiguration({
    strategy,
    disabled: isLoading,
    isNew,
  });

  const handleExit = () => {
    if (!isNew) {
      resetDraft();
    }
  };

  const handleOptimize = () => {
    if (isNew) {
      createPortfolio(draft, {
        onSuccess: data => {
          history.push(
            generateSearchPath(Routes.PORTFOLIO_RECONFIGURATION, {
              params: { id: data.id },
            })
          );
        },
      });
    } else {
      updatePortfolio(draft, { onSettled: () => resetDraft() });
    }

    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={isOpen}
      onClose={onClose}
      aria-labelledby='change-config-dialog-title'
      TransitionProps={{
        onExited: handleExit,
      }}
    >
      <DialogContent>
        {isLoading && !isNew ? <CircularProgress /> : strategyConfigElement}
      </DialogContent>
      <DialogActions>
        <DialogButton
          onClick={onClose}
          translateId={`dialogKey.${DialogKeys.CANCEL}`}
          variant='lowEmp'
        />
        <DialogButton
          pendo-id='portfolio-configuration-dialog-optimize-button'
          onClick={handleOptimize}
          translateId={`${translationPath}.optimizeButton`}
          variant='lowEmp'
          disabled={isLoadingConfig}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfigurationDialog;

import { ShowChart, Equalizer } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const useStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    right: '0',
    top: '0',
    marginTop: '0.7rem',
    marginRight: '0.7rem',
    padding: '0',
    zIndex: 10,
  },
  item: {
    cursor: 'pointer',
    color: '#a9aaaa',
    marginRight: '0.4rem',
  },
});

const ChartOptionSelector = ({ option, setOption }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <span className={classes.item} onClick={() => setOption('line')}>
        <ShowChart fontSize='small' color={option === 'line' ? 'primary' : 'inherit'} />
      </span>
      <span className={classes.item} onClick={() => setOption('bar')}>
        <Equalizer fontSize='small' color={option === 'bar' ? 'primary' : 'inherit'} />
      </span>
    </div>
  );
};

ChartOptionSelector.propTypes = {
  option: PropTypes.string.isRequired,
  setOption: PropTypes.func.isRequired,
};

export default memo(ChartOptionSelector);

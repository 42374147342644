import { Box, Card, CardContent, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';

import MyDropdown from 'components/MyDropdown';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import { toDateString } from 'lib/dates/dateUtils';
import { IsoDate } from 'lib/types';
import {
  ALLOCATION_CHART_HEIGHT,
  DEFAULT_COMPARE_ALLOC_CHIPS,
  DEFAULT_SINGLE_ALLOC_CHIPS,
} from 'lib/weights/constants';
import useAllocationChips from 'lib/weights/useAllocationChips';
import useSingleAllocationChips from 'lib/weights/useSingleAllocationChips';
import WeightsBarChart from 'lib/weights/WeightsBarChart';
import WeightsChart from 'lib/weights/WeightsChart';
import { HistoricalSnapshot } from 'screens/Portfolio/lib/types';

const translationPath = 'lib.weights.historyWeightsChartsCard' as const;

const useStyles = makeStyles(theme => ({
  mainContent: {
    height: ALLOCATION_CHART_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleWithDropdown: {
    display: 'flex',
    alignItems: 'center',
  },
  leftDropdown: {
    marginLeft: theme.spacing(2),
  },
}));

const sortRebalancingPoints = (points: ReadonlyArray<HistoricalSnapshot>) => {
  const sortedDates = points.map(point => point.effectiveDate);
  sortedDates.sort((a, b) => {
    const aDate = new Date(a);
    const bDate = new Date(b);
    if (aDate < bDate) {
      return -1;
    }
    if (aDate > bDate) {
      return 1;
    }
    return 0;
  });
  const pointsObject: { [date: IsoDate]: HistoricalSnapshot } = {};
  points.forEach(point => {
    pointsObject[point.effectiveDate] = point;
  });
  return { sortedDates, pointsObject };
};

interface DatesDropdownProps {
  date: IsoDate | undefined;
  onChangeDate: (date: IsoDate) => void;
  allDates: IsoDate[];
}

const DatesDropdown = ({ date, onChangeDate, allDates }: DatesDropdownProps) => {
  return (
    <MyDropdown
      // @ts-ignore
      value={date}
      onChange={onChangeDate}
      translationPath={`${translationPath}.dropdown`}
      disabled={false}
      isNullAllowed={false}
    >
      {allDates.map(d => (
        <MenuItem key={d} value={d}>
          {toDateString(d)}
        </MenuItem>
      ))}
    </MyDropdown>
  );
};

interface HistoryWeightsChartsCardProps {
  rebalancingPoints: ReadonlyArray<HistoricalSnapshot>;
}

const HistoryWeightsChartsCard = ({ rebalancingPoints }: HistoryWeightsChartsCardProps) => {
  const classes = useStyles();
  const { sortedDates, pointsObject } = useMemo(() => {
    return sortRebalancingPoints(rebalancingPoints);
  }, [rebalancingPoints]);
  const [leftDate, setLeftDate] = React.useState<IsoDate | undefined>(sortedDates[0]);
  const [rightDate, setRightDate] = React.useState<IsoDate | undefined>(
    sortedDates[sortedDates.length - 1]
  );

  const [isBarChart, toggleIsBarChart] = React.useReducer(previous => !previous, false);
  const { toggledValues, toolbarChipsElem: toolbarChipsMultipleChoiceElem } = useAllocationChips({
    chipValues: DEFAULT_SINGLE_ALLOC_CHIPS,
  });
  const { chosenValue, toolbarChipsElem: toolbarChipsSingleChoiceElem } = useSingleAllocationChips({
    chipValues: DEFAULT_COMPARE_ALLOC_CHIPS,
  });
  const error = (
    <SimpleText color='error.main' variant='h3' translateId={`${translationPath}.dateNotChosen`} />
  );

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4} alignItems='center'>
          <Grid item xs={12} sm={6}>
            <div className={classes.titleWithDropdown}>
              <SimpleText translateId={`${translationPath}.title`} variant='h2' />
              <div className={classes.leftDropdown} />
              <DatesDropdown date={leftDate} allDates={sortedDates} onChangeDate={setLeftDate} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatesDropdown date={rightDate} allDates={sortedDates} onChangeDate={setRightDate} />
          </Grid>
        </Grid>
        <Box mt={2} />
        <Grid container spacing={4}>
          <Grid item xs={9}>
            {isBarChart ? toolbarChipsSingleChoiceElem : toolbarChipsMultipleChoiceElem}
          </Grid>
          <Grid item xs={3}>
            <div style={{ float: 'right' }}>
              <SimpleButton
                translateId={`${translationPath}.switchTo${isBarChart ? 'Pie' : 'Bar'}Chart`}
                onClick={toggleIsBarChart}
                variant='midEmp'
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          {isBarChart ? (
            <Grid item xs={12}>
              <div className={classes.mainContent}>
                {rightDate && leftDate ? (
                  <WeightsBarChart
                    weights={pointsObject[leftDate]?.allocation?.weights}
                    weightsCompare={pointsObject[rightDate]?.allocation?.weights}
                    toggledChip={chosenValue}
                  />
                ) : (
                  error
                )}
              </div>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <div className={classes.mainContent}>
                  {leftDate ? (
                    <WeightsChart
                      weights={pointsObject[leftDate]?.allocation?.weights}
                      toggledChips={toggledValues}
                    />
                  ) : (
                    error
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.mainContent}>
                  {rightDate ? (
                    <WeightsChart
                      weights={pointsObject[rightDate]?.allocation?.weights}
                      toggledChips={toggledValues}
                    />
                  ) : (
                    error
                  )}
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HistoryWeightsChartsCard;

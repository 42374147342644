import { localizeReducer as localize } from 'react-localize-redux';
import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import navigation from './navigation/reducers';
import notifications from './notifications/reducers';
import ui from './ui/reducers';

import { isDevelopmentEnv } from 'lib/misc/misc';
import dashboardScreen from 'screens/Report/redux/reducers';

export const rootReducer = combineReducers({
  localize,
  ui,
  navigation,
  notifications,
  dashboardScreen,
});

const loggerMiddleware = createLogger();

export function configureStore(preloadedState) {
  let appliedMiddleware;
  let composeEnhancers;
  if (isDevelopmentEnv()) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    appliedMiddleware = applyMiddleware(thunkMiddleware, loggerMiddleware);
  } else {
    composeEnhancers = compose;
    appliedMiddleware = applyMiddleware(thunkMiddleware);
  }

  return createStore(rootReducer, preloadedState, composeEnhancers(appliedMiddleware));
}

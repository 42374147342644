import { Card, CardActions, CardContent, Divider, FormControlLabel, Switch } from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';

import MyDialog from 'components/MyDialog/MyDialog';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import { FunctionalityName } from 'lib/access/constants';
import { FunctionalityNameT } from 'lib/access/types';
import { useTranslate } from 'lib/localization/localization';
import { DispatchDraftObjectFn } from 'lib/misc/useDraft/useDraft';
import { FeatureSettings } from 'screens/Institute/lib/types';
import { DialogKeys } from 'utils/constants';

const translationPath = 'adminInstituteScreen.featuresCard';

const BASIC_FEATURES_NAME = 'basicFeatures' as const;

const useStyles = makeStyles(theme => ({
  section: {
    paddingTop: theme.spacing(8),
  },
  text: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface FeatureSwitchProps {
  featureName: FunctionalityNameT;
  isFeatureAvailable: boolean;
  handleSwitchAvailability: (featureName: FunctionalityNameT, isAvailable: boolean) => void;
}

const FeatureSwitch = ({
  featureName,
  handleSwitchAvailability,
  isFeatureAvailable,
}: FeatureSwitchProps) => {
  const translate = useTranslate();

  return (
    <FormControlLabel
      control={
        <Switch
          checked={isFeatureAvailable}
          onChange={e => handleSwitchAvailability(featureName, e.target.checked)}
          color='primary'
        />
      }
      labelPlacement='start'
      label={translate(`${translationPath}.feature.${featureName}`)}
    />
  );
};

interface FeatureDetailsProps {
  featureName: FunctionalityNameT | typeof BASIC_FEATURES_NAME;
  isFeatureAvailable: boolean;
}

const FeatureDetails = ({ featureName, isFeatureAvailable }: FeatureDetailsProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.text}>
        <SimpleText
          translateId={
            featureName === BASIC_FEATURES_NAME
              ? `${translationPath}.${featureName}`
              : `${translationPath}.feature.${featureName}`
          }
          variant='h3'
          color={isFeatureAvailable ? 'primary' : undefined}
        />

        <SimpleText
          translateId={`${translationPath}.${isFeatureAvailable ? 'enabled' : 'disabled'}`}
          variant='body1'
        />
      </div>
      <Divider />
    </>
  );
};

interface FeaturesCardProps {
  draftFeatures?: FeatureSettings;
  isForAdmin?: boolean;
  dispatchDraftFeatures?: DispatchDraftObjectFn<FeatureSettings>;
}

const ADMIN_VISIBLE_FEATURES = [
  FunctionalityName.SHARING,
  FunctionalityName.BACKTEST,
  FunctionalityName.RESEARCH,
  FunctionalityName.ANALYSIS,
  FunctionalityName.REPORTING,
  FunctionalityName.DOUBLE_ALERT,
  FunctionalityName.CERTIFICATES,
  FunctionalityName.ONLY_RISK_TARGET,
] as const;

const VISIBLE_FEATURES = [
  FunctionalityName.RESEARCH,
  FunctionalityName.ANALYSIS,
  FunctionalityName.REPORTING,
  FunctionalityName.CERTIFICATES,
] as const;

const FeaturesCard = ({
  isForAdmin = false,
  draftFeatures,
  dispatchDraftFeatures,
}: FeaturesCardProps) => {
  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const visibleFeatureNames = isForAdmin ? ADMIN_VISIBLE_FEATURES : VISIBLE_FEATURES;
  const areAllFeaturesAvailable = visibleFeatureNames.every(
    featureName => draftFeatures?.[featureName]?.available ?? false
  );

  const handleSwitchAvailability = (featureName: FunctionalityNameT, checked: boolean) => {
    if (dispatchDraftFeatures) {
      dispatchDraftFeatures({
        type: 'add',
        key: featureName,
        value: { ...(draftFeatures?.[featureName] ?? {}), available: checked },
      });
    }
  };

  return (
    <>
      <MyDialog
        isOpen={isDialogOpen}
        header={`${translationPath}.upgradeDialog.header`}
        message={`${translationPath}.upgradeDialog.message`}
        notPerformKey={`dialogKey.${DialogKeys.CLOSE}`}
        // performKey={`dialogKey.${DialogKeys.YES_DELETE}`}
        onNotPerformClick={() => setIsDialogOpen(false)}
        // onPerformClick={onDeleteClick}
      />
      <Card style={{ height: '100%' }}>
        <CardContent>
          <SimpleText translateId={`${translationPath}.title`} variant='h2' />
          <Grid container spacing={2} className={classes.section}>
            {!isForAdmin && (
              <Grid item xs={12}>
                <FeatureDetails featureName={BASIC_FEATURES_NAME} isFeatureAvailable />
              </Grid>
            )}
            {visibleFeatureNames.map(featureName => (
              <Grid item xs={12} key={featureName}>
                {isForAdmin ? (
                  <FeatureSwitch
                    featureName={featureName}
                    handleSwitchAvailability={handleSwitchAvailability}
                    isFeatureAvailable={draftFeatures?.[featureName]?.available ?? false}
                  />
                ) : (
                  <FeatureDetails
                    featureName={featureName}
                    isFeatureAvailable={draftFeatures?.[featureName]?.available ?? false}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </CardContent>
        {!isForAdmin && (
          <CardActions>
            <SimpleButton
              translateId={`${translationPath}.upgradeButton`}
              onClick={() => setIsDialogOpen(true)}
              variant='midEmp'
              disabled={areAllFeaturesAvailable}
            />
          </CardActions>
        )}
      </Card>
    </>
  );
};

export default FeaturesCard;

import { Collapse } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import NavbarMenu from './NavbarMenu';
import SideMenu from './SideMenu';

import MyProgress from 'components/MyProgress';
import NotificationSnacksArea from 'components/ScreenBase/NotificationSnacksArea/NotificationSnacksArea';
import { useErrorNotification } from 'lib/notifications/useAlertNotification';
import {
  ChildrenType,
  NameTitlePropsType,
  SearchMenuPropsType,
  ReturnButtonPropsType,
} from 'utils/types';

const styles = theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4rem',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': `inset 0 0 2px ${theme.palette.neutral.light}`,
      borderRadius: 10,
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.neutral.lightEmp,
      outline: '1px solid slategrey',
      borderRadius: 10,
    },
  },
  root: {
    display: 'flex',
    boxSizing: 'content-box',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentNoPadding: {
    padding: 0,
  },
  contentPadding: {
    padding: theme.spacing(0),
  },
  contentNoShift: {
    width: '100%',
  },
  contentShift: {
    width: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  collapsWrapper: {
    position: 'sticky',
    top: 65,
    zIndex: 5,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});
const ScreenBase = ({
  children,
  classes,
  title,
  navButtonsProps,
  noPadding,
  waitWhile,
  nameTitleProps,
  searchMenuProps,
  compareTitleProps,
  compareSearchMenuProps,
  returnButtonProps,
  object,
  titleTranslationId,
}) => {
  const isSideMenuCollapsed = useSelector(state => state.ui.isSideMenuCollapsed);
  const { isError, errorBarElem } = useErrorNotification();

  return (
    <div className={classNames(classes.root)}>
      <NotificationSnacksArea />
      <NavbarMenu
        titleTranslationId={title ? `screenName.${title}` : titleTranslationId}
        nameTitleProps={nameTitleProps}
        navButtonsProps={navButtonsProps}
        searchMenuProps={searchMenuProps}
        compareTitleProps={compareTitleProps}
        compareSearchMenuProps={compareSearchMenuProps}
        returnButtonProps={returnButtonProps}
        object={object}
      />
      <SideMenu />
      <main
        className={classNames(classes.content, {
          [classes.contentShift]: !isSideMenuCollapsed,
          [classes.contentNoShift]: isSideMenuCollapsed,
        })}
      >
        <div className={classes.drawerHeader} />
        <Collapse className={classes.collapsWrapper} in={Boolean(isError)}>
          {errorBarElem}
        </Collapse>
        <div className={noPadding ? classes.contentNoPadding : classes.contentPadding}>
          {waitWhile ? <MyProgress /> : children}
        </div>
      </main>
    </div>
  );
};

ScreenBase.propTypes = {
  navButtonsProps: PropTypes.arrayOf(PropTypes.object),
  children: ChildrenType.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  titleTranslationId: PropTypes.string,
  noPadding: PropTypes.bool,
  waitWhile: PropTypes.bool,
  nameTitleProps: NameTitlePropsType,
  searchMenuProps: SearchMenuPropsType,
  compareTitleProps: NameTitlePropsType,
  compareSearchMenuProps: SearchMenuPropsType,
  returnButtonProps: ReturnButtonPropsType,
  object: PropTypes.objectOf(PropTypes.any),
};

ScreenBase.defaultProps = {
  navButtonsProps: [],
  noPadding: false,
  waitWhile: false,
  nameTitleProps: null,
  searchMenuProps: null,
  compareTitleProps: null,
  compareSearchMenuProps: null,
  returnButtonProps: null,
  object: null,
  title: null,
  titleTranslationId: null,
};

export default withStyles(styles)(ScreenBase);

import { MoreVert, Delete, Create } from '@mui/icons-material';
import { IconButton, Toolbar, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import RestToRight from 'components/RestToRight';
import useEditPermission from 'lib/access/useEditPermission';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { toggleEdit } from 'screens/Report/redux/actions';
import { ClassesType } from 'utils/types';

const styles = () => ({
  content: {
    position: 'absolute',
    right: '0',
    top: '0',
    margin: '0',
    padding: '0',
    '@media print': {
      display: 'none',
    },
    zIndex: 10,
  },
  menu: {
    '& ul': {
      border: '1px solid #d3d4d5',
    },
  },
});

const WidgetToolbar = ({ classes, onEdit, onDelete }) => {
  const editMode = useSelector(state => state.dashboardScreen.editMode);
  const dispatch = useDispatch();

  const { portfolioId } = useParams();
  const { portfolio } = usePortfolioQuery(portfolioId);
  const hasEditPermission = useEditPermission(portfolio);

  const [anchorEl, setAnchorEl] = useState(null);

  const switchEdit = () => {
    if (!editMode) {
      dispatch(toggleEdit(true));
    }
  };

  if (!hasEditPermission) {
    return null;
  }
  return (
    <Toolbar className={classes.content}>
      {editMode ? (
        <>
          <IconButton
            style={{
              position: 'absolute',
              margin: '0',
              padding: '0',
              top: '0.2rem',
              right: '1.7rem',
            }}
            onClick={onEdit}
            size='large'
          >
            <Create fontSize='small' />
          </IconButton>
          <IconButton
            style={{
              position: 'absolute',
              margin: '0',
              padding: '0',
              top: '0.2rem',
              right: '0.3rem',
            }}
            onClick={onDelete}
            size='large'
          >
            <Delete fontSize='small' />
          </IconButton>
        </>
      ) : (
        <>
          <RestToRight />
          <IconButton
            className={classes.content}
            aria-controls='action-menu'
            aria-haspopup='true'
            onClick={e => setAnchorEl(e.currentTarget)}
            size='large'
          >
            <MoreVert />
          </IconButton>
          <Menu
            id='action-menu'
            className={classes.menu}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={onDelete}>
              <ListItemIcon>
                <Delete fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Delete' />
            </MenuItem>
            <MenuItem
              onClick={() => {
                onEdit();
                switchEdit();
              }}
            >
              <ListItemIcon>
                <Create fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Edit' />
            </MenuItem>
          </Menu>
        </>
      )}
    </Toolbar>
  );
};

WidgetToolbar.propTypes = {
  classes: ClassesType.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default withStyles(styles)(WidgetToolbar);

import Fuse from 'fuse.js';
import _ from 'lodash';

import { Order } from 'utils/constants';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const pairOrder = cmp(a[0], b[0]);
    if (pairOrder !== 0) return pairOrder;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export function getSorting(order, orderBy) {
  const multiplier = order === Order.DESC ? 1 : -1;
  return (a, b) => multiplier * desc(a, b, orderBy);
}

const generateFuseOptions = searchKeys => ({
  caseSensitive: false,
  shouldSort: true,
  tokenize: false,
  matchAllTokens: false,
  findAllMatches: true,
  includeScore: false,
  includeMatches: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 0,
  keys: searchKeys,
});

export function fuseSearch(objects, searchValue, searchKeys) {
  let objArray;
  if (!_.isArray(objects)) {
    objArray = _.values(objects);
  } else {
    objArray = objects;
  }
  if (searchValue) {
    const fuse = new Fuse(objArray, generateFuseOptions(searchKeys));
    const fuseSearchResults = fuse.search(searchValue);
    return fuseSearchResults.map(obj => obj.item);
  }
  return objArray;
}

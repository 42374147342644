import React from 'react';

import MyDialog from 'components/MyDialog/MyDialog';
import { useTranslate } from 'lib/localization/localization';
import { Id } from 'lib/types';
import PortfolioWarning from 'screens/Portfolio/lib/components/PortfolioWarning';
import { PortfolioAction, PortfolioAllocationType } from 'screens/Portfolio/lib/constants';
import { getTranslateIdTarget } from 'screens/Portfolio/lib/helpers';
import { useOptimizationState } from 'screens/Portfolio/lib/hooks/portfolio';
import { usePortfolioActionMutation } from 'screens/Portfolio/lib/queries';
import { OptimPortfolioAllocationTypeT } from 'screens/Portfolio/lib/types';
import { DialogKeys } from 'utils/constants';

interface ClosestPortfolioWarningProps {
  portfolioId: Id | undefined;
  allocationType: OptimPortfolioAllocationTypeT;
}

const ClosestPortfolioWarning = ({ portfolioId, allocationType }: ClosestPortfolioWarningProps) => {
  const translate = useTranslate();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { optimizationState } = useOptimizationState(portfolioId, allocationType);
  const { mutate: applyAction } = usePortfolioActionMutation(portfolioId);
  const isTargetRisk = optimizationState?.strategy?.isTargetRisk ?? true;
  const actualTargetValue = isTargetRisk
    ? optimizationState?.optimizationResult?.cvar
    : optimizationState?.optimizationResult?.ret;
  const targetName = translate(getTranslateIdTarget(isTargetRisk));
  const translateDataMessage = {
    targetName,
    strategyValue: ((optimizationState?.strategy?.targetValue ?? 0) * 100).toFixed(2),
  };
  const translateDataDetails = {
    targetName,
    actualValue: ((actualTargetValue ?? 0) * 100).toFixed(2),
  };
  const allocTypeToTranslationPath = {
    [PortfolioAllocationType.OPTIMIZED]: 'portfolioLib.closestPortfolioWarning',
    [PortfolioAllocationType.OPTIMIZED_NEW]: 'portfolioLib.closestPortfolioWarningNew',
  };
  const allocTypeToAction = {
    [PortfolioAllocationType.OPTIMIZED]: PortfolioAction.APPLY_CLOSEST,
    [PortfolioAllocationType.OPTIMIZED_NEW]: PortfolioAction.APPLY_CLOSEST_NEW,
  };
  return (
    <>
      <MyDialog
        isOpen={isDialogOpen}
        header={`${allocTypeToTranslationPath[allocationType]}.confirmationDialog.header`}
        message={`${allocTypeToTranslationPath[allocationType]}.confirmationDialog.message`}
        messageData={translateDataDetails}
        notPerformKey={`dialogKey.${DialogKeys.CANCEL}`}
        performKey={`dialogKey.${DialogKeys.CONFIRM}`}
        onNotPerformClick={() => setIsDialogOpen(false)}
        onPerformClick={() => {
          applyAction(allocTypeToAction[allocationType]);
          setIsDialogOpen(false);
        }}
      />
      <PortfolioWarning
        translationPath={allocTypeToTranslationPath[allocationType]}
        translateDataDetails={translateDataDetails}
        translateDataMessage={translateDataMessage}
        onClick={() => {
          if (allocationType === PortfolioAllocationType.OPTIMIZED) {
            setIsDialogOpen(true);
          } else {
            applyAction(allocTypeToAction[allocationType]);
          }
        }}
      />
    </>
  );
};

export default ClosestPortfolioWarning;

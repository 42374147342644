import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ResourceName } from 'lib/constants';
import { removeObjectFromArray } from 'lib/misc/misc';
import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import {
  del,
  get,
  selectListToDict,
  useCreateMutationCommon,
  useDeleteMutationCommon,
  useObjectQueryCommon,
  useObjectsQueryCommon,
  useUpdateMutationCommon,
} from 'lib/queries';
import { addError, addSmallNotification } from 'redux/notifications/actions';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';
import { SmallNotification } from 'utils/constants';

const researchListKeys = {
  all: [{ scope: ResourceName.RESEARCH_LIST }],
  lists: () => [{ ...researchListKeys.all[0], entity: 'list' }],
  list: ({ filter = 'all', instituteId }) => [
    { ...researchListKeys.lists()[0], filter, instituteId },
  ],
  details: () => [{ ...researchListKeys.all[0], entity: 'detail' }],
  detail: ({ id }) => [{ ...researchListKeys.details()[0], id }],
};

export const researchConstraintListKeys = {
  all: [{ scope: ResourceName.RESEARCH_CONSTRAINT_LIST }],
  lists: () => [{ ...researchConstraintListKeys.all[0], entity: 'list' }],
  list: ({ filter = 'all', instituteId }) => [
    { ...researchConstraintListKeys.lists()[0], filter, instituteId },
  ],
  details: () => [{ ...researchConstraintListKeys.all[0], entity: 'detail' }],
  detail: ({ id }) => [{ ...researchConstraintListKeys.details()[0], id }],
};

const baseUrlResearchList = 'research_lists/';
const baseUrlResearchConstraintList = 'research_constraint_lists/';

async function getResearchConstraintLists({ queryKey: [{ instituteId }] }) {
  return get(baseUrlResearchConstraintList, { 'institute-id': instituteId });
}

async function deleteResearchList(id) {
  return del(`${baseUrlResearchList}${id}/`);
}

const selectPredefined = data => {
  return selectListToDict(data.filter(obj => obj.isPredefined));
};

export const useResearchListsQuery = () => {
  const query = useObjectsQueryCommon(baseUrlResearchList, researchListKeys.list);
  return {
    ...query,
    researchLists: query.data || {},
  };
};

export const useResearchListQuery = id => {
  const query = useObjectQueryCommon(id, baseUrlResearchList, researchListKeys.detail, {
    queryKeyList: researchListKeys.list,
  });
  return {
    ...query,
    researchList: query.data,
  };
};

export const useCreateResearchListMutation = () => {
  return useCreateMutationCommon(baseUrlResearchList, {
    notification: SmallNotification.RESEARCH_LIST_CREATED,
    queryKeyLists: researchListKeys.lists(),
    queryKeyDetails: researchListKeys.details(),
  });
};

export const useUpdateResearchListMutation = () => {
  return useUpdateMutationCommon(baseUrlResearchList, {
    notification: SmallNotification.RESEARCH_LIST_SAVED,
    queryKeyLists: researchListKeys.lists(),
    queryKeyDetails: researchListKeys.details(),
    queryKeyInvalidate: researchConstraintListKeys.all,
  });
};

export const useDeleteResearchListMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mutation = useMutation(id => deleteResearchList(id), {
    onSuccess: (data, id) => {
      queryClient.setQueriesData(researchListKeys.lists(), prev => removeObjectFromArray(prev, id));
      queryClient.invalidateQueries(researchConstraintListKeys.all);
      dispatch(addSmallNotification(SmallNotification.RESEARCH_LIST_DELETED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
  return { ...mutation, delete: mutation.mutate };
};

export const useEveryResearchListMutation = () => {
  const createMutation = useCreateResearchListMutation();
  const updateMutation = useUpdateResearchListMutation();
  const deleteMutation = useDeleteResearchListMutation();
  const isLoading =
    createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading;
  return {
    createResearchList: createMutation.mutate,
    updateResearchList: updateMutation.mutate,
    deleteResearchList: deleteMutation.mutate,
    isLoading,
  };
};

export const useResearchConstraintListsQuery = ({ filter = 'all' } = {}) => {
  const { instituteId } = useCurrentInstitute();
  const query = useQuery(
    researchConstraintListKeys.list({ filter, instituteId }),
    getResearchConstraintLists,
    {
      select: filter === 'predefined' ? selectPredefined : selectListToDict,
    }
  );
  return {
    ...query,
    researchConstraintLists: query.data || {},
  };
};

export const useResearchConstraintListQuery = id => {
  const query = useObjectQueryCommon(
    id,
    baseUrlResearchConstraintList,
    researchConstraintListKeys.detail,
    {
      queryKeyList: researchConstraintListKeys.list,
    }
  );
  return {
    ...query,
    researchConstraintList: query.data,
  };
};

export const useCreateResearchConstraintListMutation = () => {
  return useCreateMutationCommon(baseUrlResearchConstraintList, {
    notification: SmallNotification.RESEARCH_CONSTRAINT_LIST_CREATED,
    queryKeyLists: researchConstraintListKeys.lists(),
    queryKeyDetails: researchConstraintListKeys.details(),
  });
};

export const useUpdateResearchConstraintListMutation = () => {
  return useUpdateMutationCommon(baseUrlResearchConstraintList, {
    notification: SmallNotification.RESEARCH_CONSTRAINT_LIST_SAVED,
    queryKeyLists: researchConstraintListKeys.lists(),
    queryKeyDetails: researchConstraintListKeys.details(),
  });
};

export const useDeleteResearchConstraintListMutation = () => {
  return useDeleteMutationCommon(baseUrlResearchConstraintList, {
    notification: SmallNotification.RESEARCH_CONSTRAINT_LIST_DELETED,
    queryKeyLists: researchConstraintListKeys.lists(),
    queryKeyDetails: researchConstraintListKeys.details(),
  });
};

export const useEveryResearchConstraintListMutation = () => {
  const createMutation = useCreateResearchConstraintListMutation();
  const updateMutation = useUpdateResearchConstraintListMutation();
  const deleteMutation = useDeleteResearchConstraintListMutation();
  const isLoading =
    createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading;
  return {
    createResearchConstraintList: createMutation.mutate,
    updateResearchConstraintList: updateMutation.mutate,
    deleteResearchConstraintList: deleteMutation.mutate,
    isLoading,
  };
};

import StarIcon from '@mui/icons-material/Star';
import { IconButton, Paper, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import MyDialog from 'components/MyDialog/MyDialog';
import MyFlippingCard from 'components/MyFlippingCard';
import MyMenu from 'components/MyMenu';
import MyProgress from 'components/MyProgress';
import MySimpleLineChart from 'components/MySimpleLineChart';
import SimpleText from 'components/SimpleText';
import useEditPermission from 'lib/access/useEditPermission';
import { generateSearchPath } from 'lib/navigation/navigation';
import { get30DayCumReturn } from 'lib/timeSeries/tsData';
import {
  hasAlerts,
  makeLineChartDataForPortfolioCard,
} from 'screens/Portfolio/ChoosePortfoliosScreen/helpers';
import PortfolioMessages from 'screens/Portfolio/lib/components/PortfolioMessages';
import { PortfolioTabName } from 'screens/Portfolio/lib/constants';
import { isPortfolioBusy, isPortfolioTracked } from 'screens/Portfolio/lib/helpers';
import {
  useDeletePortfolioMutation,
  useFavoritePortfolioMutation,
} from 'screens/Portfolio/lib/queries';
import { PortfolioOverview } from 'screens/Portfolio/lib/types';
import { Routes, PORTFOLIO_CARD_HEIGHT, DialogKeys } from 'utils/constants';
import { PortfolioType } from 'utils/types';

const GRAPH_HEIGHT = 185;

const useStyles = makeStyles(theme => ({
  root: {
    height: PORTFOLIO_CARD_HEIGHT,
    backgroundColor: theme.palette.background.paper,
  },
  rootWithOpacity: {
    height: PORTFOLIO_CARD_HEIGHT,
    backgroundColor: theme.palette.background.paper,
    opacity: 0.7,
  },
  graph: {
    height: `${GRAPH_HEIGHT}px`,
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
  },
  graphSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  graphFail: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  name: {
    position: 'relative',
    paddingTop: '0.5rem',
    paddingBottom: '0.75rem',
    paddingLeft: '0.75rem',
    width: '17rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    float: 'left',
    zIndex: 4,
    whiteSpace: 'nowrap',
  },
  starIcon: {
    position: 'absolute',
    float: 'right',
    zIndex: 4,
    top: theme.spacing(1),
    right: theme.spacing(10),
  },
  moreVertIcon: {
    position: 'absolute',
    float: 'right',
    zIndex: 5,
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  isFavorite: {
    fill: theme.palette.bookmark.main,
  },
  chart: {
    paddingTop: '0rem',
    height: `${GRAPH_HEIGHT}px`,
  },
  chartTitle: {
    position: 'absolute',
    paddingTop: '2.3rem',
    paddingLeft: '1rem',
  },
  lastRebalance: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    color: theme.palette.text.primary,
  },
  nameText: {
    width: '16rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  errorContainer: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(5),
    marginBottom: theme.spacing(4),
  },
}));

const PortfolioCard = ({ portfolio }: { portfolio: PortfolioOverview }) => {
  const history = useHistory();
  const classes = useStyles();
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const hasEditPermission = useEditPermission(portfolio);
  const alertsExist = hasAlerts(portfolio);

  const chartData = useMemo(() => makeLineChartDataForPortfolioCard(portfolio), [portfolio]);

  const { mutate: deletePortfolio } = useDeletePortfolioMutation();
  const { mutate: switchFavorite } = useFavoritePortfolioMutation(portfolio.id);
  const onDeleteClick = () => {
    setIsOpenDeleteDialog(false);
    deletePortfolio(portfolio.id);
  };
  const onCardReconfigureClick = () => {
    history.push(generatePath(Routes.PORTFOLIO_RECONFIGURATION, { id: portfolio.id }));
  };
  const onCardRebalanceClick = () => {
    history.push(
      generateSearchPath(Routes.PORTFOLIO, {
        params: { id: portfolio.id },
        searchParams: { tab: PortfolioTabName.REBALANCE },
      })
    );
  };
  const onCardDoubleClick = () => {
    history.push(generatePath(Routes.PORTFOLIO, { id: portfolio.id }));
  };

  const dateLastRebalanced = portfolio.lastRebalancedOn;
  const { cumReturnString } = useMemo(
    () => get30DayCumReturn(chartData.main.series),
    [chartData.main.series]
  );

  const menuItemProps = [
    {
      translateId: 'portfolioCard.viewDetails',
      onClick: () => onCardDoubleClick(),
    },
    {
      translateId: 'portfolioCard.reconfigure',
      onClick: () => onCardReconfigureClick(),
    },
  ];
  if (alertsExist) {
    menuItemProps.push({
      translateId: 'portfolioCard.rebalance',
      onClick: () => onCardRebalanceClick(),
    });
  }
  if (hasEditPermission) {
    menuItemProps.push({
      translateId: 'portfolioCard.delete',
      onClick: () => setIsOpenDeleteDialog(true),
    });
  }

  const isInProgress = isPortfolioBusy(portfolio);

  let ChartAreaContent = null;
  if (isInProgress) {
    ChartAreaContent = <MyProgress size='2rem' />;
  } else if (chartData.main.series.length > 1) {
    ChartAreaContent = (
      <div className={classes.chart}>
        <div className={classes.chartTitle}>
          <SimpleText text='%' variant='caption' />
        </div>
        <MySimpleLineChart padding={10} data={chartData.main.series} height={160} />
      </div>
    );
  }
  const frontContent = (
    <Paper
      onDoubleClick={isInProgress ? undefined : () => onCardDoubleClick()}
      className={isInProgress ? classes.rootWithOpacity : classes.root}
    >
      <div
        className={
          alertsExist
            ? classNames(classes.graph, classes.graphFail)
            : classNames(classes.graph, classes.graphSuccess)
        }
      >
        <div>
          <div className={classes.name}>
            <SimpleText
              className={classes.nameText}
              text={portfolio.name}
              variant='h2'
              color='inherit'
            />
          </div>

          {isInProgress ? null : (
            <div>
              <IconButton
                className={classNames([classes.starIcon])}
                color='inherit'
                onClick={() => switchFavorite(!portfolio.isFavorite)}
                size='large'
              >
                <StarIcon className={portfolio.isFavorite ? classes.isFavorite : undefined} />
              </IconButton>
              <MyMenu
                className={classes.moreVertIcon}
                menuItems={menuItemProps}
                toolTip='showOptions'
              />
            </div>
          )}
        </div>
        {ChartAreaContent}
      </div>
      <div className={classes.lastRebalance}>
        {dateLastRebalanced ? (
          <SimpleText
            translateId={`portfolioCard.${'lastRebalance'}`}
            translateData={{ date: format(Date.parse(dateLastRebalanced), 'yyyy-MM-dd') }}
          />
        ) : (
          <SimpleText
            translateId={`portfolioCard.${
              isPortfolioTracked(portfolio) ? 'lastRebalanceNever' : 'notTracked'
            }`}
          />
        )}
      </div>
      <Grid container className={classes.errorContainer}>
        <Grid item>
          <PortfolioMessages portfolio={portfolio} />
        </Grid>
      </Grid>
      {cumReturnString ? (
        <Stack
          direction='row'
          alignItems='flex-end'
          spacing={2}
          sx={{ mb: 2, ml: 5, position: 'fixed', bottom: 0 }}
        >
          <SimpleText text={cumReturnString} variant='h5' />
          <SimpleText
            translateId={`portfolioCard.${
              isPortfolioTracked(portfolio) ? 'inTheLastMonth' : 'inTheLastMonthOfBacktest'
            }`}
            variant='body1'
          />
        </Stack>
      ) : null}
    </Paper>
  );

  return (
    <React.Fragment>
      <MyFlippingCard
        isFlipped={false}
        frontContent={frontContent}
        height={PORTFOLIO_CARD_HEIGHT}
      />
      <MyDialog
        isOpen={isOpenDeleteDialog}
        header='portfolioScreen.useSavePromptAndDialog.dialogHeader.delete'
        message='portfolioScreen.useSavePromptAndDialog.dialogMessage.delete'
        notPerformKey={`dialogKey.${DialogKeys.CANCEL}`}
        performKey={`dialogKey.${DialogKeys.YES_DELETE}`}
        onNotPerformClick={() => setIsOpenDeleteDialog(false)}
        onPerformClick={onDeleteClick}
      />
    </React.Fragment>
  );
};

PortfolioCard.propTypes = {
  portfolio: PortfolioType.isRequired,
};

export default PortfolioCard;

import { AssetAllocation } from 'screens/Environment/lib/types';

export function createGroupConstraints(assetAllocation: AssetAllocation) {
  return assetAllocation.assetGroups.map(assetGroupId => {
    return {
      assetGroup: assetGroupId,
      minimum: 0,
      maximum: 1,
    };
  });
}

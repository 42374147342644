import { nanoid } from 'nanoid';
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ResourceName } from 'lib/constants';
import { replaceObjectInArray } from 'lib/misc/misc';
import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import { get, post, put, selectListToDict, useDeleteMutationCommon } from 'lib/queries';
import { Id, Ids, ValueOf } from 'lib/types';
import { addError, addSmallNotification } from 'redux/notifications/actions';
import { groupConstraintListKeys } from 'screens/AssetAllocation/lib/queries';
import { GroupConstraintList } from 'screens/AssetAllocation/lib/types';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';
import { PortfolioAction, PortfolioActionToNotification } from 'screens/Portfolio/lib/constants';
import { useMatchPortfolioRoute } from 'screens/Portfolio/lib/hooks/useMatch';
import {
  GetDerivativesForPortfolio,
  GetManyPortfolio,
  GetManyPortfolioStrategy,
  GetPortfolio,
  GetPortfolioAllocation,
  GetPortfolioHistory,
  GroupAlert,
  IndividualAlert,
  OptimizationResult,
  Portfolio,
  PortfolioAlerts,
  PortfolioBase,
  PortfolioHistory,
  PortfolioOverview,
  PortfolioOverviews,
  PortfolioStrategy,
  PortfolioStrategyDraft,
  PutGroupConstraintList,
  PutPortfolioStrategy,
  PutResearchConstraintList,
  WeightsAllocation,
} from 'screens/Portfolio/lib/types';
import { researchConstraintListKeys } from 'screens/Research/lib/queries';
import { ResearchConstraintList } from 'screens/Research/lib/types';
import { userGroupsKeys } from 'screens/UserGroup/lib/queries';
import { SmallNotification, SocketioEvent } from 'utils/constants';

interface ListParams {
  filter?: string;
  instituteId: Id;
}
interface DetailParams {
  id: Id;
}

const portfoliosKeys = {
  all: [{ scope: ResourceName.PORTFOLIO }] as const,
  lists: () => [{ ...portfoliosKeys.all[0], entity: 'list' }] as const,
  list: ({ filter = 'all', instituteId }: ListParams) =>
    [{ ...portfoliosKeys.lists()[0], filter, instituteId }] as const,
  details: () => [{ ...portfoliosKeys.all[0], entity: 'detail' }] as const,
  detail: ({ id }: DetailParams) => [{ ...portfoliosKeys.details()[0], id }] as const,
  derivatives: ({ id }: DetailParams) =>
    [{ ...portfoliosKeys.all[0], entity: 'derivatives', id }] as const,
};

const portfolioHistoriesKeys = {
  all: [{ scope: ResourceName.PORTFOLIO_HISTORY }] as const,
  details: () => [{ ...portfolioHistoriesKeys.all[0], entity: 'detail' }] as const,
  detail: ({ portfolioId }: { portfolioId: Id }) =>
    [{ ...portfolioHistoriesKeys.details()[0], portfolioId }] as const,
};

const baseUrl = 'portfolios/';

async function getPortfolio({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<typeof portfoliosKeys.detail>>): Promise<GetPortfolio> {
  return get(`${baseUrl}${id}/`);
}

async function getPortfolios({
  queryKey: [{ instituteId }],
}: QueryFunctionContext<ReturnType<typeof portfoliosKeys.list>>): Promise<
  ReadonlyArray<GetManyPortfolio>
> {
  return get(baseUrl, { 'institute-id': instituteId });
}

async function getDerivativesForPortfolio({
  queryKey: [{ id }],
}: QueryFunctionContext<
  ReturnType<typeof portfoliosKeys.derivatives>
>): Promise<GetDerivativesForPortfolio> {
  return get(`${baseUrl}${id}/derivatives/`);
}

async function putPortfolio(id: Id, data: any): Promise<GetPortfolio> {
  return put(`${baseUrl}${id}/`, data);
}

async function replaceAssetToDerivative(
  id: Id,
  data: { assetId: Id; derivativeId: Id | null }
): Promise<GetPortfolio> {
  const finalData = {
    assetId: parseInt(data.assetId, 10),
    derivativeId: data.derivativeId ? parseInt(data.derivativeId, 10) : null,
  };
  return put(`${baseUrl}${id}/replacement/`, finalData);
}

async function postPortfolio(data: any, instituteId: Id): Promise<GetPortfolio> {
  return post(`${baseUrl}`, data, { 'institute-id': instituteId });
}

async function putPortfolioAction(
  id: Id,
  actionType: ValueOf<typeof PortfolioAction>
): Promise<GetPortfolio> {
  return put(`portfolios/${id}/action/`, { typ: actionType });
}

async function getHistory({
  queryKey: [{ portfolioId }],
}: QueryFunctionContext<
  ReturnType<typeof portfolioHistoriesKeys.detail>
>): Promise<GetPortfolioHistory> {
  return get(`portfolios/${portfolioId}/history/`);
}

const selectAlerts = (strategyAPI: GetManyPortfolioStrategy): PortfolioAlerts => {
  const virtualAllocationAPI = strategyAPI.virtualAllocation;
  const corridorAPI = strategyAPI.targetCorridor;
  let targetAlert;
  if (virtualAllocationAPI.isTargetAlert) {
    let diff;
    if (virtualAllocationAPI.targetDiff > 0) {
      diff = virtualAllocationAPI.targetDiff - corridorAPI;
    } else {
      diff = virtualAllocationAPI.targetDiff + corridorAPI;
    }
    targetAlert = {
      diff,
      expectedValue: strategyAPI.targetValue,
      actualValue: strategyAPI.targetValue + diff,
      isTargetRisk: strategyAPI.isTargetRisk,
    };
  }
  const groupAlerts: GroupAlert[] = [];
  virtualAllocationAPI.groupAlerts.forEach(a => {
    if (a.isAlert) {
      const diff = a.isHigher ? a.weightDiff : -a.weightDiff;
      groupAlerts.push({
        diff,
        expectedValue: a.groupWeight - diff,
        actualValue: a.groupWeight,
        assetGroupId: a.assetGroup,
      });
    }
  });
  const individualAlerts: IndividualAlert[] = [];
  virtualAllocationAPI.individualAlerts.forEach(a => {
    if (a.isAlert) {
      const diff = a.isHigher ? a.weightDiff : -a.weightDiff;
      individualAlerts.push({
        diff,
        expectedValue: 0,
        actualValue: diff,
        assetId: a.asset,
        researchConstraintListId: a.researchConstraintList,
        typ: a.typ,
      });
    }
  });

  return {
    targetAlert,
    groupAlerts,
    individualAlerts,
  };
};

export const selectAllocation = (allocationAPI: GetPortfolioAllocation): WeightsAllocation => {
  return {
    weights: allocationAPI.weights.map(w => ({
      weight: w.weight,
      assetId: w.asset,
    })),
  };
};

const selectHistory = (data: GetPortfolioHistory): PortfolioHistory => {
  const points = data.portfolios.map(portfolioAPI => {
    return {
      effectiveDate: portfolioAPI.effectiveDate,
      allocation: selectAllocation(portfolioAPI.virtualAllocation),
      wasRebalanced: portfolioAPI.virtualAllocation.wasRebalanced,
      turnover: portfolioAPI.virtualAllocation.turnover,
    };
  });
  return {
    performance: data.performance,
    tablename: ResourceName.PORTFOLIO_HISTORY,
    points,
  };
};

const selectOptimizationResult = (strategyAPI: GetManyPortfolioStrategy): OptimizationResult => {
  return {
    effectiveDate: strategyAPI.effectiveDate,
    allocation: selectAllocation(strategyAPI.optimAllocation),
    updatedOn: strategyAPI.optimAllocation.updatedOn,
    isClosest: strategyAPI.optimAllocation.isClosest,
    isSolved: strategyAPI.optimAllocation.isSolved,
    isError: strategyAPI.optimAllocation.isError,
    errorMsg: strategyAPI.optimAllocation.errorMsg,
    isOutdated: strategyAPI.isOutdated,
    outdatedOn: strategyAPI.outdatedOn,
    cvar: strategyAPI.optimAllocation.cvar,
    ret: strategyAPI.optimAllocation.ret,
  };
};

const selectPortfolioBase = (portfolioAPI: GetManyPortfolio): PortfolioBase => {
  return {
    id: portfolioAPI.id,
    name: portfolioAPI.name,
    tablename: portfolioAPI.tablename,
    institute: portfolioAPI.institute,
    user: portfolioAPI.user,
    isFavorite: portfolioAPI.isFavorite,
    isSaved: portfolioAPI.isSaved,
    isTracked: portfolioAPI.current.isTracked,
    trackingStartDate: portfolioAPI.current.trackingStartDate ?? undefined,
    lastRebalancedOn: portfolioAPI.lastRebalancedOn,
    createdOn: portfolioAPI.createdOn,
  };
};

const selectPortfolioOverview = (portfolioAPI: GetManyPortfolio): PortfolioOverview => {
  const virtualAllocationAPI = portfolioAPI.current.virtualAllocation;

  let currentState;
  if (virtualAllocationAPI) {
    currentState = {
      effectiveDate: portfolioAPI.current.effectiveDate,
      alerts: selectAlerts(portfolioAPI.current),
      cvar: virtualAllocationAPI.cvar,
      ret: virtualAllocationAPI.ret,
    };
  }

  const history = {
    performance: portfolioAPI.history.performance,
  };

  return {
    ...selectPortfolioBase(portfolioAPI),
    history,
    currentState,
    optimizationState: { optimizationResult: selectOptimizationResult(portfolioAPI.current) },
  };
};

const selectStrategy = (portfolioAPI: GetPortfolio): PortfolioStrategy | undefined => {
  const strategyAPI = portfolioAPI.current;
  // if not yet fully loaded (from /portfolios endpoint)
  if (!portfolioAPI.universe) {
    return undefined;
  }
  return {
    isTargetRisk: strategyAPI.isTargetRisk,
    targetValue: strategyAPI.targetValue,
    targetCorridor: strategyAPI.targetCorridor,
    maxConstraint: strategyAPI.maxConstraint,
    minConstraint: strategyAPI.minConstraint,

    universeId: portfolioAPI.universe,
    basketId: portfolioAPI.basket,
    groupConstraintListsIds: portfolioAPI.groupConstraintLists,
    researchConstraintListsIds: portfolioAPI.researchConstraintLists,
  };
};

const selectNewStrategy = (portfolioAPI: GetPortfolio): PortfolioStrategy | undefined => {
  const strategyAPI = portfolioAPI.new;
  // if not yet fully loaded (from /portfolios endpoint)
  if (!portfolioAPI.universe) {
    return undefined;
  }
  if (strategyAPI) {
    return {
      isTargetRisk: strategyAPI.isTargetRisk,
      targetValue: strategyAPI.targetValue,
      targetCorridor: strategyAPI.targetCorridor,
      maxConstraint: strategyAPI.maxConstraint,
      minConstraint: strategyAPI.minConstraint,

      universeId: portfolioAPI.universe,
      basketId: portfolioAPI.newBasket,
      groupConstraintListsIds: portfolioAPI.newGroupConstraintLists,
      researchConstraintListsIds: portfolioAPI.newResearchConstraintLists,
    };
  }
  return undefined;
};

const selectPortfolio = (portfolioAPI: GetPortfolio): Portfolio => {
  const virtualAllocationAPI = portfolioAPI.current.virtualAllocation;

  let currentState;
  if (virtualAllocationAPI) {
    currentState = {
      effectiveDate: portfolioAPI.current.effectiveDate,
      alerts: selectAlerts(portfolioAPI.current),
      allocation: selectAllocation(virtualAllocationAPI),
      cvar: virtualAllocationAPI.cvar,
      ret: virtualAllocationAPI.ret,
    };
  }

  const history = {
    performance: portfolioAPI.history.performance,
  };
  const optimizationState = {
    optimizationResult: selectOptimizationResult(portfolioAPI.current),
    strategy: selectStrategy(portfolioAPI),
  };
  let newOptimizationState;
  if (portfolioAPI.new) {
    newOptimizationState = {
      optimizationResult: selectOptimizationResult(portfolioAPI.new),
      strategy: selectNewStrategy(portfolioAPI),
    };
  }
  const targetAllocation = portfolioAPI.current.targetAllocation
    ? selectAllocation(portfolioAPI.current.targetAllocation)
    : undefined;

  const jobAPI = portfolioAPI.new?.optimizationJob || portfolioAPI.current?.optimizationJob;
  const job = jobAPI ? { ...jobAPI, portfolioId: portfolioAPI.id } : undefined;
  return {
    ...selectPortfolioBase(portfolioAPI),
    assetsToDerivativesIds: Object.entries(portfolioAPI.assetsToDerivativesIds).reduce(
      (acc, [k, v]) => {
        acc[k.toString()] = v !== null ? v.toString() : null;
        return acc;
      },
      {} as { [key: string]: string | null }
    ),
    history,
    currentState,
    optimizationState,
    newOptimizationState,
    targetAllocation,
    optimizationJob: job,
  };
};

const selectPortfolioOverviews = (data: ReadonlyArray<GetManyPortfolio>): PortfolioOverviews => {
  const portfolios: PortfolioOverview[] = data.map(portfolioAPI => {
    return selectPortfolioOverview(portfolioAPI);
  });
  return selectListToDict(portfolios);
};

export const usePortfoliosQuery = ({ filter = 'all', enabled = true } = {}) => {
  const { instituteId } = useCurrentInstitute();
  const query = useQuery(portfoliosKeys.list({ filter, instituteId }), getPortfolios, {
    select: selectPortfolioOverviews,
    enabled,
  });
  const data = query.data as PortfolioOverviews | undefined;
  const portfolios = data || {};
  return {
    ...query,
    data,
    portfolios,
  };
};

export const usePortfolioQuery = (id: Id | undefined) => {
  const { instituteId } = useCurrentInstitute();
  const queryClient = useQueryClient();

  const query = useQuery(portfoliosKeys.detail({ id } as { id: Id }), getPortfolio, {
    select: selectPortfolio,
    enabled: !!id,
    // @ts-ignore
    placeholderData: () => {
      return queryClient
        .getQueryData<GetManyPortfolio[]>(portfoliosKeys.list({ instituteId }))
        ?.find(p => p.id === id);
    },
  });
  const data = query.data as Portfolio | undefined;
  return {
    ...query,
    portfolio: data,
    data,
  };
};

export const useDerivativesForPortfolioQuery = (id: Id | undefined) => {
  return useQuery(portfoliosKeys.derivatives({ id } as { id: Id }), getDerivativesForPortfolio, {
    enabled: !!id,
  });
};

export const usePortfolioHistoryQuery = (portfolioId: Id | undefined) => {
  const query = useQuery(
    portfolioHistoriesKeys.detail({ portfolioId } as { portfolioId: Id }),
    getHistory,
    {
      select: selectHistory,
      enabled: !!portfolioId,
    }
  );
  const data = query.data as PortfolioHistory | undefined;
  return {
    ...query,
    history: data,
    data,
  };
};

export const useFavoritePortfolioMutation = (id: Id) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation((newIsFavorite: boolean) => putPortfolio(id, { isFavorite: newIsFavorite }), {
    onSuccess: data => {
      queryClient.setQueriesData(portfoliosKeys.list({ instituteId: data.institute }), prev =>
        replaceObjectInArray(prev, data)
      );
      queryClient.setQueriesData(portfoliosKeys.detail({ id: data.id }), data);
      dispatch(
        addSmallNotification(
          data.isFavorite ? SmallNotification.IS_FAVORITE : SmallNotification.NOT_FAVORITE
        )
      );
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const usePortfolioActionMutation = (id: Id | undefined) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    (actionType: ValueOf<typeof PortfolioAction>) => putPortfolioAction(id as Id, actionType),
    {
      onSuccess: (data, actionType) => {
        queryClient.setQueriesData(portfoliosKeys.list({ instituteId: data.institute }), prev =>
          replaceObjectInArray(prev, data)
        );
        queryClient.setQueriesData(portfoliosKeys.detail({ id: data.id }), data);
        queryClient.invalidateQueries(portfolioHistoriesKeys.detail({ portfolioId: data.id }));
        queryClient.invalidateQueries(portfoliosKeys.derivatives({ id: data.id }));
        dispatch(addSmallNotification(PortfolioActionToNotification[actionType]));
      },
      onError: error => {
        dispatch(addError(makeErrorFromHttpResponse(error)));
      },
    }
  );
};

export const useChangePortfolioNameMutation = (id: Id | undefined) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation((newName: string) => putPortfolio(id as Id, { name: newName }), {
    onSuccess: data => {
      queryClient.setQueriesData(portfoliosKeys.list({ instituteId: data.institute }), prev =>
        replaceObjectInArray(prev, data)
      );
      queryClient.setQueriesData(portfoliosKeys.detail({ id: data.id }), data);
      dispatch(addSmallNotification(SmallNotification.NAME_CHANGED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const useReplaceAssetToDerivativeMutation = (id: Id | undefined) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    (data: { assetId: Id; derivativeId: Id | null }) => replaceAssetToDerivative(id as Id, data),
    {
      onSuccess: data => {
        queryClient.setQueriesData(portfoliosKeys.list({ instituteId: data.institute }), prev =>
          replaceObjectInArray(prev, data)
        );
        queryClient.setQueriesData(portfoliosKeys.detail({ id: data.id }), data);
        dispatch(addSmallNotification(SmallNotification.ASSET_REPLACED));
      },
      onError: error => {
        dispatch(addError(makeErrorFromHttpResponse(error)));
      },
    }
  );
};

const convertGroupConstraintListsDraftToPutData = (
  groupConstraintListsDraft: GroupConstraintList[]
) => {
  const groupConstraintLists: Ids = [];
  const groupConstraintListsAnonymous: PutGroupConstraintList[] = [];
  groupConstraintListsDraft.forEach(groupConsList => {
    if (!groupConsList.assetAllocation) {
      return;
    }
    if (groupConsList.isPredefined) {
      groupConstraintLists.push(groupConsList.id);
    } else {
      groupConstraintListsAnonymous.push({
        ...groupConsList,
        noPortfolioUpdate: true,
        name: nanoid(),
      });
    }
  });
  return { groupConstraintListsAnonymous, groupConstraintLists };
};

const convertResearchConstraintListsDraftToPutData = (
  researchConstraintListsDraft: ResearchConstraintList[]
) => {
  const researchConstraintLists: Ids = [];
  const researchConstraintListsAnonymous: PutResearchConstraintList[] = [];
  researchConstraintListsDraft.forEach(consList => {
    if (!consList.researchList) {
      return;
    }
    if (consList.isPredefined) {
      researchConstraintLists.push(consList.id);
    } else {
      researchConstraintListsAnonymous.push({
        ...consList,
        noPortfolioUpdate: true,
        name: nanoid(),
      });
    }
  });
  return { researchConstraintListsAnonymous, researchConstraintLists };
};

const convertStrategyDraftToPutData = (strategy: PortfolioStrategyDraft): PutPortfolioStrategy => {
  return {
    current: {
      isTargetRisk: strategy.isTargetRisk,
      targetCorridor: strategy.targetCorridor,
      targetValue: strategy.targetValue,
      maxConstraint: strategy.maxConstraint,
      minConstraint: strategy.minConstraint,
    },
    universe: strategy.universeId,
    basket: strategy.basketId,
    ...convertGroupConstraintListsDraftToPutData(strategy.groupConstraintLists),
    ...convertResearchConstraintListsDraftToPutData(strategy.researchConstraintLists),
  };
};

export const useUpdatePortfolioStrategyMutation = (id: Id | undefined) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    (strategy: PortfolioStrategyDraft) =>
      putPortfolio(id as Id, convertStrategyDraftToPutData(strategy)),
    {
      onSuccess: data => {
        queryClient.setQueriesData(portfoliosKeys.list({ instituteId: data.institute }), prev =>
          replaceObjectInArray(prev, data)
        );
        queryClient.setQueriesData(portfoliosKeys.detail({ id: data.id }), data);
        queryClient.invalidateQueries(portfoliosKeys.derivatives({ id: data.id }));
        queryClient.invalidateQueries(groupConstraintListKeys.all);
        queryClient.invalidateQueries(researchConstraintListKeys.all);
      },
      onError: error => {
        dispatch(addError(makeErrorFromHttpResponse(error)));
      },
    }
  );
};

export const useCreatePortfolioMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { instituteId } = useCurrentInstitute();
  return useMutation(
    (strategy: PortfolioStrategyDraft) =>
      postPortfolio(convertStrategyDraftToPutData(strategy), instituteId),
    {
      onSuccess: data => {
        queryClient.setQueriesData<GetManyPortfolio[]>(
          portfoliosKeys.list({ instituteId: data.institute }),
          prev => (prev ? [...prev, data] : [data])
        );
        queryClient.setQueriesData(portfoliosKeys.detail({ id: data.id }), data);
        queryClient.invalidateQueries(portfoliosKeys.derivatives({ id: data.id }));
        queryClient.invalidateQueries(groupConstraintListKeys.all);
        queryClient.invalidateQueries(researchConstraintListKeys.all);
        queryClient.invalidateQueries(userGroupsKeys.all);
      },
      onError: error => {
        dispatch(addError(makeErrorFromHttpResponse(error)));
      },
    }
  );
};

export const useDeletePortfolioMutation = () => {
  return useDeleteMutationCommon(baseUrl, {
    notification: SmallNotification.PORTFOLIO_DELETED,
    queryKeyLists: portfoliosKeys.lists(),
    queryKeyDetails: portfoliosKeys.details(),
  });
};

export const useReceivePortfolioWebsockets = (event: ValueOf<typeof SocketioEvent>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const portfolioId = useMatchPortfolioRoute();
  return (data: any) => {
    queryClient.setQueriesData(portfoliosKeys.list({ instituteId: data.institute }), prev =>
      replaceObjectInArray(prev, data)
    );
    queryClient.setQueriesData(portfoliosKeys.detail({ id: data.id }), data);
    queryClient.invalidateQueries(portfolioHistoriesKeys.detail({ portfolioId: data.id }));
    if (event === SocketioEvent.OPTIMIZATION_FINISHED) {
      if (portfolioId === data.id) {
        dispatch(addSmallNotification(SmallNotification.OPTIMIZATION_FINISHED));
      }
    }
    queryClient.invalidateQueries(portfoliosKeys.derivatives({ id: data.id }));
  };
};

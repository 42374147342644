import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect, useMemo } from 'react';

import MyDropdown from 'components/MyDropdown';
import { fuseSearch } from 'components/SearchBar/utils';
import { useDraftObjectList } from 'lib/misc/useDraft/useDraft';
import { Id, Ids } from 'lib/types';
import GroupConstraintSliders from 'screens/AssetAllocation/lib/components/GroupConstraintSliders';
import { createGroupConstraints } from 'screens/AssetAllocation/lib/helpers';
import { useGroupConstraintListsQuery } from 'screens/AssetAllocation/lib/queries';
import {
  GroupConstraint,
  GroupConstraintList,
  GroupConstraintLists,
} from 'screens/AssetAllocation/lib/types';
import { useAssetAllocationsQuery } from 'screens/Environment/lib/queries';
import {
  StrategyCard,
  StrategyCardContent,
  StrategyCardMainSection,
} from 'screens/Portfolio/lib/hooks/useStrategyConfiguration/cards/StrategyCard';

const translationPath = 'portfolioLib.strategyConfiguration.assetAllocationCard' as const;

interface GroupConstraintListCard extends Partial<GroupConstraintList> {
  cardId: string;
}

const groupContstraintListIdsToCards = (
  ids: Ids,
  allGroupConstraintLists: GroupConstraintLists
): GroupConstraintListCard[] => {
  return ids.map(id => {
    return {
      ...allGroupConstraintLists[id],
      cardId: nanoid(),
    };
  });
};

interface AssetAllocationCardProps {
  groupConstraintListCard: GroupConstraintListCard;
  disabled?: boolean;
  forbiddenAllocIds?: Ids;
  onChangeAssetAllocId?: (value: Id) => void;
  onChangeGroupConsListId?: (value: Id) => void;
  onChangeGroupConstraint?: (value: GroupConstraint) => void;
  onAddCard?: () => void;
  onRemoveCard?: () => void;
}

const AssetAllocationCard = ({
  groupConstraintListCard,
  disabled = false,
  onChangeAssetAllocId = undefined,
  onChangeGroupConsListId = undefined,
  onAddCard = undefined,
  onRemoveCard = undefined,
  onChangeGroupConstraint = undefined,
  forbiddenAllocIds = [],
}: AssetAllocationCardProps) => {
  const groupConstraintListId = groupConstraintListCard.id;
  const assetAllocId = groupConstraintListCard.assetAllocation;
  const { assetAllocations, isLoading: isLoadingAssetAllocations } = useAssetAllocationsQuery();
  const { groupConstraintLists, isLoading: isLoadingGroupConstraintLists } =
    useGroupConstraintListsQuery();
  const [searchValueAssetAlloc, setSearchValueAssetAlloc] = React.useState('');
  const [searchValueConsList, setSearchValueConsList] = React.useState('');
  const isLoading = isLoadingAssetAllocations || isLoadingGroupConstraintLists;

  const filteredConstraintLists = _.values(groupConstraintLists).filter(
    consList => consList.assetAllocation === assetAllocId && consList.isPredefined
  );
  const searchedConstraintLists = fuseSearch(filteredConstraintLists, searchValueConsList, [
    'name',
  ]);
  const searchedAssetAllocs = fuseSearch(Object.values(assetAllocations), searchValueAssetAlloc, [
    'name',
  ]).filter(
    assetAlloc => !forbiddenAllocIds.includes(assetAlloc.id) || assetAlloc.id === assetAllocId
  );

  return (
    <StrategyCard
      isLoading={isLoading}
      title={`${translationPath}.title`}
      disabled={disabled}
      onAddCard={onAddCard}
      onRemoveCard={onRemoveCard}
    >
      <StrategyCardMainSection>
        <div>
          <MyDropdown
            // @ts-ignore
            value={assetAllocId}
            onChange={onChangeAssetAllocId}
            onUpdateSearch={setSearchValueAssetAlloc}
            placeholderId='assetAllocation'
            disabled={disabled}
          >
            {searchedAssetAllocs.map(assetAlloc => (
              <MenuItem key={assetAlloc.id} value={assetAlloc.id}>
                {assetAlloc.name}
              </MenuItem>
            ))}
          </MyDropdown>
        </div>

        <div>
          <MyDropdown
            // @ts-ignore
            value={groupConstraintListId}
            onChange={onChangeGroupConsListId}
            onUpdateSearch={setSearchValueConsList}
            placeholderId='preset'
            disabled={disabled || !assetAllocId}
          >
            {searchedConstraintLists.map(consList => (
              <MenuItem key={consList.id} value={consList.id}>
                {consList.name}
              </MenuItem>
            ))}
          </MyDropdown>
        </div>
      </StrategyCardMainSection>
      <StrategyCardContent>
        <GroupConstraintSliders
          draftConstraints={groupConstraintListCard?.constraints || []}
          setDraftConstraint={onChangeGroupConstraint}
          disabled={disabled || groupConstraintListCard.isPredefined || !assetAllocId}
        />
      </StrategyCardContent>
    </StrategyCard>
  );
};

interface UseAssetAllocationCardsProps {
  groupConstraintListIds?: Ids;
  disabled?: boolean;
  isNew?: boolean;
}

const useAssetAllocationCards = ({
  groupConstraintListIds,
  disabled = false,
  isNew = false,
}: UseAssetAllocationCardsProps) => {
  const { groupConstraintLists: allGroupConstraintLists, isLoading: isLoadingConsLists } =
    useGroupConstraintListsQuery();
  const { assetAllocations, isLoading: isLoadingAssetAllocations } = useAssetAllocationsQuery();

  const cards = useMemo(() => {
    return groupConstraintListIds !== undefined
      ? groupContstraintListIdsToCards(groupConstraintListIds, allGroupConstraintLists)
      : [];
  }, [groupConstraintListIds, allGroupConstraintLists]);
  const { draft, setDraftElement, setDraft, removeDraftElementAtKey, addDraftElement } =
    useDraftObjectList(cards, { idPropName: 'cardId' });
  const amountCards = draft.length;
  useEffect(() => {
    const isEmptyListIds =
      groupConstraintListIds !== undefined && groupConstraintListIds.length === 0;
    if ((isEmptyListIds || isNew) && !disabled && draft.length === 0) {
      addDraftElement({
        cardId: nanoid(),
      });
    }
  }, [groupConstraintListIds, addDraftElement, disabled, isNew, draft]);
  const forbiddenAllocIds = draft.map((card: GroupConstraintListCard) => card.assetAllocation);
  const cardElements = draft.map((card: GroupConstraintListCard, index: number) => {
    const handleChangeGroupConstraint = (groupConstraint: GroupConstraint) => {
      const newConstraints = (card.constraints ?? []).map(elem =>
        elem.assetGroup === groupConstraint.assetGroup ? groupConstraint : elem
      );
      setDraftElement({
        ...card,
        constraints: newConstraints,
      });
    };
    const handleChangeAssetAllocId = (id: Id | null) => {
      if (id) {
        const assetAlloc = assetAllocations[id];
        setDraftElement({
          cardId: card.cardId,
          isPredefined: false,
          assetAllocation: id,
          constraints: assetAlloc !== undefined ? createGroupConstraints(assetAlloc) : [],
        });
      } else {
        setDraftElement({
          cardId: card.cardId,
        });
      }
    };
    const handleChangeConsListId = (id: Id) => {
      if (id === null) {
        if (card.assetAllocation) {
          handleChangeAssetAllocId(card.assetAllocation);
        }
      } else {
        setDraftElement({ cardId: card.cardId, ...allGroupConstraintLists[id] });
      }
    };
    const onRemoveCard = () => {
      removeDraftElementAtKey(card.cardId);
    };
    const onAddCard = () => {
      addDraftElement(
        {
          cardId: nanoid(),
        },
        index + 1
      );
    };
    return (
      <AssetAllocationCard
        groupConstraintListCard={card}
        key={card.cardId}
        disabled={disabled}
        forbiddenAllocIds={forbiddenAllocIds}
        onChangeAssetAllocId={handleChangeAssetAllocId}
        onChangeGroupConsListId={handleChangeConsListId}
        onChangeGroupConstraint={handleChangeGroupConstraint}
        onRemoveCard={amountCards > 1 ? onRemoveCard : undefined}
        onAddCard={onAddCard}
      />
    );
  });

  const resetDraft = () => {
    setDraft(undefined);
  };
  return {
    cardElements,
    resetDraft,
    draft,
    isLoading: isLoadingConsLists || isLoadingAssetAllocations,
  };
};

export default useAssetAllocationCards;

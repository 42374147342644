import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import SimpleText from 'components/SimpleText';
import { toDateString } from 'lib/dates/dateUtils';
import useStartEndDatePickers, {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE,
} from 'lib/dates/useStartEndDatePickers';
import { useDraftValue } from 'lib/misc/useDraft/useDraft';
import { ALL_KPIS } from 'lib/performance/constants';
import { AnalysisDataType } from 'screens/Analysis/lib/constants';
import PortfoliosDropdown from 'screens/Backtest/lib/components/PortfoliosDropdown';
import { useAssetsQuery } from 'screens/Environment/lib/queries';
import { useDataTypeDropdowns } from 'screens/Portfolio/lib/hooks/useDataType';
import { useDefineDateLimits } from 'screens/Portfolio/lib/hooks/useDefineDateLimits';
import {
  restoreWidgetConfig,
  updateWidgetConfig,
  updateWidgetSavedValues,
  updateWidgetSize,
} from 'screens/Report/redux/actions';
import ConfigurationFrame from 'screens/Report/ReportScreen/components/ConfigurationFrame';
import useBenchmarkChipSelection from 'screens/Report/ReportScreen/components/hooks/useBenchmarkChipSelection';
import useChipSelection from 'screens/Report/ReportScreen/components/hooks/useChipSelection';
import useSizeDropdown from 'screens/Report/ReportScreen/components/hooks/useSizeDropdown';
import {
  nameToWidgetMapping,
  sizeMappings,
} from 'screens/Report/ReportScreen/configuration/dashboard.config';
import { generateBenchmarkList, generateRowFieldData } from 'screens/Report/utils/helpers';
import { widgetType } from 'utils/types';

const useStyles = makeStyles(theme => ({
  dropdown: {
    paddingTop: theme.spacing(4),
  },
}));

const KPITableConfiguration = ({ closeAction, config }) => {
  const {
    portfolio: portfolioId,
    type,
    benchmarks: defaultBenchmarks,
    startDate,
    endDate,
    backtestId,
    rows: defaultRows,
    size: defaultSize,
  } = config.configuration;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { portfolioId: currentPortfolioId } = useParams();

  const isFirstRun = useRef(true);

  const { assets } = useAssetsQuery();

  const [SizeDropdown, size] = useSizeDropdown(defaultSize.value);

  const defaultColumns = useMemo(() => {
    return generateBenchmarkList(defaultBenchmarks?.value, assets, false);
  }, [assets, defaultBenchmarks.value]);
  const [BenchmarkField, benchmarks] = useBenchmarkChipSelection(defaultColumns);

  const defaultKPIs = useMemo(() => {
    return generateRowFieldData(defaultRows?.value, ALL_KPIS);
  }, [defaultRows?.value]);
  const [RowsField, rows] = useChipSelection(defaultKPIs);

  const { draft: draftPortfolioId, setDraft: setPortfolioId } = useDraftValue(
    portfolioId?.value ?? currentPortfolioId
  );
  const { draft: draftDataType, setDraft: setDraftDataType } = useDraftValue(
    type?.value ?? AnalysisDataType.REAL
  );
  const { draft: draftBacktestId, setDraft: setDraftBacktestId } = useDraftValue(
    backtestId?.value ?? undefined
  );
  const { limitStartDate, limitEndDate } = useDefineDateLimits({
    backtestId: draftBacktestId,
    dataType: draftDataType,
    portfolioId: draftPortfolioId,
  });
  const { draft: draftStartDate, setDraft: setDraftStartDate } = useDraftValue(
    startDate?.value ?? limitStartDate ?? toDateString(DEFAULT_START_DATE)
  );
  const { draft: draftEndDate, setDraft: setDraftEndDate } = useDraftValue(
    endDate?.value ?? limitEndDate ?? toDateString(DEFAULT_END_DATE)
  );

  // TODO separate field update as an individual effects for better performance
  useEffect(() => {
    dispatch(
      updateWidgetConfig(config.id, {
        portfolio: {
          ...config.configuration.portfolio,
          value: draftPortfolioId,
        },
        backtestId: {
          ...config.configuration.backtestId,
          value: draftBacktestId,
        },
        type: {
          ...config.configuration.type,
          value: draftDataType,
        },
        benchmarks: {
          ...config.configuration.benchmarks,
          value: benchmarks
            .filter(benchmark => benchmark.checked)
            .map(benchmark => ({ id: benchmark.key, color: benchmark.color })),
        },
        startDate: {
          ...config.configuration.startDate,
          value: draftStartDate,
        },
        endDate: {
          ...config.configuration.endDate,
          value: draftEndDate,
        },
        rows: {
          ...config.configuration.rows,
          value: rows.filter(item => item.checked).map(item => item.label),
        },
        size: {
          ...config.configuration.size,
          value: size,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    benchmarks,
    config.id,
    rows,
    size,
    draftBacktestId,
    draftDataType,
    draftEndDate,
    draftPortfolioId,
    draftStartDate,
  ]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    dispatch(
      updateWidgetSize(config.id, {
        w: nameToWidgetMapping.KPITableWidget[sizeMappings[size]],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);
  const portfolioDropdownElem = (
    <PortfoliosDropdown onChange={setPortfolioId} portfolioId={draftPortfolioId} />
  );
  const { startDatePickerElem, endDatePickerElem, quickSetElem } = useStartEndDatePickers({
    startDate: draftStartDate,
    endDate: draftEndDate,
    onEndDateChange: setDraftEndDate,
    onStartDateChange: setDraftStartDate,
    limitEndDate,
    limitStartDate,
  });
  const { dataTypeDropdownElem, backtestDropdownElem } = useDataTypeDropdowns({
    dataType: draftDataType,
    backtestId: draftBacktestId,
    onDataTypeChange: newDataType => {
      setDraftDataType(newDataType);
      setDraftStartDate(limitStartDate ?? toDateString(DEFAULT_START_DATE));
      setDraftEndDate(limitEndDate ?? toDateString(DEFAULT_END_DATE));
    },
    onBacktestChange: newBacktestId => {
      setDraftBacktestId(newBacktestId);
      setDraftStartDate(limitStartDate ?? toDateString(DEFAULT_START_DATE));
      setDraftEndDate(limitEndDate ?? toDateString(DEFAULT_END_DATE));
    },
    portfolioId: draftPortfolioId,
    allowedDataTypes: [AnalysisDataType.REAL, AnalysisDataType.BACKTEST],
  });

  useEffect(() => {
    dispatch(updateWidgetSavedValues(config.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ConfigurationFrame
      closeAction={() => {
        dispatch(restoreWidgetConfig(config.id));
        closeAction();
      }}
      saveAction={() => {
        dispatch(updateWidgetSavedValues(config.id));
        closeAction();
      }}
    >
      <div style={{ height: '65vh', overflow: 'auto' }}>
        <div className={classes.dropdown}>{portfolioDropdownElem}</div>
        <div className={classes.dropdown}>{dataTypeDropdownElem}</div>
        {backtestDropdownElem !== undefined && (
          <div className={classes.dropdown}>{backtestDropdownElem}</div>
        )}
        <BenchmarkField translateSource='dashboard' label='benchmarks' useLabelWithoutTranslate />
        <RowsField translateSource='dashboard' label='rows' />
        <SimpleText translateId='dashboard.time' fontWeight='bold' mt={4} />
        <div className={classes.dropdown}>{quickSetElem}</div>
        <SimpleText translateId='dashboard.startDate' fontWeight='bold' mt={4} />
        <div className={classes.dropdown}>{startDatePickerElem}</div>
        <SimpleText translateId='dashboard.endDate' fontWeight='bold' mt={4} />
        <div className={classes.dropdown}>{endDatePickerElem}</div>
        <SizeDropdown />
      </div>
    </ConfigurationFrame>
  );
};

KPITableConfiguration.propTypes = {
  closeAction: PropTypes.func.isRequired,
  config: widgetType.isRequired,
};

export default KPITableConfiguration;

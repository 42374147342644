import { ResearchAction } from 'screens/Research/lib/constants';
import { ResearchList } from 'screens/Research/lib/types';

export function createResearchConstraints(researchList: ResearchList) {
  return researchList?.recommendationTypes?.map(recType => {
    return {
      action: ResearchAction.BUY,
      recommendationType: recType.id,
    };
  });
}

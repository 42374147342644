import { List, ListItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import useComponentVisible from 'screens/Report/ReportScreen/components/hooks/useComponentVisible';
import WidgetCard from 'screens/Report/ReportScreen/components/WidgetCard';
import { nameToWidgetMapping } from 'screens/Report/ReportScreen/configuration/dashboard.config';
import { ClassesType } from 'utils/types';

const styles = theme => ({
  content: {
    maxHeight: '100%',
    position: 'fixed',
    backgroundColor: '#FFFF',
    top: '35%',
    right: '71px',
    marginTop: '-100px',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)',
    padding: theme.spacing(2, 2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 10,
    overflowY: 'auto',
  },
});

const WidgetsHolder = ({
  classes,
  showHolder,
  setSelectedWidget,
  setShowSettings,
  setSettingsType,
}) => {
  const [ref, isComponentVisible] = useComponentVisible(true);

  useEffect(() => {
    if (!isComponentVisible) {
      showHolder(false);
    }
  }, [isComponentVisible, showHolder]);
  return (
    <>
      {isComponentVisible ? (
        <div className={classes.content} ref={ref}>
          <List style={{ overflow: 'auto' }}>
            {Object.values(nameToWidgetMapping).map(({ type, icon }) => (
              <ListItem key={type} disablePadding>
                <WidgetCard
                  IconComponent={icon}
                  type={type}
                  setSelectedWidget={setSelectedWidget}
                  setShowSettings={setShowSettings}
                  setSettingsType={setSettingsType}
                />
              </ListItem>
            ))}
          </List>
        </div>
      ) : null}
    </>
  );
};

WidgetsHolder.propTypes = {
  classes: ClassesType.isRequired,
  showHolder: PropTypes.func.isRequired,
};

export default withStyles(styles)(WidgetsHolder);

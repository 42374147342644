import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import ScreenBase from 'components/ScreenBase/ScreenBase';
import useEditPermission from 'lib/access/useEditPermission';
import { useDraftString } from 'lib/misc/useDraft/useDraft';
import { useSavePromptAndDialog } from 'lib/misc/useSavePromptAndDialog';
import { generateSearchPath } from 'lib/navigation/navigation';
import { Id } from 'lib/types';
import WeightsChartCard from 'screens/Portfolio/lib/components/WeightsChartCard';
import WeightsChartComparisonCard from 'screens/Portfolio/lib/components/WeightsChartComparisonCard';
import WeightsTableCard from 'screens/Portfolio/lib/components/WeightsTableCard';
import {
  PortfolioAction,
  PortfolioAllocationType,
  PortfolioTabName,
} from 'screens/Portfolio/lib/constants';
import { hasNewStrategy } from 'screens/Portfolio/lib/helpers';
import { useMatchNewPortfolioRoute } from 'screens/Portfolio/lib/hooks/useMatch';
import {
  useChangePortfolioNameMutation,
  usePortfolioActionMutation,
  usePortfolioQuery,
} from 'screens/Portfolio/lib/queries';
import ConfigurationDialog from 'screens/Portfolio/PortfolioReconfigurationScreen/components/ConfigurationDialog';
import useToolbar from 'screens/Portfolio/PortfolioReconfigurationScreen/components/useToolbar';
import { Routes } from 'utils/constants';

const translationPath = 'portfolioReconfigurationScreen';

const ALLOWED_ALLOC_TYPES = [
  PortfolioAllocationType.CURRENT,
  PortfolioAllocationType.TARGET,
  PortfolioAllocationType.OPTIMIZED,
];

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    marginTop: theme.spacing(4),
  },
}));

const PortfolioReconfigurationScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isConfigurationOpen, setIsConfigurationOpen] = React.useState(false);
  const { id } = useParams<{ id: Id | undefined }>();
  const { portfolio } = usePortfolioQuery(id);
  const { draft: draftName, setDraft: setDraftName } = useDraftString(portfolio?.name);
  const { mutate: changeName } = useChangePortfolioNameMutation(id);
  const isNew = Boolean(useMatchNewPortfolioRoute());
  const isNewOrNotSaved = isNew || !portfolio?.isSaved;
  useEffect(() => {
    if (isNew) {
      setIsConfigurationOpen(true);
    }
  }, [isNew]);
  const { mutate: applyAction } = usePortfolioActionMutation(id);
  const isPortfolioChanged = portfolio ? hasNewStrategy(portfolio) : false;
  const hasEditAccess = useEditPermission(portfolio);

  const handleReconfigureButtonClick = () => {
    setIsConfigurationOpen(true);
  };
  const handleCloseConfigDialog = () => {
    setIsConfigurationOpen(false);
  };

  const handleReturnButton = () => {
    if (id && !isNewOrNotSaved) {
      history.push(
        generateSearchPath(Routes.PORTFOLIO, {
          params: { id },
          searchParams: { tab: PortfolioTabName.STRATEGY },
        })
      );
    }
    if (isNewOrNotSaved) {
      history.push(Routes.CHOOSE_PORTFOLIO);
    }
  };
  const handleDiscardButton = () => {
    applyAction(PortfolioAction.DISCARD_NEW);
  };
  const handleSaveButton = () => {
    applyAction(PortfolioAction.APPLY_NEW);
  };
  const shouldTrigger = (portfolio !== undefined && isNewOrNotSaved) || isPortfolioChanged;
  const {
    onSaveClickWrapper,
    onDeleteClickWrapper: onDiscardClickWrapper,
    promptAndDialog,
  } = useSavePromptAndDialog(isNewOrNotSaved, shouldTrigger, {
    translationPath,
    onSaveClick: handleSaveButton,
    onDeleteClick: handleDiscardButton,
  });

  const returnButtonProps = {
    translateId: `${translationPath}.${isNewOrNotSaved ? 'returnButtonNew' : 'returnButton'}`,
    onClick: handleReturnButton,
    'pendo-id': 'portfolio-reconfiguration-screen-strategy-nav-button',
  };
  const nameTitleProps = {
    name: draftName,
    onRename: setDraftName,
    onConfirm: changeName,
    startWithEditing: false,
    canRename: hasEditAccess,
  };
  const nameTitlePropsNew = {
    name: 'Portfolio',
    canRename: false,
  };
  const navButtonsPropsSaved = [
    {
      translateId: `${translationPath}.discardButton`,
      onClick: onDiscardClickWrapper,
      variant: 'midEmp',
      disabled: !hasEditAccess || !isPortfolioChanged,
    },
    {
      translateId: `${translationPath}.saveButton`,
      onClick: onSaveClickWrapper,
      variant: 'highEmp',
      disabled: !hasEditAccess || !isPortfolioChanged,
    },
    {
      translateId: `${translationPath}.navButton.next`,
      onClick: () =>
        history.push(generatePath(Routes.CHOOSE_BACKTEST_WITH_PORTFOLIO, { portfolioId: id })),
      variant: 'lowEmp',
    },
  ];
  const navButtonsPropsNotSaved = [
    {
      translateId: `${translationPath}.createButton`,
      onClick: () => {
        applyAction(PortfolioAction.SAVE);
      },
      variant: 'highEmp',
      disabled: isNew,
    },
    {
      translateId: `${translationPath}.createAndTrackButton`,
      onClick: () => {
        applyAction(PortfolioAction.SAVE_AND_TRACK);
      },
      variant: 'highEmp',
      disabled: isNew,
      'pendo-id': 'portfolio-reconfiguration-screen-create-track-button',
    },
  ];

  const {
    Toolbar,
    isCompareOn,
    selectedAllocType,
    selectedPortfolioIdCompare,
    selectedAllocTypeCompare,
  } = useToolbar({
    portfolioId: id,
    compareAllocationType: ALLOWED_ALLOC_TYPES,
    onReconfigureClick: handleReconfigureButtonClick,
  });

  return (
    <ScreenBase
      titleTranslationId={`${translationPath}.${isNewOrNotSaved ? 'titleNew' : 'title'}`}
      returnButtonProps={returnButtonProps}
      nameTitleProps={!isNew ? nameTitleProps : nameTitlePropsNew}
      navButtonsProps={isNewOrNotSaved ? navButtonsPropsNotSaved : navButtonsPropsSaved}
      object={!isNewOrNotSaved ? portfolio : undefined}
    >
      {promptAndDialog}
      <ConfigurationDialog isOpen={isConfigurationOpen} onClose={handleCloseConfigDialog} />
      <div className={classes.content} pendo-id='portfolio-reconfiguration-screen-content'>
        {isCompareOn ? (
          <>
            <Toolbar />

            <div className={classes.card}>
              <WeightsChartComparisonCard
                portfolioId={id}
                allocationType={selectedAllocType}
                portfolioIdCompare={selectedPortfolioIdCompare}
                allocationTypeCompare={selectedAllocTypeCompare}
              />
            </div>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <div className={classes.card}>
                  <WeightsTableCard portfolioId={id} allocationType={selectedAllocType} />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                {selectedPortfolioIdCompare !== undefined &&
                  selectedAllocTypeCompare !== undefined && (
                    <div className={classes.card}>
                      <WeightsTableCard
                        portfolioId={selectedPortfolioIdCompare}
                        allocationType={selectedAllocTypeCompare}
                      />
                    </div>
                  )}
              </Grid>
            </Grid>
          </>
        ) : (
          <div>
            <Toolbar />

            {!isNew && (
              <>
                <div className={classes.card}>
                  <WeightsChartCard portfolioId={id} allocationType={selectedAllocType} />
                </div>
                <div className={classes.card}>
                  <WeightsTableCard portfolioId={id} allocationType={selectedAllocType} />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </ScreenBase>
  );
};
export default PortfolioReconfigurationScreen;

import { Card, CardActions, CardContent, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import MyDialog from 'components/MyDialog/MyDialog';
import MyProgress from 'components/MyProgress';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import useEditPermission from 'lib/access/useEditPermission';
import { toDateString } from 'lib/dates/dateUtils';
import { useTranslate } from 'lib/localization/localization';
import { Id } from 'lib/types';
import { PortfolioAction } from 'screens/Portfolio/lib/constants';
import { usePortfolioQuery, usePortfolioActionMutation } from 'screens/Portfolio/lib/queries';
import { CARD_MIN_HEIGHT } from 'screens/Portfolio/PortfolioScreen/components/PortfolioOverviewTab/cards/constants';
import { DialogKeys, Dialogs } from 'utils/constants';

const translationPath = 'portfolioScreen.portfolioOverview.generalCard' as const;

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    minHeight: CARD_MIN_HEIGHT,
    flexGrow: 1,
  },
  section: {
    paddingTop: theme.spacing(6),
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
}));

const GeneralCard = ({ portfolioId }: { portfolioId: Id }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [isUntrackDialogOpen, setIsUntrackDialogOpen] = React.useState(false);
  const { portfolio, isLoading } = usePortfolioQuery(portfolioId);
  const hasAccess = useEditPermission(portfolio);
  const { mutate: applyAction, isLoading: isLoadingTrack } =
    usePortfolioActionMutation(portfolioId);
  const action = portfolio?.isTracked ? PortfolioAction.CANCEL_TRACK : PortfolioAction.TRACK;

  const handleTrackButton = () => {
    if (portfolio?.isTracked) {
      setIsUntrackDialogOpen(true);
    } else {
      applyAction(action);
    }
  };

  if (isLoading || !portfolio) {
    return (
      <Card>
        <CardContent className={classes.content}>
          <SimpleText translateId={`${translationPath}.title`} variant='h2' />
          <MyProgress />
        </CardContent>
      </Card>
    );
  }
  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <SimpleText translateId={`${translationPath}.title`} variant='h2' />
          <div>
            <div className={classes.section}>
              <SimpleText translateId={`${translationPath}.createdOn`} variant='subtitle1' />
              <SimpleText text={toDateString(portfolio.createdOn)} variant='body1' />
            </div>
            <div className={classes.section}>
              <SimpleText translateId={`${translationPath}.lastRebalancedOn`} variant='subtitle1' />
              <SimpleText
                text={
                  portfolio.lastRebalancedOn
                    ? toDateString(portfolio.lastRebalancedOn)
                    : translate(`${translationPath}.neverRebalanced`)
                }
                variant='body1'
              />
            </div>
            <div className={classes.section}>
              <SimpleText translateId={`${translationPath}.isTracked`} variant='subtitle1' />
              <SimpleText
                text={portfolio.isTracked ? translate('yes') : translate('no')}
                variant='body1'
              />
            </div>
          </div>
        </CardContent>
        <CardActions>
          <SimpleButton
            translateId={
              portfolio.isTracked ? `${translationPath}.stopTracking` : `${translationPath}.track`
            }
            onClick={handleTrackButton}
            variant='midEmp'
            disabled={!hasAccess || isLoadingTrack}
          />
          {isLoadingTrack && <CircularProgress size={24} />}
        </CardActions>
      </Card>
      <MyDialog
        isOpen={isUntrackDialogOpen}
        header={`dialogHeader.${Dialogs.UNTRACK_PORTFOLIO}`}
        message={`dialogMessage.${Dialogs.UNTRACK_PORTFOLIO}`}
        notPerformKey={`dialogKey.${DialogKeys.DISCARD}`}
        performKey={`dialogKey.${DialogKeys.CONFIRM}`}
        onNotPerformClick={() => setIsUntrackDialogOpen(false)}
        onPerformClick={() => {
          applyAction(action);
          setIsUntrackDialogOpen(false);
        }}
      />
    </>
  );
};

export default GeneralCard;

import { useMemo } from 'react';

import useLocalStorage from 'lib/misc/useLocalStorage';

const useCheckedColumns = (
  localStorageId: string,
  defaultColumns: ReadonlyArray<string>,
  all_columns: ReadonlyArray<string>
) => {
  const [checkedColumns, setCheckedColumns] = useLocalStorage(localStorageId, defaultColumns);
  const checkedColumnsSorted = useMemo(() => {
    return all_columns.filter(column => checkedColumns.includes(column));
  }, [all_columns, checkedColumns]);
  const handleColumnCheck = (checkedColumn: string) => {
    if (checkedColumns.includes(checkedColumn)) {
      setCheckedColumns(checkedColumns.filter((c: string) => c !== checkedColumn));
    } else {
      setCheckedColumns([...checkedColumns, checkedColumn]);
    }
  };

  return {
    checkedColumns: checkedColumnsSorted,
    handleColumnCheck,
  };
};

export default useCheckedColumns;

import { AssetAllocationKind } from 'screens/Environment/lib/constants';
import { NAVBAR_HEIGHT } from 'utils/constants';

export const AllocationChip = {
  ASSETS: 'ASSETS',
  SECTOR: AssetAllocationKind.SECTOR,
  SUPER_SECTOR: AssetAllocationKind.SUPER_SECTOR,
  CLASS: AssetAllocationKind.CLASS,
  TYPE: AssetAllocationKind.TYPE,
  COUNTRY: AssetAllocationKind.COUNTRY,
} as const;

export const DEFAULT_SINGLE_ALLOC_CHIPS = [
  AllocationChip.ASSETS,
  AllocationChip.CLASS,
  AllocationChip.SUPER_SECTOR,
  AllocationChip.COUNTRY,
] as const;

export const DEFAULT_COMPARE_ALLOC_CHIPS = Object.values(AllocationChip);

export const ALLOCATION_CHIP_TO_ASSET_FIELD = {
  [AllocationChip.ASSETS]: 'name',
  [AllocationChip.SECTOR]: 'sector',
  [AllocationChip.SUPER_SECTOR]: 'superSector',
  [AllocationChip.CLASS]: 'assetClass',
  [AllocationChip.TYPE]: 'assetType',
  [AllocationChip.COUNTRY]: 'country',
} as const;

export const ALLOCATION_CHART_HEIGHT = `calc(100vh - ${NAVBAR_HEIGHT} - 180px)`;

export const ALL_WEIGHTS_TABLE_COLUMNS = [
  'instituteIdentifier',
  'name',
  'weight',
  'assetClass',
  'superSector',
  'sector',
  'country',
  'assetType',
];
export const DEFAULT_WEIGHTS_TABLE_COLUMNS = ['instituteIdentifier', 'name', 'weight'] as const;

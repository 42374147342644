import _ from 'lodash';

import { filterObj } from 'lib/misc/misc';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';

const isAllocationNameAvailable = (currentInstitute, allocationName) => {
  if (!currentInstitute || !currentInstitute.settings) {
    return false;
  }
  // if there is no such settings available in Institute, then true by default
  if (
    !currentInstitute.settings.asset_allocations ||
    !currentInstitute.settings.asset_allocations[allocationName]
  ) {
    return true;
  }
  const isAvailable = currentInstitute.settings.asset_allocations[allocationName].available;
  return _.isUndefined(isAvailable) || Boolean(isAvailable);
};

/**
 *
 * @param allocationNameOrArray
 * If array or object is given, it's assumed, that it is a list of AssetAllocation objects.
 * Then method will filter for those, which are available.
 * If string is given, it's assumed, that it is a name of AssetGroupType. Then method
 * returns true or false - whether this AssetGroupType available or not.
 * @param idsToIgnore
 * Used only if allocationNameOrArray is an array or an object.
 * Array of strings, which are IDs of AssetAllocation, which we should anyway allow.
 * @returns {boolean|array}
 */
const useAllocationAvailable = (allocationNameOrArray, idsToIgnore) => {
  const { institute: currentInstitute } = useCurrentInstitute();
  if (_.isArray(allocationNameOrArray)) {
    return allocationNameOrArray.filter(
      assetAlloc =>
        isAllocationNameAvailable(currentInstitute, assetAlloc.name) ||
        idsToIgnore.includes(assetAlloc.id)
    );
  }
  if (_.isString(allocationNameOrArray)) {
    return isAllocationNameAvailable(currentInstitute, allocationNameOrArray);
  }
  if (_.isPlainObject(allocationNameOrArray)) {
    return filterObj(
      allocationNameOrArray,
      assetAlloc =>
        isAllocationNameAvailable(currentInstitute, assetAlloc.name) ||
        idsToIgnore.includes(assetAlloc.id)
    );
  }
  return null;
};

export default useAllocationAvailable;

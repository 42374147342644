import { useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import _, { intersection } from 'lodash';
import React, { useMemo } from 'react';

import SimpleText from 'components/SimpleText';
import { ALL_KPIS, CHILD_KPIS, PARENT_KPIS } from 'lib/performance/constants';
import { getColorSet } from 'lib/performance/helpers';
import KPIPaperTable from 'lib/performance/KPIPaperTable';
import { KPI, PerformanceInfo, TimeSeriesDatePoint } from 'lib/performance/types';
import {
  convertKPIMeasuresBacktest,
  convertKPIMeasuresHistory,
  getCorrectTimeSeriesPeriod,
  getKPIMeasures,
  getPercentagePerformance,
  parseDatesInSeries,
  sortAndFillMissingData,
} from 'lib/timeSeries/tsData';
import { IsoDate } from 'lib/types';
import { HistoricalSnapshot } from 'screens/Portfolio/lib/types';

const translationPath = 'lib.performance.kpisTable' as const;

const useStyles = makeStyles(theme => ({
  root: {
    color: 'inherit',
    '& MuiTableCell-root': {
      width: '6.5rem',
    },
    '& .MuiTableCell-body': {
      width: '6.5rem',
    },
  },
  headerRow: {
    width: '100%',
    color: theme.palette.text.primary,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
  },
  tableHeadersPrimary: {
    verticalAlign: 'top',
    paddingTop: 0,
    marginTop: 0,
  },
  tableHeadersBlack: {
    marginTop: '1rem',
  },
  tableHeadersBlackL: {
    marginTop: '1rem',
    marginLeft: '1rem',
    marginBottom: '2rem',
  },
  cellValuesBlack: {
    marginLeft: '1rem',
  },
  tableHeaderCell: {
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    verticalAlign: 'top',
  },
  cell: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: '5rem',
    overflow: 'hidden',
  },
  rowHeader: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '11rem',
    overflow: 'hidden',
  },
  ParentKPIs: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    width: '6.5rem',
    overflow: 'hidden',
  },
}));

interface KPIsTableProps {
  performanceInfo: PerformanceInfo | undefined;
  benchmarkPerformanceInfos?: PerformanceInfo[];
  startDate?: Date | IsoDate;
  endDate?: Date | IsoDate;
  points?: ReadonlyArray<HistoricalSnapshot>;
  kpisToShow?: KPI[];
  isSimpleTable?: boolean;
  size?: 'small' | 'medium' | 'large';
}

interface TableCellHeaderProps {
  performanceInfo: PerformanceInfo | undefined;
  color?: string;
}

export function computeTurnover(
  points: ReadonlyArray<HistoricalSnapshot>,
  startDate: Date,
  endDate: Date
) {
  const startDateFinal = startDate ? new Date(startDate) : null;
  const endDateFinal = endDate ? new Date(endDate) : null;
  let turnover = 0;

  points.forEach(point => {
    const allocDate = new Date(point.effectiveDate);
    const isAfterStart = !startDateFinal || allocDate.getTime() >= startDateFinal.getTime();
    const isBeforeEnd = !endDateFinal || allocDate.getTime() <= endDateFinal.getTime();
    if (isAfterStart && isBeforeEnd && point.turnover) {
      turnover += point.turnover;
    }
  });

  return turnover / 2;
}

function computeKPIsSingle(
  performance: TimeSeriesDatePoint[],
  cvarQuantile: number,
  rf: number,
  startDate: Date,
  endDate: Date,
  isBacktest = true,
  rebalancingPoints: ReadonlyArray<HistoricalSnapshot> = []
) {
  const amountPeriodsInYear = { day: 260, month: 12 };
  const filledPerformance = sortAndFillMissingData(performance);
  const correctPeriodPerformance = getCorrectTimeSeriesPeriod(
    filledPerformance,
    startDate,
    endDate
  );

  // new annual mean is just calculated from cumulative return by
  // taking square root (where power is amount of years)
  const kpis = getKPIMeasures(
    correctPeriodPerformance,
    amountPeriodsInYear,
    cvarQuantile,
    rf,
    false
  );
  if (isBacktest) {
    const turnover = computeTurnover(rebalancingPoints, startDate, endDate);
    return convertKPIMeasuresBacktest(kpis, turnover);
  }
  return convertKPIMeasuresHistory(kpis);
}

function computeKPIs(
  performanceInfo: PerformanceInfo | undefined,
  benchmarkPerformanceInfos: PerformanceInfo[],
  startDate?: Date | IsoDate,
  endDate?: Date | IsoDate,
  isBacktest = true,
  points: ReadonlyArray<HistoricalSnapshot> = []
) {
  const data: any = {};
  const mainSeries: TimeSeriesDatePoint[] = getPercentagePerformance(
    parseDatesInSeries(performanceInfo?.performance ?? [])
  );

  const startDateFinal = startDate ?? mainSeries[0]?.x ?? new Date(1900, 1, 1);
  const endDateFinal = endDate ?? mainSeries[mainSeries.length - 1]?.x ?? new Date(2100, 1, 1);

  data.main = computeKPIsSingle(
    mainSeries,
    0.05,
    0,
    startDateFinal instanceof Date ? startDateFinal : new Date(startDateFinal),
    endDateFinal instanceof Date ? endDateFinal : new Date(endDateFinal),
    isBacktest,
    points
  );
  benchmarkPerformanceInfos.forEach(benchmarkPerformanceInfo => {
    const series = getPercentagePerformance(
      parseDatesInSeries(benchmarkPerformanceInfo.performance ?? [])
    );
    data[benchmarkPerformanceInfo.id] = computeKPIsSingle(
      series,
      0.05,
      0,
      startDateFinal instanceof Date ? startDateFinal : new Date(startDateFinal),
      endDateFinal instanceof Date ? endDateFinal : new Date(endDateFinal),
      isBacktest
    );
  });
  return data;
}

const TableCellHeader = ({ performanceInfo, color }: TableCellHeaderProps) => {
  const classes = useStyles();
  return (
    <Tooltip title={performanceInfo?.name ?? ''}>
      <TableCell size='small' className={classes.tableHeaderCell}>
        <div>
          <SimpleText
            text={performanceInfo?.name ?? ''}
            sx={{ color }}
            className={classes.tableHeadersPrimary}
            fontWeight='bold'
          />
        </div>
      </TableCell>
    </Tooltip>
  );
};

const DEFAULT_KPIS_TO_SHOW = [...ALL_KPIS];

const KPIsTable = ({
  performanceInfo,
  benchmarkPerformanceInfos = [],
  startDate,
  endDate,
  points,
  kpisToShow = DEFAULT_KPIS_TO_SHOW,
  size = 'small',
  isSimpleTable = false,
}: KPIsTableProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const colorSet = getColorSet(10, theme.palette.graphs.bright, theme.palette.graphs.dark);
  const data = useMemo(() => {
    return computeKPIs(
      performanceInfo,
      benchmarkPerformanceInfos,
      startDate,
      endDate,
      true,
      points
    );
  }, [performanceInfo, benchmarkPerformanceInfos, startDate, endDate, points]);
  if (isSimpleTable) {
    return (
      <KPIPaperTable
        size={size}
        data={data}
        performanceInfo={performanceInfo}
        benchmarkPerformanceInfos={benchmarkPerformanceInfos}
        kpisToShow={kpisToShow}
      />
    );
  }
  return (
    <Table className={classes.root}>
      <TableBody>
        <TableRow key='header' role='checkbox' className={classes.headerRow}>
          <TableCell size='small' key='emptyCell'>
            <SimpleText text='' className={classes.cell} />
          </TableCell>
          <TableCellHeader performanceInfo={performanceInfo} />
          {benchmarkPerformanceInfos.map((perfInfo, index) => (
            <TableCellHeader performanceInfo={perfInfo} color={colorSet[index]} />
          ))}
        </TableRow>
        {Object.values(PARENT_KPIS).map(parentKPI => {
          const keysToDisplay = intersection(CHILD_KPIS[parentKPI], kpisToShow);
          if (keysToDisplay.length > 0) {
            return (
              <React.Fragment key={parentKPI}>
                <TableRow>
                  <TableCell size='small'>
                    <SimpleText
                      translateId={`${translationPath}.${parentKPI}`}
                      className={classes.ParentKPIs}
                      variant='h5'
                    />
                  </TableCell>
                  {_.range(Object.keys(data).length).map(index => (
                    <TableCell key={index} size='small' />
                  ))}
                </TableRow>
                {keysToDisplay.map(childKPI => (
                  <TableRow key={childKPI} hover>
                    <TableCell size='small'>
                      <SimpleText
                        translateId={`${translationPath}.${childKPI}`}
                        className={classes.rowHeader}
                      />
                    </TableCell>
                    <TableCell size='small'>
                      <SimpleText text={data.main[childKPI]} className={classes.cell} />
                    </TableCell>
                    {benchmarkPerformanceInfos.map(perfInfo => (
                      <TableCell size='small' key={perfInfo.id}>
                        <SimpleText
                          text={data[perfInfo.id]?.[childKPI] ?? '-'}
                          className={classes.cell}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </React.Fragment>
            );
          }
          return null;
        })}
      </TableBody>
    </Table>
  );
};

export default KPIsTable;

import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

import MyReturnButton from 'components/MyReturnButton/MyReturnButton';
import ObjectAccessDialog from 'components/ObjectAccessDialog';
import RestToRight from 'components/RestToRight';
import NameTitle from 'components/ScreenBase/NavbarMenu/NameTitle';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import { collapseSideMenu } from 'redux/ui/actions';
import { SIDE_MENU_WIDTH } from 'utils/constants';
import { NameTitlePropsType, ReturnButtonPropsType, SearchMenuPropsType } from 'utils/types';

const mapStateToProps = state => ({
  isSideMenuCollapsed: state.ui.isSideMenuCollapsed,
});

const matchDispatchToProps = dispatch => ({
  onCollapseMenuClick: () => dispatch(collapseSideMenu()),
});

const styles = theme => ({
  root: {
    boxShadow: '0 0 0 0 !important',
    backgroundColor: `${theme.palette.background.paper} !important`,
    borderBottom: `1px solid ${theme.palette.background.paper}`,
    color: theme.palette.text.primary,
    '& .NavbarMenu-menuButton-43': {
      margin: 0,
    },
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${SIDE_MENU_WIDTH}) !important`,
    marginLeft: SIDE_MENU_WIDTH,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingRight: '0px !important',
  },
  toolbarOpened: {
    paddingLeft: theme.spacing(4),
  },
  menuButton: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  hide: {
    display: 'none',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    height: '2.2rem',
  },
  sectionIcons: {
    // paddingLeft: theme.spacing(2),
    display: 'flex',
  },
  sectionIconsDark: {
    display: 'flex',
    borderLeft: `0.1rem solid ${theme.palette.secondary.dark}`,
  },
  sectionButtons: {
    paddingRight: theme.spacing(2),
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  icon: {
    color: theme.palette.neutral.main,
    margin: 0,
    '& .MuiBadge-anchorOriginTopRightRectangular': {
      color: theme.palette.primary.main,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: '100%',
      width: 10,
      height: 20,
    },
  },
  comparePortfolio: {
    float: 'right',
  },
  and: {
    float: 'left',
    marginTop: '0.4rem',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  pageTitle: {
    whiteSpace: 'nowrap',
  },
});

class NavbarMenu extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  render() {
    const {
      onCollapseMenuClick,
      isSideMenuCollapsed,
      classes,
      titleTranslationId,
      navButtonsProps,
      nameTitleProps,
      searchMenuProps,
      compareTitleProps,
      compareSearchMenuProps,
      returnButtonProps,
      object,
    } = this.props;
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      />
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        {navButtonsProps.map(navButtonProps => (
          <MenuItem
            key={navButtonProps.translateId}
            onClick={this.handleMobileMenuClose}
            disabled={navButtonProps.disabled}
          >
            <SimpleText {...navButtonProps} />
          </MenuItem>
        ))}
      </Menu>
    );

    return (
      <div>
        <AppBar
          position='fixed'
          className={classNames(classes.root, classes.appBar, {
            [classes.appBarShift]: !isSideMenuCollapsed,
          })}
        >
          <Toolbar
            className={classNames(!isSideMenuCollapsed && classes.toolbarOpened)}
            disableGutters
          >
            <IconButton
              aria-label='Open drawer'
              onClick={onCollapseMenuClick}
              className={classNames(
                classes.icon,
                classes.menuButton,
                !isSideMenuCollapsed && classes.hide
              )}
              size='large'
            >
              <MenuIcon />
            </IconButton>
            {returnButtonProps ? <MyReturnButton {...returnButtonProps} /> : null}
            <SimpleText
              pendo-id='screen-base-page-title'
              className={classes.pageTitle}
              variant='h1'
              translateId={titleTranslationId}
            />
            {!_.isNull(nameTitleProps) ? (
              <>
                <NameTitle
                  key={nameTitleProps.startWithEditing}
                  {...nameTitleProps}
                  searchMenuProps={searchMenuProps}
                  typographyVariant='h1'
                />
                {object ? <ObjectAccessDialog object={object} /> : null}
              </>
            ) : null}
            {!_.isNull(compareTitleProps) ? (
              <div>
                <SimpleText translateId='and' className={classes.and} variant='h1' />
                <NameTitle
                  className={classes.comparePortfolio}
                  key={compareTitleProps.startWithEditing}
                  {...compareTitleProps}
                  searchMenuProps={compareSearchMenuProps}
                  typographyVariant='h1'
                />
              </div>
            ) : null}
            <RestToRight />
            <div className={classes.sectionDesktop}>
              {navButtonsProps.map(navButtonProps => (
                <SimpleButton key={navButtonProps.translateId} {...navButtonProps} />
              ))}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup='true'
                onClick={this.handleMobileMenuOpen}
                className={classes.icon}
                size='large'
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
      </div>
    );
  }
}

NavbarMenu.propTypes = {
  navButtonsProps: PropTypes.arrayOf(PropTypes.object),
  onCollapseMenuClick: PropTypes.func.isRequired,
  isSideMenuCollapsed: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  activeLanguage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setActiveLanguage: PropTypes.func.isRequired,
  titleTranslationId: PropTypes.string.isRequired,
  nameTitleProps: NameTitlePropsType,
  searchMenuProps: SearchMenuPropsType,
  compareTitleProps: NameTitlePropsType,
  compareSearchMenuProps: SearchMenuPropsType,
  returnButtonProps: ReturnButtonPropsType,
  object: PropTypes.objectOf(PropTypes.any),
};

NavbarMenu.defaultProps = {
  navButtonsProps: [],
  activeLanguage: null,
  nameTitleProps: null,
  searchMenuProps: null,
  compareTitleProps: null,
  compareSearchMenuProps: null,
  returnButtonProps: null,
  object: null,
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withLocalize(withStyles(styles, { withTheme: true })(NavbarMenu)));

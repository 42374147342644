import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import SimpleButton from 'components/SimpleButton';
import { useURLSearchParams } from 'lib/navigation/navigation';
import LoginScreenBase from 'screens/Login/lib/components/LoginScreenBase';
import usePasswordStrength from 'screens/Login/lib/hooks/usePasswordStrength';
import { useResetPasswordMutation } from 'screens/Login/lib/queries';

const translationPath = 'changePasswordScreen';

const useStyles = makeStyles(theme => ({
  section: {
    paddingTop: theme.spacing(6),
  },
  root: {
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  button: {
    paddingTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ChangePasswordScreen = () => {
  const classes = useStyles();
  const searchParams = useURLSearchParams<['token']>();
  const { mutate: resetPassword } = useResetPasswordMutation();

  const {
    password,
    isPasswordStrong,
    passwordRepeat,
    arePasswordsSame,
    passwordFieldElem,
    passwordRepeatFieldElem,
  } = usePasswordStrength();

  const allFieldsEntered = password.length > 0 && passwordRepeat.length > 0;

  return (
    <LoginScreenBase>
      <div>
        <div className={classes.root}>
          <div className={classes.section}>{passwordFieldElem}</div>
          <div className={classes.section}>{passwordRepeatFieldElem}</div>
          <div className={classes.button}>
            <SimpleButton
              clickOnEnter
              disabled={!isPasswordStrong || !arePasswordsSame || !allFieldsEntered}
              size='large'
              onClick={() => resetPassword({ token: searchParams.token, data: { password } })}
              variant='highEmp'
              translateId={`${translationPath}.resetButton`}
            />
          </div>
        </div>
      </div>
    </LoginScreenBase>
  );
};

export default ChangePasswordScreen;

import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

import SimpleButton from 'components/SimpleButton';

const useStyles = makeStyles({
  content: {
    paddingBottom: '1rem',
    paddingRight: '1.25rem',
  },
  button: {},
});

const ConfigurationBottomToolbar = ({ onSaveAction, onCancelAction }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <SimpleButton variant='midEmp' onClick={onSaveAction} translateId='save' />
      <SimpleButton variant='midEmp' onClick={onCancelAction} translateId='dialogKey.CLOSE' />
    </div>
  );
};

ConfigurationBottomToolbar.propTypes = {
  onSaveAction: PropTypes.func.isRequired,
  onCancelAction: PropTypes.func.isRequired,
};

export default ConfigurationBottomToolbar;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import NotificationSnack from 'components/ScreenBase/NotificationSnacksArea/NotificationSnack';
import { removeSmallNotification } from 'redux/notifications/actions';

const mapStateToProps = state => ({
  notification: state.notifications.smallNotification,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(removeSmallNotification()),
});

// soon to be refactored into full class
// eslint-disable-next-line react/prefer-stateless-function
class NotificationSnacksArea extends React.Component {
  render() {
    const { onClose, notification } = this.props;

    return <NotificationSnack onClose={onClose} notification={notification} timeout={3000} />;
  }
}

NotificationSnacksArea.propTypes = {
  notification: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

NotificationSnacksArea.defaultProps = {
  notification: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSnacksArea);

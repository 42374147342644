import { isEmpty, first } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import MyLineChartWrapper from 'components/MyLineChart/MyLineChartWrapper';
import { selectListToDict } from 'lib/queries';
import useDateToggle from 'screens/Analysis/lib/components/useDateToggle';
import { generateLineChartData } from 'screens/Analysis/lib/helpers';
import { useAnalysisQuery } from 'screens/Analysis/lib/queries';
import {
  useAssetQueries,
  useAssetsQuery,
  useBenchmarksQuery,
} from 'screens/Environment/lib/queries';
import useBenchmarkChipSelection from 'screens/Report/ReportScreen/components/hooks/useBenchmarkChipSelection';
import { generateBenchmarkList } from 'screens/Report/utils/helpers';

const PerformanceLineChart = ({ analysisId }) => {
  const { analysis } = useAnalysisQuery(analysisId);
  const widgetData = analysis?.result?.PerformanceBreakdown.line ?? {};

  const { benchmarks } = useBenchmarksQuery();
  const { assets } = useAssetsQuery();

  const [dateFilter, DateFilterComponent] = useDateToggle(
    !isEmpty(widgetData) ? first(widgetData.filters) : 'all'
  );
  const defaultColumns = useMemo(() => {
    return [
      {
        key: -1,
        label: 'Portfolio',
        checked: true,
        color: '#2dc6c9',
        removable: false,
      },
      {
        key: -2,
        label:
          Object.values(benchmarks).find(item => item.id === analysis?.benchmarkId)?.typ ??
          'Benchmark',
        checked: true,
        color: '#d9dada',
        removable: false,
      },
      ...generateBenchmarkList([], assets),
    ];
  }, [analysis?.benchmarkId, assets, benchmarks]);
  const [BenchmarkField, assetBenchmarks] = useBenchmarkChipSelection(defaultColumns);
  const benchmarkCount = assetBenchmarks.filter(column => column.checked).length;
  const compareAssetIds = useMemo(() => {
    return assetBenchmarks.filter(item => item.key > 0 && item.checked).map(item => item.key);
  }, [assetBenchmarks]);
  const assetQueries = useAssetQueries(compareAssetIds);

  const data = useMemo(() => {
    const assetsDetailed = selectListToDict(
      assetQueries.map(item => item.data).filter(item => item !== undefined)
    );
    return generateLineChartData(widgetData.data?.[dateFilter], assetBenchmarks, assetsDetailed);
  }, [assetBenchmarks, assetQueries, dateFilter, widgetData.data]);
  if (isEmpty(data)) return null;

  return (
    <>
      <DateFilterComponent
        available={widgetData.filters}
        errors={widgetData.errors}
        hints={widgetData.hints}
      />

      <div style={{ marginTop: '1.8rem', position: 'relative', height: '90%' }}>
        <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              width: '100%',
              flexGrow: 1,
              minHeight: 0,
              minWidth: 0,
            }}
          >
            <MyLineChartWrapper
              colors={[
                {
                  id: '-2',
                  color: '#D9DADA',
                  type: 'ASSET',
                },
              ]}
              data={data}
              isDummyBacktest
              onDatesChange={() => null}
              skipScrolling
              transparentBackground
            />
          </div>
          <div>
            <BenchmarkField
              translateSource='analysis'
              useLabelWithoutTranslate
              addDisabled={benchmarkCount === 8}
              addColor={benchmarkCount === 8 ? 'disabled' : 'primary'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

PerformanceLineChart.propTypes = {
  analysisId: PropTypes.string,
};

PerformanceLineChart.defaultProps = {
  analysisId: undefined,
};

export default PerformanceLineChart;

import PropTypes from 'prop-types';
import React from 'react';

import BaseTextField from 'components/BaseTextField';
import {
  endsWithDot,
  endsWithNumber,
  isParsableNumber,
  normalizeFloatString,
  parseNumber,
} from 'components/NumberTextField/utils';
import { ClassesType } from 'utils/types';

function toString(value) {
  if (value === 0) {
    return 0;
  }
  return Math.round(value * 100) / 100;
}

class NumberTextField extends React.Component {
  state = {
    shownValue: null,
    lastValidValue: null,
  };

  componentDidMount() {
    const { value } = this.props;
    if (value !== null && value !== undefined) {
      this.setState({ shownValue: toString(value), lastValidValue: value });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    if (nextProps.value !== value) {
      this.setState({ shownValue: toString(nextProps.value) });
    }
  }

  evaluateNewState = inputValue => {
    const { lastValidValue, shownValue } = this.state;
    const strValue = normalizeFloatString(inputValue);
    let newShownValue = shownValue;
    let newLastValidValue = lastValidValue;

    if (isParsableNumber(strValue)) {
      if (endsWithDot(strValue)) {
        newShownValue = strValue;
      } else if (endsWithNumber(strValue)) {
        newShownValue = strValue;
        newLastValidValue = parseNumber(strValue);
      }
    }
    return { newShownValue, newLastValidValue };
  };

  onBaseChange = e => {
    const { newShownValue, newLastValidValue } = this.evaluateNewState(e.target.value);
    const { onChange } = this.props;
    onChange(newLastValidValue);
    this.setState({ shownValue: newShownValue, lastValidValue: newLastValidValue });
  };

  onBaseChangeWithLimit = e => {
    const { newShownValue, newLastValidValue } = this.evaluateNewState(e.target.value);
    const { onChange, limit } = this.props;
    if (newShownValue > limit) {
      onChange(limit);
      this.setState({ shownValue: limit, lastValidValue: limit });
    } else {
      onChange(newLastValidValue);
      this.setState({ shownValue: newShownValue, lastValidValue: newLastValidValue });
    }
  };

  render() {
    const { classes, value, onChange, disabled, limit, ...other } = this.props;
    const { shownValue } = this.state;
    return (
      <BaseTextField
        variant='standard'
        onChange={limit ? this.onBaseChangeWithLimit : this.onBaseChange}
        value={shownValue ?? ''}
        disabled={disabled}
        {...other}
      />
    );
  }
}

NumberTextField.propTypes = {
  classes: ClassesType,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  limit: PropTypes.number,
};

NumberTextField.defaultProps = {
  classes: null,
  disabled: false,
  limit: null,
};

export default NumberTextField;

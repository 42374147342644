// make correct error object from usual Http error
import { ErrorKind, ErrorOrigin } from 'utils/constants';

export function makeErrorFromHttpResponse(error) {
  if (error && error.response && error.response.data) {
    const errorJson = error.response.data.error;
    return {
      origin: ErrorOrigin.API,
      kind: errorJson.kind,
      data: errorJson.data,
    };
  }
  return {
    origin: ErrorOrigin.NETWORK,
    kind: ErrorKind.UNKNOWN_ERROR,
  };
}

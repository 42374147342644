import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import DialogButton from 'components/Buttons/DialogButton';
import MyDropdown from 'components/MyDropdown/MyDropdown';
import SearchBar from 'components/SearchBar/SearchBarView';
import SimpleText from 'components/SimpleText';
import Table from 'components/Table/TableView';
import { useAssetsQuery } from 'screens/Environment/lib/queries';
import { DialogKeys } from 'utils/constants';

function makeRecommendationsFromUpload(
  tableObjectsUpload,
  recommendationColumn,
  identifierColumn,
  assets
) {
  const recommendations = [];
  let matchingAsset;
  if (tableObjectsUpload && recommendationColumn && identifierColumn) {
    for (let i = 0; i < tableObjectsUpload.length; i += 1) {
      matchingAsset = null;
      const assetIdentifier = tableObjectsUpload[i][identifierColumn];
      for (const assetId in assets) {
        if (assets[assetId]) {
          if (assets[assetId].instituteIdentifier === assetIdentifier) {
            matchingAsset = assets[assetId];
            break;
          }
        }
      }
      if (matchingAsset) {
        recommendations.push({
          asset: matchingAsset.id,
          recommendationType: tableObjectsUpload[i][recommendationColumn],
        });
      }
    }
  }
  return recommendations;
}

function makeResearchTableObjects(recommendations, assets) {
  const tableObjects = [];
  if (recommendations && assets) {
    let matchingAsset;
    for (let i = 0; i < recommendations.length; i += 1) {
      matchingAsset = null;
      const assetIdentifier = recommendations[i].asset;
      for (const assetId in assets) {
        if (assets[assetId]) {
          if (assets[assetId].id === assetIdentifier) {
            matchingAsset = assets[assetId];
            break;
          }
          if (!matchingAsset) {
            if (assets[assetId].isin === assetIdentifier) {
              matchingAsset = assets[assetId];
              break;
            }
          }
        }
      }
      if (matchingAsset) {
        tableObjects.push({
          name: matchingAsset.name,
          isin: matchingAsset.isin,
          recommendation: recommendations[i].recommendationType,
        });
      }
    }
  }
  return tableObjects;
}

const columns = ['isin', 'name', 'recommendation'];

const RecommendationsTab = ({
  draftIdColName,
  setDraftIdColName,
  draftRecColName,
  setDraftRecColName,
  draftRecommendations,
  setDraftRecommendations,
  disabled,
  hasAccess,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const [uploadedTableObjects, setUploadedTableObjects] = useState([]);
  const [uploadedColumns, setUploadedColumns] = useState([]);

  const { assets } = useAssetsQuery();
  const tableObjects = useMemo(() => {
    return makeResearchTableObjects(draftRecommendations, assets);
  }, [draftRecommendations, assets]);

  const handleDiscard = () => {
    setDraftIdColName(undefined);
    setDraftRecColName(undefined);
    setOpen(false);
  };
  const handleOverwrite = () => {
    setOpen(false);
    setDraftRecommendations(
      makeRecommendationsFromUpload(uploadedTableObjects, draftRecColName, draftIdColName, assets)
    );
  };

  const onSuccessfulUpload = useCallback(uploadedData => {
    setUploadedTableObjects(uploadedData);
    if (uploadedData[0]) {
      setUploadedColumns(Object.keys(uploadedData[0]));
    }
    setOpen(true);
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='xl'
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='upload-recommendations-dialog-title'
        TransitionProps={{
          onExited: () => {
            setUploadedTableObjects([]);
            setUploadedColumns([]);
          },
        }}
      >
        <DialogTitle id='access-dialog-title'>
          <SimpleText variant='h2' translateId='researchListScreen.uploadFileDialog.title' />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Box sx={{ marginTop: 2 }}>
                <MyDropdown
                  translationPath='researchListScreen.uploadFileDialog.idDropdown'
                  value={draftIdColName}
                  onChange={setDraftIdColName}
                  onUpdateSearch={null}
                  isNullAllowed={false}
                  disabled={disabled}
                  fullWidth
                  pendo-id='research-recommendations-tab-isin-dropdown'
                >
                  {uploadedColumns.map(col => (
                    <MenuItem key={col} value={col}>
                      <SimpleText text={col} />
                    </MenuItem>
                  ))}
                </MyDropdown>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ marginTop: 2 }}>
                <MyDropdown
                  translationPath='researchListScreen.uploadFileDialog.recommendationDropdown'
                  value={draftRecColName}
                  onChange={setDraftRecColName}
                  onUpdateSearch={null}
                  isNullAllowed={false}
                  disabled={disabled}
                  fullWidth
                >
                  {uploadedColumns.map(col => (
                    <MenuItem key={col} value={col}>
                      <SimpleText text={col} />
                    </MenuItem>
                  ))}
                </MyDropdown>
              </Box>
            </Grid>
          </Grid>
          {uploadedColumns && (
            <Table
              onRowClick={() => {}}
              titleTranslateId='researchListScreen.uploadedRecommendationsTableName'
              columns={uploadedColumns}
              tableObjects={uploadedTableObjects}
              isTableWhite
              showEmptyRows={false}
            />
          )}
        </DialogContent>
        <DialogActions>
          <DialogButton
            onClick={() => handleDiscard()}
            translateId={`dialogKey.${DialogKeys.DISCARD}`}
            variant='lowEmp'
          />
          <DialogButton
            onClick={() => handleOverwrite()}
            translateId='researchListScreen.dialogKey.overwrite'
            variant='lowEmp'
            disabled={
              !draftIdColName ||
              !draftRecColName ||
              !uploadedColumns.includes(draftIdColName) ||
              !uploadedColumns.includes(draftRecColName)
            }
            pendo-id='research-recommendations-tab-overwrite-button'
          />
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item xs={12}>
          {tableObjects.length > 0 && (
            <SearchBar
              searchValue={searchValue}
              onUpdateSearch={setSearchValue}
              translationPath='researchListScreen.recommendationsSearchBar'
            />
          )}
          <Table
            onRowClick={() => {}}
            titleTranslateId='researchListScreen.recommendationsTableName'
            translateTableId='researchListScreen.recommendationsTable'
            tableObjects={tableObjects}
            columns={columns}
            searchValue={searchValue}
            isDownloadButton
            isUploadButton={!disabled && hasAccess}
            uploadButtonColor='primary'
            onSuccessfulUpload={onSuccessfulUpload}
            isTableWhite
            showEmptyRows={false}
          />
        </Grid>
      </Grid>
    </>
  );
};

RecommendationsTab.propTypes = {
  draftIdColName: PropTypes.string,
  draftRecColName: PropTypes.string,
  setDraftIdColName: PropTypes.func.isRequired,
  setDraftRecColName: PropTypes.func.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  draftRecommendations: PropTypes.arrayOf(PropTypes.object).isRequired,
  setDraftRecommendations: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

RecommendationsTab.defaultProps = {
  draftIdColName: undefined,
  draftRecColName: undefined,
  disabled: false,
};

export default RecommendationsTab;

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Translate } from 'react-localize-redux';

import NumberTextField from 'components/NumberTextField';
import SimpleText from 'components/SimpleText';
import { useDraftValue } from 'lib/misc/useDraft/useDraft';
import {
  StrategyCard,
  StrategyCardContent,
} from 'screens/Portfolio/lib/hooks/useStrategyConfiguration/cards/StrategyCard';

const translationPath = 'portfolioLib.strategyConfiguration.maximumWeightCard' as const;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  },
  input: {
    maxWidth: '100px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface VolumeCardProps {
  maxConstraint: number | undefined;
  minConstraint?: number;
  disabled?: boolean;
  onChangeMaxConstraint?: (value: number) => void;
  onChangeMinConstraint?: (value: number) => void;
}

const DIFFERENCE_MIN_MAX = 0.01;

const VolumeCard = ({
  maxConstraint,
  minConstraint,
  disabled = false,
  onChangeMaxConstraint = undefined,
  onChangeMinConstraint = undefined,
}: VolumeCardProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const handleChangeMaxConstraint = (value: number | number[]) => {
    if (onChangeMaxConstraint) {
      onChangeMaxConstraint(value as number);
    }
  };
  const handleChangeMinConstraint = (value: number | number[]) => {
    if (onChangeMinConstraint) {
      onChangeMinConstraint(value as number);
    }
  };
  const value = maxConstraint ?? 1.0;
  const valueMin = minConstraint ?? 0.0;

  const handleBlur = () => {
    let newValue = value;
    if (value < 0.01) {
      newValue = 0.01;
    } else if (value > 1.0) {
      newValue = 1.0;
    }
    if (newValue - valueMin < DIFFERENCE_MIN_MAX) {
      handleChangeMinConstraint(
        newValue - DIFFERENCE_MIN_MAX > 0 ? newValue - DIFFERENCE_MIN_MAX : 0
      );
    }
    if (newValue !== value) {
      handleChangeMaxConstraint(newValue);
    }
  };
  const handleBlurMin = () => {
    let newValue = valueMin;
    if (valueMin > 0.02) {
      newValue = 0.02;
    } else if (valueMin < 0.0) {
      newValue = 0.0;
    }
    if (value - newValue < DIFFERENCE_MIN_MAX) {
      handleChangeMaxConstraint(
        newValue + DIFFERENCE_MIN_MAX < 1 ? newValue + DIFFERENCE_MIN_MAX : 1
      );
    }
    if (newValue !== valueMin) {
      handleChangeMinConstraint(newValue);
    }
  };
  return (
    <StrategyCard title={`${translationPath}.title`}>
      <StrategyCardContent>
        <div className={classes.root}>
          <div className={classes.section}>
            <div className={classes.input}>
              <NumberTextField
                fullWidth={false}
                bold
                label={null}
                placeholder='weight'
                typographyVariant='h2'
                onChange={(newValue: number) => handleChangeMaxConstraint(newValue / 100)}
                onBlur={handleBlur}
                value={value * 100}
                disableUnderline
                textAlign='center'
                disabled={disabled}
                limit={100}
                inputProps={{
                  min: 1,
                  max: 100,
                  step: 0.5,
                  type: 'number',
                  style: {
                    textAlign: 'center',
                  },
                }}
              />
            </div>
            <SimpleText translateId={`${translationPath}.maximum`} variant='body1' />
          </div>
          <div className={classes.section}>
            <div className={classes.input}>
              <NumberTextField
                fullWidth={false}
                bold
                label={null}
                placeholder='weight'
                typographyVariant='h2'
                onChange={(newValue: number) => handleChangeMinConstraint(newValue / 100)}
                onBlur={handleBlurMin}
                value={valueMin * 100}
                disableUnderline
                textAlign='center'
                disabled={disabled}
                limit={2}
                inputProps={{
                  min: 0,
                  max: 2,
                  step: 0.25,
                  type: 'number',
                  style: {
                    textAlign: 'center',
                  },
                }}
              />
            </div>

            <div className={classes.row}>
              <SimpleText translateId={`${translationPath}.minimum`} variant='body1' />
              <Tooltip title={<Translate id={`${translationPath}.minimumWarning`} />}>
                <InfoOutlinedIcon
                  style={{ marginLeft: '4px', color: theme.palette.warning.light }}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </StrategyCardContent>
    </StrategyCard>
  );
};

interface UseVolumeCardProps {
  maxConstraint: number | undefined;
  minConstraint?: number;
  disabled?: boolean;
}

const useVolumeCard = ({ maxConstraint, minConstraint, disabled = false }: UseVolumeCardProps) => {
  const { draft, setDraft } = useDraftValue(maxConstraint);
  const { draft: draftMin, setDraft: setDraftMin } = useDraftValue(minConstraint);
  const resetDraft = () => {
    setDraft(undefined);
    setDraftMin(undefined);
  };

  const cardElement = (
    <VolumeCard
      maxConstraint={draft}
      disabled={disabled}
      onChangeMaxConstraint={setDraft}
      minConstraint={draftMin}
      onChangeMinConstraint={setDraftMin}
    />
  );
  return {
    cardElement,
    resetDraft,
    draftMaxConstraint: draft,
    draftMinConstraint: draftMin,
  };
};

export default useVolumeCard;

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
    
  }
  
  html {
    font-size: 15px;
    overflow-x: hidden;
  }
`;

import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ResourceName } from 'lib/constants';
import { replaceObjectInArray } from 'lib/misc/misc';
import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import { get, post, put, selectListToDict } from 'lib/queries';
import { Id } from 'lib/types';
import { addError, addSmallNotification } from 'redux/notifications/actions';
import {
  Institute,
  InstituteOverview,
  PostInstitute,
  PutInstitute,
} from 'screens/Institute/lib/types';
import { authKeys } from 'screens/Login/lib/queries';
import { userGroupsKeys } from 'screens/UserGroup/lib/queries';
import { SmallNotification } from 'utils/constants';

interface DetailParams {
  id: Id;
}

const instituteKeys = {
  all: [{ scope: ResourceName.INSTITUTE, admin: false }] as const,
  lists: () => [{ ...instituteKeys.all[0], entity: 'list' }] as const,
  list: ({ filter = 'all' } = {}) => [{ ...instituteKeys.lists()[0], filter }] as const,
  details: () => [{ ...instituteKeys.all[0], entity: 'detail' }] as const,
  detail: ({ id }: DetailParams) => [{ ...instituteKeys.details()[0], id }] as const,
};

const instituteAdminKeys = {
  all: [{ scope: ResourceName.INSTITUTE, admin: true }] as const,
  lists: () => [{ ...instituteAdminKeys.all[0], entity: 'list' }] as const,
  list: ({ filter = 'all' } = {}) => [{ ...instituteAdminKeys.lists()[0], filter }] as const,
  details: () => [{ ...instituteAdminKeys.all[0], entity: 'detail' }] as const,
  detail: ({ id }: DetailParams) => [{ ...instituteAdminKeys.details()[0], id }] as const,
};

async function getInstitutes(): Promise<InstituteOverview[]> {
  return get('institutes/');
}

async function getInstitute({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<typeof instituteKeys.detail>>): Promise<Institute> {
  return get(`institutes/${id}/`);
}

async function getInstitutesAdmin(): Promise<InstituteOverview[]> {
  return get('admin/institutes/');
}

async function getInstituteAdmin({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<typeof instituteAdminKeys.detail>>): Promise<Institute> {
  return get(`admin/institutes/${id}/`);
}

async function putInstituteAdmin(id: Id, data: PutInstitute) {
  return put(`admin/institutes/${id}/`, data);
}

async function postInstituteAdmin(data: PostInstitute) {
  return post('admin/institutes/', data);
}

export const useInstituteQuery = (id: Id | undefined) => {
  const query = useQuery(instituteKeys.detail({ id } as { id: Id }), getInstitute, {
    enabled: !!id,
  });
  return {
    ...query,
    institute: query.data,
  };
};

export const useInstitutesQuery = () => {
  const query = useQuery(instituteKeys.list(), getInstitutes, {
    select: selectListToDict,
  });
  return {
    ...query,
    institutes: query.data || {},
  };
};

export const useInstituteQueryAdmin = (id: Id | undefined) => {
  const query = useQuery(instituteAdminKeys.detail({ id } as { id: Id }), getInstituteAdmin, {
    enabled: !!id,
  });
  return {
    ...query,
    institute: query.data,
  };
};

export const useInstitutesQueryAdmin = () => {
  const query = useQuery(instituteAdminKeys.list(), getInstitutesAdmin, {
    select: selectListToDict,
  });
  return {
    ...query,
    institutes: query.data || {},
  };
};

export const useCreateInstituteMutationAdmin = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation((data: PostInstitute) => postInstituteAdmin(data), {
    onSuccess: data => {
      queryClient.setQueriesData<InstituteOverview[]>(
        [{ ...instituteAdminKeys.lists()[0] }],
        prev => (prev ? [...prev, data] : [data])
      );

      queryClient.setQueriesData([{ ...instituteAdminKeys.details()[0], id: data.id }], data);
      dispatch(addSmallNotification(SmallNotification.INSTITUTE_CREATED));
      queryClient.invalidateQueries(userGroupsKeys.all);
      queryClient.invalidateQueries([{ scope: ResourceName.USER }]);
      queryClient.invalidateQueries(instituteKeys.all);
      queryClient.invalidateQueries(authKeys.all);
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const useUpdateInstituteMutationAdmin = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, data }: { id: Id; data: PutInstitute }) => putInstituteAdmin(id, data),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueriesData([{ ...instituteAdminKeys.lists()[0] }], prev =>
          replaceObjectInArray(prev, data)
        );

        queryClient.setQueriesData([{ ...instituteAdminKeys.details()[0], id: data.id }], data);

        const keys = Object.keys(variables.data);
        const key = keys[0];
        if (keys.length === 1 && key === 'name') {
          dispatch(addSmallNotification(SmallNotification.NAME_CHANGED));
        } else {
          queryClient.invalidateQueries(userGroupsKeys.all);
          queryClient.invalidateQueries([{ scope: ResourceName.USER }]);
          queryClient.invalidateQueries(authKeys.all);
          dispatch(addSmallNotification(SmallNotification.INSTITUTE_SAVED));
        }
        queryClient.invalidateQueries(instituteKeys.all);
      },
      onError: error => {
        dispatch(addError(makeErrorFromHttpResponse(error)));
      },
    }
  );
};

import { CardActions } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

import MyDropdown from 'components/MyDropdown/MyDropdown';
import SimpleText from 'components/SimpleText';
import { ResearchAction } from 'screens/Research/lib/constants';

const possibleActions = [ResearchAction.BUY, ResearchAction.HOLD, ResearchAction.SELL];

const useStyles = makeStyles({
  insideCard: {
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  text: {
    minWidth: '10rem',
    maxWidth: '10rem',
    textAlign: 'left',
    paddingRight: '1rem',
  },
});

const ConstraintDropdown = ({ text, value, onChange, disabled }) => {
  const classes = useStyles();
  return (
    <CardActions className={classes.insideCard}>
      <div>
        <SimpleText text={text} className={classes.text} />
      </div>
      <MyDropdown
        value={value}
        onChange={onChange}
        translationPath='researchConstraintListScreen.actionDropdown'
        isNullAllowed={false}
        disabled={disabled}
      >
        {possibleActions.map(action => (
          <MenuItem key={action} value={action}>
            <SimpleText
              translateId={`researchConstraintListScreen.actionDropdown.values.${action}`}
            />
          </MenuItem>
        ))}
      </MyDropdown>
    </CardActions>
  );
};

ConstraintDropdown.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ConstraintDropdown.defaultProps = {
  disabled: false,
};

export default ConstraintDropdown;

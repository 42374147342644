import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty, first } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import RatioItem from 'screens/Analysis/AnalysisScreen/widgets/RatiosBar/RatioItem';
import TestSpinner from 'screens/Analysis/lib/components/TestSpinner';
import useDateToggle from 'screens/Analysis/lib/components/useDateToggle';
import WidgetTitleSelector from 'screens/Analysis/lib/components/WidgetTitleSelector';
import { useAnalysisQuery } from 'screens/Analysis/lib/queries';

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: '0.7rem',
    position: 'relative',
  },
  content: {
    height: '10rem',
    backgroundColor: '#FAFAFA',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    marginTop: '0.4rem',
  },
}));

const RatiosBar = ({ analysisId }) => {
  const classes = useStyles();

  const { analysis } = useAnalysisQuery(analysisId);
  const widgetData = analysis?.result?.RatiosBar ?? {};

  const [dateFilter, DateFilterComponent] = useDateToggle(
    !isEmpty(widgetData) ? first(widgetData.filters) : 'all'
  );

  return (
    <Grid item className={classes.wrapper} md={12}>
      {!isEmpty(widgetData) && (
        <>
          <WidgetTitleSelector mainTitle='ratios' disableSelection omitMargin />
          <DateFilterComponent
            available={widgetData.filters}
            errors={widgetData.errors}
            hints={widgetData.hints}
          />
        </>
      )}
      <Grid container spacing={4} pendo-id='analysis-ratios-bar'>
        {isEmpty(widgetData) ? (
          <div className={classes.content}>
            <TestSpinner />
          </div>
        ) : (
          <>
            {widgetData.data[dateFilter].map((item, i) => (
              <RatioItem key={`${i + 1}-${item.title}`} {...item} hasTooltip />
            ))}
          </>
        )}
      </Grid>
    </Grid>
  );
};

RatiosBar.propTypes = {
  analysisId: PropTypes.string,
};

RatiosBar.defaultProps = {
  analysisId: undefined,
};

export default RatiosBar;

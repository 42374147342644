import DeleteIcon from '@mui/icons-material/Delete';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import DialogButton from 'components/Buttons/DialogButton';
import InfoTooltip from 'components/InfoTooltip';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import { addElementsToDict } from 'lib/misc/misc';
import { useRolesQuery } from 'screens/UserGroup/lib/queries';
import { DialogKeys } from 'utils/constants';
import { idArrayType } from 'utils/types';

const useStyles = makeStyles(theme => ({
  wrapper: {
    maxWidth: '350px',
  },
  addRoleButtonArea: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    height: '2.5rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  listName: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
}));

const RolesList = ({ draftRoleIds, dispatchDraftRoleIds, disabled }) => {
  const classes = useStyles();
  const { isLoading: isLoadingRoles, data: dataRoles } = useRolesQuery();
  const allRolesObj = addElementsToDict({}, dataRoles);

  const [open, setOpen] = React.useState(false);
  const availableRoleIds = dataRoles
    ? dataRoles.map(role => role.id).filter(roleId => !draftRoleIds.includes(roleId))
    : [];
  const [checkedRoleIds, setCheckedRoleIds] = useState([]);

  const handleRoleIdsToggle = value => () => {
    const currentIndex = checkedRoleIds.indexOf(value);
    const newChecked = [...checkedRoleIds];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedRoleIds(newChecked);
  };

  const handleDiscard = () => {
    setOpen(false);
  };

  const handleAddRoles = () => {
    dispatchDraftRoleIds({ type: 'add', value: checkedRoleIds });
    setOpen(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='add-role-dialog-title'
        TransitionProps={{
          onExited: () => setCheckedRoleIds([]),
        }}
      >
        <DialogContent>
          <InfoTooltip size='small' translateId='userGroupScreen.infoTooltip.roles'>
            <SimpleText
              className={classes.listName}
              translateId='userGroupScreen.addRolesDialogTitle'
              variant='h2'
            />
          </InfoTooltip>
          <List dense={false}>
            {availableRoleIds.map(roleId => {
              const labelId = `checkbox-list-label-${roleId}`;
              return (
                <ListItem
                  divider={availableRoleIds.indexOf(roleId) < availableRoleIds.length - 1}
                  key={roleId}
                  button
                  onClick={handleRoleIdsToggle(roleId)}
                >
                  <ListItemIcon>
                    <Checkbox
                      color='primary'
                      edge='start'
                      checked={checkedRoleIds.includes(roleId)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={allRolesObj?.[roleId]?.name} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <DialogButton
            onClick={() => handleDiscard()}
            translateId={`dialogKey.${DialogKeys.DISCARD}`}
            variant='lowEmp'
          />
          <DialogButton
            onClick={() => handleAddRoles()}
            translateId='userGroupScreen.dialogKey.addRoles'
            variant='lowEmp'
          />
        </DialogActions>
      </Dialog>

      <div className={classes.wrapper}>
        <InfoTooltip size='small' translateId='userGroupScreen.infoTooltip.roles'>
          <SimpleText
            className={classes.listName}
            translateId='userGroupScreen.rolesList'
            variant='h2'
          />
        </InfoTooltip>
        <List dense={false}>
          {draftRoleIds.map(roleId => (
            <ListItem divider key={roleId}>
              <ListItemText primary={allRolesObj?.[roleId]?.name ?? roleId} />
              <ListItemSecondaryAction>
                <IconButton
                  disabled={disabled || isLoadingRoles}
                  edge='end'
                  aria-label='delete'
                  onClick={() => dispatchDraftRoleIds({ type: 'remove', value: [roleId] })}
                  size='large'
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <div className={classes.addRoleButtonArea}>
          <SimpleButton
            disabled={disabled || isLoadingRoles}
            className={classes.button}
            onClick={() => setOpen(true)}
            translateId='userGroupScreen.addRolesButton'
            variant='lowEmp'
          />
        </div>
      </div>
    </>
  );
};

RolesList.propTypes = {
  draftRoleIds: idArrayType.isRequired,
  dispatchDraftRoleIds: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

RolesList.defaultProps = {
  disabled: false,
};

export default RolesList;

export const FIRST_LEVEL_HEIGHT = '15rem';
export const SECOND_LEVEL_HEIGHT = '30rem';
export const THIRD_LEVEL_HEIGHT = '11rem';
export const FOURTH_LEVEL_HEIGHT = '30rem';

export const AnalysisDataType = {
  REAL: 'REAL',
  HISTORICAL: 'HISTORICAL',
  BACKTEST: 'BACKTEST',
} as const;

import { useCallback } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

import useLocalStorage from 'lib/misc/useLocalStorage';

export const useTranslate = () => {
  return useSelector(state => getTranslate(state.localize));
};

export const useSwitchLanguage = () => {
  const [activeLanguage, setValue] = useLocalStorage('activeLanguage', 'de');
  return useCallback(() => {
    if (activeLanguage === 'en') {
      setValue('de');
    } else {
      setValue('en');
    }
  }, [activeLanguage, setValue]);
};

export const useActiveLanguage = () => {
  const [activeLanguage] = useLocalStorage('activeLanguage', 'de');
  return activeLanguage;
};

import { Card, CardContent } from '@mui/material';
import React from 'react';

import SimpleText from 'components/SimpleText';
import { toDateString } from 'lib/dates/dateUtils';
import { makePerformanceInfosFromAssetQueries } from 'lib/performance/helpers';
import KPIsTable from 'lib/performance/KPIsTable';
import { Id, Ids } from 'lib/types';
import { AnalysisDataTypeT } from 'screens/Analysis/lib/types';
import { useAssetQueries } from 'screens/Environment/lib/queries';
import usePortfolioPerformance from 'screens/Portfolio/lib/hooks/usePortfolioPerformance';

const translationPath = 'lib.performance.kpisTableCard' as const;

interface KPIsTableCardProps {
  portfolioId?: Id;
  dataType?: AnalysisDataTypeT;
  backtestId?: Id;
  compareAssetIds: Ids;
  startDate?: Date;
  endDate?: Date;
}

const KPIsTableCard = ({
  portfolioId,
  dataType,
  backtestId,
  compareAssetIds,
  startDate,
  endDate,
}: KPIsTableCardProps) => {
  const assetQueries = useAssetQueries(compareAssetIds);
  const benchmarkPerfInfos = makePerformanceInfosFromAssetQueries(compareAssetIds, assetQueries);
  const { performanceInfo, points } = usePortfolioPerformance({
    portfolioId,
    dataType,
    backtestId,
  });
  return (
    <Card>
      <CardContent>
        <SimpleText
          translateId={`${translationPath}.title`}
          translateData={{ startDate: toDateString(startDate), endDate: toDateString(endDate) }}
          variant='h2'
        />
        <div style={{ overflow: 'auto' }}>
          <KPIsTable
            performanceInfo={performanceInfo}
            benchmarkPerformanceInfos={benchmarkPerfInfos}
            startDate={startDate}
            endDate={endDate}
            points={points}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default KPIsTableCard;

import axios from 'axios';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import createLogger from 'lib/logging/logging';
import {
  addElementsToDict,
  inspect,
  removeObjectFromArray,
  replaceObjectInArray,
} from 'lib/misc/misc';
import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import { Id, Ids, ResourceNameT, Url, ValueOf } from 'lib/types';
import { addError, addSmallNotification } from 'redux/notifications/actions';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';
import { userGroupsKeys } from 'screens/UserGroup/lib/queries';
import { SmallNotification } from 'utils/constants';

interface QueryKeyObj {
  scope: string;
}

interface QueryKeyListsObj extends QueryKeyObj {
  entity: 'list';
}

interface QueryKeyDetailsObj extends QueryKeyObj {
  entity: 'detail';
}

interface ListParams {
  instituteId: Id;
  filter?: string;
}
interface ListReturn extends QueryKeyListsObj {
  instituteId: Id;
}

interface DetailParams {
  id: Id;
}
interface DetailReturn extends QueryKeyDetailsObj {
  id: Id;
}

type QueryKeyListFunc = (params: ListParams) => readonly [ListReturn];
type QueryKeyDetailFunc = (params: DetailParams) => readonly [DetailReturn];

type QueryKey = readonly [QueryKeyObj];
type QueryKeyLists = readonly [QueryKeyListsObj];
type QueryKeyDetails = readonly [QueryKeyDetailsObj];

export const REACT_APP_ROBO_API_URL = `${window.location.protocol}//${window.location.hostname}`;
// for dev without nginx service - export ROBO_API_URL=http://localhost:5001/api
export const ROBO_API_URL = `${REACT_APP_ROBO_API_URL}/api`;
type Token = string;
const log: any = createLogger('service');
const tokenConfig = (token: Token | null) => {
  if (!token) {
    return {};
  }
  return {
    headers: {
      Authorization: token,
    },
  };
};

function getLocalToken() {
  return localStorage.getItem('token');
}

function generateTokenConfig() {
  const token = getLocalToken();
  log.debug(`generateTokenConfig(token=${token})`);
  return tokenConfig(token);
}

export function apiUrlFor(url: Url) {
  return `${ROBO_API_URL}/${url}`;
}

export async function get(url: Url, params?: object) {
  log.debug(`get(url=${url}, data=${params})`);
  const response = await axios.get(apiUrlFor(url), {
    ...generateTokenConfig(),
    // cancelToken: getSourceForInstitute(getLocalInstituteId()).token,
    params,
  });
  return response.data;
}

export async function del(url: Url) {
  const response = await axios.delete(apiUrlFor(url), { ...generateTokenConfig() });
  return response.data;
}

export async function put(url: Url, data?: object) {
  log.debug(`put URL:${url} Data: ${inspect(data)}`);
  const response = await axios.put(apiUrlFor(url), data, { ...generateTokenConfig() });
  return response.data;
}

export async function post(url: Url, data: object, params?: object) {
  const response = await axios.post(apiUrlFor(url), data, { ...generateTokenConfig(), params });
  return response.data;
}

async function getCommon(url: Url, { queryKey: [{ id }] }: { queryKey: readonly [DetailParams] }) {
  return get(`${url}${id}/`);
}

async function getManyCommon(
  url: Url,
  { queryKey: [{ instituteId }] }: { queryKey: readonly [ListParams] }
) {
  return get(url, { 'institute-id': instituteId });
}

async function delCommon(url: Url, id: Id) {
  return del(`${url}${id}/`);
}

async function putCommon(url: Url, id: Id, data: any) {
  return put(`${url}${id}/`, data);
}

async function postCommon(url: Url, data: any, instituteId: Id) {
  return post(url, data, { 'institute-id': instituteId });
}

interface Data {
  id: Id;
}
interface TMapping<TData> {
  [key: Id]: TData;
}

/**
 * Helper function to convert array to object.
 * Use as 'select' option in 'useQuery' hook.
 * @param data {Array[object]} Each object in array should have at least 'id' key.
 * @return {Object[object]}
 */
export function selectListToDict<TData>(data: ReadonlyArray<TData>): TMapping<TData> {
  return addElementsToDict({}, data);
}

export const useObjectsQueryCommon = (
  url: Url,
  queryKeyList: QueryKeyListFunc,
  { filter = 'all' } = {}
) => {
  const { instituteId } = useCurrentInstitute();
  const getFn = useCallback(queryKey => getManyCommon(url, queryKey), [url]);
  return useQuery(queryKeyList({ filter, instituteId }), getFn, {
    select: selectListToDict,
  });
};

interface UseObjectQueryCommonOptions {
  queryKeyList?: QueryKeyListFunc;
}

export const useObjectQueryCommon = (
  id: Id,
  url: Url,
  queryKeyDetail: QueryKeyDetailFunc,
  { queryKeyList }: UseObjectQueryCommonOptions = {}
) => {
  const queryClient = useQueryClient();
  const { instituteId } = useCurrentInstitute();
  const getFn = useCallback(queryKey => getCommon(url, queryKey), [url]);
  return useQuery(queryKeyDetail({ id }), getFn, {
    enabled: !!id,
    initialData: () => {
      if (queryKeyList) {
        const objects = queryClient.getQueryData<Data[]>(queryKeyList({ instituteId }));
        if (objects) {
          const obj = objects.find(o => o.id === id);
          if (obj) {
            return obj;
          }
        }
      }
      return undefined;
    },
  });
};

interface UseCreateMutationCommonOptions {
  notification?: ValueOf<typeof SmallNotification>;
  queryKeyLists?: QueryKeyLists;
  queryKeyDetails?: QueryKeyDetails;
}

export function useCreateMutationCommon<T>(
  url: Url,
  {
    notification = SmallNotification.OBJECT_CREATED,
    queryKeyLists,
    queryKeyDetails,
  }: UseCreateMutationCommonOptions = {}
) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { instituteId } = useCurrentInstitute();
  return useMutation((data: T) => postCommon(url, data, instituteId), {
    onSuccess: data => {
      if (queryKeyLists) {
        queryClient.setQueriesData<Data[]>(
          [{ ...queryKeyLists[0], instituteId: data.institute }],
          prev => (prev ? [...prev, data] : [data])
        );
      }
      if (queryKeyDetails) {
        queryClient.setQueriesData([{ ...queryKeyDetails[0], id: data.id }], data);
      }
      if (notification) {
        dispatch(addSmallNotification(notification));
      }
      queryClient.invalidateQueries(userGroupsKeys.all);
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
}

interface UseUpdateMutationCommonOptions {
  notification?: ValueOf<typeof SmallNotification>;
  queryKeyLists?: QueryKeyLists;
  queryKeyDetails?: QueryKeyDetails;
  queryKeyInvalidate?: QueryKey;
}

export function useUpdateMutationCommon<T>(
  url: Url,
  {
    notification = SmallNotification.OBJECT_SAVED,
    queryKeyLists,
    queryKeyDetails,
    queryKeyInvalidate,
  }: UseUpdateMutationCommonOptions = {}
) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(({ id, data }: { id: Id; data: T }) => putCommon(url, id, data), {
    onSuccess: (data, variables) => {
      if (queryKeyLists) {
        queryClient.setQueriesData([{ ...queryKeyLists[0], instituteId: data.institute }], prev =>
          replaceObjectInArray(prev, data)
        );
      }
      if (queryKeyDetails) {
        queryClient.setQueriesData([{ ...queryKeyDetails[0], id: data.id }], data);
      }
      if (notification) {
        const keys = Object.keys(variables.data);
        const key = keys[0];
        if (keys.length === 1 && key === 'name') {
          dispatch(addSmallNotification(SmallNotification.NAME_CHANGED));
        } else {
          dispatch(addSmallNotification(notification));
        }
      }
      if (queryKeyInvalidate) {
        queryClient.invalidateQueries(queryKeyInvalidate);
      }
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
}

interface UseDeleteMutationCommonOptions {
  notification?: ValueOf<typeof SmallNotification>;
  queryKeyLists?: QueryKeyLists;
  queryKeyDetails?: QueryKeyDetails;
}

export const useDeleteMutationCommon = (
  url: Url,
  {
    notification = SmallNotification.OBJECT_DELETED,
    queryKeyLists,
    queryKeyDetails,
  }: UseDeleteMutationCommonOptions
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mutation = useMutation((id: Id) => delCommon(url, id), {
    onSuccess: (data, id) => {
      if (queryKeyLists) {
        queryClient.setQueriesData(queryKeyLists, prev => removeObjectFromArray(prev, id));
      }
      if (queryKeyDetails) {
        // If I invalidate or remove the query, it is directly recreated, while we are on the screen
        // for a brief moment.
        // queryClient.invalidateQueries([{ ...queryKeyDetails[0], id }]);
      }
      if (notification) {
        dispatch(addSmallNotification(notification));
      }
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
  return { ...mutation, delete: mutation.mutate };
};

interface ObjectsUpdatedEventData {
  ids: Ids;
  instituteId: Id;
  resourceName: ResourceNameT;
}

export const useObjectsUpdatedWebsockets = () => {
  const queryClient = useQueryClient();
  return (data: ObjectsUpdatedEventData) => {
    queryClient.invalidateQueries([
      { scope: data.resourceName, entity: 'list', instituteId: data.instituteId },
    ]);
    data.ids.forEach(id => {
      queryClient.invalidateQueries([{ scope: data.resourceName, entity: 'detail', id }]);
    });
  };
};

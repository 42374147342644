import { useMemo } from 'react';
import { useQueries, useQuery } from 'react-query';

import { ResourceName } from 'lib/constants';
import { get, selectListToDict } from 'lib/queries';

export const envKeys = {
  all: [{ scope: 'environment' }],
};

const benchmarkKeys = {
  all: [{ scope: ResourceName.BENCHMARK }],
  lists: () => [{ ...benchmarkKeys.all[0], entity: 'list' }],
  list: () => [{ ...benchmarkKeys.lists()[0] }],
};

const assetsKeys = {
  all: [{ scope: 'asset' }],
  details: () => [{ ...assetsKeys.all[0], entity: 'detail' }],
  detail: ({ id }) => [{ ...assetsKeys.details()[0], id }],
  adminList: () => [{ ...assetsKeys.all[0], entity: 'adminList' }],
};

async function getEnvironment() {
  return get('environment/');
}

async function getAsset({ queryKey: [{ id }] }) {
  return get(`assets/${id}/`, { with_ts: true });
}

async function getBenchmarks() {
  return get('benchmarks/');
}

async function getAssetsAdmin() {
  return get('assets/admin/');
}

const typesToFields = {
  SUPER_SECTOR: 'superSector',
  SECTOR: 'sector',
  COUNTRY: 'country',
  CLASS: 'assetClass',
  TYPE: 'assetType',
  CURRENCY: 'currency',
};

const selectAssets = data => {
  const { assets: assetsArray, assetGroups: assetGroupsArray } = data;
  const assetGroups = selectListToDict(assetGroupsArray);
  const updatedAssetsArray = assetsArray.map(asset => {
    const updatedAsset = { ...asset };
    updatedAsset.instituteIdentifier = updatedAsset.isin;
    updatedAsset.assetGroups.forEach(assetGroupId => {
      const assetGroup = assetGroups[assetGroupId];
      if (assetGroup?.typ && typesToFields[assetGroup.typ]) {
        const field = typesToFields[assetGroup.typ];
        updatedAsset[field] = assetGroup.name;
      }
    });
    return updatedAsset;
  });

  return selectListToDict(updatedAssetsArray);
};

const selectAssetGroups = data => {
  const { assetGroups: assetGroupsArray } = data;
  return selectListToDict(assetGroupsArray);
};

const selectAssetAllocations = data => {
  const { assetAllocations: assetAllocationsArray } = data;
  return selectListToDict(assetAllocationsArray);
};

export const useAssetQueries = assetIds => {
  const queries = useMemo(() => {
    return assetIds.map(id => {
      return {
        queryKey: assetsKeys.detail({ id }),
        queryFn: () => getAsset({ queryKey: [{ id }] }),
      };
    });
  }, [assetIds]);
  return useQueries(queries);
};

export const useAssetsQuery = () => {
  const query = useQuery(envKeys.all, getEnvironment, {
    select: selectAssets,
  });
  return {
    ...query,
    assets: query.data || {},
  };
};

function findCash(assets) {
  return Object.values(assets).find(asset => asset.isCash);
}

export const useCashQuery = () => {
  const query = useAssetsQuery();
  const cash = useMemo(() => findCash(query.assets), [query.assets]);
  return {
    ...query,
    assets: undefined,
    cash,
    data: cash,
  };
};

export const useAssetsAdminQuery = () => {
  const query = useQuery(assetsKeys.adminList(), getAssetsAdmin, {
    select: selectListToDict,
  });
  return {
    ...query,
    assets: query.data || {},
  };
};

export const useAssetGroupsQuery = () => {
  const query = useQuery(envKeys.all, getEnvironment, {
    select: selectAssetGroups,
  });
  return {
    ...query,
    assetGroups: query.data || {},
  };
};

export const useAssetAllocationsQuery = () => {
  const query = useQuery(envKeys.all, getEnvironment, {
    select: selectAssetAllocations,
  });
  return {
    ...query,
    assetAllocations: query.data || {},
  };
};

export const useAssetAllocationQuery = id => {
  const { assetAllocations, ...query } = useAssetAllocationsQuery();
  return {
    ...query,
    assetAllocation: assetAllocations?.[id],
  };
};

export const useBenchmarksQuery = () => {
  const query = useQuery(benchmarkKeys.list(), getBenchmarks, {
    select: selectListToDict,
  });
  return {
    ...query,
    benchmarks: query.data || {},
  };
};

import { Dialog, DialogActions, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import DialogButton from 'components/Buttons/DialogButton';
import Table from 'components/Table';
import { useUsersQuery, useUsersQueryAdmin } from 'screens/User/lib/queries';
import { DialogKeys } from 'utils/constants';
import { idArrayType } from 'utils/types';

const UsersList = ({ draftUserIds, dispatchDraftUserIds, disabled, users }) => {
  const { draftUsers, availableUsers } = useMemo(() => {
    return {
      draftUsers: Object.values(users).filter(user => draftUserIds.includes(user.id)),
      availableUsers: Object.values(users).filter(user => !draftUserIds.includes(user.id)),
    };
  }, [users, draftUserIds]);

  const [open, setOpen] = React.useState(false);
  const [checkedUserIds, setCheckedUserIds] = useState([]);

  const handleUserIdsToggle = userId => {
    const currentIndex = checkedUserIds.indexOf(userId);
    const newChecked = [...checkedUserIds];

    if (currentIndex === -1) {
      newChecked.push(userId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedUserIds(newChecked);
  };

  const handleDiscard = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    dispatchDraftUserIds({ type: 'add', value: checkedUserIds });
    setOpen(false);
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='add-user-dialog-title'
        TransitionProps={{
          onExited: () => setCheckedUserIds([]),
        }}
      >
        <DialogContent>
          <Table
            title='addUsers'
            translateTableId='usersTable'
            tableObjects={availableUsers}
            columns={['id', 'email', 'name', 'surname']}
            deletableRows={false}
            isWithCheckbox
            isDownloadButton
            isTableWhite
            showEmptyRows={false}
            onRowClick={userId => handleUserIdsToggle(userId)}
            onSelectAllClick={(userIds, numSelected) => {
              if (numSelected === 0) {
                setCheckedUserIds(userIds);
              } else {
                setCheckedUserIds([]);
              }
            }}
            selectedObjectIds={checkedUserIds}
          />
        </DialogContent>
        <DialogActions>
          <DialogButton
            onClick={() => handleDiscard()}
            translateId={`dialogKey.${DialogKeys.DISCARD}`}
            variant='lowEmp'
          />
          <DialogButton
            onClick={() => handleAdd()}
            translateId='userGroupScreen.dialogKey.addUsers'
            variant='lowEmp'
          />
        </DialogActions>
      </Dialog>
      <Table
        title='users'
        translateTableId='usersTable'
        tableObjects={draftUsers}
        columns={['id', 'email', 'name', 'surname']}
        deletableRows={!disabled}
        isWithCheckbox={!disabled}
        isDownloadButton
        isTableWhite
        showEmptyRows={false}
        onDeleteRowClick={user => dispatchDraftUserIds({ type: 'remove', value: [user.id] })}
        onDeleteSelectedClick={userIds => dispatchDraftUserIds({ type: 'remove', value: userIds })}
        onAddRowClick={() => setOpen(true)}
        addRowTranslateId='userGroupScreen.addUsersButton'
      />
    </>
  );
};

UsersList.propTypes = {
  draftUserIds: idArrayType.isRequired,
  dispatchDraftUserIds: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

UsersList.defaultProps = {
  disabled: false,
};

export const InstituteUsersTable = ({ draftUserIds, dispatchDraftUserIds, disabled }) => {
  const { users } = useUsersQuery();
  return (
    <UsersList
      draftUserIds={draftUserIds}
      dispatchDraftUserIds={dispatchDraftUserIds}
      disabled={disabled}
      users={users}
    />
  );
};

export const UsersTableAdmin = ({ draftUserIds, dispatchDraftUserIds, disabled }) => {
  const { users } = useUsersQueryAdmin();
  return (
    <UsersList
      draftUserIds={draftUserIds}
      dispatchDraftUserIds={dispatchDraftUserIds}
      disabled={disabled}
      users={users}
    />
  );
};

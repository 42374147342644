import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo } from 'react';

import { ChildrenType } from 'utils/types';

const useStyles = makeStyles({
  footerWrapper: {
    display: 'inline-block',
    marginLeft: '0.5rem',
    marginBottom: '0.3rem',
    '& span': {
      display: 'inline-block',
      marginRight: '0.3rem',
      fontWeight: '600',
    },
  },
});

const WidgetFooter = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='flex-end'>
      <div className={classes.footerWrapper}>{children}</div>
    </Grid>
  );
};

WidgetFooter.propTypes = {
  children: ChildrenType.isRequired,
};

export default memo(WidgetFooter);

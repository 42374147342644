import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import SimpleText from 'components/SimpleText';
import { useTranslate } from 'lib/localization/localization';
import { getAlertsInfo } from 'screens/Portfolio/lib/helpers';
import { PortfolioType } from 'utils/types';

const PortfolioMessages = ({ portfolio }) => {
  const translate = useTranslate();
  const alertsInfo = useMemo(() => getAlertsInfo(portfolio), [portfolio]);
  if (alertsInfo.alertCount === 0) {
    return <Typography sx={{ color: 'text.primary' }}>{translate('noAlerts')}</Typography>;
  }
  let errorMessage;
  if (alertsInfo.alertCount === 1) {
    errorMessage = (
      <SimpleText
        translateId={`portfolioLib.alerts.${alertsInfo.errorMessages[0].translateId}`}
        translateData={alertsInfo.errorMessages[0].translateData}
      />
    );
  } else {
    errorMessage = <SimpleText translateId='portfolioLib.alerts.multipleConstraintsViolated' />;
  }
  return (
    <Stack direction='row' alignItems='center' spacing={2} sx={{ color: 'error.main' }}>
      <ErrorOutlineIcon />
      <Typography>{errorMessage}</Typography>
    </Stack>
  );
};

PortfolioMessages.propTypes = {
  portfolio: PortfolioType.isRequired,
};

export default PortfolioMessages;

import GroupIcon from '@mui/icons-material/Group';
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListSubheader,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';
import { Translate } from 'react-localize-redux';

import DialogButton from 'components/Buttons/DialogButton';
import MyDropdown from 'components/MyDropdown/MyDropdown';
import ObjectAccessDialogListItem from 'components/ObjectAccessDialog/ObjectAccessDialogListItem';
import SimpleText from 'components/SimpleText';
import { PermissionLevel } from 'lib/access/constants';
import { useChangeAccessMutation } from 'lib/access/queries';
import useAdminPermission from 'lib/access/useAdminPermission';
import useCheckAccessTo from 'lib/access/useCheckAccessTo';
import { useUsersQuery } from 'screens/User/lib/queries';
import { useUserGroupsQuery } from 'screens/UserGroup/lib/queries';
import { DialogKeys } from 'utils/constants';
import { ResourceType } from 'utils/types';

const useStyles = makeStyles({
  card: {
    height: '100%',
  },
  creatorText: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  dropdown: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
});

const ObjectAccessDialog = ({ object }) => {
  const classes = useStyles();
  const { mutate: updateAccess, isLoading: isLoadingUpdate } = useChangeAccessMutation(
    object.tablename,
    object.id
  );
  const { userGroups } = useUserGroupsQuery();
  const { users } = useUsersQuery();

  const [newOwnerId, setNewOwnerId] = React.useState(object.user);

  const [open, setOpen] = React.useState(false);
  const [newPermissionLevels, setPermissionLevelChanges] = React.useState({});

  const hasAdminPermission = useAdminPermission(object);
  const { actualPermissionLevels, possiblePermissionLevelsByGroup } = useCheckAccessTo(
    object,
    newPermissionLevels
  );

  const groupsByPermissionLevel = useMemo(() => {
    const result = {
      [PermissionLevel.NO]: [],
      [PermissionLevel.READ]: [],
      [PermissionLevel.EDIT]: [],
      [PermissionLevel.ADMIN]: [],
    };
    Object.entries(actualPermissionLevels).forEach(([userGroupId, permissionLevel]) => {
      result[permissionLevel].push(userGroupId);
    });
    return result;
  }, [actualPermissionLevels]);

  const handleOnExited = () => {
    setPermissionLevelChanges({});
    setNewOwnerId(object.user);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    updateAccess({
      newPermissionLevels,
      newOwnerId: newOwnerId !== object.user ? newOwnerId : null,
    });

    handleClose();
  };

  return (
    <>
      <Tooltip title={<Translate id='objectAccessDialog.iconTooltip' />}>
        <IconButton onClick={handleClickOpen} size='large'>
          <GroupIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={open}
        onClose={handleClose}
        aria-labelledby='access-dialog-title'
        TransitionProps={{
          onExited: handleOnExited,
        }}
      >
        <DialogTitle id='access-dialog-title'>
          <SimpleText variant='h2' translateId='dialogHeader.OBJECT_ACCESS' />
        </DialogTitle>
        <DialogContent>
          <MyDropdown
            className={classes.dropdown}
            value={newOwnerId}
            onChange={setNewOwnerId}
            onUpdateSearch={null}
            placeholderId='owner'
            isNullAllowed={false}
            disabled={!hasAdminPermission}
          >
            {Object.values(users).map(user => (
              <MenuItem key={user.id} value={user.id}>
                {user.name && user.surname ? (
                  <SimpleText text={`${user.name} ${user.surname}`} />
                ) : (
                  <SimpleText text={user.email} />
                )}
              </MenuItem>
            ))}
          </MyDropdown>
          <Grid container spacing={2}>
            {Object.entries(groupsByPermissionLevel).map(([permissionLevel, userGroupIds]) => (
              <Grid item xs={12} sm={6} lg={3} key={permissionLevel}>
                <Card className={classes.card}>
                  <List
                    dense={false}
                    key={permissionLevel}
                    subheader={
                      <ListSubheader
                        component={SimpleText}
                        variant='h3'
                        translateId={`objectAccessDialog.permissionLevel.${permissionLevel}`}
                      />
                    }
                  >
                    {userGroupIds.map(userGroupId => (
                      <ObjectAccessDialogListItem
                        key={userGroupId}
                        userGroup={userGroups[userGroupId]}
                        currentPermissionLevel={permissionLevel}
                        possiblePermissionLevels={
                          hasAdminPermission ? possiblePermissionLevelsByGroup[userGroupId] : []
                        }
                        onPermissionLevelChange={newPermissionLevel => {
                          setPermissionLevelChanges(prevState => ({
                            ...prevState,
                            [userGroupId]: newPermissionLevel,
                          }));
                        }}
                      />
                    ))}
                  </List>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          {hasAdminPermission ? (
            <>
              <DialogButton
                onClick={handleClose}
                translateId={`dialogKey.${DialogKeys.DISCARD}`}
                variant='lowEmp'
              />
              <DialogButton
                onClick={handleSave}
                translateId={`dialogKey.${DialogKeys.SAVE}`}
                variant='lowEmp'
                disabled={isLoadingUpdate}
              />
            </>
          ) : (
            <DialogButton
              onClick={handleClose}
              translateId={`dialogKey.${DialogKeys.CLOSE}`}
              variant='lowEmp'
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

ObjectAccessDialog.propTypes = {
  object: ResourceType.isRequired,
};

export default ObjectAccessDialog;

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Card, CardMedia, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
  },
  media: {
    filter: 'blur(6px)',
  },
  overlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
});

const WidgetNotAvailable = ({ image, message }) => {
  const classes = useStyles();

  return (
    <Card style={{ position: 'relative' }}>
      <CardMedia
        component='img'
        alt='Widget is not available'
        image={image}
        title='Widget is not available'
        className={classNames(classes.root, classes.media)}
      />
      <div className={classes.overlay}>
        <ErrorOutlineIcon color='primary' fontSize='large' style={{ marginBottom: '0.4rem' }} />
        {message && <Typography variant='h3'>{message}</Typography>}
      </div>
    </Card>
  );
};

WidgetNotAvailable.propTypes = {
  image: PropTypes.string.isRequired,
  message: PropTypes.string,
};

WidgetNotAvailable.defaultProps = {
  message: null,
};

export default WidgetNotAvailable;

import { Dashboard, PieChart } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import chroma from 'chroma-js';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ContainerDimensions from 'react-container-dimensions';
import Plot from 'react-plotly.js';

import { AssetAllocationsType, ClassesType, idArrayType, ThemeType } from '../../utils/types';
import SimpleIconButton from '../SimpleIconButton';

import { defineNumColors, makeData, TREEMAP_TYPE, PIECHART_TYPE } from './utils';

import useLocalStorage from 'lib/misc/useLocalStorage';

const styles = () => ({
  chartTypeSwitch: {
    zIndex: 100,
    position: 'absolute',
    '@media print': {
      display: 'none',
    },
  },
});

const PortfolioAllocationChart = ({
  priority,
  weights,
  assets,
  assetGroups,
  assetAllocations,
  noLeafs,
  layout,
  config,
  additionalWidth,
  additionalHeight,
  classes,
  theme,
}) => {
  const [chartType, setChartType] = useLocalStorage(
    'PortfolioAllocationChart.chartType',
    PIECHART_TYPE
  );
  const otherChartType = chartType === TREEMAP_TYPE ? PIECHART_TYPE : TREEMAP_TYPE;

  const data = useMemo(
    () => makeData(chartType, priority, weights, assets, assetGroups, assetAllocations, noLeafs),
    [chartType, priority, weights, assets, assetGroups, assetAllocations, noLeafs]
  );
  const numColors = useMemo(() => defineNumColors(data), [data]);
  const layoutFinal = layout || {};
  const configFinal = config || {};

  const hovertemplate = '<b>%{label}</b><br> Percentage: %{value:.2f}%<extra></extra>';
  const leaf = { opacity: 0.9 };

  const treemapPathbarHeight = 30;
  const dataFinal = {
    ...data,
    type: chartType,
    hovertemplate,
    leaf,
    marker: { line: { width: 0.5, color: 'rgb(255, 255, 255)' } },
    pathbar: { visible: true, thickness: treemapPathbarHeight },
    branchvalues: 'total',
  };

  const brightColor = theme.palette.graphs.bright;
  const darkColor = theme.palette.graphs.dark;
  const colorSet = chroma
    .scale(chroma.bezier([brightColor, darkColor]).scale())
    .correctLightness()
    .colors(numColors);
  return (
    <ContainerDimensions>
      {({ width, height }) => (
        <>
          <SimpleIconButton
            onClick={() => {
              setChartType(otherChartType);
            }}
            translateId={otherChartType}
            className={classes.chartTypeSwitch}
          >
            {otherChartType === TREEMAP_TYPE ? <Dashboard /> : <PieChart />}
          </SimpleIconButton>

          <Plot
            data={[dataFinal]}
            layout={{
              ...layoutFinal,
              width: width + additionalWidth,
              height: height + additionalHeight,
              paper_bgcolor: 'rgba(255,255,255,0)',
              plot_bgcolor: 'rgba(255,255,255,0)',
              colorway: colorSet,
              margin: {
                l: 0,
                r: 0,
                b: 5,
                t: chartType === TREEMAP_TYPE ? treemapPathbarHeight + 2 : 5,
              },
            }}
            config={{ ...configFinal, displaylogo: false, modeBarButtonsToRemove: ['toImage'] }}
          />
        </>
      )}
    </ContainerDimensions>
  );
};

PortfolioAllocationChart.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  weights: PropTypes.any.isRequired,
  assetAllocations: AssetAllocationsType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assetGroups: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assets: PropTypes.any.isRequired,
  priority: idArrayType.isRequired,
  noLeafs: PropTypes.bool,
  layout: PropTypes.objectOf(PropTypes.any),
  config: PropTypes.objectOf(PropTypes.any),
  additionalWidth: PropTypes.number,
  additionalHeight: PropTypes.number,
  classes: ClassesType.isRequired,
  theme: ThemeType.isRequired,
};

PortfolioAllocationChart.defaultProps = {
  noLeafs: false,
  additionalWidth: 0,
  additionalHeight: 0,
  layout: null,
  config: null,
};

export default withStyles(styles, { withTheme: true })(PortfolioAllocationChart);

import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ALL_WEIGHTS_TABLE_COLUMNS } from 'lib/weights/constants';
import usePortfolioAllocationDropdowns from 'screens/Portfolio/lib/hooks/usePortfolioAllocationDropdowns';
import {
  restoreWidgetConfig,
  updateWidgetConfig,
  updateWidgetSavedValues,
  updateWidgetSize,
} from 'screens/Report/redux/actions';
import ConfigurationFrame from 'screens/Report/ReportScreen/components/ConfigurationFrame';
import useChipSelection from 'screens/Report/ReportScreen/components/hooks/useChipSelection';
import useSizeDropdown from 'screens/Report/ReportScreen/components/hooks/useSizeDropdown';
import useSortingField from 'screens/Report/ReportScreen/components/hooks/useSortingField';
import {
  nameToWidgetMapping,
  sizeMappings,
} from 'screens/Report/ReportScreen/configuration/dashboard.config';
import { widgetType } from 'utils/types';

const useStyles = makeStyles(theme => ({
  dropdown: {
    paddingTop: theme.spacing(4),
  },
}));

const WeightsTableConfiguration = ({ closeAction, config }) => {
  const {
    columns,
    portfolio: configPortfolio,
    size: configSize,
    sort,
    allocationType: configAllocationType,
  } = config.configuration;
  const classes = useStyles();
  const { portfolioId: currentPortfolioId } = useParams();
  const dispatch = useDispatch();

  const {
    selectedPortfolioId,
    selectedAllocType,
    portfolioDropdownElem,
    allocationTypeDropdownElem,
  } = usePortfolioAllocationDropdowns({
    initialPortfolioId: configPortfolio?.value ?? currentPortfolioId,
    initialAllocationType: configAllocationType?.value,
  });

  const isFirstRun = useRef(true);

  const [SizeDropdown, size] = useSizeDropdown(configSize.value);

  const columnsInput = useMemo(() => {
    return ALL_WEIGHTS_TABLE_COLUMNS.map((column, index) => ({
      removable: true,
      key: index,
      label: column,
      checked: columns.value.includes(column),
    }));
  }, [columns.value]);

  const [ChipsField, columnsData] = useChipSelection(columnsInput);

  const checkedCount = columnsData.filter(column => column.checked).length;

  const [SortingField, sortColumn, order] = useSortingField(
    columnsData,
    sort.value[0],
    sort.value[1]
  );

  useEffect(() => {
    dispatch(
      updateWidgetConfig(config.id, {
        portfolio: {
          ...config.configuration.portfolio,
          value: selectedPortfolioId,
        },
        columns: {
          ...config.configuration.columns,
          value: columnsData.filter(item => item.checked).map(item => item.label),
        },
        size: {
          ...config.configuration.size,
          value: size,
        },
        sort: {
          ...config.configuration.sort,
          value: [sortColumn, order],
        },
        allocationType: {
          ...config.configuration.allocationType,
          value: selectedAllocType,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsData, order, size, sortColumn, selectedAllocType, selectedPortfolioId]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    dispatch(
      updateWidgetSize(config.id, {
        w: nameToWidgetMapping.WeightsTableWidget[sizeMappings[size]],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);
  useEffect(() => {
    dispatch(updateWidgetSavedValues(config.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigurationFrame
      closeAction={() => {
        dispatch(restoreWidgetConfig(config.id));
        closeAction();
      }}
      saveAction={() => {
        dispatch(updateWidgetSavedValues(config.id));
        closeAction();
      }}
    >
      <div className={classes.dropdown}>{portfolioDropdownElem}</div>
      <div className={classes.dropdown}>{allocationTypeDropdownElem}</div>
      <ChipsField
        translateSource='dashboard'
        label='columns'
        addDisabled={checkedCount === ALL_WEIGHTS_TABLE_COLUMNS.length}
        addColor={checkedCount < ALL_WEIGHTS_TABLE_COLUMNS.length ? 'primary' : 'disabled'}
      />
      <SizeDropdown />
      <SortingField />
    </ConfigurationFrame>
  );
};

WeightsTableConfiguration.propTypes = {
  closeAction: PropTypes.func.isRequired,
  config: widgetType.isRequired,
};

export default WeightsTableConfiguration;

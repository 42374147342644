import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import useAllocationDropdown from 'lib/weights/useAllocationDropdown';
import usePortfolioAllocationDropdowns from 'screens/Portfolio/lib/hooks/usePortfolioAllocationDropdowns';
import {
  restoreWidgetConfig,
  updateWidgetConfig,
  updateWidgetSavedValues,
} from 'screens/Report/redux/actions';
import ConfigurationFrame from 'screens/Report/ReportScreen/components/ConfigurationFrame';
import { widgetType } from 'utils/types';

const useStyles = makeStyles(theme => ({
  dropdown: {
    paddingTop: theme.spacing(4),
  },
}));

const PieChartConfiguration = ({ closeAction, config }) => {
  const { portfolioId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    portfolio: configPortfolio,
    category: configCategory,
    allocationType: configAllocationType,
  } = config.configuration;

  const {
    selectedAssetAllocType: selectedCategory,
    assetAllocTypeDropdownElem: categoryDropdownElem,
  } = useAllocationDropdown({ initialAllocationType: configCategory?.value });

  const {
    selectedPortfolioId,
    selectedAllocType,
    portfolioDropdownElem,
    allocationTypeDropdownElem,
  } = usePortfolioAllocationDropdowns({
    initialPortfolioId: configPortfolio?.value || portfolioId,
    initialAllocationType: configAllocationType?.value,
  });

  useEffect(() => {
    dispatch(
      updateWidgetConfig(config.id, {
        portfolio: {
          ...config.configuration.portfolio,
          value: selectedPortfolioId,
        },
        category: {
          ...config.configuration.category,
          value: selectedCategory,
        },
        allocationType: {
          ...config.configuration.allocationType,
          value: selectedAllocType,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, selectedPortfolioId, selectedAllocType]);

  useEffect(() => {
    dispatch(updateWidgetSavedValues(config.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ConfigurationFrame
      closeAction={() => {
        dispatch(restoreWidgetConfig(config.id));
        closeAction();
      }}
      saveAction={() => {
        dispatch(updateWidgetSavedValues(config.id));
        closeAction();
      }}
    >
      <div className={classes.dropdown}>{portfolioDropdownElem}</div>
      <div className={classes.dropdown}>{allocationTypeDropdownElem}</div>
      <div className={classes.dropdown}>{categoryDropdownElem}</div>
    </ConfigurationFrame>
  );
};

PieChartConfiguration.propTypes = {
  closeAction: PropTypes.func.isRequired,
  config: widgetType.isRequired,
};

export default PieChartConfiguration;

import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import RouteWithAuth from 'components/MyRouting/RouteWithAuth';
import AnalysisScreen from 'screens/Analysis/AnalysisScreen/AnalysisScreen';
import ChooseAnalysisScreen from 'screens/Analysis/ChooseAnalysisScreen';
import ChooseGroupConstraintListScreen from 'screens/AssetAllocation/ChooseGroupConstraintListScreen';
import GroupConstraintListScreen from 'screens/AssetAllocation/GroupConstraintListScreen';
import BacktestScreen from 'screens/Backtest/BacktestScreen';
import ChooseBacktestsScreen from 'screens/Backtest/ChooseBacktestsScreen';
import AssetsScreen from 'screens/Environment/AssetsScreen';
import HelpScreen from 'screens/Help/HelpScreen';
import AdminChooseInstituteScreen from 'screens/Institute/AdminChooseInstituteScreen';
import AdminInstituteScreen from 'screens/Institute/AdminInstituteScreen';
import InstituteScreen from 'screens/Institute/InstituteScreen/InstituteScreen';
import ChangePasswordScreen from 'screens/Login/ChangePasswordScreen';
import ForgetPasswordScreen from 'screens/Login/ForgetPasswordScreen';
import LoginScreen from 'screens/Login/LoginScreen';
import RegisterScreen from 'screens/Login/RegisterScreen';
import PortfolioListScreen from 'screens/Portfolio/ChoosePortfoliosScreen';
import PortfolioReconfigurationScreen from 'screens/Portfolio/PortfolioReconfigurationScreen';
import PortfolioScreen from 'screens/Portfolio/PortfolioScreen';
import ChooseReportsScreen from 'screens/Report/ChooseReportsScreen/ChooseReportsScreen';
import ReportScreen from 'screens/Report/ReportScreen/ReportScreen';
import ChooseResearchConstraintListScreen from 'screens/Research/ChooseResearchConstraintListScreen';
import ChooseResearchListScreen from 'screens/Research/ChooseResearchListScreen';
import ResearchConstraintListScreen from 'screens/Research/ResearchConstraintListScreen';
import ResearchListScreen from 'screens/Research/ResearchListScreen';
import BasketScreen from 'screens/Universe/BasketScreen';
import ChooseBasketScreen from 'screens/Universe/ChooseBasketScreen';
import ChooseUniverseScreen from 'screens/Universe/ChooseUniverseScreen';
import UniverseScreen from 'screens/Universe/UniverseScreen';
import AdminChooseUserScreen from 'screens/User/AdminChooseUserScreen';
import AdminUserScreen from 'screens/User/AdminUserScreen';
import ChooseUserGroupScreen from 'screens/UserGroup/ChooseUserGroupScreen';
import UserGroupScreen from 'screens/UserGroup/UserGroupScreen';
import { Routes } from 'utils/constants';
import { Route } from 'utils/extensions';

const MyRoutes = () => {
  return (
    <Switch>
      <Route path={Routes.LOGIN} component={LoginScreen} />
      <Route path={Routes.REGISTER} component={RegisterScreen} />
      <Route path={Routes.FORGOT_PASSWORD} component={ForgetPasswordScreen} />
      <Route path={Routes.CHANGE_PASSWORD} component={ChangePasswordScreen} />

      <RouteWithAuth path={Routes.HELP} component={HelpScreen} />

      <RouteWithAuth path={[Routes.SETTINGS_INSTITUTE]} component={InstituteScreen} exact />

      <RouteWithAuth path={Routes.BASKET} component={BasketScreen} />
      <RouteWithAuth path={Routes.NEW_BASKET} component={BasketScreen} />
      <RouteWithAuth path={Routes.CHOOSE_BASKET} component={ChooseBasketScreen} />

      <RouteWithAuth path={Routes.CHOOSE_PORTFOLIO} component={PortfolioListScreen} />
      <RouteWithAuth path={Routes.PORTFOLIO} exact component={PortfolioScreen} />
      <RouteWithAuth
        path={[Routes.PORTFOLIO_RECONFIGURATION, Routes.NEW_PORTFOLIO]}
        component={PortfolioReconfigurationScreen}
        exact
      />
      <RouteWithAuth
        path={[Routes.CHOOSE_BACKTEST, Routes.CHOOSE_BACKTEST_WITH_PORTFOLIO]}
        component={ChooseBacktestsScreen}
        exact
      />
      <RouteWithAuth
        path={[Routes.BACKTEST_WITH_PORTFOLIO, Routes.NEW_BACKTEST]}
        component={BacktestScreen}
        exact
      />
      <RouteWithAuth
        path={[Routes.CHOOSE_ANALYSIS, Routes.CHOOSE_ANALYSIS_WITH_PORTFOLIO]}
        component={ChooseAnalysisScreen}
        exact
      />
      <RouteWithAuth
        path={[Routes.ANALYSIS, Routes.NEW_ANALYSIS]}
        component={AnalysisScreen}
        exact
      />

      <RouteWithAuth path={[Routes.REPORT, Routes.NEW_REPORT]} component={ReportScreen} exact />
      <RouteWithAuth
        path={[Routes.CHOOSE_REPORT_WITH_PORTFOLIO, Routes.CHOOSE_REPORT]}
        component={ChooseReportsScreen}
        exact
      />

      <RouteWithAuth
        path={[Routes.UNIVERSE, Routes.NEW_UNIVERSE]}
        component={UniverseScreen}
        exact
      />
      <RouteWithAuth path={Routes.CHOOSE_UNIVERSE} component={ChooseUniverseScreen} />

      <RouteWithAuth
        path={[Routes.USER_GROUP, Routes.NEW_USER_GROUP]}
        component={UserGroupScreen}
        exact
      />
      <RouteWithAuth path={Routes.CHOOSE_USER_GROUP} component={ChooseUserGroupScreen} />

      <RouteWithAuth path={Routes.ADMIN_ASSETS} component={AssetsScreen} />
      <RouteWithAuth path={Routes.ADMIN_INSTITUTES} component={AdminChooseInstituteScreen} />
      <RouteWithAuth
        path={[Routes.ADMIN_INSTITUTE, Routes.ADMIN_NEW_INSTITUTE]}
        component={AdminInstituteScreen}
        exact
      />
      <RouteWithAuth path={Routes.ADMIN_USERS} component={AdminChooseUserScreen} />
      <RouteWithAuth
        path={[Routes.ADMIN_USER, Routes.ADMIN_NEW_USER]}
        component={AdminUserScreen}
        exact
      />

      <RouteWithAuth path={Routes.CHOOSE_RESEARCH_LIST} component={ChooseResearchListScreen} />
      <RouteWithAuth path={Routes.RESEARCH_LIST} component={ResearchListScreen} />
      <RouteWithAuth path={Routes.NEW_RESEARCH_LIST} component={ResearchListScreen} />

      <RouteWithAuth
        path={[Routes.CHOOSE_RESEARCH_CONSTRAINT_LIST]}
        component={ChooseResearchConstraintListScreen}
      />
      <RouteWithAuth
        path={Routes.RESEARCH_CONSTRAINT_LIST}
        component={ResearchConstraintListScreen}
      />
      <RouteWithAuth
        path={Routes.NEW_RESEARCH_CONSTRAINT_LIST}
        component={ResearchConstraintListScreen}
      />

      <RouteWithAuth
        path={Routes.CHOOSE_GROUP_CONSTRAINT_LIST}
        component={ChooseGroupConstraintListScreen}
      />
      <RouteWithAuth path={Routes.GROUP_CONSTRAINT_LIST} component={GroupConstraintListScreen} />
      <RouteWithAuth
        path={Routes.NEW_GROUP_CONSTRAINT_LIST}
        component={GroupConstraintListScreen}
      />

      <Route>
        <Redirect to={Routes.HOME} />
      </Route>
    </Switch>
  );
};

export default MyRoutes;

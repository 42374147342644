import PropTypes from 'prop-types';
import React from 'react';

import { useTranslate } from 'lib/localization/localization';
import ConstraintDropdown from 'screens/Research/lib/components/ConstraintDropdown';
import { AnyBackendObject } from 'utils/types';

const ConstraintsDropdowns = ({
  recommendationTypes,
  draftConstraints,
  setDraftConstraint,
  draftNoOpinionAction,
  setDraftNoOpinionAction,
  disabled,
}) => {
  const translate = useTranslate();
  return (
    <>
      {draftConstraints.map(draftConstraint => {
        const recommendationType = (recommendationTypes ?? []).find(
          type => type.id === draftConstraint.recommendationType
        );
        return (
          <ConstraintDropdown
            key={draftConstraint.assetGroup}
            value={draftConstraint?.action}
            onChange={value => setDraftConstraint({ ...draftConstraint, action: value })}
            disabled={disabled}
            text={recommendationType?.name}
          />
        );
      })}
      <ConstraintDropdown
        value={draftNoOpinionAction}
        onChange={setDraftNoOpinionAction}
        disabled={disabled}
        text={translate('researchConstraintListScreen.noOpinion')}
      />
    </>
  );
};

ConstraintsDropdowns.propTypes = {
  recommendationTypes: PropTypes.arrayOf(AnyBackendObject),
  draftConstraints: PropTypes.arrayOf(AnyBackendObject),
  setDraftConstraint: PropTypes.func,
  draftNoOpinionAction: PropTypes.string,
  setDraftNoOpinionAction: PropTypes.func,
  disabled: PropTypes.bool,
};

ConstraintsDropdowns.defaultProps = {
  recommendationTypes: [],
  draftConstraints: [],
  setDraftConstraint: () => {},
  draftNoOpinionAction: null,
  setDraftNoOpinionAction: () => {},
  disabled: false,
};

export default ConstraintsDropdowns;

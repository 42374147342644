import { Settings, Assessment } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useEditPermission from 'lib/access/useEditPermission';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { useReportQuery } from 'screens/Report/lib/queries';
import WidgetsHolder from 'screens/Report/ReportScreen/components/WidgetsHolder';
import PdfExport from 'screens/Report/ReportScreen/PdfExport';
import DashboardConfig from 'screens/Report/ReportScreen/ReportConfig';
import ImageWidgetConfiguration from 'screens/Report/ReportScreen/widgets/ImageWidget/ImageWidgetConfiguration';
import KPITableConfiguration from 'screens/Report/ReportScreen/widgets/KPITableWidget/KPITableConfiguration';
import LineChartConfiguration from 'screens/Report/ReportScreen/widgets/LineChartWidget/LineChartConfiguration';
import PieChartConfiguration from 'screens/Report/ReportScreen/widgets/PieChartWidget/PieChartConfiguration';
import TextWidgetConfiguration from 'screens/Report/ReportScreen/widgets/TextWidget/TextWidgetConfiguration';
import WeightsTableConfiguration from 'screens/Report/ReportScreen/widgets/WeightsTable/WeightsTableConfiguration';
import { widgetType } from 'utils/types';

const useStyles = makeStyles(theme => ({
  sidebar: {
    position: 'fixed',
    height: props => (props.hasEditPermission ? '215px' : '135px'),
    width: '55px',
    backgroundColor: '#FFFF',
    top: '50%',
    right: '0',
    marginTop: '-100px',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)',
    padding: theme.spacing(2, 2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 10,
  },
}));

const Sidebar = ({
  orientation,
  type,
  setType,
  show,
  parentShow,
  widgetConfig,
  disableAddWidgets,
  setSelectedWidget,
  setShowSettings,
  setSettingsType,
}) => {
  const editMode = useSelector(state => state.dashboardScreen.editMode);
  const { id, portfolioId } = useParams();
  const { report: reportInfo } = useReportQuery(id);
  const { portfolio } = usePortfolioQuery(portfolioId);
  const hasEditPermission = useEditPermission(portfolio);

  const [showHolder, setShowHolder] = useState(false);

  const classes = useStyles({ hasEditPermission });

  // TODO improve settings selection
  const mapSettings = {
    TextWidget: (
      <TextWidgetConfiguration config={widgetConfig} closeAction={() => parentShow(false)} />
    ),
    ImageWidget: (
      <ImageWidgetConfiguration config={widgetConfig} closeAction={() => parentShow(false)} />
    ),
    WeightsTableWidget: (
      <WeightsTableConfiguration
        config={widgetConfig}
        reportInfo={reportInfo}
        closeAction={() => parentShow(false)}
      />
    ),
    LineChartWidget: (
      <LineChartConfiguration
        config={widgetConfig}
        reportInfo={reportInfo}
        closeAction={() => parentShow(false)}
      />
    ),
    PieChartWidget: (
      <PieChartConfiguration
        config={widgetConfig}
        reportInfo={reportInfo}
        closeAction={() => parentShow(false)}
      />
    ),
    KPITableWidget: (
      <KPITableConfiguration
        config={widgetConfig}
        reportInfo={reportInfo}
        closeAction={() => parentShow(false)}
      />
    ),
    general: (
      <DashboardConfig closeAction={() => parentShow(false)} storedOrientation={orientation} />
    ),
  };

  return (
    <>
      {!show ? (
        <>
          {showHolder ? (
            <WidgetsHolder
              showHolder={setShowHolder}
              setSelectedWidget={setSelectedWidget}
              setShowSettings={setShowSettings}
              setSettingsType={setSettingsType}
            />
          ) : null}
          <div className={classes.sidebar}>
            <Tooltip title={<Translate id='dashboard.reportSettings' />}>
              <IconButton
                onClick={() => {
                  setType('general');
                  parentShow(!show);
                }}
                size='large'
              >
                <Settings fontSize='large' color='action' />
              </IconButton>
            </Tooltip>
            {hasEditPermission ? (
              <Tooltip
                title={
                  !disableAddWidgets ? (
                    <Translate id='dashboard.addWidgets' />
                  ) : (
                    <Translate id='dashboard.tooManyPages' />
                  )
                }
              >
                <IconButton
                  onClick={!disableAddWidgets ? () => setShowHolder(prev => !prev) : () => null}
                  size='large'
                >
                  <Assessment fontSize='large' color={showHolder ? 'primary' : 'action'} />
                </IconButton>
              </Tooltip>
            ) : null}

            {/* TODO  - Templates feature */}
            {/* <InsertDriveFile fontSize='large' color='action' /> */}
            <PdfExport orientation={orientation} disabled={editMode} />
          </div>
        </>
      ) : (
        <>{mapSettings[type]}</>
      )}
    </>
  );
};

Sidebar.propTypes = {
  orientation: PropTypes.string.isRequired,
  type: PropTypes.string,
  setType: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  parentShow: PropTypes.func.isRequired,
  widgetConfig: widgetType,
  disableAddWidgets: PropTypes.bool,
};

Sidebar.defaultProps = {
  type: 'general',
  widgetConfig: null,
  disableAddWidgets: false,
};

export default Sidebar;

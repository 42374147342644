import { useMediaQuery } from '@mui/material';
import { useCallback } from 'react';

import useLocalStorage from 'lib/misc/useLocalStorage';

type LightDarkMode = 'light' | 'dark';

export const useLightDarkMode = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [mode, setValue] = useLocalStorage<LightDarkMode>(
    'lightDarkMode',
    prefersDarkMode ? 'dark' : 'light'
  );
  const switchMode = useCallback(() => {
    if (mode === 'light') {
      setValue('dark');
    } else {
      setValue('light');
    }
  }, [mode, setValue]);
  return {
    switchMode,
    currentMode: mode,
    isLightMode: mode === 'light',
    isDarkMode: mode === 'dark',
  };
};

import { Card, CardActions, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { createLocation } from 'components/MyRouting/utils';
import ObjectScreenBase from 'components/ObjectScreenBase';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import useEditPermission from 'lib/access/useEditPermission';
import { useTranslate } from 'lib/localization/localization';
import { useDraftString } from 'lib/misc/useDraft/useDraft';
import { Id } from 'lib/types';
import { UserRegistrationStatus } from 'screens/User/lib/constants';
import {
  useCreateUserMutationAdmin,
  useSendInvitationMutationAdmin,
  useUpdateUserMutationAdmin,
  useUserQueryAdmin,
} from 'screens/User/lib/queries';
import { UserRegistrationStatusT } from 'screens/User/lib/types';
import { Routes } from 'utils/constants';

const translationPath = 'adminUserScreen';

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  section: {
    paddingTop: theme.spacing(8),
  },
}));

interface GeneralCardProps {
  draftEmail: string;
  createdOn?: string | null;
  setDraftEmail: (draftEmail: string) => void;
}

const GeneralCard = ({ draftEmail, setDraftEmail, createdOn }: GeneralCardProps) => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <SimpleText translateId={`${translationPath}.generalCard.title`} variant='h2' />

        <div className={classes.section}>
          <TextField
            size='small'
            fullWidth
            disabled
            label={translate(`${translationPath}.generalCard.createdOnField.label`)}
            variant='outlined'
            value={createdOn}
          />
        </div>
        <div className={classes.section}>
          <TextField
            size='small'
            fullWidth
            required
            label={translate(`${translationPath}.generalCard.emailTextfield.label`)}
            variant='outlined'
            value={draftEmail}
            onChange={event => {
              setDraftEmail(event.target.value);
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
};

interface RegistrationCardProps {
  registrationStatus?: UserRegistrationStatusT | null;
  registeredOn?: string | null;
  invitationSentOn?: string | null;
  onInviteClick: () => void;
  disabled?: boolean;
}

const RegistrationCard = ({
  registeredOn,
  invitationSentOn,
  registrationStatus,
  onInviteClick,
  disabled = false,
}: RegistrationCardProps) => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <SimpleText translateId={`${translationPath}.registrationCard.title`} variant='h2' />
        <div className={classes.section}>
          <TextField
            size='small'
            fullWidth
            disabled
            label={translate(`${translationPath}.registrationCard.registrationStatusField.label`)}
            variant='outlined'
            value={registrationStatus}
          />
        </div>
        <div className={classes.section}>
          <TextField
            size='small'
            fullWidth
            disabled
            label={translate(`${translationPath}.registrationCard.registeredOnField.label`)}
            variant='outlined'
            value={registeredOn}
          />
        </div>
        <div className={classes.section}>
          <TextField
            size='small'
            fullWidth
            disabled
            label={translate(`${translationPath}.registrationCard.invitationSentOnField.label`)}
            variant='outlined'
            value={invitationSentOn}
          />
        </div>
      </CardContent>
      <CardActions>
        <SimpleButton
          translateId={`${translationPath}.registrationCard.inviteButton`}
          onClick={onInviteClick}
          variant='midEmp'
          disabled={
            !onInviteClick || registrationStatus === UserRegistrationStatus.REGISTERED || disabled
          }
        />
      </CardActions>
    </Card>
  );
};

const AdminUserScreen = () => {
  const classes = useStyles();
  const history = useHistory();

  const { id: objId } = useParams<{ id: Id | undefined }>();
  const isNew = !objId;
  const { user, isLoading: isLoadingUser } = useUserQueryAdmin(objId);
  const hasEditAccess = useEditPermission(user, { isGeneralRole: true });
  const hasCreateAccess = useCreatePermission(PermissionResourceName.USER, {
    isGeneralRole: true,
  });
  const hasAccess = isNew ? hasCreateAccess : hasEditAccess;
  const { mutate: createUser, isLoading: isLoadingCreate } = useCreateUserMutationAdmin();
  const { mutate: updateUser, isLoading: isLoadingUpdate } = useUpdateUserMutationAdmin();
  const { mutate: sendInvitation, isLoading: isLoadingInvite } = useSendInvitationMutationAdmin();
  const isLoadingMutation = isLoadingCreate || isLoadingUpdate || isLoadingInvite;

  const { draft: draftEmail, setDraft: setDraftEmail } = useDraftString(user?.email);

  const draft = { email: draftEmail };

  const onSaveClick = () => {
    if (isNew) {
      createUser(draft, {
        onSuccess: data => {
          history.push(createLocation(Routes.ADMIN_USER, data.id));
        },
      });
    } else {
      updateUser({ id: objId, data: draft });
    }
  };

  const handleInviteClick = () => {
    if (!isNew) {
      sendInvitation(objId);
    }
  };

  return (
    <ObjectScreenBase
      disabled={isLoadingMutation}
      waitWhile={isLoadingUser}
      object={user}
      draftObj={draft}
      onSaveClick={onSaveClick}
      hasAccess={hasAccess}
      translationPath={translationPath}
      routeNew={Routes.ADMIN_NEW_USER}
      routeChoose={Routes.ADMIN_USERS}
      routeObject={Routes.ADMIN_USER}
      draftName={draftEmail}
      isAccessDialogOn={false}
    >
      <div className={classes.content}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <GeneralCard
              draftEmail={draftEmail}
              setDraftEmail={setDraftEmail}
              createdOn={user?.createdOn}
            />
          </Grid>
          {!isNew && (
            <Grid item xs={12} md={6}>
              <RegistrationCard
                registeredOn={user?.registeredOn}
                invitationSentOn={user?.invitationSentOn}
                registrationStatus={user?.registrationStatus}
                onInviteClick={handleInviteClick}
                disabled={isLoadingMutation || user?.email !== draftEmail}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </ObjectScreenBase>
  );
};

export default AdminUserScreen;

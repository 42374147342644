import { Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import PerformanceBarChart from 'screens/Analysis/AnalysisScreen/widgets/PerformanceBreakdown/PerformanceBarChart';
import PerformanceLineChart from 'screens/Analysis/AnalysisScreen/widgets/PerformanceBreakdown/PerformanceLineChart';
import PerformanceWidgetHeader from 'screens/Analysis/AnalysisScreen/widgets/PerformanceBreakdown/PerformanceWidgetHeader';
import TestSpinner from 'screens/Analysis/lib/components/TestSpinner';
import WidgetFooter from 'screens/Analysis/lib/components/WidgetFooter';
import { SECOND_LEVEL_HEIGHT } from 'screens/Analysis/lib/constants';
import { useAnalysisQuery } from 'screens/Analysis/lib/queries';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: '0.7rem',
    height: SECOND_LEVEL_HEIGHT,

    position: 'relative',
  },
}));

const PerformanceBreakdownWrapper = ({ analysisId }) => {
  const classes = useStyles();

  const { analysis } = useAnalysisQuery(analysisId);
  const widgetData = analysis?.result?.PerformanceBreakdown ?? {};

  const [chartOption, setChartOption] = useState('line');
  const [title, setTitle] = useState('month');

  useEffect(() => {
    if (chartOption === 'line') setTitle('month');
  }, [chartOption, setTitle]);

  return (
    <Grid item md={12} lg={7} xl={8}>
      <Paper className={classes.wrapper}>
        <Grid container style={{ height: '100%' }}>
          {isEmpty(widgetData) ? (
            <TestSpinner />
          ) : (
            <>
              <PerformanceWidgetHeader
                option={chartOption}
                setOption={setChartOption}
                title={title}
                setTitle={setTitle}
              />
              <div style={{ width: '100%', position: 'relative' }}>
                {chartOption === 'bar' ? (
                  <PerformanceBarChart title={title} analysisId={analysisId} />
                ) : (
                  <PerformanceLineChart analysisId={analysisId} />
                )}
              </div>

              {chartOption !== 'line' ? (
                <WidgetFooter>
                  <span style={{ color: '#abe6e9' }}>Portfolio</span>
                  <span style={{ color: '#d9dada' }}>Benchmark</span>
                </WidgetFooter>
              ) : null}
            </>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

PerformanceBreakdownWrapper.propTypes = {
  analysisId: PropTypes.string,
};

PerformanceBreakdownWrapper.defaultProps = {
  analysisId: undefined,
};

export default PerformanceBreakdownWrapper;

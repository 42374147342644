import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';

import MyMenuItem from './MyMenuItem';

import SearchBarView from 'components/SearchBar/SearchBarView';
import { MenuItemsType } from 'utils/types';

class MyMenu extends React.Component {
  state = {
    setAnchorEl: null,
  };

  handleClick = event => {
    this.setState({ setAnchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ setAnchorEl: null });
  };

  render() {
    const { menuItems, iconElement, iconClassName, className, toolTip, onUpdateSearch, disabled } =
      this.props;
    const { setAnchorEl } = this.state;
    return (
      <span>
        <Tooltip title={<Translate id={toolTip} />}>
          <IconButton
            className={classNames(className)}
            color='inherit'
            onClick={this.handleClick}
            disabled={disabled}
            size='large'
          >
            {React.createElement(iconElement, iconClassName ? { className: iconClassName } : {})}
          </IconButton>
        </Tooltip>
        <Menu
          id='simple-menu'
          anchorEl={setAnchorEl}
          keepMounted
          open={Boolean(setAnchorEl)}
          onClose={this.handleClose}
        >
          {onUpdateSearch ? (
            <SearchBarView onUpdateSearch={onUpdateSearch} placeholderId='search' />
          ) : null}
          {menuItems.map(menuItem => (
            <MyMenuItem
              key={menuItem.translateId || menuItem.text}
              onClick={menuItem.onClick}
              path={menuItem.path}
              translateId={menuItem.translateId}
              text={menuItem.text}
            />
          ))}
        </Menu>
      </span>
    );
  }
}

MyMenu.propTypes = {
  menuItems: MenuItemsType,
  iconElement: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  toolTip: PropTypes.string,
  onUpdateSearch: PropTypes.func,
  disabled: PropTypes.bool,
  iconClassName: PropTypes.string,
};

MyMenu.defaultProps = {
  iconElement: MoreVertIcon,
  className: '',
  menuItems: null,
  toolTip: '',
  onUpdateSearch: null,
  disabled: false,
  iconClassName: null,
};
export default MyMenu;

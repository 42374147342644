import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';

import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import useCreatePermission from 'lib/access/useCreatePermission';
import useEditPermission from 'lib/access/useEditPermission';
import { ResourceName } from 'lib/constants';
import { Id } from 'lib/types';
import { PortfolioAllocationType } from 'screens/Portfolio/lib/constants';
import { hasNewStrategy } from 'screens/Portfolio/lib/helpers';
import { useMatchNewPortfolioRoute } from 'screens/Portfolio/lib/hooks/useMatch';
import usePortfolioAllocationDropdowns from 'screens/Portfolio/lib/hooks/usePortfolioAllocationDropdowns';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { PortfolioAllocationTypeT } from 'screens/Portfolio/lib/types';

const translationPath = 'portfolioReconfigurationScreen.useToolbar' as const;

const useStyles = makeStyles(theme => ({
  allocationDropdownCompare: {
    paddingLeft: theme.spacing(4),
  },
  toolbarLeft: { width: '100%', display: 'flex', justifyContent: 'flex-start' },
}));

interface UseToolbarProps {
  portfolioId: Id | undefined;
  compareAllocationType?: PortfolioAllocationTypeT | PortfolioAllocationTypeT[];
  onReconfigureClick: () => void;
}

const useToolbar = ({
  portfolioId,
  compareAllocationType = undefined,
  onReconfigureClick = () => {},
}: UseToolbarProps) => {
  const [isCompareOn, setIsCompareOn] = React.useState(false);
  const classes = useStyles();
  const { portfolio } = usePortfolioQuery(portfolioId);
  const isNew = Boolean(useMatchNewPortfolioRoute());
  const hasEditAccess = useEditPermission(portfolio);
  const hasCreateAccess = useCreatePermission(ResourceName.PORTFOLIO);
  const hasAccess = isNew ? hasCreateAccess : hasEditAccess;
  const [selectedAllocType, setSelectedAllocType] = React.useState<PortfolioAllocationTypeT>(
    PortfolioAllocationType.OPTIMIZED
  );
  useEffect(() => {
    if ((portfolio && hasNewStrategy(portfolio)) || isNew) {
      setSelectedAllocType(PortfolioAllocationType.OPTIMIZED_NEW);
    } else {
      setSelectedAllocType(PortfolioAllocationType.OPTIMIZED);
    }
  }, [portfolio, isNew]);

  const {
    selectedPortfolioId: selectedPortfolioIdCompare,
    selectedAllocType: selectedAllocTypeCompare,
    portfolioDropdownElem: portfolioDropdownCompareElem,
    allocationTypeDropdownElem: allocationTypeDropdownCompareElem,
  } = usePortfolioAllocationDropdowns({
    allowedAllocationTypes: compareAllocationType,
    initialPortfolioId: portfolioId,
    initialAllocationType: PortfolioAllocationType.OPTIMIZED,
  });

  const handleCompareButton = () => {
    setIsCompareOn(true);
  };
  const handleExitCompareButton = () => {
    setIsCompareOn(false);
  };

  const toolbarLeft = (
    <div className={classes.toolbarLeft}>
      <SimpleText variant='h2' translateId={`${translationPath}.title.${selectedAllocType}`} />
      <SimpleButton
        pendo-id='portfolio-reconfiguration-screen-reconfigure-button'
        translateId={`${translationPath}.${isNew ? 'configureButton' : 'reconfigureButton'}`}
        onClick={onReconfigureClick}
        variant='highEmp'
        disabled={!hasAccess}
      />
    </div>
  );
  const Toolbar = () => (
    <Grid container alignItems='center'>
      <Grid item xs={6}>
        {toolbarLeft}
      </Grid>
      {!isNew && (
        <Grid item xs={6}>
          <div style={{ float: 'right' }}>
            <SimpleButton
              translateId={`${translationPath}.compareButton`}
              onClick={handleCompareButton}
              variant='midEmp'
            />
          </div>
        </Grid>
      )}
    </Grid>
  );

  const ToolbarCompare = () => (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12} md={6}>
        {toolbarLeft}
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container alignItems='center'>
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={6}>
                {portfolioDropdownCompareElem}
              </Grid>
              <Grid item xs={6}>
                <div className={classes.allocationDropdownCompare}>
                  {allocationTypeDropdownCompareElem}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <div style={{ float: 'right' }}>
              <SimpleButton
                translateId={`${translationPath}.exitCompareButton`}
                onClick={handleExitCompareButton}
                variant='midEmp'
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  return {
    selectedAllocType,
    isCompareOn,
    Toolbar: isCompareOn ? ToolbarCompare : Toolbar,
    selectedPortfolioIdCompare: isCompareOn ? selectedPortfolioIdCompare : undefined,
    selectedAllocTypeCompare: isCompareOn ? selectedAllocTypeCompare : undefined,
  };
};

export default useToolbar;

import PropTypes from 'prop-types';
import React from 'react';

class MyTimeout extends React.Component {
  componentDidMount() {
    const { onTimeout, ms } = this.props;
    this.timeout = setTimeout(onTimeout, ms);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    return null;
  }
}

MyTimeout.propTypes = {
  onTimeout: PropTypes.func.isRequired,
  ms: PropTypes.number.isRequired,
};

export default MyTimeout;

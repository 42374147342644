//  filterTableObjects(groupConstraints, allocations)

import { format } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { createLocation } from 'components/MyRouting/utils';
import ChooseScreen from 'components/SearchTools/ChooseScreen';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import {
  useDeleteGroupConstraintListMutation,
  useGroupConstraintListsQuery,
} from 'screens/AssetAllocation/lib/queries';
import { useAssetAllocationsQuery } from 'screens/Environment/lib/queries';
import { Routes } from 'utils/constants';

const columns = ['id', 'name', 'createdOn', 'updatedOn', 'assetAllocation'];

const ChooseGroupConstraintListScreen = () => {
  const history = useHistory();
  const hasCreatePermission = useCreatePermission(PermissionResourceName.GROUP_CONSTRAINT_LIST);

  const { groupConstraintLists, isLoading } = useGroupConstraintListsQuery({
    filter: 'predefined',
  });
  const { mutate: deleteGroupConsList } = useDeleteGroupConstraintListMutation();
  const { assetAllocations } = useAssetAllocationsQuery();
  const cellFormatting = {
    assetAllocation: assetAllocId =>
      Object.values(assetAllocations).find(allocation => allocation.id === assetAllocId)?.typ || '',
    createdOn: date => (date ? format(new Date(date), 'yyyy-MM-dd') : ''),
    updatedOn: date => (date ? format(new Date(date), 'yyyy-MM-dd') : ''),
  };

  return (
    <ChooseScreen
      dialogTranslatePath='groupConsListScreen.useSavePromptAndDialog'
      waitWhile={isLoading}
      key={columns}
      tableObjects={groupConstraintLists}
      columns={columns}
      searchColumns={columns}
      cellFormatting={cellFormatting}
      searchValue={null}
      translationPath='chooseGroupConsListScreen'
      isTableWhite
      onNewClick={hasCreatePermission ? () => history.push(Routes.NEW_GROUP_CONSTRAINT_LIST) : null}
      onRowClick={id => history.push(createLocation(Routes.GROUP_CONSTRAINT_LIST, id))}
      onDeleteRowClick={obj => {
        deleteGroupConsList(obj.id);
      }}
    />
  );
};

export default ChooseGroupConstraintListScreen;

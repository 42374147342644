import DoneIcon from '@mui/icons-material/Done';
import { Chip, Stack } from '@mui/material';
import React from 'react';

import { useTranslate } from 'lib/localization/localization';
import useToggleList from 'lib/misc/useToggleList';
import { ValueOf } from 'lib/types';
import { AllocationChip } from 'lib/weights/constants';

const translationPath = 'lib.weights.useAssetAllocation' as const;

interface AllocationChipsProps {
  chipValues: ReadonlyArray<ValueOf<typeof AllocationChip>>;
  isToggled: (chip: ValueOf<typeof AllocationChip>) => boolean;
  toggle: (chip: ValueOf<typeof AllocationChip>) => void;
}

const ToolbarChips = ({ chipValues, isToggled, toggle }: AllocationChipsProps) => {
  const translate = useTranslate();
  return (
    <Stack direction='row' spacing={2}>
      {chipValues.map(chip => (
        <Chip
          variant='outlined'
          key={chip}
          label={translate(`${translationPath}.chip.${chip}`)}
          onClick={() => toggle(chip)}
          color={isToggled(chip) ? 'primary' : 'default'}
          icon={isToggled(chip) ? <DoneIcon /> : undefined}
        />
      ))}
    </Stack>
  );
};

interface UseAllocationChipsProps {
  chipValues: ReadonlyArray<ValueOf<typeof AllocationChip>>;
}

const useAllocationChips = ({ chipValues }: UseAllocationChipsProps) => {
  const { isToggled, toggle, toggledValues } = useToggleList([...chipValues]);

  return {
    toggledValues,
    toolbarChipsElem: (
      <ToolbarChips chipValues={chipValues} isToggled={isToggled} toggle={toggle} />
    ),
  };
};

export default useAllocationChips;

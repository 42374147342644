import _ from 'lodash';
import React from 'react';

import MyDialog from 'components/MyDialog/MyDialog';
import useEditPermission from 'lib/access/useEditPermission';
import { Resource, Resources } from 'lib/types';
import { DialogKeys } from 'utils/constants';

interface UseTableDeleteProps<T extends Resource> {
  tableObjects: T[] | Resources<T>;
  dialogTranslatePath: string;
  onDeleteRowClick: (obj: T | null) => void;
  editPermissionOverride?: boolean;
}

function useTableDelete<T extends Resource>({
  tableObjects,
  dialogTranslatePath,
  onDeleteRowClick,
  editPermissionOverride,
}: UseTableDeleteProps<T>) {
  const editPermissions = useEditPermission(_.values(tableObjects));
  const [objectToDelete, setObjectToDelete] = React.useState<T | null>(null);

  const deletableRows = [];
  for (const [tableObject, hasEditPermission] of _.zip(_.values(tableObjects), editPermissions)) {
    let canDelete = hasEditPermission;
    if (editPermissionOverride !== undefined) {
      canDelete = editPermissionOverride;
    }
    if (canDelete && tableObject) {
      deletableRows.push(tableObject.id);
    }
  }

  const handleDeleteClick = (obj: T) => {
    setObjectToDelete(obj);
  };

  const deleteDialog = (
    <MyDialog
      isOpen={!!objectToDelete}
      header={
        dialogTranslatePath
          ? `${dialogTranslatePath}.dialogHeader.delete`
          : 'chooseScreen.deleteDialog.header'
      }
      message={dialogTranslatePath ? `${dialogTranslatePath}.dialogMessage.delete` : undefined}
      notPerformKey={`dialogKey.${DialogKeys.CANCEL}`}
      performKey={`dialogKey.${DialogKeys.DELETE}`}
      onNotPerformClick={() => {
        setObjectToDelete(null);
      }}
      onPerformClick={() => {
        onDeleteRowClick(objectToDelete);
        setObjectToDelete(null);
      }}
    />
  );

  return {
    deletableRows,
    handleDeleteClick,
    deleteDialog,
  };
}
export default useTableDelete;

import { keysToLowerCase } from 'lib/misc/misc';

interface UploadedRow {}

export function getUploadedIsins(uploadedData: UploadedRow[]) {
  const isins: string[] = [];
  uploadedData.forEach(row => {
    const lowerCaseRow = keysToLowerCase(row);
    const possibleColNames = [
      'isin',
      'isins',
      'id',
      'ids',
      'instituteidentifier',
      'instituteidentifiers',
    ];
    for (const colName of possibleColNames) {
      if (lowerCaseRow[colName]) {
        isins.push(lowerCaseRow[colName]);
        break;
      }
    }
  });
  return isins;
}

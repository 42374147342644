import chroma from 'chroma-js';
import _ from 'lodash';

import { PerformanceInfoType } from 'lib/performance/constants';
import { PerformanceInfo, TimeSeriesDatePoint } from 'lib/performance/types';
import {
  getCorrectTimeSeriesPeriod,
  getPercentagePerformance,
  getPerformanceFromPrice,
  parseDatesInSeries,
} from 'lib/timeSeries/tsData';
import { Id, Ids, IsoDate, ValueOf } from 'lib/types';
import { useAssetQueries } from 'screens/Environment/lib/queries';

export function getColorSet(howMany: number, brightColor: string, darkColor: string): string[] {
  return (
    chroma
      // @ts-ignore
      .scale(chroma.bezier([brightColor, darkColor]).scale())
      .correctLightness()
      .colors(howMany)
  );
}

export interface LineChartSeriesData {
  id: Id;
  name: string;
  series: TimeSeriesDatePoint[] | null;
  color: string;
  type: ValueOf<typeof PerformanceInfoType>;
  error?: string;
  isLoading?: boolean;
}

export interface LineChartData {
  [key: string]: LineChartSeriesData;

  main: LineChartSeriesData;
}

export function computeLineChartData(
  performanceInfo: PerformanceInfo,
  benchmarkPerformanceInfos: PerformanceInfo[],
  startDate?: Date | IsoDate,
  endDate?: Date | IsoDate,
  getColorSetFn?: (nColors: number) => string[],
  mainColor: string = '#000000',
  brightColor: string = '#ffffff',
  darkColor: string = '#000000'
) {
  let mainSeries = getPercentagePerformance(parseDatesInSeries(performanceInfo.performance));
  if (startDate && endDate) {
    mainSeries = getCorrectTimeSeriesPeriod(
      mainSeries,
      startDate instanceof Date ? startDate : new Date(startDate),
      endDate instanceof Date ? endDate : new Date(endDate)
    );
  }
  const data: LineChartData = {
    main: {
      id: performanceInfo.id,
      name: performanceInfo.name ?? performanceInfo.id,
      series: mainSeries,
      color: mainColor,
      type: performanceInfo.type,
      error: undefined,
      isLoading: performanceInfo.isLoading,
    },
  };
  const firstPoint = mainSeries[0];
  const lastPoint = mainSeries[mainSeries.length - 1];
  if (firstPoint === undefined || lastPoint === undefined) {
    return data;
  }
  const seriesStartDate = firstPoint.x;
  const seriesEndDate = lastPoint.x;
  const colorSet = (getColorSetFn ?? getColorSet)(10, brightColor, darkColor);
  benchmarkPerformanceInfos.forEach((perfInfo, i) => {
    if (perfInfo.performance !== undefined) {
      const series = parseDatesInSeries(perfInfo.performance).sort((a: any, b: any) => a.x - b.x);
      const correctPeriodSeries = getCorrectTimeSeriesPeriod(
        series,
        seriesStartDate,
        seriesEndDate
      );
      let errorMessage;
      let assetPerformance: TimeSeriesDatePoint[] = [];
      if (correctPeriodSeries.length > 0) {
        assetPerformance = getPerformanceFromPrice(correctPeriodSeries);
      }
      if (assetPerformance.length < mainSeries.length * 0.75) {
        errorMessage = 'missing timeseries';
      }
      data[i] = {
        id: perfInfo.id,
        name: perfInfo.name ?? perfInfo.id,
        series: getPercentagePerformance(assetPerformance),
        color: colorSet[i] ?? mainColor,
        type: perfInfo.type,
        error: errorMessage,
        isLoading: perfInfo.isLoading,
      };
    } else {
      data[i] = {
        id: perfInfo.id,
        name: perfInfo.name ?? perfInfo.id,
        series: [],
        color: colorSet[i] ?? mainColor,
        type: perfInfo.type,
        error: 'missing timeseries',
        isLoading: perfInfo.isLoading,
      };
    }
  });
  return data;
}

export function makePerformanceInfosFromAssetQueries(
  assetIds: Ids,
  assetQueries: ReturnType<typeof useAssetQueries>
): PerformanceInfo[] {
  const perfInfos: PerformanceInfo[] = [];
  _.zip(assetIds, assetQueries).forEach(([assetId, assetQuery]) => {
    if (assetId) {
      perfInfos.push({
        performance: assetQuery?.data?.performance,
        id: assetId,
        name: assetQuery?.data?.name,
        type: PerformanceInfoType.ASSET,
        isLoading: assetQuery?.isLoading,
      });
    }
  });
  return perfInfos;
}

import { isEmpty, first } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import BarChartWrapper from 'screens/Analysis/lib/components/BarChartWrapper';
import useDateToggle from 'screens/Analysis/lib/components/useDateToggle';
import { useAnalysisQuery } from 'screens/Analysis/lib/queries';

const PerformanceBarChart = ({ title, analysisId }) => {
  const { analysis } = useAnalysisQuery(analysisId);
  const widgetData = useMemo(
    () => analysis?.result?.PerformanceBreakdown ?? {},
    [analysis?.result?.PerformanceBreakdown]
  );
  const [dateFilter, DateFilterComponent] = useDateToggle(
    // Disable all on bar chart, scroll issue
    !isEmpty(widgetData) ? first(widgetData[title].filters) : 'all'
  );
  const barChartData = useMemo(() => {
    const filteredData = widgetData[title].data[dateFilter].filter(
      ({ portfolio, benchmark }) => portfolio !== 0 && benchmark !== 0
    );
    return filteredData.map(({ label, portfolio, benchmark }) => ({
      date: label,
      Portfolio: (portfolio * 100).toFixed(1),
      Benchmark: (benchmark * 100).toFixed(1),
    }));
  }, [dateFilter, title, widgetData]);

  return (
    <>
      <DateFilterComponent
        available={widgetData[title].filters}
        errors={widgetData[title].errors}
        hints={widgetData[title].hints}
      />
      <BarChartWrapper data={barChartData} />
    </>
  );
};

PerformanceBarChart.propTypes = {
  title: PropTypes.string.isRequired,
  analysisId: PropTypes.string,
};

PerformanceBarChart.defaultProps = {
  analysisId: undefined,
};

export default PerformanceBarChart;

import { Card, CardContent, Dialog, DialogActions, DialogContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';

import DialogButton from 'components/Buttons/DialogButton';
import MyProgress from 'components/MyProgress';
import SearchBar from 'components/SearchBar/SearchBarView';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import Table from 'components/Table/TableView';
import { toGermanNumberString, triggerCsvDownload } from 'lib/misc/misc';
import { useDraftList } from 'lib/misc/useDraft/useDraft';
import {
  computeLineChartData,
  makePerformanceInfosFromAssetQueries,
} from 'lib/performance/helpers';
import PerformanceChart from 'lib/performance/PerformanceChart';
import { PerformanceInfo } from 'lib/performance/types';
import { Id, Ids } from 'lib/types';
import { ALLOCATION_CHART_HEIGHT } from 'lib/weights/constants';
import { AnalysisDataTypeT } from 'screens/Analysis/lib/types';
import { useAssetQueries, useAssetsQuery } from 'screens/Environment/lib/queries';
import usePortfolioPerformance from 'screens/Portfolio/lib/hooks/usePortfolioPerformance';
import { DEFAULT_CSV_DELIMITER, DialogKeys } from 'utils/constants';

const translationPath = 'lib.performance.performanceChartCard' as const;
const MAX_ASSETS_TO_COMPARE = 10;
const useStyles = makeStyles(theme => ({
  mainContent: {
    height: ALLOCATION_CHART_HEIGHT,
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

interface CompareAssetsDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  assetIds: Ids;
  onChooseAssetIds: (assetIds: Ids) => void;
}

const ASSET_TABLE_COLUMNS = [
  'isin',
  'name',
  'assetClass',
  'superSector',
  'sector',
  'country',
  'assetType',
];

export const CompareAssetsDialog = ({
  isOpen,
  setIsOpen,
  assetIds,
  onChooseAssetIds,
}: CompareAssetsDialogProps) => {
  const [searchValue, setSearchValue] = useState('');
  const { assets, isLoading } = useAssetsQuery();
  const shownAssets = useMemo(() => {
    // check if isQualityCheckPassed is true
    return _.pickBy(assets, asset => asset.isQualityCheckPassed && !asset.isCash);
  }, [assets]);
  const { draft: draftAssetIds, toggleDraftElement, setDraft } = useDraftList(assetIds);

  const handleDiscard = () => {
    setIsOpen(false);
  };

  const handleApply = () => {
    onChooseAssetIds(draftAssetIds);
    setIsOpen(false);
  };

  const handleSelectAllClick = () => {
    setDraft([]);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby='add-asset-dialog-title'
      TransitionProps={{
        onExited: () => setDraft(undefined),
      }}
    >
      <DialogContent>
        <SearchBar
          searchValue={searchValue}
          onUpdateSearch={setSearchValue}
          placeholderId='universe'
        />
        {isLoading ? (
          <MyProgress />
        ) : (
          <Table
            titleTranslateId={`${translationPath}.compareAssetsDialog.compareAssetsTableName`}
            translateTableId='assetTable'
            tableObjects={shownAssets}
            columns={ASSET_TABLE_COLUMNS}
            searchValue={searchValue}
            deletableRows={false}
            isWithCheckbox
            isDownloadButton
            isTableWhite
            showEmptyRows={false}
            onRowClick={(id: string) => {
              if (draftAssetIds.length < MAX_ASSETS_TO_COMPARE || draftAssetIds.includes(id)) {
                toggleDraftElement(id);
              }
            }}
            onSelectAllClick={draftAssetIds.length > 0 ? handleSelectAllClick : undefined}
            selectedObjectIds={draftAssetIds}
          />
        )}
      </DialogContent>
      <DialogActions>
        <DialogButton
          onClick={() => handleDiscard()}
          translateId={`dialogKey.${DialogKeys.DISCARD}`}
          variant='lowEmp'
        />
        <DialogButton
          onClick={() => handleApply()}
          translateId={`dialogKey.${DialogKeys.CONFIRM}`}
          variant='lowEmp'
        />
      </DialogActions>
    </Dialog>
  );
};

interface TsDownloadRow {
  [column: string]: number | Date;
  date: Date;
}

function makeTSDataForDownload(
  performanceInfo: PerformanceInfo,
  benchmarkPerformanceInfos: PerformanceInfo[]
): TsDownloadRow[] {
  const chartData = computeLineChartData(performanceInfo, benchmarkPerformanceInfos);
  const combinedTs: { [row: string]: TsDownloadRow } = {};
  if (chartData.main.series) {
    chartData.main.series.forEach(tsPoint => {
      combinedTs[tsPoint.x.toString()] = { date: tsPoint.x, [chartData.main.name]: tsPoint.y };
    });
  }

  _.forOwn(chartData, ts => {
    if (ts.name === 'main') {
      return;
    }
    if (ts.series) {
      ts.series.forEach(tsPoint => {
        const row = combinedTs[tsPoint.x.toString()];
        if (row) {
          row[ts.name] = tsPoint.y;
        }
      });
    }
  });
  return _.values(combinedTs);
}

const handleDownload = (
  performanceInfo: PerformanceInfo,
  benchmarkPerformanceInfos: PerformanceInfo[]
) => {
  const tsData = makeTSDataForDownload(performanceInfo, benchmarkPerformanceInfos);
  if (tsData && tsData[0]) {
    const numberColumns = Object.keys(tsData[0]).filter(col => col !== 'date');
    const exportFormatting: { [key in string]: (arg: any) => string } = {};
    numberColumns.forEach(col => {
      exportFormatting[col] = (value: number) => toGermanNumberString(value);
    });
    exportFormatting.date = date => format(date, 'yyyy-MM-dd');
    const options = { delimiter: DEFAULT_CSV_DELIMITER, exportFormatting };
    triggerCsvDownload(tsData, `backtest-${performanceInfo.name ?? ''}.csv`, options);
  }
};

interface PerformanceChartCardProps {
  portfolioId?: Id;
  dataType?: AnalysisDataTypeT;
  backtestId?: Id;
  onDatesChange?: (startDate: Date, endDate: Date) => void;
  compareAssetIds?: Ids;
  onChangeCompareAssetIds?: (compareAssetIds: Ids) => void;
  onRebalancingPointClick?: (rebalancingPoint1: Date, rebalancingPoint2: Date) => void;
}

const PerformanceChartCard = ({
  portfolioId,
  dataType,
  backtestId,
  onDatesChange,
  compareAssetIds = [],
  onChangeCompareAssetIds = () => {},
  onRebalancingPointClick,
}: PerformanceChartCardProps) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const assetQueries = useAssetQueries(compareAssetIds);
  const benchmarkPerfInfos = useMemo(() => {
    return makePerformanceInfosFromAssetQueries(compareAssetIds, assetQueries);
  }, [compareAssetIds, assetQueries]);

  const { isLoading, performanceInfo, points, progressOrErrorElem } = usePortfolioPerformance({
    portfolioId,
    dataType,
    backtestId,
  });
  const content = performanceInfo ? (
    <PerformanceChart
      performanceInfo={performanceInfo}
      points={points}
      benchmarkPerformanceInfos={benchmarkPerfInfos}
      onRemoveBenchmark={(id: Id) => {
        onChangeCompareAssetIds(compareAssetIds.filter(assetId => assetId !== id));
      }}
      onRebalancingPointClick={onRebalancingPointClick}
      onDatesChange={onDatesChange}
    />
  ) : undefined;

  return (
    <Card>
      <CardContent>
        <CompareAssetsDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          assetIds={compareAssetIds}
          onChooseAssetIds={onChangeCompareAssetIds}
        />
        <div className={classes.topBar}>
          <SimpleText translateId={`${translationPath}.title`} variant='h2' />
          <div className={classes.buttons}>
            <SimpleButton
              translateId={`${translationPath}.compareButton`}
              onClick={() => setIsOpen(true)}
              variant='midEmp'
              disabled={isLoading}
            />
            <SimpleButton
              translateId={`${translationPath}.downloadTsButton`}
              onClick={() => {
                if (performanceInfo) {
                  handleDownload(performanceInfo, benchmarkPerfInfos);
                }
              }}
              variant='midEmp'
              disabled={isLoading}
            />
          </div>
        </div>
        <div className={classes.mainContent}>{progressOrErrorElem ?? content}</div>
      </CardContent>
    </Card>
  );
};

export default PerformanceChartCard;

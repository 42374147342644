import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

import InfoTooltip from 'components/InfoTooltip';
import MyDropdown from 'components/MyDropdown/MyDropdown';
import SimpleText from 'components/SimpleText';
import { PermissionLevel } from 'lib/access/constants';

const useStyles = makeStyles(theme => ({
  areaName: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  dropdown: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: '350px',
  },
}));

const DefaultAccessDropdown = ({ draftDefaultAccess, setDraftDefaultAccess, disabled }) => {
  const classes = useStyles();
  return (
    <>
      <InfoTooltip size='small' translateId='userGroupScreen.infoTooltip.defaultAccess'>
        <SimpleText
          className={classes.areaName}
          translateId='userGroupScreen.defaultAccessDropdown'
          variant='h2'
        />
      </InfoTooltip>
      <MyDropdown
        className={classes.dropdown}
        value={draftDefaultAccess}
        onChange={setDraftDefaultAccess}
        onUpdateSearch={null}
        isNullAllowed={false}
        disabled={disabled}
        fullWidth
      >
        {Object.values(PermissionLevel).map(permissionLevel => (
          <MenuItem key={permissionLevel} value={permissionLevel}>
            <SimpleText translateId={`userGroupScreen.permissionLevel.${permissionLevel}`} />
          </MenuItem>
        ))}
      </MyDropdown>
    </>
  );
};

DefaultAccessDropdown.propTypes = {
  draftDefaultAccess: PropTypes.string.isRequired,
  setDraftDefaultAccess: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DefaultAccessDropdown.defaultProps = {
  disabled: false,
};

export default DefaultAccessDropdown;

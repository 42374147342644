import { createTheme, Theme } from '@mui/material';

const ralewayFont = ['Raleway', 'Helvetica', 'Arial'].join(',');

const opensansFont = ['Open Sans', 'Helvetica', 'Arial'].join(',');

const fontWeightLight = 300;
const fontWeightSemiBold = 600;
const fontWeightNormal = 400;
const fontWeightMedium = 500;

const palette = {
  mode: 'light',
  contrastThreshold: 1.5,
  neutral: {
    dark: '#606262',
    darkUnd: '#707272',
    light: '#c7c8c8',
    lightEmp: '#b5b6b6',
    main: '#929393',
  },
  primary: {
    main: '#2dc6c9',
  },
  secondary: {
    main: '#5d5c80',
  },
  success: {
    main: '#aad08a',
  },
  error: {
    main: '#e69386',
  },
  background: {
    default: '#f1f1f1',
    paper: '#f9f9f9',
  },
  bookmark: {
    main: '#f3d086',
  },
  graphs: {
    bright: '#41cbc7',
    dark: '#732691',
  },
} as const;

const darkPalette = {
  ...palette,
  mode: 'dark',
  background: {
    default: '#121212',
    paper: '#1e1e1e',
  },
  error: {
    main: '#d04545',
  },
  success: {
    main: '#468359',
  },
} as const;

const typography = {
  useNextVariants: true, // do not change this
  // page title
  h1: {
    fontFamily: ralewayFont,
    fontWeight: fontWeightLight,
    fontSize: '2.375rem',
    lineHeight: 1,
    letterSpacing: '0em',
  },
  // card title, portfolio name, section name, error name
  h2: {
    fontFamily: ralewayFont,
    fontWeight: fontWeightLight,
    fontSize: '1.625rem',
    lineHeight: 1,
    letterSpacing: '0em',
  },
  // tabs, menu titles, error message
  h3: {
    fontFamily: ralewayFont,
    fontWeight: fontWeightSemiBold,
    fontSize: '1rem',
    lineHeight: 1,
    letterSpacing: '-0.00375em',
    textTransform: 'uppercase',
  },
  // Tabs in card
  h4: {
    fontFamily: ralewayFont,
    fontWeight: fontWeightSemiBold,
    fontSize: '0.875rem',
    lineHeight: 1,
    letterSpacing: '-0.00375em',
  },
  // big numbers in cards and portfolio country/industry tables
  h5: {
    fontFamily: opensansFont,
    fontWeight: fontWeightLight,
    fontSize: '1.625rem',
    lineHeight: 1,
    letterSpacing: '0em',
  },
  // not used
  h6: {
    fontFamily: ralewayFont,
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
  },
  h7: {
    fontFamily: ralewayFont,
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
  },
  // same as body1 but for numbers?
  subtitle1: {
    fontFamily: opensansFont,
    fontWeight: fontWeightNormal,
    fontSize: '0.875rem',
    lineHeight: 1,
    letterSpacing: '0.01875em',
  },
  // table headers and table content
  subtitle2: {
    fontFamily: opensansFont,
    fontWeight: fontWeightNormal,
    fontSize: '0.75rem',
    lineHeight: 1,
    letterSpacing: '0.03125em',
  },
  // menu text, settings, search field text, chips
  body1: {
    fontFamily: ralewayFont,
    fontWeight: fontWeightNormal,
    fontSize: '0.875rem',
    lineHeight: 1,
    letterSpacing: '0.01875em',
  },
  // smaller than body1
  body2: {
    fontFamily: ralewayFont,
    fontWeight: fontWeightNormal,
    fontSize: '0.75rem',
    lineHeight: 1,
    letterSpacing: '0.03125em',
  },
  // buttons
  button: {
    fontFamily: ralewayFont,
    fontWeight: fontWeightSemiBold,
    fontSize: '0.875rem',
    lineHeight: 1,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
  // parameter labels, table rows per page
  caption: {
    fontFamily: opensansFont,
    fontWeight: fontWeightNormal,
    fontSize: '0.625rem',
    lineHeight: 1,
    letterSpacing: '0.03333em',
  },
  // table titles
  overline: {
    fontFamily: ralewayFont,
    fontWeight: fontWeightMedium,
    fontSize: '0.75rem',
    lineHeight: 1,
    letterSpacing: '0.0975em',
    textTransform: 'uppercase',
  },
} as const;

const components = {
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        ...theme.typography.body1,
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.background.default,
      }),
    },
  },
  MuiSlider: {
    styleOverrides: {
      valueLabel: ({ theme }: { theme: Theme }) => ({
        top: 1,
        background: 'transparent',
        color: theme.palette.text.primary,
      }),
    },
  },
} as const;

const theme = {
  shape: {
    borderRadius: 8,
  },
  spacing: (factor: number) => 4 * factor,
  palette,
  typography,
  components,
};

export const createCustomTheme = (mode: 'light' | 'dark' = 'light') => {
  const customTheme = mode === 'light' ? theme : { ...theme, palette: darkPalette };
  // @ts-ignore
  return createTheme(customTheme);
};

import _ from 'lodash';

import {
  PermissionAccessLevel,
  PermissionLevel,
  PERMISSION_LEVEL_HIERARCHY,
  PERMISSION_LEVEL_HIERARCHY_FULL,
} from 'lib/access/constants';
import { ResourceName } from 'lib/constants';

export function isOwnObject(object, user) {
  if (!user) {
    return false;
  }
  return object.user === user.id;
}

export function isPermissionLevelLessEqual(permissionLevel, otherPermissionLevel) {
  return (
    PERMISSION_LEVEL_HIERARCHY_FULL.indexOf(permissionLevel) <=
    PERMISSION_LEVEL_HIERARCHY_FULL.indexOf(otherPermissionLevel)
  );
}

function isInAccessDict(object, userGroup, permissionLevel) {
  const objId = parseInt(object.id, 10);
  if (permissionLevel in userGroup.accessDict) {
    const idsWithAccess = userGroup.accessDict[permissionLevel][object.tablename];
    if (idsWithAccess && idsWithAccess.includes(objId)) {
      return true;
    }
  }
  return false;
}

function defineGroupAccessForPermissionLevelTo(object, userGroup, permissionLevel, user = null) {
  let hasAccessPermission = null;
  let possibleAccessPermission = null;
  const isUserNotInGroup = user && !user.groups.includes(userGroup.id);
  const isNotSameInstitute =
    object.institute !== userGroup.institute && object.tablename !== ResourceName.INSTITUTE;

  if (isNotSameInstitute || isUserNotInGroup) {
    return { hasAccessPermission, possibleAccessPermission };
  }
  for (const role of userGroup.roles) {
    const resourcePermissions = role.permissions.filter(
      permission =>
        permission.resourceName === object.tablename &&
        isPermissionLevelLessEqual(permissionLevel, permission.permissionLevel)
    );

    for (const permission of resourcePermissions) {
      if (permission.accessLevel === PermissionAccessLevel.ANY) {
        hasAccessPermission = permission;
        possibleAccessPermission = null;
        return { hasAccessPermission, possibleAccessPermission: null };
      }
      if (permission.accessLevel === PermissionAccessLevel.OWN) {
        // in case object is NEW or we need, if there is theoretical access
        const checkTheoreticalAccess = !object.id;
        if (
          checkTheoreticalAccess ||
          isInAccessDict(object, userGroup, permissionLevel) ||
          isOwnObject(object, user)
        ) {
          hasAccessPermission = permission;
          possibleAccessPermission = null;
        } else if (!hasAccessPermission) {
          possibleAccessPermission = permission;
        }
      }
    }
  }
  return { hasAccessPermission, possibleAccessPermission };
}

export function defineGroupAccessTo(object, userGroup, user = null) {
  let currentPermissionLevel = PermissionLevel.NO;
  let currentPermissionAccessLevel = PermissionAccessLevel.NO;
  const possiblePermissionLevels = [];

  for (const permissionLevel of PERMISSION_LEVEL_HIERARCHY.slice().reverse()) {
    const { hasAccessPermission, possibleAccessPermission } = defineGroupAccessForPermissionLevelTo(
      object,
      userGroup,
      permissionLevel,
      user
    );

    if (possibleAccessPermission || hasAccessPermission) {
      // if better permission level was already found
      if (currentPermissionLevel !== PermissionLevel.NO) {
        possiblePermissionLevels.push(permissionLevel);
        // if no better permission level was found, but now possible access is found
      } else if (possibleAccessPermission) {
        possiblePermissionLevels.push(permissionLevel);
      } else {
        currentPermissionAccessLevel = hasAccessPermission.accessLevel;
        currentPermissionLevel = permissionLevel;
        if (currentPermissionAccessLevel === PermissionAccessLevel.ANY) {
          break;
        }
      }
    }
  }
  if (
    currentPermissionAccessLevel !== PermissionAccessLevel.ANY &&
    currentPermissionLevel !== PermissionLevel.NO
  ) {
    possiblePermissionLevels.push(PermissionLevel.NO);
  }
  return { currentPermissionLevel, possiblePermissionLevels };
}

export function defineGroupsAccessTo(object, userGroups) {
  const actualPermissionLevels = {};
  const possiblePermissionLevelsByGroup = {};
  const filteredUserGroups = Object.values(userGroups).filter(
    group => group.institute === object.institute
  );

  filteredUserGroups.forEach(userGroup => {
    const { currentPermissionLevel, possiblePermissionLevels } = defineGroupAccessTo(
      object,
      userGroup
    );
    actualPermissionLevels[userGroup.id] = currentPermissionLevel;
    possiblePermissionLevelsByGroup[userGroup.id] = possiblePermissionLevels;
  });
  return {
    actualPermissionLevels,
    possiblePermissionLevelsByGroup,
  };
}

export function adjustGroupsAccessForNewChanges(
  actualPermissionLevels,
  possiblePermissionLevelsByGroup,
  newPermissionLevels
) {
  const adjustedActualPermissionLevels = _.cloneDeep(actualPermissionLevels);
  const adjustedPossiblePermissionLevelsByGroup = _.cloneDeep(possiblePermissionLevelsByGroup);

  Object.entries(newPermissionLevels).forEach(([userGroupId, newPermissionLevel]) => {
    const possiblePermissionLevels = adjustedPossiblePermissionLevelsByGroup[userGroupId];

    if (possiblePermissionLevels.includes(newPermissionLevel)) {
      possiblePermissionLevels.splice(possiblePermissionLevels.indexOf(newPermissionLevel), 1);
      possiblePermissionLevels.push(actualPermissionLevels[userGroupId]);
      adjustedActualPermissionLevels[userGroupId] = newPermissionLevel;
    }
  });
  return { adjustedActualPermissionLevels, adjustedPossiblePermissionLevelsByGroup };
}

function hasPermissionForSingle(object, user, userGroups, requiredPermissionLevel) {
  if (!object || !user) {
    return false;
  }
  let filteredUserGroups = Object.values(userGroups).filter(
    group => group.institute === object.institute && user.groups.includes(group.id)
  );
  if (object.tablename === ResourceName.INSTITUTE || object.tablename === ResourceName.USER) {
    const generalGroups = Object.values(userGroups).filter(
      group => group.institute === null && user.groups.includes(group.id)
    );
    filteredUserGroups = filteredUserGroups.concat(generalGroups);
  }

  for (const userGroup of filteredUserGroups) {
    const { currentPermissionLevel } = defineGroupAccessTo(object, userGroup, user);
    if (isPermissionLevelLessEqual(requiredPermissionLevel, currentPermissionLevel)) {
      return true;
    }
  }
  return false;
}

export function hasPermissionFor(
  objectOrObjects,
  user,
  userGroups,
  requiredPermissionLevel,
  currentInstituteId,
  isGeneralRole = false
) {
  if (_.isArray(objectOrObjects)) {
    const result = [];
    for (const object of objectOrObjects) {
      result.push(hasPermissionForSingle(object, user, userGroups, requiredPermissionLevel));
    }
    return result;
  }
  // string, meaning it is a resource name
  if (_.isString(objectOrObjects)) {
    const instituteId = isGeneralRole ? null : currentInstituteId;
    const newObject = { tablename: objectOrObjects, institute: instituteId, id: null };
    return hasPermissionForSingle(newObject, user, userGroups, requiredPermissionLevel);
  }
  // single object, or nil
  if (_.isNil(objectOrObjects) || 'tablename' in objectOrObjects) {
    return hasPermissionForSingle(objectOrObjects, user, userGroups, requiredPermissionLevel);
  }
  const result = {};
  Object.entries(objectOrObjects).forEach(([objId, object]) => {
    result[objId] = hasPermissionForSingle(object, user, userGroups, requiredPermissionLevel);
  });
  return result;
}

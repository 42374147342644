import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect, useMemo } from 'react';

import MyDropdown from 'components/MyDropdown';
import { fuseSearch } from 'components/SearchBar/utils';
import { useDraftObjectList } from 'lib/misc/useDraft/useDraft';
import { Id, Ids } from 'lib/types';
import {
  StrategyCard,
  StrategyCardContent,
  StrategyCardMainSection,
} from 'screens/Portfolio/lib/hooks/useStrategyConfiguration/cards/StrategyCard';
import ConstraintsDropdowns from 'screens/Research/lib/components/ConstraintsDropdowns';
import { ResearchAction } from 'screens/Research/lib/constants';
import { createResearchConstraints } from 'screens/Research/lib/helpers';
import {
  useResearchConstraintListsQuery,
  useResearchListsQuery,
} from 'screens/Research/lib/queries';
import {
  ResearchActionT,
  ResearchConstraint,
  ResearchConstraintList,
  ResearchConstraintLists,
} from 'screens/Research/lib/types';

const translationPath = 'portfolioLib.strategyConfiguration.researchCard' as const;

interface ResearchConstraintListCard extends Partial<ResearchConstraintList> {
  cardId: string;
}

const researchContstraintListIdsToCards = (
  ids: Ids,
  allResearchConstraintLists: ResearchConstraintLists
): ResearchConstraintListCard[] => {
  return ids.map(id => {
    return {
      ...allResearchConstraintLists[id],
      cardId: nanoid(),
    };
  });
};

interface ResearchCardProps {
  researchConstraintListCard: ResearchConstraintListCard;
  disabled?: boolean;
  forbiddenResearchListIds?: Ids;
  onChangeResearchListId?: (value: Id) => void;
  onChangeConsListId?: (value: Id) => void;
  onChangeConstraint?: (value: ResearchConstraint) => void;
  onChangeNoOpinionAction?: (value: ResearchActionT) => void;
  onAddCard?: () => void;
  onRemoveCard?: () => void;
}

const ResearchCard = ({
  researchConstraintListCard,
  disabled = false,
  forbiddenResearchListIds = [],
  onChangeResearchListId,
  onChangeConsListId,
  onChangeConstraint,
  onChangeNoOpinionAction,
  onAddCard,
  onRemoveCard,
}: ResearchCardProps) => {
  const researchConsListId = researchConstraintListCard.id;
  const researchListId = researchConstraintListCard.researchList;
  const { researchLists, isLoading: isLoadingResearchLists } = useResearchListsQuery();
  const { researchConstraintLists, isLoading: isLoadingResearchConstraintLists } =
    useResearchConstraintListsQuery();
  const [searchValueResList, setSearchValueResList] = React.useState('');
  const [searchValueConsList, setSearchValueConsList] = React.useState('');
  const isLoading = isLoadingResearchLists || isLoadingResearchConstraintLists;

  const filteredConstraintLists = _.values(researchConstraintLists).filter(
    consList => consList.researchList === researchListId && consList.isPredefined
  );
  const searchedConstraintLists = fuseSearch(filteredConstraintLists, searchValueConsList, [
    'name',
  ]);
  const searchedResearchLists = fuseSearch(Object.values(researchLists), searchValueResList, [
    'name',
  ]).filter(
    resList => !forbiddenResearchListIds.includes(resList.id) || resList.id === researchListId
  );

  return (
    <StrategyCard
      isLoading={isLoading}
      title={`${translationPath}.title`}
      disabled={disabled}
      onAddCard={onAddCard}
      onRemoveCard={onRemoveCard}
    >
      <StrategyCardMainSection>
        <div>
          <MyDropdown
            // @ts-ignore
            value={researchListId}
            onChange={onChangeResearchListId}
            onUpdateSearch={setSearchValueResList}
            placeholderId='researchList'
            disabled={disabled}
          >
            {searchedResearchLists.map(resList => (
              <MenuItem key={resList.id} value={resList.id}>
                {resList.name}
              </MenuItem>
            ))}
          </MyDropdown>
        </div>

        <div>
          <MyDropdown
            // @ts-ignore
            value={researchConsListId}
            onChange={onChangeConsListId}
            onUpdateSearch={setSearchValueConsList}
            placeholderId='preset'
            disabled={disabled || !researchListId}
          >
            {searchedConstraintLists.map(consList => (
              <MenuItem key={consList.id} value={consList.id}>
                {consList.name}
              </MenuItem>
            ))}
          </MyDropdown>
        </div>
      </StrategyCardMainSection>

      <StrategyCardContent>
        <ConstraintsDropdowns
          recommendationTypes={researchLists[researchListId]?.recommendationTypes}
          draftConstraints={researchConstraintListCard?.constraints || []}
          draftNoOpinionAction={researchConstraintListCard?.noOpinionAction}
          setDraftConstraint={onChangeConstraint}
          setDraftNoOpinionAction={onChangeNoOpinionAction}
          disabled={disabled || researchConstraintListCard.isPredefined || !researchListId}
        />
      </StrategyCardContent>
    </StrategyCard>
  );
};

interface UseResearchCardsProps {
  researchConstraintListIds?: Ids;
  disabled?: boolean;
  isNew?: boolean;
}

const useResearchCards = ({
  researchConstraintListIds,
  disabled = false,
  isNew = false,
}: UseResearchCardsProps) => {
  const { researchConstraintLists: allResearchConstraintLists, isLoading: isLoadingConsLists } =
    useResearchConstraintListsQuery();
  const { researchLists, isLoading: isLoadingResearchLists } = useResearchListsQuery();

  const cards = useMemo(() => {
    return researchConstraintListIds !== undefined
      ? researchContstraintListIdsToCards(researchConstraintListIds, allResearchConstraintLists)
      : [];
  }, [researchConstraintListIds, allResearchConstraintLists]);
  const { draft, setDraftElement, setDraft, removeDraftElementAtKey, addDraftElement } =
    useDraftObjectList(cards, { idPropName: 'cardId' });
  const amountCards = draft.length;
  useEffect(() => {
    const isEmptyListIds =
      researchConstraintListIds !== undefined && researchConstraintListIds.length === 0;
    if ((isEmptyListIds || isNew) && !disabled && draft.length === 0) {
      addDraftElement({
        cardId: nanoid(),
      });
    }
  }, [researchConstraintListIds, addDraftElement, disabled, isNew, draft]);

  const forbiddenResearchListIds = draft.map(
    (card: ResearchConstraintListCard) => card.researchList
  );
  const cardElements = draft.map((card: ResearchConstraintListCard, index: number) => {
    const handleChangeConstraint = (constraint: ResearchConstraint) => {
      const newConstraints = (card.constraints ?? []).map(elem =>
        elem.recommendationType === constraint.recommendationType ? constraint : elem
      );
      setDraftElement({
        ...card,
        constraints: newConstraints,
      });
    };
    const handleChangeNoOpinionAction = (noOpinionAction: ResearchActionT) => {
      setDraftElement({
        ...card,
        noOpinionAction,
      });
    };

    const handleChangeResearchListId = (id: Id | null) => {
      if (id) {
        const researchList = researchLists[id];
        setDraftElement({
          cardId: card.cardId,
          isPredefined: false,
          researchList: id,
          constraints: researchList !== undefined ? createResearchConstraints(researchList) : [],
          noOpinionAction: ResearchAction.HOLD,
        });
      } else {
        setDraftElement({
          cardId: card.cardId,
        });
      }
    };
    const handleChangeConsListId = (id: Id) => {
      if (id === null) {
        if (card.researchList) {
          handleChangeResearchListId(card.researchList);
        }
      } else {
        setDraftElement({ cardId: card.cardId, ...allResearchConstraintLists[id] });
      }
    };
    const onRemoveCard = () => {
      removeDraftElementAtKey(card.cardId);
    };
    const onAddCard = () => {
      addDraftElement(
        {
          cardId: nanoid(),
        },
        index + 1
      );
    };
    return (
      <ResearchCard
        researchConstraintListCard={card}
        key={card.cardId}
        disabled={disabled}
        forbiddenResearchListIds={forbiddenResearchListIds}
        onChangeResearchListId={handleChangeResearchListId}
        onChangeConsListId={handleChangeConsListId}
        onChangeConstraint={handleChangeConstraint}
        onChangeNoOpinionAction={handleChangeNoOpinionAction}
        onRemoveCard={amountCards > 1 ? onRemoveCard : undefined}
        onAddCard={onAddCard}
      />
    );
  });
  const resetDraft = () => {
    setDraft(undefined);
  };
  return {
    cardElements,
    resetDraft,
    draft,
    isLoading: isLoadingConsLists || isLoadingResearchLists,
  };
};

export default useResearchCards;

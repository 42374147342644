import { createLocation } from 'components/MyRouting/utils';
import { createAction, createTypes } from 'lib/misc/misc';
import { Routes } from 'utils/constants';

const types = createTypes('navigation', [
  'RESET_NAVIGATION',
  'REDIRECT_TO',
  'ADD_PREV_ROUTE',
  'ADD_CURRENT_ROUTE',
  'REFRESH',
]);

export default types;

export const resetNavigation = createAction(types.RESET_NAVIGATION);
export const refresh = createAction(types.REFRESH);

export function redirectToHome() {
  return {
    type: types.REDIRECT_TO,
    payload: Routes.HOME,
  };
}

export function redirectToManageBaskets() {
  return {
    type: types.REDIRECT_TO,
    payload: Routes.NEW_BASKET,
  };
}

export function redirectTo(route, params) {
  return {
    type: types.REDIRECT_TO,
    payload: createLocation(route, params),
  };
}

export function addPrevRoute(route) {
  return {
    type: types.ADD_PREV_ROUTE,
    payload: route,
  };
}

export function addCurrentRoute(route) {
  return {
    type: types.ADD_CURRENT_ROUTE,
    payload: route,
  };
}

export function redirectToManageResearchLists() {
  return {
    type: types.REDIRECT_TO,
    payload: Routes.CHOOSE_RESEARCH_LIST,
  };
}

import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { createLocation } from 'components/MyRouting/utils';
import ObjectScreenBase from 'components/ObjectScreenBase';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import useEditPermission from 'lib/access/useEditPermission';
import { useDraftList, useDraftString } from 'lib/misc/useDraft/useDraft';
import { useCashQuery } from 'screens/Environment/lib/queries';
import {
  useEveryUniverseMutation,
  useUniverseQuery,
  useUniversesQuery,
} from 'screens/Universe/lib/queries';
import UniverseTable from 'screens/Universe/UniverseScreen/components/UniverseTable';
import { Routes } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  tablesContainer: {
    paddingLeft: theme.spacing(12),
  },
  searchMenu: {
    paddingLeft: theme.spacing(12),
  },
}));

const UniverseScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id: objId } = useParams();
  const isNew = !objId;
  const { universe, isLoading: isLoadingUniverse } = useUniverseQuery(objId);
  const hasEditAccess = useEditPermission(universe);
  const hasCreateAccess = useCreatePermission(PermissionResourceName.UNIVERSE);
  const hasAccess = isNew ? hasCreateAccess : hasEditAccess;
  const { cash } = useCashQuery();
  const { universes } = useUniversesQuery();
  const {
    createUniverse,
    deleteUniverse,
    updateUniverse,
    isLoading: isLoadingMutation,
  } = useEveryUniverseMutation();

  const { draft: draftName, setDraft: setDraftName } = useDraftString(universe?.name);
  const { draft: draftAssetIds, dispatchDraft: dispatchDraftAssetIds } = useDraftList(
    universe?.assets
  );

  const draft = { assets: draftAssetIds, name: draftName };

  useEffect(() => {
    if (isNew && cash) {
      dispatchDraftAssetIds({ type: 'add', value: [cash.id] });
    }
  }, [isNew, dispatchDraftAssetIds, cash]);

  const onSaveClick = () => {
    if (isNew) {
      createUniverse(draft, {
        onSuccess: data => {
          history.push(createLocation(Routes.UNIVERSE, data.id));
        },
      });
    } else {
      updateUniverse({ id: objId, data: draft });
    }
  };

  const onSaveNameClick = () => {
    updateUniverse({ id: objId, data: { name: draftName } });
  };

  const onDeleteClick = () => {
    deleteUniverse(objId, {
      onSuccess: () => {
        history.push(Routes.CHOOSE_UNIVERSE);
      },
    });
  };

  return (
    <ObjectScreenBase
      disabled={isLoadingMutation}
      onSaveNameClick={onSaveNameClick}
      waitWhile={isLoadingUniverse}
      object={universe}
      objects={universes}
      draftObj={draft}
      onSaveClick={onSaveClick}
      onDeleteClick={onDeleteClick}
      hasAccess={hasAccess}
      translationPath='universeScreen'
      propsToTriggerDialog={['assets']}
      routeNew={Routes.NEW_UNIVERSE}
      routeChoose={Routes.CHOOSE_UNIVERSE}
      routeObject={Routes.UNIVERSE}
      draftName={draftName}
      setDraftName={setDraftName}
    >
      <div className={classes.content}>
        <Grid container>
          <Grid item xs={12}>
            <UniverseTable
              draftAssetIds={draftAssetIds}
              dispatchDraftAssetIds={dispatchDraftAssetIds}
              disabled={!hasAccess}
            />
          </Grid>
        </Grid>
      </div>
    </ObjectScreenBase>
  );
};

export default UniverseScreen;

import { Checkbox, FormControlLabel, Link, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory } from 'react-router-dom';

import SimpleButton from 'components/SimpleButton';
import { useTranslate } from 'lib/localization/localization';
import { generateSearchPath, useURLSearchParams } from 'lib/navigation/navigation';
import usePasswordStrength from 'screens/Login/lib/hooks/usePasswordStrength';
import { useRegisterMutation } from 'screens/Login/lib/queries';
import TermsDialog from 'screens/Login/RegisterScreen/components/TermsDialog';
import { Routes } from 'utils/constants';

const translationPath = 'registerScreen.registerForm';

const useStyles = makeStyles(theme => ({
  section: {
    paddingTop: theme.spacing(6),
  },
  root: {
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  registerButton: {
    paddingTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  orLoginLink: {
    paddingTop: theme.spacing(2),
  },
}));

const RegisterForm = () => {
  const classes = useStyles();
  const searchParams = useURLSearchParams<['token', 'email']>();
  const translate = useTranslate();
  const history = useHistory();

  const [name, setName] = React.useState('');
  const [surname, setSurname] = React.useState('');
  const {
    password,
    isPasswordStrong,
    passwordRepeat,
    arePasswordsSame,
    passwordFieldElem,
    passwordRepeatFieldElem,
  } = usePasswordStrength();
  const { mutate: register, isLoading } = useRegisterMutation();

  const [areTermsAccepted, setAreTermsAccepted] = React.useState(false);

  const [areTermsOpen, setAreTermsOpen] = React.useState(false);

  const onTermsOpen = (event: any) => {
    event.preventDefault();
    setAreTermsOpen(true);
  };
  const onTermsClose = () => {
    setAreTermsOpen(false);
  };

  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = React.useState(false);
  const onPrivacyPolicyOpen = (event: any) => {
    event.preventDefault();
    setIsPrivacyPolicyOpen(true);
  };
  const onPrivacyPolicyClose = () => {
    setIsPrivacyPolicyOpen(false);
  };

  const onRedirectToLogin = (event: any) => {
    event.preventDefault();
    history.push(generateSearchPath(Routes.LOGIN, { searchParams: { email: searchParams.email } }));
  };

  const allFieldsEntered =
    name.length > 0 &&
    surname.length > 0 &&
    password.length > 0 &&
    passwordRepeat.length > 0 &&
    areTermsAccepted;

  const onRegisterClick = () => {
    register({ token: searchParams.token, data: { name, surname, password } });
  };

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <TextField
          size='small'
          disabled
          fullWidth
          label={translate(`${translationPath}.email`)}
          variant='outlined'
          value={searchParams.email}
        />
      </div>
      <div className={classes.section}>
        <TextField
          size='small'
          fullWidth
          required
          label={translate(`${translationPath}.name`)}
          variant='outlined'
          value={name}
          onChange={event => {
            setName(event.target.value);
          }}
        />
      </div>
      <div className={classes.section}>
        <TextField
          size='small'
          fullWidth
          required
          label={translate(`${translationPath}.surname`)}
          variant='outlined'
          value={surname}
          onChange={event => {
            setSurname(event.target.value);
          }}
        />
      </div>
      <div className={classes.section}>{passwordFieldElem}</div>
      <div className={classes.section}>{passwordRepeatFieldElem}</div>
      <div className={classes.section}>
        <TermsDialog isOpen={areTermsOpen} onClose={onTermsClose} type='termsAndConditions' />
        <TermsDialog
          isOpen={isPrivacyPolicyOpen}
          onClose={onPrivacyPolicyClose}
          type='privacyPolicy'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={areTermsAccepted}
              onChange={event => {
                setAreTermsAccepted(event.target.checked);
              }}
              name='termsAccepted'
              color='primary'
            />
          }
          label={
            <>
              {translate(`${translationPath}.agreeToTerms1`)}
              <Link href='#' onClick={onTermsOpen}>
                {translate(`${translationPath}.termsConditions`)}
              </Link>
              {translate(`${translationPath}.agreeToTerms2`)}
              <Link href='#' onClick={onPrivacyPolicyOpen}>
                {translate(`${translationPath}.privacyPolicy`)}
              </Link>
              {translate(`${translationPath}.agreeToTerms3`)}
            </>
          }
        />
      </div>
      <div className={classes.registerButton}>
        <SimpleButton
          clickOnEnter
          disabled={!allFieldsEntered || !arePasswordsSame || !isPasswordStrong || isLoading}
          size='large'
          onClick={onRegisterClick}
          variant='highEmp'
          translateId={`${translationPath}.registerButton`}
        />
        <Link href='#' onClick={onRedirectToLogin} className={classes.orLoginLink}>
          {translate(`${translationPath}.orLogin`)}
        </Link>
      </div>
    </div>
  );
};

export default RegisterForm;

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import DialogButton from 'components/Buttons/DialogButton';
import SimpleText from 'components/SimpleText';
import { ClassesType } from 'utils/types';

const styles = {
  children: {
    marginLeft: '1rem',
    marginRight: '1rem',
    fontFamily: 'ralewayFont',
    fontWeight: 'fontWeightNormal',
    fontSize: '0.875rem',
    lineHeight: 1,
    letterSpacing: '0.01875em',
  },
};

const MyDialog = props => {
  const {
    classes,
    isOpen,
    header,
    message,
    messageData,
    notPerformKey,
    performKey,
    className,
    onNotPerformClick,
    onPerformClick,
    children,
    isNotPerformKeyDisabled,
    isPerformKeyDisabled,
    ...other
  } = props;
  return (
    <Dialog
      data-test-id='dialogComponent'
      open={isOpen}
      onClose={null}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='md'
      {...other}
    >
      <DialogTitle id='alert-dialog-title'>
        <SimpleText variant='h2' translateId={header} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <SimpleText variant='body1' translateId={message} translateData={messageData} />
        </DialogContentText>
        <DialogContentText className={classes.children}>
          <div className={classNames(className, classes.children)}>{children}</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {notPerformKey ? (
          <DialogButton
            disabled={isNotPerformKeyDisabled}
            onClick={onNotPerformClick}
            translateId={notPerformKey}
            variant='lowEmp'
          />
        ) : null}
        {performKey ? (
          <DialogButton
            disabled={isPerformKeyDisabled}
            onClick={onPerformClick}
            translateId={performKey}
            variant='lowEmp'
          />
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

MyDialog.propTypes = {
  classes: ClassesType,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  message: PropTypes.string,
  messageData: PropTypes.object,
  notPerformKey: PropTypes.string,
  performKey: PropTypes.string,
  onNotPerformClick: PropTypes.func,
  onPerformClick: PropTypes.func,
  children: PropTypes.node,
  isNotPerformKeyDisabled: PropTypes.bool,
  isPerformKeyDisabled: PropTypes.bool,
};

MyDialog.defaultProps = {
  classes: null,
  className: '',
  children: null,
  message: '',
  messageData: undefined,
  isNotPerformKeyDisabled: false,
  isPerformKeyDisabled: false,
  onNotPerformClick: undefined,
  onPerformClick: undefined,
  notPerformKey: undefined,
  performKey: undefined,
};

export default withStyles(styles, { withTheme: true })(MyDialog);

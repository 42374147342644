import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from 'react';

import GlobalErrorHandler from 'components/Global/GlobalErrorHandler';
import GlobalStyle from 'components/Global/GlobalStyle';
import LocalizeInitializer from 'components/LocalizeInitializer';
import MyRoutes from 'components/MyRouting/MyRoutes';
import SocketioEventListener from 'components/SocketioEventListener';
import { useLightDarkMode } from 'lib/theming/lightDarkMode';
import { createCustomTheme } from 'lib/theming/theme';
import { Router } from 'utils/extensions';

const App = () => {
  const { currentMode } = useLightDarkMode();
  const theme = React.useMemo(() => createCustomTheme(currentMode), [currentMode]);

  return (
    <>
      <LocalizeInitializer />
      <GlobalStyle />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalErrorHandler>
            <Router>
              <SocketioEventListener />
              <MyRoutes />
            </Router>
          </GlobalErrorHandler>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;

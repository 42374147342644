import Checkbox from '@mui/material/Checkbox/index';
import TableCell from '@mui/material/TableCell/index';
import TableHead from '@mui/material/TableHead/index';
import TableRow from '@mui/material/TableRow/index';
import TableSortLabel from '@mui/material/TableSortLabel/index';
import Tooltip from '@mui/material/Tooltip/index';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';

import SimpleText from 'components/SimpleText';
import { Order } from 'utils/constants';
import { ClassesType, OrderType } from 'utils/types';

const styles = {
  tableHeader: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  columnName: {
    maxWidth: '2%',
  },
  checkbox: {
    padding: '12px !important',
  },
  deleteIcon: {
    width: 30,
  },
};

class EnhancedTableHead extends React.Component {
  getSortDirection = (columnKey, orderBy, order) => {
    if (columnKey !== orderBy) {
      return Order.ASC;
    }
    if (columnKey === orderBy && order === Order.DESC) {
      return Order.ASC;
    }
    return Order.DESC;
  };

  render() {
    const {
      onSelectAllClick,
      numSelected,
      rowCount,
      columns,
      orderBy,
      order,
      onColumnSortClick,
      translateTableId,
      classes,
      isWithCheckbox,
      areAnyRowsDeletable,
      areAnyRowsDownloadable,
    } = this.props;

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          <TableCell padding='checkbox'>
            {isWithCheckbox && onSelectAllClick ? (
              <Checkbox
                color='primary'
                size='medium'
                classes={{ root: classes.checkbox }}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected !== 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            ) : null}
          </TableCell>
          {columns.map(columnKey => (
            <Translate key={columnKey}>
              {({ translate }) => (
                <TableCell
                  className={classes.columnName}
                  key={columnKey}
                  sortDirection={orderBy === columnKey ? order : false}
                >
                  <Tooltip title={translate('sort')}>
                    <TableSortLabel
                      active={orderBy === columnKey}
                      direction={order || Order.DESC}
                      onClick={() =>
                        onColumnSortClick(
                          this.getSortDirection(columnKey, orderBy, order),
                          columnKey
                        )
                      }
                    >
                      {translateTableId ? (
                        <SimpleText
                          translateId={`${translateTableId}.${columnKey}`}
                          variant='subtitle2'
                          fontWeight='bold'
                        />
                      ) : (
                        <SimpleText text={columnKey} variant='subtitle2' fontWeight='bold' />
                      )}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              )}
            </Translate>
          ))}
          {areAnyRowsDeletable ? (
            <TableCell className={classes.deleteIconRow} size='small' />
          ) : null}
          {areAnyRowsDownloadable ? (
            <TableCell className={classes.deleteIconRow} size='small' />
          ) : null}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func,
  rowCount: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  order: OrderType.isRequired,
  orderBy: PropTypes.string.isRequired,
  onColumnSortClick: PropTypes.func.isRequired,
  translateTableId: PropTypes.string.isRequired,
  classes: ClassesType.isRequired,
  isWithCheckbox: PropTypes.bool.isRequired,
  areAnyRowsDeletable: PropTypes.bool,
  areAnyRowsDownloadable: PropTypes.bool,
};

EnhancedTableHead.defaultProps = {
  onSelectAllClick: null,
  areAnyRowsDeletable: false,
  areAnyRowsDownloadable: false,
};

export default withStyles(styles, { withTheme: true })(EnhancedTableHead);

import { PermissionLevel } from 'lib/access/constants';
import useFunctionalityOn from 'lib/access/useFunctionalityOn';
import useHasPermission from 'lib/access/useHasPermission';
import { REQUIRED_FUNCTIONALITY_FOR_ROUTE, REQUIRED_PERMISSIONS_FOR_ROUTE } from 'utils/constants';

const DEFAULT_PERMISSION_LEVEL = PermissionLevel.READ;

export const useIsPathAvailable = path => {
  const requiredPermissions = REQUIRED_PERMISSIONS_FOR_ROUTE[path] || {};
  const functionalityName = REQUIRED_FUNCTIONALITY_FOR_ROUTE[path] || null;

  const isFunctionalityOn = useFunctionalityOn(functionalityName);

  let hasPermission = useHasPermission(
    requiredPermissions.resourceName,
    requiredPermissions.permissionLevel || DEFAULT_PERMISSION_LEVEL,
    requiredPermissions.isGeneralRole
  );
  if (!requiredPermissions.resourceName) {
    hasPermission = true;
  }
  return hasPermission && isFunctionalityOn;
};

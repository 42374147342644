import { Cancel } from '@mui/icons-material';
import { Toolbar, IconButton, MenuItem, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import MyDropdown from 'components/MyDropdown/MyDropdown';
import PositionMe from 'components/PositionMe';
import RestToRight from 'components/RestToRight';
import SimpleText from 'components/SimpleText';
import { changePageOrientation, toggleEdit } from 'screens/Report/redux/actions';
import ConfigurationBottomToolbar from 'screens/Report/ReportScreen/components/ConfigurationBottomToolbar';

const useStyles = makeStyles(theme => ({
  content: {
    position: 'fixed',
    height: '85vh',
    width: '300px',
    backgroundColor: '#FFFF',
    right: '0',
    bottom: '10px',
    boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)',
    zIndex: '100',
    borderRadius: theme.shape.borderRadius,
  },
}));

const orientations = [
  { name: 'Landscape', value: 'landscape' },
  { name: 'Portrait', value: 'portrait' },
];

const ReportConfig = ({ closeAction, storedOrientation }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [orientation, setOrientation] = useState(storedOrientation);

  return (
    <div className={classes.content}>
      <Toolbar style={{ marginBottom: '0.7rem' }}>
        <SimpleText translateId='dashboard.configuration' variant='h5' />
        <RestToRight />
        <IconButton onClick={closeAction} size='large'>
          <Cancel color='action' size='small' />
        </IconButton>
      </Toolbar>
      <Container fixed>
        <SimpleText translateId='dashboard.orientation' fontWeight='bold' mb={2} />
        <MyDropdown
          value={orientation}
          onChange={v => setOrientation(v)}
          placeholderId='orientation'
          isNullAllowed={false}
        >
          {orientations.map(item => (
            <MenuItem key={item.name} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </MyDropdown>
        <PositionMe howHorizontal='right' howVertical='bottom'>
          <ConfigurationBottomToolbar
            onCancelAction={closeAction}
            onSaveAction={() => {
              dispatch(toggleEdit(true));
              dispatch(changePageOrientation(orientation));
              closeAction();
            }}
          />
        </PositionMe>
      </Container>
    </div>
  );
};

ReportConfig.propTypes = {
  closeAction: PropTypes.func.isRequired,
  storedOrientation: PropTypes.string.isRequired,
};

export default ReportConfig;

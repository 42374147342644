import { endOfDay } from 'date-fns';
import React from 'react';

import DatePickerBase from 'lib/dates/DatePickerBase';
import { IsoDate } from 'lib/types';

interface DatesPickerProps {
  date?: IsoDate | null;
  onDateChange: (date: IsoDate) => void;
  disabled?: boolean;
  limitStartDate?: Date | IsoDate;
  limitEndDate?: Date | IsoDate;
}

const DatePicker = ({
  date,
  onDateChange,
  disabled = false,
  limitEndDate,
  limitStartDate,
}: DatesPickerProps) => {
  const limitStartDateDate = limitStartDate ? endOfDay(new Date(limitStartDate)) : undefined;
  const limitEndDateDate = limitEndDate ? endOfDay(new Date(limitEndDate)) : undefined;

  return (
    <DatePickerBase
      value={date}
      onChange={onDateChange}
      // @ts-ignore
      minDate={limitStartDateDate}
      maxDate={limitEndDateDate}
      disabled={disabled}
    />
  );
};

export default DatePicker;

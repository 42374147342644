import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import MyDropdown from 'components/MyDropdown/MyDropdown';
import ChooseScreen from 'components/SearchTools/ChooseScreen';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import { generateSearchPath, useURLSearchParams } from 'lib/navigation/navigation';
import {
  useDeleteResearchConstraintListMutation,
  useResearchConstraintListsQuery,
  useResearchListsQuery,
} from 'screens/Research/lib/queries';
import { Routes } from 'utils/constants';

const columns = ['id', 'name', 'createdOn', 'updatedOn', 'researchList'];

const useStyles = makeStyles(theme => ({
  dropdown: {
    marginTop: theme.spacing(2),
  },
}));

const ChooseResearchConstraintListScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const searchParams = useURLSearchParams();
  const { researchListId } = searchParams;
  const hasCreatePermission = useCreatePermission(PermissionResourceName.RESEARCH_CONSTRAINT_LIST);

  const { researchConstraintLists, isLoading } = useResearchConstraintListsQuery({
    filter: 'predefined',
  });
  const researchConsListsFiltered = useMemo(() => {
    if (!researchListId) {
      return researchConstraintLists;
    }
    return Object.values(researchConstraintLists).filter(
      obj => obj.researchList === researchListId
    );
  }, [researchConstraintLists, researchListId]);

  const { researchLists } = useResearchListsQuery();
  const { mutate: deleteObj } = useDeleteResearchConstraintListMutation();
  const cellFormatting = {
    researchList: resListId =>
      Object.values(researchLists).find(resList => resList.id === resListId)?.name || resListId,
    createdOn: date => (date ? format(new Date(date), 'yyyy-MM-dd') : ''),
    updatedOn: date => (date ? format(new Date(date), 'yyyy-MM-dd') : ''),
  };
  const researchListDropdown = (
    <MyDropdown
      className={classes.dropdown}
      value={researchListId}
      onChange={value => {
        if (value) {
          history.push(
            generateSearchPath(Routes.CHOOSE_RESEARCH_CONSTRAINT_LIST, {
              searchParams: { researchListId: value },
            })
          );
        } else {
          history.push(Routes.CHOOSE_RESEARCH_CONSTRAINT_LIST);
        }
      }}
      onUpdateSearch={null}
      translationPath='chooseResearchConstraintListScreen.researchListDropdown'
    >
      {_.values(researchLists).map(resList => (
        <MenuItem key={resList.id} value={resList.id}>
          {resList.name}
        </MenuItem>
      ))}
    </MyDropdown>
  );
  return (
    <ChooseScreen
      dialogTranslatePath='researchConstraintListScreen.useSavePromptAndDialog'
      returnButtonProps={
        researchListId
          ? {
              translateId: `chooseResearchConstraintListScreen.returnButtonToResearchList`,
              route: generatePath(Routes.RESEARCH_LIST, { id: researchListId }),
            }
          : undefined
      }
      topScreen={researchListDropdown}
      waitWhile={isLoading}
      key={columns}
      tableObjects={researchConsListsFiltered}
      columns={columns}
      searchColumns={columns}
      cellFormatting={cellFormatting}
      searchValue={null}
      translationPath='chooseResearchConstraintListScreen'
      isTableWhite
      onNewClick={
        hasCreatePermission
          ? () =>
              history.push(
                generateSearchPath(Routes.NEW_RESEARCH_CONSTRAINT_LIST, { searchParams })
              )
          : null
      }
      onRowClick={id =>
        history.push(
          generateSearchPath(Routes.RESEARCH_CONSTRAINT_LIST, { params: { id }, searchParams })
        )
      }
      onDeleteRowClick={obj => {
        deleteObj(obj.id);
      }}
    />
  );
};

export default ChooseResearchConstraintListScreen;

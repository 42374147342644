import { Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { first, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import BarChartWrapper from 'screens/Analysis/lib/components/BarChartWrapper';
import TestSpinner from 'screens/Analysis/lib/components/TestSpinner';
import useDateToggle from 'screens/Analysis/lib/components/useDateToggle';
import WidgetFooter from 'screens/Analysis/lib/components/WidgetFooter';
import WidgetTitleSelector from 'screens/Analysis/lib/components/WidgetTitleSelector';
import { FOURTH_LEVEL_HEIGHT } from 'screens/Analysis/lib/constants';
import { useAnalysisQuery } from 'screens/Analysis/lib/queries';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '1.7rem',
    height: FOURTH_LEVEL_HEIGHT,
    position: 'relative',
  },
});

const DownCaptureChart = ({ analysisId }) => {
  const classes = useStyles();

  const { analysis } = useAnalysisQuery(analysisId);
  const widgetData = analysis?.result?.DownCaptureBar ?? {};

  const [dateFilter, DateFilterComponent] = useDateToggle(
    !isEmpty(widgetData) ? first(widgetData.filters) : 'all'
  );

  return (
    <Grid item md={12} lg={12}>
      <Paper className={classes.wrapper}>
        <Grid container style={{ height: '100%' }}>
          {isEmpty(widgetData) ? (
            <TestSpinner />
          ) : (
            <>
              <WidgetTitleSelector mainTitle='downCaptureMonth' disableSelection />
              <DateFilterComponent
                available={widgetData.filters}
                errors={widgetData.errors}
                hints={widgetData.hints}
              />
              <div style={{ height: '82%', width: '100%', position: 'relative' }}>
                <BarChartWrapper
                  height={330}
                  data={Object.values(widgetData.data[dateFilter]).map(
                    ({ label, portfolio, benchmark }) => ({
                      date: label,
                      Portfolio: (portfolio * 100).toFixed(1),
                      Benchmark: (benchmark * 100).toFixed(1),
                    })
                  )}
                  padding={0.5}
                />
              </div>
              <WidgetFooter>
                <span style={{ color: '#abe6e9' }}>Portfolio</span>
                <span style={{ color: '#d9dada' }}>Benchmark</span>
              </WidgetFooter>
            </>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

DownCaptureChart.propTypes = {
  analysisId: PropTypes.string,
};

DownCaptureChart.defaultProps = {
  analysisId: undefined,
};

export default DownCaptureChart;

import React, { useMemo } from 'react';

import MyProgress from 'components/MyProgress';
import SimpleText from 'components/SimpleText';
import { PerformanceInfoType } from 'lib/performance/constants';
import { Id } from 'lib/types';
import { AnalysisDataType } from 'screens/Analysis/lib/constants';
import { AnalysisDataTypeT } from 'screens/Analysis/lib/types';
import { useBacktestQuery } from 'screens/Backtest/lib/queries';
import { usePortfolioHistoryQuery, usePortfolioQuery } from 'screens/Portfolio/lib/queries';

const translationPath = 'portfolioLib.usePortfolioPerformance' as const;

const getErrorTranslateId = (
  portfolioId: Id | undefined,
  dataType: AnalysisDataTypeT | undefined,
  backtestId: Id | undefined
) => {
  if (portfolioId === undefined && dataType !== AnalysisDataType.BACKTEST) {
    return 'noPortfolioId';
  }
  if (backtestId === undefined && dataType === AnalysisDataType.BACKTEST) {
    return 'noBacktestId';
  }
  if (dataType === undefined) {
    return 'noDataType';
  }
  return 'performanceNotAvailable';
};

interface UsePortfolioPerformanceProps {
  portfolioId?: Id;
  dataType?: AnalysisDataTypeT;
  backtestId?: Id;
}

const usePortfolioPerformance = ({
  portfolioId,
  dataType,
  backtestId,
}: UsePortfolioPerformanceProps) => {
  const isBacktest = dataType === AnalysisDataType.BACKTEST;
  const { backtest, isLoading: isLoadingBacktest } = useBacktestQuery(
    isBacktest ? backtestId : undefined
  );
  const { history, isLoading: isLoadingHistory } = usePortfolioHistoryQuery(
    !isBacktest ? portfolioId : undefined
  );
  const { portfolio, isLoading: isLoadingPortfolio } = usePortfolioQuery(
    !isBacktest ? portfolioId : undefined
  );
  const isLoading = isBacktest ? isLoadingBacktest : isLoadingPortfolio || isLoadingHistory;

  const performanceInfo = useMemo(() => {
    const id = isBacktest ? backtestId : portfolioId;
    if (!id) {
      return undefined;
    }
    return {
      performance: isBacktest ? backtest?.performance : history?.performance,
      id,
      name: isBacktest ? backtest?.name : portfolio?.name,
      type: PerformanceInfoType.PORTFOLIO,
    };
  }, [
    backtest?.name,
    backtest?.performance,
    backtestId,
    history?.performance,
    isBacktest,
    portfolio?.name,
    portfolioId,
  ]);

  let content: JSX.Element | undefined;
  if (isLoading) {
    content = <MyProgress translateId={`${translationPath}.loadingPerformance`} />;
  } else if (performanceInfo?.performance === undefined) {
    content = (
      <SimpleText
        color='error.main'
        variant='h3'
        translateId={`${translationPath}.${getErrorTranslateId(portfolioId, dataType, backtestId)}`}
      />
    );
  }

  return {
    performanceInfo,
    points: isBacktest ? backtest?.points : history?.points,
    isLoading,
    progressOrErrorElem: content,
  };
};

export default usePortfolioPerformance;

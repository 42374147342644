import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { Avatar, Box, Chip, CircularProgress, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useMemo, useState } from 'react';

import MyLineChart from 'components/MyLineChart/MyLineChartWrapper';
import { computeLineChartData, LineChartData, LineChartSeriesData } from 'lib/performance/helpers';
import { PerformanceInfo, TimeSeriesDatePoint } from 'lib/performance/types';
import { getTextColor } from 'lib/theming/utils';
import { getPercentagePerformance, parseDatesInSeries } from 'lib/timeSeries/tsData';
import { Id, IsoDate } from 'lib/types';
import { HistoricalSnapshot, TimeSeries } from 'screens/Portfolio/lib/types';

interface PerformanceChartProps {
  performanceInfo: PerformanceInfo;
  benchmarkPerformanceInfos?: PerformanceInfo[];
  points?: ReadonlyArray<HistoricalSnapshot>;
  onDatesChange?: (startDate: Date, endDate: Date) => void;
  onRebalancingPointClick?: (rebalancingPoint1: Date, rebalancingPoint2: Date) => void;
  onPointerMove?: (date: Date) => void;
  onRemoveBenchmark?: (id: Id) => void;
  disabled?: boolean;
  legendType?: 'interactive' | 'static' | 'none';
  startDate?: Date | IsoDate;
  endDate?: Date | IsoDate;
  getColorSetFn?: (nColors: number) => string[];
  gridLinesCount?: number;
}

interface StaticLegendProps {
  data: LineChartData;
}

interface LegendProps {
  data: LineChartData;
  pointerPosition: Date | null;
  onRemoveBenchmark?: (id: Id) => void;
  onHover?: (name: string | null) => void;
}

interface LegendChipProps {
  dataItem: LineChartSeriesData;
  pointerPosition: Date | null;
  onRemoveBenchmark?: (id: Id) => void;
  onHover?: (name: string | null) => void;
}

function computeRebalancingPointsData(timeSeries: TimeSeriesDatePoint[], dates: Date[]) {
  const rebalancingMarkSeries: TimeSeriesDatePoint[] = [];
  if (dates) {
    dates.forEach(date => {
      const rebalancingDate = new Date(date);
      timeSeries.forEach(tsPoint => {
        if (rebalancingDate.getTime() === tsPoint.x.getTime()) {
          const rebalancingDataPoint = {
            x: tsPoint.x,
            y: tsPoint.y,
          };
          rebalancingMarkSeries.push(rebalancingDataPoint);
        }
      });
    });
  }

  return rebalancingMarkSeries;
}

function sortByEffectiveDate(a: HistoricalSnapshot, b: HistoricalSnapshot) {
  if (a.effectiveDate < b.effectiveDate) {
    return -1;
  }
  if (a.effectiveDate > b.effectiveDate) {
    return 1;
  }
  return 0;
}

function computeBacktestRebalancingPointsData(
  performance?: TimeSeries,
  points?: ReadonlyArray<HistoricalSnapshot>
) {
  if (points && performance) {
    const pointsCopy = points.slice();
    pointsCopy.sort(sortByEffectiveDate);
    const percentagePerformance = getPercentagePerformance(parseDatesInSeries(performance));
    const effectiveDates: Date[] = [];
    pointsCopy.forEach((point, i) => {
      // first point should always be included
      // and if no rebalancing, do not include the point
      if (i === 0 || i === pointsCopy.length - 1 || point.wasRebalanced) {
        const effectiveDate = new Date(point.effectiveDate);
        effectiveDates.push(effectiveDate);
      }
    });
    return computeRebalancingPointsData(percentagePerformance, effectiveDates);
  }
  return null;
}

function getChipLegendValue(series: TimeSeriesDatePoint[] | null, pointerPosition: Date | null) {
  if (series && pointerPosition) {
    for (let i = 0; i < series.length; i += 1) {
      const point = series[i];
      if (point) {
        if (point.x.getTime() === pointerPosition.getTime()) {
          return `${point.y.toFixed(2)} %`;
        }
      }
    }
  }
  if (series) {
    const lastPoint = series[series.length - 1];
    if (lastPoint) {
      return `${lastPoint.y.toFixed(2)} %`;
    }
  }
  return '-';
}

const LegendChip = ({ dataItem, pointerPosition, onRemoveBenchmark, onHover }: LegendChipProps) => {
  const value = useMemo(() => {
    return getChipLegendValue(dataItem.series, pointerPosition);
  }, [dataItem.series, pointerPosition]);
  let progressIcon;
  let errorIcon;
  const textColor = useMemo(() => {
    return getTextColor(dataItem.color);
  }, [dataItem.color]);
  if (dataItem.isLoading) {
    progressIcon = <CircularProgress size='1rem' sx={{ color: textColor }} />;
  }
  if (dataItem.error) {
    errorIcon = (
      <Tooltip title={dataItem.error}>
        <ErrorOutline color='error' />
      </Tooltip>
    );
  }

  let avatar;
  if (progressIcon ?? errorIcon) {
    avatar = (
      <Avatar style={{ backgroundColor: dataItem.color }}>{progressIcon ?? errorIcon}</Avatar>
    );
  }
  return (
    <Chip
      variant='filled'
      label={`${dataItem.name} ${value}`}
      avatar={avatar}
      sx={{ color: textColor, backgroundColor: dataItem.color }}
      onDelete={onRemoveBenchmark ? () => onRemoveBenchmark(dataItem.id) : undefined}
      onMouseOver={onHover ? () => onHover(dataItem.name) : undefined}
      onMouseOut={onHover ? () => onHover(null) : undefined}
    />
  );
};

const Legend = ({ data, pointerPosition, onRemoveBenchmark, onHover }: LegendProps) => {
  return (
    <div
      style={{
        zIndex: 1,
        position: 'absolute',
        top: '2rem',
        left: '4rem',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      {Object.entries(data).map(([key, dataItem]) => (
        <Box sx={{ m: 1 }}>
          <LegendChip
            dataItem={dataItem}
            pointerPosition={pointerPosition}
            key={key}
            onRemoveBenchmark={key !== 'main' ? onRemoveBenchmark : undefined}
            onHover={onHover}
          />
        </Box>
      ))}
    </div>
  );
};

const StaticLegend = ({ data }: StaticLegendProps) => {
  return (
    <div>
      {Object.entries(data).map(([key, dataItem]) => (
        <Chip
          key={key}
          label={dataItem.name ?? dataItem.id}
          style={{
            color: getTextColor(dataItem.color),
            backgroundColor: dataItem.color,
            margin: '0.2rem',
            height: '18px',
          }}
        />
      ))}
    </div>
  );
};

const PerformanceChart = ({
  performanceInfo,
  points,
  onDatesChange,
  onRebalancingPointClick,
  onPointerMove,
  benchmarkPerformanceInfos = [],
  onRemoveBenchmark,
  disabled = false,
  startDate,
  endDate,
  legendType = 'interactive',
  getColorSetFn,
  gridLinesCount = 7,
}: PerformanceChartProps) => {
  const theme = useTheme();
  const rebalancingData = useMemo(() => {
    return computeBacktestRebalancingPointsData(performanceInfo.performance, points);
  }, [performanceInfo, points]);
  const data = useMemo(() => {
    return computeLineChartData(
      performanceInfo,
      benchmarkPerformanceInfos,
      startDate,
      endDate,
      getColorSetFn,
      theme.palette.text.primary,
      theme.palette.graphs.bright,
      theme.palette.graphs.dark
    );
  }, [
    performanceInfo,
    benchmarkPerformanceInfos,
    startDate,
    endDate,
    getColorSetFn,
    theme.palette.text.primary,
    theme.palette.graphs.bright,
    theme.palette.graphs.dark,
  ]);
  const [pointerPosition, setPointerPosition] = useState<Date | null>(null);
  const [hoveredLine, setHoveredLine] = useState<string | null>(null);
  const handlePointerMove = (date: Date) => {
    setPointerPosition(date);
    if (onPointerMove) {
      onPointerMove(date);
    }
  };

  return (
    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          width: '100%',
          flexGrow: 1,
          minHeight: 0,
          minWidth: 0,
        }}
      >
        <div style={{ height: '100%', width: '100%' }}>
          {legendType === 'interactive' && (
            <Legend
              data={data}
              pointerPosition={pointerPosition}
              onRemoveBenchmark={disabled ? undefined : onRemoveBenchmark}
              onHover={setHoveredLine}
            />
          )}
          <MyLineChart
            onDatesChange={disabled ? undefined : onDatesChange}
            data={data}
            markSeriesData={data.main.series}
            isDummyBacktest
            gridLinesCount={gridLinesCount}
            hoveredLine={hoveredLine}
            zoomSlowness={20}
            tickTotalX={12}
            onPointerMove={disabled ? undefined : handlePointerMove}
            rebalancingPoints={rebalancingData}
            onRebalancingPointClick={disabled ? undefined : onRebalancingPointClick}
            transparentBackground
            skipScrolling={disabled}
          />
        </div>
      </div>

      {legendType === 'static' && (
        <div>
          <StaticLegend data={data} />
        </div>
      )}
    </div>
  );
};

export default PerformanceChart;

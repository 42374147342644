import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withLocalize } from 'react-localize-redux';

import SearchBarView from 'components/SearchBar/SearchBarView';
import SimpleText from 'components/SimpleText';
import { ChildrenType, ClassesType } from 'utils/types';

const styles = theme => ({
  textField: {
    width: '100%',
    minWidth: '100px',
    maxWidth: '400px',
    color: theme.palette.text.primary,
  },
});

const stopImmediatePropagation = e => {
  e.stopPropagation();
  e.preventDefault();
};

class MyDropdown extends PureComponent {
  onChangeInternal = (event, value) => {
    const { onChange } = this.props;
    onChange(value.props.value || null);
  };

  onExitedInternal = () => {
    const { onResetSearch, onUpdateSearch } = this.props;
    if (!onUpdateSearch) {
      return;
    }
    if (onResetSearch) {
      onResetSearch();
    } else {
      onUpdateSearch(null);
    }
  };

  render() {
    const {
      classes,
      value,
      onUpdateSearch,
      disabled,
      placeholderId,
      translationPath,
      translate,
      isNullAllowed,
      children,
      className,
      fullWidth,
      ...other
    } = this.props;
    let label = translationPath ? translate(`${translationPath}.placeholder`) : null;
    if (!label) {
      label = placeholderId ? translate(`dropdownPlaceholder.${placeholderId}`) : '';
    }
    return (
      <TextField
        size='small'
        select
        className={clsx(className, classes.textField)}
        variant='outlined'
        value={value || ''}
        onChange={this.onChangeInternal}
        SelectProps={{
          MenuProps: {
            TransitionProps: {
              onExited: this.onExitedInternal,
            },
          },
        }}
        placeholder='Placeholder'
        label={label}
        disabled={disabled}
        fullWidth={fullWidth}
        pendo-id={other['pendo-id'] ?? undefined}
      >
        {onUpdateSearch ? (
          <Box
            sx={{ mr: 2, ml: 2, mb: 2 }}
            onClickCapture={stopImmediatePropagation}
            onKeyDown={event => {
              event.stopPropagation();
            }}
          >
            <SearchBarView onUpdateSearch={onUpdateSearch} placeholderId='search' />
          </Box>
        ) : null}
        {isNullAllowed ? (
          <MenuItem value=''>
            <em>
              <SimpleText translateId='none' />
            </em>
          </MenuItem>
        ) : null}

        {children}
      </TextField>
    );
  }
}

MyDropdown.propTypes = {
  classes: ClassesType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  onUpdateSearch: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onResetSearch: PropTypes.func,
  disabled: PropTypes.bool,
  placeholderId: PropTypes.string,
  translationPath: PropTypes.string,
  translate: PropTypes.func.isRequired,
  isNullAllowed: PropTypes.bool,
  children: ChildrenType.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};

MyDropdown.defaultProps = {
  disabled: false,
  onResetSearch: null,
  isNullAllowed: true,
  className: '',
  value: null,
  onUpdateSearch: null,
  placeholderId: null,
  translationPath: null,
  fullWidth: false,
};

export default withLocalize(withStyles(styles, { withTheme: true })(MyDropdown));

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { FunctionalityName } from '../../../../../../lib/access/constants';
import useFunctionalityOn from '../../../../../../lib/access/useFunctionalityOn';

import NumberTextField from 'components/NumberTextField';
import PositionMe from 'components/PositionMe';
import SimpleText from 'components/SimpleText';
import { useTranslate } from 'lib/localization/localization';
import { useDraftValue } from 'lib/misc/useDraft/useDraft';
import {
  StrategyCard,
  StrategyCardContent,
  StrategyCardMainSection,
} from 'screens/Portfolio/lib/hooks/useStrategyConfiguration/cards/StrategyCard';

const translationPath = 'portfolioLib.strategyConfiguration.targetMetricCard' as const;

const useStyles = makeStyles({
  card: {
    maxWidth: '250px',
  },
  configurationOnlyRisk: {
    width: '100%',
    marginTop: '10px',
  },
  configuration: {
    width: '100%',
  },
});

const DEFAULT_CORRIDOR = 0.01;
const DEFAULT_TARGET = 0.1;
const MAX_RISK = 70;
const MAX_RETURN = 90;

interface TargetMetricCardProps {
  isTargetRisk: boolean;
  targetValue: number;
  targetCorridor: number;
  disabled?: boolean;
  onChangeTargetValue?: (value: number) => void;
  onChangeIsTargetRisk?: (newValue: boolean) => void;
  onChangeTargetCorridor?: (newValue: number) => void;
}

const TargetMetricCard = ({
  isTargetRisk,
  targetValue,
  targetCorridor,
  disabled = false,
  onChangeTargetValue = undefined,
  onChangeIsTargetRisk = undefined,
  onChangeTargetCorridor = undefined,
}: TargetMetricCardProps) => {
  const translate = useTranslate();
  const isOnlyRiskTarget = useFunctionalityOn(FunctionalityName.ONLY_RISK_TARGET) && isTargetRisk;
  const classes = useStyles();
  const handleChangeSwitch = () => {
    if (onChangeIsTargetRisk !== undefined) {
      onChangeIsTargetRisk(!isTargetRisk);
    }
  };

  return (
    <StrategyCard
      title={`${translationPath}.${isOnlyRiskTarget ? 'titleOnlyRisk' : 'title'}`}
      className={classes.card}
    >
      {!isOnlyRiskTarget ? (
        <StrategyCardMainSection>
          <RadioGroup value={isTargetRisk.toString()} onChange={handleChangeSwitch}>
            <FormControlLabel
              value='true'
              disabled={disabled}
              control={<Radio color='primary' />}
              label={translate(`${translationPath}.riskTab`)}
            />
            <FormControlLabel
              value='false'
              disabled={disabled}
              control={<Radio color='primary' />}
              label={translate(`${translationPath}.returnTab`)}
            />
          </RadioGroup>
        </StrategyCardMainSection>
      ) : (
        <></>
      )}
      <StrategyCardContent>
        <Grid
          container
          className={isOnlyRiskTarget ? classes.configurationOnlyRisk : classes.configuration}
          justifyContent='space-evenly'
        >
          <Grid item xs={5}>
            <PositionMe>
              <NumberTextField
                bold
                label={null}
                placeholder={isTargetRisk ? `${translationPath}.risk` : `${translationPath}.return`}
                typographyVariant='h2'
                onChange={
                  onChangeTargetValue
                    ? (newValue: number) => onChangeTargetValue(newValue / 100)
                    : undefined
                }
                value={targetValue * 100}
                disableUnderline
                textAlign='center'
                disabled={disabled}
                limit={isTargetRisk ? MAX_RISK : MAX_RETURN}
              />
            </PositionMe>
          </Grid>
          <Grid item xs={2}>
            <PositionMe>
              <SimpleText text='±' variant='h3' fontWeight='bold' />
            </PositionMe>
          </Grid>
          <Grid item xs={5}>
            <PositionMe>
              <NumberTextField
                bold
                label={null}
                placeholder={`${translationPath}.corridor`}
                typographyVariant='h2'
                onChange={
                  onChangeTargetCorridor
                    ? (newValue: number) => onChangeTargetCorridor(newValue / 100)
                    : undefined
                }
                value={targetCorridor * 100}
                disableUnderline
                textAlign='center'
                disabled={disabled}
                limit={targetValue * 100}
              />
            </PositionMe>
          </Grid>
        </Grid>
        <Grid container justifyContent='space-evenly'>
          <Grid item xs={5}>
            <SimpleText translateId={`${translationPath}.percent`} variant='body1' align='center' />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <SimpleText
              translateId={`${translationPath}.percPoint`}
              variant='body1'
              align='center'
            />
          </Grid>
        </Grid>
      </StrategyCardContent>
    </StrategyCard>
  );
};

interface UseTargetMetricCardProps {
  isTargetRisk?: boolean;
  targetValue?: number;
  targetCorridor?: number;
  disabled?: boolean;
}

const useTargetMetricCard = ({
  isTargetRisk = true,
  targetValue = DEFAULT_TARGET,
  targetCorridor = DEFAULT_CORRIDOR,
  disabled = false,
}: UseTargetMetricCardProps) => {
  const { draft: draftTarget, setDraft: setDraftTarget } = useDraftValue(targetValue);
  const { draft: draftIsTargetRisk, setDraft: setDraftIsTargetRisk } = useDraftValue(isTargetRisk);
  const { draft: draftTargetCorridor, setDraft: setDraftTargetCorridor } =
    useDraftValue(targetCorridor);

  const resetDraft = () => {
    setDraftTarget(undefined);
    setDraftIsTargetRisk(undefined);
    setDraftTargetCorridor(undefined);
  };
  const draft = {
    targetValue: draftTarget,
    isTargetRisk: draftIsTargetRisk,
    targetCorridor: draftTargetCorridor,
  };

  const cardElement = (
    <TargetMetricCard
      isTargetRisk={draftIsTargetRisk}
      targetValue={draftTarget}
      targetCorridor={draftTargetCorridor}
      disabled={disabled}
      onChangeIsTargetRisk={setDraftIsTargetRisk}
      onChangeTargetValue={setDraftTarget}
      onChangeTargetCorridor={setDraftTargetCorridor}
    />
  );

  return {
    cardElement,
    resetDraft,
    draft,
  };
};

export default useTargetMetricCard;

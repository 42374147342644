import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { PermissionLevelT } from 'lib/access/types';
import { replaceObjectInArray } from 'lib/misc/misc';
import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import { put } from 'lib/queries';
import { Id, ResourceNameT } from 'lib/types';
import { addError } from 'redux/notifications/actions';
import { userGroupsKeys } from 'screens/UserGroup/lib/queries';
import { UserGroup } from 'screens/UserGroup/lib/types';

type NewPermissionLevels = { [userGroupId: Id]: PermissionLevelT };

async function putAccess(
  resourceName: ResourceNameT,
  id: Id,
  newPermissionLevels: NewPermissionLevels,
  newOwnerId: Id | null
): Promise<UserGroup[]> {
  return put(`${resourceName}/${id}/access/`, {
    newPermissionLevels,
    newOwnerId: newOwnerId ?? null,
  });
}

interface ChangeAccessMutateProps {
  newPermissionLevels: NewPermissionLevels;
  newOwnerId: Id | null;
}

export const useChangeAccessMutation = (resourceName: ResourceNameT, id: Id) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    ({ newPermissionLevels, newOwnerId }: ChangeAccessMutateProps) =>
      putAccess(resourceName, id, newPermissionLevels, newOwnerId),
    {
      onSuccess: (data, variables) => {
        data.forEach(userGroup => {
          queryClient.setQueriesData(userGroupsKeys.lists(), prev =>
            replaceObjectInArray(prev, userGroup)
          );
        });
        if (variables.newOwnerId) {
          const queryKey = [{ scope: resourceName, entity: 'detail', id }];
          const currentObject = queryClient.getQueryData(queryKey);
          if (currentObject !== undefined) {
            queryClient.setQueryData<object>(queryKey, prev => {
              if (prev) {
                return {
                  ...prev,
                  user: variables.newOwnerId,
                };
              }
              return prev;
            });
          }
        }
      },
      onError: error => {
        dispatch(addError(makeErrorFromHttpResponse(error)));
      },
    }
  );
};

import React from 'react';

import LoginScreenBase from 'screens/Login/lib/components/LoginScreenBase';
import RegisterForm from 'screens/Login/RegisterScreen/components/RegisterForm';

const RegisterScreen = () => {
  return (
    <LoginScreenBase>
      <div>
        <RegisterForm />
      </div>
    </LoginScreenBase>
  );
};

export default RegisterScreen;

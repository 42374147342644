import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withLocalize } from 'react-localize-redux';

import { ClassesType, TypographyVariantType } from 'utils/types';

const styles = theme => ({
  root: {
    margin: 0,
  },
  ...theme.typography,
  whiteColor: {
    color: theme.palette.common.white,
  },
  blackColor: {
    color: theme.palette.text.primary,
  },
  inheritColor: {
    color: 'inherit',
  },
  greyColor: {
    color: theme.palette.secondary[400],
  },
  bold: {
    fontWeight: 'bold !important',
  },
  descriptionField: {
    borderStyle: 'solid',
  },
});

const BaseTextField = ({
  label,
  placeholder,
  typographyVariant,
  classes,
  value,
  onChange,
  color,
  disableUnderline,
  notControlled,
  InputProps,
  textAlign,
  bold,
  className,
  returnOnlyText,
  noPadding,
  fullWidth,
  extraStyles,
  multiline,
  rows,
  disabled,
  translate,
  ...other
}) => {
  const baseInputProps = {
    classes: {
      input: classNames(classes[typographyVariant], {
        [classes.whiteColor]: color === 'white',
        [classes.blackColor]: color === 'black',
        [classes.inheritColor]: color === 'inherit',
        [classes.greyColor]: color === 'grey',
        [classes.bold]: bold,
      }),
    },
    disableUnderline,
  };
  const inputStyles = { textAlign };
  if (noPadding) {
    inputStyles.padding = 0;
  }
  let finalPlaceholder = null;
  if (placeholder) {
    if (_.isNumber(placeholder)) {
      finalPlaceholder = placeholder;
    } else {
      finalPlaceholder = translate(placeholder);
    }
  }
  return (
    <TextField
      fullWidth={fullWidth}
      label={label ? translate(label) : ''}
      placeholder={finalPlaceholder}
      className={classNames(classes.root, className, classes[extraStyles])}
      InputProps={{ ...baseInputProps, ...InputProps }}
      /* eslint-disable-next-line react/jsx-no-duplicate-props */
      inputProps={{ style: inputStyles }}
      value={notControlled ? undefined : value ?? ''}
      onChange={returnOnlyText ? e => onChange(e.target.value) : onChange}
      margin='normal'
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      {...other}
    />
  );
};

BaseTextField.propTypes = {
  bold: PropTypes.bool,
  InputProps: PropTypes.objectOf(PropTypes.any),
  classes: ClassesType.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  typographyVariant: TypographyVariantType,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  color: PropTypes.string,
  disableUnderline: PropTypes.bool,
  notControlled: PropTypes.bool,
  textAlign: PropTypes.string,
  className: PropTypes.string,
  returnOnlyText: PropTypes.bool,
  noPadding: PropTypes.bool,
  fullWidth: PropTypes.bool,
  extraStyles: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
};

BaseTextField.defaultProps = {
  className: '',
  bold: false,
  textAlign: 'left',
  InputProps: {},
  label: null,
  placeholder: null,
  typographyVariant: 'body1',
  color: 'black',
  value: null,
  disableUnderline: false,
  notControlled: false,
  returnOnlyText: false,
  noPadding: false,
  fullWidth: true,
  extraStyles: '',
  multiline: false,
  rows: 1,
  disabled: false,
};

export default withLocalize(withStyles(styles, { withTheme: true })(BaseTextField));

import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import ChooseScreen from 'components/SearchTools/ChooseScreen';
import useEditPermission from 'lib/access/useEditPermission';
import { toDateString } from 'lib/dates/dateUtils';
import { Id } from 'lib/types';
import PortfoliosDropdown from 'screens/Backtest/lib/components/PortfoliosDropdown';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { useDeleteReportMutation, useReportsQuery } from 'screens/Report/lib/queries';
import { Report } from 'screens/Report/lib/types';
import { Routes } from 'utils/constants';

const translationPath = 'chooseReportsScreen';

const columns = ['id', 'name', 'createdOn', 'updatedOn'];

const ChooseReportsScreen = () => {
  const history = useHistory();
  const { id, portfolioId } = useParams<{ id: Id | undefined; portfolioId: Id | undefined }>();
  const { reports } = useReportsQuery(portfolioId);
  const { mutate: deleteReport } = useDeleteReportMutation();
  const { portfolio, isLoading } = usePortfolioQuery(portfolioId);
  const hasCreatePermission = useEditPermission(portfolio);

  const handleChangePortfolioId = (newPortfolioId: Id) => {
    history.push(
      generatePath(Routes.CHOOSE_REPORT_WITH_PORTFOLIO, {
        portfolioId: newPortfolioId,
        id,
      })
    );
  };

  const cellFormatting = {
    createdOn: toDateString,
    updatedOn: toDateString,
  };

  return (
    <ChooseScreen
      dialogTranslatePath='reportScreen.useSavePromptAndDialog'
      returnButtonProps={
        portfolioId
          ? {
              translateId: `${translationPath}.returnButtonToPortfolio`,
              route: generatePath(Routes.PORTFOLIO, { id: portfolioId }),
            }
          : undefined
      }
      topScreen={
        <PortfoliosDropdown onChange={handleChangePortfolioId} portfolioId={portfolioId} />
      }
      waitWhile={isLoading}
      key={translationPath}
      tableObjects={reports}
      // @ts-ignore
      columns={columns}
      searchColumns={columns}
      cellFormatting={cellFormatting}
      searchValue={null}
      translationPath={translationPath}
      isTableWhite
      onNewClick={
        hasCreatePermission
          ? () => history.push(generatePath(Routes.NEW_REPORT, { portfolioId, id }))
          : undefined
      }
      onRowClick={(clickedId: Id) =>
        history.push(generatePath(Routes.REPORT, { id: clickedId, portfolioId }))
      }
      onDeleteRowClick={(obj: Report) => {
        deleteReport(obj.id);
      }}
      editPermissionOverride={hasCreatePermission}
    />
  );
};

export default ChooseReportsScreen;

import DoneIcon from '@mui/icons-material/Done';
import { Chip, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import SimpleButton from 'components/SimpleButton';
import { FunctionalityName } from 'lib/access/constants';
import useFunctionalityOn from 'lib/access/useFunctionalityOn';
import { useTranslate } from 'lib/localization/localization';
import useToggleList from 'lib/misc/useToggleList';
import useAssetAllocationCards from 'screens/Portfolio/lib/hooks/useStrategyConfiguration/cards/useAssetAllocationCards';
import useBasketCard from 'screens/Portfolio/lib/hooks/useStrategyConfiguration/cards/useBasketCard';
import useMaximumWeightCard from 'screens/Portfolio/lib/hooks/useStrategyConfiguration/cards/useMaximumWeightCard';
import useResearchCards from 'screens/Portfolio/lib/hooks/useStrategyConfiguration/cards/useResearchCards';
import useTargetMetricCard from 'screens/Portfolio/lib/hooks/useStrategyConfiguration/cards/useTargetMetricCard';
import { PortfolioStrategy } from 'screens/Portfolio/lib/types';

const translationPath = 'portfolioLib.strategyConfiguration' as const;

const ConfigurationChip = {
  TARGET_METRIC: 'TARGET_METRIC',
  VOLUME: 'VOLUME',
  BASKET: 'BASKET',
  INVESTMENT_POLICIES: 'INVESTMENT_POLICIES',
  RESEARCH: 'RESEARCH',
  SIGNALING: 'SIGNALING',
} as const;

const CARD_TYPES = [
  ConfigurationChip.TARGET_METRIC,
  ConfigurationChip.VOLUME,
  ConfigurationChip.BASKET,
  ConfigurationChip.INVESTMENT_POLICIES,
  ConfigurationChip.RESEARCH,
] as const;

const useStyles = makeStyles(theme => ({
  toolbar: {
    marginBottom: theme.spacing(4),
  },
  button: {
    float: 'right',
  },
}));

const StrategyConfigurationCard = ({ cardElem }: { cardElem: JSX.Element | JSX.Element[] }) => {
  if (!Array.isArray(cardElem)) {
    return <Grid item>{cardElem} </Grid>;
  }

  return (
    <>
      {cardElem.map((el: JSX.Element) => (
        <Grid item>{el}</Grid>
      ))}
    </>
  );
};

interface StrategyConfigurationProps {
  targetMetricCardElem: JSX.Element;
  volumeCardElem: JSX.Element;
  basketCardElem: JSX.Element;
  allocCardElements: JSX.Element[];
  researchCardElements: JSX.Element[];
  onReconfigureClick?: () => void;
}

const StrategyConfiguration = ({
  targetMetricCardElem,
  volumeCardElem,
  basketCardElem,
  allocCardElements,
  researchCardElements,
  onReconfigureClick = undefined,
}: StrategyConfigurationProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { isToggled, toggle } = useToggleList([...CARD_TYPES]);

  const isResearchFunctionOn = useFunctionalityOn(FunctionalityName.RESEARCH);
  const filteredCardTypes = CARD_TYPES.filter(cardId => {
    return !(cardId === ConfigurationChip.RESEARCH && !isResearchFunctionOn);
  });

  const chipToElem = {
    [ConfigurationChip.TARGET_METRIC]: targetMetricCardElem,
    [ConfigurationChip.VOLUME]: volumeCardElem,
    [ConfigurationChip.BASKET]: basketCardElem,
    [ConfigurationChip.INVESTMENT_POLICIES]: allocCardElements,
    [ConfigurationChip.RESEARCH]: researchCardElements,
  };

  return (
    <div>
      <div className={classes.toolbar}>
        <Grid container spacing={4} alignItems='center'>
          <Grid item xs={onReconfigureClick ? 9 : 12}>
            <Stack direction='row' spacing={2}>
              {filteredCardTypes.map(chipType => (
                <Chip
                  variant='outlined'
                  key={chipType}
                  label={translate(`${translationPath}.configurationChip.${chipType}`)}
                  onClick={() => toggle(chipType)}
                  color={isToggled(chipType) ? 'primary' : 'default'}
                  icon={isToggled(chipType) ? <DoneIcon /> : undefined}
                />
              ))}
            </Stack>
          </Grid>
          {onReconfigureClick && (
            <Grid item xs={3}>
              <div className={classes.button}>
                <SimpleButton
                  translateId={`${translationPath}.reconfigureButton`}
                  onClick={onReconfigureClick}
                  variant='highEmp'
                />
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      <Grid container spacing={4}>
        {filteredCardTypes.map(
          chipType =>
            isToggled(chipType) && (
              <StrategyConfigurationCard cardElem={chipToElem[chipType]} key={chipType} />
            )
        )}
      </Grid>
    </div>
  );
};

interface UseStrategyConfigurationProps {
  strategy: PortfolioStrategy | undefined;
  disabled?: boolean;
  onReconfigureClick?: () => void;
  isNew?: boolean;
}

const useStrategyConfiguration = ({
  strategy,
  disabled = true,
  onReconfigureClick = undefined,
  isNew = false,
}: UseStrategyConfigurationProps) => {
  const {
    cardElement: targetMetricCardElem,
    draft: draftTargetMetrics,
    resetDraft: resetDraftTargetMetrics,
  } = useTargetMetricCard({
    isTargetRisk: strategy?.isTargetRisk,
    targetValue: strategy?.targetValue,
    targetCorridor: strategy?.targetCorridor,
    disabled,
  });
  const {
    cardElement: volumeCardElem,
    draftMaxConstraint,
    draftMinConstraint,
    resetDraft: resetDraftMaxMinConstraint,
  } = useMaximumWeightCard({
    maxConstraint: strategy?.maxConstraint,
    minConstraint: strategy?.minConstraint,
    disabled,
  });
  const {
    cardElement: basketCardElem,
    draft: draftBasketUniverse,
    resetDraft: resetDraftBasketUniverse,
    isLoading: isLoadingBasketUniverse,
  } = useBasketCard({
    universeId: strategy?.universeId,
    basketId: strategy?.basketId,
    isNew,
    disabled,
  });
  const {
    cardElements: allocCardElements,
    draft: assetAllocDraft,
    resetDraft: resetAssetAllocDraft,
    isLoading: isLoadingAssetAlloc,
  } = useAssetAllocationCards({
    groupConstraintListIds: strategy?.groupConstraintListsIds,
    disabled,
    isNew,
  });
  const {
    cardElements: researchCardElements,
    draft: researchDraft,
    resetDraft: resetResearchDraft,
    isLoading: isLoadingResearch,
  } = useResearchCards({
    researchConstraintListIds: strategy?.researchConstraintListsIds,
    disabled,
    isNew,
  });

  const resetDraft = () => {
    resetDraftTargetMetrics();
    resetDraftMaxMinConstraint();
    resetDraftBasketUniverse();
    resetAssetAllocDraft();
    resetResearchDraft();
  };
  const draft = {
    ...draftTargetMetrics,
    ...draftBasketUniverse,
    maxConstraint: draftMaxConstraint,
    minConstraint: draftMinConstraint,
    groupConstraintLists: assetAllocDraft,
    researchConstraintLists: researchDraft,
  };

  const element = (
    <StrategyConfiguration
      targetMetricCardElem={targetMetricCardElem}
      volumeCardElem={volumeCardElem}
      basketCardElem={basketCardElem}
      allocCardElements={allocCardElements}
      researchCardElements={researchCardElements}
      onReconfigureClick={onReconfigureClick}
    />
  );
  return {
    strategyConfigElement: element,
    draft,
    resetDraft,
    isLoading: isLoadingBasketUniverse || isLoadingAssetAlloc || isLoadingResearch,
  };
};

export default useStrategyConfiguration;

import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import chroma from 'chroma-js';
import _ from 'lodash';
import React, { useMemo } from 'react';

import MyBarChart from 'components/MyBarChart/BarChartWrapper';
import MyProgress from 'components/MyProgress';
import SimpleText from 'components/SimpleText';
import { ValueOf } from 'lib/types';
import { ALLOCATION_CHIP_TO_ASSET_FIELD, AllocationChip } from 'lib/weights/constants';
import { useAssetsQuery } from 'screens/Environment/lib/queries';
import { AssetOverviews } from 'screens/Environment/lib/types';
import { Weights } from 'screens/Portfolio/lib/types';

const translationPath = 'lib.weights.portfolioWeightsBarChart' as const;

function getCategoryWeights(
  assets: AssetOverviews,
  portfolioWeights: Weights,
  category: ValueOf<typeof AllocationChip>
) {
  const portfolioData: { [key in string]: number } = {};
  const fieldName = ALLOCATION_CHIP_TO_ASSET_FIELD[category];
  portfolioWeights.forEach(weight => {
    const asset = assets[weight.assetId];
    if (asset) {
      if (asset[fieldName] in portfolioData) {
        portfolioData[asset[fieldName]] += weight.weight * 100;
      } else {
        portfolioData[asset[fieldName]] = weight.weight * 100;
      }
    }
  });
  return portfolioData;
}

function getXAndYs(
  allKeys: string[],
  currentPortfolioData: { [key in string]: number },
  comparePortfolioData: { [key in string]: number },
  zero: string,
  name: string,
  amount: string,
  brightColor: string,
  darkColor: string
) {
  const chartData: any[] = [];
  const colorSet = chroma
    // @ts-ignore
    .scale(chroma.bezier([brightColor, darkColor]).scale())
    .correctLightness()
    .colors(allKeys.length);
  let currentIndex = 0;
  allKeys.forEach(member => {
    const currentData = currentPortfolioData[member];
    const compareData = comparePortfolioData[member];
    if (currentData && compareData) {
      chartData.push({
        [zero]: 0,
        [name]: member,
        [amount]: compareData - currentData,
        color: colorSet[currentIndex],
      });
      currentIndex += 1;
    } else if (compareData) {
      chartData.push({
        [zero]: 0,
        [name]: member,
        [amount]: compareData,
        color: colorSet[currentIndex],
      });
      currentIndex += 1;
    } else if (currentData) {
      chartData.push({
        [zero]: 0,
        [name]: member,
        [amount]: -1 * currentData,
        color: colorSet[currentIndex],
      });
      currentIndex += 1;
    }
  });
  return chartData;
}
export function getChartData(
  assets: AssetOverviews,
  currentPortfolioWeights: Weights,
  comparePortfolioWeights: Weights,
  category: ValueOf<typeof AllocationChip>,
  graphBrightColor: string,
  graphDarkColor: string
) {
  if (!assets || !currentPortfolioWeights || !comparePortfolioWeights || !category) {
    return [];
  }
  const currentPortfolioData = getCategoryWeights(assets, currentPortfolioWeights, category);
  const comparePortfolioData = getCategoryWeights(assets, comparePortfolioWeights, category);
  const merged = { ...comparePortfolioData, ...currentPortfolioData };
  const allKeys = _.keys(merged);
  let z;
  let n;
  let a;
  if (allKeys.length < 20) {
    z = 'x0';
    n = 'y';
    a = 'x';
  } else {
    z = 'y0';
    n = 'x';
    a = 'y';
  }
  return getXAndYs(
    allKeys,
    currentPortfolioData,
    comparePortfolioData,
    z,
    n,
    a,
    graphBrightColor,
    graphDarkColor
  );
}

const useStyles = makeStyles({
  chart: {
    height: '100%',
    width: '100%',
  },
});

interface WeightsBarChartProps {
  weights: Weights | undefined;
  weightsCompare: Weights | undefined;
  toggledChip: ValueOf<typeof AllocationChip>;
}

const WeightsBarChart = ({ weights, weightsCompare, toggledChip }: WeightsBarChartProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const { assets, isLoading: isLoadingAssets } = useAssetsQuery();
  const isEnvironmentLoading = isLoadingAssets;
  const barChartData = useMemo(() => {
    if (weights === undefined || weightsCompare === undefined) {
      return [];
    }
    return getChartData(
      assets,
      weights,
      weightsCompare,
      toggledChip,
      theme.palette.graphs.bright,
      theme.palette.graphs.dark
    );
  }, [
    assets,
    theme.palette.graphs.bright,
    theme.palette.graphs.dark,
    toggledChip,
    weights,
    weightsCompare,
  ]);
  let mainContent;
  if (isEnvironmentLoading) {
    mainContent = <MyProgress translateId={`${translationPath}.waitEnvironment`} />;
  } else if (weights === undefined || weightsCompare === undefined) {
    mainContent = (
      <SimpleText
        color='error.main'
        variant='h3'
        translateId={`${translationPath}.weightsNotAvailable`}
      />
    );
  } else {
    mainContent = (
      <div className={classes.chart}>
        <MyBarChart data={barChartData} additionalWidth={-50} padding={0} />
      </div>
    );
  }
  return mainContent;
};

export default WeightsBarChart;

import { Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty, first } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import BarChartWrapper from 'screens/Analysis/lib/components/BarChartWrapper';
import TestSpinner from 'screens/Analysis/lib/components/TestSpinner';
import useDateToggle from 'screens/Analysis/lib/components/useDateToggle';
import WidgetFooter from 'screens/Analysis/lib/components/WidgetFooter';
import WidgetTitleSelector from 'screens/Analysis/lib/components/WidgetTitleSelector';
import { SECOND_LEVEL_HEIGHT } from 'screens/Analysis/lib/constants';
import { useAnalysisQuery } from 'screens/Analysis/lib/queries';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '0.7rem',
    height: SECOND_LEVEL_HEIGHT,

    position: 'relative',
  },
});

const RiskPerformanceBreakdown = ({ analysisId }) => {
  const classes = useStyles();

  const { analysis } = useAnalysisQuery(analysisId);
  const widgetData = analysis?.result?.RiskBreakdown ?? {};

  const [title, setTitle] = useState('month');

  const [dateFilter, DateFilterComponent] = useDateToggle(
    !isEmpty(widgetData) ? first(widgetData[title].filters) : 'all'
  );

  return (
    <Grid item md={12} lg={7} xl={8}>
      <Paper className={classes.wrapper}>
        <Grid container style={{ height: '100%' }}>
          {isEmpty(widgetData) ? (
            <TestSpinner />
          ) : (
            <>
              <WidgetTitleSelector
                mainTitle='std'
                title={title}
                setTitle={setTitle}
                options={['month', 'Country', 'Sector']}
              />
              <DateFilterComponent
                available={widgetData[title].filters}
                errors={widgetData[title].errors}
                hints={widgetData[title].hints}
              />
              <div style={{ height: '82%', width: '100%', position: 'relative' }}>
                <BarChartWrapper
                  data={Object.values(widgetData[title].data[dateFilter]).map(
                    ({ label, portfolio, benchmark }) => ({
                      date: label,
                      Portfolio: (portfolio * 100).toFixed(1),
                      Benchmark: (benchmark * 100).toFixed(1),
                    })
                  )}
                />
              </div>
              <WidgetFooter>
                <span style={{ color: '#abe6e9' }}>Portfolio</span>
                <span style={{ color: '#d9dada' }}>Benchmark</span>
              </WidgetFooter>
            </>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

RiskPerformanceBreakdown.propTypes = {
  analysisId: PropTypes.string,
};

RiskPerformanceBreakdown.defaultProps = {
  analysisId: undefined,
};

export default RiskPerformanceBreakdown;

import { Card, CardActions, CardContent, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React from 'react';

import MyDropdown from 'components/MyDropdown/MyDropdown';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import { toDateString } from 'lib/dates/dateUtils';
import useStartEndDatePickers, {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE,
} from 'lib/dates/useStartEndDatePickers';
import { findDiffOriginalAndDraft } from 'lib/misc/diff';
import { useDraftValue } from 'lib/misc/useDraft/useDraft';
import { Id } from 'lib/types';
import { AnalysisDataType } from 'screens/Analysis/lib/constants';
import { useAnalysisQuery } from 'screens/Analysis/lib/queries';
import { AnalysisConfiguration } from 'screens/Analysis/lib/types';
import { useBenchmarksQuery } from 'screens/Environment/lib/queries';
import { useDataTypeDropdowns } from 'screens/Portfolio/lib/hooks/useDataType';
import { useDefineDateLimits } from 'screens/Portfolio/lib/hooks/useDefineDateLimits';

const translationPath = 'analysisScreen.analysisConfigCard';

const useStyles = makeStyles(theme => ({
  section: {
    paddingTop: theme.spacing(8),
  },
  datePickerSection: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

interface BenchmarkDropdownProps {
  benchmarkId: Id | undefined;
  onBenchmarkChange?: (benchmarkId: Id) => void;
  disabled?: boolean;
}

const BenchmarkDropdown = ({
  benchmarkId,
  onBenchmarkChange,
  disabled = false,
}: BenchmarkDropdownProps) => {
  const { benchmarks, isLoading: isLoadingBenchmarks } = useBenchmarksQuery();
  return (
    <MyDropdown
      // @ts-ignore
      disabled={disabled || isLoadingBenchmarks}
      value={benchmarkId}
      onChange={onBenchmarkChange}
      onUpdateSearch={null}
      translationPath={`${translationPath}.benchmarksDropdown`}
      isNullAllowed={false}
    >
      {_.values(benchmarks).map(benchmark => (
        <MenuItem key={benchmark.id} value={benchmark.id}>
          {benchmark.typ}
        </MenuItem>
      ))}
    </MyDropdown>
  );
};

interface AnalysisConfigCardProps {
  analysisId: Id | undefined;
  portfolioId: Id | undefined;
  disabled?: boolean;
  onClick?: (configDraft: AnalysisConfiguration) => void;
}

const AnalysisConfigCard = ({
  analysisId,
  disabled,
  onClick,
  portfolioId,
}: AnalysisConfigCardProps) => {
  const classes = useStyles();
  const isNew = analysisId === undefined;
  const { analysis, isLoading: isLoadingAnalysis } = useAnalysisQuery(analysisId);

  const { draft: draftBenchmarkId, setDraft: setDraftBenchmarkId } = useDraftValue(
    analysis?.benchmarkId ?? undefined
  );
  const { draft: draftDataType, setDraft: setDraftDataType } = useDraftValue(
    analysis?.dataType ?? AnalysisDataType.HISTORICAL
  );
  const { draft: draftBacktestId, setDraft: setDraftBacktestId } = useDraftValue(
    analysis?.backtestId ?? undefined
  );
  const { limitStartDate, limitEndDate } = useDefineDateLimits({
    backtestId: draftBacktestId,
    dataType: draftDataType,
    portfolioId,
  });
  const { draft: draftStartDate, setDraft: setDraftStartDate } = useDraftValue(
    analysis?.startDate ?? limitStartDate ?? toDateString(DEFAULT_START_DATE)
  );
  const { draft: draftEndDate, setDraft: setDraftEndDate } = useDraftValue(
    analysis?.endDate ?? limitEndDate ?? toDateString(DEFAULT_END_DATE)
  );
  const { startDatePickerElem, endDatePickerElem, quickSetElem } = useStartEndDatePickers({
    startDate: draftStartDate,
    endDate: draftEndDate,
    onEndDateChange: setDraftEndDate,
    onStartDateChange: setDraftStartDate,
    disabled: disabled || isLoadingAnalysis,
    limitEndDate,
    limitStartDate,
  });
  const { dataTypeDropdownElem, backtestDropdownElem } = useDataTypeDropdowns({
    dataType: draftDataType,
    backtestId: draftBacktestId,
    disabled: disabled || isLoadingAnalysis,
    onDataTypeChange: setDraftDataType,
    onBacktestChange: setDraftBacktestId,
    portfolioId,
  });

  const draft = {
    startDate: draftStartDate,
    endDate: draftEndDate,
    benchmarkId: draftBenchmarkId,
    dataType: draftDataType,
    backtestId: draftBacktestId,
  };
  const original = {
    startDate: analysis?.startDate,
    endDate: analysis?.endDate,
    benchmarkId: analysis?.benchmarkId,
    dataType: analysis?.dataType,
    backtestId: analysis?.backtestId,
  };
  const { isEqual } = findDiffOriginalAndDraft(original, draft);
  const saveButtonTranslateId = `${translationPath}.${isEqual ? 'rerunButton' : 'saveButton'}`;

  const handleClick = () => {
    if (onClick) {
      onClick(draft as AnalysisConfiguration);
    }
  };
  const disabledRun =
    draftBenchmarkId === undefined ||
    (draftDataType === AnalysisDataType.BACKTEST && draftBacktestId === undefined);

  return (
    <Card>
      <CardContent>
        <SimpleText translateId={`${translationPath}.title`} variant='h2' />
        <div className={classes.section}>
          <BenchmarkDropdown
            benchmarkId={draftBenchmarkId}
            onBenchmarkChange={setDraftBenchmarkId}
            disabled={disabled || isLoadingAnalysis}
          />
        </div>
        <div className={classes.section}>
          <Grid container spacing={4}>
            <Grid item xs={backtestDropdownElem !== undefined ? 6 : 12}>
              {dataTypeDropdownElem}
            </Grid>
            {backtestDropdownElem !== undefined && (
              <Grid item xs={6}>
                {backtestDropdownElem}
              </Grid>
            )}
          </Grid>
        </div>
        <div className={classes.section}>
          <div className={classes.datePickerSection}>
            <SimpleText translateId={`${translationPath}.startDate`} variant='subtitle1' />
            {startDatePickerElem}
          </div>
          <div className={classes.datePickerSection}>
            <SimpleText translateId={`${translationPath}.endDate`} variant='subtitle1' />
            {endDatePickerElem}
          </div>
          <div className={classes.datePickerSection}>
            <SimpleText translateId={`${translationPath}.quickSet`} variant='subtitle1' />
            {quickSetElem}
          </div>
        </div>
      </CardContent>
      <CardActions>
        <SimpleButton
          translateId={isNew ? `${translationPath}.createButton` : saveButtonTranslateId}
          onClick={handleClick}
          variant='highEmp'
          disabled={disabled || isLoadingAnalysis || disabledRun}
          pendo-id='analysis-analysis-config-save-run-button'
        />
      </CardActions>
    </Card>
  );
};

export default AnalysisConfigCard;

import { Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    right: '0',
    top: '0',
    marginTop: '0.7rem',
    marginRight: '0.7rem',
    padding: '0',
    zIndex: 10,
    display: 'flex',
  },
  item: {
    cursor: 'pointer',
    color: '#a9aaaa',
    marginRight: '0.4rem',
    fontWeight: '600',
  },
  itemSelected: {
    color: theme.palette.primary.main,
  },
  itemDisabled: {
    color: '#d2d2d2',
  },
}));

const options = ['all', 'y', '1m', '3m', '6m'];

const useDateToggle = defaultValue => {
  const classes = useStyles();

  const [date, setDate] = useState(defaultValue);

  useEffect(() => {
    setDate(defaultValue);
  }, [defaultValue]);

  const Component = ({ available, hints, errors, customOptions }) => {
    const list = isEmpty(customOptions) ? options : customOptions;

    return (
      <div className={classes.wrapper}>
        {list.map(option => {
          const isDisabled = !available.includes(option);
          const content = (
            <Typography
              variant='body1'
              className={`${classes.item} ${option === date ? classes.itemSelected : null} ${
                isDisabled ? classes.itemDisabled : null
              }`}
              key={option}
              onClick={!isDisabled ? () => setDate(option) : null}
            >
              {option.toUpperCase()}
            </Typography>
          );
          let tooltipContent = hints[option];
          if (isDisabled) {
            tooltipContent = errors[option];
          }

          return (
            <>
              {!isEmpty(tooltipContent) ? (
                <Tooltip
                  title={
                    <Translate>
                      {({ translate }) => {
                        if (isDisabled) {
                          return translate(`analysis.${tooltipContent.type}`, tooltipContent.data);
                        }

                        return tooltipContent.map(item => (
                          <>
                            {translate(`analysis.${item.type}`, item.data)}
                            <br />
                          </>
                        ));
                      }}
                    </Translate>
                  }
                >
                  {content}
                </Tooltip>
              ) : (
                content
              )}
            </>
          );
        })}
      </div>
    );
  };

  Component.propTypes = {
    available: PropTypes.arrayOf(PropTypes.string),
    hints: PropTypes.objectOf(PropTypes.array),
    errors: PropTypes.objectOf(PropTypes.object),
    customOptions: PropTypes.arrayOf(PropTypes.string),
  };

  Component.defaultProps = {
    available: options,
    hints: [],
    errors: [],
    customOptions: null,
  };

  return [date, Component, setDate];
};

export default useDateToggle;

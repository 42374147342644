import DoneIcon from '@mui/icons-material/Done';
import { Chip, Stack } from '@mui/material';
import React from 'react';

import { useTranslate } from 'lib/localization/localization';
import { ValueOf } from 'lib/types';
import { AllocationChip } from 'lib/weights/constants';

const translationPath = 'lib.weights.useAssetAllocation' as const;

interface AllocationChipsProps {
  chipValues: ReadonlyArray<ValueOf<typeof AllocationChip>>;
  chipValue: ValueOf<typeof AllocationChip>;
  setChipValue: (chip: ValueOf<typeof AllocationChip>) => void;
}

const ToolbarChips = ({ chipValues, setChipValue, chipValue }: AllocationChipsProps) => {
  const translate = useTranslate();
  return (
    <Stack direction='row' spacing={2}>
      {chipValues.map(chip => (
        <Chip
          variant='outlined'
          key={chip}
          label={translate(`${translationPath}.chip.${chip}`)}
          onClick={() => setChipValue(chip)}
          color={chip === chipValue ? 'primary' : 'default'}
          icon={chip === chipValue ? <DoneIcon /> : undefined}
        />
      ))}
    </Stack>
  );
};

interface UseAllocationChipsProps {
  chipValues: ReadonlyArray<ValueOf<typeof AllocationChip>>;
}

const useSingleAllocationChips = ({ chipValues }: UseAllocationChipsProps) => {
  const [chipValue, setChipValue] = React.useState<ValueOf<typeof AllocationChip>>(
    chipValues[0] || AllocationChip.ASSETS
  );

  return {
    chosenValue: chipValue,
    toolbarChipsElem: (
      <ToolbarChips chipValues={chipValues} setChipValue={setChipValue} chipValue={chipValue} />
    ),
  };
};

export default useSingleAllocationChips;

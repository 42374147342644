import React from 'react';

import { toDateTimeString } from 'lib/dates/dateUtils';
import { Id } from 'lib/types';
import PortfolioWarning from 'screens/Portfolio/lib/components/PortfolioWarning';
import { PortfolioAction, PortfolioAllocationType } from 'screens/Portfolio/lib/constants';
import { useOptimizationState } from 'screens/Portfolio/lib/hooks/portfolio';
import { usePortfolioActionMutation } from 'screens/Portfolio/lib/queries';
import { OptimPortfolioAllocationTypeT } from 'screens/Portfolio/lib/types';

const translationPath = 'portfolioLib.outdatedPortfolioWarning';

interface OutdatedPortfolioWarningProps {
  portfolioId: Id | undefined;
  allocationType: OptimPortfolioAllocationTypeT;
}

const OutdatedPortfolioWarning = ({
  portfolioId,
  allocationType,
}: OutdatedPortfolioWarningProps) => {
  const { optimizationState } = useOptimizationState(portfolioId, allocationType);
  const { mutate: applyAction } = usePortfolioActionMutation(portfolioId);
  const translateDataDetails = {
    updatedOn: toDateTimeString(optimizationState?.optimizationResult?.updatedOn),
    outdatedOn: toDateTimeString(optimizationState?.optimizationResult?.outdatedOn),
  };
  const allocTypeToAction = {
    [PortfolioAllocationType.OPTIMIZED]: PortfolioAction.REOPTIMIZE,
    [PortfolioAllocationType.OPTIMIZED_NEW]: PortfolioAction.REOPTIMIZE_NEW,
  };
  return (
    <PortfolioWarning
      translationPath={translationPath}
      translateDataDetails={translateDataDetails}
      onClick={() => {
        applyAction(allocTypeToAction[allocationType]);
      }}
    />
  );
};

export default OutdatedPortfolioWarning;

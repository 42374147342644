import { Box, Collapse, Theme, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useEffect } from 'react';

import Footer from 'components/Footer';
import NotificationSnacksArea from 'components/ScreenBase/NotificationSnacksArea/NotificationSnacksArea';
import {
  useErrorNotification,
  useSuccessNotification,
} from 'lib/notifications/useAlertNotification';
import { useLightDarkMode } from 'lib/theming/lightDarkMode';
import loginImage from 'static/images/loginImage.jpg';
import logoFontDark from 'static/images/logoFontDark.png';
import logoFontWhite from 'static/images/logoFontWhite.png';

const useStyles = makeStyles(theme => ({
  fullFrame: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.white,
    height: '100vh',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  fullFrameWithoutImage: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.white,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoFontCard: {
    width: '15rem',
  },
  loginArea: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  imageArea: {
    width: '40%',
    height: '100%',
    overflow: 'hidden',
  },
  loginBox: {
    width: '35rem',
    maxWidth: '90vw',
  },
  errorBar: {
    paddingTop: theme.spacing(6),
  },
  successBar: {
    paddingTop: theme.spacing(3),
  },
}));

const LoginScreenBase = ({ children }: JSX.ElementChildrenAttribute) => {
  const classes = useStyles();
  const { isLightMode } = useLightDarkMode();
  const { isError, errorBarElem } = useErrorNotification();
  const { isSuccess, successBarElem } = useSuccessNotification();
  const [showImage, setShowImage] = React.useState(true);
  const pictureHidden =
    useMediaQuery((theme: Theme) => theme.breakpoints.down('lg')) || !isLightMode;
  const resize = useCallback(() => {
    setShowImage(window.innerWidth >= 1100 && window.innerHeight >= 650);
  }, []);

  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
  });
  const loginArea = (
    <div className={classes.loginArea}>
      <Box
        component='img'
        className={classes.logoFontCard}
        src={isLightMode ? logoFontDark : logoFontWhite}
      />
      <div className={classes.loginBox}>
        <Collapse in={isError}>
          <div className={classes.errorBar}>{errorBarElem}</div>
        </Collapse>
        <Collapse className={classes.successBar} in={isSuccess}>
          <div className={classes.successBar}>{successBarElem}</div>
        </Collapse>
        {children}
      </div>
    </div>
  );

  return (
    <div>
      <NotificationSnacksArea />
      {pictureHidden ? (
        <div className={classes.fullFrameWithoutImage}>{loginArea}</div>
      ) : (
        <div className={classes.fullFrame}>
          {loginArea}
          {showImage ? (
            <Box component='img' className={classes.imageArea} src={loginImage} />
          ) : null}
        </div>
      )}

      <Footer />
    </div>
  );
};

export default LoginScreenBase;

// <img src={loginImage} />

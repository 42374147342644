export function getMinMaxPlusFiveX(myArray) {
  let lowest = Number.POSITIVE_INFINITY;
  let highest = Number.NEGATIVE_INFINITY;
  let tmp;
  for (let i = myArray.length - 1; i >= 0; i -= 1) {
    tmp = myArray[i].x;
    if (tmp < lowest) lowest = tmp;
    if (tmp > highest) highest = tmp;
  }
  return [lowest - 5, highest + 5];
}

export function getMinMaxPlusFiveY(myArray) {
  let lowest = Number.POSITIVE_INFINITY;
  let highest = Number.NEGATIVE_INFINITY;
  let tmp;
  for (let i = myArray.length - 1; i >= 0; i -= 1) {
    tmp = myArray[i].y;
    if (tmp < lowest) lowest = tmp;
    if (tmp > highest) highest = tmp;
  }
  return [lowest - 5, highest + 5];
}

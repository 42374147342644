import { CircularProgress } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';

import MyDropdown from 'components/MyDropdown';
import { useTranslate } from 'lib/localization/localization';
import { Id } from 'lib/types';
import { PortfolioAllocationType } from 'screens/Portfolio/lib/constants';
import { hasOptimNewAllocation, isPortfolioTracked } from 'screens/Portfolio/lib/helpers';
import { usePortfolioQuery, usePortfoliosQuery } from 'screens/Portfolio/lib/queries';
import {
  Portfolio,
  PortfolioAllocationTypeT,
  PortfolioOverviews,
} from 'screens/Portfolio/lib/types';

const translationPath = 'portfolioLib.useAllocationDropdowns' as const;

const useStyles = makeStyles(theme => ({
  portfolioDropdown: {
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    paddingLeft: theme.spacing(2),
  },
}));

interface AllocationTypeDropdownProps {
  selectedAllocType: PortfolioAllocationTypeT | undefined;
  setSelectedAllocType: (allocType: PortfolioAllocationTypeT) => void;
  allocTypes: PortfolioAllocationTypeT[];
}

const AllocationTypeDropdown = ({
  selectedAllocType,
  setSelectedAllocType,
  allocTypes,
}: AllocationTypeDropdownProps) => {
  const translate = useTranslate();
  return (
    <div>
      <MyDropdown
        // @ts-ignore
        value={selectedAllocType}
        onChange={setSelectedAllocType}
        // onUpdateSearch={setSelectedAllocType}
        translationPath={`${translationPath}.allocationDropdown`}
        disabled={allocTypes.length <= 1}
        isNullAllowed={false}
      >
        {allocTypes.map(type => (
          <MenuItem key={type} value={type}>
            {translate(`${translationPath}.allocationDropdown.${type}`)}
          </MenuItem>
        ))}
      </MyDropdown>
    </div>
  );
};

interface PortfolioDropdownProps {
  isLoading: boolean;
  selectedPortfolioId: Id | undefined;
  setSelectedPortfolioId: (id: Id) => void;
  portfolios: PortfolioOverviews;
}

const PortfolioDropdown = ({
  isLoading,
  selectedPortfolioId,
  setSelectedPortfolioId,
  portfolios,
}: PortfolioDropdownProps) => {
  const classes = useStyles();

  return (
    <div className={classes.portfolioDropdown}>
      <MyDropdown
        // @ts-ignore
        value={selectedPortfolioId}
        onChange={setSelectedPortfolioId}
        // onUpdateSearch={setSelectedAllocType}
        translationPath={`${translationPath}.portfolioDropdown`}
        isNullAllowed={false}
      >
        {Object.values(portfolios).map(p => (
          <MenuItem key={p.id} value={p.id}>
            {p.name}
          </MenuItem>
        ))}
      </MyDropdown>
      {isLoading && (
        <div className={classes.progress}>
          <CircularProgress size={24} />
        </div>
      )}
    </div>
  );
};

const defineAllowedTypes = (
  portfolio: Portfolio | undefined,
  allocationType: PortfolioAllocationTypeT | PortfolioAllocationTypeT[] | undefined
): PortfolioAllocationTypeT[] => {
  if (portfolio === undefined) {
    return [];
  }
  let allowedAllocTypes: PortfolioAllocationTypeT[] = Object.values(PortfolioAllocationType);
  if (typeof allocationType === 'string') {
    allowedAllocTypes = [allocationType];
  } else if (Array.isArray(allocationType)) {
    allowedAllocTypes = allocationType;
  }
  if (!isPortfolioTracked(portfolio)) {
    allowedAllocTypes = allowedAllocTypes.filter(
      type => type !== PortfolioAllocationType.TARGET && type !== PortfolioAllocationType.CURRENT
    );
  }
  if (!hasOptimNewAllocation(portfolio)) {
    allowedAllocTypes = allowedAllocTypes.filter(
      type => type !== PortfolioAllocationType.OPTIMIZED_NEW
    );
  }
  return allowedAllocTypes;
};

interface UseToolbarDropdownsProps {
  portfolioId?: Id;
  allowedAllocationTypes?: PortfolioAllocationTypeT | PortfolioAllocationTypeT[];
  initialPortfolioId?: Id;
  initialAllocationType?: PortfolioAllocationTypeT;
}

const usePortfolioAllocationDropdowns = ({
  portfolioId = undefined,
  allowedAllocationTypes = undefined,
  initialPortfolioId = undefined,
  initialAllocationType = undefined,
}: UseToolbarDropdownsProps) => {
  const isPortfolioDropdownOn = portfolioId === undefined;
  const [selectedPortfolioId, setSelectedPortfolioId] = React.useState<Id | undefined>(
    initialPortfolioId
  );
  useEffect(() => {
    setSelectedPortfolioId(portfolioId === undefined ? initialPortfolioId : portfolioId);
  }, [portfolioId, initialPortfolioId]);

  const { portfolios, isLoading: isLoadingPortfolios } = usePortfoliosQuery({
    enabled: isPortfolioDropdownOn,
  });
  const savedPortfolios = useMemo(() => {
    return _.pickBy(portfolios, p => p.isSaved);
  }, [portfolios]);
  const { portfolio, isLoading: isLoadingPortfolio } = usePortfolioQuery(selectedPortfolioId);

  const adjustedAllowedAllocTypes = defineAllowedTypes(portfolio, allowedAllocationTypes);
  const [selectedAllocType, setSelectedAllocType] = React.useState(
    initialAllocationType || adjustedAllowedAllocTypes[0]
  );
  useEffect(() => {
    if (portfolio !== undefined) {
      if (!selectedAllocType || !adjustedAllowedAllocTypes.includes(selectedAllocType)) {
        setSelectedAllocType(adjustedAllowedAllocTypes[0]);
      }
    }
  }, [portfolio, adjustedAllowedAllocTypes, selectedAllocType]);

  return {
    selectedPortfolioId,
    selectedAllocType,
    portfolioDropdownElem: isPortfolioDropdownOn ? (
      <PortfolioDropdown
        setSelectedPortfolioId={setSelectedPortfolioId}
        selectedPortfolioId={selectedPortfolioId}
        isLoading={isLoadingPortfolios || isLoadingPortfolio}
        portfolios={savedPortfolios}
      />
    ) : null,
    allocationTypeDropdownElem: (
      <AllocationTypeDropdown
        selectedAllocType={selectedAllocType}
        allocTypes={adjustedAllowedAllocTypes}
        setSelectedAllocType={setSelectedAllocType}
      />
    ),
  };
};

export default usePortfolioAllocationDropdowns;

import MenuItem from '@mui/material/MenuItem';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

import SimpleText from 'components/SimpleText';
import { ClassesType } from 'utils/types';

const styles = {
  root: {
    minWidth: 120,
  },
};

const MyMenuItem = ({ classes, translateId, onClick, text }) => {
  return (
    <MenuItem className={classes.root} onClick={onClick}>
      <SimpleText translateId={translateId} text={text} />
    </MenuItem>
  );
};

MyMenuItem.propTypes = {
  classes: ClassesType.isRequired,
  translateId: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

MyMenuItem.defaultProps = {
  onClick: null,
  text: null,
  translateId: null,
};

export default withStyles(styles, { withTheme: true })(MyMenuItem);

import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';

import { ChildrenType, ClassesType, ColorType } from 'utils/types';

const styles = theme => ({
  primary: {
    color: theme.palette.grey.pronounced,
  },
});

const MyIconButton = ({ classes, onClick, children, color, tooltip }) => {
  const iconButton = (
    <IconButton onClick={onClick} className={classes[color]} size='large'>
      {children}
    </IconButton>
  );
  if (tooltip) {
    return (
      <Tooltip title={<Translate id={`iconButtonTooltip.${tooltip}`} />}>{iconButton}</Tooltip>
    );
  }
  return iconButton;
};

MyIconButton.propTypes = {
  classes: ClassesType.isRequired,
  color: ColorType,
  children: ChildrenType.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

MyIconButton.defaultProps = {
  color: 'primary',
  tooltip: null,
};

export default withStyles(styles, { withTheme: true })(MyIconButton);

import { nanoid } from 'nanoid';

import types from './actions';

import {
  attachComponents,
  getLastYFromWidgets,
  handleLayoutUpdate,
  handlePageOrientationChange,
  handleWidgetConfigRestore,
  handleWidgetConfigUpdate,
  handleWidgetSizeUpdate,
  updateConfigSavedValues,
} from 'screens/Report/utils/helpers';

const firstId = nanoid();
const initialState = {
  editMode: false,
  isModified: false,
  currentSettings: {
    pageOrientation: 'landscape',
  },
  currentWidgets: [
    {
      id: firstId,
      type: 'Separator',
      layout: {
        i: `separator${firstId}`,
        x: 1,
        y: 20,
        w: 12,
        h: 1,
        static: true,
      },
    },
  ],
  lastY: 0,
};

export default function (state = initialState, action) {
  let settings;
  let newLayout;
  switch (action.type) {
    case types.CLEAR_CURRENT:
      return {
        ...state,
        currentSettings: initialState.currentSettings,
        currentWidgets: initialState.currentWidgets,
      };
    case types.CHANGE_PAGE_ORIENTATION:
      settings = { ...state.currentSettings };
      settings.pageOrientation = action.payload;
      return {
        ...state,
        currentSettings: settings,
        currentWidgets: handlePageOrientationChange(action.payload, state.currentWidgets),
      };
    case types.APPLY_REPORT_TO_UI:
      return {
        ...state,
        reportInfo: {
          name: action.payload.name,
          portfolioInfo: action.payload.portfolioInfo,
        },
        currentSettings: {
          pageOrientation: action.payload.layout.dashboard.orientation,
        },
        currentWidgets: attachComponents(action.payload.layout.widgets),
      };
    case types.ADD_WIDGET:
      return {
        ...state,
        currentWidgets: [...state.currentWidgets, action.payload],
      };
    case types.UPDATE_WIDGET:
      return {
        ...state,
        currentWidgets: [
          ...state.currentWidgets.filter(item => item.id !== action.payload.id),
          action.payload.widget,
        ],
      };
    case types.TOGGLE_MODIFIED:
      return {
        ...state,
        isModified: action.payload,
      };
    case types.TOGGLE_EDIT:
      return {
        ...state,
        isModified: state.isModified || action.payload,
        editMode: action.payload,
        currentWidgets: [
          ...state.currentWidgets.map(item => {
            if (item.type !== 'Separator') {
              return {
                ...item,
                layout: {
                  ...item.layout,
                  static: !action.payload,
                },
              };
            }

            return item;
          }),
        ],
      };
    case types.UPDATE_LAYOUT:
      newLayout = handleLayoutUpdate(
        state.currentWidgets,
        action.payload,
        state.currentSettings.pageOrientation
      );

      return {
        ...state,
        currentWidgets: newLayout,
        lastY: getLastYFromWidgets(newLayout),
      };
    case types.DELETE_WIDGET:
      return {
        ...state,
        currentWidgets: [...state.currentWidgets.filter(item => item.id !== action.payload)],
      };
    case types.UPDATE_WIDGET_CONFIG:
      return {
        ...state,
        currentWidgets: handleWidgetConfigUpdate(state.currentWidgets, action.payload),
      };
    case types.RESTORE_WIDGET_CONFIG:
      return {
        ...state,
        currentWidgets: handleWidgetConfigRestore(state.currentWidgets, action.payload),
      };
    case types.UPDATE_WIDGET_SAVED_VALUES:
      return {
        ...state,
        currentWidgets: updateConfigSavedValues(state.currentWidgets, action.payload),
      };
    case types.UPDATE_WIDGET_SIZE:
      return {
        ...state,
        currentWidgets: handleWidgetSizeUpdate(state.currentWidgets, action.payload),
      };
    default:
      return state;
  }
}

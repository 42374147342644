import { PermissionLevel } from 'lib/access/constants';
import useHasPermission from 'lib/access/useHasPermission';

/**
 *
 * @param {object|object[]|Object.<string, object>} objectOrObjects: object or Array/Dict of objects
 * @returns {boolean|boolean[]|Object.<string, boolean>}
 * CURRENTLY WORKS ONLY FOR OBJECTS, BELONGING TO INSTITUTE!
 * If object is provided, it is checked for having 'tablename' key.
 *
 * If it has it, it's considered to be a single object.
 * Then hook returns True, if current user has ADMIN permission for the object.
 *
 * If it doesn't have it, then it's considered to be a dictionary of objects. Can be empty.
 * Then hook returns Object of booleans, where each element is True,
 * if User has ADMIN permission for corresponding object in `objectOrObjects` dict.
 *
 * If array of objects is provided, returns array of booleans, where each element is True, if
 * User has ADMIN permission for corresponding object in `objectOrObjects` array.
 *
 * Returns False for Null/Undefined objects (also in array or dictionary).
 */
const useAdminPermission = objectOrObjects => {
  return useHasPermission(objectOrObjects, PermissionLevel.ADMIN);
};

export default useAdminPermission;

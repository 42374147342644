import { FormControlLabel, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import SimpleText from 'components/SimpleText';
import { toDateString } from 'lib/dates/dateUtils';
import useStartEndDatePickers, {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE,
} from 'lib/dates/useStartEndDatePickers';
import { useTranslate } from 'lib/localization/localization';
import { useDraftValue } from 'lib/misc/useDraft/useDraft';
import { AnalysisDataType } from 'screens/Analysis/lib/constants';
import PortfoliosDropdown from 'screens/Backtest/lib/components/PortfoliosDropdown';
import { useAssetsQuery } from 'screens/Environment/lib/queries';
import { useDataTypeDropdowns } from 'screens/Portfolio/lib/hooks/useDataType';
import { useDefineDateLimits } from 'screens/Portfolio/lib/hooks/useDefineDateLimits';
import {
  restoreWidgetConfig,
  updateWidgetConfig,
  updateWidgetSavedValues,
} from 'screens/Report/redux/actions';
import ConfigurationFrame from 'screens/Report/ReportScreen/components/ConfigurationFrame';
import useBenchmarkChipSelection from 'screens/Report/ReportScreen/components/hooks/useBenchmarkChipSelection';
import { generateBenchmarkList } from 'screens/Report/utils/helpers';
import { widgetType } from 'utils/types';

const useStyles = makeStyles(theme => ({
  dropdown: {
    paddingTop: theme.spacing(4),
  },
}));

const LineChartConfiguration = ({ closeAction, config }) => {
  const {
    portfolio: portfolioId,
    type,
    benchmarks: defaultBenchmarks,
    startDate,
    endDate,
    showBenchmarkLegend,
    backtestId,
  } = config.configuration;
  const translate = useTranslate();
  const { portfolioId: currentPortfolioId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { assets } = useAssetsQuery();

  const { draft: draftShowBenchmarkLegend, setDraft: setShowBenchmarkLegend } = useDraftValue(
    showBenchmarkLegend?.value ?? true
  );
  const { draft: draftPortfolioId, setDraft: setPortfolioId } = useDraftValue(
    portfolioId?.value ?? currentPortfolioId
  );
  const { draft: draftDataType, setDraft: setDraftDataType } = useDraftValue(
    type?.value ?? AnalysisDataType.REAL
  );
  const { draft: draftBacktestId, setDraft: setDraftBacktestId } = useDraftValue(
    backtestId?.value ?? undefined
  );
  const { limitStartDate, limitEndDate } = useDefineDateLimits({
    backtestId: draftBacktestId,
    dataType: draftDataType,
    portfolioId: draftPortfolioId,
  });
  const { draft: draftStartDate, setDraft: setDraftStartDate } = useDraftValue(
    startDate?.value ?? limitStartDate ?? toDateString(DEFAULT_START_DATE)
  );
  const { draft: draftEndDate, setDraft: setDraftEndDate } = useDraftValue(
    endDate?.value ?? limitEndDate ?? toDateString(DEFAULT_END_DATE)
  );

  const defaultColumns = useMemo(() => {
    return generateBenchmarkList(defaultBenchmarks?.value, assets);
  }, [assets, defaultBenchmarks.value]);
  const [BenchmarkField, benchmarks] = useBenchmarkChipSelection(defaultColumns);

  const benchmarkCount = benchmarks.filter(column => column.checked).length;

  // TODO separate field update as an individual effects for better performance
  useEffect(() => {
    dispatch(
      updateWidgetConfig(config.id, {
        portfolio: {
          ...config.configuration.portfolio,
          value: draftPortfolioId,
        },
        backtestId: {
          ...config.configuration.backtestId,
          value: draftBacktestId,
        },
        type: {
          ...config.configuration.type,
          value: draftDataType,
        },
        benchmarks: {
          ...config.configuration.benchmarks,
          value: benchmarks
            .filter(benchmark => benchmark.checked)
            .map(benchmark => ({ id: benchmark.key, color: benchmark.color })),
        },
        startDate: {
          ...config.configuration.startDate,
          value: draftStartDate,
        },
        endDate: {
          ...config.configuration.endDate,
          value: draftEndDate,
        },
        showBenchmarkLegend: {
          ...config.configuration.showBenchmarkLegend,
          value: draftShowBenchmarkLegend,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    benchmarks,
    config.id,
    draftBacktestId,
    draftDataType,
    draftEndDate,
    draftPortfolioId,
    draftShowBenchmarkLegend,
    draftStartDate,
  ]);

  const portfolioDropdownElem = (
    <PortfoliosDropdown onChange={setPortfolioId} portfolioId={draftPortfolioId} />
  );
  const { startDatePickerElem, endDatePickerElem, quickSetElem } = useStartEndDatePickers({
    startDate: draftStartDate,
    endDate: draftEndDate,
    onEndDateChange: setDraftEndDate,
    onStartDateChange: setDraftStartDate,
    limitEndDate,
    limitStartDate,
  });
  const { dataTypeDropdownElem, backtestDropdownElem } = useDataTypeDropdowns({
    dataType: draftDataType,
    backtestId: draftBacktestId,
    onDataTypeChange: newDataType => {
      setDraftDataType(newDataType);
      setDraftStartDate(limitStartDate ?? toDateString(DEFAULT_START_DATE));
      setDraftEndDate(limitEndDate ?? toDateString(DEFAULT_END_DATE));
    },
    onBacktestChange: newBacktestId => {
      setDraftBacktestId(newBacktestId);
      setDraftStartDate(limitStartDate ?? toDateString(DEFAULT_START_DATE));
      setDraftEndDate(limitEndDate ?? toDateString(DEFAULT_END_DATE));
    },
    portfolioId: draftPortfolioId,
    allowedDataTypes: [AnalysisDataType.REAL, AnalysisDataType.BACKTEST],
  });

  useEffect(() => {
    dispatch(updateWidgetSavedValues(config.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ConfigurationFrame
      closeAction={() => {
        dispatch(restoreWidgetConfig(config.id));
        closeAction();
      }}
      saveAction={() => {
        dispatch(updateWidgetSavedValues(config.id));
        closeAction();
      }}
    >
      <div style={{ height: '65vh', overflow: 'auto' }}>
        <div className={classes.dropdown}>{portfolioDropdownElem}</div>
        <div className={classes.dropdown}>{dataTypeDropdownElem}</div>
        {backtestDropdownElem !== undefined && (
          <div className={classes.dropdown}>{backtestDropdownElem}</div>
        )}
        <BenchmarkField
          translateSource='dashboard'
          label='benchmarks'
          useLabelWithoutTranslate
          addDisabled={benchmarkCount === 10}
          addColor={benchmarkCount === 10 ? 'disabled' : 'primary'}
        />
        <br />

        <FormControlLabel
          control={
            <Checkbox
              checked={draftShowBenchmarkLegend}
              onChange={() => setShowBenchmarkLegend(!draftShowBenchmarkLegend)}
              name='chipLegend'
              color='primary'
            />
          }
          label={translate('dashboard.showBenchmarkLegend')}
        />

        <SimpleText translateId='dashboard.time' fontWeight='bold' mt={4} />
        <div className={classes.dropdown}>{quickSetElem}</div>
        <SimpleText translateId='dashboard.startDate' fontWeight='bold' mt={4} />
        <div className={classes.dropdown}>{startDatePickerElem}</div>
        <SimpleText translateId='dashboard.endDate' fontWeight='bold' mt={4} />
        <div className={classes.dropdown}>{endDatePickerElem}</div>
      </div>
    </ConfigurationFrame>
  );
};

LineChartConfiguration.propTypes = {
  closeAction: PropTypes.func.isRequired,
  config: widgetType.isRequired,
};

export default LineChartConfiguration;

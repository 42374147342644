import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import MyTabs from 'components/MyTabs';
import ScreenBase from 'components/ScreenBase';
import SearchBar from 'components/SearchBar/SearchBarView';
import { fuseSearch } from 'components/SearchBar/utils';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import { useTabs } from 'lib/navigation/navigation';
import PortfolioCard from 'screens/Portfolio/ChoosePortfoliosScreen/components/PortfolioCard';
import { hasAlerts, sortPortfolioCards } from 'screens/Portfolio/ChoosePortfoliosScreen/helpers';
import { isPortfolioTracked } from 'screens/Portfolio/lib/helpers';
import { usePortfoliosQuery } from 'screens/Portfolio/lib/queries';
import { PORTFOLIO_CARD_HEIGHT, Routes } from 'utils/constants';
// const log = createLogger('PortfolioListScreen');

const useStyles = makeStyles(theme => ({
  grid: {
    padding: '10px',
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: '3.3rem',
  },
  tab: {
    height: '4rem',
  },
  badge: {
    padding: theme.spacing(1),
  },
  hiUser: {
    float: 'left',
    marginTop: '1rem',
    marginBottom: '1rem',
    marginLeft: '0.3rem',
  },
  searchBar: {
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    paddingLeft: '2.75rem',
    paddingRight: '2.75rem',
  },
  allCards: {
    paddingRight: '2.65rem',
    paddingLeft: '2.65rem',
  },
  msg: {
    float: 'left',
    position: 'relative',
    bottom: '-1.8rem',
    marginLeft: '1rem',
  },
}));

const ChoosePortfoliosScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const hasCreatePermission = useCreatePermission(PermissionResourceName.PORTFOLIO);
  const { tabIndex, onTabIndexChange, tab } = useTabs(['tracked', 'saved', 'alert']);

  const [searchValue, setSearchValue] = React.useState('');

  const { portfolios, isLoading } = usePortfoliosQuery();

  const { savedPortfolios, alertPortfolios, trackedPortfolios } = useMemo(() => {
    const savedPortfolio = _.filter(portfolios, portfolio => portfolio.isSaved);
    const searchFields = ['name'];
    const searchedPortfolios = fuseSearch(savedPortfolio, searchValue, searchFields);
    const sortedPortfolios = sortPortfolioCards(searchedPortfolios);
    return {
      savedPortfolios: sortedPortfolios,
      alertPortfolios: sortedPortfolios.filter(portfolio => hasAlerts(portfolio)),
      trackedPortfolios: sortedPortfolios.filter(portfolio => isPortfolioTracked(portfolio)),
    };
  }, [portfolios, searchValue]);

  const SkeletonCard = [1, 2, 3, 4, 5, 6, 7, 8].map(number => (
    <Grid item key={number} className={classes.grid} xs={12} sm={12} md={6} lg={4} xl={3}>
      <Skeleton variant='rectangular' height={PORTFOLIO_CARD_HEIGHT} />
    </Grid>
  ));
  let portfoliosToShow;
  switch (tab) {
    case 'tracked':
      portfoliosToShow = trackedPortfolios;
      break;
    case 'saved':
      portfoliosToShow = savedPortfolios;
      break;
    case 'alert':
      portfoliosToShow = alertPortfolios;
      break;
    default:
      portfoliosToShow = [];
  }

  const cardsToShow = portfoliosToShow.map(portfolio => (
    <Grid item key={portfolio.id} className={classes.grid} xs={12} sm={12} md={6} lg={4} xl={3}>
      <PortfolioCard portfolio={portfolio} />
    </Grid>
  ));

  const navButtonsProps = [];
  if (hasCreatePermission) {
    navButtonsProps.push({
      translateId: 'navButton.new',
      onClick: () => {
        history.push(Routes.NEW_PORTFOLIO);
      },
      variant: 'highEmp',
      'pendo-id': 'portfolio-choose-portfolios-screen-new',
    });
  }

  return (
    <ScreenBase titleTranslationId='portfolioListScreen.title' navButtonsProps={navButtonsProps}>
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.tabs}>
          <MyTabs
            translationPath='portfolioListScreen.tabs'
            chosenTab={tabIndex}
            onChangeTab={(event: any, value: number) => onTabIndexChange(value)}
            tabsWithBadge={[
              { translateId: 'trackedPortfolios', badge: 0 },
              { translateId: 'savedPortfolios', badge: 0 },
              { translateId: 'alertPortfolios', badge: alertPortfolios.length },
            ]}
          />
        </Grid>
        <Grid item xs={12} className={classes.searchBar}>
          <SearchBar
            onUpdateSearch={setSearchValue}
            translationPath='portfolioListScreen.searchBar'
          />
        </Grid>
        <Grid container className={classes.allCards}>
          {isLoading ? SkeletonCard : cardsToShow}
        </Grid>
      </Grid>
    </ScreenBase>
  );
};

export default ChoosePortfoliosScreen;

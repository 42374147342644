import {
  endOfToday,
  subYears,
  parse,
  isSaturday,
  isSunday,
  subBusinessDays,
  addBusinessDays,
} from 'date-fns';
import { isEmpty, first, last } from 'lodash';

import {
  getCorrectTimeSeriesPeriod,
  getPercentagePerformance,
  getPerformanceFromPrice,
  parseDatesInSeries,
} from 'lib/timeSeries/tsData';

export function isWeekend(date) {
  return isSaturday(date) || isSunday(date);
}

// eslint-disable-next-line no-unused-vars
function rollBackIfWeekend(date) {
  if (isWeekend(date)) {
    return subBusinessDays(date, 1);
  }
  return date;
}

function rollForwardIfWeekend(date) {
  if (isWeekend(date)) {
    return addBusinessDays(date, 1);
  }
  return date;
}

function checkAvailableDataOptions(portfolio) {
  const dataSelection = [{ id: 1, value: 'historical' }];
  if (!isEmpty(portfolio.backtests) && !isEmpty(portfolio.backtests[0].portfolios)) {
    dataSelection.push({ id: 2, value: 'backtest' });
  }
  if (portfolio.isTracked) {
    if (!isEmpty(portfolio.history.portfolios)) {
      dataSelection.push({ id: 3, value: 'real' });
    }
  }
  return dataSelection;
}

function getDateLimitations(portfolio, dataType) {
  const yesterday = subBusinessDays(endOfToday(), 1);
  const fiveYearsAgo = rollForwardIfWeekend(subYears(yesterday, 5));

  const dates = {
    left: fiveYearsAgo,
    right: yesterday,
  };

  if (dataType === 'backtest' && !isEmpty(portfolio.backtests)) {
    const backtest = portfolio.backtests[0];
    const left = parse(backtest.startDate, 'yyyy-MM-dd', new Date());
    const right = parse(backtest.endDate, 'yyyy-MM-dd', new Date());
    return { left: rollForwardIfWeekend(left), right: rollForwardIfWeekend(right) };
  }

  if (dataType === 'real' && portfolio.isTracked) {
    dates.left = parse(portfolio.trackingStartDate, 'yyyy-MM-dd', new Date());
  }
  return dates;
}

function generateLineChartData(widgetData, assetBenchmarks, assets) {
  const data = {};
  if (widgetData === undefined) {
    return data;
  }

  data.main = {
    type: 'PORTFOLIO',
    color: '#2dc6c9',
    error: null,
    id: '-1',
    name: 'portfolio',
    series: widgetData.portfolio.map(({ x, y }) => ({
      x: new Date(x),
      y: y * 100,
    })),
  };

  data[0] = {
    type: 'ASSET',
    color: '#D9DADA',
    error: null,
    id: '-2',
    name: 'benchmark',
    series: widgetData.benchmark.map(({ x, y }) => ({
      x: new Date(x),
      y: y * 100,
    })),
  };

  let i = 1;
  const start = first(data.main.series);
  const end = last(data.main.series);
  assetBenchmarks
    .filter(benchmark => benchmark.key > 0 && benchmark.checked)
    .forEach(benchmark => {
      const asset = assets[benchmark.key];
      if (asset?.performance) {
        const series = parseDatesInSeries(asset.performance).sort((a, b) => a.x - b.x);
        const correctPeriodSeries = getCorrectTimeSeriesPeriod(series, start.x, end.x);
        const assetPerformance = getPerformanceFromPrice(correctPeriodSeries);

        data[i] = {
          type: 'ASSET',
          color: benchmark.color,
          error: null,
          id: benchmark.key,
          name: benchmark.label,
          series: getPercentagePerformance(assetPerformance),
        };
        i += 1;
      }
    });
  return data;
}

export { checkAvailableDataOptions, getDateLimitations, generateLineChartData };

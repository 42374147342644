import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import useLocalStorage from 'lib/misc/useLocalStorage';
import { generateSearchPath } from 'lib/navigation/navigation';
import { SuccessKindType } from 'lib/notifications/constants';
import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import {
  useErrorNotification,
  useSuccessNotification,
} from 'lib/notifications/useAlertNotification';
import { apiUrlFor, get, post } from 'lib/queries';
import { addSmallNotification } from 'redux/notifications/actions';
import { UserOverview } from 'screens/User/lib/types';
import { userGroupsKeys } from 'screens/UserGroup/lib/queries';
import { UserGroup } from 'screens/UserGroup/lib/types';
import { ErrorKind, Routes, SmallNotification } from 'utils/constants';
import { logoutUserForExtensions } from 'utils/extensions';

export const authKeys = {
  all: [{ scope: 'auth' }],
};

interface GetTokenResponse {
  user: UserOverview;
  groups: UserGroup[];
  token: string;
}

interface GetTokenBody {
  email: string;
  password: string;
}

interface PostRegisterBody {
  name: string;
  surname: string;
  password: string;
}

interface PostRequestResetLinkBody {
  email: string;
}

interface PostResetPasswordBody {
  password: string;
}

async function getToken({ email, password }: GetTokenBody): Promise<GetTokenResponse> {
  const response = await axios.post(apiUrlFor('users/to/token/'), {
    email,
    password,
  });
  return response.data;
}

async function getUserSelf(): Promise<GetTokenResponse> {
  return get('users/from/token/');
}

async function postRegister(token: string, data: PostRegisterBody): Promise<UserOverview> {
  return post(`register/?token=${token}`, data);
}

async function postResetPassword(token: string, data: PostResetPasswordBody) {
  return post(`reset_password/?token=${token}`, data);
}

async function postRequestResetLink(data: PostRequestResetLinkBody) {
  return post('request_reset_link/', data);
}

const useAuthQuery = (options: any) => {
  return useQuery(authKeys.all, getUserSelf, {
    staleTime: Infinity,
    retry: false,
    ...options,
  });
};

export const useUserSelfQuery = () => {
  const query = useAuthQuery({ select: (data: GetTokenResponse) => data.user });
  return {
    ...query,
    user: query.data,
  };
};

export const useAuthToken = () => {
  const [token, setToken] = useLocalStorage<string | null>('token', null);
  return {
    token,
    setToken,
  };
};

export const useAuth = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { token, setToken } = useAuthToken();
  const queryClient = useQueryClient();
  const query = useAuthQuery({
    enabled: !!token,
    onSuccess: (data: GetTokenResponse) => {
      queryClient.setQueryData(userGroupsKeys.list(), data.groups);
      setToken(data.token);
    },
    onError: () => {
      setToken(null);
    },
  });
  const mutation = useMutation(
    ({ email, password }: GetTokenBody) => getToken({ email, password }),
    {
      // response of the mutation is passed to onSuccess
      onSuccess: self => {
        // update detail view directly
        queryClient.setQueryData(authKeys.all, self);
        const { from } = (location.state || { from: { pathname: Routes.CHOOSE_PORTFOLIO } }) as {
          from: { pathname: string };
        };
        history.replace(from);
      },
      onError: error => {
        switch (makeErrorFromHttpResponse(error).kind) {
          case ErrorKind.UNAUTHORIZED:
            dispatch(addSmallNotification(SmallNotification.INCORRECT_LOGIN_DATA));
            break;
          case ErrorKind.AWAITING_REGISTRATION:
            dispatch(addSmallNotification(SmallNotification.ACCOUNT_NOT_APPROVED));
            break;
          case ErrorKind.USER_EXPIRED:
            dispatch(addSmallNotification(SmallNotification.ACCOUNT_EXPIRED));
            break;
          default:
            dispatch(addSmallNotification(SmallNotification.UNKNOWN_ERROR));
        }
      },
    }
  );
  const logout = () => {
    queryClient.clear();
    setToken(null);
    logoutUserForExtensions();
  };
  return {
    isAuthenticating: query.isLoading || (query.isSuccess && !token),
    isAuthenticated: !!query.data && !!token,
    login: mutation.mutate,
    logout,
  };
};

export const useRegisterMutation = () => {
  const { addError } = useErrorNotification();
  const { addSuccess } = useSuccessNotification();
  const history = useHistory();
  return useMutation(
    ({ token, data }: { token: string; data: PostRegisterBody }) => postRegister(token, data),
    {
      onSuccess: data => {
        history.push(generateSearchPath(Routes.LOGIN, { searchParams: { email: data.email } }));
        addSuccess({ kind: SuccessKindType.REGISTRATION_SUCCESS });
      },
      onError: error => {
        addError(makeErrorFromHttpResponse(error));
      },
    }
  );
};

export const useResetPasswordMutation = () => {
  const { addError } = useErrorNotification();
  const { addSuccess } = useSuccessNotification();
  const history = useHistory();
  return useMutation(
    ({ token, data }: { token: string; data: PostResetPasswordBody }) =>
      postResetPassword(token, data),
    {
      onSuccess: () => {
        history.push(Routes.LOGIN);
        addSuccess({ kind: SuccessKindType.PASSWORD_RESET_SUCCESS });
      },
      onError: error => {
        addError(makeErrorFromHttpResponse(error));
      },
    }
  );
};

export const useRequestResetLinkMutation = () => {
  const { addError } = useErrorNotification();
  const { addSuccess } = useSuccessNotification();
  return useMutation((data: PostRequestResetLinkBody) => postRequestResetLink(data), {
    onSuccess: () => {
      addSuccess({ kind: SuccessKindType.PASSWORD_RESET_LINK_SENT_SUCCESS });
    },
    onError: error => {
      addError(makeErrorFromHttpResponse(error));
    },
  });
};

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import UploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel/index';
import IconButton from '@mui/material/IconButton/index';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu/index';
import MenuItem from '@mui/material/MenuItem/index';
import Toolbar from '@mui/material/Toolbar/index';
import Tooltip from '@mui/material/Tooltip/index';
import Typography from '@mui/material/Typography/index';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';

import RestToRight from '../RestToRight';

import SimpleIconButton from 'components/SimpleIconButton';
import SimpleText from 'components/SimpleText';
import { parseCsv, triggerCsvDownload } from 'lib/misc/misc';
import { DEFAULT_CSV_DELIMITER } from 'utils/constants';
import { ClassesType, ExportFieldsType, TableObjectsType } from 'utils/types';

const styles = {
  columnMenu: {
    marginLeft: -9,
  },
  tableName: {},
};

class EnhancedTableToolbar extends React.Component {
  state = {
    anchorViewColumns: null,
  };

  handleDownload = () => {
    const {
      tableObjects,
      exportFields,
      columns,
      titleTranslateId,
      exportFormatting,
      downloadFileTitle,
    } = this.props;
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    today = `${dd}/${mm}/${yyyy}`;
    const options = { delimiter: DEFAULT_CSV_DELIMITER };
    options.fields = exportFields || columns;
    options.exportFormatting = exportFormatting;
    triggerCsvDownload(
      tableObjects,
      `${today}-${titleTranslateId.replace('.', '-')}-${downloadFileTitle}.csv`,
      options
    );
  };

  handleUpload = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = this.onFileUpload;
  };

  onFileUpload = e => {
    const { onSuccessfulUpload } = this.props;
    const csv = e.target.result;
    const options = { separator: DEFAULT_CSV_DELIMITER };
    // const onSuccessfulUpload = (data) => { console.log(data); };
    parseCsv(csv, onSuccessfulUpload, options);
    // const availableAssetIds = checkAvailability(assets, uploadedIsins);
    // onSuccessfulUpload(availableAssetIds);
  };

  handleClickViewColumns = event => {
    this.setState({ anchorViewColumns: event.currentTarget });
  };

  handleCloseViewColumns = () => {
    this.setState({ anchorViewColumns: null });
  };

  render() {
    const {
      titleTranslateId,
      titleTranslateData,
      numSelected,
      onApplyClick,
      isOnLeftSide,
      translate,
      classes,
      columns,
      checkedColumns,
      onColumnCheck,
      onSuccessfulUpload,
      isDownloadButton,
      translateTableId,
      hasColumnCheckBox,
      onAddRowClick,
      addRowTranslateId,
      onDeleteSelectedClick,
      uploadButtonColor,
    } = this.props;
    const { anchorViewColumns } = this.state;
    const selectedToolbarButtons = onApplyClick ? (
      <Tooltip title='Apply'>
        <IconButton aria-label='Apply' onClick={onApplyClick} color='primary' size='large'>
          {isOnLeftSide ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
        </IconButton>
      </Tooltip>
    ) : (
      <React.Fragment>
        {onDeleteSelectedClick ? (
          <Tooltip title='Delete Selected'>
            <IconButton
              aria-label='delete'
              onClick={onDeleteSelectedClick}
              color='primary'
              size='large'
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </React.Fragment>
    );

    const selectedToolbarInfo = (
      <Typography color='inherit' variant='h2'>
        {numSelected} <Translate id='selected' />
      </Typography>
    );

    const selectedToolbar = (
      <Toolbar>
        {isOnLeftSide ? selectedToolbarInfo : selectedToolbarButtons}
        <RestToRight />
        {isOnLeftSide ? selectedToolbarButtons : selectedToolbarInfo}
      </Toolbar>
    );

    const viewColumnsMenu = (
      <Menu
        id='view-columns-menu'
        anchorEl={anchorViewColumns}
        open={Boolean(anchorViewColumns)}
        onClose={this.handleCloseViewColumns}
      >
        {columns.map(columnKey => (
          <MenuItem key={columnKey}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedColumns.includes(columnKey)}
                  onChange={() => onColumnCheck(columnKey)}
                  value='checkedB'
                  color='primary'
                />
              }
              label={translateTableId ? translate(`${translateTableId}.${columnKey}`) : columnKey}
            />
          </MenuItem>
        ))}
      </Menu>
    );
    const commonToolbar = (
      <Toolbar>
        {titleTranslateId !== '' ? (
          <SimpleText
            className={classes.tableName}
            translateId={titleTranslateId}
            translateData={titleTranslateData}
            variant='h2'
          />
        ) : null}
        <RestToRight />
        {onAddRowClick ? (
          <Tooltip title={translate(addRowTranslateId)}>
            <IconButton onClick={onAddRowClick} size='large'>
              <AddIcon color='primary' />
            </IconButton>
          </Tooltip>
        ) : null}
        {onSuccessfulUpload ? (
          <>
            <Input
              style={{ display: 'none' }}
              name='file'
              id='upload-button-file'
              className='inputfile'
              onChange={e => this.handleUpload(e)}
              onClick={e => {
                e.target.value = null;
              }}
              type='file'
              inputProps={{ accept: '.csv' }}
            />
            <InputLabel htmlFor='upload-button-file'>
              <SimpleIconButton
                translateId='upload'
                component='span'
                color={uploadButtonColor}
                pendo-id='lib-table-toolbar-upload-icon-button'
              >
                <UploadIcon />
              </SimpleIconButton>
            </InputLabel>
          </>
        ) : null}
        {isDownloadButton ? (
          <SimpleIconButton onClick={this.handleDownload} translateId='download'>
            <DownloadIcon />
          </SimpleIconButton>
        ) : null}
        {hasColumnCheckBox ? (
          <SimpleIconButton
            className={classes.columnMenu}
            onClick={this.handleClickViewColumns}
            translateId='chooseColumns'
          >
            <ViewColumnIcon />
          </SimpleIconButton>
        ) : null}
        {viewColumnsMenu}
      </Toolbar>
    );

    return numSelected > 0 ? selectedToolbar : commonToolbar;
  }
}

EnhancedTableToolbar.propTypes = {
  titleTranslateId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  titleTranslateData: PropTypes.object,
  numSelected: PropTypes.number.isRequired,
  onApplyClick: PropTypes.func,
  isOnLeftSide: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  checkedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  onColumnCheck: PropTypes.func.isRequired,
  tableObjects: TableObjectsType.isRequired,
  exportFields: ExportFieldsType,
  onSuccessfulUpload: PropTypes.func,
  isDownloadButton: PropTypes.bool.isRequired,
  exportFormatting: PropTypes.objectOf(PropTypes.func).isRequired,
  classes: ClassesType.isRequired,
  translateTableId: PropTypes.string.isRequired,
  hasColumnCheckBox: PropTypes.bool,
  downloadFileTitle: PropTypes.string,
  onAddRowClick: PropTypes.func,
  addRowTranslateId: PropTypes.string,
  onDeleteSelectedClick: PropTypes.func,
  uploadButtonColor: PropTypes.string,
};

EnhancedTableToolbar.defaultProps = {
  titleTranslateId: null,
  titleTranslateData: undefined,
  isOnLeftSide: true,
  exportFields: null,
  onSuccessfulUpload: null,
  onApplyClick: null,
  hasColumnCheckBox: true,
  downloadFileTitle: '',
  onAddRowClick: null,
  onDeleteSelectedClick: false,
  addRowTranslateId: 'iconButtonTooltip.add',
  uploadButtonColor: 'default',
};

export default withLocalize(withStyles(styles, { withTheme: true })(EnhancedTableToolbar));

import { useMemo } from 'react';

import { hasPermissionFor } from 'lib/access/helpers';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';
import { useUserSelfQuery } from 'screens/Login/lib/queries';
import { useUserGroupsQuery } from 'screens/UserGroup/lib/queries';

/**
 *
 * @param {object|object[]|Object.<string, object>|string|undefined} objectOrObjects:
 * object or Array/Dict of objects or string
 * @param {string} requiredPermissionLevel: required PermissionLevel
 * @param {boolean} isGeneralRole:
 * @returns {boolean|boolean[]|Object.<string, boolean>}
 * CURRENTLY WORKS ONLY FOR OBJECTS, BELONGING TO INSTITUTE!
 * If string is provided, it is considered to be a name of the resource.
 * Then hook returns True, if current user has a permission to access this kind of
 * objects in theory. E.g. when User needs to create new object.
 *
 * If object is provided, it is checked for having 'tablename' key.
 *
 * If it has it, it's considered to be a single object.
 * Then hook returns True, if current user has given permission for the object.
 *
 * If it doesn't have it, then it's considered to be a dictionary of objects. Can be empty.
 * Then hook returns Object of booleans, where each element is True,
 * if User has given permission for corresponding object in `objectOrObjects` dict.
 *
 * If array of objects is provided, returns array of booleans, where each element is True, if
 * User has given permission for corresponding object in `objectOrObjects` array.
 *
 * Returns False for Null/Undefined objects (also in array or dictionary).
 */
const useHasPermission = (objectOrObjects, requiredPermissionLevel, isGeneralRole = false) => {
  const { user } = useUserSelfQuery();
  const { userGroups } = useUserGroupsQuery();
  const { instituteId } = useCurrentInstitute();
  return useMemo(
    () =>
      hasPermissionFor(
        objectOrObjects,
        user,
        userGroups,
        requiredPermissionLevel,
        instituteId,
        isGeneralRole
      ),
    [objectOrObjects, user, userGroups, requiredPermissionLevel, isGeneralRole, instituteId]
  );
};

export default useHasPermission;

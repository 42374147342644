import { Grid } from '@mui/material';
import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

const TestSpinner = () => {
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'
      style={{ height: '100%' }}
    >
      <BeatLoader size={45} color='#57d1d3' loading />
    </Grid>
  );
};

export default TestSpinner;

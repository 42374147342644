import arrDiff from 'arr-diff';
import _ from 'lodash';

function stringifyObjectsInArray(arr) {
  return arr.map(item => {
    if (typeof item === 'object') {
      return JSON.stringify(item);
    }
    return item;
  });
}

/**
 * Method checks if there are any changed in draft Object in comparison with
 * original Object.
 * Only those properties are compared, which are in a draft Object.
 *
 * Only shallow comparison is used, except for Arrays.
 * Arrays are compared like Sets (without taking position of values into account).
 * Objects in Arrays are first stringified and then compared.
 *
 * @param original
 * @param draft
 * @return {{ isEqual: boolean, diff: object}}
 * isEqual - true if there are no changes, false otherwise.
 * diff - object with changed properties.
 * For Arrays, diff is a { add: [], remove: [] } object.
 * For all other values, diff is a { oldValue: value, newValue: value } object.
 */
export const findDiffOriginalAndDraft = (original, draft) => {
  if (original === draft) {
    return { isEqual: true, diff: {} };
  }

  // draft may have only a subset of the original properties
  const draftKeys = Object.keys(draft ?? {});
  const originalCut = _.pick(original ?? {}, draftKeys);

  const diff = {};
  draftKeys.forEach(key => {
    if (Array.isArray(originalCut[key]) && Array.isArray(draft[key])) {
      const originalArr = stringifyObjectsInArray(originalCut[key]);
      const draftArr = stringifyObjectsInArray(draft[key]);
      const add = arrDiff(draftArr, originalArr);
      const remove = arrDiff(originalArr, draftArr);
      if (add.length || remove.length) {
        diff[key] = { add, remove };
      }
    } else if (_.isPlainObject(originalCut[key]) && _.isPlainObject(draft[key])) {
      const originalStringified = JSON.stringify(originalCut[key]);
      const draftStringified = JSON.stringify(draft[key]);
      if (originalStringified !== draftStringified) {
        diff[key] = { oldValue: originalCut[key], newValue: draft[key] };
      }
    } else if (originalCut[key] !== draft[key]) {
      diff[key] = { oldValue: originalCut[key], newValue: draft[key] };
    }
  });

  return { isEqual: _.isEmpty(diff), diff };
};

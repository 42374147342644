import withStyles from '@mui/styles/withStyles';
import React from 'react';

import { ChildrenType, ClassesType } from 'utils/types';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    paddingLeft: '3rem',
    width: '100%',
    padding: 0,
    margin: 0,
    minHeight: '4rem',
  },
});

const TopScreenButtons = ({ classes, children }) => <div className={classes.root}>{children}</div>;

TopScreenButtons.propTypes = {
  classes: ClassesType.isRequired,
  children: ChildrenType,
};

TopScreenButtons.defaultProps = {
  children: null,
};

export default withStyles(styles, { withTheme: true })(TopScreenButtons);

import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ClassesType } from 'utils/types';

// idea for styles taken from here: https://davidwalsh.name/css-flip
const styles = {
  /* entire container, keeps perspective */
  flipContainer: {
    perspective: '1000px',
  },
  /* flipper turned when clicked */
  flipperTurned: {
    transform: 'rotateY(180deg)',
  },

  cardSize: {
    width: '100%',
  },

  /* flip speed goes here */
  flipper: {
    transition: '0.6s',
    transformStyle: 'preserve-3d',
    position: 'relative',
  },

  /* hide back of pane during swap */
  card: {
    backfaceVisibility: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
  },

  /* front pane, placed above back */
  front: {
    zIndex: 2,
    /* for firefox 31 */
    transform: 'rotateY(0deg)',
  },

  /* back, initially hidden pane */
  back: {
    transform: 'rotateY(180deg)',
  },
};

const MyFlippingCard = ({ classes, isFlipped, frontContent, backContent, height }) => (
  <div style={{ height }} className={classNames(classes.flipContainer, classes.cardSize)}>
    <div
      className={classNames(classes.flipper, {
        [classes.flipperTurned]: isFlipped,
      })}
    >
      <div style={{ height }} className={classNames(classes.front, classes.cardSize, classes.card)}>
        {frontContent}
      </div>
      {backContent && (
        <div
          style={{ height }}
          className={classNames(classes.back, classes.cardSize, classes.card)}
        >
          {backContent}
        </div>
      )}
    </div>
  </div>
);

MyFlippingCard.propTypes = {
  classes: ClassesType.isRequired,
  isFlipped: PropTypes.bool.isRequired,
  height: PropTypes.string.isRequired,
  frontContent: PropTypes.node.isRequired,
  backContent: PropTypes.node,
};

MyFlippingCard.defaultProps = {
  backContent: undefined,
};

MyFlippingCard.defaultProps = {};

export default withStyles(styles, { withTheme: true })(MyFlippingCard);

import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Translate } from 'react-localize-redux';

import SimpleText from 'components/SimpleText';
import { PERMISSION_LEVEL_HIERARCHY_FULL, PermissionLevel } from 'lib/access/constants';
import { isPermissionLevelLessEqual } from 'lib/access/helpers';
import { useUsersQuery } from 'screens/User/lib/queries';
import { UserGroupType } from 'utils/types';

const ObjectAccessDialogListItem = ({
  userGroup,
  currentPermissionLevel,
  possiblePermissionLevels,
  onPermissionLevelChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { users } = useUsersQuery();
  const usersString = useMemo(() => {
    if (users !== undefined) {
      const usersInGroup = Object.values(users).filter(user => user.groups.includes(userGroup.id));
      return usersInGroup.reduce(
        (previousValue, currentValue) => `${previousValue}<br>${currentValue.name}`,
        ''
      );
    }
    return '';
  }, [userGroup.id, users]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = permissionLevel => {
    onPermissionLevelChange(permissionLevel);
    handleClose();
  };

  const disabled = _.isEmpty(possiblePermissionLevels);
  return (
    <>
      <Tooltip title={<Translate id='objectAccessDialog.groupTooltip' data={{ usersString }} />}>
        <ListItem button disabled={disabled} onClick={handleClick}>
          <ListItemAvatar>
            <Avatar>
              <GroupIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={userGroup.name}
            // secondary={secondary ? 'Secondary text' : null}
          />
          {possiblePermissionLevels.includes(PermissionLevel.NO) ? (
            <ListItemSecondaryAction>
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={() => handleAction(PermissionLevel.NO)}
                size='large'
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          ) : null}
        </ListItem>
      </Tooltip>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {PERMISSION_LEVEL_HIERARCHY_FULL.map(permissionLevel => {
          if (!possiblePermissionLevels.includes(permissionLevel)) {
            return null;
          }
          let action = isPermissionLevelLessEqual(currentPermissionLevel, permissionLevel)
            ? 'promote'
            : 'demote';
          action = permissionLevel === PermissionLevel.NO ? 'revoke' : action;
          return (
            <MenuItem key={permissionLevel} onClick={() => handleAction(permissionLevel)}>
              <SimpleText
                translateId={`objectAccessDialog.actions.${action}`}
                translateData={{ permissionLevel }}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

ObjectAccessDialogListItem.propTypes = {
  userGroup: UserGroupType.isRequired,
  currentPermissionLevel: PropTypes.string.isRequired,
  possiblePermissionLevels: PropTypes.arrayOf(PropTypes.string),
  onPermissionLevelChange: PropTypes.func.isRequired,
};

ObjectAccessDialogListItem.defaultProps = {
  possiblePermissionLevels: [],
};

export default ObjectAccessDialogListItem;

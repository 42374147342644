import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { intersection } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, Fragment } from 'react';
import { Translate } from 'react-localize-redux';

import { CHILD_KPIS, PARENT_KPIS, PerformanceInfoType } from 'lib/performance/constants';
import useDashboardStyles from 'screens/Report/ReportScreen/configuration/dashboard.style';

const KPIPaperTable = ({ data, performanceInfo, benchmarkPerformanceInfos, size, kpisToShow }) => {
  const wrapper = useRef();

  const dashboardClasses = useDashboardStyles();
  const performanceInfos = [performanceInfo, ...benchmarkPerformanceInfos];

  return (
    <TableContainer
      ref={wrapper}
      style={{
        maxHeight: wrapper?.current?.parentNode.offsetHeight,
        background: '#fff',
      }}
    >
      <Table stickyHeader aria-label='a dense kpi table' size='small'>
        <TableHead>
          <TableRow>
            <TableCell style={{ paddingRight: '2.5rem' }} />
            {performanceInfos.map(item => (
              <TableCell
                key={`firstRow.${item.id}`}
                className={dashboardClasses[`${size}Table`]}
                style={{ fontWeight: 'bold', textAlign: 'right' }}
              >
                {item.name}
              </TableCell>
            ))}
            {/* Jus to make a bit of space on the right for edit/delete */}
            <TableCell className='d-print-none' />
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(PARENT_KPIS).map(parentKPI => {
            const keysToDisplay = intersection(CHILD_KPIS[parentKPI], kpisToShow);
            if (keysToDisplay.length > 0) {
              return (
                <Fragment key={parentKPI}>
                  <TableRow>
                    <TableCell
                      className={dashboardClasses[`${size}Table`]}
                      style={{ borderBottom: 'none', fontWeight: 'bold', marginBottom: '0.3rem' }}
                    >
                      <Translate id={`dashboard.${parentKPI}`} />
                    </TableCell>
                  </TableRow>
                  {keysToDisplay.map(key => {
                    return (
                      <TableRow key={`inner.${key}`}>
                        <TableCell className={dashboardClasses[`${size}Table`]}>
                          <Translate id={`dashboard.${key}`} />
                        </TableCell>
                        {performanceInfos.map(perfInfo => {
                          const dataItem =
                            perfInfo.type === PerformanceInfoType.PORTFOLIO
                              ? data.main
                              : data[perfInfo.id];
                          return (
                            <TableCell
                              key={`child.${key}.${perfInfo.id}`}
                              className={dashboardClasses[`${size}Table`]}
                              style={{ textAlign: 'right' }}
                            >
                              {dataItem?.[key] ?? '-'}
                            </TableCell>
                          );
                        })}
                        {/* Jus to make a bit of space on the right for edit/delete */}
                        <TableCell className='d-print-none' />
                      </TableRow>
                    );
                  })}
                </Fragment>
              );
            }
            return null;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

KPIPaperTable.propTypes = {
  size: PropTypes.string.isRequired,
};

export default KPIPaperTable;

import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import React from 'react';

import MyDropdown from 'components/MyDropdown/MyDropdown';
import { useTranslate } from 'lib/localization/localization';
import { Id } from 'lib/types';
import { AnalysisDataType } from 'screens/Analysis/lib/constants';
import { AnalysisDataTypeT } from 'screens/Analysis/lib/types';
import { useBacktestsQuery } from 'screens/Backtest/lib/queries';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';

const translationPath = 'portfolioLib.useDataType';

interface UseDataTypeDropdownProps {
  dataType: AnalysisDataTypeT;
  onDataTypeChange: (dataType: AnalysisDataTypeT) => void;
  backtestId: Id | undefined;
  onBacktestChange: (backtestId: Id | undefined) => void;
  portfolioId: Id | undefined;
  disabled?: boolean;
  allowedDataTypes?: AnalysisDataTypeT[];
}

export const useDataTypeDropdowns = ({
  dataType,
  onDataTypeChange,
  backtestId,
  onBacktestChange,
  portfolioId,
  disabled = false,
  allowedDataTypes,
}: UseDataTypeDropdownProps) => {
  const translate = useTranslate();
  const showBacktest = dataType === AnalysisDataType.BACKTEST;
  const { portfolio } = usePortfolioQuery(portfolioId);
  const { backtests, isLoading: isLoadingBacktests } = useBacktestsQuery(
    showBacktest ? portfolioId : undefined
  );
  let availableDataTypes: AnalysisDataTypeT[] = [
    AnalysisDataType.HISTORICAL,
    AnalysisDataType.BACKTEST,
  ];
  if (portfolio?.isTracked) {
    // history is available, if portfolio is Tracked
    availableDataTypes.push(AnalysisDataType.REAL);
  }
  if (allowedDataTypes !== undefined) {
    availableDataTypes = _.intersection(availableDataTypes, allowedDataTypes);
  }
  const handleDataTypeChange = (newDataType: AnalysisDataTypeT) => {
    onDataTypeChange(newDataType);
    if (newDataType !== AnalysisDataType.BACKTEST) {
      onBacktestChange(undefined);
    }
  };

  const dataTypeDropdownElem = (
    <MyDropdown
      // @ts-ignore
      disabled={disabled}
      value={dataType}
      onChange={handleDataTypeChange}
      onUpdateSearch={null}
      translationPath={`${translationPath}.dataTypeDropdown`}
      isNullAllowed={false}
    >
      {availableDataTypes.map(type => (
        <MenuItem key={type} value={type}>
          {translate(`${translationPath}.dataType.${type}`)}
        </MenuItem>
      ))}
    </MyDropdown>
  );
  const backtestDropdownElem = showBacktest ? (
    <MyDropdown
      // @ts-ignore
      disabled={disabled || isLoadingBacktests}
      value={backtestId}
      onChange={onBacktestChange}
      onUpdateSearch={null}
      translationPath={`${translationPath}.backtestsDropdown`}
      isNullAllowed={false}
    >
      {_.values(backtests).map(backtest => (
        <MenuItem key={backtest.id} value={backtest.id}>
          {backtest.name}
        </MenuItem>
      ))}
    </MyDropdown>
  ) : undefined;

  return {
    dataTypeDropdownElem,
    backtestDropdownElem,
  };
};

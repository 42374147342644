import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Button from '@mui/material/Button/index';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';

import { ClassesType } from 'utils/types';

const styles = theme => ({
  root: {
    marginRight: '1rem',
  },
  lowEmp: {
    color: theme.palette.neutral.main,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderWidth: 1,
    '&:hover': {
      borderColor: theme.palette.neutral.main,
    },
    '&:focus': {
      borderColor: theme.palette.neutral.main,
    },
    '&:active': {
      borderColor: theme.palette.neutral.main,
    },
  },
  text: {
    marginRight: '0.5rem',
  },
});

const MyReturnButton = props => {
  const history = useHistory();
  const { classes, translateId, route, onClick, className, ...other } = props;
  function onGoBackClick() {
    history.goBack();
  }

  function getOnClick() {
    if (route) {
      history.push(route);
    } else if (onClick) {
      onClick();
    } else {
      onGoBackClick();
    }
  }

  return (
    <Button
      data-test-id='buttonComponent'
      className={classNames(className, classes.root, classes.lowEmp)}
      /* eslint-disable-next-line react/jsx-no-bind */
      onClick={getOnClick}
      {...other}
    >
      <KeyboardArrowLeftIcon data-test-id='icon' />
      <div className={classes.text}>
        <Translate data-test-id='title' id={translateId} />
      </div>
    </Button>
  );
};

MyReturnButton.propTypes = {
  classes: ClassesType,
  translateId: PropTypes.string.isRequired,
  route: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

MyReturnButton.defaultProps = {
  classes: null,
  route: null,
  onClick: null,
  className: '',
};

export default withStyles(styles, { withTheme: true })(MyReturnButton);

import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import MyTabs from 'components/MyTabs';
import ScreenBase from 'components/ScreenBase';
import useEditPermission from 'lib/access/useEditPermission';
import { useDraftString } from 'lib/misc/useDraft/useDraft';
import { useSavePromptAndDialog } from 'lib/misc/useSavePromptAndDialog';
import { useTabs } from 'lib/navigation/navigation';
import { Id } from 'lib/types';
import { PORTFOLIO_TAB_NAMES, PortfolioTabName } from 'screens/Portfolio/lib/constants';
import { getNumberOfAlerts, isPortfolioTracked } from 'screens/Portfolio/lib/helpers';
import {
  useChangePortfolioNameMutation,
  useDeletePortfolioMutation,
  usePortfolioQuery,
} from 'screens/Portfolio/lib/queries';
import PortfolioAllocationTab from 'screens/Portfolio/PortfolioScreen/components/PortfolioAllocationTab';
import PortfolioOverviewTab from 'screens/Portfolio/PortfolioScreen/components/PortfolioOverviewTab';
import PortfolioPerformanceTab from 'screens/Portfolio/PortfolioScreen/components/PortfolioPeformanceTab';
import PortfolioRebalanceTab from 'screens/Portfolio/PortfolioScreen/components/PortfolioRebalanceTab';
import PortfolioStrategyTab from 'screens/Portfolio/PortfolioScreen/components/PortfolioStrategyTab';
import { Routes } from 'utils/constants';

const translationPath = 'portfolioScreen' as const;

const tabNamesToComponents = {
  [PortfolioTabName.OVERVIEW]: <PortfolioOverviewTab />,
  [PortfolioTabName.STRATEGY]: <PortfolioStrategyTab />,
  [PortfolioTabName.ALLOCATION]: <PortfolioAllocationTab />,
  [PortfolioTabName.PERFORMANCE]: <PortfolioPerformanceTab />,
  [PortfolioTabName.REBALANCE]: <PortfolioRebalanceTab />,
} as const;

const TAB_NAMES_FOR_TRACKED = [PortfolioTabName.PERFORMANCE, PortfolioTabName.REBALANCE] as const;
const requiresTracking = (tabName: typeof PORTFOLIO_TAB_NAMES[number]) =>
  _.indexOf(TAB_NAMES_FOR_TRACKED, tabName) !== -1;

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: '3.3rem',
  },
}));

const PortfolioScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: Id }>();
  const { portfolio } = usePortfolioQuery(id);
  const { draft: draftName, setDraft: setDraftName } = useDraftString(portfolio?.name);

  const hasEditAccess = useEditPermission(portfolio);
  const { mutate: changeName } = useChangePortfolioNameMutation(id);
  const { mutate: deletePortfolio, isLoading: isLoadingDelete } = useDeletePortfolioMutation();
  const { tabIndex, onTabIndexChange, tab, onTabChange } = useTabs(PORTFOLIO_TAB_NAMES);
  useEffect(() => {
    if (portfolio && !isPortfolioTracked(portfolio) && requiresTracking(tab)) {
      onTabChange(PortfolioTabName.OVERVIEW);
    }
  }, [onTabChange, portfolio, tab]);
  const tabs = [
    { translateId: 'overview', badge: 0, 'pendo-id': 'portfolio-screen-overview-tab' },
    { translateId: 'strategy', badge: 0, 'pendo-id': 'portfolio-screen-strategy-tab' },
    { translateId: 'allocation', badge: 0, 'pendo-id': 'portfolio-screen-allocation-tab' },
  ];
  if (portfolio && isPortfolioTracked(portfolio)) {
    tabs.push({
      translateId: 'performance',
      badge: 0,
      'pendo-id': 'portfolio-screen-performance-tab',
    });
    tabs.push({
      translateId: 'rebalance',
      badge: getNumberOfAlerts(portfolio),
      'pendo-id': 'portfolio-screen-rebalance-tab',
    });
  }
  const returnButtonProps = {
    translateId: `${translationPath}.returnButton`,
    route: Routes.CHOOSE_PORTFOLIO,
  };
  const nameTitleProps = {
    name: draftName,
    onRename: setDraftName,
    onConfirm: changeName,
    startWithEditing: false,
    canRename: hasEditAccess,
  };
  const handleDeleteClick = () => {
    if (id) {
      deletePortfolio(id, {
        onSuccess: () => {
          history.push(Routes.CHOOSE_PORTFOLIO);
        },
      });
    }
  };
  const { onDeleteClickWrapper, dialog } = useSavePromptAndDialog(false, false, {
    translationPath,
    onDeleteClick: handleDeleteClick,
  });
  const navButtonsProps = [
    {
      translateId: 'navButton.delete',
      onClick: onDeleteClickWrapper,
      variant: 'midEmp',
      disabled: !hasEditAccess || isLoadingDelete,
    },
  ];
  return (
    <ScreenBase
      titleTranslationId={`${translationPath}.title`}
      returnButtonProps={returnButtonProps}
      nameTitleProps={nameTitleProps}
      object={portfolio}
      navButtonsProps={navButtonsProps}
    >
      {dialog}
      <div className={classes.tabs}>
        <MyTabs
          translationPath={`${translationPath}.tabs`}
          chosenTab={tabIndex}
          onChangeTab={(event: any, value: number) => onTabIndexChange(value)}
          tabsWithBadge={tabs}
        />
      </div>
      <div className={classes.content}>{tabNamesToComponents[tab]}</div>
    </ScreenBase>
  );
};
export default PortfolioScreen;

import { Grid } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';

import { FunctionalityName } from 'lib/access/constants';
import useFunctionalityOn from 'lib/access/useFunctionalityOn';
import { Id } from 'lib/types';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import AlertsCard from 'screens/Portfolio/PortfolioScreen/components/PortfolioOverviewTab/cards/AlertsCard';
import {
  ChildAnalysesCard,
  ChildBacktestsCard,
  ChildReportsCard,
} from 'screens/Portfolio/PortfolioScreen/components/PortfolioOverviewTab/cards/ChildObjectsCard';
import GeneralCard from 'screens/Portfolio/PortfolioScreen/components/PortfolioOverviewTab/cards/GeneralCard';
import MetricsCard from 'screens/Portfolio/PortfolioScreen/components/PortfolioOverviewTab/cards/MetricsCard';
import TopHoldingsCard from 'screens/Portfolio/PortfolioScreen/components/PortfolioOverviewTab/cards/TopHoldingsCard';

const PortfolioOverviewTab = () => {
  const { id: objId } = useParams<{ id: Id }>();
  const { portfolio } = usePortfolioQuery(objId);
  const isAnalysisOn = useFunctionalityOn(FunctionalityName.ANALYSIS);
  const isBacktestingOn = useFunctionalityOn(FunctionalityName.BACKTEST);
  const isReportingOn = useFunctionalityOn(FunctionalityName.REPORTING);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <GeneralCard portfolioId={objId} />
      </Grid>
      {portfolio?.isTracked && (
        <>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <AlertsCard portfolioId={objId} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <MetricsCard portfolioId={objId} />
          </Grid>
        </>
      )}

      <Grid item xs={12} md={6} xl={4}>
        <TopHoldingsCard portfolioId={objId} />
      </Grid>
      {isReportingOn && (
        <Grid item xs={12} md={6} xl={4}>
          <ChildReportsCard portfolioId={objId} />
        </Grid>
      )}
      {isBacktestingOn && (
        <Grid item xs={12} md={6} xl={4}>
          <ChildBacktestsCard portfolioId={objId} />
        </Grid>
      )}

      {isAnalysisOn && (
        <Grid item xs={12} md={6} xl={4}>
          <ChildAnalysesCard portfolioId={objId} />
        </Grid>
      )}
    </Grid>
  );
};
export default PortfolioOverviewTab;

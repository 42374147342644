import { Alert, AlertTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslate } from 'lib/localization/localization';
import { AlertNotification } from 'lib/notifications/types';
import {
  addAlert as addAlertRedux,
  removeAlert as removeAlertRedux,
} from 'redux/notifications/actions';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
  },
}));

type Severity = 'error' | 'warning' | 'info' | 'success';

interface AlertBarProps {
  alert: AlertNotification;
  onClose: () => void;
  severity: Severity;
}

const AlertBar = ({ alert, onClose, severity }: AlertBarProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [cachedAlert, setCachedAlert] = React.useState<AlertNotification | null>(null);

  useEffect(() => {
    if (alert && alert !== cachedAlert) {
      setCachedAlert(alert);
    }
  }, [cachedAlert, alert]);
  if (!cachedAlert) {
    return null;
  }

  return (
    <Alert severity={severity} onClose={onClose} className={classes.root}>
      <AlertTitle>
        {translate(`${severity}Message.${cachedAlert.kind}`, cachedAlert.data)}
      </AlertTitle>
      {translate(`${severity}Details.${cachedAlert.kind}`, cachedAlert.data)}
    </Alert>
  );
};

const useAlertNotification = (severity: Severity) => {
  const dispatch = useDispatch();
  const alert = useSelector((state: any) => state.notifications[severity]);
  const isAlert = Boolean(alert);

  const removeAlert = () => dispatch(removeAlertRedux(severity));
  const addAlert = (a: AlertNotification) => dispatch(addAlertRedux(severity, a));

  const handleAlertBarClose = () => removeAlert();
  const alertBarElem = <AlertBar alert={alert} onClose={handleAlertBarClose} severity={severity} />;

  return {
    alertBarElem,
    isAlert,
    addAlert,
    removeAlert,
  };
};

export const useErrorNotification = () => {
  const { alertBarElem, isAlert, addAlert, removeAlert } = useAlertNotification('error');
  return {
    errorBarElem: alertBarElem,
    isError: isAlert,
    addError: addAlert,
    removeError: removeAlert,
  };
};
export const useSuccessNotification = () => {
  const { alertBarElem, isAlert, addAlert, removeAlert } = useAlertNotification('success');
  return {
    successBarElem: alertBarElem,
    isSuccess: isAlert,
    addSuccess: addAlert,
    removeSuccess: removeAlert,
  };
};

export default useAlertNotification;

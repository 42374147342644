import PropTypes from 'prop-types';
import React from 'react';

import AssetsTable from 'screens/Universe/lib/components/AssetsTable';
import { useBasketQuery, useUniverseQuery } from 'screens/Universe/lib/queries';
import { idArrayType, idType } from 'utils/types';

const BasketTable = ({
  basketId,
  draftUniverseId,
  draftAssetIds,
  dispatchDraftAssetIds,
  disabled,
}) => {
  const { basket } = useBasketQuery(basketId);
  const { universe } = useUniverseQuery(basket?.universe || draftUniverseId);
  const availableAssetIds = universe?.assets || [];
  return (
    <AssetsTable
      dispatchDraftAssetIds={dispatchDraftAssetIds}
      availableAssetIds={availableAssetIds}
      draftAssetIds={draftAssetIds}
      disabled={disabled}
      translatePath='basketScreen'
    />
  );
};

BasketTable.propTypes = {
  basketId: idType,
  draftUniverseId: idType,
  draftAssetIds: idArrayType.isRequired,
  dispatchDraftAssetIds: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

BasketTable.defaultProps = {
  disabled: false,
  basketId: undefined,
  draftUniverseId: undefined,
};

export default BasketTable;

import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ClassesType } from 'utils/types';

const styles = {
  root: {
    display: 'flex',
  },
  horizontal: {
    width: '100%',
  },
  verticalCenter: {
    height: '100%',
    alignItems: 'center',
    // position: 'absolute',
    // top: 0,
  },
  verticalBottom: {
    bottom: 0,
    left: 0,
    position: 'absolute',
  },
};

/*
Vertical "center" position works only for empty parent component
 */

const horizontalPropToJustify = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
};

const PositionMe = ({
  classes,
  children,
  onlyHorizontal,
  onlyVertical,
  howHorizontal,
  howVertical,
}) => (
  <div
    className={classNames(classes.root, {
      [classes.horizontal]: !onlyVertical,
      [classes.verticalBottom]: !onlyHorizontal && howVertical === 'bottom',
      [classes.verticalCenter]: !onlyHorizontal && howVertical === 'center',
    })}
    style={!onlyVertical ? { justifyContent: horizontalPropToJustify[howHorizontal] } : {}}
  >
    <div>{children}</div>
  </div>
);

PositionMe.propTypes = {
  children: PropTypes.node,
  classes: ClassesType.isRequired,
  onlyHorizontal: PropTypes.bool,
  onlyVertical: PropTypes.bool,
  howHorizontal: PropTypes.oneOf(['center', 'left', 'right']),
  howVertical: PropTypes.oneOf(['center', 'bottom']),
};

PositionMe.defaultProps = {
  onlyHorizontal: false,
  onlyVertical: false,
  howHorizontal: 'center',
  howVertical: 'center',
  children: null,
};

export default withStyles(styles)(PositionMe);

import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';

import { deleteWidget } from 'screens/Report/redux/actions';
import WidgetToolbar from 'screens/Report/ReportScreen/components/WidgetToolbar';
import useDashboardStyles from 'screens/Report/ReportScreen/configuration/dashboard.style';
import { widgetComponentPropType } from 'utils/types';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    height: 'inherit',
    width: 'inherit',
  },
  content: {
    pointerEvents: 'none',
    padding: 'none',
  },
});

const TextWidget = ({ widget, setSelectedWidget, setShowSettings, setSettingsType, setLayout }) => {
  const classes = useStyles();
  const dashboardStyles = useDashboardStyles();

  const dispatch = useDispatch();

  const wrapper = useRef();
  return (
    <div className={classNames(classes.wrapper, dashboardStyles.mainCardWrapper)} ref={wrapper}>
      <WidgetToolbar
        onEdit={() => {
          setSettingsType('TextWidget');
          setSelectedWidget(widget);
          setShowSettings(true);
        }}
        onDelete={() => {
          setLayout(prev => prev.filter(item => item.i !== widget.id));
          dispatch(deleteWidget(widget.id));
        }}
      />
      <ReactQuill
        theme={null}
        readOnly
        className={classes.content}
        value={widget?.configuration.text.value}
      />
    </div>
  );
};

TextWidget.propTypes = widgetComponentPropType;

export default TextWidget;

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Card } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React from 'react';

import MyProgress from 'components/MyProgress';
import SimpleIconButton from 'components/SimpleIconButton';
import SimpleText from 'components/SimpleText';

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    overflow: 'auto',
    minHeight: '200px',
    maxHeight: '400px',
    minWidth: '250px',
  },
  header: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: '56px',
  },
  iconButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  mainSection: {
    padding: theme.spacing(4),
    paddingBottom: 0,
    paddingTop: 0,
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  content: {
    padding: theme.spacing(4),
    paddingTop: 0,
  },
}));

interface StrategyCardHeaderProps {
  translateId: string;
  disabled?: boolean;
  onRemoveCard?: () => void;
  onAddCard?: () => void;
}

const StrategyCardHeader = ({
  translateId,
  disabled = false,
  onRemoveCard,
  onAddCard,
}: StrategyCardHeaderProps) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <SimpleText translateId={translateId} variant='h2' />
      {!disabled && (
        <div className={classes.iconButtons}>
          {onRemoveCard ? (
            <SimpleIconButton onClick={onRemoveCard} translateId='discard' size='small'>
              <DeleteOutlineIcon />
            </SimpleIconButton>
          ) : null}
          {onAddCard ? (
            <SimpleIconButton onClick={onAddCard} translateId='addNew' size='small'>
              <AddIcon color='primary' />
            </SimpleIconButton>
          ) : null}
        </div>
      )}
    </div>
  );
};

interface StrategyCardMainSectionProps {
  children?: (JSX.Element | null)[] | JSX.Element | null;
}

export const StrategyCardMainSection = ({ children }: StrategyCardMainSectionProps) => {
  const classes = useStyles();
  return <div className={classes.mainSection}>{children}</div>;
};

export const StrategyCardContent = ({ children }: JSX.ElementChildrenAttribute) => {
  const classes = useStyles();
  return <div className={classes.content}>{children}</div>;
};

interface StrategyCardProps {
  children: JSX.Element[] | JSX.Element;
  className?: string;
  isLoading?: boolean;
  title: string;
  disabled?: boolean;
  onRemoveCard?: () => void;
  onAddCard?: () => void;
}

export const StrategyCard = ({
  children,
  className,
  title,
  isLoading = false,
  disabled,
  onRemoveCard,
  onAddCard,
}: StrategyCardProps) => {
  const classes = useStyles();
  return (
    <Card className={classNames(classes.card, className)}>
      <StrategyCardHeader
        translateId={title}
        disabled={disabled}
        onRemoveCard={onRemoveCard}
        onAddCard={onAddCard}
      />
      {isLoading ? (
        <StrategyCardMainSection>
          <MyProgress />
        </StrategyCardMainSection>
      ) : (
        children
      )}
    </Card>
  );
};

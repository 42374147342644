import _ from 'lodash';

import { PermissionLevel, FunctionalityName } from 'lib/access/constants';
import { ResourceName } from 'lib/constants';

/**
 * Enums
 */

export const RebalancingFrequency = {
  M1: 'M1',
  M3: 'M3',
  M6: 'M6',
  M12: 'M12',
};

export const ALL_REBALANCING_FREQUENCIES = _.keys(RebalancingFrequency);

export const BacktestTimeframes = {
  '1Y': '1Y',
  '3Y': '3Y',
  '5Y': '5Y',
};

export const ALL_BACKTEST_TIMEFRAMES = _.keys(BacktestTimeframes);

export const ConfigurationChip = {
  TARGET_METRIC: 'TARGET_METRIC',
  INVESTMENT_POLICIES: 'INVESTMENT_POLICIES',
  RESEARCH: 'RESEARCH',
  VOLUME: 'VOLUME',
  SIGNALING: 'SIGNALING',
  BASKET: 'BASKET',
};

export const AssetAllocationKind = {
  SECTOR: 'SECTOR',
  SUPER_SECTOR: 'SUPER_SECTOR',
  CLASS: 'CLASS',
  TYPE: 'TYPE',
  CURRENCY: 'CURRENCY',
  COUNTRY: 'COUNTRY',
};

export const TreemapChip = {
  ASSETS: 'ASSETS',
  SECTOR: AssetAllocationKind.SECTOR,
  SUPER_SECTOR: AssetAllocationKind.SUPER_SECTOR,
  CLASS: AssetAllocationKind.CLASS,
  TYPE: AssetAllocationKind.TYPE,
  CURRENCY: AssetAllocationKind.CURRENCY,
  COUNTRY: AssetAllocationKind.COUNTRY,
};

export const DEFAULT_TREEMAP_CHIPS = [
  TreemapChip.ASSETS,
  TreemapChip.CLASS,
  TreemapChip.SUPER_SECTOR,
  TreemapChip.COUNTRY,
];

export const BarChartChip = {
  name: 'NAME',
  assetClass: AssetAllocationKind.CLASS,
  assetType: AssetAllocationKind.TYPE,
  country: AssetAllocationKind.COUNTRY,
  sector: AssetAllocationKind.SECTOR,
  superSector: AssetAllocationKind.SUPER_SECTOR,
};

export const BacktestAllocationChip = {
  assetClass: 'ASSET_CLASSES',
  country: 'COUNTRY',
  superSector: 'SUPER_SECTOR',
};

export const SocketioEvent = {
  OPTIMIZATION_FINISHED: 'OPTIMIZATION_FINISHED',
  OPTIMIZATION_STARTED: 'OPTIMIZATION_STARTED',
  TRANSACTIONS_UPDATE_FINISHED: 'TRANSACTIONS_UPDATE_FINISHED',
  TRANSACTIONS_UPDATE_STARTED: 'TRANSACTIONS_UPDATE_STARTED',
  BACKTEST_FINISHED: 'BACKTEST_FINISHED',
  BACKTEST_STARTED: 'BACKTEST_STARTED',
  BACKTEST_CANCELLED: 'BACKTEST_CANCELLED',
  TS_UPDATE_FINISHED: 'TS_UPDATE_FINISHED',
  TS_UPDATE_STARTED: 'TS_UPDATE_STARTED',
  BASKET_UPDATE_FINISHED: 'BASKET_UPDATE_FINISHED',
  BASKET_UPDATE_STARTED: 'BASKET_UPDATE_STARTED',
  RESEARCH_LIST_UPDATE_FINISHED: 'RESEARCH_LIST_UPDATE_FINISHED',
  RESEARCH_LIST_UPDATE_STARTED: 'RESEARCH_LIST_UPDATE_STARTED',
  RESEARCH_CONS_LIST_UPDATE_FINISHED: 'RESEARCH_CONS_LIST_UPDATE_FINISHED',
  RESEARCH_CONS_LIST_UPDATE_STARTED: 'RESEARCH_CONS_LIST_UPDATE_STARTED',
  UNIVERSE_UPDATE_FINISHED: 'UNIVERSE_UPDATE_FINISHED',
  UNIVERSE_UPDATE_STARTED: 'UNIVERSE_UPDATE_STARTED',
  ACTUALIZATION_STARTED: 'ACTUALIZATION_STARTED',
  ACTUALIZATION_FINISHED: 'ACTUALIZATION_FINISHED',
  GROUP_CONS_LIST_UPDATE_STARTED: 'GROUP_CONS_LIST_UPDATE_STARTED',
  GROUP_CONS_LIST_UPDATE_FINISHED: 'GROUP_CONS_LIST_UPDATE_FINISHED',
  PORTFOLIO_ANALYSIS_STARTED: 'PORTFOLIO_ANALYSIS_STARTED',
  PORTFOLIO_ANALYSIS_FINISHED: 'PORTFOLIO_ANALYSIS_FINISHED',
  PORTFOLIO_ANALYSIS_PARTIAL: 'PORTFOLIO_ANALYSIS_PARTIAL',
  OBJECTS_UPDATED: 'OBJECTS_UPDATED',
  USER_INVITATION_SENT: 'USER_INVITATION_SENT',
};

export const PortfolioJobStatus = {
  IN_QUEUE: 'IN_QUEUE',
  TO_START: 'TO_START',
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
  CANCELLED: 'CANCELLED',
};

export const RUNNING_JOB_STATUSES = [
  PortfolioJobStatus.IN_QUEUE,
  PortfolioJobStatus.TO_START,
  PortfolioJobStatus.RUNNING,
];

export const SmallNotification = {
  OPTIMIZATION_FINISHED: 'OPTIMIZATION_FINISHED',
  BACKTEST_FINISHED: 'BACKTEST_FINISHED',
  ANALYSIS_FINISHED: 'ANALYSIS_FINISHED',
  OBJECT_CREATED: 'OBJECT_CREATED',
  OBJECT_SAVED: 'OBJECT_SAVED',
  OBJECT_DELETED: 'OBJECT_DELETED',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  UNIVERSE_SAVED: 'UNIVERSE_SAVED',
  UNIVERSE_CREATED: 'UNIVERSE_CREATED',
  PORTFOLIO_SAVED: 'PORTFOLIO_SAVED',
  PORTFOLIO_CREATED: 'PORTFOLIO_CREATED',
  INCORRECT_LOGIN_DATA: 'INCORRECT_LOGIN_DATA',
  BASKET_SAVED: 'BASKET_SAVED',
  BASKET_CREATED: 'BASKET_CREATED',
  INSTITUTE_SAVED: 'INSTITUTE_SAVED',
  TS_UPDATE_TASK_INVOKED: 'TS_UPDATE_TASK_INVOKED',
  INSTITUTE_CREATED: 'INSTITUTE_CREATED',
  EMPTY_NAME: 'EMPTY_NAME',
  ASSET_CREATED: 'ASSET_CREATED',
  ASSET_EXISTS: 'ASSET_EXISTS',
  PORTFOLIO_DELETED: 'PORTFOLIO_DELETED',
  BACKTEST_SAVED: 'BACKTEST_SAVED',
  BACKTEST_CREATED: 'BACKTEST_CREATED',
  BACKTEST_DELETED: 'BACKTEST_DELETED',
  ANALYSIS_SAVED: 'ANALYSIS_SAVED',
  ANALYSIS_CREATED: 'ANALYSIS_CREATED',
  ANALYSIS_DELETED: 'ANALYSIS_DELETED',
  ACTION_SENT: 'ACTION_SENT',
  BASKET_DELETED: 'BASKET_DELETED',
  UNIVERSE_DELETED: 'UNIVERSE_DELETED',
  USER_GROUP_DELETED: 'USER_GROUP_DELETED',
  USER_GROUP_SAVED: 'USER_GROUP_SAVED',
  USER_GROUP_CREATED: 'USER_GROUP_CREATED',
  USER_DELETED: 'USER_DELETED',
  USER_SAVED: 'USER_SAVED',
  USER_CREATED: 'USER_CREATED',
  PORTFOLIO_UNTRACKED: 'PORTFOLIO_UNTRACKED',
  PORTFOLIO_TRACKED: 'PORTFOLIO_TRACKED',
  NOT_FAVORITE: 'NOT_FAVORITE',
  IS_FAVORITE: 'IS_FAVORITE',
  NAME_CHANGED: 'NAME_CHANGED',
  ASSET_REPLACED: 'ASSET_REPLACED',
  NEW_PORTFOLIO_DISCARDED: 'NEW_PORTFOLIO_DISCARDED',
  NEW_PORTFOLIO_APPLIED: 'NEW_PORTFOLIO_APPLIED',
  CLOSEST_PORTFOLIO_APPLIED: 'CLOSEST_PORTFOLIO_APPLIED',
  FEEDBACK_SENT: 'FEEDBACK_SENT',
  ACCOUNT_NOT_APPROVED: 'ACCOUNT_NOT_APPROVED',
  ACCOUNT_EXPIRED: 'ACCOUNT_EXPIRED',
  RESEARCH_LIST_CREATED: 'RESEARCH_LIST_CREATED',
  RESEARCH_LIST_DELETED: 'RESEARCH_LIST_DELETED',
  RESEARCH_LIST_SAVED: 'RESEARCH_LIST_SAVED',
  RESEARCH_CONSTRAINT_LIST_CREATED: 'RESEARCH_CONSTRAINT_LIST_CREATED',
  RESEARCH_CONSTRAINT_LIST_DELETED: 'RESEARCH_CONSTRAINT_LIST_DELETED',
  RESEARCH_CONSTRAINT_LIST_SAVED: 'RESEARCH_CONSTRAINT_LIST_SAVED',
  PORTFOLIO_IS_VIRTUAL: 'PORTFOLIO_IS_VIRTUAL',
  PORTFOLIO_IS_REAL: 'PORTFOLIO_IS_REAL',
  DELETE_LATEST_PORTFOLIO_UPLOAD: 'DELETE_LATEST_PORTFOLIO_UPLOAD',
  GROUP_CONSTRAINT_CREATED: 'GROUP_CONSTRAINT_CREATED',
  GROUP_CONSTRAINT_DELETED: 'GROUP_CONSTRAINT_DELETED',
  GROUP_CONSTRAINT_SAVED: 'GROUP_CONSTRAINT_SAVED',
  GROUP_CONSTRAINT_EMPTY_NAME: 'GROUP_CONSTRAINT_EMPTY_NAME',
  REPORT_CREATED: 'REPORT_CREATED',
  REPORT_DELETED: 'REPORT_DELETED',
  REPORT_UPDATED: 'REPORT_UPDATED',
  ASSET_DELETED: 'ASSET_DELETED',
  ASSETS_DELETED: 'ASSETS_DELETED',
  ASSET_UPDATED: 'ASSET_UPDATED',
  ACCESS_UPDATED: 'ACCESS_UPDATED',
  USER_INVITATION_SENT: 'USER_INVITATION_SENT',
};

export const PortfolioChange = {
  UNSAVED_PORTFOLIO: 'UNSAVED_PORTFOLIO',
  UNSAVED_NAME: 'UNSAVED_NAME',
  UNSAVED_NEW: 'UNSAVED_NEW',
  UNSAVED_CONFIGS: 'UNSAVED_CONFIGS',
  UNSAVED_NEW_CONFIGS: 'UNSAVED_NEW_CONFIGS',
};

export const Dialogs = {
  PORTFOLIO_NAME_NOT_SAVED: 'PORTFOLIO_NAME_NOT_SAVED',
  PORTFOLIO_NOT_SAVED: 'PORTFOLIO_NOT_SAVED',
  PORTFOLIO_NEW_NOT_SAVED: 'PORTFOLIO_NEW_NOT_SAVED',
  PORTFOLIO_CONFIGS_NOT_SAVED: 'PORTFOLIO_CONFIGS_NOT_SAVED',
  OVERWRITE_PORTFOLIO_CONFIG_TRACKED: 'OVERWRITE_PORTFOLIO_CONFIG_TRACKED',
  OVERWRITE_PORTFOLIO_CONFIG_UNTRACKED: 'OVERWRITE_PORTFOLIO_CONFIG_UNTRACKED',
  NEW_VERSION: 'NEW_VERSION',
  EXPORT_WEIGHTS: 'EXPORT_WEIGHTS',
  PORTFOLIO_TRACK_OLD_PORTFOLIO: 'PORTFOLIO_TRACK_OLD_PORTFOLIO',
  PORTFOLIO_UNTRACK: 'PORTFOLIO_UNTRACK',
  FEEDBACK: 'FEEDBACK',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',
  REGISTER_FAILURE_CONFLICT: 'REGISTER_FAILURE_CONFLICT',
  SWITCH_TO_VIRTUAL_PORTFOLIO: 'SWITCH_TO_VIRTUAL_PORTFOLIO',
  SWITCH_TO_REAL_PORTFOLIO: 'SWITCH_TO_REAL_PORTFOLIO',
  ADD_OR_WITHDRAW_CASH: 'ADD_OR_WITHDRAW_CASH',
  REPORT_NOT_SAVED: 'REPORT_NOT_SAVED',
  REPORT_CHANGES_NOT_SAVED: 'REPORT_CHANGES_NOT_SAVED',
  UPLOAD_FILE_HAS_ISSUES: 'UPLOAD_FILE_HAS_ISSUES',
  UNTRACK_PORTFOLIO: 'UNTRACK_PORTFOLIO',
};

export const DialogKeys = {
  CONTINUE: 'CONTINUE',
  CANCEL: 'CANCEL',
  DELAY: 'DELAY',
  QUIT: 'QUIT',
  KEEP_CREATING: 'KEEP_CREATING',
  DISCARD_AND_LEAVE: 'DISCARD_AND_LEAVE',
  REOPTIMIZE: 'REOPTIMIZE',
  KEEP_EDITING: 'KEEP_EDITING',
  SAVE: 'SAVE',
  DISCARD: 'DISCARD',
  DELETE: 'DELETE',
  UPDATE_VERSION: 'UPDATE_VERSION',
  EXPORT: 'EXPORT',
  NO: 'NO',
  YES: 'YES',
  YES_DELETE: 'YES_DELETE',
  OVERWRITE: 'OVERWRITE',
  SEND: 'SEND',
  CLOSE: 'CLOSE',
  CREATE: 'CREATE',
  CONFIRM: 'CONFIRM',
  ADD_OR_WITHDRAW: 'ADD_OR_WITHDRAW',
  PRINT: 'PRINT',
};

export const ErrorOrigin = {
  API: 'API',
  OPTIMIZATION: 'OPTIMIZATION',
  BACKTEST: 'BACKTEST',
  NETWORK: 'NETWORK',
  DELETE_BASKET: 'DELETE_BASKET',
  DELETE_RESEARCH_LIST: 'DELETE_RESEARCH_LIST',
  INPUT: 'INPUT',
};

export const ErrorKind = {
  TEST: 'TEST',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  UNKNOWN_PORTFOLIO_WARNING: 'UNKNOWN_PORTFOLIO_WARNING',
  UNKNOWN_BACKTEST_WARNING: 'UNKNOWN_BACKTEST_WARNING',
  UNAUTHORIZED: 'UNAUTHORIZED',
  AWAITING_REGISTRATION: 'AWAITING_REGISTRATION',
  USER_EXPIRED: 'USER_EXPIRED',
  NOT_FOUND: 'NOT_FOUND',
  FORBIDDEN: 'FORBIDDEN',
  EXISTING_PORTFOLIOS_ATTACHED: 'EXISTING_PORTFOLIOS_ATTACHED',
  INVALID_SCHEMA_FORMAT: 'INVALID_SCHEMA_FORMAT',
  OPTIMIZATION_IN_PROCESS: 'OPTIMIZATION_IN_PROCESS',
  RESOURCE_NOT_EXISTS: 'RESOURCE_NOT_EXISTS',
  BACKTEST_IN_PROCESS: 'BACKTEST_IN_PROCESS',
  BASKET_UPDATE_IN_PROCESS: 'BASKET_UPDATE_IN_PROCESS',
  BASKET_PORTFOLIO_IN_PROCESS: 'BASKET_PORTFOLIO_IN_PROCESS',
  NEW_BASKET_DIFFERENT_UNIVERSE: 'NEW_BASKET_DIFFERENT_UNIVERSE',
  DB_INTEGRITY_ERROR: 'DB_INTEGRITY_ERROR',
  MORE_THAN_ONE_BACKTEST_CONFIG: 'MORE_THAN_ONE_BACKTEST_CONFIG',
  ASSETS_NOT_IN_UNIVERSE: 'ASSETS_NOT_IN_UNIVERSE',
  BASKET_NOT_IN_UNIVERSE: 'BASKET_NOT_IN_UNIVERSE',
  NO_OPTIMAL_PORTFOLIO: 'NO_OPTIMAL_PORTFOLIO',
  NO_OPTIMAL_PORTFOLIO_AT_DATE: 'NO_OPTIMAL_PORTFOLIO_AT_DATE',
  TOO_MANY_QUEUED: 'TOO_MANY_QUEUED',
  CRITICAL_ERROR: 'CRITICAL_ERROR',
  TARGET_CORRIDOR_SMALL: 'TARGET_CORRIDOR_SMALL',
  UNKNOWN_UPLOAD_FILE_ERROR: 'UNKNOWN_UPLOAD_FILE_ERROR',
  POSITION_CONFLICT_WITH_PREVIOUS_POSITION: 'POSITION_CONFLICT_WITH_PREVIOUS_POSITION',
  POSITION_CONFLICT_WITH_PREVIOUS_UPLOAD: 'POSITION_CONFLICT_WITH_PREVIOUS_UPLOAD',
  EMPTY_IDENTIFIER_COLUMNS: 'EMPTY_IDENTIFIER_COLUMNS',
};

export const WarningKind = {
  ONLY_CLOSEST_AVAILABLE: 'ONLY_CLOSEST_AVAILABLE',
};

export const Routes = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot_password',
  CHANGE_PASSWORD: '/reset_password',
  HOME: '/portfolios',
  SETTINGS_INSTITUTE: '/settings/institute',
  UNIVERSE: '/universe/:id',
  NEW_UNIVERSE: '/universe',
  CHOOSE_UNIVERSE: '/universes',
  USER_GROUP: '/user_group/:id',
  NEW_USER_GROUP: '/user_group',
  CHOOSE_USER_GROUP: '/user_groups',
  BASKET: '/basket/:id',
  NEW_BASKET: '/basket',
  CHOOSE_BASKET: '/baskets',
  CHOOSE_PORTFOLIO: '/portfolios',
  PORTFOLIO: '/portfolio/:id',
  NEW_PORTFOLIO: '/portfolio',
  PORTFOLIO_RECONFIGURATION: '/portfolio/:id/reconfiguration',
  CHOOSE_BACKTEST: '/backtests',
  CHOOSE_BACKTEST_WITH_PORTFOLIO: '/portfolio/:portfolioId/backtests',
  BACKTEST_WITH_PORTFOLIO: '/portfolio/:portfolioId/backtest/:id',
  NEW_BACKTEST: '/portfolio/:portfolioId/backtest',
  CHOOSE_ANALYSIS: '/analyses',
  CHOOSE_ANALYSIS_WITH_PORTFOLIO: '/portfolio/:portfolioId/analyses',
  ANALYSIS: '/portfolio/:portfolioId/analysis/:id',
  NEW_ANALYSIS: '/portfolio/:portfolioId/analysis',
  CHOOSE_REPORT: '/reports',
  CHOOSE_REPORT_WITH_PORTFOLIO: '/portfolio/:portfolioId/reports',
  REPORT: '/portfolio/:portfolioId/report/:id',
  NEW_REPORT: '/portfolio/:portfolioId/report',
  ABOUT: '/about',
  TEST: '/test',
  TEST_ADMIN: '/testAdmin',
  ADMIN_ASSETS: '/assets',
  ADMIN_INSTITUTES: '/institutes',
  ADMIN_INSTITUTE: '/institute/:id',
  ADMIN_NEW_INSTITUTE: '/institute',
  ADMIN_USERS: '/users',
  ADMIN_USER: '/user/:id',
  ADMIN_NEW_USER: '/user',
  NO_ACCESS: '/noAccess',
  HELP: '/help',
  REGISTER: '/register',
  CHOOSE_RESEARCH_LIST: '/researchLists',
  NEW_RESEARCH_LIST: '/researchList',
  RESEARCH_LIST: '/researchList/:id',
  CHOOSE_RESEARCH_CONSTRAINT_LIST: '/researchPresets',
  NEW_RESEARCH_CONSTRAINT_LIST: '/researchPreset',
  RESEARCH_CONSTRAINT_LIST: '/researchPreset/:id',
  CHOOSE_GROUP_CONSTRAINT_LIST: '/allocationPresets',
  NEW_GROUP_CONSTRAINT_LIST: '/allocationPreset',
  GROUP_CONSTRAINT_LIST: '/allocationPreset/:id',
};

export const REQUIRED_PERMISSIONS_FOR_ROUTE = {
  [Routes.CHOOSE_PORTFOLIO]: { resourceName: ResourceName.PORTFOLIO },
  [Routes.PORTFOLIO]: { resourceName: ResourceName.PORTFOLIO },
  [Routes.NEW_PORTFOLIO]: {
    resourceName: ResourceName.PORTFOLIO,
    permissionLevel: PermissionLevel.EDIT,
  },
  [Routes.PORTFOLIO_RECONFIGURATION]: {
    resourceName: ResourceName.PORTFOLIO,
    permissionLevel: PermissionLevel.EDIT,
  },

  [Routes.NEW_BACKTEST]: {
    resourceName: ResourceName.PORTFOLIO,
    permissionLevel: PermissionLevel.EDIT,
  },
  [Routes.CHOOSE_BACKTEST]: { resourceName: ResourceName.PORTFOLIO },
  [Routes.CHOOSE_BACKTEST_WITH_PORTFOLIO]: { resourceName: ResourceName.PORTFOLIO },
  [Routes.BACKTEST_WITH_PORTFOLIO]: { resourceName: ResourceName.PORTFOLIO },

  [Routes.NEW_ANALYSIS]: {
    resourceName: ResourceName.PORTFOLIO,
    permissionLevel: PermissionLevel.EDIT,
  },
  [Routes.CHOOSE_ANALYSIS]: { resourceName: ResourceName.PORTFOLIO },
  [Routes.CHOOSE_ANALYSIS_WITH_PORTFOLIO]: { resourceName: ResourceName.PORTFOLIO },
  [Routes.ANALYSIS]: { resourceName: ResourceName.PORTFOLIO },

  [Routes.BASKET]: { resourceName: ResourceName.BASKET },
  [Routes.CHOOSE_BASKET]: { resourceName: ResourceName.BASKET },
  [Routes.NEW_BASKET]: {
    resourceName: ResourceName.BASKET,
    permissionLevel: PermissionLevel.EDIT,
  },

  [Routes.UNIVERSE]: {
    resourceName: ResourceName.UNIVERSE,
    permissionLevel: PermissionLevel.EDIT,
  },
  [Routes.NEW_UNIVERSE]: {
    resourceName: ResourceName.UNIVERSE,
    permissionLevel: PermissionLevel.EDIT,
  },
  [Routes.CHOOSE_UNIVERSE]: {
    resourceName: ResourceName.UNIVERSE,
    permissionLevel: PermissionLevel.EDIT,
  },

  [Routes.USER_GROUP]: {
    resourceName: ResourceName.USER_GROUP,
    permissionLevel: PermissionLevel.EDIT,
  },
  [Routes.NEW_USER_GROUP]: {
    resourceName: ResourceName.USER_GROUP,
    permissionLevel: PermissionLevel.EDIT,
  },
  [Routes.CHOOSE_USER_GROUP]: {
    resourceName: ResourceName.USER_GROUP,
    permissionLevel: PermissionLevel.EDIT,
  },
  [Routes.SETTINGS_INSTITUTE]: {
    resourceName: ResourceName.INSTITUTE,
    permissionLevel: PermissionLevel.READ,
  },
  [Routes.ADMIN_ASSETS]: {
    resourceName: ResourceName.ASSET,
    permissionLevel: PermissionLevel.ADMIN,
    isGeneralRole: true,
  },
  [Routes.ADMIN_INSTITUTES]: {
    resourceName: ResourceName.INSTITUTE,
    permissionLevel: PermissionLevel.ADMIN,
    isGeneralRole: true,
  },
  [Routes.ADMIN_USERS]: {
    resourceName: ResourceName.USER,
    permissionLevel: PermissionLevel.ADMIN,
    isGeneralRole: true,
  },

  [Routes.CHOOSE_RESEARCH_LIST]: { resourceName: ResourceName.RESEARCH_LIST },
  [Routes.RESEARCH_LIST]: { resourceName: ResourceName.RESEARCH_LIST },
  [Routes.NEW_RESEARCH_LIST]: {
    resourceName: ResourceName.RESEARCH_LIST,
    permissionLevel: PermissionLevel.EDIT,
  },

  [Routes.CHOOSE_RESEARCH_CONSTRAINT_LIST]: { resourceName: ResourceName.RESEARCH_CONSTRAINT_LIST },
  [Routes.RESEARCH_CONSTRAINT_LIST]: { resourceName: ResourceName.RESEARCH_CONSTRAINT_LIST },
  [Routes.NEW_RESEARCH_CONSTRAINT_LIST]: {
    resourceName: ResourceName.RESEARCH_CONSTRAINT_LIST,
    permissionLevel: PermissionLevel.EDIT,
  },

  [Routes.CHOOSE_GROUP_CONSTRAINT_LIST]: { resourceName: ResourceName.GROUP_CONSTRAINT_LIST },
  [Routes.GROUP_CONSTRAINT_LIST]: { resourceName: ResourceName.GROUP_CONSTRAINT_LIST },
  [Routes.NEW_GROUP_CONSTRAINT_LIST]: {
    resourceName: ResourceName.GROUP_CONSTRAINT_LIST,
    permissionLevel: PermissionLevel.EDIT,
  },

  [Routes.NEW_REPORT]: {
    resourceName: ResourceName.PORTFOLIO,
    permissionLevel: PermissionLevel.EDIT,
  },
  [Routes.CHOOSE_REPORT_WITH_PORTFOLIO]: { resourceName: ResourceName.PORTFOLIO },
  [Routes.CHOOSE_REPORT]: { resourceName: ResourceName.PORTFOLIO },
  [Routes.REPORT]: { resourceName: ResourceName.PORTFOLIO },
};

export const REQUIRED_FUNCTIONALITY_FOR_ROUTE = {
  [Routes.BACKTEST_WITH_PORTFOLIO]: FunctionalityName.BACKTEST,
  [Routes.CHOOSE_BACKTEST_WITH_PORTFOLIO]: FunctionalityName.BACKTEST,
  [Routes.NEW_BACKTEST]: FunctionalityName.BACKTEST,
  [Routes.CHOOSE_BACKTEST]: FunctionalityName.BACKTEST,

  [Routes.CHOOSE_RESEARCH_LIST]: FunctionalityName.RESEARCH,
  [Routes.RESEARCH_LIST]: FunctionalityName.RESEARCH,
  [Routes.NEW_RESEARCH_LIST]: FunctionalityName.RESEARCH,

  [Routes.CHOOSE_RESEARCH_CONSTRAINT_LIST]: FunctionalityName.RESEARCH,
  [Routes.RESEARCH_CONSTRAINT_LIST]: FunctionalityName.RESEARCH,
  [Routes.NEW_RESEARCH_CONSTRAINT_LIST]: FunctionalityName.RESEARCH,

  [Routes.NEW_REPORT]: FunctionalityName.REPORTING,
  [Routes.CHOOSE_REPORT_WITH_PORTFOLIO]: FunctionalityName.REPORTING,
  [Routes.CHOOSE_REPORT]: FunctionalityName.REPORTING,
  [Routes.REPORT]: FunctionalityName.REPORTING,

  [Routes.NEW_ANALYSIS]: FunctionalityName.ANALYSIS,
  [Routes.CHOOSE_ANALYSIS]: FunctionalityName.ANALYSIS,
  [Routes.CHOOSE_ANALYSIS_WITH_PORTFOLIO]: FunctionalityName.ANALYSIS,
  [Routes.ANALYSIS]: FunctionalityName.ANALYSIS,
};

export const RESOURCE_NAME_TO_REDUX_NAME = {
  basket: 'baskets',
  universe: 'universes',
  research_list: 'researchLists',
  research_constraint_list: 'researchConstraintLists',
  group_constraint_list: 'groupConstraints',
  report_template: 'reportTemplates',
  report: 'reports',
  portfolio_info: 'portfolios',
  user_group: 'userGroups',
};

export const AssetGroupKind = {
  CLASS: 'CLASS',
  INDUSTRY: 'INDUSTRY',
  COUNTRY: 'COUNTRY',
  SUPER_SECTOR: 'SUPER_SECTOR',
  SECTOR: 'SECTOR',
  TYPE: 'TYPE',
  CURRENCY: 'CURRENCY',
};

export const UniverseBasketTab = {
  BASKET: 1,
  UNIVERSE: 0,
};

export const NAVBAR_HEIGHT_PX = 64;
export const NAVBAR_HEIGHT = `${NAVBAR_HEIGHT_PX}px`;
export const SIDE_MENU_WIDTH = '17rem';
export const PORTFOLIO_CARD_HEIGHT = '300px';

export const BenchmarkType = {
  ASSET: 'ASSET',
  PORTFOLIO: 'PORTFOLIO',
};

export const Order = {
  DESC: 'desc',
  ASC: 'asc',
};

export const PortfolioAllocationType = {
  VIRTUAL: 'VIRTUAL',
  REAL: 'REAL',
  OPTIM: 'OPTIM',
};

export const PortfolioAction = {
  ROLLBACK_CONFIG: 'ROLLBACK_CONFIG',
  CANCEL_OPTIMIZATION: 'CANCEL_OPTIMIZATION',
  CANCEL_BACKTEST: 'CANCEL_BACKTEST',
  TRACK: 'TRACK',
  REBALANCE: 'REBALANCE',
  CANCEL_TRACK: 'CANCEL_TRACK',
  CANCEL_REBALANCE: 'CANCEL_REBALANCE',
  DISCARD_NEW: 'DISCARD_NEW',
  APPLY_NEW: 'APPLY_NEW',
  APPLY_CLOSEST: 'APPLY_CLOSEST',
  SWITCH_TO_REAL: 'SWITCH_TO_REAL',
  SWITCH_TO_VIRTUAL: 'SWITCH_TO_VIRTUAL',
  DELETE_LATEST_UPLOAD: 'DELETE_LATEST_UPLOAD',
};

export const DEFAULT_CSV_DELIMITER = ';';

export const ResearchListsTabsAdmin = {
  FILE_UPLOAD: 0,
  COLUMN_LABELS: 1,
  PRESETS: 2,
};

export const ResearchListsTabsUser = {
  FILE_UPLOAD: 0,
  PRESETS: 1,
};

export const TipsAndTricks = {
  ComingSoon: 'ComingSoon',
};

export const UserStatus = {
  APPROVED: 'APPROVED',
  AWAITING: 'AWAITING',
};

export const CashTransactionAction = {
  ADD: 'ADD',
  WITHDRAW: 'WITHDRAW',
};

import { Add } from '@mui/icons-material';
import { IconButton, Chip, Menu, MenuItem } from '@mui/material';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';

import SimpleText from 'components/SimpleText';
import { getTextColor } from 'lib/theming/utils';

const useChipSelection = defaultColumns => {
  const [columns, setColumns] = useState(defaultColumns);
  useEffect(() => {
    setColumns(defaultColumns);
  }, [defaultColumns]);

  const controlId = nanoid();
  const checkedCount = columns.filter(column => column.checked).length;
  const handleDelete = column =>
    checkedCount > 0
      ? setColumns(prev =>
          prev.map(item => {
            if (item.key === column.key) {
              return { ...item, checked: false };
            }
            return item;
          })
        )
      : null;

  const Component = ({
    translateSource,
    label,
    addDisabled,
    addColor,
    useLabelWithoutTranslate,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    return (
      <>
        {label ? (
          <SimpleText translateId={`${translateSource}.${label}`} fontWeight='bold' my={4} />
        ) : null}
        <IconButton onClick={e => setAnchorEl(e.currentTarget)} disabled={addDisabled} size='large'>
          <Add color={addColor} aria-controls={controlId} aria-haspopup='true' />
        </IconButton>
        {columns
          .filter(column => column.checked)
          .map(column => (
            <Chip
              style={{
                margin: '0.4rem',
                backgroundColor: column.color || '#d9dada',
                color: getTextColor(column.color || '#d9dada'),
              }}
              key={column.key}
              label={
                !useLabelWithoutTranslate ? (
                  <Translate id={`${translateSource}.${column.label}`} />
                ) : (
                  column.label
                )
              }
              onDelete={column.removable ? () => handleDelete(column) : null}
            />
          ))}
        <Menu
          id={controlId}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {columns
            .filter(column => !column.checked)
            .map(column => (
              <MenuItem
                key={column.key}
                onClick={() => {
                  setColumns(prev =>
                    prev.map(item => {
                      if (item.key === column.key) {
                        return {
                          ...item,
                          checked: true,
                        };
                      }
                      return item;
                    })
                  );
                  setAnchorEl(null);
                }}
              >
                {!useLabelWithoutTranslate ? (
                  <Translate id={`${translateSource}.${column.label}`} />
                ) : (
                  column.label
                )}
              </MenuItem>
            ))}
        </Menu>
      </>
    );
  };

  Component.propTypes = {
    translateSource: PropTypes.string.isRequired,
    useLabelWithoutTranslate: PropTypes.bool,
    label: PropTypes.string,
    addDisabled: PropTypes.bool,
    addColor: PropTypes.string,
  };

  Component.defaultProps = {
    label: '',
    addDisabled: false,
    useLabelWithoutTranslate: false,
    addColor: 'primary',
  };

  return [Component, columns];
};

export default useChipSelection;

import PropTypes from 'prop-types';
import React from 'react';
import { SizeMe } from 'react-sizeme';

import MySimpleLineChartBase from './MySimpleLineChartBase';

import { ChartDataType } from 'utils/types';

const MySimpleLineChartWrapper = ({ data, padding, height, YAxisTitle }) => (
  <SizeMe monitorWidth>
    {({ size }) => (
      <div>
        <MySimpleLineChartBase
          width={size.width - padding}
          data={data}
          height={height}
          YAxisTitle={YAxisTitle}
        />
      </div>
    )}
  </SizeMe>
);

MySimpleLineChartWrapper.propTypes = {
  data: ChartDataType.isRequired,
  padding: PropTypes.number,
  height: PropTypes.number,
  YAxisTitle: PropTypes.string,
};

MySimpleLineChartWrapper.defaultProps = {
  padding: 30,
  height: 150,
  YAxisTitle: null,
};

export default MySimpleLineChartWrapper;

// for chart on portfolio card
import 'static/styles/chartStyle.css';

import React from 'react';
import { LocalizeProvider } from 'react-localize-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';

import { configureStore } from '../../redux';

import App from 'components/Global/App';
import { isDevelopmentEnv } from 'lib/misc/misc';

const store = configureStore();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // globally default to 60 seconds
      // staleTime: Infinity,
      staleTime: 1000 * 60,
      retry: 1,
    },
  },
});

// temporary hack to make SearchBar work in MenuList (e.g. Select drop-down list)
document.addEventListener(
  'keydown',
  e => {
    if (e.key !== 'Escape') {
      e.stopImmediatePropagation();
    }
  },
  false
);

const Root = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <LocalizeProvider store={store}>
          <App />
        </LocalizeProvider>
      </Provider>
      {isDevelopmentEnv() ? (
        <ReactQueryDevtools initialIsOpen panelProps={{ style: { color: '#ff3860' } }} />
      ) : null}
    </QueryClientProvider>
  );
};

export default Root;

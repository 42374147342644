export const TREEMAP_TYPE = 'treemap';
export const PIECHART_TYPE = 'sunburst';

export function defineNumColors(data, commonParentId = '') {
  let numColors = 0;
  let indexParentless = null;
  data.parents.forEach((parentId, index) => {
    if (parentId === commonParentId) {
      numColors += 1;
      indexParentless = index;
    }
  });
  if (numColors === 1) {
    const newCommonParentId = data.ids[indexParentless];
    return defineNumColors(data, newCommonParentId);
  }
  if (numColors === 0) {
    return 7;
  }
  return numColors;
}

export function makeData(
  chartType,
  priority,
  weights,
  assets,
  assetGroups,
  assetAllocations,
  noLeafs = false
) {
  const ids = [];
  const labels = [];
  const parents = [];
  const valuesDict = {};
  weights.forEach(weightObj => {
    if (weightObj.weight === 0) {
      return;
    }
    const asset = assets[weightObj.assetId];
    const groupIdsForSunburst = [];
    let groupSunburstId = '';
    let previousGroupSunburstId = null;
    priority.forEach(assetAllocId => {
      for (const assetGroupId of assetAllocations[assetAllocId].assetGroups) {
        if (asset.assetGroups.includes(assetGroupId)) {
          previousGroupSunburstId = groupSunburstId;
          groupIdsForSunburst.push(assetGroupId);
          groupSunburstId = `Group-${groupIdsForSunburst.join('-')}`;
          if (!ids.includes(groupSunburstId)) {
            ids.push(groupSunburstId);
            labels.push(assetGroups[assetGroupId].name);
            parents.push(previousGroupSunburstId);
            valuesDict[groupSunburstId] = weightObj.weight;
          } else {
            valuesDict[groupSunburstId] += weightObj.weight;
          }
          break;
        }
      }
    });
    if (!noLeafs) {
      const assetSunburstId = `Asset-${asset.id}`;
      ids.push(assetSunburstId);
      labels.push(asset.name);
      parents.push(groupSunburstId);
      valuesDict[assetSunburstId] = weightObj.weight;
    }
  });
  const values = [];
  ids.forEach((sunburstId, index) => {
    values.push(valuesDict[sunburstId] * 100);
    labels[index] = `${labels[index]} ${values[index].toFixed(2)}%`;
    if (chartType === TREEMAP_TYPE && parents[index] === '') {
      parents[index] = 'Portfolio';
    }
  });
  if (chartType === TREEMAP_TYPE) {
    ids.push('Portfolio');
    labels.push('            Portfolio 100%');
    values.push(100);
    parents.push('');
  }

  const data = {
    ids,
    labels,
    parents,
    values,
  };
  return data;
}

import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import React from 'react';

import SimpleText from 'components/SimpleText';
import { useTranslate } from 'lib/localization/localization';

const useStyles = makeStyles(theme => ({
  chart: {
    height: '90%',
    width: '100%',
  },
  legend: {
    height: '10%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  legendItem: {
    paddingLeft: theme.spacing(8),

    maxWidth: '20%',
  },
  legendText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

interface ReturnBreakdownBarChartProps {
  data: BarDatum[];
  padding?: number;
}

const ReturnBreakdownBarChart = ({ data, padding = 0.1 }: ReturnBreakdownBarChartProps) => {
  const translate = useTranslate();
  const theme = useTheme();
  const classes = useStyles();
  const name = translate('analysis.value') as string;
  return (
    <>
      <div className={classes.chart}>
        <ResponsiveBar
          data={data}
          colors={['#abe6e9', '#d9dada']}
          valueFormat={(value: number) => `${value}%`}
          padding={padding}
          innerPadding={2}
          keys={[name]}
          indexBy='key'
          margin={{ top: 10, right: 10, bottom: 20, left: 10 }}
          groupMode='grouped'
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={null}
          gridYValues={[0]}
          labelSkipWidth={13}
          labelSkipHeight={9}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.5]] }}
          legends={[]}
          isInteractive
          animate
          theme={{
            tooltip: {
              container: {
                background: theme.palette.background.paper,
                color: theme.palette.text.primary,
              },
            },
          }}
        />
      </div>
      <div className={classes.legend}>
        {data.map(item => (
          <div key={item.key} className={classes.legendItem}>
            <SimpleText text={item.key} className={classes.legendText} />
          </div>
        ))}
      </div>
    </>
  );
};

export default ReturnBreakdownBarChart;

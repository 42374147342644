import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import { post } from 'lib/queries';
import { addError, addSmallNotification } from 'redux/notifications/actions';
import { SmallNotification } from 'utils/constants';

async function postFeedback(topic: string, message: string) {
  return post('feedback/', {
    topic,
    message,
  });
}

export async function postError(error: any, info: any) {
  return post('error/', { error, info });
}

export const useSendFeedbackMutation = () => {
  const dispatch = useDispatch();

  return useMutation(
    ({ topic, message }: { topic: string; message: string }) => postFeedback(topic, message),
    {
      onSuccess: () => {
        dispatch(addSmallNotification(SmallNotification.FEEDBACK_SENT));
      },
      onError: error => {
        dispatch(addError(makeErrorFromHttpResponse(error)));
      },
    }
  );
};

import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { createLocation } from 'components/MyRouting/utils';
import ObjectScreenBase from 'components/ObjectScreenBase';
import { PermissionResourceName } from 'lib/access/constants';
import useCreatePermission from 'lib/access/useCreatePermission';
import useEditPermission from 'lib/access/useEditPermission';
import { useDraft, useDraftObjectList, useDraftString } from 'lib/misc/useDraft/useDraft';
import AssetAllocationDropdown from 'screens/AssetAllocation/GroupConstraintListScreen/components/AssetAllocationDropdown';
import GroupConstraintSliders from 'screens/AssetAllocation/lib/components/GroupConstraintSliders';
import {
  useEveryGroupConstraintListMutation,
  useGroupConstraintListQuery,
  useGroupConstraintListsQuery,
} from 'screens/AssetAllocation/lib/queries';
import { Routes } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  tablesContainer: {
    paddingLeft: theme.spacing(12),
  },
  searchMenu: {
    paddingLeft: theme.spacing(12),
  },
}));

const GroupConstraintListScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id: objId } = useParams();
  const isNew = !objId;
  const { groupConstraintList, isLoading: isLoadingObj } = useGroupConstraintListQuery(objId);
  const { groupConstraintLists } = useGroupConstraintListsQuery();

  const hasEditAccess = useEditPermission(groupConstraintList);
  const hasCreateAccess = useCreatePermission(PermissionResourceName.GROUP_CONSTRAINT_LIST);
  const hasAccess = isNew ? hasCreateAccess : hasEditAccess;

  const {
    createGroupConstraintList,
    deleteGroupConstraintList,
    updateGroupConstraintList,
    isLoading: isLoadingMutation,
  } = useEveryGroupConstraintListMutation();

  const { draft: draftName, setDraft: setDraftName } = useDraftString(groupConstraintList?.name);
  const { draft: draftAssetAlloc, setDraft: setDraftAssetAlloc } = useDraft(
    groupConstraintList?.assetAllocation
  );
  const {
    draft: draftConstraints,
    setDraftElement: setDraftConstraint,
    setDraft: setDraftConstraints,
  } = useDraftObjectList(groupConstraintList?.constraints, { idPropName: 'assetGroup' });

  const draft = {
    constraints: draftConstraints,
    name: draftName,
    assetAllocation: draftAssetAlloc,
    isPredefined: true,
  };

  const onSaveClick = () => {
    if (isNew) {
      createGroupConstraintList(draft, {
        onSuccess: data => {
          history.push(createLocation(Routes.GROUP_CONSTRAINT_LIST, data.id));
        },
      });
    } else {
      updateGroupConstraintList(
        { id: objId, data: draft },
        {
          onSuccess: data => {
            setDraftConstraints(data.constraints);
          },
        }
      );
    }
  };

  const onSaveNameClick = () => {
    updateGroupConstraintList({ id: objId, data: { name: draftName } });
  };

  const onDeleteClick = () => {
    deleteGroupConstraintList(objId, {
      onSuccess: () => {
        history.push(Routes.CHOOSE_GROUP_CONSTRAINT_LIST);
      },
    });
  };

  return (
    <ObjectScreenBase
      disabled={isLoadingMutation || !draftAssetAlloc}
      onSaveNameClick={onSaveNameClick}
      waitWhile={isLoadingObj}
      object={groupConstraintList}
      objects={groupConstraintLists}
      draftObj={draft}
      onSaveClick={onSaveClick}
      onDeleteClick={onDeleteClick}
      hasAccess={hasAccess}
      translationPath='groupConsListScreen'
      propsToTriggerDialog={['constraints', 'assetAllocation']}
      routeNew={Routes.NEW_GROUP_CONSTRAINT_LIST}
      routeChoose={Routes.CHOOSE_GROUP_CONSTRAINT_LIST}
      routeObject={Routes.GROUP_CONSTRAINT_LIST}
      draftName={draftName}
      setDraftName={setDraftName}
    >
      <Grid container>
        <Grid item xs={12} lg={12}>
          <div className={classes.content}>
            <AssetAllocationDropdown
              draftAssetAlloc={draftAssetAlloc}
              setDraftAssetAlloc={setDraftAssetAlloc}
              setDraftConstraints={setDraftConstraints}
              disabled={!isNew || !hasAccess}
            />
            <Grid item md={4}>
              <GroupConstraintSliders
                draftConstraints={draftConstraints}
                setDraftConstraint={setDraftConstraint}
                disabled={!hasAccess}
              />
            </Grid>
          </div>
        </Grid>
      </Grid>
    </ObjectScreenBase>
  );
};

export default GroupConstraintListScreen;

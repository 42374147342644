import { DatePicker, LocalizationProvider } from '@mui/lab';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { endOfDay } from 'date-fns';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React from 'react';

import { DateType } from 'utils/types';

const DatePickerBase = ({ value, onChange, ...other }) => {
  const valueDate = endOfDay(new Date(value));
  const id = nanoid();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        renderInput={props => (
          <TextField {...props} size='small' onKeyDown={e => e.preventDefault()} />
        )}
        required
        views={['year', 'month', 'day']}
        id={id}
        inputFormat='yyyy-MM-dd'
        value={valueDate}
        disableHighlightToday
        onChange={date => {
          onChange(endOfDay(date));
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...other}
      />
    </LocalizationProvider>
  );
};

DatePickerBase.propTypes = {
  value: DateType.isRequired,
  onChange: PropTypes.func,
};

DatePickerBase.defaultProps = {
  onChange: () => {},
};

export default DatePickerBase;

import { Id } from 'lib/types';
import { AnalysisDataType } from 'screens/Analysis/lib/constants';
import { AnalysisDataTypeT } from 'screens/Analysis/lib/types';
import { useBacktestQuery } from 'screens/Backtest/lib/queries';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';

interface UseDefineDateLimitsProps {
  backtestId: Id | undefined;
  dataType: AnalysisDataTypeT;
  portfolioId: Id | undefined;
}

export const useDefineDateLimits = ({
  backtestId,
  dataType,
  portfolioId,
}: UseDefineDateLimitsProps) => {
  const isBacktest = dataType === AnalysisDataType.BACKTEST;
  const isReal = dataType === AnalysisDataType.REAL;
  const { backtest } = useBacktestQuery(isBacktest ? backtestId : undefined);
  const { portfolio } = usePortfolioQuery(isReal ? portfolioId : undefined);
  if (isReal) {
    return {
      limitStartDate: portfolio?.trackingStartDate,
      limitEndDate: undefined,
    };
  }
  if (isBacktest) {
    return {
      limitStartDate: backtest?.startDate,
      limitEndDate: backtest?.endDate,
    };
  }
  return {
    limitStartDate: undefined,
    limitEndDate: undefined,
  };
};

import React from 'react';
import { useHistory } from 'react-router-dom';

import { createLocation } from 'components/MyRouting/utils';
import ChooseScreen from 'components/SearchTools/ChooseScreen';
import { useUsersQueryAdmin } from 'screens/User/lib/queries';
import { Routes } from 'utils/constants';

const columns = ['id', 'email', 'registrationStatus', 'registeredOn', 'name', 'surname'];

const AdminChooseUserScreen = () => {
  const history = useHistory();

  const { users, isLoading } = useUsersQueryAdmin();

  return (
    // @ts-ignore
    <ChooseScreen
      dialogTranslatePath='adminUserScreen.useSavePromptAndDialog'
      waitWhile={isLoading}
      tableObjects={users}
      // @ts-ignore
      columns={columns}
      searchColumns={columns}
      screenTitle='adminUsers'
      searchValue={null}
      translateTableId='userTableAdmin'
      isTableWhite
      onNewClick={() => history.push(Routes.ADMIN_NEW_USER)}
      onRowClick={(id: string) => history.push(createLocation(Routes.ADMIN_USER, id))}
    />
  );
};

export default AdminChooseUserScreen;

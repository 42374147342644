import React from 'react';
import { useDispatch } from 'react-redux';

import WeightsTable from 'lib/weights/WeightsTable';
import usePortfolioAllocation from 'screens/Portfolio/lib/hooks/usePortfolioAllocation';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { deleteWidget } from 'screens/Report/redux/actions';
import WidgetToolbar from 'screens/Report/ReportScreen/components/WidgetToolbar';
import useDashboardStyles from 'screens/Report/ReportScreen/configuration/dashboard.style';
import { widgetComponentPropType } from 'utils/types';

const WeightTableWidget = ({
  widget,
  setSelectedWidget,
  setShowSettings,
  setSettingsType,
  setLayout,
}) => {
  const dashboardStyles = useDashboardStyles();

  const dispatch = useDispatch();
  const { portfolio, sort, columns, size, allocationType } = widget.configuration;

  const { weights, progressOrErrorElem, derivatives } = usePortfolioAllocation(
    portfolio?.value,
    allocationType?.value
  );
  const { data } = usePortfolioQuery(portfolio?.value);

  const content = (
    <WeightsTable
      isSimpleTable
      weights={weights}
      allocationType={allocationType}
      columns={columns.value}
      size={size.value}
      orderBy={sort?.value?.[0]}
      order={sort?.value?.[1]}
      derivatives={derivatives}
      portfolioId={portfolio?.value}
      assetsToDerivativesIds={data?.assetsToDerivativesIds ?? {}}
    />
  );

  return (
    <div className={dashboardStyles.mainCardWrapper}>
      <WidgetToolbar
        onEdit={() => {
          setSettingsType('WeightsTableWidget');
          setSelectedWidget(widget);
          setShowSettings(true);
        }}
        onDelete={() => {
          setLayout(prev => prev.filter(item => item.i !== widget.id));
          dispatch(deleteWidget(widget.id));
        }}
      />
      {progressOrErrorElem ?? content}
    </div>
  );
};

WeightTableWidget.propTypes = widgetComponentPropType;

export default WeightTableWidget;

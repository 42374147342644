import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ResourceName } from 'lib/constants';
import { replaceObjectInArray } from 'lib/misc/misc';
import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import { get, post, put, selectListToDict } from 'lib/queries';
import { Id, ValueOf } from 'lib/types';
import { addError, addSmallNotification } from 'redux/notifications/actions';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';
import {
  PostUserAdmin,
  PutUserAdmin,
  UserAdmin,
  UserOverview,
  UserOverviewAdmin,
} from 'screens/User/lib/types';
import { userGroupsKeys } from 'screens/UserGroup/lib/queries';
import { SmallNotification, SocketioEvent } from 'utils/constants';

interface DetailParams {
  id: Id;
}

export const userKeys = {
  all: [{ scope: ResourceName.USER, admin: false }] as const,
  lists: () => [{ ...userKeys.all[0], entity: 'list' }] as const,
  list: ({ instituteId }: { instituteId: Id }) =>
    [{ ...userKeys.lists()[0], instituteId }] as const,
};

export const userKeysAdmin = {
  all: [{ scope: ResourceName.USER, admin: true }] as const,
  lists: () => [{ ...userKeysAdmin.all[0], entity: 'list' }] as const,
  list: () => [{ ...userKeysAdmin.lists()[0] }] as const,
  details: () => [{ ...userKeysAdmin.all[0], entity: 'detail' }] as const,
  detail: ({ id }: DetailParams) => [{ ...userKeysAdmin.details()[0], id }] as const,
};

async function getUsers({
  queryKey: [{ instituteId }],
}: QueryFunctionContext<ReturnType<typeof userKeys.list>>): Promise<UserOverview[]> {
  return get('users/', { 'institute-id': instituteId });
}

async function getUsersAdmin(): Promise<UserOverviewAdmin[]> {
  return get('admin/users/');
}

async function getUserAdmin({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<typeof userKeysAdmin.detail>>): Promise<UserAdmin> {
  return get(`admin/users/${id}/`);
}

async function putUserAdmin(id: Id, data: PutUserAdmin): Promise<UserAdmin> {
  return put(`admin/users/${id}/`, data);
}

async function postUserAdmin(data: PostUserAdmin): Promise<UserAdmin> {
  return post('admin/users/', data);
}

async function sendInvitationAdmin(id: Id) {
  return post(`admin/send_invitation/${id}/`, {});
}

export const useUsersQuery = () => {
  const { instituteId } = useCurrentInstitute();
  const query = useQuery(userKeys.list({ instituteId }), getUsers, {
    select: selectListToDict,
  });
  return {
    ...query,
    users: query.data || {},
  };
};

export const useUsersQueryAdmin = () => {
  const query = useQuery(userKeysAdmin.list(), getUsersAdmin, {
    select: selectListToDict,
  });
  return {
    ...query,
    users: query.data || {},
  };
};

export const useUserQueryAdmin = (id: Id | undefined) => {
  const query = useQuery(userKeysAdmin.detail({ id } as { id: Id }), getUserAdmin, {
    enabled: !!id,
  });
  return {
    ...query,
    user: query.data,
  };
};

export const useCreateUserMutationAdmin = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation((data: PostUserAdmin) => postUserAdmin(data), {
    onSuccess: data => {
      queryClient.setQueriesData<UserOverviewAdmin[]>([{ ...userKeysAdmin.lists()[0] }], prev =>
        prev ? [...prev, data] : [data]
      );

      queryClient.setQueriesData([{ ...userKeysAdmin.details()[0], id: data.id }], data);
      dispatch(addSmallNotification(SmallNotification.USER_CREATED));
      queryClient.invalidateQueries(userGroupsKeys.all);
      queryClient.invalidateQueries(userKeys.all);
      queryClient.invalidateQueries([{ scope: ResourceName.INSTITUTE }]);
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const useUpdateUserMutationAdmin = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(({ id, data }: { id: Id; data: PutUserAdmin }) => putUserAdmin(id, data), {
    onSuccess: data => {
      queryClient.setQueriesData([{ ...userKeysAdmin.lists()[0] }], prev =>
        replaceObjectInArray(prev, data)
      );

      queryClient.setQueriesData([{ ...userKeysAdmin.details()[0], id: data.id }], data);

      queryClient.invalidateQueries(userGroupsKeys.all);
      queryClient.invalidateQueries(userKeys.all);
      queryClient.invalidateQueries([{ scope: ResourceName.INSTITUTE }]);
      dispatch(addSmallNotification(SmallNotification.USER_SAVED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const useSendInvitationMutationAdmin = () => {
  const dispatch = useDispatch();
  return useMutation((id: Id) => sendInvitationAdmin(id), {
    onSuccess: () => {
      dispatch(addSmallNotification(SmallNotification.ACTION_SENT));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const useReceiveUserWebsocketsAdmin = (event: ValueOf<typeof SocketioEvent>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return (data: any) => {
    queryClient.setQueriesData([{ ...userKeysAdmin.lists()[0] }], prev =>
      replaceObjectInArray(prev, data)
    );
    queryClient.setQueriesData([{ ...userKeysAdmin.details()[0], id: data.id }], data);
    if (event !== SocketioEvent.USER_INVITATION_SENT) {
      queryClient.invalidateQueries(userGroupsKeys.all);
      queryClient.invalidateQueries(userKeys.all);
      queryClient.invalidateQueries([{ scope: ResourceName.INSTITUTE }]);
    } else {
      dispatch(addSmallNotification(SmallNotification.USER_INVITATION_SENT));
    }
  };
};

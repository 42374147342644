export const ResourceName = {
  REPORT: 'report',
  BENCHMARK: 'benchmark',
  ANALYSIS: 'analysis',
  BACKTEST: 'backtest',
  PORTFOLIO: 'portfolio_info',
  PORTFOLIO_HISTORY: 'portfolio_history',
  BASKET: 'basket',
  UNIVERSE: 'universe',
  USER_GROUP: 'user_group',
  ASSET: 'asset',
  RESEARCH_LIST: 'research_list',
  RESEARCH_CONSTRAINT_LIST: 'research_constraint_list',
  GROUP_CONSTRAINT_LIST: 'group_constraint_list',
  INSTITUTE: 'institute',
  USER: 'user',
} as const;

import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { ResourceName } from 'lib/constants';
import { replaceObjectInArray } from 'lib/misc/misc';
import { makeErrorFromHttpResponse } from 'lib/notifications/helpers';
import { get, post, put, selectListToDict, useDeleteMutationCommon } from 'lib/queries';
import { Id, ValueOf } from 'lib/types';
import { addError, addSmallNotification } from 'redux/notifications/actions';
import {
  Analysis,
  AnalysisOverview,
  AnalysisOverviews,
  PostAnalysis,
  PutAnalysis,
} from 'screens/Analysis/lib/types';
import { SmallNotification, SocketioEvent } from 'utils/constants';

interface ListParams {
  portfolioId: Id;
}
interface DetailParams {
  id: Id;
}

interface UpdateMutateParams {
  id: Id;
  data: PutAnalysis;
}

export const analysisKeys = {
  all: [{ scope: ResourceName.ANALYSIS }] as const,
  lists: () => [{ ...analysisKeys.all[0], entity: 'list' }] as const,
  list: ({ portfolioId }: ListParams) => [{ ...analysisKeys.lists()[0], portfolioId }] as const,
  details: () => [{ ...analysisKeys.all[0], entity: 'detail' }] as const,
  detail: ({ id }: DetailParams) => [{ ...analysisKeys.details()[0], id }] as const,
};

async function getAnalyses({
  queryKey: [{ portfolioId }],
}: QueryFunctionContext<ReturnType<typeof analysisKeys.list>>): Promise<
  ReadonlyArray<AnalysisOverview>
> {
  return get(`portfolios/${portfolioId}/analyses/`);
}

async function getAnalysis({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<typeof analysisKeys.detail>>): Promise<Analysis> {
  return get(`analyses/${id}/`);
}

async function postAnalysis(data: PostAnalysis, portfolioId: Id): Promise<Analysis> {
  return post(`portfolios/${portfolioId}/analyses/`, data);
}

async function putAnalysis(data: PutAnalysis, backtestId: Id): Promise<Analysis> {
  return put(`analyses/${backtestId}/`, data);
}

export const useAnalysesQuery = (portfolioId: Id | undefined) => {
  const query = useQuery(analysisKeys.list({ portfolioId } as { portfolioId: Id }), getAnalyses, {
    select: selectListToDict,
    enabled: !!portfolioId,
  });
  const data = query.data as AnalysisOverviews | undefined;
  const analyses = data || {};
  return {
    ...query,
    data,
    analyses,
  };
};

export const useAnalysisQuery = (id: Id | undefined) => {
  const query = useQuery(analysisKeys.detail({ id } as { id: Id }), getAnalysis, {
    enabled: !!id,
  });
  return {
    ...query,
    analysis: query.data,
  };
};

export const useCreateAnalysisMutation = (portfolioId: Id) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation((draft: PostAnalysis) => postAnalysis(draft, portfolioId), {
    onSuccess: data => {
      queryClient.setQueriesData<Analysis[]>(
        analysisKeys.list({ portfolioId: data.portfolioId }),
        prev => (prev ? [...prev, data] : [data])
      );
      queryClient.setQueriesData(analysisKeys.detail({ id: data.id }), data);
      dispatch(addSmallNotification(SmallNotification.ANALYSIS_CREATED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const useUpdateAnalysisMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(({ id, data }: UpdateMutateParams) => putAnalysis(data, id), {
    onSuccess: data => {
      queryClient.setQueriesData(analysisKeys.list({ portfolioId: data.portfolioId }), prev =>
        replaceObjectInArray(prev, data)
      );
      queryClient.setQueriesData(analysisKeys.detail({ id: data.id }), data);
      dispatch(addSmallNotification(SmallNotification.ANALYSIS_SAVED));
    },
    onError: error => {
      dispatch(addError(makeErrorFromHttpResponse(error)));
    },
  });
};

export const useDeleteAnalysisMutation = () => {
  return useDeleteMutationCommon('analyses/', {
    notification: SmallNotification.ANALYSIS_DELETED,
    queryKeyLists: analysisKeys.lists(),
    queryKeyDetails: analysisKeys.details(),
  });
};

export const useReceiveAnalysisWebsockets = (event: ValueOf<typeof SocketioEvent>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return (data: Analysis) => {
    queryClient.setQueriesData(analysisKeys.list({ portfolioId: data.portfolioId }), prev =>
      replaceObjectInArray(prev, data)
    );
    queryClient.setQueriesData(analysisKeys.detail({ id: data.id }), data);
    if (event === SocketioEvent.PORTFOLIO_ANALYSIS_FINISHED) {
      dispatch(addSmallNotification(SmallNotification.ANALYSIS_FINISHED));
    }
  };
};

import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { makePerformanceInfosFromAssetQueries } from 'lib/performance/helpers';
import PerformanceChart from 'lib/performance/PerformanceChart';
import { useAssetQueries } from 'screens/Environment/lib/queries';
import usePortfolioPerformance from 'screens/Portfolio/lib/hooks/usePortfolioPerformance';
import { deleteWidget } from 'screens/Report/redux/actions';
import WidgetToolbar from 'screens/Report/ReportScreen/components/WidgetToolbar';
import useDashboardStyles from 'screens/Report/ReportScreen/configuration/dashboard.style';
import { widgetComponentPropType } from 'utils/types';

const LineChartWidget = ({
  widget,
  setSelectedWidget,
  setShowSettings,
  setSettingsType,
  setLayout,
}) => {
  const dashboardStyles = useDashboardStyles();

  const {
    portfolio: portfolioId,
    showBenchmarkLegend,
    startDate,
    endDate,
    benchmarks,
    backtestId,
    type: dataType,
  } = widget.configuration;
  const dispatch = useDispatch();
  const getColorSetFn = useCallback(() => {
    return (benchmarks?.value ?? []).map(b => b.color ?? '#000000');
  }, [benchmarks?.value]);
  const compareAssetIds = (benchmarks?.value ?? []).map(b => b.id);
  const assetQueries = useAssetQueries(compareAssetIds);
  const benchmarkPerfInfos = useMemo(() => {
    return makePerformanceInfosFromAssetQueries(compareAssetIds, assetQueries);
  }, [compareAssetIds, assetQueries]);
  const { performanceInfo, progressOrErrorElem } = usePortfolioPerformance({
    portfolioId: portfolioId?.value,
    dataType: dataType?.value,
    backtestId: backtestId?.value,
  });

  const content = performanceInfo ? (
    <PerformanceChart
      performanceInfo={performanceInfo}
      benchmarkPerformanceInfos={benchmarkPerfInfos}
      startDate={startDate?.value}
      endDate={endDate?.value}
      disabled
      legendType={showBenchmarkLegend?.value ? 'static' : 'none'}
      getColorSetFn={getColorSetFn}
      gridLinesCount={3}
    />
  ) : undefined;
  return (
    <div className={dashboardStyles.mainCardWrapper} style={{ overflow: 'hidden' }}>
      <WidgetToolbar
        onEdit={() => {
          setSettingsType('LineChartWidget');
          setSelectedWidget(widget);
          setShowSettings(true);
        }}
        onDelete={() => {
          setLayout(prev => prev.filter(item => item.i !== widget.id));
          dispatch(deleteWidget(widget.id));
        }}
      />
      {progressOrErrorElem ?? content}
    </div>
  );
};

LineChartWidget.propTypes = widgetComponentPropType;

export default LineChartWidget;

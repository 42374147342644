import { isAfter, isBefore, parseISO } from 'date-fns';
import _, { sortBy } from 'lodash';
import { nanoid } from 'nanoid';
import randomColor from 'random-color';

import {
  separatorStartHeightMappings,
  typeToComponentMapping,
} from 'screens/Report/ReportScreen/configuration/dashboard.config';

// TODO separate function by logic
function getSeparatorsOnly(widgets) {
  return widgets.filter(widget => widget.type === 'Separator');
}

function getWidgetsOnly(widgets) {
  return widgets.filter(widget => widget.type !== 'Separator');
}

export function transformDataForReport(reportInfo, reportSettings, reportWidgets) {
  return {
    ...reportInfo,
    layout: {
      dashboard: {
        orientation: reportSettings.pageOrientation,
      },
      widgets: reportWidgets.map(widget => {
        const { layout } = widget;
        const { component, ...fields } = layout;
        return {
          id: widget.id,
          type: widget.type,
          configuration: {
            ...widget.configuration,
          },
          layout: {
            ...fields,
          },
        };
      }),
    },
  };
}

export function filterSavedPortfoliosWithoutReport(reports, portfolios, hasEditPermissions) {
  const reportIds = Object.values(reports).map(report => report.portfolioInfo);
  return Object.fromEntries(
    Object.entries(portfolios).filter(
      ([, value]) => !reportIds.includes(value.id) && value.isSaved && hasEditPermissions[value.id]
    )
  );
}

export function filterSavedPortfoliosWithReport(reports, portfolios) {
  const reportIds = Object.values(reports).map(report => report.portfolioInfo);
  return Object.fromEntries(
    Object.entries(portfolios).filter(([, value]) => reportIds.includes(value.id) && value.isSaved)
  );
}

export function getReportByPortfolioIfExists(id, reports) {
  const filtered = Object.values(reports).filter(report => report.portfolioInfo === id.toString());
  if (!_.isEmpty(filtered)) {
    return filtered[0];
  }
  return null;
}

export function transformLayoutToGrid(layout, classes) {
  return layout.map(item => {
    if (item.i.includes('separator')) {
      return { key: item.i, component: null, className: classes.separator };
    }

    return { key: item.i, component: item.component, className: null };
  });
}

export function prepareWidgetAddition(config, layoutItem, editMode) {
  const id = nanoid();
  return {
    id,
    type: config.type,
    configuration: config.configuration,
    layout: {
      i: id,
      x: layoutItem.x,
      y: layoutItem.y,
      h: config.minH,
      w: config.minW,
      static: !editMode,
      minW: config.minW,
      minH: config.minH,
      maxW: config.maxW,
      maxH: config.maxH,
      component: config.widget,
    },
  };
}

function checkForNewSeparator(widgetsToUpdate, orientation) {
  const response = [0, null];
  const onlyWidgets = getWidgetsOnly(widgetsToUpdate);
  const onlySeparators = getSeparatorsOnly(widgetsToUpdate);
  const lastSeparatorY = onlySeparators.reduce(
    (max, { layout }) => (layout.y > max ? layout.y : max),
    0
  );
  const lastWidgetY = onlyWidgets.reduce((max, { layout }) => (layout.y > max ? layout.y : max), 0);
  const removeSeparators =
    (lastSeparatorY - lastWidgetY) / separatorStartHeightMappings[orientation];
  if (onlySeparators.length > 1 && Math.sign(removeSeparators) && removeSeparators >= 1) {
    return [-1, Math.round(removeSeparators)];
  }

  if (lastWidgetY < lastSeparatorY) {
    return response;
  }

  const id = nanoid();
  return [
    1,
    {
      id,
      type: 'Separator',
      layout: {
        i: `separator${id}`,
        x: 1,
        y: lastSeparatorY + separatorStartHeightMappings[orientation],
        w: 12,
        h: 1,
        static: true,
      },
    },
  ];
}

export function handleLayoutUpdate(currentWidgets, newLayout, orientation) {
  const newWidgets = currentWidgets.map(widget => {
    const layoutItem = newLayout.find(item => item.i === widget.id);
    if (layoutItem) {
      return {
        ...widget,
        layout: {
          ...widget.layout,
          x: layoutItem.x,
          y: layoutItem.y,
          h: layoutItem.h,
          w: layoutItem.w,
        },
      };
    }
    return widget;
  });

  const [needSeparator, separator] = checkForNewSeparator(newWidgets, orientation);
  if (!needSeparator) return newWidgets;
  const onlySeparators = getSeparatorsOnly(newWidgets);
  switch (needSeparator) {
    case 0:
      return newWidgets;
    case 1:
      return [...newWidgets, separator];
    case -1:
      for (let i = 0; i < separator; i += 1) {
        onlySeparators.pop();
      }
      return [...newWidgets.filter(item => item.type !== 'Separator'), ...onlySeparators];
    default:
      return [...newWidgets, separator];
  }
}

export function handlePageOrientationChange(orientation, widgets) {
  // const [needSeparator, separator] = checkForNewSeparator(widgets, orientation);

  let counter = 1;
  const separators = getSeparatorsOnly(widgets);
  const widgetsOnly = getWidgetsOnly(widgets);

  return [
    ...separators.map(item => {
      const y = separatorStartHeightMappings[orientation] * counter;
      counter += 1;
      return {
        ...item,
        layout: {
          ...item.layout,
          y,
        },
      };
    }),
    ...widgetsOnly,
  ];
}

export function attachComponents(widgets) {
  return widgets.map(widget => {
    return {
      ...widget,
      layout: {
        ...widget.layout,
        component: typeToComponentMapping[widget.type],
      },
    };
  });
}

export function handleWidgetConfigUpdate(widgets, payload) {
  const { id, config } = payload;
  const widgetToChange = widgets.find(item => item.id === id);

  if (widgetToChange) {
    const widgetsWithoutCurrent = widgets.filter(item => item.id !== id);

    return [
      ...widgetsWithoutCurrent,
      {
        ...widgetToChange,
        configuration: config,
      },
    ];
  }

  return widgets;
}

export function generateBenchmarkList(defaults, assets, withColor = true) {
  const defaultAssetIds = defaults.map(benchmark => benchmark.id);
  const assetsSorted = sortBy(Object.values(assets), 'name');
  return assetsSorted
    .filter(asset => asset.name)
    .map(asset => {
      const isChecked = defaultAssetIds.includes(asset.id);

      const column = {
        removable: true,
        key: asset.id,
        label: asset.name,
        checked: isChecked,
      };
      if (withColor) {
        column.color = !isChecked
          ? randomColor().hexString()
          : defaults.find(item => item.id === asset.id).color;
      }
      return column;
    });
}

export function filterChartDataByTimeFrame(chartData, startDate, endDate) {
  const filteredData = {};
  Object.entries(chartData).forEach(([key, value]) => {
    let filteredSeries = value.series;
    if (startDate) {
      filteredSeries = filteredSeries.filter(item =>
        isAfter(item.x, _.isString(startDate) ? parseISO(startDate) : startDate)
      );
    }

    if (endDate) {
      filteredSeries = filteredSeries.filter(item =>
        isBefore(item.x, _.isString(startDate) ? parseISO(endDate) : endDate)
      );
    }

    filteredData[key] = {
      ...value,
      series: filteredSeries,
    };
  });

  return filteredData;
}

export function getDataWithSeries(data) {
  const dataWithSeries = {};
  const keys = Object.keys(data);
  for (let i = 0; i < keys.length; i += 1) {
    if (data[keys[i]].series) {
      dataWithSeries[keys[i]] = data[keys[i]];
    }
  }
  return dataWithSeries;
}

export function generateRowFieldData(current, allRows, forceCheck = null) {
  return allRows.map((row, i) => ({
    removable: true,
    key: i,
    label: row,
    checked: forceCheck ?? current.includes(row),
  }));
}

export function handleWidgetConfigRestore(widgets, id) {
  const widgetToChange = widgets.find(item => item.id === id);

  if (widgetToChange) {
    const clonedWidget = _.clone(widgetToChange);
    Object.keys(widgetToChange.configuration).forEach(key =>
      _.set(clonedWidget, `configuration.${key}.value`, clonedWidget.configuration[key].savedValue)
    );

    const widgetsWithoutCurrent = widgets.filter(item => item.id !== id);

    return [...widgetsWithoutCurrent, clonedWidget];
  }

  return widgets;
}

export function updateConfigSavedValues(widgets, id) {
  const widgetToChange = widgets.find(item => item.id === id);

  if (widgetToChange) {
    const clonedWidget = _.clone(widgetToChange);
    Object.keys(widgetToChange.configuration).forEach(key =>
      _.set(clonedWidget, `configuration.${key}.savedValue`, clonedWidget.configuration[key].value)
    );

    const widgetsWithoutCurrent = widgets.filter(item => item.id !== id);

    return [...widgetsWithoutCurrent, clonedWidget];
  }

  return widgets;
}

export function handleWidgetSizeUpdate(widgets, payload) {
  const { id, layout } = payload;
  const widgetToChange = widgets.find(item => item.id === id);

  if (widgetToChange) {
    const widgetsWithoutCurrent = widgets.filter(item => item.id !== id);

    return [
      ...widgetsWithoutCurrent,
      {
        ...widgetToChange,
        layout: {
          ...widgetToChange.layout,
          ...layout,
        },
      },
    ];
  }

  return widgets;
}

export function getLastYFromWidgets(widgets) {
  return getWidgetsOnly(widgets).reduce((ac, { layout }) => {
    if (ac > layout.h + layout.y) return ac;

    return layout.h + layout.y;
  }, 0);
}

import { addHours, addBusinessDays, subBusinessDays, subMonths } from 'date-fns';
import _ from 'lodash';
import { mean, std } from 'mathjs';

export function isSunday(date) {
  return date.getUTCDay() === 0;
}

export function isMonday(date) {
  return date.getUTCDay() === 1;
}

export function isFriday(date) {
  return date.getUTCDay() === 5;
}

export function isSaturday(date) {
  return date.getUTCDay() === 6;
}

export function isWeekend(date) {
  return isSaturday(date) || isSunday(date);
}

// eslint-disable-next-line no-unused-vars
export function rollBackIfWeekend(date) {
  if (isWeekend(date)) {
    return subBusinessDays(date, 1);
  }
  return date;
}

export function rollForwardIfWeekend(date) {
  if (isWeekend(date)) {
    return addBusinessDays(date, 1);
  }
  return date;
}

export function isMonthsLastDay(date) {
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();
  const day = date.getUTCDate();
  const lastDate = new Date(Date.UTC(year, month + 1, 0));
  const lastDay = lastDate.getUTCDate();
  return day === lastDay;
}

/**
 * Generates array of dates in the given range and with given frequency
 * @param startDate
 * @param endDate
 * @param freq - one of 'B' (only business days) or 'D' (every day).
 * @return {Array}
 */
export function generateDateRange(startDate, endDate, freq = 'D') {
  const dateRange = [];
  let hours;
  let currentDate = new Date(startDate);
  if (freq === 'B' && isWeekend(currentDate)) {
    if (isSaturday(currentDate)) {
      currentDate = addHours(currentDate, 48);
    }
    if (isSunday(currentDate)) {
      currentDate = addHours(currentDate, 24);
    }
  }
  while (currentDate.getTime() <= endDate.getTime()) {
    dateRange.push(currentDate);
    if (freq === 'B' && isFriday(currentDate)) {
      hours = 72;
    } else {
      hours = 24;
    }
    currentDate = addHours(currentDate, hours);
  }
  return dateRange;
}

export function get30DayCumReturn(series) {
  if (series && series.length > 1) {
    const lastPerfPoint = series[series.length - 1];
    const lastDate = lastPerfPoint.x;

    const monthAgoDate = rollBackIfWeekend(subMonths(lastDate, 1));

    let monthAgoPerfPoint = series
      .slice(-60)
      .find(elem => elem.x.getTime() === monthAgoDate.getTime());
    if (!monthAgoPerfPoint) {
      // eslint-disable-next-line prefer-destructuring
      monthAgoPerfPoint = series.slice(-22)[0];
    }

    const cumulativeReturn = lastPerfPoint.y / monthAgoPerfPoint.y - 1;
    return {
      cumReturnString: `${(100 * cumulativeReturn).toFixed(2)} %`,
      cumReturn: cumulativeReturn,
    };
  }
  return {
    cumReturnString: null,
    cumReturn: null,
  };
}

export function generateTimeSeries(startDate, endDate, freq = 'D') {
  const ts = [];
  const dateRange = generateDateRange(startDate, endDate, freq);
  dateRange.forEach(date => {
    ts.push({ x: date, y: Math.random() });
  });

  return ts;
}

export function getCorrectTimeSeriesPeriod(timeSeries, exactStartDate, exactEndDate) {
  let startIndex = 0;
  let endIndex = timeSeries.length;
  for (let i = 0; i < timeSeries.length; i += 1) {
    if (timeSeries[i].x.getTime() >= exactStartDate.getTime()) {
      startIndex = i;
      break;
    }
  }
  for (let j = timeSeries.length - 1; j >= 0; j -= 1) {
    if (timeSeries[j].x.getTime() <= exactEndDate.getTime()) {
      endIndex = j;
      break;
    }
  }
  return timeSeries.slice(startIndex, endIndex + 1);
}

export function getReturnFromPerformanceBDay(performance) {
  const returns = [];
  let previousPoint;
  let date;
  for (let i = 1; i < performance.length; i += 1) {
    date = performance[i].x;
    if (isWeekend(date)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    previousPoint = isMonday(date) ? i - 3 : i - 1;
    if (previousPoint >= 0) {
      const dataPoint = {
        x: performance[i].x,
        y: (performance[i].y - performance[previousPoint].y) / performance[previousPoint].y,
      };
      returns.push(dataPoint);
    }
  }
  return returns;
}

/**
 * Method looks for Month's end dates (e.g. 2020-03-31) and calculates returns between these dates.
 * @param sortedFilledPerformance - performance figures should be sorted and filled fully
 * (so that we have Month's end dates)
 * @return {Array}
 */
export function getMonthEndReturns(sortedFilledPerformance) {
  const monthlyReturns = [];
  const monthEndPerformance = [];

  for (let i = 0; i < sortedFilledPerformance.length; i += 1) {
    const currentDate = sortedFilledPerformance[i].x;
    if (isMonthsLastDay(currentDate)) {
      monthEndPerformance.push(sortedFilledPerformance[i]);
    }
  }

  for (let i = 1; i < monthEndPerformance.length; i += 1) {
    monthlyReturns.push(monthEndPerformance[i].y / monthEndPerformance[i - 1].y - 1);
  }

  return monthlyReturns;
}

export function getReturnFromPerformanceDay(performance) {
  const returns = [];
  for (let i = 1; i < performance.length; i += 1) {
    const dataPoint = {
      x: performance[i].x,
      y: (performance[i].y - performance[i - 1].y) / performance[i - 1].y,
    };
    returns.push(dataPoint);
  }
  return returns;
}

/**
 * Get return from performance at certain frequency
 * @param performance
 * @param freq - one of 'B' (only business days), 'D' (every day), or 'M' (end of Month).
 * @return {Array}
 */
export function getReturnFromPerformance(performance, freq = 'D') {
  if (freq === 'D') {
    return getReturnFromPerformanceDay(performance);
  }
  if (freq === 'B') {
    return getReturnFromPerformanceBDay(performance);
  }
  if (freq === 'M') {
    return getMonthEndReturns(performance);
  }
  throw new Error("freq should be one of ['B', 'D', 'M']");
}

function sortNumber(a, b) {
  return a - b;
}

export function getPercentile(array, percentile) {
  /** this is a function that receives array of returns and percentile in integer and returns VAR
   * @param {number[]|number}
   * @return VAR
   */
  const arrayCopy = _.cloneDeep(array);
  arrayCopy.sort(sortNumber);
  const index = (percentile / 100.0) * (arrayCopy.length - 1);
  let result;
  if (Math.floor(index) === index) {
    result = arrayCopy[index];
  } else {
    const i = Math.floor(index);
    const fraction = index - i;
    result = arrayCopy[i] + (arrayCopy[i + 1] - arrayCopy[i]) * fraction;
  }

  return result;
}

export function getCumReturn(sortedPerformance) {
  if (sortedPerformance.length > 2) {
    return sortedPerformance[sortedPerformance.length - 1].y / sortedPerformance[0].y - 1;
  }
  return null;
}

export function getMaxDrawDown(sortedPerformance) {
  let peak;
  let trough;
  let tempMDD;
  let finalMDD = 0;
  for (let i = 0; i < sortedPerformance.length; i += 1) {
    peak = sortedPerformance[i];
    for (let j = 0; j < sortedPerformance.length; j += 1) {
      trough = sortedPerformance[j];
      if (peak.x.getTime() < trough.x.getTime()) {
        tempMDD = (trough.y - peak.y) / peak.y;
        if (tempMDD < finalMDD) {
          finalMDD = tempMDD;
        }
      }
    }
  }
  return finalMDD;
}

/**
 * this is a function that receives an array of
 * sortedPerformance objects and returns several KPI measures as an object
 * @param sortedPerf
 * @param amountPeriodsInYear
 * @param cvarQuantile
 * @param rf
 * @param basedOnMonthly
 * @return object
 */
export function getKPIMeasures(
  sortedPerf,
  amountPeriodsInYear,
  cvarQuantile,
  rf,
  basedOnMonthly = true
) {
  let cumulativeReturn = null;
  let annualMean = null;
  let annualStd = null;
  let annualVar = null;
  let annualCvar = null;
  let sharpeRatio = null;
  let cSharpeRatio = null;
  let mdd = null;
  if (sortedPerf.length > 0) {
    const returns = getReturnFromPerformance(sortedPerf, 'B');
    cumulativeReturn = sortedPerf[sortedPerf.length - 1].y / sortedPerf[0].y - 1;
    // const years = returns.length / amountPeriodsInYear.day;
    // annualMean = (1 + cumulativeReturn) ** (1 / years) - 1;
    const arrayReturns = [];
    for (let i = 0; i < returns.length; i += 1) {
      arrayReturns.push(returns[i].y);
    }
    annualMean = mean(arrayReturns) * amountPeriodsInYear.day;
    let returnForCalculations = arrayReturns;
    mdd = getMaxDrawDown(sortedPerf);
    let period = 'day';
    if (basedOnMonthly) {
      returnForCalculations = getReturnFromPerformance(sortedPerf, 'M');
      period = 'month';
    }
    // const meanReturn = returnSum / returns.length;
    if (returnForCalculations.length > 12) {
      const STD = std(returnForCalculations);
      annualStd = STD * amountPeriodsInYear[period] ** (1 / 2);
      const VAR = getPercentile(returnForCalculations, 5);
      let CVAR = 0;
      if (returnForCalculations.filter(x => x < VAR).length > 0) {
        CVAR = mean(returnForCalculations.filter(x => x < VAR));
      }
      annualVar = VAR * amountPeriodsInYear[period] ** (1 / 2);
      annualCvar = CVAR * amountPeriodsInYear[period] ** (1 / 2);
      const excessReturn = annualMean - rf;
      sharpeRatio = excessReturn / annualStd;
      cSharpeRatio = excessReturn / annualCvar;
    }
  }
  return {
    cumulativeReturn,
    annualMean,
    annualStd,
    annualVar,
    annualCvar,
    sharpeRatio,
    cSharpeRatio,
    mdd,
  };
}

export function convertKPIMeasuresBacktest(kpis, turnover) {
  return {
    cumulativeReturn: _.isNull(kpis.cumulativeReturn)
      ? '-'
      : `${(100 * kpis.cumulativeReturn).toFixed(2)} %`,
    meanReturn: _.isNull(kpis.annualMean) ? '-' : `${(100 * kpis.annualMean).toFixed(2)} %`,
    STD: _.isNull(kpis.annualStd) ? '-' : `${(100 * kpis.annualStd).toFixed(2)} %`,
    VAR: _.isNull(kpis.annualVar) ? '-' : `${(100 * kpis.annualVar).toFixed(2)} %`,
    CVAR: _.isNull(kpis.annualCvar) ? '-' : `${(100 * kpis.annualCvar).toFixed(2)} %`,
    sharpeRatio: _.isNull(kpis.sharpeRatio) ? '-' : Math.round(100 * kpis.sharpeRatio) / 100,
    cSharpeRatio: _.isNull(kpis.cSharpeRatio) ? '-' : Math.round(100 * kpis.cSharpeRatio) / 100,
    MDD: _.isNull(kpis.mdd) ? '-' : `${(100 * kpis.mdd).toFixed(2)} %`,
    turnover: _.isNull(turnover) ? '-' : Math.round(100 * turnover) / 100,
  };
}

export function convertKPIMeasuresHistory(kpis) {
  return {
    cumulativeReturn: _.isNull(kpis.cumulativeReturn)
      ? 0
      : `${(100 * kpis.cumulativeReturn).toFixed(2)} %`,
    meanReturn: _.isNull(kpis.annualMean) ? 0 : `${(100 * kpis.annualMean).toFixed(2)} %`,
    annualMean: _.isNull(kpis.annualMean) ? 0 : `${(100 * kpis.annualMean).toFixed(2)} %`,
    MDD: _.isNull(kpis.mdd) ? 0 : `${(100 * kpis.mdd).toFixed(2)} %`,
  };
}

export function parseDatesInSeries(series) {
  return series.map(s => ({
    x: s.x instanceof Date ? s.x : new Date(s.x),
    y: s.y,
  }));
}

export function getPerformanceFromPrice(priceTimeSeries) {
  const performanceData = [];
  const firstDayPrice = priceTimeSeries[0].y;
  for (let i = 0; i < priceTimeSeries.length; i += 1) {
    performanceData.push({
      x: priceTimeSeries[i].x,
      y: priceTimeSeries[i].y / firstDayPrice,
    });
  }
  return performanceData.sort((a, b) => a.x - b.x);
}

export function getPercentagePerformance(performance) {
  const performanceData = [];
  for (let i = 0; i < performance.length; i += 1) {
    performanceData.push({
      x: performance[i].x,
      y: performance[i].y * 100,
    });
  }
  return performanceData.sort((a, b) => a.x - b.x);
}

export function sortAndFillMissingData(performance) {
  /** this is a function that receives a performance array, sorts it and if there are missing
   * values first fills them with the previous day's value and if there are missing values left
   * it fills them with the next day's value. (in case the first value is missing for example).
   * This function automatically applies daily frequency.
   * @param object[]
   * @return object[]
   */
  const perf = _.cloneDeep(performance);
  if (performance && performance.length > 0) {
    perf.sort((a, b) => a.x - b.x);
    const perfFilled = [];
    const fullDateRange = generateDateRange(perf[0].x, perf[perf.length - 1].x, 'D');
    let currentPoint = 0;
    fullDateRange.forEach(date => {
      if (!(date.getTime() === perf[currentPoint].x.getTime())) {
        perfFilled.push({ x: date, y: null });
      } else {
        perfFilled.push(perf[currentPoint]);
        currentPoint += 1;
      }
    });

    for (let i = 1; i < perfFilled.length; i += 1) {
      if (!perfFilled[i].y) {
        perfFilled[i].y = perfFilled[i - 1].y;
      }
    }
    for (let i = perfFilled.length - 1; i >= 0; i -= 1) {
      if (!perfFilled[i].y) {
        perfFilled[i].y = perfFilled[i + 1].y;
      }
    }
    return perfFilled;
  }
  return [];
}

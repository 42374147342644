import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Id } from 'lib/types';
import WeightsChartCard from 'screens/Portfolio/lib/components/WeightsChartCard';
import WeightsChartComparisonCard from 'screens/Portfolio/lib/components/WeightsChartComparisonCard';
import WeightsTableCard from 'screens/Portfolio/lib/components/WeightsTableCard';
import { PortfolioAllocationType } from 'screens/Portfolio/lib/constants';
import useAllocationToolbar from 'screens/Portfolio/PortfolioScreen/components/PortfolioAllocationTab/useAllocationToolbar';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(4),
  },
}));

const ALLOWED_ALLOC_TYPES = [
  PortfolioAllocationType.CURRENT,
  PortfolioAllocationType.TARGET,
  PortfolioAllocationType.OPTIMIZED,
];

const PortfolioAllocationTab = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: Id }>();

  const {
    selectedPortfolioId,
    selectedAllocType,
    Toolbar,
    isCompareOn,
    selectedPortfolioIdCompare,
    selectedAllocTypeCompare,
  } = useAllocationToolbar({
    portfolioId: id,
    allocationType: ALLOWED_ALLOC_TYPES,
    compareAllocationType: ALLOWED_ALLOC_TYPES,
  });

  if (isCompareOn) {
    return (
      <>
        <Toolbar />
        <div className={classes.card}>
          <WeightsChartComparisonCard
            portfolioId={selectedPortfolioId}
            allocationType={selectedAllocType}
            portfolioIdCompare={selectedPortfolioIdCompare}
            allocationTypeCompare={selectedAllocTypeCompare}
          />
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {selectedPortfolioId !== undefined && selectedAllocType !== undefined && (
              <div className={classes.card}>
                <WeightsTableCard
                  portfolioId={selectedPortfolioId}
                  allocationType={selectedAllocType}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {selectedPortfolioIdCompare !== undefined && selectedAllocTypeCompare !== undefined && (
              <div className={classes.card}>
                <WeightsTableCard
                  portfolioId={selectedPortfolioIdCompare}
                  allocationType={selectedAllocTypeCompare}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <div>
      <Toolbar />
      <div className={classes.card}>
        <WeightsChartCard portfolioId={selectedPortfolioId} allocationType={selectedAllocType} />
        {selectedPortfolioId !== undefined && selectedAllocType !== undefined && (
          <div className={classes.card}>
            <WeightsTableCard
              portfolioId={selectedPortfolioId}
              allocationType={selectedAllocType}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PortfolioAllocationTab;

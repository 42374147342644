import React from 'react';

function useToggleList<T>(initialList: T[]) {
  const [toggledValues, setToggledValues] = React.useState(initialList);

  const toggle = (value: T) => {
    const index = toggledValues.indexOf(value);
    if (index === -1) {
      setToggledValues([...toggledValues, value]);
    } else {
      setToggledValues(toggledValues.filter(type => type !== value));
    }
  };
  const isToggled = (value: T) => {
    return toggledValues.indexOf(value) !== -1;
  };

  return { toggledValues, toggle, isToggled };
}

export default useToggleList;

import { Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty, first, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ContributionTableView from 'screens/Analysis/AnalysisScreen/widgets/ContributionTable/ContributionTableView';
import TestSpinner from 'screens/Analysis/lib/components/TestSpinner';
import useDateToggle from 'screens/Analysis/lib/components/useDateToggle';
import WidgetFooter from 'screens/Analysis/lib/components/WidgetFooter';
import WidgetTitleSelector from 'screens/Analysis/lib/components/WidgetTitleSelector';
import { SECOND_LEVEL_HEIGHT } from 'screens/Analysis/lib/constants';
import { useAnalysisQuery } from 'screens/Analysis/lib/queries';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '0.7rem',
    height: SECOND_LEVEL_HEIGHT,
    position: 'relative',
  },
});
const columns = ['name', 'avWeight', 'stdDev', 'cStd'];

const cellFormatting = {
  name: row => row.replace(/(.{12})..+/, '$1…'),
  avWeight: row => `${(row * 100).toFixed(2)}%`,
  stdDev: row => `${(row * 100).toFixed(2)}%`,
  cStd: row => `${(row * 100).toFixed(2)}%`,
  cBeta: row => `${(row * 100).toFixed(2)}%`,
};

const RiskContributionTable = ({ analysisId }) => {
  const classes = useStyles();

  const { analysis } = useAnalysisQuery(analysisId);
  const widgetData = analysis?.result?.RiskContributionTable ?? {};

  const [title, setTitle] = useState('asset');

  const [dateFilter, DateFilterComponent, setDate] = useDateToggle(
    !isEmpty(widgetData) ? first(widgetData[title].filters) : 'all'
  );

  useEffect(() => {
    if (!isEmpty(widgetData)) {
      setDate(first(widgetData[title].filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const tableObjects = widgetData[title]?.data[dateFilter];
  const tableObjectsSorted = sortBy(tableObjects, obj => -obj.cStd);

  return (
    <Grid item md={12} lg={5} xl={4}>
      <Paper className={classes.wrapper}>
        <Grid container style={{ height: '100%' }}>
          {isEmpty(widgetData) ? (
            <TestSpinner />
          ) : (
            <>
              <WidgetTitleSelector
                mainTitle='contribution'
                options={['asset', 'Country', 'Sector']}
                title={title}
                setTitle={setTitle}
              />
              <div style={{ height: '85%', position: 'relative', width: '100%' }}>
                <DateFilterComponent
                  available={widgetData[title].filters}
                  errors={widgetData[title].errors}
                  hints={widgetData[title].hints}
                />
                <ContributionTableView
                  columns={columns}
                  tableObjects={tableObjectsSorted}
                  cellFormatting={cellFormatting}
                  showBenchmark={title !== 'asset'}
                />
              </div>
              <WidgetFooter>
                <span style={{ color: '#abe6e9' }}>Portfolio</span>
                <span style={{ color: '#d9dada' }}>Benchmark</span>
              </WidgetFooter>
            </>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

RiskContributionTable.propTypes = {
  analysisId: PropTypes.string,
};

RiskContributionTable.defaultProps = {
  analysisId: undefined,
};

export default RiskContributionTable;

import { Card, CardContent, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import SimpleText from 'components/SimpleText';
import { useCurrentInstitute } from 'screens/Institute/lib/hooks/useCurrentInstitute';
import TermsDialog from 'screens/Login/RegisterScreen/components/TermsDialog';

const translationPath = 'adminInstituteScreen.documentsCard';

const useStyles = makeStyles(theme => ({
  section: {
    paddingTop: theme.spacing(8),
  },
}));

const DocumentsCard = () => {
  const classes = useStyles();
  const { institute } = useCurrentInstitute();

  const [areTermsOpen, setAreTermsOpen] = React.useState(false);

  const onTermsOpen = (event: any) => {
    event.preventDefault();
    setAreTermsOpen(true);
  };
  const onTermsClose = () => {
    setAreTermsOpen(false);
  };

  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = React.useState(false);
  const onPrivacyPolicyOpen = (event: any) => {
    event.preventDefault();
    setIsPrivacyPolicyOpen(true);
  };
  const onPrivacyPolicyClose = () => {
    setIsPrivacyPolicyOpen(false);
  };

  return (
    <Card style={{ height: '100%' }}>
      <CardContent>
        <TermsDialog isOpen={areTermsOpen} onClose={onTermsClose} type='termsAndConditions' />
        <TermsDialog
          isOpen={isPrivacyPolicyOpen}
          onClose={onPrivacyPolicyClose}
          type='privacyPolicy'
        />
        <SimpleText translateId={`${translationPath}.title`} variant='h2' />
        {institute?.isTrial && (
          <div className={classes.section}>
            <Link href='#' onClick={onTermsOpen}>
              <SimpleText translateId={`${translationPath}.termsConditions`} variant='body1' />
            </Link>
          </div>
        )}

        <div className={classes.section}>
          <Link href='#' onClick={onPrivacyPolicyOpen}>
            <SimpleText translateId={`${translationPath}.privacyPolicy`} variant='body1' />
          </Link>
        </div>
      </CardContent>
    </Card>
  );
};

export default DocumentsCard;

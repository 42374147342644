import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

import MyTipsAndTricks from 'components/MyTipsAndTricks/MyTipsAndTricks';
import PositionMe from 'components/PositionMe';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import { ClassesType } from 'utils/types';

const styles = theme => ({
  root: {
    margin: 0,
    textAlign: 'center',
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(12),
  },
});

const MyProgress = ({
  translateId,
  size,
  classes,
  center,
  color,
  onCancelClick,
  hasTipsAndTricks,
  theme,
  ...other
}) => {
  const Wrapper = center ? PositionMe : React.createElement('div');

  return (
    <Wrapper>
      <div className={classes.root}>
        <div className={classes.text}>
          {translateId ? (
            <SimpleText
              translateId={translateId}
              color={color ?? theme.palette.neutral.dark}
              {...other}
            />
          ) : null}
        </div>
        <CircularProgress size={size} />
        {onCancelClick ? (
          <div className={classes.button}>
            <SimpleButton
              translateId='cancel'
              variant='highEmp'
              onClick={onCancelClick}
              {...other}
            />
          </div>
        ) : null}
        {hasTipsAndTricks ? <MyTipsAndTricks /> : null}
      </div>
    </Wrapper>
  );
};

MyProgress.propTypes = {
  center: PropTypes.bool,
  translateId: PropTypes.string,
  size: PropTypes.string,
  classes: ClassesType.isRequired,
  color: PropTypes.string,
  onCancelClick: PropTypes.func,
  hasTipsAndTricks: PropTypes.bool,
};

MyProgress.defaultProps = {
  center: true,
  translateId: 'progress.wait',
  size: '5rem',
  color: undefined,
  onCancelClick: null,
  hasTipsAndTricks: false,
};

export default withStyles(styles, { withTheme: true })(MyProgress);

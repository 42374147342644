import CloseIcon from '@mui/icons-material/Close';
import PenIcon from '@mui/icons-material/Create';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import BaseTextField from 'components/BaseTextField';
import MyIconButton from 'components/Buttons/MyIconButton';
import TitlePageButton from 'components/Buttons/TitlePageButton';
import SearchMenu from 'components/SearchTools/SearchMenu';
import SimpleText from 'components/SimpleText';
import { redirectTo } from 'redux/navigation/actions';
import { Routes } from 'utils/constants';
import { ClassesType, SearchMenuPropsType, TypographyVariantType } from 'utils/types';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(2),
  },
});

const mapDispatchToProps = dispatch => ({
  onCancelCompareClick: portfolioId => dispatch(redirectTo(Routes.PORTFOLIO, portfolioId)),
});

class NameTitle extends React.Component {
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    isEditing: this.props.startWithEditing,
    nameBeforeEditing: '',
  };

  onPenClick = () => {
    const { name } = this.props;
    this.setState({ isEditing: true, nameBeforeEditing: name });
    document.addEventListener('keydown', this.onKeyDown, false);
  };

  onKeyDown = event => {
    if (event.key === 'Escape') {
      this.onTextFieldBlur(false);
      const { onRename } = this.props;
      const { nameBeforeEditing } = this.state;
      if (nameBeforeEditing) {
        onRename(nameBeforeEditing);
      }
    }
  };

  onTextFieldBlur = (isConfirm = true) => {
    const { name, onRename, onConfirm } = this.props;
    if (name === '') {
      const { nameBeforeEditing } = this.state;
      if (nameBeforeEditing === '') {
        return;
      }
      onRename(nameBeforeEditing);
    } else if (isConfirm && onConfirm) {
      onConfirm(name);
    }

    this.setState({ isEditing: false });
    document.removeEventListener('keydown', this.onKeyDown, false);
  };

  onTextFieldKeyPress = event => {
    if (event.key === 'Enter') {
      this.onTextFieldBlur();
    }
  };

  render() {
    const {
      classes,
      name,
      onRename,
      typographyVariant,
      searchMenuProps,
      isDeletable,
      canRename,
      onDelete,
      canChooseFromMenu,
    } = this.props;
    const { isEditing } = this.state;
    if (!isEditing) {
      return (
        <React.Fragment>
          {searchMenuProps ? (
            <SearchMenu {...searchMenuProps}>
              {onClick => (
                <TitlePageButton
                  typographyVariant={typographyVariant}
                  text={name}
                  onClick={canChooseFromMenu ? onClick : null}
                />
              )}
            </SearchMenu>
          ) : (
            <div className={classes.textField}>
              <SimpleText text={name} variant={typographyVariant} />
            </div>
          )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {isDeletable ? (
            <MyIconButton onClick={onDelete}>
              <CloseIcon />
            </MyIconButton>
          ) : canRename ? (
            <MyIconButton onClick={this.onPenClick} tooltip='rename'>
              <PenIcon />
            </MyIconButton>
          ) : null}
        </React.Fragment>
      );
    }
    return (
      <div className={classes.textField}>
        <BaseTextField
          placeholder='portfolioNameField.placeholder'
          typographyVariant={typographyVariant}
          value={name}
          onChange={onRename}
          disableUnderline
          returnOnlyText
          onBlur={this.onTextFieldBlur}
          autoFocus
          onKeyPress={this.onTextFieldKeyPress}
          noPadding
          pendo-id='lib-screen-base-name-title'
        />
      </div>
    );
  }
}

NameTitle.propTypes = {
  classes: ClassesType.isRequired,
  name: PropTypes.string,
  onRename: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  typographyVariant: TypographyVariantType,
  searchMenuProps: SearchMenuPropsType,
  startWithEditing: PropTypes.bool,
  isDeletable: PropTypes.bool,
  currentPortfolioId: PropTypes.number,
  onCancelCompareClick: PropTypes.func.isRequired,
  canRename: PropTypes.bool,
  onDelete: PropTypes.func,
  canChooseFromMenu: PropTypes.bool,
};

NameTitle.defaultProps = {
  name: '',
  typographyVariant: 'h1',
  startWithEditing: false,
  isDeletable: false,
  currentPortfolioId: null,
  canRename: true,
  onDelete: null,
  canChooseFromMenu: true,
  onConfirm: null,
  searchMenuProps: null,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(NameTitle));

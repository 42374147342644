import { Add } from '@mui/icons-material';
import { IconButton, Chip } from '@mui/material';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';

import SimpleText from 'components/SimpleText';
import { CompareAssetsDialog } from 'lib/performance/PerformanceChartCard';
import { getTextColor } from 'lib/theming/utils';

const useChipSelection = defaultColumns => {
  const [columns, setColumns] = useState(defaultColumns);
  useEffect(() => {
    setColumns(defaultColumns);
  }, [defaultColumns]);

  const controlId = nanoid();
  const checkedCount = columns.filter(column => column.checked).length;
  const handleDelete = column =>
    checkedCount > 0
      ? setColumns(prev =>
          prev.map(item => {
            if (item.key === column.key) {
              return { ...item, checked: false };
            }
            return item;
          })
        )
      : null;

  const Component = ({
    translateSource,
    label,
    addDisabled,
    addColor,
    useLabelWithoutTranslate,
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <>
        {label ? (
          <SimpleText translateId={`${translateSource}.${label}`} fontWeight='bold' my={4} />
        ) : null}
        <IconButton onClick={() => setIsOpen(true)} disabled={addDisabled} size='large'>
          <Add color={addColor} aria-controls={controlId} aria-haspopup='true' />
        </IconButton>
        {columns
          .filter(column => column.checked)
          .map(column => (
            <Chip
              style={{
                margin: '0.4rem',
                backgroundColor: column.color || '#d9dada',
                color: getTextColor(column.color || '#d9dada'),
              }}
              key={column.key}
              label={
                !useLabelWithoutTranslate ? (
                  <Translate id={`${translateSource}.${column.label}`} />
                ) : (
                  column.label
                )
              }
              onDelete={column.removable ? () => handleDelete(column) : null}
            />
          ))}
        <CompareAssetsDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          assetIds={columns.filter(c => c.checked && c.key > 0).map(c => c.key)}
          onChooseAssetIds={assetIds => {
            setColumns(prev =>
              prev.map(item => {
                if (item.key > 0) {
                  return {
                    ...item,
                    checked: assetIds.includes(item.key),
                  };
                }
                return item;
              })
            );
          }}
        />
      </>
    );
  };

  Component.propTypes = {
    translateSource: PropTypes.string.isRequired,
    useLabelWithoutTranslate: PropTypes.bool,
    label: PropTypes.string,
    addDisabled: PropTypes.bool,
    addColor: PropTypes.string,
  };

  Component.defaultProps = {
    label: '',
    addDisabled: false,
    useLabelWithoutTranslate: false,
    addColor: 'primary',
  };

  return [Component, columns];
};

export default useChipSelection;

import { DarkMode, LightMode } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HelpIcon from '@mui/icons-material/Help';
import LanguageIcon from '@mui/icons-material/Language';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Divider } from '@mui/material';
import Drawer from '@mui/material/Drawer/index';
import IconButton from '@mui/material/IconButton/index';
import List from '@mui/material/List/index';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import SideListItem from './SideListItem';

import InstituteDropdown from 'components/ScreenBase/SideMenu/InstituteDropdown';
import MyFeedbackDialog from 'components/ScreenBase/SideMenu/MyFeedbackDialog';
import { useSwitchLanguage, useTranslate } from 'lib/localization/localization';
import { useLightDarkMode } from 'lib/theming/lightDarkMode';
import { collapseSideMenu } from 'redux/ui/actions';
import { useAuth } from 'screens/Login/lib/queries';
import logoDark from 'static/images/LogoFullDark.png';
import logoWhite from 'static/images/LogoFullWhite.png';
import { NAVBAR_HEIGHT, Routes as PATH, Routes, SIDE_MENU_WIDTH } from 'utils/constants';
import { ClassesType } from 'utils/types';

const styles = theme => ({
  drawer: {
    width: SIDE_MENU_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: SIDE_MENU_WIDTH,
    backgroundColor: theme.palette.background.paper,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    height: NAVBAR_HEIGHT,
    borderBottom: `1px solid ${theme.palette.grey.base}`,
    marginBottom: theme.spacing(4),
  },
  logo: {
    height: '50px',
    right: '10px',
    bottom: 0,
    position: 'relative',
  },
  instituteDropdown: {
    margin: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  menuList: { overflow: 'auto', flexGrow: 1 },
  icon: {
    color: theme.palette.neutral.main,
    width: 50,
    '& .MuiBadge-anchorOriginTopRightRectangular': {
      color: theme.palette.primary.main,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: '100%',
    },
  },
  iconBottuns: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const LIST_SECTION_MARGIN = '2.5rem';
const SECTIONS = [
  {
    headerProps: {
      translationId: 'portfolioManagement',
    },
    listItemsProps: [
      {
        translationId: 'monitorPortfolios',
        path: Routes.CHOOSE_PORTFOLIO,
      },
      {
        translationId: 'performanceBacktest',
        path: Routes.CHOOSE_BACKTEST,
      },
      {
        translationId: 'analyses',
        path: Routes.CHOOSE_ANALYSIS,
      },
      {
        translationId: 'reports',
        path: Routes.CHOOSE_REPORT,
      },
    ],
  },
  {
    headerProps: {
      translationId: 'utilities',
    },
    listItemsProps: [
      {
        translationId: 'baskets',
        path: Routes.CHOOSE_BASKET,
      },
      {
        translationId: 'adminUniverses',
        path: Routes.CHOOSE_UNIVERSE,
      },
      {
        translationId: 'researchLists',
        path: Routes.CHOOSE_RESEARCH_LIST,
      },
      {
        translationId: 'researchConstraintLists',
        path: generatePath(Routes.CHOOSE_RESEARCH_CONSTRAINT_LIST),
      },
      {
        translationId: 'assetAllocations',
        path: Routes.CHOOSE_GROUP_CONSTRAINT_LIST,
      },
    ],
  },
  {
    headerProps: {
      translationId: 'settings',
      path: Routes.SETTINGS_INSTITUTE,
    },
    listItemsProps: [
      {
        translationId: 'settingsInstitute',
        path: Routes.SETTINGS_INSTITUTE,
      },
      {
        translationId: 'adminUserGroups',
        path: Routes.CHOOSE_USER_GROUP,
      },
    ],
  },
  {
    headerProps: {
      translationId: 'admin',
      path: Routes.ADMIN_ASSETS,
    },
    listItemsProps: [
      {
        translationId: 'adminAssets',
        path: Routes.ADMIN_ASSETS,
      },
      {
        translationId: 'adminInstitutes',
        path: Routes.ADMIN_INSTITUTES,
      },
      {
        translationId: 'adminUsers',
        path: Routes.ADMIN_USERS,
      },
    ],
  },
];

const SideMenu = ({ classes, theme }) => {
  const { isLightMode } = useLightDarkMode();
  const dispatch = useDispatch();
  const history = useHistory();
  const translate = useTranslate();
  const switchLanguage = useSwitchLanguage();
  const { switchMode, currentMode } = useLightDarkMode();
  const isSideMenuCollapsed = useSelector(state => state.ui.isSideMenuCollapsed);
  const { logout } = useAuth();
  const onLogoutClick = () => {
    logout();
    history.push(PATH.LOGIN);
  };
  const onCollapseMenuClick = () => dispatch(collapseSideMenu());

  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);

  const toggleBodyClass = () => {
    if (isSideMenuCollapsed) {
      document.body.classList.remove('has-side-menu');
    } else {
      document.body.classList.add('has-side-menu');
    }
  };

  useEffect(() => {
    toggleBodyClass();
  });

  const handleSwitchLanguage = () => {
    switchLanguage();
  };

  const handleGoToHelp = () => {
    history.push(Routes.HELP);
  };

  const handleFeedbackDialogOpen = () => {
    setIsFeedbackDialogOpen(true);
  };

  const handleFeedbackDialogClose = () => {
    setIsFeedbackDialogOpen(false);
  };

  return (
    <Drawer
      className={classes.drawer}
      transitionDuration={theme.transitions.duration.enteringScreen}
      anchor='left'
      open={!isSideMenuCollapsed}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={onCollapseMenuClick}
    >
      <div className={classes.drawerHeader}>
        <img src={isLightMode ? logoDark : logoWhite} alt='Logo2' className={classes.logo} />
        <div className={classes.menuIcon}>
          <IconButton onClick={onCollapseMenuClick} size='large'>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
      </div>
      <div className={classes.instituteDropdown}>
        <InstituteDropdown />
      </div>
      <Divider sx={{ mb: 2 }} />
      <List className={classes.menuList}>
        {SECTIONS.map(section => {
          const sectionHeaderProps = section.headerProps;
          return (
            <React.Fragment key={sectionHeaderProps.translationId}>
              <SideListItem {...sectionHeaderProps} isSection />
              {section.listItemsProps.map(listItemProps => (
                <SideListItem {...listItemProps} key={listItemProps.translationId} />
              ))}
              <div style={{ marginBottom: LIST_SECTION_MARGIN }} />
            </React.Fragment>
          );
        })}
      </List>
      <Divider sx={{ mt: 2 }} />
      <div className={classes.iconBottuns}>
        <Tooltip title={translate('navMenu.logOut')}>
          <IconButton
            aria-haspopup='true'
            onClick={onLogoutClick}
            className={classes.icon}
            size='large'
          >
            <PowerSettingsNewIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={translate('navMenu.feedBack')}>
          <IconButton className={classes.icon} onClick={handleFeedbackDialogOpen} size='large'>
            <FeedbackIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={translate('navMenu.help')}>
          <IconButton className={classes.icon} onClick={handleGoToHelp} size='large'>
            <HelpIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={translate('navMenu.switchLanguage')}>
          <IconButton className={classes.icon} onClick={handleSwitchLanguage} size='large'>
            <LanguageIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={translate(
            currentMode === 'light' ? 'navMenu.switchToDarkMode' : 'navMenu.switchToLightMode'
          )}
        >
          <IconButton className={classes.icon} onClick={() => switchMode()} size='large'>
            {currentMode === 'light' ? <DarkMode /> : <LightMode />}
          </IconButton>
        </Tooltip>
      </div>
      <MyFeedbackDialog
        OnCloseClick={handleFeedbackDialogClose}
        isFeedbackDialogOpen={isFeedbackDialogOpen}
      />
    </Drawer>
  );
};

SideMenu.propTypes = {
  classes: ClassesType.isRequired,
  theme: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withStyles(styles, { withTheme: true })(SideMenu);

import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

import MyDialog from 'components/MyDialog/MyDialog';
import SimpleText from 'components/SimpleText';
import { DialogKeys, Dialogs } from 'utils/constants';

const useStyles = makeStyles({
  feedbackDialog: {
    width: '30rem',
  },
  feedbackIntro: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  writeEmail: {
    float: 'left',
  },
  email: {
    float: 'left',
    marginLeft: '0.5rem',
  },
  feedbackDetails: {
    marginTop: '1rem',
  },
});

const MyFeedBackDialog = ({ isFeedbackDialogOpen, OnCloseClick }) => {
  const classes = useStyles();

  return (
    <MyDialog
      className={classes.feedbackDialog}
      isOpen={isFeedbackDialogOpen}
      header={`dialogHeader.${Dialogs.FEEDBACK}`}
      performKey={`dialogKey.${DialogKeys.CLOSE}`}
      onPerformClick={OnCloseClick}
    >
      <div className={classes.feedbackIntro}>
        <SimpleText className={classes.writeEmail} translateId='dialogMessage.WRITE_EMAIL' />
        <SimpleText className={classes.email} fontWeight='bold' text='support@alpha-factory.de' />
      </div>
      <div>
        <SimpleText
          className={classes.feedbackDetails}
          translateId='dialogMessage.FEEDBACK_DETAILS'
        />
      </div>
      <div>
        <SimpleText
          className={classes.feedbackDetails}
          translateId='dialogMessage.WE_WILL_GET_BACK'
        />
      </div>
    </MyDialog>
  );
};

MyFeedBackDialog.propTypes = {
  isFeedbackDialogOpen: PropTypes.bool,
  OnCloseClick: PropTypes.bool.isRequired,
};
MyFeedBackDialog.defaultProps = {
  isFeedbackDialogOpen: false,
};
export default MyFeedBackDialog;

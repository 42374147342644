import { Grid } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';

import useScroll from 'lib/misc/useScroll';
import KPIsTableCard from 'lib/performance/KPIsTableCard';
import PerformanceChartCard from 'lib/performance/PerformanceChartCard';
import { Id } from 'lib/types';
import HistoryWeightsChartsCard from 'lib/weights/HistoryWeightsChartsCard';
import { AnalysisDataType } from 'screens/Analysis/lib/constants';
import usePortfolioPerformance from 'screens/Portfolio/lib/hooks/usePortfolioPerformance';

const PortfolioPerformanceTab = () => {
  const { id } = useParams<{ id: Id }>();
  const [startDate, setStartDate] = React.useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = React.useState<Date | undefined>(undefined);
  const [compareAssetIds, setCompareAssetIds] = React.useState<Id[]>([]);

  const { executeScroll, scrollRef } = useScroll();

  const handleDatesChange = (newStartDate: Date, newEndDate: Date) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const { points } = usePortfolioPerformance({
    portfolioId: id,
    dataType: AnalysisDataType.REAL,
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PerformanceChartCard
          portfolioId={id}
          dataType={AnalysisDataType.REAL}
          onDatesChange={handleDatesChange}
          compareAssetIds={compareAssetIds}
          onChangeCompareAssetIds={setCompareAssetIds}
          onRebalancingPointClick={executeScroll}
        />
      </Grid>
      <Grid item xs={12}>
        <KPIsTableCard
          portfolioId={id}
          dataType={AnalysisDataType.REAL}
          compareAssetIds={compareAssetIds}
          endDate={endDate}
          startDate={startDate}
        />
      </Grid>
      {points && (
        <Grid ref={scrollRef} item xs={12}>
          <HistoryWeightsChartsCard rebalancingPoints={points} />
        </Grid>
      )}
    </Grid>
  );
};

export default PortfolioPerformanceTab;

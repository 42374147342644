import { PermissionLevel } from 'lib/access/constants';
import useHasPermission from 'lib/access/useHasPermission';
import { Id, Resource, Resources } from 'lib/types';

interface Options {
  isGeneralRole?: boolean;
}

/**
 * @param objectOrObjects: object or Array/Dict of objects
 * @param options: { isGeneralRole?: boolean }
 * @returns
 * CURRENTLY WORKS ONLY FOR OBJECTS, BELONGING TO INSTITUTE!
 * If object is provided, it is checked for having 'tablename' key.
 *
 * If it has it, it's considered to be a single object.
 * Then hook returns True, if current user has EDIT permission for the object.
 *
 * If it doesn't have it, then it's considered to be a dictionary of objects. Can be empty.
 * Then hook returns Object of booleans, where each element is True,
 * if User has EDIT permission for corresponding object in `objectOrObjects` dict.
 *
 * If array of objects is provided, returns array of booleans, where each element is True, if
 * User has EDIT permission for corresponding object in `objectOrObjects` array.
 *
 * Returns False for Null/Undefined objects (also in array or dictionary).
 */
function useEditPermission(objectOrObjects: Resource | undefined, options?: Options): boolean;
function useEditPermission(objectOrObjects: Resource[], options?: Options): boolean[];
function useEditPermission(objectOrObjects: Resources, options?: Options): { [id: Id]: boolean };
function useEditPermission(objectOrObjects: any, options: any = {}): any {
  return useHasPermission(objectOrObjects, PermissionLevel.EDIT, options.isGeneralRole);
}

export default useEditPermission;

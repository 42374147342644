import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import InfoTooltip from 'components/InfoTooltip';
import MyDialog from 'components/MyDialog/MyDialog';
import SimpleButton from 'components/SimpleButton';
import SimpleText from 'components/SimpleText';
import useEditPermission from 'lib/access/useEditPermission';
import { Id } from 'lib/types';
import WeightsExportDialog from 'lib/weights/WeightsExportDialog';
import { PortfolioAction, PortfolioAllocationType } from 'screens/Portfolio/lib/constants';
import { useOptimizationInfo, useWeights } from 'screens/Portfolio/lib/hooks/portfolio';
import {
  useDerivativesForPortfolioQuery,
  usePortfolioActionMutation,
  usePortfolioQuery,
} from 'screens/Portfolio/lib/queries';
import { DialogKeys } from 'utils/constants';

const translationPath = 'portfolioScreen.portfolioRebalance.rebalanceToolbar' as const;

const useStyles = makeStyles({
  toolbarLeft: { width: '100%', display: 'flex', justifyContent: 'flex-start' },
});

interface ToolbarProps {
  portfolioId: Id;
}

const RebalanceToolbar = ({ portfolioId }: ToolbarProps) => {
  const classes = useStyles();
  const [isRebalanceDialogOpen, setIsRebalanceDialogOpen] = React.useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = React.useState(false);
  const { portfolio, isLoading } = usePortfolioQuery(portfolioId);
  const { mutate: applyAction } = usePortfolioActionMutation(portfolioId);
  const hasEditAccess = useEditPermission(portfolio);
  const {
    isError,
    jobIsDone,
    isOutdated,
    isLoading: isLoadingOptimInfo,
  } = useOptimizationInfo(portfolioId, PortfolioAllocationType.OPTIMIZED);
  const { weights, isLoading: isLoadingWeights } = useWeights(
    portfolioId,
    PortfolioAllocationType.OPTIMIZED
  );
  const { data: derivativesData } = useDerivativesForPortfolioQuery(portfolioId);

  const canRebalance = !isError && jobIsDone && !isOutdated;

  const handleRebalanceClick = () => {
    applyAction(PortfolioAction.REBALANCE, {
      onSuccess: () => {
        setIsExportDialogOpen(true);
      },
    });
  };

  return (
    <>
      <MyDialog
        isOpen={isRebalanceDialogOpen}
        header={`${translationPath}.rebalanceDialog.header`}
        message={`${translationPath}.rebalanceDialog.message`}
        notPerformKey={`dialogKey.${DialogKeys.CANCEL}`}
        performKey={`dialogKey.${DialogKeys.CONFIRM}`}
        onNotPerformClick={() => setIsRebalanceDialogOpen(false)}
        onPerformClick={() => {
          handleRebalanceClick();
          setIsRebalanceDialogOpen(false);
        }}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <WeightsExportDialog
            isOpen={isExportDialogOpen}
            setIsOpen={setIsExportDialogOpen}
            weights={weights}
            assetsToDerivativesIds={portfolio?.assetsToDerivativesIds ?? {}}
            derivatives={derivativesData?.derivatives ?? []}
          />
          <div className={classes.toolbarLeft}>
            <SimpleText
              variant='h2'
              translateId={`${translationPath}.title.${PortfolioAllocationType.CURRENT}`}
            />
            <SimpleButton
              pendo-id='portfolio-rebalance-toolbar-rebalance-button'
              translateId={`${translationPath}.rebalanceButton`}
              onClick={() => setIsRebalanceDialogOpen(true)}
              variant='highEmp'
              disabled={
                !hasEditAccess ||
                !canRebalance ||
                isLoading ||
                isLoadingOptimInfo ||
                isLoadingWeights
              }
            />
            {!canRebalance && (
              <InfoTooltip
                size='small'
                translateId={`${translationPath}.tooltipOptimizationProblem`}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <SimpleText
            variant='h2'
            translateId={`${translationPath}.title.${PortfolioAllocationType.OPTIMIZED}`}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RebalanceToolbar;

import { Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import MyProgress from 'components/MyProgress';
import SimpleText from 'components/SimpleText';
import Table from 'components/Table/TableView';
import useTableDelete from 'components/Table/useTableDelete';
import useEditPermission from 'lib/access/useEditPermission';
import { toDateString } from 'lib/dates/dateUtils';
import { Id, Resource, Resources } from 'lib/types';
import { useAnalysesQuery, useDeleteAnalysisMutation } from 'screens/Analysis/lib/queries';
import { useBacktestsQuery, useDeleteBacktestMutation } from 'screens/Backtest/lib/queries';
import { usePortfolioQuery } from 'screens/Portfolio/lib/queries';
import { CARD_MIN_HEIGHT } from 'screens/Portfolio/PortfolioScreen/components/PortfolioOverviewTab/cards/constants';
import { useDeleteReportMutation, useReportsQuery } from 'screens/Report/lib/queries';
import { Routes } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    minHeight: CARD_MIN_HEIGHT,
    flexGrow: 1,
    paddingTop: '0 !important',
  },
  section: {
    paddingTop: theme.spacing(6),
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
}));

const columns = ['name', 'createdOn', 'updatedOn'];

const cellFormatting = {
  createdOn: toDateString,
  updatedOn: toDateString,
};

interface ChildObjectsCardProps<T extends Resource> {
  childrenObjects: T[] | Resources<T>;
  portfolioId: Id;
  dialogTranslatePath: string;
  deleteChildObjFn: (id: Id) => void;
  isLoadingChildren: boolean;
  translateTableId: string;
  route: string;
  routeNew: string;
  translationPath: string;
}

const ChildObjectsCard = <T extends Resource>({
  portfolioId,
  childrenObjects,
  dialogTranslatePath,
  deleteChildObjFn,
  isLoadingChildren,
  translateTableId,
  route,
  routeNew,
  translationPath,
}: ChildObjectsCardProps<T>) => {
  const history = useHistory();
  const classes = useStyles();
  const { portfolio, isLoading } = usePortfolioQuery(portfolioId);
  const hasCreatePermission = useEditPermission(portfolio);

  const { deletableRows, handleDeleteClick, deleteDialog } = useTableDelete({
    tableObjects: childrenObjects,
    dialogTranslatePath,
    onDeleteRowClick: (obj: T | null) => {
      if (obj) {
        deleteChildObjFn(obj.id);
      }
    },
    editPermissionOverride: hasCreatePermission,
  });

  if (isLoading || !portfolio || isLoadingChildren) {
    return (
      <Card>
        <CardContent className={classes.content}>
          <SimpleText translateId={`${translationPath}.title`} variant='h2' />
          <MyProgress />
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          {deleteDialog}
          <div>
            <Table
              titleTranslateId={`${translationPath}.title`}
              // @ts-ignore
              waitWhile={isLoadingChildren}
              tableObjects={childrenObjects}
              columns={columns}
              searchColumns={columns}
              cellFormatting={cellFormatting}
              searchValue={null}
              isWithCheckbox={false}
              isTableWhite
              deletableRows={deletableRows}
              translateTableId={translateTableId}
              onRowClick={(clickedId: Id) =>
                history.push(generatePath(route, { id: clickedId, portfolioId }))
              }
              onDeleteRowClick={handleDeleteClick}
              onAddRowClick={
                hasCreatePermission
                  ? () => history.push(generatePath(routeNew, { portfolioId, id: undefined }))
                  : undefined
              }
              isDownloadButton={false}
              rowsPerPageChoices={[5, 10, 15, 20]}
              initialRowsPerPage={5}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export const ChildReportsCard = ({ portfolioId }: { portfolioId: Id }) => {
  const { reports, isLoading: isLoadingReports } = useReportsQuery(portfolioId);
  const { mutate: deleteReport } = useDeleteReportMutation();

  return (
    <ChildObjectsCard
      childrenObjects={reports}
      portfolioId={portfolioId}
      dialogTranslatePath='reportScreen.useSavePromptAndDialog'
      deleteChildObjFn={deleteReport}
      isLoadingChildren={isLoadingReports}
      translateTableId='chooseReportsScreen.columns'
      route={Routes.REPORT}
      routeNew={Routes.NEW_REPORT}
      translationPath='portfolioScreen.portfolioOverview.reportsCard'
    />
  );
};

export const ChildBacktestsCard = ({ portfolioId }: { portfolioId: Id }) => {
  const { backtests, isLoading: isLoadingBacktests } = useBacktestsQuery(portfolioId);
  const { mutate: deleteBacktest } = useDeleteBacktestMutation();

  return (
    <ChildObjectsCard
      childrenObjects={backtests}
      portfolioId={portfolioId}
      dialogTranslatePath='backtestScreen.useSavePromptAndDialog'
      deleteChildObjFn={deleteBacktest}
      isLoadingChildren={isLoadingBacktests}
      translateTableId='chooseBacktestsScreen.columns'
      route={Routes.BACKTEST_WITH_PORTFOLIO}
      routeNew={Routes.NEW_BACKTEST}
      translationPath='portfolioScreen.portfolioOverview.backtestsCard'
    />
  );
};

export const ChildAnalysesCard = ({ portfolioId }: { portfolioId: Id }) => {
  const { analyses, isLoading: isLoadingAnalyses } = useAnalysesQuery(portfolioId);
  const { mutate: deleteAnalysis } = useDeleteAnalysisMutation();

  return (
    <ChildObjectsCard
      childrenObjects={analyses}
      portfolioId={portfolioId}
      dialogTranslatePath='analysisScreen.useSavePromptAndDialog'
      deleteChildObjFn={deleteAnalysis}
      isLoadingChildren={isLoadingAnalyses}
      translateTableId='chooseAnalysesScreen.columns'
      route={Routes.ANALYSIS}
      routeNew={Routes.NEW_ANALYSIS}
      translationPath='portfolioScreen.portfolioOverview.analysesCard'
    />
  );
};

import React from 'react';
import { useParams } from 'react-router-dom';

import DownCapture from 'screens/Analysis/AnalysisScreen/widgets/DownCapture/DownCapture';
import DrawdownBar from 'screens/Analysis/AnalysisScreen/widgets/DrawdownBar/DrawdownBar';
import RiskBreakdown from 'screens/Analysis/AnalysisScreen/widgets/RiskBreakdown/RiskBreakdown';
import RiskBreakdownBar from 'screens/Analysis/AnalysisScreen/widgets/RiskBreakdownBar/RiskBreakdownBar';
import RiskContributionTable from 'screens/Analysis/AnalysisScreen/widgets/RiskContributionTable/RiskContributionTable';

const RiskTab = () => {
  const { id } = useParams();

  return (
    <>
      <RiskBreakdownBar analysisId={id} />
      <RiskBreakdown analysisId={id} />
      <RiskContributionTable analysisId={id} />
      <DrawdownBar analysisId={id} />
      <DownCapture analysisId={id} />
    </>
  );
};

export default RiskTab;

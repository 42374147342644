import { getPercentagePerformance, parseDatesInSeries } from 'lib/timeSeries/tsData';
import { Id, ValueOf } from 'lib/types';
import { getNumberOfAlerts } from 'screens/Portfolio/lib/helpers';
import { PortfolioOverview } from 'screens/Portfolio/lib/types';
import { BenchmarkType } from 'utils/constants';

interface LineChartData {
  main: {
    id: Id;
    name: string;
    series: { x: Date; y: number }[];
    color: string;
    type: ValueOf<typeof BenchmarkType>;
    error: null;
  };
}

export function makeLineChartDataForPortfolioCard(portfolio: PortfolioOverview): LineChartData {
  let main;
  let performance;
  if (portfolio.isTracked) {
    performance = portfolio.history.performance;
  }

  if (performance) {
    main = {
      id: portfolio.id,
      name: portfolio.name,
      series: getPercentagePerformance(parseDatesInSeries(performance)),
      color: '#9B9B9B',
      type: BenchmarkType.PORTFOLIO,
      error: null,
    };
  } else {
    main = {
      id: portfolio.id,
      name: portfolio.name,
      series: [],
      color: '#9B9B9B',
      type: BenchmarkType.PORTFOLIO,
      error: null,
    };
  }
  return { main };
}

interface INameable {
  name: string;
}

type SortFuncReturn = -1 | 1 | 0;

function sortByName(a: INameable, b: INameable): SortFuncReturn {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export function hasAlerts(portfolio: PortfolioOverview) {
  return getNumberOfAlerts(portfolio) > 0;
}

export function sortPortfolioCards(
  portfolios: PortfolioOverview[]
): ReadonlyArray<PortfolioOverview> {
  const favoritePortfolios: PortfolioOverview[] = [];
  const alertPortfolios: PortfolioOverview[] = [];
  const otherPortfolios: PortfolioOverview[] = [];
  portfolios.forEach(portfolio => {
    if (portfolio) {
      if (portfolio.isFavorite) {
        favoritePortfolios.push(portfolio);
        return;
      }
      if (hasAlerts(portfolio)) {
        alertPortfolios.push(portfolio);
        return;
      }
      otherPortfolios.push(portfolio);
    }
  });
  favoritePortfolios.sort(sortByName);
  otherPortfolios.sort(sortByName);
  alertPortfolios.sort(sortByName);
  return favoritePortfolios.concat(alertPortfolios, otherPortfolios);
}

import Button from '@mui/material/Button/index';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';

import { ClassesType, TranslateDataType } from 'utils/types';

const styles = theme => ({
  root: {
    margin: `0 ${theme.spacing(4)} 0 ${theme.spacing(4)}`,
    minWidth: '7rem',
    maxHeight: '35px',
    borderRadius: theme.shape.borderRadius,
  },
});

const myVariantToMui = {
  highEmp: 'contained',
  lowEmp: 'text',
  midEmp: 'outlined',
};

class SimpleButton extends React.Component {
  componentDidMount() {
    const { clickOnEnter } = this.props;
    if (clickOnEnter) {
      document.addEventListener('keypress', this.onKeyPress, false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.onKeyPress, false);
  }

  onKeyPress = event => {
    const { onClick, disabled } = this.props;
    if (event.key === 'Enter' && !disabled) {
      onClick();
    }
  };

  render() {
    const {
      translateId,
      onClick,
      component,
      classes,
      variant,
      className,
      translateData,
      clickOnEnter,
      size,
      ...other
    } = this.props;
    return (
      <Button
        data-test-id={`SimpleButton-${translateId}`}
        className={classNames(className, classes.root)}
        onClick={onClick}
        component={component}
        size={size}
        variant={myVariantToMui[variant]}
        color={variant ? 'primary' : 'default'}
        {...other}
      >
        <Translate id={translateId} data={translateData} />
      </Button>
    );
  }
}

SimpleButton.propTypes = {
  classes: ClassesType.isRequired,
  translateId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  component: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  translateData: TranslateDataType,
  clickOnEnter: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

SimpleButton.defaultProps = {
  disabled: false,
  component: 'button',
  onClick: null,
  variant: null,
  className: '',
  translateData: null,
  clickOnEnter: false,
  size: 'medium',
};

export default withStyles(styles, { withTheme: true })(SimpleButton);

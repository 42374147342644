import { ChangeCircle } from '@mui/icons-material';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Translate } from 'react-localize-redux';

import { fuseSearch } from '../SearchBar/utils';

import EnhancedTableHead from './Header';
import EnhancedTableToolbar from './Toolbar';
import { getSorting, stableSort } from './utils';

import SimpleText from 'components/SimpleText';
import { switchElementInArray, triggerCsvDownload } from 'lib/misc/misc';
import { DEFAULT_CSV_DELIMITER, Order } from 'utils/constants';
import { ExportFieldsType, idArrayType, OrderType, TableObjectsType } from 'utils/types';

const styles = theme => ({
  root: {
    width: '100%',
    minWidth: '400px',
    maxWidth: '100vw',
    borderRadius: '0',
    boxShadow: '0 0 0',
    '& .MuiTableCell-body': {
      color: theme.palette.text.primary,
    },
    '& .MuiTableCell-head': {
      color: theme.palette.text.primary,
    },
  },
  tableRight: {},
  table: {
    overflowX: 'scroll',
    scrollbarWidth: 100,
    color: 'inherit',
  },
  tr: {
    height: '2.2rem',
  },
  cellObject: {
    maxWidth: '10rem',
    minWidth: '10rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    wordWrap: 'normal',
  },
  pagination: {
    // height: '3rem !important',
    // minHeight: '2rem !important',
    // fontSize: '0.625rem',
    // letterSpacing: '0.03333em',
    // lineHeight: '5.185em',
  },
  identifiers: {
    maxWidth: '10rem',
    // minWidth: '10rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    lineHeight: 1.25,
  },
  withErrorIdentifier: {
    float: 'left',
    color: theme.palette.error.main,
    marginTop: '0.3rem',
    maxWidth: '10rem',
    // minWidth: '10rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    lineHeight: 1.25,
  },
  deletIcon: {
    fontSize: 15,
    padding: 0,
  },
  deleteIconRow: {
    width: 30,
  },
  icons: {
    display: 'flex',
  },
});

class TableView extends React.Component {
  constructor(props) {
    super(props);

    const { initialOrder, initialOrderBy, columns, initialRowsPerPage } = this.props;
    this.state = {
      order: initialOrder,
      orderBy: initialOrderBy || columns[0],
      pageNumber: 0,
      rowsPerPage: initialRowsPerPage,
      checkedColumns: columns,
      selectedObjectIds: [],
    };
  }

  onSelectAllClick = (objectIds, numSelected) => {
    if (numSelected === 0) {
      this.setState({ selectedObjectIds: objectIds });
    } else {
      this.setState({ selectedObjectIds: [] });
    }
  };

  onRowClick = id => {
    const { selectedObjectIds } = this.state;
    if (selectedObjectIds.includes(id)) {
      this.setState({ selectedObjectIds: selectedObjectIds.filter(i => i !== id) });
    } else {
      this.setState({ selectedObjectIds: [...selectedObjectIds, id] });
    }
  };

  onColumnSortClick = (order, orderBy) => {
    this.setState({ order, orderBy });
  };

  onChangeRowsPerPage = rowsPerPage => {
    this.setState({ rowsPerPage, pageNumber: 0 });
  };

  onChangePage = pageNumber => {
    this.setState({ pageNumber });
  };

  onColumnCheck = column => {
    const { checkedColumns } = this.state;
    this.setState({ checkedColumns: switchElementInArray(checkedColumns, column) });
  };

  handleRowsDownload = tableRowObject => {
    const { rowsDownloadData, exportFormatting } = this.props;
    const rowData = rowsDownloadData.find(row => row.id === tableRowObject.id);
    const options = { delimiter: DEFAULT_CSV_DELIMITER };
    options.fields = rowData.columns;
    options.exportFormatting = exportFormatting;
    triggerCsvDownload(rowData.data, `${rowData.fileName}`, options);
  };

  render() {
    const {
      columns,
      tableObjects,
      title,
      titleTranslateId,
      titleTranslateData,
      onApplyClick,
      isOnLeftSide,
      classes,
      searchValue,
      searchColumns,
      translateTableId,
      cellFormatting,
      isTableWhite,
      isWithCheckbox,
      exportFields,
      isDownloadButton,
      exportFormatting,
      onSuccessfulUpload,
      rowsPerPageChoices,
      hasPagination,
      hasToolbar,
      alertIdentifiers,
      deletableRows,
      onDeleteRowClick,
      extraOnClickObjects,
      hasColumnCheckBox,
      downloadFileTitle,
      showEmptyRows,
      onAddRowClick,
      addRowTranslateId,
      isUploadButton,
      uploadButtonColor,
      downloadableRows,
      onDeleteSelectedClick,
      replaceableIds,
    } = this.props;
    const areAllRowsDeletable = _.isBoolean(deletableRows) && deletableRows;
    const areAnyRowsDeletable = Boolean(deletableRows);
    const areAnyRowsDownloadable = Boolean(downloadableRows);
    let tableObjectsArray = tableObjects;
    if (!_.isArray(tableObjects)) {
      tableObjectsArray = _.values(tableObjects);
    }

    // If `onRowClick` is provided in props,
    // then use it along with `selectedObjectIds` and `onSelectAllClick` from this.props;
    // Otherwise, use values from this.state
    let {
      selectedObjectIds,
      onRowClick: onRowClickFunc,
      onSelectAllClick: onSelectAllClickFunc,
    } = this.props;
    if (!onRowClickFunc) {
      onRowClickFunc = this.onRowClick;
      onSelectAllClickFunc = this.onSelectAllClick;
      ({ selectedObjectIds } = this.state);
    }

    // If `onColumnSortClick` is provided in props,
    // then use it along with `order`, `orderBy` from this.props;
    // Otherwise, use values from this.state
    let { order, orderBy, onColumnSortClick: onColumnSortClickFunc } = this.props;
    if (!onColumnSortClickFunc) {
      onColumnSortClickFunc = this.onColumnSortClick;
      ({ order, orderBy } = this.state);
    }

    // If `onChangeRowsPerPage` is provided in props,
    // then use it along with `rowsPerPage` from this.props;
    // Otherwise, use values from this.state
    let { rowsPerPage, onChangeRowsPerPage: onChangeRowsPerPageFunc } = this.props;
    if (!onChangeRowsPerPageFunc) {
      onChangeRowsPerPageFunc = this.onChangeRowsPerPage;
      ({ rowsPerPage } = this.state);
    }

    // If `onChangePage` is provided in props,
    // then use it along with `pageNumber` from this.props;
    // Otherwise, use values from this.state
    let { pageNumber, onChangePage: onChangePageFunc } = this.props;
    if (!onChangePageFunc) {
      onChangePageFunc = this.onChangePage;
      ({ pageNumber } = this.state);
    }

    // If `onColumnCheck` is provided in props,
    // then use it along with `checkedColumns` from this.props;
    // Otherwise, use values from this.state
    let { checkedColumns, onColumnCheck: onColumnCheckFunc } = this.props;
    if (!onColumnCheckFunc) {
      onColumnCheckFunc = this.onColumnCheck;
      ({ checkedColumns } = this.state);
    }
    const onApplyClickFunc = onApplyClick ? () => onApplyClick(selectedObjectIds) : null;
    const onDeleteSelectedClickFunc = onDeleteSelectedClick
      ? () => {
          onDeleteSelectedClick(selectedObjectIds);
          this.setState({ selectedObjectIds: [] });
        }
      : null;

    const numSelected = selectedObjectIds.length;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const searchTableObjects = useMemo(
      () => fuseSearch(tableObjectsArray, searchValue, searchColumns ?? columns),
      [tableObjectsArray, searchValue, searchColumns, columns]
    );
    const objectIds = searchTableObjects.map(obj => obj.id);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const sortedTableObjects = useMemo(
      () => stableSort(searchTableObjects, getSorting(order, orderBy)),
      [searchTableObjects, order, orderBy]
    );

    const visibleObjects = sortedTableObjects.slice(
      pageNumber * rowsPerPage,
      pageNumber * rowsPerPage + rowsPerPage
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const countVisibleObjects = sortedTableObjects.length;
      if (countVisibleObjects <= pageNumber * rowsPerPage) {
        let newPageNumber = Math.floor((countVisibleObjects - 1) / rowsPerPage);
        if (newPageNumber < 0) {
          newPageNumber = 0;
        }
        this.setState({ pageNumber: newPageNumber });
      }
    }, [pageNumber, rowsPerPage, sortedTableObjects.length]);

    const emptyRowsNumber = rowsPerPage - visibleObjects.length;
    const emptyRows = _.range(emptyRowsNumber).map(i => (
      <TableRow key={i} className={classes.tr}>
        <TableCell padding='checkbox' size='small'>
          {isWithCheckbox ? <Checkbox color='primary' checked={false} disabled /> : null}
        </TableCell>
        {columns.map(columnKey => (
          <TableCell key={columnKey} />
        ))}
        {areAnyRowsDeletable || areAllRowsDeletable ? (
          <TableCell className={classes.deleteIconRow} size='small' />
        ) : null}
        {areAnyRowsDownloadable ? (
          <TableCell className={classes.deleteIconRow} size='small' />
        ) : null}
      </TableRow>
    ));
    return (
      <Box className={isTableWhite ? classes.root : clsx(classes.root, classes.tableRight)}>
        {hasToolbar ? (
          <EnhancedTableToolbar
            titleTranslateId={title ? `tableTitle.${title}` : titleTranslateId}
            titleTranslateData={titleTranslateData}
            numSelected={numSelected}
            onApplyClick={onApplyClickFunc}
            isOnLeftSide={isOnLeftSide}
            columns={columns}
            checkedColumns={checkedColumns}
            onColumnCheck={onColumnCheckFunc}
            tableObjects={tableObjectsArray}
            exportFields={exportFields}
            onSuccessfulUpload={isUploadButton ? onSuccessfulUpload : null}
            uploadButtonColor={uploadButtonColor}
            isDownloadButton={isDownloadButton}
            exportFormatting={exportFormatting}
            translateTableId={translateTableId}
            hasColumnCheckBox={hasColumnCheckBox}
            downloadFileTitle={downloadFileTitle}
            onAddRowClick={onAddRowClick}
            addRowTranslateId={addRowTranslateId}
            onDeleteSelectedClick={onDeleteSelectedClickFunc}
          />
        ) : null}
        <div className={classes.table}>
          <Table>
            <EnhancedTableHead
              translateTableId={translateTableId}
              numSelected={numSelected}
              order={order}
              orderBy={orderBy}
              onColumnSortClick={onColumnSortClickFunc}
              onSelectAllClick={
                onSelectAllClickFunc
                  ? () => onSelectAllClickFunc(objectIds, numSelected)
                  : undefined
              }
              tableObjects={tableObjectsArray}
              rowCount={tableObjectsArray.length}
              columns={checkedColumns}
              isWithCheckbox={isWithCheckbox}
              areAnyRowsDeletable={areAnyRowsDeletable}
              areAnyRowsDownloadable={areAnyRowsDownloadable}
            />
            <TableBody>
              {visibleObjects.map(tableObject => {
                const isSelected = selectedObjectIds.includes(tableObject.id);
                return (
                  <TableRow
                    className={classes.tr}
                    key={tableObject.id}
                    hover
                    role='checkbox'
                    selected={isSelected}
                  >
                    <TableCell
                      onClick={() => onRowClickFunc(tableObject.id)}
                      padding='checkbox'
                      size='small'
                    >
                      {isWithCheckbox ? (
                        <Checkbox
                          color='primary'
                          checked={isSelected}
                          checkedIcon={<CheckBoxOutlined />}
                        />
                      ) : null}
                      {replaceableIds && replaceableIds.includes(tableObject.id) && (
                        <ChangeCircle
                          color='primary'
                          onClick={() => onRowClickFunc(tableObject.id)}
                        />
                      )}
                    </TableCell>
                    {checkedColumns.map(columnKey => {
                      const value = tableObject?.[columnKey] || '';
                      const formattedValue =
                        columnKey in cellFormatting && value
                          ? cellFormatting[columnKey](value)
                          : value;
                      return (
                        <Tooltip key={columnKey} title={formattedValue}>
                          <TableCell
                            onClick={() => onRowClickFunc(tableObject.id)}
                            size='small'
                            key={columnKey}
                            className={classes.cellObject}
                          >
                            <React.Fragment>
                              {alertIdentifiers ? (
                                <React.Fragment>
                                  {Object.keys(alertIdentifiers).includes(value) ? (
                                    <React.Fragment>
                                      <SimpleText
                                        className={classes.withErrorIdentifier}
                                        text={formattedValue}
                                        variant='subtitle2'
                                      />
                                      <div className={classes.icons}>{alertIdentifiers[value]}</div>
                                    </React.Fragment>
                                  ) : (
                                    <SimpleText
                                      className={classes.identifiers}
                                      text={formattedValue}
                                      variant='subtitle2'
                                    />
                                  )}
                                </React.Fragment>
                              ) : (
                                <SimpleText text={formattedValue} variant='subtitle2' />
                              )}
                            </React.Fragment>
                          </TableCell>
                        </Tooltip>
                      );
                    })}
                    {areAnyRowsDownloadable && downloadableRows.includes(tableObject.id) ? (
                      <Tooltip title='download'>
                        <TableCell
                          onClick={() => this.handleRowsDownload(tableObject)}
                          className={classes.deleteIconRow}
                          size='small'
                        >
                          <DownloadIcon className={classes.deletIcon} />
                        </TableCell>
                      </Tooltip>
                    ) : null}
                    {areAnyRowsDownloadable && !downloadableRows.includes(tableObject.id) ? (
                      <TableCell className={classes.deleteIconRow} size='small' />
                    ) : null}
                    {areAllRowsDeletable ||
                    (areAnyRowsDeletable && deletableRows.includes(tableObject.id)) ? (
                      <Tooltip title='delete'>
                        <TableCell
                          onClick={() => onDeleteRowClick(tableObject, extraOnClickObjects)}
                          className={classes.deleteIconRow}
                          size='small'
                        >
                          <DeleteOutlineIcon className={classes.deletIcon} />
                        </TableCell>
                      </Tooltip>
                    ) : null}
                    {!areAllRowsDeletable &&
                    areAnyRowsDeletable &&
                    !deletableRows.includes(tableObject.id) ? (
                      <TableCell className={classes.deleteIconRow} size='small' />
                    ) : null}
                  </TableRow>
                );
              })}
              {showEmptyRows ? emptyRows : null}
            </TableBody>
          </Table>
        </div>
        {hasPagination ? (
          <Translate>
            {({ translate }) => (
              <TablePagination
                classes={{ toolbar: classes.pagination }}
                rowsPerPageOptions={rowsPerPageChoices}
                component='div'
                labelRowsPerPage={translate('table.rowsPerPage')}
                labelDisplayedRows={({ from, to, count }) =>
                  translate('table.displayedRows', { from, to, count })
                }
                count={sortedTableObjects.length}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onPageChange={(e, page) => onChangePageFunc(page)}
                onRowsPerPageChange={e => onChangeRowsPerPageFunc(e.target.value)}
              />
            )}
          </Translate>
        ) : null}
      </Box>
    );
  }
}

TableView.propTypes = {
  translateTableId: PropTypes.string.isRequired,
  titleTranslateId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  titleTranslateData: PropTypes.any,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRowClick: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  onApplyClick: PropTypes.func,
  selectedObjectIds: idArrayType,
  tableObjects: TableObjectsType.isRequired,
  title: PropTypes.string,
  isOnLeftSide: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  pageNumber: PropTypes.number,
  rowsPerPage: PropTypes.number,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  checkedColumns: PropTypes.arrayOf(PropTypes.string),
  onColumnCheck: PropTypes.func,
  order: OrderType,
  orderBy: PropTypes.string,
  onColumnSortClick: PropTypes.func,
  searchValue: PropTypes.string,
  searchColumns: PropTypes.arrayOf(PropTypes.string),
  cellFormatting: PropTypes.objectOf(PropTypes.func),
  exportFormatting: PropTypes.objectOf(PropTypes.func),
  isTableWhite: PropTypes.bool,
  isWithCheckbox: PropTypes.bool,
  exportFields: ExportFieldsType,
  onSuccessfulUpload: PropTypes.func,
  isDownloadButton: PropTypes.bool,
  initialOrderBy: PropTypes.string,
  initialOrder: PropTypes.string,
  initialRowsPerPage: PropTypes.number,
  rowsPerPageChoices: PropTypes.arrayOf(PropTypes.number),
  hasPagination: PropTypes.bool,
  hasToolbar: PropTypes.bool,
  alertIdentifiers: PropTypes.arrayOf(PropTypes.string),
  // if Boolean true, then all rows are deletable,
  // if Falsy value, then no row is deletable,
  // if Array of Numbers, then corresponds to IDs of tableObjects, which are deletable.
  deletableRows: PropTypes.oneOfType([PropTypes.bool, idArrayType]),
  onDeleteRowClick: PropTypes.func,
  extraOnClickObjects: PropTypes.objectOf(PropTypes.any),
  hasColumnCheckBox: PropTypes.bool,
  downloadFileTitle: PropTypes.string,
  showEmptyRows: PropTypes.bool,
  onAddRowClick: PropTypes.func,
  addRowTranslateId: PropTypes.string,
  isUploadButton: PropTypes.bool,
  uploadButtonColor: PropTypes.string,
  downloadableRows: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
  rowsDownloadData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  onDeleteSelectedClick: PropTypes.func,
};

TableView.defaultProps = {
  title: null,
  titleTranslateId: null,
  titleTranslateData: undefined,
  onRowClick: null,
  selectedObjectIds: [],
  isOnLeftSide: true,
  cellFormatting: {},
  searchValue: null,
  searchColumns: null,
  exportFields: null,
  onSuccessfulUpload: null,
  isDownloadButton: false,
  exportFormatting: {},
  onColumnSortClick: null,
  order: null,
  orderBy: null,
  initialOrderBy: null,
  initialOrder: Order.ASC,
  initialRowsPerPage: 10,
  rowsPerPage: null,
  rowsPerPageChoices: [5, 10, 20, 50, 100],
  onChangeRowsPerPage: null,
  onChangePage: null,
  pageNumber: null,
  checkedColumns: null,
  onColumnCheck: null,
  onApplyClick: null,
  onSelectAllClick: null,
  hasPagination: true,
  hasToolbar: true,
  alertIdentifiers: null,
  deletableRows: false,
  onDeleteRowClick: null,
  extraOnClickObjects: null,
  hasColumnCheckBox: true,
  downloadFileTitle: '',
  showEmptyRows: true,
  onAddRowClick: null,
  isUploadButton: true,
  uploadButtonColor: 'default',
  downloadableRows: false,
  rowsDownloadData: [],
  onDeleteSelectedClick: null,
  addRowTranslateId: 'iconButtonTooltip.add',
  isTableWhite: false,
  isWithCheckbox: false,
};

export default withStyles(styles, { withTheme: true })(TableView);
